import { HeadTypeInfo } from 'modules/onboarding/common/components';

import { useHeadTypeInfo } from '../hooks';
import { useOnboardingContext } from '../hooks/useOnboardingContext';
import { PageComponentProps } from '../types';

export const HeadTypeInfoStep = ({ canGoBack }: PageComponentProps) => {
  const { goToBack, onSubmit, bodies, initialValues, submitLoading } =
    useHeadTypeInfo();

  const { step } = useOnboardingContext();

  return (
    <HeadTypeInfo
      bodies={bodies ?? []}
      canGoBack={canGoBack}
      initialValues={initialValues}
      step={step}
      submitLoading={submitLoading}
      onGoBack={goToBack}
      onSubmit={onSubmit}
    />
  );
};
