import {
  OnboardingTaxResidency,
  ClientType,
} from 'modules/onboarding/common/components';

import { useOwnerTaxResidency } from '../hooks';
import { PageComponentProps } from '../types';

export const OwnerTaxResidencyStep = ({ canGoBack }: PageComponentProps) => {
  const {
    countries,
    countriesLoading,
    initialValues,
    submitLoading,
    onSubmit,
    goToBack,
  } = useOwnerTaxResidency();

  return (
    <OnboardingTaxResidency
      canGoBack={canGoBack}
      clientType={ClientType.Client}
      countries={countries}
      countriesLoading={countriesLoading}
      initialValues={initialValues}
      submitLoading={submitLoading}
      onGoBack={goToBack}
      onSubmit={onSubmit}
    />
  );
};
