import { LoadingViewWrapper } from 'components';
import { OnboardingKYC } from 'modules/onboarding/common/components';

import { useKyc } from '../hooks';

export const KycStep = () => {
  const { token, expirationHandler, loading } = useKyc();
  return (
    <LoadingViewWrapper loading={loading || !token}>
      <OnboardingKYC expirationHandler={expirationHandler} token={token!} />
    </LoadingViewWrapper>
  );
};
