import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useLegalClientRepresentativeSaveCompanyConstitutionalDocumentMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { LegalClientSaveConstitutionalDocumentParam } from 'api/types/params';

import { parseLegalClientRepresentative } from '../helpers';

export const useLegalClientRepresentativeSaveCompanyConstitutionalDocument =
  () => {
    const [handle, { data, loading, error: apolloError }] =
      useLegalClientRepresentativeSaveCompanyConstitutionalDocumentMutation();

    const error = useError(apolloError);

    return {
      data: data?.legalClientRepresentativeSaveCompanyConstitutionalDocument
        ? parseLegalClientRepresentative(
            data.legalClientRepresentativeSaveCompanyConstitutionalDocument
          )
        : undefined,
      loading,
      error,
      saveConstitutionalDocument: useCallback(
        async (
          id: string,
          params: LegalClientSaveConstitutionalDocumentParam
        ) => {
          const res = await handle({
            variables: {
              id,
              input: params,
            },
          });
          return res?.data
            ?.legalClientRepresentativeSaveCompanyConstitutionalDocument
            ? parseLegalClientRepresentative(
                res.data
                  .legalClientRepresentativeSaveCompanyConstitutionalDocument
              )
            : undefined;
        },
        [handle]
      ),
    };
  };
