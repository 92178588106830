import {
  ObjectSchema,
  TypedForm,
  FormErrors as FormErrorsBase,
} from 'libs/form';
import {
  getBaseDebtsSchema,
  BaseDebtsFormType,
} from 'modules/onboarding/common/forms/baseDebtsForm';

export interface DebtsFormType extends BaseDebtsFormType {}

export const getSchema = (
  isEntrepreneur?: boolean
): ObjectSchema<DebtsFormType> => getBaseDebtsSchema(isEntrepreneur);

export const { Field, Form, useFormContext, Submit } =
  TypedForm<DebtsFormType>();

export type FormErrors = FormErrorsBase<DebtsFormType>;
