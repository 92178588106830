import { CountryEntity } from 'api/types/entity';
import {
  TypedForm,
  FormErrors as FormErrorsBase,
  ObjectSchema,
  string,
  object,
  array,
} from 'libs/form';
import { Maybe } from 'types/maybe';

export interface OnboardingCitizenshipFormType {
  citizenships: Maybe<CountryEntity>[];
}

export const getSchema = (): ObjectSchema<OnboardingCitizenshipFormType> =>
  object({
    citizenships: array()
      .of(
        object({
          id: string().required(),
          name: string().required(),
        }).required()
      )
      .required(),
  });

export const { Field, Form, useFormContext, Submit } =
  TypedForm<OnboardingCitizenshipFormType>();

export type FormErrors = FormErrorsBase<OnboardingCitizenshipFormType>;
