import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useLegalClientHeadSaveHeadTypeInfoMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { SaveHeadTypeInfoParam } from 'api/types/params';

import { parseLegalClientHeadEntity } from './helpers';

export const useLegalClientHeadSaveHeadTypeInfo = () => {
  const [handle, { data, loading, error: apolloError }] =
    useLegalClientHeadSaveHeadTypeInfoMutation();

  const error = useError(apolloError);

  return {
    data: data?.legalClientHeadSaveHeadTypeInfo
      ? parseLegalClientHeadEntity(data.legalClientHeadSaveHeadTypeInfo)
      : undefined,
    loading,
    error,
    saveHeadTypeInfo: useCallback(
      async (id: string, headId: string, param: SaveHeadTypeInfoParam) => {
        const res = await handle({
          variables: {
            id,
            headId,
            input: param,
          },
        });
        return res?.data?.legalClientHeadSaveHeadTypeInfo
          ? parseLegalClientHeadEntity(res.data.legalClientHeadSaveHeadTypeInfo)
          : undefined;
      },
      [handle]
    ),
  };
};
