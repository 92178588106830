import { useTranslation } from 'libs/i18n';
import { phoneMaxLength } from 'modules/onboarding/legal/forms/companyContactsForm';

import { phoneValidation } from '../../../../../../common/helpers/validations';
import { Field } from '../../../forms/contactsForm';

export interface FormContentProps {}

export const FormContent = () => {
  const { t } = useTranslation();

  return (
    <>
      <Field.Text
        showOptionalMark
        checkValue={phoneValidation}
        label={t('onboarding.legalClientBranch.contacts.phone.label')}
        maxLength={phoneMaxLength}
        name="phone"
        placeholder={t(
          'onboarding.legalClientBranch.contacts.phone.placeholder'
        )}
      />

      <Field.Text
        showOptionalMark
        label={t('onboarding.legalClientBranch.contacts.email.label')}
        name="email"
        placeholder={t(
          'onboarding.legalClientBranch.contacts.email.placeholder'
        )}
        type="email"
      />
    </>
  );
};
