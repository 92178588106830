import { getYesOrNoOptions } from 'helpers';
import { useTranslation } from 'libs/i18n';
import { Stack } from 'libs/ui';
import {
  BaseDebtsFormContent,
  ClientType,
  FormBodyWrapper,
} from 'modules/onboarding/common/components';

import {
  DebtsFormType,
  Field,
  FormErrors,
  getSchema,
} from '../../forms/debtsForm';

interface Props {
  initialValues?: DebtsFormType;
  onSubmit: (values: DebtsFormType) => void;
  canGoBack?: boolean;
  onGoBack?: () => void;
  formError?: FormErrors;
  submitLoading?: boolean;
  submitDisabled?: boolean;
  isEntrepreneur?: boolean;
  clientType?: ClientType;
  subtitle?: string;
}

export const Debts = ({
  initialValues,
  canGoBack,
  submitLoading,
  submitDisabled,
  formError,
  isEntrepreneur,
  onGoBack,
  onSubmit,
  clientType,
  subtitle,
}: Props) => {
  const { t } = useTranslation();
  const schema = getSchema(isEntrepreneur);
  return (
    <FormBodyWrapper
      canGoBack={canGoBack}
      clientType={clientType}
      initialErrors={formError}
      initialValues={initialValues}
      submitDisabled={submitDisabled}
      submitLoading={submitLoading}
      subTitle={subtitle ?? t('onboarding.debts.subtitle')}
      title={t('onboarding.debts.title')}
      validationSchema={schema}
      onGoBack={onGoBack}
      onSubmit={onSubmit}
    >
      <Stack spacing={32}>
        <BaseDebtsFormContent isEntrepreneur={isEntrepreneur} />

        <Field.Autocomplete
          label={t('onboarding.debts.marginTrades.label')}
          name="marginTrades"
          optionLabelKey="label"
          options={getYesOrNoOptions(t)}
          optionValueKey="value"
          placeholder={t('common.select.placeholder')}
          variant="select"
        />
      </Stack>
    </FormBodyWrapper>
  );
};
