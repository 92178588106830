import { useOnboardingClientIdentity } from 'api/requests';
import { ClientIdentityType } from 'api/types/data';
import { LoadingLayout } from 'components';

import { ClientContainer } from '../client';
import { ClientWithRepresentative } from '../clientWithRepresentative';
import { Legal } from '../legal';
import { LegalBeneficiary } from '../legalBeneficiary';
import { LegalFirstHead } from '../legalFirstHead';
import { LegalHead } from '../legalHead';
import { RepresentativeContainer } from '../representative';

export const OnboardingRoutes = () => {
  const { clientIdentity, loading } = useOnboardingClientIdentity();

  if (loading) {
    return <LoadingLayout />;
  }

  switch (clientIdentity?.type) {
    case ClientIdentityType.Client:
      return <ClientContainer clientId={clientIdentity.id} />;

    case ClientIdentityType.ClientRepresentative:
      return <RepresentativeContainer clientId={clientIdentity.id} />;

    case ClientIdentityType.ClientWithRepresentative:
      return <ClientWithRepresentative clientId={clientIdentity.id} />;

    case ClientIdentityType.LegalClientRepresentative:
      return <Legal clientId={clientIdentity.id} />;

    case ClientIdentityType.LegalClientHead:
      return <LegalHead clientId={clientIdentity.id} />;

    case ClientIdentityType.LegalClientLegalHeadFirstHead:
      return <LegalFirstHead clientId={clientIdentity.id} />;

    case ClientIdentityType.LegalClientBeneficiary:
      return <LegalBeneficiary clientId={clientIdentity.id} />;

    default:
      return <LoadingLayout />;
  }
};
