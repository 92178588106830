import { useRef } from 'react';

import { SendCodeTimer, SendCodeTimerRef } from 'components';
import { useIsMobileSize } from 'hooks';
import { useTranslation } from 'libs/i18n';
import { Pressed, Stack, Typography } from 'libs/ui';
import { Icon } from 'libs/ui/Icon';

import { WaitingKycUserType } from '../types';

import { ItemStyled, SendButtonWrapperStyled } from './styles';

export interface ItemProps {
  user: WaitingKycUserType;
  onEdit: (id: string) => void;
  onResend: (id: string) => void;
}

export const Item = ({ user, onEdit, onResend }: ItemProps) => {
  const { t } = useTranslation();

  const timerRef = useRef<SendCodeTimerRef>();

  const isMobile = useIsMobileSize();

  const handleResend = () => {
    onResend(user.id);
    timerRef.current?.startTimer(60);
  };

  return (
    <Stack
      alignItems={isMobile ? 'start' : 'center'}
      direction="row-exceptMobile"
      justifyContent="space-between"
      spacing={isMobile ? 0 : 12}
    >
      <ItemStyled alignItems="center" direction="row-exceptMobile" spacing={16}>
        <Stack
          alignItems="center"
          className="waitingKyc-item-textContent"
          direction="row"
          spacing={8}
        >
          <Typography
            className="waitingKyc-item-text"
            title={`${user.name} - ${user.email}`}
            variant="bodySemiBold"
            variantMobile="captionSemiBold"
          >
            {user.name} — {user.email}
          </Typography>
          {!user.done && !user.isActive && (
            <Pressed onClick={() => onEdit(user.id)}>
              <Icon.Edit />
            </Pressed>
          )}
        </Stack>
      </ItemStyled>
      {!user.done && !user.isActive && (
        <SendButtonWrapperStyled>
          <SendCodeTimer
            ref={timerRef}
            buttonLabel={t('onboarding.waitingKyc.button.label')}
            onClickSend={handleResend}
          />
        </SendButtonWrapperStyled>
      )}
    </Stack>
  );
};
