import { CountryEntity } from 'api/types/entity';
import { Stack } from 'libs/ui';

import { useFormContext } from '../../../forms/onboardingTaxResidencyForm';

import { Header, Fields, Buttons } from './components';

export interface FormContentProps {
  countriesLoading?: boolean;
  countries: CountryEntity[];
}

export const FormContent = ({
  countriesLoading,
  countries,
}: FormContentProps) => {
  const { values } = useFormContext();

  return (
    <Stack spacing={32}>
      {values.taxValues.map((value, index) => (
        <Stack key={index.toLocaleString()} spacing={32}>
          <Stack spacing={24} spacingMobile={16}>
            <Header index={index} />

            <Fields
              countries={countries}
              countriesLoading={countriesLoading}
              index={index}
              value={value}
            />
          </Stack>

          <Buttons index={index} />
        </Stack>
      ))}
    </Stack>
  );
};
