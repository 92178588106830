import {
  LegalClientEntity,
  LegalClientRepresentativeEntity,
  OnboardingProcessStepNameType,
} from 'api/types/entity';

import { HeadPageType, InnerState } from '../hooks/useOnboarding/type';
import { LegalClientBeneficiary } from '../innerOnboarding/LegalClientBeneficiary';
import { LegalClientBranch } from '../innerOnboarding/legalClientBranch';
import { LegalClientHead } from '../innerOnboarding/legalClientHead';
import { LegalClientLegalHead } from '../innerOnboarding/legalClientLegalHead';
import { LegalClientLegalHeadFirstHead } from '../innerOnboarding/legalClientLegalHeadFirstHead';

interface Props {
  currentClient: LegalClientRepresentativeEntity;
  legalClient: LegalClientEntity | null;
  innerState: InnerState;
  currentPageName: OnboardingProcessStepNameType;
}

export const getInnerComponent = ({
  currentClient,
  legalClient,
  innerState,
  currentPageName,
}: Props) => {
  const isHead =
    currentPageName === OnboardingProcessStepNameType.LegalClientHeads;
  const isBeneficiary =
    currentPageName === OnboardingProcessStepNameType.LegalClientBeneficiaries;
  const isBranch =
    currentPageName === OnboardingProcessStepNameType.LegalClientBranches;

  // Head
  if (isHead && legalClient) {
    switch (innerState.state?.type) {
      case HeadPageType.person:
        return (
          <LegalClientHead
            currentClient={currentClient}
            goBack={innerState.removeState}
            id={innerState.state?.headId}
            legalClient={legalClient}
            onCreateHead={(id) => innerState.setHead(HeadPageType.person, id)}
          />
        );

      case HeadPageType.legal:
        return (
          <LegalClientLegalHead
            currentClient={currentClient}
            goBack={innerState.removeState}
            id={innerState.state?.headId!}
            legalClient={legalClient}
            onCreateLegalHead={(id) =>
              innerState.setHead(HeadPageType.legal, id)
            }
            onSetFirstHead={(id) =>
              innerState.setFirstHead(innerState.state?.headId!, id)
            }
          />
        );

      case HeadPageType.legalFirstHead:
        return (
          <LegalClientLegalHeadFirstHead
            currentClient={currentClient}
            goBack={() =>
              innerState.setHead(HeadPageType.legal, innerState.state?.headId!)
            }
            id={innerState.state?.legalFirstHeadId}
            legalClient={legalClient}
            legalHeadId={innerState.state?.headId!}
            onCreateFirstHead={(id) =>
              innerState.setFirstHead(innerState.state?.headId!, id)
            }
          />
        );

      default:
        break;
    }
  }

  // Beneficiary
  if (
    isBeneficiary &&
    legalClient &&
    innerState.state?.type === HeadPageType.beneficiary
  ) {
    return (
      <LegalClientBeneficiary
        currentClient={currentClient}
        goBack={innerState.removeState}
        id={innerState.state?.beneficiaryId!}
        legalClient={legalClient}
        onCreateBeneficiary={(id) => innerState.setBeneficiary(id)}
      />
    );
  }

  // Branch
  if (
    isBranch &&
    legalClient &&
    innerState.state?.type === HeadPageType.branch
  ) {
    return (
      <LegalClientBranch
        currentClient={currentClient}
        goBack={innerState.removeState}
        id={innerState.state?.branchId!}
        legalClient={legalClient}
        onCreateBranch={(id) => innerState.setBranch(id)}
      />
    );
  }

  return null;
};
