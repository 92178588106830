import { PropsWithChildren, useMemo } from 'react';

// eslint-disable-next-line no-restricted-imports
import { ApolloProvider, useApolloClient } from '@apollo/client';
import { config } from 'config';

import { createApolloClient } from './helpers/createApolloClient';

interface ClientProviderProps extends PropsWithChildren {
  /** Language used for api */
  language: string;
}

/**
 * Client provider
 */
export const ClientProvider = ({ language, children }: ClientProviderProps) => {
  const client = useMemo(() => {
    const client = createApolloClient(config.API_URL, language);
    return client;
  }, [language]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export const useClient = () => useApolloClient();
