import { CompanyTaxResidency } from '../components/CompanyTaxResidency/CompanyTaxResidency';
import { useCompanyTaxResidency } from '../hooks';
import { PageComponentProps } from '../types';

export const CompanyTaxResidencyStep = ({ canGoBack }: PageComponentProps) => {
  const {
    countries,
    countriesLoading,
    initialValues,
    submitLoading,
    onSubmit,
    goToBack,
  } = useCompanyTaxResidency();

  return (
    <CompanyTaxResidency
      canGoBack={canGoBack}
      countries={countries}
      countriesLoading={countriesLoading}
      initialValues={initialValues}
      submitLoading={submitLoading}
      onGoBack={goToBack}
      onSubmit={onSubmit}
    />
  );
};
