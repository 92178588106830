import {
  OrganizationType,
  OrganizationalAndLegalFormEntity,
} from 'api/types/entity';
import { YesOrNoTranslationPath } from 'helpers';
import { TFunc, TranslationPath } from 'libs/i18n';
import { YesOrNoType } from 'types/yesOrNo';

import { OrganizationalAndLegalFormFormType } from '../../forms/organizationalAndLegalFormForm';

export const OrganizationTypePath: Record<OrganizationType, TranslationPath> = {
  [OrganizationType.Commercial]:
    'onboarding.organizationalAndLegalForm.commercialType',
  [OrganizationType.NonCommercial]:
    'onboarding.organizationalAndLegalForm.noCommercialType',
};

export const getInitialData = (
  data: OrganizationalAndLegalFormEntity | undefined,
  t: TFunc
) => {
  const isFinancialInstitution = data?.isFinancialInstitution
    ? YesOrNoType.Yes
    : YesOrNoType.No;
  const isUnincorporatedStructure = data?.isUnincorporatedStructure
    ? YesOrNoType.Yes
    : YesOrNoType.No;

  const values: OrganizationalAndLegalFormFormType | undefined = data
    ? {
        organizationalAndLegalForm: data.organizationalAndLegalForm,

        organizationType: {
          label: t(OrganizationTypePath[data.organizationType]),
          value: data.organizationType,
        },

        isFinancialInstitution:
          data.organizationType === OrganizationType.Commercial
            ? {
                label: t(YesOrNoTranslationPath[isFinancialInstitution]),
                value: data.isFinancialInstitution
                  ? YesOrNoType.Yes
                  : YesOrNoType.No,
              }
            : undefined,

        isUnincorporatedStructure:
          data.organizationType === OrganizationType.Commercial
            ? {
                label: t(YesOrNoTranslationPath[isUnincorporatedStructure]),
                value: data.isUnincorporatedStructure
                  ? YesOrNoType.Yes
                  : YesOrNoType.No,
              }
            : undefined,
      }
    : undefined;

  return values;
};
