import { DebtsEntity } from 'api/types/entity';
import { yesOrNot } from 'helpers';
import { TFunc } from 'libs/i18n';
import { getBaseDebtsInitialData } from 'modules/onboarding/common/helpers/debts/getBaseDebtsInitialData';

import { DebtsFormType } from '../../forms/debtsForm';

export const getInitialData = (debts: DebtsEntity | undefined, t: TFunc) => {
  const baseDebsInitialData = getBaseDebtsInitialData(debts, t);

  const marginTrades = yesOrNot(t, debts?.hasMarginTrades);

  const values: DebtsFormType | undefined = debts
    ? {
        ...baseDebsInitialData,
        marginTrades,
      }
    : undefined;

  return values;
};
