export enum RelationType {
  not = 'Not',
  yesIAm = 'YesIAm',
  yesSpouses = 'YesSpouses',
}

export interface PepConnectionEntity {
  relation: RelationType;

  firstname?: string;
  lastname?: string;
  surname?: string;
  workName?: string;
  position?: string;
}
