import { useMemo } from 'react';

import { PasswordValidationRule } from 'api/types/data';
import {
  PasswordFormBody,
  VerificationCodeFormContentDialog,
} from 'components';
import { PasswordValidation } from 'helpers';
import { useTranslation } from 'libs/i18n';

import {
  getSchema,
  RestorePasswordFormType,
  FormErrors,
  CODE_LENGTH,
} from '../../forms/restorePasswordForm';

interface Props {
  initialValues?: RestorePasswordFormType;
  onSubmit: (value: RestorePasswordFormType) => void;
  canGoBack?: boolean;
  onGoBack?: () => void;
  initialErrors?: FormErrors;
  submitLoading?: boolean;
  passwordValidationRule?: PasswordValidationRule;
  isOpenVerificationDialog: boolean;
  onCloseVerificationDialog: () => void;
}

export const RestorePassword = ({
  initialErrors,
  initialValues,
  onSubmit,
  canGoBack,
  onGoBack,
  submitLoading,
  passwordValidationRule,
  isOpenVerificationDialog,
  onCloseVerificationDialog,
}: Props) => {
  const { t } = useTranslation();

  const passwordValidation = useMemo(
    () => PasswordValidation.parseByValidationRule(passwordValidationRule),
    [passwordValidationRule]
  );

  const schema = useMemo(
    () => getSchema(t, passwordValidation),
    [passwordValidation, t]
  );

  const handleOnSubmit = (values: RestorePasswordFormType) => {
    if (isOpenVerificationDialog) {
      onSubmit(values);
    } else {
      onSubmit({ password: values.password });
    }
  };

  return (
    <PasswordFormBody
      canGoBack={canGoBack}
      initialErrors={initialErrors}
      initialValues={initialValues}
      passwordFieldName="password"
      passwordValidation={passwordValidation}
      submitLoading={submitLoading}
      subTitle={t('auth.restorePassword.password.subtitle')}
      title={t('auth.restorePassword.password.title')}
      validationSchema={schema}
      onGoBack={onGoBack}
      onSubmit={handleOnSubmit}
    >
      <VerificationCodeFormContentDialog<RestorePasswordFormType>
        codeFieldName="otpCode"
        isOpen={isOpenVerificationDialog}
        length={CODE_LENGTH}
        onClose={onCloseVerificationDialog}
      />
    </PasswordFormBody>
  );
};
