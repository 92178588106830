export enum ClientIdentityType {
  Client = 'Client',
  ClientRepresentative = 'ClientRepresentative',
  ClientWithRepresentative = 'ClientWithRepresentative',
  LegalClientRepresentative = 'LegalClientRepresentative',
  LegalClientHead = 'LegalClientHead',
  LegalClientBeneficiary = 'LegalClientBeneficiary',
  LegalClientLegalHeadFirstHead = 'LegalClientLegalHeadFirstHead',
}

export interface ClientIdentity {
  id: string;
  type: ClientIdentityType;
}
