// eslint-disable-next-line no-restricted-imports
import {
  MutationInput_ClientController_SavePep_Input_PepConnectionType as PepConnectionTypeApi,
  SavePepDto_Input,
} from 'api/generated/graphql';
import { RelationType } from 'api/types/entity';
import { SavePepConnectionParam } from 'api/types/params';

const relationType: Record<RelationType, PepConnectionTypeApi> = {
  [RelationType.not]: PepConnectionTypeApi.None,
  [RelationType.yesSpouses]: PepConnectionTypeApi.FamilyMember,
  [RelationType.yesIAm]: PepConnectionTypeApi.Self,
};

export const parsePepConnectionParam = (
  params: SavePepConnectionParam
): SavePepDto_Input => ({
  connectedPepInfo: params.connectedPepInfo,
  pepConnectionType: relationType[params.relation],
});
