import { useTranslation } from 'libs/i18n';
import { AddAndRemoveButtons } from 'modules/onboarding/common/components/AddAndRemoveButtons';
import { useFormContext } from 'modules/onboarding/common/forms/onboardingTaxResidencyForm';

interface Props {
  index: number;
}

export const Buttons = ({ index }: Props) => {
  const { values, setValues } = useFormContext();

  const { t } = useTranslation();

  const onAdd = () => {
    values.taxValues.push({ country: null });
    setValues(values, false);
  };

  const onRemove = (index: number) => {
    values.taxValues.splice(index, 1);
    setValues(values, false);
  };

  return (
    <AddAndRemoveButtons
      addLabel={t('onboarding.taxResidency.addTaxNumber')}
      counts={values.taxValues.length}
      index={index}
      removeLabel={t('onboarding.taxResidency.removeTaxNumber')}
      onAdd={onAdd}
      onRemove={onRemove}
    />
  );
};
