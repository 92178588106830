import { useContext } from 'react';

import {
  LegalClientEntity,
  LegalClientRepresentativeEntity,
} from 'api/types/entity';
import { LegalClientLegalHeadFirstHeadEntity } from 'api/types/entity/legal/legalHeadFirstHead';
import { PersonalDataFormType } from 'modules/onboarding/common/forms/personalDataForm';
import {
  OnboardingContext,
  OnboardingContextProps,
} from 'modules/onboarding/common/providers/OnboardingProvider/Context';

export const useOnboardingContext = () => {
  const res = useContext(OnboardingContext);
  return res as OnboardingContextProps<{
    currentClient: LegalClientRepresentativeEntity;
    legalClient: LegalClientEntity;
    legalHeadId: string;
    firstHead: LegalClientLegalHeadFirstHeadEntity;
    onCreateFirstHead: (personalData: PersonalDataFormType) => Promise<boolean>;
  }>;
};
