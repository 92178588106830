import { useRef } from 'react';

import { useLegalClientHeadSaveTaxResidences } from 'api/requests';
import { useServerErrorNotify } from 'hooks';
import { OnboardingTaxResidencyFormType } from 'modules/onboarding/common/forms/onboardingTaxResidencyForm';
import {
  getInitialData,
  getSubmitData,
} from 'modules/onboarding/common/helpers/taxResidency';
import {
  useBeforeSubmitTaxResidency,
  useOnboardingCountries,
} from 'modules/onboarding/common/hooks';

import { useOnboardingContext } from './useOnboardingContext';

export const useTaxResidency = () => {
  const { goToBack, goToNext, data } = useOnboardingContext();
  const { countries, loading: countriesLoading } = useOnboardingCountries();
  const { error, loading, saveTaxResidences } =
    useLegalClientHeadSaveTaxResidences();

  const initialValues = useRef(
    getInitialData(data.legalClientHead.taxResidences)
  ).current;

  const onSubmit = useBeforeSubmitTaxResidency(
    async (values: OnboardingTaxResidencyFormType) => {
      const submitData = getSubmitData(values);
      await saveTaxResidences(
        data.legalClient.id,
        data.legalClientHead.id,
        submitData
      );
      goToNext();
    }
  );

  useServerErrorNotify(error);

  return {
    onSubmit,
    goToBack,
    submitLoading: loading,
    initialValues,
    countries,
    countriesLoading,
  };
};
