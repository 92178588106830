import {
  BusinessRelationshipGoalType,
  BusinessRelationshipNatureType,
  OperationType,
} from 'api/types/entity';
import { useTranslation } from 'libs/i18n';
import { Stack } from 'libs/ui';

import {
  useFormContext,
  Field,
  getPlannedAssetTypesOptions,
  getPlannedOperationTypesOptions,
  getBusinessRelationshipGoalTypeOption,
  getBusinessRelationshipNatureTypeOption,
} from '../../../forms/companyBusinessRelationshipForm';

export const FormContent = () => {
  const { values } = useFormContext();
  const { t } = useTranslation();

  const isOtherPlannedOperationTypes = values.plannedOperationTypes?.some(
    (source) => source.value === OperationType.Other
  );

  const isOtherBusinessRelationshipNatures =
    values.businessRelationshipNatures?.some(
      (source) => source.value === BusinessRelationshipNatureType.Other
    );

  const isOtherBusinessRelationshipGoals =
    values.businessRelationshipGoals?.some(
      (source) => source.value === BusinessRelationshipGoalType.Other
    );

  return (
    <Stack spacing={24} spacingMobile={16}>
      <Field.Autocomplete
        multiple
        label={t(
          'onboarding.companyBusinessRelationship.plannedAssetTypes.label'
        )}
        name="plannedAssetTypes"
        optionLabelKey="label"
        options={getPlannedAssetTypesOptions(t)}
        optionValueKey="value"
        placeholder={t(
          'onboarding.companyBusinessRelationship.plannedAssetTypes.placeholder'
        )}
        variant="select"
      />

      <Field.Autocomplete
        multiple
        label={t(
          'onboarding.companyBusinessRelationship.plannedOperationTypes.label'
        )}
        name="plannedOperationTypes"
        optionLabelKey="label"
        options={getPlannedOperationTypesOptions(t)}
        optionValueKey="value"
        placeholder={t(
          'onboarding.companyBusinessRelationship.plannedOperationTypes.placeholder'
        )}
        variant="select"
      />

      {isOtherPlannedOperationTypes && (
        <Field.Text
          label={t('onboarding.companyBusinessRelationship.other.label')}
          name="otherPlannedOperationType"
          placeholder={t(
            'onboarding.companyBusinessRelationship.other.placeholder'
          )}
        />
      )}

      <Field.Price
        label={t(
          'onboarding.companyBusinessRelationship.plannedAssetSize.label'
        )}
        name="plannedAssetSize"
        placeholder={t(
          'onboarding.companyBusinessRelationship.plannedAssetSize.placeholder'
        )}
      />

      <Field.Autocomplete
        multiple
        label={t(
          'onboarding.companyBusinessRelationship.businessRelationshipGoals.label'
        )}
        name="businessRelationshipGoals"
        optionLabelKey="label"
        options={getBusinessRelationshipGoalTypeOption(t)}
        optionValueKey="value"
        placeholder={t(
          'onboarding.companyBusinessRelationship.businessRelationshipGoals.placeholder'
        )}
        variant="select"
      />

      {isOtherBusinessRelationshipGoals && (
        <Field.Text
          label={t('onboarding.companyBusinessRelationship.other.label')}
          name="otherBusinessRelationshipGoal"
          placeholder={t(
            'onboarding.companyBusinessRelationship.other.placeholder'
          )}
        />
      )}

      <Field.Autocomplete
        multiple
        label={t(
          'onboarding.companyBusinessRelationship.businessRelationshipNatures.label'
        )}
        name="businessRelationshipNatures"
        optionLabelKey="label"
        options={getBusinessRelationshipNatureTypeOption(t)}
        optionValueKey="value"
        placeholder={t(
          'onboarding.companyBusinessRelationship.businessRelationshipNatures.placeholder'
        )}
        variant="select"
      />

      {isOtherBusinessRelationshipNatures && (
        <Field.Text
          label={t('onboarding.companyBusinessRelationship.other.label')}
          name="otherBusinessRelationshipNature"
          placeholder={t(
            'onboarding.companyBusinessRelationship.other.placeholder'
          )}
        />
      )}
    </Stack>
  );
};
