import { FormContainer } from 'components';
import { useTranslation } from 'libs/i18n';
import { FileType, FileUpload, Stack } from 'libs/ui';

import {
  OnboardingSignatureSamplesFormType,
  getSchema,
  FormErrors,
  Field,
  MAX_FILES,
} from '../../forms/onboardingSignatureSamplesForm';
import { DownloadContent } from '../DownloadContent';

interface Props {
  initialValues?: OnboardingSignatureSamplesFormType;
  documentSimpleHref: string;
  canGoBack?: boolean;
  formError?: FormErrors;
  submitLoading?: boolean;
  files?: FileUpload[];
  onAddFiles?: (file: File[]) => void;
  onRemoveFile?: (file: FileUpload) => void;
  onSubmit: (values: OnboardingSignatureSamplesFormType) => void;
  onGoBack?: () => void;
  subTitle?: string;
  title?: string;
  fileTypes?: FileType[];
  documentTitle?: string;
  submitDisabled?: boolean;
}

export const OnboardingSignatureSamples = ({
  initialValues,
  documentSimpleHref,
  submitLoading,
  formError,
  canGoBack,
  files,
  onAddFiles,
  onRemoveFile,
  onGoBack,
  onSubmit,
  subTitle,
  fileTypes,
  documentTitle,
  title,
  submitDisabled,
}: Props) => {
  const { t } = useTranslation();
  const schema = getSchema();
  return (
    <FormContainer
      canGoBack={canGoBack}
      initialErrors={formError}
      initialValues={initialValues}
      submitDisabled={submitDisabled}
      submitLoading={submitLoading}
      subTitle={subTitle ?? t('onboarding.signatureSamples.subtitle')}
      title={title ?? t('onboarding.signatureSamples.title')}
      validationSchema={schema}
      onGoBack={onGoBack}
      onSubmit={onSubmit}
    >
      <Stack spacing={32}>
        <DownloadContent href={documentSimpleHref} />
        <Field.FilesUpload
          files={files}
          fileTypes={fileTypes ?? [FileType.pdf]}
          maxFiles={MAX_FILES}
          name="fileIds"
          title={
            documentTitle ?? t('onboarding.signatureSamples.documentTitle')
          }
          onAddFiles={onAddFiles}
          onRemoveFile={onRemoveFile}
        />
      </Stack>
    </FormContainer>
  );
};
