import { PasswordValidation } from 'helpers/validations/password';
import {
  TypedForm,
  FormErrors as FormErrorsBase,
  password,
  ObjectSchema,
  object,
} from 'libs/form';
import { TFunc } from 'libs/i18n';

export interface RegistrationPasswordFormType {
  password: string;
}

export const getSchema = (
  t: TFunc,
  passwordValidation: PasswordValidation
): ObjectSchema<RegistrationPasswordFormType> =>
  object({
    password: password(passwordValidation, t),
  });

export const { Field, Form, useFormContext, Submit } =
  TypedForm<RegistrationPasswordFormType>();

export type FormErrors = FormErrorsBase<RegistrationPasswordFormType>;
