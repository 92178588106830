import {
  TypedForm,
  FormErrors as FormErrorsBase,
  ObjectSchema,
  object,
  string,
  date,
  array,
} from 'libs/form';
import { TFunc } from 'libs/i18n';
import { getToday } from 'modules/onboarding/common/helpers/getToday';

export interface BaseStateRegistrationFormType {
  date: Date;
  number: string;
  dateOfIssue: Date;
  nameAuthority: string;
  registrationDocumentName: string;
}

export interface CompanyStateRegistrationFormType
  extends BaseStateRegistrationFormType {
  fileIds: string[];
}
export const MAX_FILES = 10;
export const numberMaxLength = 200;
export const registrationDocumentNameMaxLength = 2000;
export const nameAuthorityMaxLength = 2000;

export const getBaseScheme = (
  t: TFunc
): ObjectSchema<BaseStateRegistrationFormType> =>
  object({
    date: date().required().max(getToday(), t('validation.maxDate')),
    registrationDocumentName: string()
      .max(registrationDocumentNameMaxLength)
      .required(),
    number: string().max(numberMaxLength).required(),
    dateOfIssue: date().required().max(getToday(), t('validation.maxDate')),
    nameAuthority: string().required().max(nameAuthorityMaxLength),
  });

export const getSchema = (
  t: TFunc
): ObjectSchema<CompanyStateRegistrationFormType> =>
  getBaseScheme(t).concat(
    object({
      date: date().required().max(getToday(), t('validation.maxDate')),
      number: string().max(numberMaxLength).required(),
      dateOfIssue: date().required().max(getToday(), t('validation.maxDate')),
      nameAuthority: string().required().max(nameAuthorityMaxLength),
      fileIds: array().of(string().required()).required().min(1).max(MAX_FILES),
    })
  );
export const { Field, Form, useFormContext, Submit } =
  TypedForm<CompanyStateRegistrationFormType>();

export type FormErrors = FormErrorsBase<CompanyStateRegistrationFormType>;
