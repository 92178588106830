import { useRef } from 'react';

import { useLegalClientSaveOrganizationalAndLegalForm } from 'api/requests';
import { useServerErrorNotify } from 'hooks';
import { useTranslation } from 'libs/i18n';

import { OrganizationalAndLegalFormFormType } from '../forms/organizationalAndLegalFormForm';
import { getInitialData } from '../helpers/organizationalAndLegalForm/getInitialData';
import { getSubmitData } from '../helpers/organizationalAndLegalForm/getSubmitData';

import { useOnboardingContext } from './useOnboardingContext';

export const useOrganizationalAndLegalForm = () => {
  const { goToBack, goToNext, data } = useOnboardingContext();

  const { error, loading, saveOrganizationalAndLegalForm } =
    useLegalClientSaveOrganizationalAndLegalForm();

  const { t } = useTranslation();

  const initialValues = useRef(
    getInitialData(data.legalClient?.organizationalAndLegalForm, t)
  ).current;

  const handleSubmit = async (values: OrganizationalAndLegalFormFormType) => {
    if (values) {
      const params = getSubmitData(values);
      const res = await saveOrganizationalAndLegalForm(
        data.legalClient!.id,
        params
      );
      if (res) {
        goToNext();
      }
    }
  };

  useServerErrorNotify(error);

  return {
    onSubmit: handleSubmit,
    goToBack,
    submitLoading: loading,
    initialValues,
  };
};
