import {
  CompanyFinancingSources,
  CompanyIncomeSources,
} from 'api/types/entity';
import { IncomeSourcesType } from 'api/types/entity/clientEntity';
import {
  TypedForm,
  FormErrors as FormErrorsBase,
  ObjectSchema,
  string,
  object,
  array,
  mixed,
} from 'libs/form';
import { TFunc } from 'libs/i18n';
import { SelectType } from 'types/selectType';

export interface CompanyFinancingSourcesFormType {
  incomeSources: SelectType<CompanyIncomeSources>[];
  otherIncomeSource?: string;
  financingSources: SelectType<CompanyFinancingSources>[];
  otherFinancingSources?: string;
  auditedFinancialReportFileIds: string[];
  recommendationLetterFileIds?: string[];
}

export const MAX_FILES = 10;
export const maxLengthOtherIncomeSource = 200;
export const maxLengthOtherFinancingSources = 150;

const isOtherIncomeSource = (values: SelectType<IncomeSourcesType>[]) =>
  values?.find((v) => v?.value === IncomeSourcesType.Other) !== undefined;

const isOtherFinancingSources = (
  values: SelectType<CompanyFinancingSources>[]
) =>
  values?.find((v) => v?.value === CompanyFinancingSources.Other) !== undefined;

export const getIncomeSourcesOptions = (t: TFunc) =>
  Object.values(CompanyIncomeSources).map((v) => ({
    label: t(`onboarding.companyFinancingSources.incomeSourcesOptions.${v}`),
    value: v,
  }));

export const getFinancingSourcesOptions = (t: TFunc) =>
  Object.values(CompanyFinancingSources).map((v) => ({
    label: t(`onboarding.companyFinancingSources.financingSourcesOptions.${v}`),
    value: v,
  }));

export const getSchema = (
  t: TFunc
): ObjectSchema<CompanyFinancingSourcesFormType> =>
  object({
    incomeSources: array()
      .of(
        object({
          value: mixed<CompanyIncomeSources>()
            .oneOf(Object.values(CompanyIncomeSources))
            .required(),
          label: string().required(),
        })
          .default(null)
          .required()
      )
      .min(1, t('validation.required'))
      .required(),
    otherIncomeSource: string().when('incomeSources', {
      is: isOtherIncomeSource,
      then: (schema) => schema.required().max(maxLengthOtherIncomeSource),
    }),
    financingSources: array()
      .of(
        object({
          value: mixed<CompanyFinancingSources>()
            .oneOf(Object.values(CompanyFinancingSources))
            .required(),
          label: string().required(),
        })
          .default(null)
          .required()
      )
      .min(1, t('validation.required'))
      .required(),
    otherFinancingSources: string().when('financingSources', {
      is: isOtherFinancingSources,
      then: (schema) => schema.required().max(maxLengthOtherFinancingSources),
    }),
    auditedFinancialReportFileIds: array()
      .of(string().required())
      .required()
      .min(1)
      .max(MAX_FILES),
    recommendationLetterFileIds: array().of(string().required()).max(MAX_FILES),
  });

export const { Field, Form, useFormContext, Submit } =
  TypedForm<CompanyFinancingSourcesFormType>();

export type FormErrors = FormErrorsBase<CompanyFinancingSourcesFormType>;
