import { DetailedHTMLProps } from 'react';

import { LinkLabel } from 'libs/ui';
// eslint-disable-next-line no-restricted-imports
import MarkdownBase from 'react-markdown';

import { MarkdownProps } from './types';

const Link = ({
  children,
  href,
}: DetailedHTMLProps<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
>) => <LinkLabel href={href} label={children as string} target="_blank" />;

/**
 * Markdown component.
 */
export const Markdown = (props: MarkdownProps) => (
  <MarkdownBase components={{ a: Link }} {...props} />
);
