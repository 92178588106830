import { useRef } from 'react';

import { useClientRepresentativeSaveBankAccounts } from 'api/requests';
import { useServerErrorNotify } from 'hooks';
import { OnboardingAccountsInOrganizationsFormType } from 'modules/onboarding/common/forms/onboardingAccountsInOrganizationsForm';
import {
  getInitialData,
  getSubmitData,
} from 'modules/onboarding/common/helpers/accountsInOrganizations';
import { useOnboardingCountries } from 'modules/onboarding/common/hooks';

import { useOnboardingContext } from './useOnboardingContext';

export const useAccountsInOrganizations = () => {
  const {
    loading: submitLoading,
    error,
    saveBankAccounts,
  } = useClientRepresentativeSaveBankAccounts();

  const { data, goToNext, goToBack, clientId } = useOnboardingContext();

  const { countries, loading: countriesLoading } = useOnboardingCountries();

  const initialValues = useRef({
    accounts: getInitialData(data.currentClient.bankAccounts),
  }).current;

  const onSubmit = async (
    values: OnboardingAccountsInOrganizationsFormType
  ) => {
    const submitData = getSubmitData(values);
    const res = await saveBankAccounts(clientId, submitData);

    if (res) {
      goToNext();
    }
  };

  useServerErrorNotify(error);

  return {
    onSubmit,
    goToBack,
    submitLoading,
    initialValues,
    countries,
    countriesLoading,
  };
};
