import { LegalClientBusinessRelationshipEntity } from 'api/types/entity';
import { TFunc } from 'libs/i18n';

import {
  CompanyBusinessRelationshipFormType,
  getBusinessRelationshipGoalTypeOption,
  getBusinessRelationshipNatureTypeOption,
  getPlannedAssetTypesOptions,
  getPlannedOperationTypesOptions,
} from '../../forms/companyBusinessRelationshipForm';

export const getInitialData = (
  t: TFunc,
  data?: LegalClientBusinessRelationshipEntity
): CompanyBusinessRelationshipFormType | undefined => {
  if (!data) {
    return undefined;
  }

  return {
    plannedAssetTypes: getPlannedAssetTypesOptions(t).filter(({ value }) =>
      data.plannedAssetTypes.some((v) => v === value)
    ),

    businessRelationshipGoals: getBusinessRelationshipGoalTypeOption(t).filter(
      ({ value }) => data.businessRelationshipGoals.some((v) => v === value)
    ),

    businessRelationshipNatures: getBusinessRelationshipNatureTypeOption(
      t
    ).filter(({ value }) =>
      data.businessRelationshipNatures.some((v) => v === value)
    ),

    plannedOperationTypes: getPlannedOperationTypesOptions(t).filter(
      ({ value }) => data.plannedOperationTypes.some((v) => v === value)
    ),

    otherBusinessRelationshipGoal: data.otherBusinessRelationshipGoal,
    otherBusinessRelationshipNature: data.otherBusinessRelationshipNature,
    otherPlannedOperationType: data.otherPlannedOperationType,

    plannedAssetSize: data.plannedAssetSize,
  };
};
