import { getColor } from 'helpers';
import { CSSObject } from 'libs/ui/styled';
import { ColorsDark, ColorsLight, Theme } from 'libs/ui/theme';

const getColorPrimary = (theme: Theme) => ({
  bgColor: getColor(theme, {
    dark: ColorsDark.gray50,
    light: ColorsLight.gray50,
  }),
  borderColor: getColor(theme, {
    dark: ColorsDark.gray150,
    light: ColorsLight.gray150,
  }),
  textColor: getColor(theme, {
    dark: ColorsDark.white,
    light: ColorsLight.black,
  }),

  hoverBgColor: getColor(theme, {
    dark: ColorsDark.gray150,
    light: ColorsLight.gray150,
  }),
  hoverBorderColor: getColor(theme, {
    dark: ColorsDark.gray300,
    light: ColorsLight.blue100,
  }),
  hoverTextColor: getColor(theme, {
    dark: ColorsDark.gray300,
    light: ColorsLight.gray300,
  }),

  focusBgColor: getColor(theme, {
    dark: ColorsDark.gray150,
    light: ColorsLight.gray150,
  }),
  focusBorderColor: getColor(theme, {
    dark: ColorsDark.gray150,
    light: ColorsLight.gray150,
  }),
  focusTextColor: getColor(theme, {
    dark: ColorsDark.white,
    light: ColorsLight.black,
  }),

  disableBorderColor: getColor(theme, {
    dark: ColorsDark.gray200,
    light: ColorsLight.gray200,
  }),
  disableBgColor: getColor(theme, {
    dark: ColorsDark.gray200,
    light: ColorsLight.gray200,
  }),
  disableTextColor: getColor(theme, {
    dark: ColorsDark.gray250,
    light: ColorsLight.gray150,
  }),
});

export const getSmallMode = (theme: Theme): CSSObject => {
  const colors = getColorPrimary(theme);

  return {
    ...theme.typography.captionBold,
    borderRadius: 8,
    border: `1px solid ${colors.borderColor};`,
    background: colors.bgColor,
    height: 30,
    paddingLeft: 12,
    paddingRight: 12,
    color: colors.textColor,

    '&:hover': {
      borderColor: colors.hoverBorderColor,
      backgroundColor: colors.hoverBgColor,
      color: colors.hoverTextColor,
    } as CSSObject,
    '&:active': {
      borderColor: colors.focusBorderColor,
      backgroundColor: colors.focusBgColor,
      color: colors.focusTextColor,
    } as CSSObject,
    '&:disabled': {
      borderColor: colors.disableBorderColor,
      backgroundColor: colors.disableBgColor,
      color: colors.disableTextColor,
    } as CSSObject,
  };
};
