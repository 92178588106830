import { FileEntity } from '../fileEntity';

export enum RoleInCompanyType {
  Director = 'Director',
  OneOfDirectors = 'OneOfDirectors',
  AuthorizedRepresentative = 'AuthorizedRepresentative',
}

export enum CivilLawSubjectType {
  PhysicalPerson = 'PhysicalPerson',
  JuridicalPerson = 'JuridicalPerson',
}

export interface RoleInCompanyEntity {
  role: RoleInCompanyType;

  civilLawSubject?: CivilLawSubjectType;

  authorityConfirmationDocument: {
    number?: string;
    dateOfIssue: Date;
    dateOfExpiration: Date;
    files: FileEntity[];
  };
}
