import { getColor } from 'helpers';
import { styled } from 'libs/ui/styled';
import { ColorsDark, ColorsLight } from 'libs/ui/theme';

export const ContainerStyled = styled('div')(({ theme }) => ({
  borderColor: getColor(theme, {
    light: ColorsLight.blue150,
    dark: ColorsDark.blue250,
  }),
  borderWidth: 1,
  borderStyle: 'solid',
  borderRadius: 12,
  padding: 20,

  [theme.breakpoints.down('mobile')]: {
    padding: 12,
  },
}));
