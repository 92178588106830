import { CountryEntity } from 'api/types/entity';
import { useTranslation } from 'libs/i18n';
import { Field } from 'modules/onboarding/common/forms/onboardingTaxResidencyForm';

interface Props {
  index: number;
  countriesLoading?: boolean;
  countries: CountryEntity[];
}

export const Fields = ({ index, countriesLoading, countries }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <Field.Autocomplete
        label={t('onboarding.taxResidency.country.label')}
        loading={countriesLoading}
        name={`taxValues.${index}.country` as 'taxValues'}
        optionLabelKey="name"
        options={countries}
        optionValueKey="id"
        placeholder={t('onboarding.taxResidency.country.placeholder')}
      />

      <Field.Text
        label={t('onboarding.taxResidency.taxNumber.label')}
        name={`taxValues.${index}.taxNumber` as 'taxValues'}
        placeholder={t('onboarding.taxResidency.taxNumber.placeholder')}
      />
    </>
  );
};
