import { useRef } from 'react';

import { useLegalClientHeadSaveAddresses } from 'api/requests';
import { useFilesUpload, useServerErrorNotify } from 'hooks';
import { BaseOnboardingRegistrationAddressFormType } from 'modules/onboarding/common/forms/onboardingRegistrationAddressForm';
import {
  getBaseInitialData,
  getBaseSubmitData,
} from 'modules/onboarding/common/helpers/registrationAddress';
import { useOnboardingCountries } from 'modules/onboarding/common/hooks';

import { useOnboardingContext } from './useOnboardingContext';

export const useRegistrationAddress = () => {
  const { goToBack, goToNext, data } = useOnboardingContext();

  const { countries, loading: countriesLoading } = useOnboardingCountries();
  const { error, loading, saveAddresses } = useLegalClientHeadSaveAddresses();

  const initialData = useRef(
    data.legalClientHead.registrationAddress
      ? getBaseInitialData(
          data.legalClientHead.registrationAddress,
          data.legalClientHead.residentialAddress
        )
      : undefined
  ).current;

  const initialValues = useRef(initialData?.values).current;

  const {
    files,
    isLoadingFile: submitDisabled,
    onRemoveFile,
    onAddFiles,
  } = useFilesUpload(initialData?.files);

  const onSubmit = async (
    values: BaseOnboardingRegistrationAddressFormType
  ) => {
    if (values) {
      const { registrationAddress, residentialAddress } = getBaseSubmitData(
        values,
        true
      );
      await saveAddresses(data.legalClient.id, data.legalClientHead.id, {
        registrationAddress,
        residentialAddress,
      });
      goToNext();
    }
  };

  useServerErrorNotify(error);

  return {
    initialValues,
    countries,
    countriesLoading,
    submitLoading: loading,
    files,
    submitDisabled,
    onSubmit,
    goToBack,
    onAddFiles,
    onRemoveFile,
  };
};
