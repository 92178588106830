import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import {
  OnboardingLegalClientDocument,
  useLegalClientCreateHeadMutation,
} from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { LegalClientCreateHeadSaveParam } from 'api/types/params';
import { formatToISODate } from 'helpers';

import { parseLegalClientHeadEntity } from './helpers';

export const useLegalClientCreateHead = () => {
  const [handle, { data, loading, error: apolloError }] =
    useLegalClientCreateHeadMutation({
      refetchQueries: [OnboardingLegalClientDocument],
      awaitRefetchQueries: true,
    });

  const error = useError(apolloError);

  return {
    data: data?.LegalClientCreateHead
      ? parseLegalClientHeadEntity(data.LegalClientCreateHead)
      : undefined,
    loading,
    error,
    createHead: useCallback(
      async (id: string, param: LegalClientCreateHeadSaveParam) => {
        const res = await handle({
          variables: {
            id,
            input: {
              dateOfBirth: formatToISODate(param.dateOfBirth),
              email: param.email,
              firstName: param.firstName,
              lastName: param.lastName,
              middleName: param.middleName,
            },
          },
        });
        return res.data?.LegalClientCreateHead
          ? parseLegalClientHeadEntity(res.data.LegalClientCreateHead)
          : undefined;
      },
      [handle]
    ),
  };
};
