import { FinancingSourcesFormType } from 'modules/onboarding/common/forms/financingSourcesForm';
import { useClientFinancingSources } from 'modules/onboarding/common/hooks';

import { useOnboardingContext } from './useOnboardingContext';

export const useOwnerFinancingSources = () => {
  const { goToBack, goToNext, data } = useOnboardingContext();

  const {
    files,
    initialValues,
    onAddFiles,
    onRemoveFile,
    saveFinancingSources,
    submitDisabled,
    submitLoading,
  } = useClientFinancingSources(data.ownerClient!);

  const onSubmit = async (values: FinancingSourcesFormType) => {
    if (values) {
      await saveFinancingSources(values);
      goToNext();
    }
  };

  return {
    onSubmit,
    goToBack,
    files,
    onAddFiles,
    onRemoveFile,
    submitDisabled,
    submitLoading,
    initialValues,
  };
};
