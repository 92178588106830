// eslint-disable-next-line no-restricted-imports
import {
  Query_ClientController_GetById_PepConnectionType as ClientPepConnectionTypeApi,
  Query_ClientController_GetById_Representative_PepConnectionType as ClientRepresentativePepConnectionTypeApi,
  Query_LegalClientRepresentativeController_GetById_PepConnectionType as LegalClientRepresentativePepConnectionTypeApi,
  Query_LegalClientController_GetHead_PepConnectionType as LegalClientHeadPepConnectionTypeApi,
  Query_LegalClientController_GetBeneficiary_PepConnectionType as LegalClientBeneficiariesPepConnectionTypeApi,
  Query_LegalClientController_GetLegalHeadFirstHead_PepConnectionType as LegalClientFirstHeadPepConnectionTypeApi,
  PepInfoDto,
} from 'api/generated/graphql';
import { PepConnectionEntity, RelationType } from 'api/types/entity';

type PepConnectionTypeApi =
  | ClientPepConnectionTypeApi
  | ClientRepresentativePepConnectionTypeApi
  | LegalClientHeadPepConnectionTypeApi
  | LegalClientRepresentativePepConnectionTypeApi
  | LegalClientBeneficiariesPepConnectionTypeApi
  | LegalClientFirstHeadPepConnectionTypeApi;

const relationType: Record<PepConnectionTypeApi, RelationType> = {
  [ClientPepConnectionTypeApi.None]: RelationType.not,
  [ClientPepConnectionTypeApi.FamilyMember]: RelationType.yesSpouses,
  [ClientPepConnectionTypeApi.Self]: RelationType.yesIAm,
};

export interface ParsePepConnectionEntityParams {
  pepConnectionType?: PepConnectionTypeApi | null;
  connectedPepInfo?: PepInfoDto | null;
}

export const parsePepConnectionEntity = (
  data: ParsePepConnectionEntityParams
): PepConnectionEntity | undefined =>
  data.pepConnectionType
    ? {
        relation: relationType[data.pepConnectionType],
        firstname: data.connectedPepInfo?.firstName,
        lastname: data.connectedPepInfo?.lastName,
        position: data.connectedPepInfo?.employerName,
        surname: data.connectedPepInfo?.middleName || undefined,
        workName: data.connectedPepInfo?.jobTitle,
      }
    : undefined;
