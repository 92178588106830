import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useClientRepresentativeSaveCitizenshipsMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { SaveCitizenshipParam } from 'api/types/params/onboarding';

import { parseClientRepresentative } from './helpers/parseClientRepresentative';

export const useClientRepresentativeSaveCitizenships = () => {
  const [handle, { data, loading, error: apolloError }] =
    useClientRepresentativeSaveCitizenshipsMutation();

  const error = useError(apolloError);

  return {
    data: data?.clientRepresentativeSaveCitizenships
      ? parseClientRepresentative(data.clientRepresentativeSaveCitizenships)
      : undefined,
    loading,
    error,
    saveCitizenships: useCallback(
      async (id: string, params: SaveCitizenshipParam) => {
        const res = await handle({
          variables: {
            id,
            input: params,
          },
        });
        return res.data?.clientRepresentativeSaveCitizenships
          ? parseClientRepresentative(
              res.data.clientRepresentativeSaveCitizenships
            )
          : undefined;
      },
      [handle]
    ),
  };
};
