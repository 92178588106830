import { FC, PropsWithChildren } from 'react';

import { LogoutButtonFeature } from 'feature';
import { Stack, Switch } from 'libs/ui';
import { Icon } from 'libs/ui/Icon';
import { useTheme } from 'libs/ui/theme';

import { WrapperStyled } from '../Wrapper.styles';

import { HeaderStyled } from './Header.styles';

export interface HeaderProps {
  className?: string;
}

export const Header: FC<HeaderProps & PropsWithChildren> = ({ className }) => {
  const { mode, toggleColorMode } = useTheme();
  return (
    <HeaderStyled className={className}>
      <WrapperStyled className="header-wrapper">
        <div className="header-logo">
          <Icon.Logo />
        </div>
        <div className="header-right">
          <Stack alignItems="center" direction="row" spacing={16}>
            <Switch
              checked={mode === 'dark'}
              className="header-themeSwitch"
              datax="themeSwitch"
              size="medium"
              thumb={<Icon.Moon />}
              thumbChecked={<Icon.Sun />}
              onChange={toggleColorMode}
            />
            <LogoutButtonFeature />
          </Stack>
        </div>
      </WrapperStyled>
    </HeaderStyled>
  );
};
