import { useEffect } from 'react';

import { ServerError } from 'api/types/error';
import { useNotify } from 'libs/notify';

export const useServerErrorNotify = (error?: ServerError) => {
  const notify = useNotify();
  useEffect(() => {
    if (error) {
      notify.error(error);
    }
  }, [error, notify]);
};
