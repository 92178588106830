import {
  TypedForm,
  FormErrors as FormErrorsBase,
  ObjectSchema,
  string,
  object,
} from 'libs/form';

export interface BaseNameFormType {
  fullName: string;
}

export interface CompanyNameFormType extends BaseNameFormType {
  shortName?: string;
  fullNameEng?: string;
  shortNameEng?: string;
}

export const maxLengthName = 200;

export const getBaseSchema = (): ObjectSchema<BaseNameFormType> =>
  object({
    fullName: string().max(maxLengthName).required(),
  });

export const getSchema = (): ObjectSchema<CompanyNameFormType> =>
  getBaseSchema().concat(
    object({
      shortName: string().max(maxLengthName),
      fullNameEng: string().max(maxLengthName),
      shortNameEng: string().max(maxLengthName),
    })
  );

export const { Field, Form, useFormContext, Submit } =
  TypedForm<CompanyNameFormType>();

export type FormErrors = FormErrorsBase<CompanyNameFormType>;
