export enum HeadPageType {
  person = 'person',
  legal = 'legal',
  legalFirstHead = 'legalFirstHead',
  beneficiary = 'beneficiary',
  branch = 'branch',
}

export interface StateValue {
  headId?: string;
  legalFirstHeadId?: string;
  beneficiaryId?: string;
  type: HeadPageType;
  branchId?: string;
}

export interface InnerState {
  state: StateValue | undefined;

  setCreateFirstHead: () => void;
  removeState: () => void;
  setFirstHead: (headId: string, legalFirstHeadId?: string) => void;
  setHead: (
    type: HeadPageType.legal | HeadPageType.person,
    headId?: string
  ) => void;
  setBeneficiary: (beneficiaryId?: string) => void;
  setBranch: (branchId?: string) => void;
}
