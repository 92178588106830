import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useSendLoginEmailToClientMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';

export const useSendLoginEmailToClient = () => {
  const [handle, { data, loading, error: apolloError }] =
    useSendLoginEmailToClientMutation();

  const error = useError(apolloError);

  return {
    data: { resendTime: data?.sendLoginEmailToClient?.resendTime },
    loading,
    error,
    sendLoginEmail: useCallback(
      async (id: string) => {
        const res = await handle({ variables: { id } });
        return { resendTime: res.data?.sendLoginEmailToClient?.resendTime };
      },
      [handle]
    ),
  };
};
