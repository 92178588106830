import { subYears } from 'date-fns';
import { isValidName } from 'helpers';
import {
  TypedForm,
  FormErrors as FormErrorsBase,
  ObjectSchema,
  string,
  date,
  object,
  email,
} from 'libs/form';
import { TFunc } from 'libs/i18n';

export const dateOfBirthMaxDate = subYears(new Date(), 18);
export const dateOfBirthMinDate = subYears(new Date(), 100);

export interface PersonalDataFormType {
  email: string;
  firstName: string;
  lastName: string;
  middleName?: string;
  dateOfBirth: Date;
}

export const getSchema = (t: TFunc): ObjectSchema<PersonalDataFormType> =>
  object({
    email: email(t).required(),
    firstName: string()
      .max(20)
      .required()
      .test('ischars', t('validation.noSpecialCharsAndNumber'), isValidName),
    lastName: string()
      .max(20)
      .required()
      .test('ischars', t('validation.noSpecialCharsAndNumber'), isValidName),
    middleName: string()
      .max(20)
      .test('ischars', t('validation.noSpecialCharsAndNumber'), isValidName),
    dateOfBirth: date()
      .min(dateOfBirthMinDate, t('onboarding.personalData.dateOfBirthMaxError'))
      .max(dateOfBirthMaxDate, t('onboarding.personalData.dateOfBirthMinError'))
      .required(),
  });

export const { Field, Form, useFormContext, Submit } =
  TypedForm<PersonalDataFormType>();

export type FormErrors = FormErrorsBase<PersonalDataFormType>;
