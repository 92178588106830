// eslint-disable-next-line no-restricted-imports
import { useLegalClientBeneficiaryQuery } from 'api/generated/graphql';
import { useError } from 'api/helpers';

import { parseLegalClientBeneficiaryEntity } from './helpers';

export const useLegalClientBeneficiary = (beneficiaryId: string) => {
  const {
    data,
    loading,
    error: apolloError,
    refetch,
  } = useLegalClientBeneficiaryQuery({ variables: { id: beneficiaryId } });

  const error = useError(apolloError);

  return {
    beneficiary: data?.legalClientBeneficiary
      ? parseLegalClientBeneficiaryEntity(data.legalClientBeneficiary)
      : undefined,
    loading,
    refetch,
    error,
  };
};
