import { useOnboardingClient } from 'api/requests';
import { useInterval } from 'hooks';
import { OnboardingStatus } from 'modules/onboarding/common/components';
import { STATUS_UPDATE_INTERVAL } from 'modules/onboarding/common/constants';

import { useClientWithRepresentativeOnboardingStatus, useKyc } from '../hooks';
import { ClientWithRepresentativeView } from '../views';

interface Props {
  clientId: string;
}

export const ClientWithRepresentativeContainer = ({ clientId }: Props) => {
  const { expirationHandler, loading, token } = useKyc(clientId);

  const { currentClient, refetch: refetchClient } =
    useOnboardingClient(clientId);

  const onboardingStatus =
    useClientWithRepresentativeOnboardingStatus(currentClient);

  useInterval(
    () => {
      refetchClient();
    },
    onboardingStatus ? STATUS_UPDATE_INTERVAL : null
  );

  if (onboardingStatus) {
    return <OnboardingStatus onboardingStatus={onboardingStatus} />;
  }

  return (
    <ClientWithRepresentativeView
      expirationHandler={expirationHandler}
      loading={loading}
      token={token}
    />
  );
};
