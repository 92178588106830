import { useRef } from 'react';

import { useLegalClientSaveRegistrationPlace } from 'api/requests';
import { useServerErrorNotify } from 'hooks';
import { useOnboardingCountries } from 'modules/onboarding/common/hooks';

import { CompanyRegistrationPlaceFormType } from '../forms/companyRegistrationPlaceForm';
import {
  getSubmitData,
  getInitialData,
} from '../helpers/companyRegistrationPlace';

import { useOnboardingContext } from './useOnboardingContext';

export const useCompanyRegistrationPlace = () => {
  const { goToBack, goToNext, data } = useOnboardingContext();
  const { error, loading, saveRegistrationPlace } =
    useLegalClientSaveRegistrationPlace();

  const { countries, loading: countriesLoading } = useOnboardingCountries();

  const initialValues = useRef(
    getInitialData(data.legalClient?.registrationPlace)
  ).current;

  const onSubmit = async (values: CompanyRegistrationPlaceFormType) => {
    if (values) {
      const submitData = getSubmitData(values);
      const res = await saveRegistrationPlace(data.legalClient!.id, submitData);

      if (res) {
        goToNext();
      }
    }
  };

  useServerErrorNotify(error);

  return {
    onSubmit,
    goToBack,
    submitLoading: loading,
    initialValues,
    countries,
    countriesLoading,
  };
};
