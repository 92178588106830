import { useRef } from 'react';

import { useLegalClientLegalHeadSaveStateRegistration } from 'api/requests';
import { useFilesUpload, useServerErrorNotify } from 'hooks';
import { useOnboardingCountries } from 'modules/onboarding/common/hooks';
import { CompanyStateRegistrationFormType } from 'modules/onboarding/legal/forms/companyStateRegistrationForm';
import {
  getInitialData,
  getSubmitData,
} from 'modules/onboarding/legal/helpers/stateRegistration';

import { useOnboardingContext } from './useOnboardingContext';

export const useCompanyStateRegistration = () => {
  const { saveStateRegistration, error, loading } =
    useLegalClientLegalHeadSaveStateRegistration();

  const { goToBack, goToNext, data } = useOnboardingContext();

  const { filesUpload, values: initialValues } = useRef(
    getInitialData(data.legalHead.stateRegistration)
  ).current;

  const {
    files,
    isLoadingFile: submitDisabled,
    onRemoveFile,
    onAddFiles,
  } = useFilesUpload(filesUpload);

  const { countries, loading: countriesLoading } = useOnboardingCountries();

  const onSubmit = async (values: CompanyStateRegistrationFormType) => {
    const submitData = getSubmitData(values);
    const res = await saveStateRegistration(
      data.legalClient.id,
      data.legalHead.id,
      submitData
    );
    if (res) {
      goToNext();
    }
  };

  useServerErrorNotify(error);

  return {
    onSubmit,
    goToBack,
    submitLoading: loading,
    initialValues,
    submitDisabled,
    countries,
    countriesLoading,
    files,
    onAddFiles,
    onRemoveFile,
  };
};
