import { useMemo } from 'react';

import { LegalClientInnerItemEntity } from 'api/types/entity/legal/legalClientInnerItemEntity';
import { FormContainer } from 'components';
import { useTranslation } from 'libs/i18n';
import { Button } from 'libs/ui';
import { useDialog } from 'libs/ui/Dialog/useDialog';
import { Icon } from 'libs/ui/Icon';

import { DATAX_ADD_ITEM } from '../../../common/constants';
import { HeadPageType } from '../../hooks/useOnboarding/type';
import { UserList } from '../UserList';

import { HeadAddDialog } from './HeadAddDialog';

interface CompanyHeadsProps {
  heads: LegalClientInnerItemEntity[];
  legalHeads: LegalClientInnerItemEntity[];
  canGoBack?: boolean;
  submitLoading?: boolean;
  deleteLoading?: boolean;
  errorHeadsIds?: string[];
  onGoBack?: () => void;
  onAdd: (type: HeadPageType.legal | HeadPageType.person) => void;
  onEdit: (id: string) => void;
  onDelete: (id: string) => void;
  onSubmit: () => void;
}

export const CompanyHeads = ({
  canGoBack,
  submitLoading,
  heads,
  legalHeads,
  deleteLoading,
  errorHeadsIds,
  onGoBack,
  onEdit,
  onAdd,
  onDelete,
  onSubmit,
}: CompanyHeadsProps) => {
  const { t } = useTranslation();

  const { isOpen, onClose, open } = useDialog();

  const allHeads = useMemo(() => {
    const type = t('onboarding.legalClientHeads.userType');

    const result = heads
      .map((h) => ({
        id: h.id,
        name: h.name,
        type,
        createdAt: h.createdAt,
      }))
      .concat(
        legalHeads.map((h) => ({
          id: h.id,
          name: h.name,
          type,
          createdAt: h.createdAt,
        }))
      );

    result.sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime());

    return result;
  }, [heads, legalHeads, t]);

  return (
    <FormContainer
      canGoBack={canGoBack}
      submitLoading={submitLoading}
      subTitle={t('onboarding.legalClientHeads.subtitle')}
      title={t('onboarding.legalClientHeads.title')}
      onGoBack={onGoBack}
      onSubmit={onSubmit}
    >
      <UserList
        deleteLoading={deleteLoading}
        errorIds={errorHeadsIds}
        errorText={t('onboarding.legalClientHeads.filledErrorMessage')}
        users={allHeads}
        onDelete={onDelete}
        onEdit={onEdit}
      />
      <Button
        fullWidth
        datax={DATAX_ADD_ITEM}
        label={t('onboarding.legalClientHeads.addButton')}
        mode="medium"
        startIcon={<Icon.Plus />}
        onClick={open}
      />

      <HeadAddDialog
        isOpen={isOpen}
        onClickLegal={() => onAdd(HeadPageType.legal)}
        onClickPerson={() => onAdd(HeadPageType.person)}
        onClose={onClose}
      />
    </FormContainer>
  );
};
