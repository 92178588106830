import { useCallback, useMemo } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useOnboardingClientUserLazyQuery } from 'api/generated/graphql';
import { useError } from 'api/helpers';

import { parseClientUserEntity } from '../helpers';

export const useOnboardingClientUserLazy = () => {
  const [handle, { data, loading, error: apolloError, refetch }] =
    useOnboardingClientUserLazyQuery({
      notifyOnNetworkStatusChange: true,
    });

  const getClientUser = useCallback(
    async (id: string) => {
      const res = await handle({ variables: { id } });

      return res?.data?.onboardingClientUser
        ? parseClientUserEntity(res?.data?.onboardingClientUser)
        : null;
    },
    [handle]
  );

  const clientUser = useMemo(
    () =>
      data?.onboardingClientUser
        ? parseClientUserEntity(data?.onboardingClientUser)
        : null,
    [data?.onboardingClientUser]
  );

  const error = useError(apolloError);

  return {
    getClientUser,
    refetch,
    clientUser,
    loading,
    error,
  };
};
