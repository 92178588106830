import { LegalClientSaveBankAccountsParam } from 'api/types/params/onboarding/legalClientSaveBankAccountsParam';
import { lodash } from 'helpers';
import { YesOrNoType } from 'types/yesOrNo';

import { CompanyAccountsInOrganizationsFormType } from '../../forms/companyAccountsInOrganizationsForm';

export const getSubmitData = (
  values: CompanyAccountsInOrganizationsFormType
): LegalClientSaveBankAccountsParam => ({
  bankAccounts: lodash.compact(
    values.accounts.map((v) => ({
      swiftCode: v.bic!,
      country: v.country?.id!,
      hasPermanentManagementInCountry:
        v.hasPermanentManagementInCountry?.value === YesOrNoType.Yes,
      onTerritoryWithNoAML_CFT:
        v.onTerritoryWithNoAML_CFT?.value === YesOrNoType.Yes,
    }))
  ),
});
