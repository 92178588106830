import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import {
  OnboardingLegalClientDocument,
  useLegalClientBeneficiarySavePersonalDataMutation,
} from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { LegalClientCreateHeadSaveParam } from 'api/types/params';
import { formatToISODate } from 'helpers';

import { parseLegalClientBeneficiaryEntity } from './helpers';

export const useLegalClientBeneficiarySavePersonalData = () => {
  const [handle, { data, loading, error: apolloError }] =
    useLegalClientBeneficiarySavePersonalDataMutation({
      refetchQueries: [OnboardingLegalClientDocument],
      awaitRefetchQueries: true,
    });

  const error = useError(apolloError);

  return {
    data: data?.legalClientBeneficiarySavePersonalData
      ? parseLegalClientBeneficiaryEntity(
          data.legalClientBeneficiarySavePersonalData
        )
      : undefined,
    loading,
    error,
    savePersonalData: useCallback(
      async (
        id: string,
        beneficiaryId: string,
        param: LegalClientCreateHeadSaveParam
      ) => {
        const res = await handle({
          variables: {
            id,
            beneficiaryId,
            input: {
              dateOfBirth: formatToISODate(param.dateOfBirth),
              email: param.email,
              firstName: param.firstName,
              lastName: param.lastName,
              middleName: param.middleName,
            },
          },
        });
        return res?.data?.legalClientBeneficiarySavePersonalData
          ? parseLegalClientBeneficiaryEntity(
              res.data.legalClientBeneficiarySavePersonalData
            )
          : undefined;
      },
      [handle]
    ),
  };
};
