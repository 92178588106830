import { LegalClientSaveStateRegistrationParam } from 'api/types/params/onboarding/legalClientSaveStateRegistrationParam';
import { LegalClientSaveBaseStateRegistrationParam } from 'api/types/params/onboarding/saveBaseStateRegistrationParam';
import { formatToISODate } from 'helpers';

import {
  BaseStateRegistrationFormType,
  CompanyStateRegistrationFormType,
} from '../../forms/companyStateRegistrationForm';

export const getBaseSubmitData = (
  values: BaseStateRegistrationFormType
): LegalClientSaveBaseStateRegistrationParam => ({
  initialStateRegistrationDate: formatToISODate(values.date),
  registrationAuthorityName: values.nameAuthority,
  registrationNumber: values.number,
  registrationDocumentName: values.registrationDocumentName,
  stateRegistrationConfirmationDocument: {
    dateOfIssue: formatToISODate(values.dateOfIssue),
  },
});

export const getSubmitData = (
  values: CompanyStateRegistrationFormType
): LegalClientSaveStateRegistrationParam => {
  const baseData = getBaseSubmitData(values);
  return {
    ...baseData,
    stateRegistrationConfirmationDocument: {
      ...baseData.stateRegistrationConfirmationDocument,
      fileIds: values.fileIds,
    },
  };
};
