import { useRef } from 'react';

import { ContentStepperStep } from 'components';
import { useTranslation } from 'libs/i18n';
import { usePageTitle } from 'libs/navigation';

import { RestorePasswordStepper } from '../components/RestorePasswordStepper';
import { useRestorePasswordUrlData } from '../hooks';
import {
  ConfirmEmailLinkStep,
  ConfirmEmailStep,
  RestorePasswordStep,
} from '../steps';

export const RestorePasswordContainer = () => {
  const { t } = useTranslation();
  const { urlData } = useRestorePasswordUrlData();

  usePageTitle(t('auth.restorePassword.pageTitle'));

  const steps: ContentStepperStep[] = useRef([
    {
      component: urlData ? ConfirmEmailLinkStep : ConfirmEmailStep,
      canGoBack: true,
    },
    {
      component: RestorePasswordStep,
      canGoBack: false,
    },
  ]).current;

  return <RestorePasswordStepper steps={steps} />;
};
