import { useContext } from 'react';

import {
  LegalClientEntity,
  LegalClientRepresentativeEntity,
  LegalClientBranchEntity,
} from 'api/types/entity';
import {
  OnboardingContext,
  OnboardingContextProps,
} from 'modules/onboarding/common/providers/OnboardingProvider/Context';

import { NameFormType } from '../forms/nameForm';

export const useOnboardingContext = () => {
  const res = useContext(OnboardingContext);
  return res as OnboardingContextProps<{
    currentClient: LegalClientRepresentativeEntity;
    legalClient: LegalClientEntity;
    legalClientBranch: LegalClientBranchEntity;
    onCreateBranch: (name: NameFormType) => Promise<boolean>;
  }>;
};
