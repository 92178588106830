import { TaxResidencesEntity } from 'api/types/entity';
import { Maybe } from 'types/maybe';

import { CompanyTaxResidencyFormType } from '../../forms/companyTaxResidencyForm';

export const getInitialData = (
  taxResidences?: Maybe<TaxResidencesEntity[]>
): CompanyTaxResidencyFormType => ({
  taxValues: taxResidences?.map((v) => ({
    country: v.country || null,
    taxNumber: v.taxId || '',
  })) || [{ country: null, taxNumber: '' }],
});
