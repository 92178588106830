// eslint-disable-next-line no-restricted-imports
import { ConstitutionalDocumentFragment } from 'api/generated/graphql';
import { LegalClientConstitutionalDocumentEntity } from 'api/types/entity';
import { lodash } from 'helpers';
import { Maybe } from 'types/maybe';

import { parseFileEntity } from '../../helpers';

export const parseLegalClientConstitutionalDocument = (
  constitutionalDocument: Maybe<ConstitutionalDocumentFragment>
): LegalClientConstitutionalDocumentEntity | undefined => {
  if (!constitutionalDocument) {
    return undefined;
  }

  return {
    dateOfLastChange: new Date(constitutionalDocument.dateOfLastChange),
    files: lodash.compact(constitutionalDocument.files).map(parseFileEntity),
  };
};
