import { useMemo } from 'react';

import { EmailFormBody } from 'components';
import { useTranslation } from 'libs/i18n';
import { getSchema } from 'modules/auth/registration/forms/registrationEmailForm';

import { ConfirmEmailFormType, FormErrors } from '../../forms/confirmEmailForm';

type Props = {
  initialValues?: ConfirmEmailFormType;
  onSendEmailCode: (email: string) => void;
  onSubmit: (value: ConfirmEmailFormType) => void;
  canGoBack?: boolean;
  onGoBack?: () => void;
  initialErrors?: FormErrors;
  resendTime?: number;
  submitLoading?: boolean;
};

export const ConfirmEmail = ({
  initialErrors,
  onSendEmailCode,
  initialValues,
  onSubmit,
  canGoBack,
  onGoBack,
  resendTime,
  submitLoading,
}: Props) => {
  const { t } = useTranslation();
  const schema = useMemo(() => getSchema(t), [t]);

  return (
    <EmailFormBody
      canGoBack={canGoBack}
      codeFieldName="code"
      emailFieldName="email"
      initialErrors={initialErrors}
      initialValues={initialValues}
      resendTime={resendTime}
      submitLoading={submitLoading}
      subTitle={t('auth.restorePassword.email.subtitle')}
      title={t('auth.restorePassword.email.title')}
      validationSchema={schema}
      onGoBack={onGoBack}
      onSendEmailCode={onSendEmailCode}
      onSubmit={onSubmit}
    />
  );
};
