import { isValidName } from 'helpers';
import { TypedForm, FormErrors as FormErrorsBase } from 'libs/form';
import { TFunc } from 'libs/i18n';
import {
  getOrderSubmissionContactSchema,
  OrderSubmissionContacts,
} from 'modules/onboarding/common/forms/orderSubmissionContacts';
import { ObjectSchema, string, object, array } from 'yup';

export interface LegalOrderSubmissionContact extends OrderSubmissionContacts {
  firstName: string;
  lastName: string;
  middleName?: string;
  position: string;
}

export interface LegalOrderSubmissionContactsFormType {
  orderSubmissionContacts: LegalOrderSubmissionContact[];
}

export const maxLengthName = 20;
export const maxLengthPosition = 200;

export const getLegalOrderSubmissionContactSchema = (
  t: TFunc
): ObjectSchema<LegalOrderSubmissionContact> =>
  getOrderSubmissionContactSchema(t).concat(
    object({
      firstName: string()
        .max(maxLengthName)
        .required()
        .test('ischars', t('validation.noSpecialCharsAndNumber'), isValidName),
      lastName: string()
        .max(maxLengthName)
        .required()
        .test('ischars', t('validation.noSpecialCharsAndNumber'), isValidName),
      middleName: string()
        .max(maxLengthName)
        .test('ischars', t('validation.noSpecialCharsAndNumber'), isValidName),
      position: string().max(maxLengthPosition).required(),
    })
  );

export const getSchema = (
  t: TFunc
): ObjectSchema<LegalOrderSubmissionContactsFormType> =>
  object({
    orderSubmissionContacts: array()
      .of(getLegalOrderSubmissionContactSchema(t))
      .min(1, t('validation.required'))
      .required(),
  });

export const { Field, Form, useFormContext, Submit } =
  TypedForm<LegalOrderSubmissionContactsFormType>();

export type FormErrors = FormErrorsBase<LegalOrderSubmissionContactsFormType>;
