import { FC } from 'react';

import { useOnboardingClientIdentity } from 'api/requests';
import { ClientIdentityType } from 'api/types/data';
import { LoadingLayout } from 'components';
import { useTranslation } from 'libs/i18n';
import { ROUTES, useNavigate, usePageTitle } from 'libs/navigation';

import { OnboardingInfoView } from '../views';

export type OnboardingInfoContainerProps = {};

export const OnboardingInfoContainer: FC<OnboardingInfoContainerProps> = () => {
  const { navigate } = useNavigate();
  const { clientIdentity, loading } = useOnboardingClientIdentity();

  const { t } = useTranslation();

  usePageTitle(t('onboarding.pageTitle'));

  const goToOnbording = () => {
    navigate(ROUTES.onboarding.fullPath, {}, { replace: true });
  };

  let texts = t('onboarding-info.personalTexts', { returnObjects: true });

  if (
    clientIdentity?.type === ClientIdentityType.ClientWithRepresentative ||
    clientIdentity?.type === ClientIdentityType.LegalClientBeneficiary ||
    clientIdentity?.type === ClientIdentityType.LegalClientHead ||
    clientIdentity?.type === ClientIdentityType.LegalClientLegalHeadFirstHead
  ) {
    goToOnbording();
    return <LoadingLayout />;
  }

  if (clientIdentity?.type === ClientIdentityType.LegalClientRepresentative) {
    texts = t('onboarding-info.legalTexts', { returnObjects: true });
  }

  if (loading) {
    return <LoadingLayout />;
  }
  return <OnboardingInfoView texts={texts} onOnboadring={goToOnbording} />;
};
