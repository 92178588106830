import { useContext } from 'react';

import { ClientEntity } from 'api/types/entity';
import {
  OnboardingContext,
  OnboardingContextProps,
} from 'modules/onboarding/common/providers/OnboardingProvider/Context';

export const useOnboardingContext = () => {
  const res = useContext(OnboardingContext);
  return res as OnboardingContextProps<ClientEntity>;
};
