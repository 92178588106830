import { ContentStepperComponentProps } from 'components';

export interface RegistrationStepProps<T = any>
  extends ContentStepperComponentProps<T> {}

export enum RegistrationType {
  personal = 'personal',
  legal = 'legal',
}

export interface RegistrationUrlData {
  email: string;
  code: string;
  type: RegistrationType;
  agreementIds: string[];
}
