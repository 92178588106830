import { useTranslation } from 'libs/i18n';
import { CompanyTaxResidency } from 'modules/onboarding/legal/components/CompanyTaxResidency';

import { useTaxResidency, useOnboardingContext } from '../hooks';
import { PageComponentProps } from '../types';

export const TaxResidencyStep = ({ canGoBack }: PageComponentProps) => {
  const {
    countries,
    countriesLoading,
    initialValues,
    submitLoading,
    onSubmit,
    goToBack,
  } = useTaxResidency();

  const { step } = useOnboardingContext();
  const { t } = useTranslation();

  return (
    <CompanyTaxResidency
      canGoBack={canGoBack}
      countries={countries}
      countriesLoading={countriesLoading}
      initialValues={initialValues}
      step={step}
      submitLoading={submitLoading}
      subTitle={t('onboarding.legalClientBranch.taxResidency.subtitle')}
      onGoBack={goToBack}
      onSubmit={onSubmit}
    />
  );
};
