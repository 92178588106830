import { useEffect, useState } from 'react';

import { tokenStorage } from 'api/client/helpers/tokenStorage';
import { useLogin as useLoginApi, useLoginWithOtp } from 'api/requests';
import {
  VerificationCodeFormFormErrors,
  VerificationCodeFormType,
} from 'components';
import { emptyErrorString } from 'libs/form';
import { useDialog } from 'libs/ui/Dialog/useDialog';

import { FormErrors, LoginFormType } from '../forms/loginFormForm';

export const useLogin = () => {
  const {
    isOpen: isOpenVerificationDialog,
    onClose: onCloseVerificationDialog,
    open,
  } = useDialog();

  const { login, loading, error } = useLoginApi();

  const {
    loginWithOtp,
    loading: loadingOtp,
    error: errorOtp,
  } = useLoginWithOtp();

  const [formError, setError] = useState<FormErrors>();
  const [verificationFormError, setVerificationError] =
    useState<VerificationCodeFormFormErrors>();

  const onSubmit = async (values: LoginFormType) => {
    const loginData = await login(values);
    if (loginData) {
      tokenStorage.login(loginData);
      if (loginData.otpRequired) {
        open();
        return false;
      }
    }
    return !!loginData;
  };

  const onSubmitVerification = async (values: VerificationCodeFormType) => {
    const loginData = await loginWithOtp(values.code);
    if (loginData) {
      tokenStorage.login(loginData);
    }
    return !!loginData;
  };

  const handleOnCloseVerificationDialog = () => {
    onCloseVerificationDialog();
    setVerificationError({ code: undefined });
  };

  useEffect(() => {
    if (error) {
      setError({ email: emptyErrorString, password: error.message });
    } else {
      setError({ email: undefined, password: undefined });
    }
  }, [error]);

  useEffect(() => {
    if (errorOtp) {
      setVerificationError({ code: emptyErrorString });
    } else {
      setVerificationError({ code: undefined });
    }
  }, [error, errorOtp]);

  return {
    onSubmit,
    formError,
    loading,
    isOpenVerificationDialog,
    onCloseVerificationDialog: handleOnCloseVerificationDialog,
    onSubmitVerification,
    verificationFormError,
    loadingOtp,
    errorOtp,
  };
};
