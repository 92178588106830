import { useMemo } from 'react';

import { useField as useFieldBase } from 'formik';

import { emptyErrorString } from '../types';
import { useFormContext } from '../useFormContext';

export interface FieldMetaProps<Value> {
  /** Value of the field */
  value: Value;
  /** Is error */
  isError?: boolean;
  /** Error message of the field */
  errorText?: string;
  /** Has the field been visited? */
  touched: boolean;
  /** Initial value of the field */
  initialValue?: Value;
  /** Initial touched state of the field */
  initialTouched: boolean;
  /** Initial error message of the field */
  initialError?: string;
}

interface Options {
  allowSubmit?: boolean;
}

export const useField = <T>(name: string, { allowSubmit }: Options = {}) => {
  const [field, metaBase, helpers] = useFieldBase<T>(name);
  const { handleSubmit } = useFormContext();

  const meta: FieldMetaProps<T> = useMemo(
    () => ({
      value: metaBase.value,
      isError: Boolean(metaBase.error) || metaBase.error === emptyErrorString,
      errorText:
        metaBase.error === emptyErrorString ? undefined : metaBase.error,
      touched: metaBase.touched,
      initialTouched: metaBase.initialTouched,
      initialValue: metaBase.initialValue,
      initialError: metaBase.initialError,
    }),
    [
      metaBase.error,
      metaBase.initialError,
      metaBase.initialTouched,
      metaBase.initialValue,
      metaBase.touched,
      metaBase.value,
    ]
  );
  return useMemo(
    () => ({
      field,
      meta,
      metaBase,
      helpers,
      inputProps: {
        error: meta.isError,
        helperText: meta.errorText,
        onSubmit: () => allowSubmit && handleSubmit(),
      },
    }),
    [field, meta, metaBase, helpers, allowSubmit, handleSubmit]
  );
};
