import { MaritalStatusType, OccupationType } from 'api/types/entity';
import { subYears } from 'date-fns';
import {
  TypedForm,
  FormErrors as FormErrorsBase,
  ObjectSchema,
  string,
  object,
  date,
  mixed,
} from 'libs/form';
import { TFunc } from 'libs/i18n';
import { SelectType } from 'types/selectType';
import { Maybe } from 'yup';

export const registrationDateMax = subYears(new Date(), 100);

export interface OnboardingBiographyFormType {
  maritalStatus: Maybe<SelectType<MaritalStatusType>>;
  occupation: Maybe<SelectType<OccupationType>>;

  companyName?: string;
  workAddress?: string;
  position?: string;

  entrepreneurName?: string;
  registrationNumber?: string;
  registrationDate?: Date;
  registrationPlace?: string;
  typeOfServices?: string;
}

const isCompany = (v: SelectType<OccupationType>) =>
  v?.value === OccupationType.businessOwner ||
  v?.value === OccupationType.employee;

const isEntrepreneur = (v: SelectType<OccupationType>) =>
  v?.value === OccupationType.entrepreneur;

export const getSchema = (
  t: TFunc
): ObjectSchema<OnboardingBiographyFormType> =>
  object({
    maritalStatus: object({
      value: mixed<MaritalStatusType>()
        .oneOf(Object.values(MaritalStatusType))
        .required(),
      label: string().required(),
    })
      .default(null)
      .required(),

    occupation: object({
      value: mixed<OccupationType>()
        .oneOf(Object.values(OccupationType))
        .required(),
      label: string().required(),
    })
      .default(null)
      .required(),

    companyName: string().when('occupation', {
      is: isCompany,
      then: (schema) => schema.required().max(200),
    }),
    workAddress: string().when('occupation', {
      is: isCompany,
      then: (schema) => schema.required().max(200),
    }),
    position: string().when('occupation', {
      is: isCompany,
      then: (schema) => schema.required().max(200),
    }),

    entrepreneurName: string().when('occupation', {
      is: isEntrepreneur,
      then: (schema) => schema.required().max(200),
    }),
    registrationNumber: string().when('occupation', {
      is: isEntrepreneur,
      then: (schema) => schema.required().max(200),
    }),
    registrationDate: date().when('occupation', {
      is: isEntrepreneur,
      then: (schema) =>
        schema
          .min(
            registrationDateMax,
            t('auth.registration.personalData.dateOfBirthMaxError')
          )
          .required(),
    }),
    registrationPlace: string().when('occupation', {
      is: isEntrepreneur,
      then: (schema) => schema.required().max(2000),
    }),
    typeOfServices: string().when('occupation', {
      is: isEntrepreneur,
      then: (schema) => schema.required().max(2000),
    }),
  });

export const { Field, Form, useFormContext, Submit } =
  TypedForm<OnboardingBiographyFormType>();

export type FormErrors = FormErrorsBase<OnboardingBiographyFormType>;
