import { useState } from 'react';

import { useFileUpload } from 'api/requests/file';
import { FileInfo } from 'libs/ui';
import { v4 as uuidv4 } from 'uuid';

export class FileUpload {
  id: string;

  serverId?: string;

  file?: File;

  fileInfo: FileInfo;

  loading?: boolean;

  isUploadError?: boolean;

  data?: any;

  constructor(
    fileInfo: FileInfo,
    file?: File,
    loading: boolean = false,
    serverId?: string,
    data?: any
  ) {
    this.id = uuidv4();
    this.file = file;
    this.loading = loading;
    this.serverId = serverId;
    this.fileInfo = fileInfo;
    this.data = data;
  }
}

export const useFilesUpload = (initialFiles?: FileUpload[]) => {
  const { upload } = useFileUpload();

  const [files, setFiles] = useState<FileUpload[]>(initialFiles ?? []);

  const loader = (files: FileUpload[]) => {
    files.forEach(async (uploadFile) => {
      if (!uploadFile.file) return;

      const serverId = await upload(uploadFile.file).catch();

      setFiles((oldFiles) => {
        const changedFiles = oldFiles.map((file) => {
          if (file.id === uploadFile.id) {
            file.loading = false;
            if (serverId) {
              file.serverId = serverId;
            } else {
              file.isUploadError = true;
            }
          }
          return file;
        });

        return [...changedFiles];
      });
    });
  };

  const onAddFiles = async (files: File[], data?: any) => {
    setFiles((v) => {
      const newFiles = files.map(
        (f) =>
          new FileUpload(
            {
              name: f.name,
              size: f.size,
            },
            f,
            true,
            undefined,
            data
          )
      );
      loader(newFiles);
      return v.concat(newFiles);
    });
  };

  const onRemoveFile = (file: FileUpload) => {
    setFiles((oldFiles) => {
      const newFiles = oldFiles.filter((f) => f.id !== file.id);
      return newFiles;
    });
  };

  const onRemoveFilesByServerIds = (ids: string[]) => {
    setFiles((oldFiles) => {
      const newFiles = oldFiles.filter(
        (f) => !f.serverId || !ids.includes(f.serverId)
      );
      return newFiles;
    });
  };

  const isLoadingFile = files.some((v) => v.loading);

  return {
    onRemoveFile,
    onRemoveFilesByServerIds,
    onAddFiles,
    files,
    isLoadingFile,
  };
};
