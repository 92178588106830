import { FormContainerStepProps } from 'components';
import { useTranslation } from 'libs/i18n';

import {
  OnboardingTaxResidencyFormType,
  FormErrors,
  getSchema,
} from '../../forms/onboardingTaxResidencyForm';
import { FormBodyWrapper, ClientType } from '../FormBodyWrapper';

import { FormContent, FormContentProps } from './FormContent';

interface OnboardingTaxResidencyProps
  extends FormContentProps,
    FormContainerStepProps {
  initialValues?: OnboardingTaxResidencyFormType;
  onSubmit: (values: OnboardingTaxResidencyFormType) => void;
  canGoBack?: boolean;
  onGoBack?: () => void;
  formError?: FormErrors;
  submitLoading?: boolean;
  clientType?: ClientType;
  subTitle?: string;
}

export const OnboardingTaxResidency = ({
  initialValues,
  canGoBack,
  onGoBack,
  submitLoading,
  formError,
  step,
  onSubmit,
  subTitle,
  clientType,
  ...formProps
}: OnboardingTaxResidencyProps) => {
  const schema = getSchema();
  const { t } = useTranslation();
  return (
    <FormBodyWrapper
      canGoBack={canGoBack}
      clientType={clientType}
      initialErrors={formError}
      initialValues={initialValues}
      step={step}
      submitLoading={submitLoading}
      subTitle={subTitle ?? t('onboarding.taxResidency.subtitle')}
      title={t('onboarding.taxResidency.title')}
      validationSchema={schema}
      onGoBack={onGoBack}
      onSubmit={onSubmit}
    >
      <FormContent {...formProps} />
    </FormBodyWrapper>
  );
};
