import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import {
  OnboardingProcessEntity,
  OnboardingProcessStepNameType,
} from 'api/types/entity';
import { PageComponent } from 'modules/onboarding/common/types';

const getPrevPage = (
  currentPageName: string,
  previousSteps: OnboardingProcessEntity['previousSteps']
) => {
  if (!previousSteps.length) {
    return undefined;
  }
  const currentPageIndex = previousSteps.findIndex(
    ({ name }) => name === currentPageName
  );
  if (currentPageIndex === -1) {
    return previousSteps[previousSteps.length - 1];
  }
  return previousSteps[currentPageIndex - 1];
};

interface Args {
  onGoBack?: (name: string) => void;
  onEnd?: () => void;
}

export const useOnboardingPage = (
  process: OnboardingProcessEntity,
  pagesMap: Record<string, PageComponent>,
  { onEnd, onGoBack }: Args
) => {
  const prevPageNameRef = useRef<OnboardingProcessStepNameType>();

  const [currentPageName, setCurrentPageName] = useState(
    process.currentStep.name
  );
  const { totalStepCount } = process;

  const currentPageNumber = useMemo(() => {
    if (!process.previousSteps.length) {
      return 0;
    }
    const currentPageIndex = process.previousSteps.findIndex(
      ({ name }) => name === currentPageName
    );
    if (currentPageIndex === -1) {
      return process.previousSteps.length;
    }
    return currentPageIndex;
  }, [currentPageName, process.previousSteps]);

  const canGoBack = useMemo(() => {
    const prevPage = getPrevPage(currentPageName, process.previousSteps);
    return !!prevPage?.isEditable;
  }, [currentPageName, process.previousSteps]);

  const currentPage = useMemo(
    () => pagesMap[currentPageName],
    [currentPageName, pagesMap]
  );

  const goToBack = useCallback(() => {
    const prevPage = getPrevPage(currentPageName, process.previousSteps);

    if (!prevPage || !prevPage.isEditable) {
      return;
    }
    prevPageNameRef.current = currentPageName;
    setCurrentPageName(prevPage.name);
    onGoBack?.(prevPage.name);
  }, [currentPageName, process.previousSteps, onGoBack]);

  const goToNext = useCallback(() => {
    if (prevPageNameRef.current) {
      setCurrentPageName(prevPageNameRef.current);
    }
    prevPageNameRef.current = undefined;
    if (totalStepCount === currentPageNumber + 1) {
      onEnd?.();
    }
  }, [currentPageNumber, onEnd, totalStepCount]);

  useEffect(() => {
    prevPageNameRef.current = undefined;
    setCurrentPageName(process.currentStep.name);
  }, [process]);

  return {
    currentPage,
    currentPageNumber,
    currentPageName,
    totalStepCount,
    canGoBack,
    goToBack,
    goToNext,
  };
};
