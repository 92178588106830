import { useRef } from 'react';

import { useClientRepresentativeSaveDebts } from 'api/requests';
import { OccupationType } from 'api/types/entity';
import { useServerErrorNotify } from 'hooks';
import { useTranslation } from 'libs/i18n';
import { BaseDebtsFormType } from 'modules/onboarding/common/forms/baseDebtsForm';
import { getBaseDebtsInitialData } from 'modules/onboarding/common/helpers/debts/getBaseDebtsInitialData';
import { getBaseDebtsSubmitData } from 'modules/onboarding/common/helpers/debts/getBaseDebtsSubmitData';

import { useOnboardingContext } from './useOnboardingContext';

export const useDebts = () => {
  const {
    saveDebts,
    error,
    loading: submitLoading,
  } = useClientRepresentativeSaveDebts();
  const { t } = useTranslation();

  const { goToBack, goToNext, data, clientId } = useOnboardingContext();

  const initialValues = useRef(
    getBaseDebtsInitialData(data.currentClient.debts, t)
  ).current;

  const isEntrepreneur =
    data.currentClient.biography?.occupation === OccupationType.entrepreneur;

  const onSubmit = async (values: BaseDebtsFormType) => {
    if (values) {
      const submitData = getBaseDebtsSubmitData(values, isEntrepreneur);
      await saveDebts(clientId, submitData);
      goToNext();
    }
  };

  useServerErrorNotify(error);

  return {
    onSubmit,
    goToBack,
    isEntrepreneur,
    initialValues,
    submitLoading,
  };
};
