import { BankAccountEntity } from 'api/types/entity';
import { Maybe } from 'types/maybe';

import { OnboardingAccountsInOrganizationsFormValue } from '../../forms/onboardingAccountsInOrganizationsForm';

export const getInitialData = (
  bankAccounts: Maybe<BankAccountEntity[]>
): OnboardingAccountsInOrganizationsFormValue[] =>
  bankAccounts?.map((v) => ({
    country: v.country || null,
    bic: v.swiftCode,
  })) || [{ country: null, bic: '' }];
