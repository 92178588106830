import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useClientRepresentativeSavePepConnectionMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { SavePepConnectionParam } from 'api/types/params';

import { parsePepConnectionParam } from '../helpers';

import { parseClientRepresentative } from './helpers/parseClientRepresentative';

export const useClientRepresentativeSavePepConnection = () => {
  const [savePepConnection, { data, loading, error: apolloError }] =
    useClientRepresentativeSavePepConnectionMutation();

  const error = useError(apolloError);

  return {
    data: data?.clientRepresentativeSavePepConnection
      ? parseClientRepresentative(data.clientRepresentativeSavePepConnection)
      : undefined,
    loading,
    error,
    savePepConnection: useCallback(
      async (id: string, params: SavePepConnectionParam) => {
        const res = await savePepConnection({
          variables: { id, input: parsePepConnectionParam(params) },
        });
        return res.data?.clientRepresentativeSavePepConnection
          ? parseClientRepresentative(
              res.data.clientRepresentativeSavePepConnection
            )
          : undefined;
      },
      [savePepConnection]
    ),
  };
};
