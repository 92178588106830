import { useRef, useLayoutEffect } from 'react';

const POSTFIX_TITLE = ' | NGDEM Finance';
interface Prarams {
  /**
   * Whether to return previous title when unmounting
   * @default true
   */
  previousOnUnmount: boolean;
}
/**
 * Set document title
 * @param {string} title - The document title
 * @param {boolean} previousOnUnmount - Whether to return previous title when unmounting
 */
export const usePageTitle = (title: string, params?: Prarams) => {
  const prevTitle = useRef(document.title);

  const previousOnUnmount = params?.previousOnUnmount ?? true;

  useLayoutEffect(() => {
    document.title = title + POSTFIX_TITLE;
  }, [title]);

  useLayoutEffect(
    () => () => {
      if (previousOnUnmount) {
        document.title = prevTitle.current;
      }
    },
    [previousOnUnmount]
  );
};
