import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useClientSaveBiographyMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { BiographyParam } from 'api/types/params/onboarding/biographyParam';

import { parseBiographyParams, parseClientEntity } from '../helpers';

export const useClientSaveBiography = () => {
  const [handle, { data, loading, error: apolloError }] =
    useClientSaveBiographyMutation();

  const error = useError(apolloError);

  return {
    data: data?.clientSaveBiography
      ? parseClientEntity(data.clientSaveBiography)
      : undefined,
    loading,
    error,
    saveBiography: useCallback(
      async (id: string, params: BiographyParam) => {
        const input = parseBiographyParams(params);
        const res = await handle({
          variables: {
            id,
            input,
          },
        });
        return res?.data?.clientSaveBiography
          ? parseClientEntity(res.data.clientSaveBiography)
          : undefined;
      },
      [handle]
    ),
  };
};
