import { styled } from 'libs/ui/styled';

import { MARGIN_HORIZONTAL, MOBILE_MARGIN_HORIZONTAL } from './constants';

export const WrapperStyled = styled('div')(({ theme }) => ({
  marginLeft: MARGIN_HORIZONTAL,
  marginRight: MARGIN_HORIZONTAL,

  [theme.breakpoints.down('tablet')]: {
    marginLeft: MOBILE_MARGIN_HORIZONTAL,
    marginRight: MOBILE_MARGIN_HORIZONTAL,
  },
}));
