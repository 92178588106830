// eslint-disable-next-line no-restricted-imports
import { Dialog as DialogBase, SwipeableDrawer } from '@mui/material';
import { getColor } from 'helpers';

import { CSSObject, styled } from '../styled';
import { ColorsDark, ColorsLight } from '../theme';

export const DialogSyled = styled(DialogBase)(({ theme }) => ({
  '.MuiDialog-paper': {
    backgroundColor: getColor(theme, {
      light: ColorsLight.white,
      dark: ColorsDark.black,
    }),
    backgroundImage: 'none',
  } as CSSObject,

  '.MuiBackdrop-root': {
    backgroundColor: `${getColor(theme, {
      light: ColorsLight.gray300,
      dark: ColorsDark.gray50,
    })}A5`,
  },
  '.MuiPaper-root': {
    borderRadius: 24,
    boxShadow: theme.palette.shadow.main,
  },
}));

export const SwipeableDrawerSyled = styled(SwipeableDrawer)(({ theme }) => ({
  '.MuiDrawer-paper': {
    backgroundColor: getColor(theme, {
      light: ColorsLight.white,
      dark: ColorsDark.black,
    }),
    backgroundImage: 'none',
    marginLeft: 0,
    marginRight: 0,
    width: '100%',
  } as CSSObject,

  '.MuiBackdrop-root': {
    backgroundColor: `${getColor(theme, {
      light: ColorsLight.gray300,
      dark: ColorsDark.gray50,
    })}A5`,
  },
  '.MuiPaper-root': {
    borderTopRightRadius: 24,
    borderTopLeftRadius: 24,
    boxShadow: theme.palette.shadow.main,
  } as CSSObject,
}));

export const PullerStyled = styled('div')(({ theme }) => ({
  width: 64,
  height: 4,
  backgroundColor: getColor(theme, {
    dark: ColorsDark.gray100,
    light: ColorsLight.gray100,
  }),
  borderRadius: 16,
  position: 'absolute',
  top: 8,
  left: 'calc(50% - 32px)',
}));
