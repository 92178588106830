import { CompanyAccountsInOrganizations } from '../components';
import { useCompanyAccountsInOrganizations } from '../hooks';
import { PageComponentProps } from '../types';

export const CompanyAccountsInOrganizationsStep = ({
  canGoBack,
}: PageComponentProps) => {
  const {
    initialValues,
    onSubmit,
    submitLoading,
    countries,
    countriesLoading,
    goToBack,
  } = useCompanyAccountsInOrganizations();

  return (
    <CompanyAccountsInOrganizations
      canGoBack={canGoBack}
      countries={countries}
      countriesLoading={countriesLoading}
      initialValues={initialValues}
      submitLoading={submitLoading}
      onGoBack={goToBack}
      onSubmit={onSubmit}
    />
  );
};
