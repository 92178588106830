import { OnboardingProcessStepNameType } from 'api/types/entity';

import {
  CitizenshipStep,
  PepConnectionStep,
  RegistrationAddressStep,
  PositionInCompanyStep,
  TaxResidencyStep,
  PersonalDataStep,
  HeadTypeInfoStep,
} from './steps';
import { RoutePage } from './types';

export const pagesMap: Partial<
  Record<OnboardingProcessStepNameType, RoutePage['component']>
> = {
  [OnboardingProcessStepNameType.LegalClientHeadPersonalData]: PersonalDataStep,
  [OnboardingProcessStepNameType.LegalClientHeadType]: HeadTypeInfoStep,
  [OnboardingProcessStepNameType.LegalClientHeadCitizenships]: CitizenshipStep,
  [OnboardingProcessStepNameType.LegalClientHeadTaxResidencies]:
    TaxResidencyStep,
  [OnboardingProcessStepNameType.LegalClientHeadAddresses]:
    RegistrationAddressStep,
  [OnboardingProcessStepNameType.LegalClientHeadPep]: PepConnectionStep,
  [OnboardingProcessStepNameType.LegalClientHeadPositionInCompany]:
    PositionInCompanyStep,
};
