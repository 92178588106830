import { TypedForm, FormErrors as FormErrorsBase } from 'libs/form';
import { ObjectSchema, string, object } from 'yup';

export interface RegistrationTwoFactorFormType {
  code: string;
}

export const schema: ObjectSchema<RegistrationTwoFactorFormType> = object({
  code: string().min(6).required(),
});

export const { Field, Form, useFormContext, Submit } =
  TypedForm<RegistrationTwoFactorFormType>();

export type FormErrors = FormErrorsBase<RegistrationTwoFactorFormType>;
