// eslint-disable-next-line no-restricted-imports
import { BranchContactFragment } from 'api/generated/graphql';
import { LegalClientBaseContactEntity } from 'api/types/entity';
import { Maybe } from 'types/maybe';

export const parseLegalClientBranchContacts = (
  contacts?: Maybe<BranchContactFragment>
): LegalClientBaseContactEntity | undefined => {
  if (!contacts) {
    return undefined;
  }

  return {
    email: contacts.email,
    phoneNumber: contacts.phoneNumber,
  };
};
