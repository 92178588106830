import { CountryEntity } from 'api/types/entity';
import { Maybe } from 'types/maybe';

import { OnboardingCitizenshipFormType } from '../../forms/onboardingCitizenshipForm';

export const getInitialData = (
  citizenships?: Maybe<Maybe<CountryEntity>[]>
): OnboardingCitizenshipFormType => ({
  citizenships: citizenships?.length ? citizenships : [null],
});
