import { getYesOrNoOptions } from 'helpers';
import { FileUpload } from 'hooks';
import { useTranslation } from 'libs/i18n';
import { Stack } from 'libs/ui';
import { YesOrNoType } from 'types/yesOrNo';

import { useFormContext, Field } from '../../../forms/companyLicenseForm';

import { Buttons, Header } from './components';
import { Fields } from './components/Fields';

export interface FormContentProps {
  files?: FileUpload[];
  onAddFiles?: (file: File[]) => void;
  onRemoveFile?: (file: FileUpload) => void;
  onRemoveFilesByServerIds?: (ids: string[]) => void;
}

export const FormContent = ({
  files,
  onAddFiles,
  onRemoveFile,
  onRemoveFilesByServerIds,
}: FormContentProps) => {
  const { t } = useTranslation();
  const { values } = useFormContext();
  return (
    <>
      <Field.Autocomplete
        label={t('onboarding.companyLicense.companyActivityIsLicensed.label')}
        name="companyActivityIsLicensed"
        optionLabelKey="label"
        options={getYesOrNoOptions(t)}
        optionValueKey="value"
        placeholder={t('common.select.placeholder')}
        variant="select"
      />
      {values.companyActivityIsLicensed &&
        values.companyActivityIsLicensed.value === YesOrNoType.Yes && (
          <>
            {values.licenses.map((value, index) => (
              <Stack key={index.toLocaleString()} spacing={32}>
                <Stack spacing={24} spacingMobile={16}>
                  <Header index={index} />

                  <Fields
                    files={files}
                    index={index}
                    onAddFiles={onAddFiles}
                    onRemoveFile={onRemoveFile}
                  />
                </Stack>

                <Buttons
                  index={index}
                  onRemoveFilesByServerIds={onRemoveFilesByServerIds}
                />
              </Stack>
            ))}
          </>
        )}
    </>
  );
};
