import { useRef } from 'react';

import { useLegalClientLegalHeadFirstHeadSaveCitizenships } from 'api/requests';
import { useServerErrorNotify } from 'hooks';
import { OnboardingCitizenshipFormType } from 'modules/onboarding/common/forms/onboardingCitizenshipForm';
import { getInitialData } from 'modules/onboarding/common/helpers/citizenship/getInitialData';
import { getSubmitData } from 'modules/onboarding/common/helpers/citizenship/getSubmitData';
import { useOnboardingCountries } from 'modules/onboarding/common/hooks';

import { useOnboardingContext } from './useOnboardingContext';

export const useCitizenship = () => {
  const { goToBack, goToNext, data } = useOnboardingContext();

  const { error, loading, saveCitizenships } =
    useLegalClientLegalHeadFirstHeadSaveCitizenships();

  const { countries, loading: countriesLoading } = useOnboardingCountries();

  const initialValues = useRef(
    getInitialData(data.firstHead.citizenships)
  ).current;

  const onSubmit = async (values: OnboardingCitizenshipFormType) => {
    if (values.citizenships) {
      const submitData = getSubmitData(values);
      await saveCitizenships(
        data.legalClient.id,
        data.legalHeadId,
        data.firstHead.id,
        submitData
      );
      goToNext();
    }
  };

  useServerErrorNotify(error);

  return {
    onSubmit,
    submitLoading: loading,
    initialValues,
    countries,
    countriesLoading,
    goToBack,
  };
};
