import { useState } from 'react';

import { useAgreements as useAgreementsApi } from 'api/requests';
import { AgreementsClassificationType } from 'api/types/entity/agreementsEntity';

interface Props {
  classificationType: AgreementsClassificationType;
  onAgreement: (ids: string[]) => void;
}

export const useAgreements = ({ classificationType, onAgreement }: Props) => {
  const { agreements, loading } = useAgreementsApi(classificationType);

  const [activeIndex, setActiveIndex] = useState(0);

  const onAccept = () => {
    if (agreements && activeIndex === agreements.length - 1) {
      onAgreement(agreements.map(({ id }) => id));
      return;
    }
    setActiveIndex(activeIndex + 1);
  };

  return { agreements, activeIndex, loading, onAccept };
};
