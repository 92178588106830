import { CountryEntity, CurrencyType } from 'api/types/entity';
import {
  TypedForm,
  FormErrors as FormErrorsBase,
  ObjectSchema,
  string,
  object,
  array,
  mixed,
} from 'libs/form';
import { Maybe } from 'types/maybe';

export interface WithdrawalAccountsFormValue {
  bankCountry: Maybe<CountryEntity>;
  swiftCode: string;
  bankName: string;
  bankAddress: string;
  checkingAccount: string;
  correspondentAccount?: string;
  ownerConfirmationFileIds: string[];
  currency: Maybe<{ value: CurrencyType; label: string }>;
}

export const MAX_FILES = 10;

export interface WithdrawalAccountsFormType {
  withdrawalAccounts: WithdrawalAccountsFormValue[];
}

export const getSchema = (): ObjectSchema<WithdrawalAccountsFormType> =>
  object({
    withdrawalAccounts: array()
      .of(
        object({
          swiftCode: string().required().max(200),
          bankName: string().required().max(200),
          bankAddress: string().required().max(200),
          checkingAccount: string().required().max(200),
          correspondentAccount: string().max(200),
          ownerConfirmationFileIds: array()
            .of(string().required())
            .required()
            .min(1)
            .max(MAX_FILES),
          bankCountry: object({
            id: string().required(),
            name: string().required(),
          }).required(),

          currency: object({
            value: mixed<CurrencyType>()
              .oneOf(Object.values(CurrencyType))
              .required(),
            label: string().required(),
          }).required(),
        }).required()
      )
      .required(),
  });

export const { Field, Form, useFormContext, Submit } =
  TypedForm<WithdrawalAccountsFormType>();

export type FormErrors = FormErrorsBase<WithdrawalAccountsFormType>;
