import { useRef } from 'react';

import { useLegalClientBeneficiarySaveControlOver } from 'api/requests/onboarding/legal/beneficiary';
import { ControlOverEntity } from 'api/types/entity/legal/beneficiary/controlOverEntity';
import { yesOrNot } from 'helpers';
import { useServerErrorNotify } from 'hooks';
import { TFunc, useTranslation } from 'libs/i18n';
import { YesOrNoType } from 'types/yesOrNo';

import { ControlOverFormType } from '../forms/controlOverForm';

import { useOnboardingContext } from './useOnboardingContext';

const getInitialValue = (
  t: TFunc,
  data: ControlOverEntity | undefined
): ControlOverFormType | undefined => {
  if (!data) return undefined;

  return {
    financialConditionAmount: data.financialConditionAmount,
    directCapitalParticipationPercentage:
      data.directCapitalParticipationPercentage,
    hasControlOverLegalClient: yesOrNot(t, data.hasControlOverLegalClient)!,
    indirectCapitalParticipationPercentage:
      data.indirectCapitalParticipationPercentage,
  };
};

export const useControlOver = () => {
  const { t } = useTranslation();
  const { goToBack, goToNext, data } = useOnboardingContext();

  const {
    saveControlOver,
    loading: submitLoading,
    error,
  } = useLegalClientBeneficiarySaveControlOver();

  const initialValues = useRef(
    getInitialValue(t, data.legalClientBeneficiary.controlOver)
  ).current;

  const onSubmit = async (values: ControlOverFormType) => {
    if (values) {
      await saveControlOver(
        data.legalClient.id,
        data.legalClientBeneficiary.id,
        {
          directCapitalParticipationPercentage: Number(
            values.directCapitalParticipationPercentage
          ),
          financialConditionAmount: values.financialConditionAmount,
          indirectCapitalParticipationPercentage: Number(
            values.indirectCapitalParticipationPercentage
          ),
          hasControlOverLegalClient:
            values.hasControlOverLegalClient.value === YesOrNoType.Yes,
        }
      );
      goToNext();
    }
  };

  useServerErrorNotify(error);

  return {
    onSubmit,
    submitLoading,
    initialValues,
    goToBack,
  };
};
