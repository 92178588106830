import { RegistrationPlaceEntity } from 'api/types/entity';
import { Maybe } from 'types/maybe';

import { CompanyRegistrationPlaceFormType } from '../../forms/companyRegistrationPlaceForm';

export const getInitialData = (
  values?: Maybe<RegistrationPlaceEntity>
): CompanyRegistrationPlaceFormType => ({
  country: values?.country ?? null,
  locality: values?.locality ?? '',
  specialRegistrationArea: values?.specialRegistrationArea ?? undefined,
});
