import { parseServerError } from 'api/helpers/parseServerError';
import { useLegalClientCreateHead } from 'api/requests';
import { LegalClientEntity } from 'api/types/entity';
import { LegalClientCreateHeadSaveParam } from 'api/types/params';
import { useNotify } from 'libs/notify';

export const useCreateHead = (legalClient: LegalClientEntity) => {
  const { createHead } = useLegalClientCreateHead();

  const notify = useNotify();

  const handleCreateHead = async (param: LegalClientCreateHeadSaveParam) => {
    try {
      const head = await createHead(legalClient.id, param);

      return head;
    } catch (error) {
      const serverError = parseServerError(error);
      if (serverError) {
        throw serverError;
      }
      notify.error(error);
    }
    return undefined;
  };

  return {
    createHead: handleCreateHead,
  };
};
