import { styled } from 'libs/ui/styled';

import { StackProps } from './types';

export const Stack = styled('div', {
  shouldForwardProp: (prop) =>
    prop !== 'justifyContent' && prop !== 'alignItems' && prop !== 'direction',
})<StackProps>(
  ({
    theme,
    spacing = 0,
    spacingMobile,
    direction = 'column',
    alignItems,
    justifyContent,
    flexWrap,
    flex,
  }) => ({
    display: 'flex',
    alignItems,
    justifyContent,
    flexWrap,
    flex,
    flexDirection: direction === 'column' ? 'column' : 'row',
    gap: spacing,
    [theme.breakpoints.down('mobile')]: {
      flexDirection: direction === 'row-exceptMobile' ? 'column' : direction,
      gap: spacingMobile ?? spacing,
    },
  })
);
