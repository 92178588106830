import { useTranslation } from 'libs/i18n';
import { OnboardingCitizenship } from 'modules/onboarding/common/components';

import { useCitizenship } from '../hooks';
import { useOnboardingContext } from '../hooks/useOnboardingContext';
import { PageComponentProps } from '../types';

export const CitizenshipStep = ({ canGoBack }: PageComponentProps) => {
  const {
    countries,
    countriesLoading,
    goToBack,
    initialValues,
    onSubmit,
    submitLoading,
  } = useCitizenship();

  const { step } = useOnboardingContext();
  const { t } = useTranslation();

  return (
    <OnboardingCitizenship
      canGoBack={canGoBack}
      countries={countries}
      countriesLoading={countriesLoading}
      initialValues={initialValues}
      step={step}
      submitLoading={submitLoading}
      subTitle={t('onboarding.legalClientBeneficiary.citizenship.subtitle')}
      onGoBack={goToBack}
      onSubmit={onSubmit}
    />
  );
};
