import { useMemo } from 'react';

import {
  PasswordErrorType,
  PasswordValidation,
} from 'helpers/validations/password';
import { useFormContext } from 'libs/form/useFormContext';
import { useTranslation } from 'libs/i18n';
import { Typography, Stack } from 'libs/ui';
import { Icon } from 'libs/ui/Icon';
import { useTheme } from 'libs/ui/theme';

interface Props<Values extends object> {
  passwordFieldName: Extract<keyof Values, string>;
  passwordValidation: PasswordValidation;
}

export const PasswordRules = <Values extends object>({
  passwordValidation,
  passwordFieldName,
}: Props<Values>) => {
  const { theme } = useTheme();

  const { t } = useTranslation();
  const { values } = useFormContext<Values>();

  const rules = passwordValidation.checkPassword(
    values[passwordFieldName] as string
  );

  const SuccessIcon = (
    <Icon.Check color={theme.palette.success.main} size={16} />
  );
  const ErrorIcon = <Icon.Close color={theme.palette.error.main} size={16} />;

  const rulesView = useMemo(
    () => ({
      [PasswordErrorType.SIZE]: t('components.passwordForm.rules.size'),
      [PasswordErrorType.DIGIT]: t('components.passwordForm.rules.digit'),
      [PasswordErrorType.LETTER]: t('components.passwordForm.rules.latter'),
      [PasswordErrorType.SPECIAL_CHARS]: t(
        'components.passwordForm.rules.specChars'
      ),
    }),
    [t]
  );

  return (
    <Stack spacing={8}>
      {Object.entries(rulesView).map(([key, text]) => (
        <Stack key={key} alignItems="center" direction="row" spacing={4}>
          {rules[key as PasswordErrorType] ? SuccessIcon : ErrorIcon}
          <Typography variantMobile="caption">{text}</Typography>
        </Stack>
      ))}
    </Stack>
  );
};
