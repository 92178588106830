import { useRef } from 'react';

import { useLegalClientRepresentativeSaveBiography } from 'api/requests';
import { useServerErrorNotify } from 'hooks';
import { useTranslation } from 'libs/i18n';
import {
  getInitialData,
  getSubmitData,
} from 'modules/onboarding/common/helpers/biography';

import { OnboardingBiographyFormType } from '../../common/forms/onboardingBiographyForm';

import { useOnboardingContext } from './useOnboardingContext';

export const useBiography = () => {
  const {
    loading: submitLoading,
    error,
    saveBiography,
  } = useLegalClientRepresentativeSaveBiography();

  const { t } = useTranslation();

  const { goToBack, goToNext, data } = useOnboardingContext();

  const initialValues = useRef(
    getInitialData(data.currentClient.biography, t)
  ).current;

  const onSubmit = async (values: OnboardingBiographyFormType) => {
    if (values) {
      const submitData = getSubmitData(values);
      await saveBiography(data.currentClient.id, submitData);
      goToNext();
    }
  };

  useServerErrorNotify(error);

  return {
    onSubmit,
    goToBack,
    initialValues,
    submitLoading,
  };
};
