import { useEffect, useMemo } from 'react';

import { useClientRepresentativeCreateClient } from 'api/requests';
import { ErrorCode } from 'api/types/error';
import { useNotify } from 'libs/notify';
import {
  PersonalDataFormType,
  FormErrors,
} from 'modules/onboarding/common/forms/personalDataForm';

import { useOnboardingContext } from './useOnboardingContext';

export const useOwnerPersonalData = () => {
  const { goToBack, goToNext, clientId, data } = useOnboardingContext();
  const notify = useNotify();

  const {
    createClient,
    loading: submitLoading,
    error,
  } = useClientRepresentativeCreateClient();

  const onSubmit = async (values: PersonalDataFormType) => {
    await createClient(clientId, values);
  };

  const formError = useMemo((): FormErrors | undefined => {
    if (error?.code === ErrorCode.USER_WITH_EMAIL_ALREADY_EXISTS) {
      return { email: error?.message };
    }
    if (error) {
      notify.error(error);
    }

    return undefined;
  }, [error, notify]);

  useEffect(() => {
    if (data.ownerClient) {
      goToNext();
    }
  }, [data.ownerClient, goToNext]);

  return {
    onSubmit,
    goToBack,
    formError,
    submitLoading,
  };
};
