import { OnboardingTaxResidencyFormType } from 'modules/onboarding/common/forms/onboardingTaxResidencyForm';
import {
  useBeforeSubmitTaxResidency,
  useClientTaxResidency,
} from 'modules/onboarding/common/hooks';

import { useOnboardingContext } from './useOnboardingContext';

export const useOwnerTaxResidency = () => {
  const { goToBack, goToNext, data } = useOnboardingContext();
  const {
    countries,
    countriesLoading,
    initialValues,
    onSubmit,
    submitLoading,
  } = useClientTaxResidency(data.ownerClient!);

  const handleSubmit = useBeforeSubmitTaxResidency(
    async (values: OnboardingTaxResidencyFormType) => {
      await onSubmit(values);
      goToNext();
    }
  );

  return {
    onSubmit: handleSubmit,
    goToBack,
    submitLoading,
    initialValues,
    countries,
    countriesLoading,
  };
};
