import { PropsWithChildren, ReactNode } from 'react';

import { LayoutProps } from 'components/Layout';

import { FormLayoutStyled } from './FormLayout.styles';

export interface AuthLayoutProps extends PropsWithChildren, LayoutProps {
  center?: boolean;
  headerComponent?: ReactNode;
  className?: string;
}

export const FormLayout = ({
  children,
  headerComponent,
  center,
  className,
  ...layoutProps
}: AuthLayoutProps) => (
  <FormLayoutStyled center={center} className={className} {...layoutProps}>
    {headerComponent && (
      <div className="formLayout-header">{headerComponent}</div>
    )}
    <div className="formLayout-content">{children}</div>
  </FormLayoutStyled>
);
