import { CompanyFinancingSources } from '../components';
import { useCompanyFinancingSources } from '../hooks';
import { PageComponentProps } from '../types';

export const CompanyFinancingSourcesStep = ({
  canGoBack,
}: PageComponentProps) => {
  const {
    initialValues,
    onSubmit,
    submitDisabled,
    submitLoading,
    goToBack,
    auditedFinancialReportFiles,
    onAddFilesAuditedFinancialReportFiles,
    onAddFilesRecommendationLetterFiles,
    onRemoveFileAuditedFinancialReportFiles,
    onRemoveFileRecommendationLetterFiles,
    recommendationLetterFiles,
  } = useCompanyFinancingSources();

  return (
    <CompanyFinancingSources
      auditedFinancialReportFiles={auditedFinancialReportFiles}
      canGoBack={canGoBack}
      initialValues={initialValues}
      recommendationLetterFiles={recommendationLetterFiles}
      submitDisabled={submitDisabled}
      submitLoading={submitLoading}
      onAddFilesAuditedFinancialReportFiles={
        onAddFilesAuditedFinancialReportFiles
      }
      onAddFilesRecommendationLetterFiles={onAddFilesRecommendationLetterFiles}
      onGoBack={goToBack}
      onRemoveFileAuditedFinancialReportFiles={
        onRemoveFileAuditedFinancialReportFiles
      }
      onRemoveFileRecommendationLetterFiles={
        onRemoveFileRecommendationLetterFiles
      }
      onSubmit={onSubmit}
    />
  );
};
