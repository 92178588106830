import { ClientType, Debts } from 'modules/onboarding/common/components';

import { useOwnerDebts } from '../hooks';
import { PageComponentProps } from '../types';

export const OwnerDebtsStep = ({ canGoBack }: PageComponentProps) => {
  const { goToBack, initialValues, onSubmit, submitLoading, isEntrepreneur } =
    useOwnerDebts();

  return (
    <Debts
      canGoBack={canGoBack}
      clientType={ClientType.Client}
      initialValues={initialValues}
      isEntrepreneur={isEntrepreneur}
      submitLoading={submitLoading}
      onGoBack={goToBack}
      onSubmit={onSubmit}
    />
  );
};
