import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useRegistrationTwoFactorGenerateMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';

import { parseData } from './parseData';

export const useRegistrationTwoFactorGenerate = () => {
  const [handle, { data, loading, error: apolloError }] =
    useRegistrationTwoFactorGenerateMutation();

  const error = useError(apolloError);

  const generate = useCallback(async () => {
    const res = await handle();
    const data = res.data?.OtpController_generate;
    if (!data) return undefined;
    return parseData(data);
  }, [handle]);

  const qrData = data?.OtpController_generate
    ? parseData(data.OtpController_generate)
    : undefined;

  return {
    data: qrData,
    loading,
    error,
    generate,
  };
};
