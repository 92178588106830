import { CountryEntity } from 'api/types/entity';
import { useTranslation } from 'libs/i18n';
import {
  maxLengthBic,
  Field,
} from 'modules/onboarding/common/forms/onboardingAccountsInOrganizationsForm';

interface Props {
  index: number;
  countriesLoading?: boolean;
  countries: CountryEntity[];
}

export const Fields = ({ index, countriesLoading, countries }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <Field.Autocomplete
        label={t('onboarding.accountsInOrganizations.country.label')}
        loading={countriesLoading}
        name={`accounts.${index}.country` as 'accounts'}
        optionLabelKey="name"
        options={countries}
        optionValueKey="id"
        placeholder={t(
          'onboarding.accountsInOrganizations.country.placeholder'
        )}
      />

      <Field.Text
        label={t('onboarding.accountsInOrganizations.bic.label')}
        maxLength={maxLengthBic}
        name={`accounts.${index}.bic` as 'accounts'}
        placeholder={t('onboarding.accountsInOrganizations.bic.placeholder')}
      />
    </>
  );
};
