import { CompanyHeads } from '../components';
import { useHeads } from '../hooks/useHeads';
import { PageComponentProps } from '../types';

export const CompanyHeadsStep = ({ canGoBack }: PageComponentProps) => {
  const {
    submitLoading,
    deleteLoading,
    errorHeadsIds,
    onAdd,
    onSubmit,
    goToBack,
    onEdit,
    onDelete,
    heads,
    legalHeads,
  } = useHeads();

  return (
    <CompanyHeads
      canGoBack={canGoBack}
      deleteLoading={deleteLoading}
      errorHeadsIds={errorHeadsIds}
      heads={heads}
      legalHeads={legalHeads}
      submitLoading={submitLoading}
      onAdd={onAdd}
      onDelete={onDelete}
      onEdit={onEdit}
      onGoBack={goToBack}
      onSubmit={onSubmit}
    />
  );
};
