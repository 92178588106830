import { FormLayout, LoadingLayoutWrapper } from 'components';
import { OnboardingKYC } from 'modules/onboarding/common/components';

interface Props {
  loading: boolean;
  token?: string;
  expirationHandler: () => Promise<string>;
}

export const LegalHeadView = ({ token, loading, expirationHandler }: Props) => (
  <FormLayout>
    <LoadingLayoutWrapper loading={loading || !token}>
      <OnboardingKYC expirationHandler={expirationHandler} token={token!} />
    </LoadingLayoutWrapper>
  </FormLayout>
);
