export enum RegistrationUserType {
  Client = 'Client',
  ClientRepresentative = 'ClientRepresentative',
  LegalClientRepresentative = 'LegalClientRepresentative',
}
export interface RegistrationParam {
  email: string;
  firstName: string;
  lastName: string;
  middleName?: string;
  dateOfBirth: Date;
  password: string;
  type: RegistrationUserType;
  agreementIds: string[];
}
