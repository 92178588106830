import { LegalClientBankAccountEntity } from 'api/types/entity/legal/legalClientBankAccountEntity';
import { yesOrNot } from 'helpers';
import { TFunc } from 'libs/i18n';
import { Maybe } from 'types/maybe';

import { CompanyAccountsInOrganizationsFormType } from '../../forms/companyAccountsInOrganizationsForm';

export const getInitialData = (
  bankAccounts: Maybe<LegalClientBankAccountEntity[]>,
  t: TFunc
): CompanyAccountsInOrganizationsFormType => ({
  accounts: bankAccounts?.map((v) => ({
    country: v.country || null,
    bic: v.swiftCode,
    hasPermanentManagementInCountry: yesOrNot(
      t,
      v?.hasPermanentManagementInCountry
    ),
    onTerritoryWithNoAML_CFT: yesOrNot(t, v?.onTerritoryWithNoAML_CFT),
  })) || [
    {
      country: null,
      bic: '',
      hasPermanentManagementInCountry: null,
      onTerritoryWithNoAML_CFT: null,
    },
  ],
});
