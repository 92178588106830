import { LegalClientContactEntity } from 'api/types/entity';
import { yesOrNot } from 'helpers';
import { TFunc } from 'libs/i18n';
import { Maybe } from 'types/maybe';

import { CompanyContactsFormType } from '../../forms/companyContactsForm';

export const getInitialData = (
  data: Maybe<LegalClientContactEntity> | undefined,
  t: TFunc
): CompanyContactsFormType | undefined => {
  if (!data) {
    return undefined;
  }

  return {
    disclosureInformation: yesOrNot(t, data.isInformationDisclosureCompliant),
    disclosureInformationResource:
      data.informationDisclosureCompliantResource ?? undefined,
    phone: data.phoneNumber ?? undefined,
    email: data.email ?? undefined,
    site: data.website ?? undefined,
  };
};
