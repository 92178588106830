import {
  CivilLawSubjectType,
  RoleInCompanyEntity,
  RoleInCompanyType,
} from 'api/types/entity/legalClientRepresentativeEntity/roleInCompanyEntity';
import { FileUpload } from 'hooks/useFilesUpload';
import { TFunc, TranslationPath } from 'libs/i18n';

import { RoleInCompanyFormType } from '../../forms/roleInCompanyForm';

export const RoleInCompanyTypePath: Record<RoleInCompanyType, TranslationPath> =
  {
    [RoleInCompanyType.Director]: 'onboarding.roleInCompany.roles.director',
    [RoleInCompanyType.OneOfDirectors]:
      'onboarding.roleInCompany.roles.oneOfDirectors',
    [RoleInCompanyType.AuthorizedRepresentative]:
      'onboarding.roleInCompany.roles.authorizedRepresentative',
  };

export const CivilLawSubjectTypePath: Record<
  CivilLawSubjectType,
  TranslationPath
> = {
  [CivilLawSubjectType.JuridicalPerson]:
    'onboarding.roleInCompany.civilLawSubjects.juridicalPerson',
  [CivilLawSubjectType.PhysicalPerson]:
    'onboarding.roleInCompany.civilLawSubjects.physicalPerson',
};

export const getInitialData = (
  roleInCompany: RoleInCompanyEntity | undefined,
  t: TFunc
) => {
  const values: RoleInCompanyFormType | undefined = roleInCompany
    ? {
        role: {
          value: roleInCompany.role,
          label: t(RoleInCompanyTypePath[roleInCompany.role]),
        },
        dateOfExpiration:
          roleInCompany.authorityConfirmationDocument.dateOfExpiration,

        dateOfIssue: roleInCompany.authorityConfirmationDocument.dateOfIssue,
        fileIds: roleInCompany.authorityConfirmationDocument.files.map(
          (v) => v.id
        ),
        civilLawSubject: roleInCompany.civilLawSubject
          ? {
              value: roleInCompany.civilLawSubject,
              label: t(CivilLawSubjectTypePath[roleInCompany.civilLawSubject]),
            }
          : undefined,
        number: roleInCompany.authorityConfirmationDocument.number,
      }
    : undefined;

  const files: FileUpload[] | undefined =
    roleInCompany?.authorityConfirmationDocument.files.map(
      (v) =>
        new FileUpload({ name: v.name, size: v.size }, undefined, false, v.id)
    );
  return { values, files };
};
