// eslint-disable-next-line no-restricted-imports
import {
  RoleInCompanyFragment,
  Query_LegalClientRepresentativeController_GetById_RoleInCompany_RoleInCompany as RoleInCompanyTypeApi,
  Query_LegalClientRepresentativeController_GetById_RoleInCompany_CivilLawSubject as CivilLawSubjectTypeApi,
} from 'api/generated/graphql';
import {
  CivilLawSubjectType,
  RoleInCompanyEntity,
  RoleInCompanyType,
} from 'api/types/entity';
import { lodash } from 'helpers';

import { parseFileEntity } from '../../helpers';

const roleMap: Record<RoleInCompanyTypeApi, RoleInCompanyType> = {
  [RoleInCompanyTypeApi.AuthorizedRepresentative]:
    RoleInCompanyType.AuthorizedRepresentative,
  [RoleInCompanyTypeApi.Director]: RoleInCompanyType.Director,
  [RoleInCompanyTypeApi.OneOfDirectors]: RoleInCompanyType.OneOfDirectors,
};

const civilLawSubjectMap: Record<CivilLawSubjectTypeApi, CivilLawSubjectType> =
  {
    [CivilLawSubjectTypeApi.JuridicalPerson]:
      CivilLawSubjectType.JuridicalPerson,
    [CivilLawSubjectTypeApi.PhysicalPerson]: CivilLawSubjectType.PhysicalPerson,
  };

export const parseRoleInCompanyEntity = (
  roleInCompany: RoleInCompanyFragment
): RoleInCompanyEntity => ({
  authorityConfirmationDocument: {
    dateOfExpiration: new Date(
      roleInCompany.authorityConfirmationDocument.dateOfExpiration
    ),
    dateOfIssue: new Date(
      roleInCompany.authorityConfirmationDocument.dateOfIssue
    ),
    files: lodash
      .compact(roleInCompany.authorityConfirmationDocument.files)
      .map(parseFileEntity),
    number: roleInCompany.authorityConfirmationDocument.number || undefined,
  },
  civilLawSubject: roleInCompany.civilLawSubject
    ? civilLawSubjectMap[roleInCompany.civilLawSubject]
    : undefined,
  role: roleMap[roleInCompany.roleInCompany],
});
