import { useState } from 'react';

import { ContentStepper, ContentStepperStep } from 'components';
import { Stepper } from 'components/Stepper';
import { Stack } from 'libs/ui';

import { RegistrationType } from '../../types';
import { RegistrationLayout } from '../RegistrationLayout';
import { RegistrationProvider } from '../RegistrationProvider';

interface RegistrationStepperProps {
  steps: ContentStepperStep[];
  initialActiveIndex?: number;
  agreementIds: string[];
  registrationType: RegistrationType;
  hideStepper?: boolean;
  onBack: () => void;
  onEnd: () => void;
}

export const RegistrationStepper = ({
  steps,
  initialActiveIndex = 0,
  agreementIds,
  registrationType,
  hideStepper = false,
  onBack,
  onEnd,
}: RegistrationStepperProps) => {
  const [activeIndex, setActiveIndex] = useState(initialActiveIndex);

  return (
    <RegistrationLayout>
      <Stack spacing={32}>
        {!hideStepper && (
          <Stepper activeIndex={activeIndex} count={steps.length} />
        )}
        <RegistrationProvider
          agreementIds={agreementIds}
          registrationType={registrationType}
        >
          <ContentStepper
            initialActiveIndex={initialActiveIndex}
            steps={steps}
            onBack={onBack}
            onChangeStep={setActiveIndex}
            onEnd={onEnd}
          />
        </RegistrationProvider>
      </Stack>
    </RegistrationLayout>
  );
};
