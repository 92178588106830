import { useContext } from 'react';

import {
  LegalClientEntity,
  LegalClientLegalHeadEntity,
  LegalClientRepresentativeEntity,
} from 'api/types/entity';
import { LegalClientCreateLegalHeadSaveParam } from 'api/types/params';
import {
  OnboardingContext,
  OnboardingContextProps,
} from 'modules/onboarding/common/providers/OnboardingProvider/Context';

export const useOnboardingContext = () => {
  const res = useContext(OnboardingContext);
  return res as OnboardingContextProps<{
    currentClient: LegalClientRepresentativeEntity;
    legalClient: LegalClientEntity;
    legalHead: LegalClientLegalHeadEntity;
    onSetFirstHead: (id?: string) => void;
    onCreateLegalHead: (
      param: LegalClientCreateLegalHeadSaveParam
    ) => Promise<boolean>;
  }>;
};
