import { FormContainer, FormContainerStepProps } from 'components';
import { getYesOrNoOptions } from 'helpers';
import { useTranslation } from 'libs/i18n';

import {
  ControlOverFormType,
  getSchema,
  FormErrors,
  Field,
} from '../../forms/controlOverForm';

interface Props extends FormContainerStepProps {
  initialValues?: ControlOverFormType;
  canGoBack?: boolean;
  formError?: FormErrors;
  submitLoading?: boolean;
  onSubmit: (values: ControlOverFormType) => void;
  onGoBack?: () => void;
}

export const ControlOver = ({
  initialValues,
  submitLoading,
  formError,
  canGoBack,
  step,
  onGoBack,
  onSubmit,
}: Props) => {
  const { t } = useTranslation();
  const schema = getSchema();
  return (
    <FormContainer
      canGoBack={canGoBack}
      initialErrors={formError}
      initialValues={initialValues}
      step={step}
      submitLoading={submitLoading}
      subTitle={t('onboarding.legalClientBeneficiary.controlOver.subtitle')}
      title={t('onboarding.legalClientBeneficiary.controlOver.title')}
      validationSchema={schema}
      onGoBack={onGoBack}
      onSubmit={onSubmit}
    >
      <Field.Autocomplete
        label={t(
          'onboarding.legalClientBeneficiary.controlOver.hasControlOverLegalClient.label'
        )}
        name="hasControlOverLegalClient"
        optionLabelKey="label"
        options={getYesOrNoOptions(t)}
        optionValueKey="value"
        placeholder={t('common.select.placeholder')}
        variant="select"
      />
      <Field.Number
        format={(v) => (!v ? v : Number(v).toString())}
        label={t(
          'onboarding.legalClientBeneficiary.controlOver.directCapitalParticipationPercentage.label'
        )}
        maxNumber={100}
        name="directCapitalParticipationPercentage"
        placeholder={t(
          'onboarding.legalClientBeneficiary.controlOver.directCapitalParticipationPercentage.placeholder'
        )}
      />

      <Field.Number
        format={(v) => (!v ? v : Number(v).toString())}
        label={t(
          'onboarding.legalClientBeneficiary.controlOver.indirectCapitalParticipationPercentage.label'
        )}
        maxNumber={100}
        name="indirectCapitalParticipationPercentage"
        placeholder={t(
          'onboarding.legalClientBeneficiary.controlOver.indirectCapitalParticipationPercentage.placeholder'
        )}
      />

      <Field.Price
        label={t(
          'onboarding.legalClientBeneficiary.controlOver.financialConditionAmount.label'
        )}
        name="financialConditionAmount"
        placeholder={t(
          'onboarding.legalClientBeneficiary.controlOver.financialConditionAmount.placeholder'
        )}
      />
    </FormContainer>
  );
};
