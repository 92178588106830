import { useRef } from 'react';

import { useLegalClientBranchSaveContacts } from 'api/requests/onboarding/legal/branch';
import { useServerErrorNotify } from 'hooks';

import { ContactsFormType } from '../forms/contactsForm';
import { getInitialData, getSubmitData } from '../helpers/contacts';

import { useOnboardingContext } from './useOnboardingContext';

export const useContacts = () => {
  const { goToBack, goToNext, data } = useOnboardingContext();

  const { error, loading, saveContacts } = useLegalClientBranchSaveContacts();

  const initialValues = useRef(
    getInitialData(data.legalClientBranch.contacts)
  ).current;

  const onSubmit = async (values: ContactsFormType) => {
    if (values) {
      const submitData = getSubmitData(values);
      await saveContacts(
        data.legalClient.id,
        data.legalClientBranch.id,
        submitData
      );
      goToNext();
    }
  };

  useServerErrorNotify(error);

  return {
    onSubmit,
    goToBack,
    submitLoading: loading,
    initialValues,
  };
};
