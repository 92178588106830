import { useEffect, useRef } from 'react';

import { useLegalClientSaveInvoicePaymentConfirmationFiles } from 'api/requests';
import { useFilesUpload } from 'hooks';
import { InvoiceConfirmForPaymentFormType } from 'modules/onboarding/common/forms/invoiceConfirmForPaymentForm';
import { useClientInvoice } from 'modules/onboarding/common/hooks';

import { useOnboardingContext } from './useOnboardingContext';

export const useInvoiceConfirmForPayment = () => {
  const { data, goToBack, goToNext } = useOnboardingContext();

  const { getInvoice, invoiceLink } = useClientInvoice(data.legalClient);

  const { saveInvoicePaymentConfirmationFiles, loading: submitLoading } =
    useLegalClientSaveInvoicePaymentConfirmationFiles();

  const initialValues: InvoiceConfirmForPaymentFormType = useRef({
    fileIds: [],
  }).current;

  const {
    files,
    isLoadingFile: submitDisabled,
    onRemoveFile,
    onAddFiles,
  } = useFilesUpload([]);

  const onSubmit = async (values: InvoiceConfirmForPaymentFormType) => {
    if (values) {
      await saveInvoicePaymentConfirmationFiles(
        data.legalClient?.id!,
        values.fileIds
      );
      goToNext();
    }
  };

  useEffect(() => {
    getInvoice?.();
  }, [getInvoice]);

  return {
    onSubmit,
    files,
    invoiceLink,
    onRemoveFile,
    onAddFiles,
    goToBack,
    submitDisabled,
    initialValues,
    submitLoading,
  };
};
