import { LegalClientBranchSaveContactsParam } from 'api/types/params';

import { ContactsFormType } from '../../forms/contactsForm';

export const getSubmitData = (
  values: ContactsFormType
): LegalClientBranchSaveContactsParam => ({
  email: values.email,
  phoneNumber: values.phone,
});
