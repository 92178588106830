import { FormContainerStepProps } from 'components';
import { useTranslation } from 'libs/i18n';
import {
  ClientType,
  FormBodyWrapper,
} from 'modules/onboarding/common/components';

import {
  CompanyTaxResidencyFormType,
  FormErrors,
  getSchema,
} from '../../forms/companyTaxResidencyForm';

import { FormContent, FormContentProps } from './FormContent';

interface CompanyTaxResidencyProps
  extends FormContentProps,
    FormContainerStepProps {
  initialValues?: CompanyTaxResidencyFormType;
  onSubmit: (values: CompanyTaxResidencyFormType) => void;
  canGoBack?: boolean;
  onGoBack?: () => void;
  formError?: FormErrors;
  submitLoading?: boolean;
  clientType?: ClientType;
  subTitle?: string;
}

export const CompanyTaxResidency = ({
  initialValues,
  canGoBack,
  onGoBack,
  submitLoading,
  formError,
  onSubmit,
  step,
  clientType,
  subTitle,
  ...formProps
}: CompanyTaxResidencyProps) => {
  const schema = getSchema();
  const { t } = useTranslation();
  return (
    <FormBodyWrapper
      canGoBack={canGoBack}
      clientType={clientType}
      initialErrors={formError}
      initialValues={initialValues}
      step={step}
      submitLoading={submitLoading}
      subTitle={subTitle ?? t('onboarding.companyTaxResidency.subtitle')}
      title={t('onboarding.taxResidency.title')}
      validationSchema={schema}
      onGoBack={onGoBack}
      onSubmit={onSubmit}
    >
      <FormContent {...formProps} />
    </FormBodyWrapper>
  );
};
