import { SvgIcon } from '../SvgIcon';
import { SvgProps } from '../types';

export const Minus = ({ size = 24, className, color }: SvgProps) => (
  <SvgIcon className={className} size={size}>
    <svg
      color={color}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 12L4 12"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="2"
      />
    </svg>
  </SvgIcon>
);
