import { useRef } from 'react';

import { useClientSaveTaxResidences } from 'api/requests';
import { ClientEntity } from 'api/types/entity';
import { useServerErrorNotify } from 'hooks';
import { OnboardingTaxResidencyFormType } from 'modules/onboarding/common/forms/onboardingTaxResidencyForm';
import { useOnboardingCountries } from 'modules/onboarding/common/hooks';

import { getInitialData, getSubmitData } from '../helpers/taxResidency';

export const useClientTaxResidency = (client: ClientEntity) => {
  const { saveTaxResidences, error, loading } = useClientSaveTaxResidences();

  const { countries, loading: countriesLoading } = useOnboardingCountries();

  const initialValues = useRef(getInitialData(client.taxResidences)).current;

  const onSubmit = async (values: OnboardingTaxResidencyFormType) => {
    if (values.taxValues) {
      const data = getSubmitData(values);
      await saveTaxResidences(client.id, data);
    }
  };
  useServerErrorNotify(error);

  return {
    onSubmit,
    submitLoading: loading,
    initialValues,
    countries,
    countriesLoading,
  };
};
