import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useClientSaveKycStepMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';

import { parseClientEntity } from '../helpers';

export const useClientSaveKycStep = () => {
  const [saveKycStep, { data, loading, error: apolloError }] =
    useClientSaveKycStepMutation();

  const error = useError(apolloError);

  return {
    data: data?.clientSaveKycStep
      ? parseClientEntity(data.clientSaveKycStep)
      : undefined,
    loading,
    error,
    saveKycStep: useCallback(
      async (id: string) => {
        const res = await saveKycStep({
          variables: { id },
        });
        return res.data?.clientSaveKycStep
          ? parseClientEntity(res.data.clientSaveKycStep)
          : undefined;
      },
      [saveKycStep]
    ),
  };
};
