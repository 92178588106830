import { FormLayout, LoadingViewWrapper } from 'components';
import { OnboardingKYC } from 'modules/onboarding/common/components';

interface Props {
  loading: boolean;
  token?: string;
  expirationHandler: () => Promise<string>;
}

export const ClientWithRepresentativeView = ({
  token,
  loading,
  expirationHandler,
}: Props) => (
  <FormLayout>
    <LoadingViewWrapper loading={loading || !token}>
      <OnboardingKYC expirationHandler={expirationHandler} token={token!} />
    </LoadingViewWrapper>
  </FormLayout>
);
