import { FormContainer, FormContainerStepProps } from 'components';
import { useTranslation } from 'libs/i18n';

import {
  FormErrors,
  getSchema,
  PositionInCompanyFormType,
} from '../../forms/positionInCompanyForm';

import { FormContent, FormContentProps } from './FormContent';

interface Props extends FormContentProps, FormContainerStepProps {
  initialValues?: PositionInCompanyFormType;
  onSubmit: (values: PositionInCompanyFormType) => void;
  canGoBack?: boolean;
  onGoBack?: () => void;
  formError?: FormErrors;
  submitDisabled?: boolean;
  submitLoading?: boolean;
  subTitle?: string;
}

export const PositionInCompany = ({
  initialValues,
  canGoBack,
  onGoBack,
  submitLoading,
  submitDisabled,
  formError,
  subTitle,
  step,
  onSubmit,
  ...formProps
}: Props) => {
  const { t } = useTranslation();
  const schema = getSchema(t);

  return (
    <FormContainer
      canGoBack={canGoBack}
      initialErrors={formError}
      initialValues={initialValues}
      step={step}
      submitDisabled={submitDisabled}
      submitLoading={submitLoading}
      subTitle={subTitle ?? t('onboarding.positionInCompany.subtitle')}
      title={t('onboarding.positionInCompany.title')}
      validationSchema={schema}
      onGoBack={onGoBack}
      onSubmit={onSubmit}
    >
      <FormContent {...formProps} />
    </FormContainer>
  );
};
