import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useClientSaveAddressesMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { AddressesWithFilesParam } from 'api/types/params/onboarding/addressesParam';

import { parseClientEntity } from '../helpers';

export const useClientSaveAddresses = () => {
  const [handle, { data, loading, error: apolloError }] =
    useClientSaveAddressesMutation();

  const error = useError(apolloError);

  return {
    data: data?.clientSaveAddresses
      ? parseClientEntity(data.clientSaveAddresses)
      : undefined,
    loading,
    error,
    saveAddresses: useCallback(
      async (id: string, params: AddressesWithFilesParam) => {
        const res = await handle({
          variables: {
            id,
            input: params,
          },
        });
        return res?.data?.clientSaveAddresses
          ? parseClientEntity(res.data.clientSaveAddresses)
          : undefined;
      },
      [handle]
    ),
  };
};
