import { LegalClientBaseContactEntity } from 'api/types/entity';
import { Maybe } from 'types/maybe';

import { ContactsFormType } from '../../forms/contactsForm';

export const getInitialData = (
  data: Maybe<LegalClientBaseContactEntity> | undefined
): ContactsFormType | undefined => {
  if (!data) {
    return undefined;
  }

  return {
    phone: data.phoneNumber ?? '',
    email: data.email ?? '',
  };
};
