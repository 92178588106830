import { ContentStepperComponentProps } from 'components';
import { useTranslation } from 'libs/i18n';
import { ROUTES, useNavigate } from 'libs/navigation';
import { useNotify } from 'libs/notify';

import { RestorePassword } from '../components';
import { RestorePasswordFormType } from '../forms/restorePasswordForm';
import { useRestorePassword } from '../hooks';

export const RestorePasswordStep = ({
  canGoBack,
  onGoBack,
}: ContentStepperComponentProps) => {
  const { t } = useTranslation();

  const { navigate } = useNavigate();

  const notify = useNotify();

  const {
    passwordValidationRule,
    isOpenVerificationDialog,
    submitLoading,
    formError,
    onCloseVerificationDialog,
    restorePassword,
  } = useRestorePassword();

  const onSubmit = async (values: RestorePasswordFormType) => {
    if (await restorePassword(values)) {
      notify.info(t('auth.restorePassword.successRestore'));
      navigate(ROUTES.login.fullPath, {});
    }
  };

  return (
    <RestorePassword
      canGoBack={canGoBack}
      initialErrors={formError}
      isOpenVerificationDialog={isOpenVerificationDialog}
      passwordValidationRule={passwordValidationRule}
      submitLoading={submitLoading}
      onCloseVerificationDialog={onCloseVerificationDialog}
      onGoBack={onGoBack}
      onSubmit={onSubmit}
    />
  );
};
