// eslint-disable-next-line no-restricted-imports
import { PositionInCompanyFragment } from 'api/generated/graphql';
import { PositionInCompanyEntity } from 'api/types/entity';
import { lodash } from 'helpers';

import { parseFileEntity } from '../../helpers';

export const parsePositionInCompanyEntity = (
  positionInCompany: PositionInCompanyFragment
): PositionInCompanyEntity => ({
  position: positionInCompany.positionInCompany,
  authorityConfirmationDocument: {
    dateOfExpiration: new Date(
      positionInCompany.positionConfirmationDocument.dateOfExpiration
    ),

    files: lodash
      .compact(positionInCompany.positionConfirmationDocument.files)
      .map(parseFileEntity),
  },
});
