import { createContext } from 'react';

import { NotificationTypes } from './types';

export interface NotificationActions {
  logAndError: (error: unknown, defaultMessage?: string) => void;
  error: (error: unknown, defaultMessage?: string) => void;
  info: (text: string) => void;
  show: (type: NotificationTypes, text: string) => void;
}

export const NotificationsContext = createContext<NotificationActions | null>(
  null
);
