import { useCallback } from 'react';

import { useClientConfirmationData } from 'modules/onboarding/common/hooks';

import { useOnboardingContext } from './useOnboardingContext';

export const useConfirmationData = () => {
  const { goToBack, clientId, goToNext } = useOnboardingContext();

  const { confirm, error, loading } = useClientConfirmationData(clientId);

  const onConfirm = useCallback(async () => {
    await confirm();
    goToNext();
  }, [confirm, goToNext]);

  return { onConfirm, goToBack, loading, error };
};
