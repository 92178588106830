import {
  ClientType,
  OnboardingPepConnection,
} from 'modules/onboarding/common/components';

import { useOwnerPepConnection } from '../hooks';
import { PageComponentProps } from '../types';

export const OwnerPepConnectionStep = ({ canGoBack }: PageComponentProps) => {
  const { goToBack, initialValues, onSubmit, submitLoading } =
    useOwnerPepConnection();

  return (
    <OnboardingPepConnection
      canGoBack={canGoBack}
      clientType={ClientType.Client}
      initialValues={initialValues}
      submitLoading={submitLoading}
      onGoBack={goToBack}
      onSubmit={onSubmit}
    />
  );
};
