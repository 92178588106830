import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import {
  OnboardingLegalClientDocument,
  useLegalClientCreateBodyMutation,
} from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { LegalClientBodyParam } from 'api/types/params';

export const useLegalClientCreateBody = () => {
  const [handle, { loading, error: apolloError }] =
    useLegalClientCreateBodyMutation({
      refetchQueries: [OnboardingLegalClientDocument],
      awaitRefetchQueries: true,
    });

  const error = useError(apolloError);

  return {
    loading,
    error,
    createBody: useCallback(
      async (id: string, params: LegalClientBodyParam) => {
        const res = await handle({
          variables: {
            id,
            input: params,
          },
        });
        return !!res?.data?.legalClientCreateBody;
      },
      [handle]
    ),
  };
};
