import { useContext } from 'react';

import { ClientEntity, ClientRepresentativeEntity } from 'api/types/entity';
import {
  OnboardingContext,
  OnboardingContextProps,
} from 'modules/onboarding/common/providers/OnboardingProvider/Context';

export const useOnboardingContext = () => {
  const res = useContext(OnboardingContext);
  return res as OnboardingContextProps<{
    currentClient: ClientRepresentativeEntity;
    ownerClient: ClientEntity | null;
    refetchCurrentClient: () => void;
  }>;
};
