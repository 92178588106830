import { BaseDebtsEntity } from 'api/types/entity';
import { yesOrNot } from 'helpers';
import { TFunc } from 'libs/i18n';
import { BaseDebtsFormType } from 'modules/onboarding/common/forms/baseDebtsForm';

export const getBaseDebtsInitialData = (
  baseDebsEntity: BaseDebtsEntity | undefined,
  t: TFunc
): BaseDebtsFormType | undefined => {
  const bankruptcyProceedings = yesOrNot(
    t,
    baseDebsEntity?.hasBankruptcyProceedings
  );
  const delaysOrNonFulfillmentOfObligations = yesOrNot(
    t,
    baseDebsEntity?.hasDelaysOrNonFulfillmentOfObligations
  );

  const values: BaseDebtsFormType | undefined = baseDebsEntity
    ? {
        ...baseDebsEntity,

        bankruptcyProceedings,
        delaysOrNonFulfillmentOfObligations,
      }
    : undefined;

  return values;
};
