import { useEffect, useMemo } from 'react';

import {
  useClientRepresentativeSaveOwnerEmail,
  useOnboardingClientUserLazy,
  useSendLoginEmailToClient,
} from 'api/requests';
import { KYCStatus } from 'api/types/data';
import { ClientEntity, ClientRepresentativeEntity } from 'api/types/entity';
import { ErrorCode } from 'api/types/error';
import { parseFullName } from 'helpers';
import { useTranslation } from 'libs/i18n';
import { useNotify } from 'libs/notify';
import { WaitingKycUserType } from 'modules/onboarding/common/components';

import { FormErrors } from '../forms/emailForm';

export const useWaitingOwnerKyc = (
  currentClient: ClientRepresentativeEntity | null,
  ownerClient: ClientEntity | null
) => {
  const notify = useNotify();
  const { t } = useTranslation();

  const isWaitingOwnerKyc =
    currentClient?.kyc?.status === KYCStatus.success &&
    ownerClient?.kyc?.status !== KYCStatus.success;

  const { getClientUser: getOwnerClientUser, clientUser } =
    useOnboardingClientUserLazy();

  const {
    saveOwnerEmail,
    loading: loadingOwnerEmailEdit,
    error,
  } = useClientRepresentativeSaveOwnerEmail();

  const onEditOwnerEmail = async (id: string, email: string) => {
    const handleResult = (res: any) => {
      if (res) {
        notify.info(t('onboarding.waitingKyc.emailSuccessEdit'));
      }
      return !!res;
    };

    const res = await saveOwnerEmail(ownerClient?.id!, email);
    return handleResult(res);
  };

  const ownerClientUser = useMemo<WaitingKycUserType | undefined>(
    () =>
      clientUser
        ? {
            id: clientUser.id,
            isActive: clientUser.isActive,
            done: false,
            email: clientUser.email,
            name: parseFullName(clientUser),
          }
        : undefined,
    [clientUser]
  );

  const formErrorSaveOwnerEmail = useMemo((): FormErrors | undefined => {
    if (
      error?.code === ErrorCode.ACCOUNT_ALREADY_ACTIVE ||
      error?.code === ErrorCode.USER_WITH_EMAIL_ALREADY_EXISTS
    ) {
      return { email: error?.message };
    }

    return undefined;
  }, [error]);

  const { sendLoginEmail: sendOwnerLoginEmail } = useSendLoginEmailToClient();

  const handleSendOwnerLoginEmail = () =>
    sendOwnerLoginEmail(currentClient!.id);

  useEffect(() => {
    if (isWaitingOwnerKyc && ownerClient?.id) {
      getOwnerClientUser(ownerClient.id);
    }
  }, [getOwnerClientUser, isWaitingOwnerKyc, ownerClient?.id]);

  return {
    isWaitingOwnerKyc,
    ownerClientUser,
    formErrorSaveOwnerEmail,
    loadingOwnerEmailEdit,
    getOwnerClientUser,
    onEditOwnerEmail,
    sendOwnerLoginEmail: handleSendOwnerLoginEmail,
  };
};
