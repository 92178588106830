import { FormContainer } from 'components';
import { useTranslation } from 'libs/i18n';

import {
  CompanyFinancialMonitoringFormType,
  FormErrors,
  getSchema,
} from '../../forms/companyFinancialMonitoringForm';

import { FormContent, FormContentProps } from './FormContent';

interface Props extends FormContentProps {
  initialValues?: CompanyFinancialMonitoringFormType;
  onSubmit: (values: CompanyFinancialMonitoringFormType) => void;
  canGoBack?: boolean;
  onGoBack?: () => void;
  formError?: FormErrors;
  submitLoading?: boolean;
  hasBranches: boolean;
}

export const CompanyFinancialMonitoring = ({
  initialValues,
  canGoBack,
  onGoBack,
  submitLoading,
  formError,
  onSubmit,
  ...formProps
}: Props) => {
  const { t } = useTranslation();
  const schema = getSchema(t, formProps.hasBranches);

  return (
    <FormContainer
      canGoBack={canGoBack}
      initialErrors={formError}
      initialValues={initialValues}
      submitLoading={submitLoading}
      subTitle={t('onboarding.companyFinancialMonitoring.subtitle')}
      title={t('onboarding.companyFinancialMonitoring.title')}
      validationSchema={schema}
      onGoBack={onGoBack}
      onSubmit={onSubmit}
    >
      <FormContent {...formProps} />
    </FormContainer>
  );
};
