import { ClientType, CompanyName } from 'modules/onboarding/common/components';

import { useLegalRepresentativeCompanyName } from '../../hooks';
import { PageComponentProps } from '../../types';

export const LegalRepresentativeCompanyNameStep = ({
  canGoBack,
}: PageComponentProps) => {
  const { initialValues, onSubmit, submitLoading, goToBack } =
    useLegalRepresentativeCompanyName();

  return (
    <CompanyName
      canGoBack={canGoBack}
      clientType={ClientType.ClientRepresentative}
      initialValues={initialValues}
      submitLoading={submitLoading}
      onGoBack={goToBack}
      onSubmit={onSubmit}
    />
  );
};
