// eslint-disable-next-line no-restricted-imports
import {
  Query_LegalClientController_GetById_FinancialMonitoring_Info_AmlPolicyReviewFrequency as AmlPolicyReviewFrequencyApi,
  Query_LegalClientController_GetById_FinancialMonitoring_Info_MonetaryServiceTransactionCountForLastSixMonths as MonetaryServiceTransactionCountForLastSixMonthsApi,
  FinancialMonitoringDto_Input,
} from 'api/generated/graphql';
import { LegalClientFinancialMonitoringEntity } from 'api/types/entity';
import { formatToISODate } from 'helpers';

export const parseFinancialMonitoringParams = (
  params: LegalClientFinancialMonitoringEntity
): FinancialMonitoringDto_Input => ({
  isSubjectToRegulation: params.isSubjectToRegulation,
  info: params.info
    ? {
        ...params.info,
        amlPolicyReviewFrequency:
          AmlPolicyReviewFrequencyApi[params.info.amlPolicyReviewFrequency],
        monetaryServiceTransactionCountForLastSixMonths:
          MonetaryServiceTransactionCountForLastSixMonthsApi[
            params.info.monetaryServiceTransactionCountForLastSixMonths
          ],
        dateOfLastCheck: params.info.dateOfLastCheck
          ? formatToISODate(params.info.dateOfLastCheck)
          : undefined,
      }
    : undefined,
});
