import {
  TypedForm,
  FormErrors as FormErrorsBase,
  ObjectSchema,
  object,
  string,
  email,
} from 'libs/form';
import { TFunc } from 'libs/i18n';
import { phoneMaxLength } from 'modules/onboarding/legal/forms/companyContactsForm';

export interface ContactsFormType {
  phone?: string;
  email?: string;
}

export const getSchema = (t: TFunc): ObjectSchema<ContactsFormType> =>
  object({
    phone: string().max(phoneMaxLength),
    email: email(t),
  });

export const { Field, Form, useFormContext, Submit } =
  TypedForm<ContactsFormType>();

export type FormErrors = FormErrorsBase<ContactsFormType>;
