import { useCallback, useMemo } from 'react';

// eslint-disable-next-line no-restricted-imports
import {
  OnboardingLegalClientParticipantsFragment,
  useOnboardingLegalClientParticipantsLazyQuery,
} from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { lodash } from 'helpers';

import { parseLegalClientBeneficiaryItemEntity } from './helpers/innerItemEntity/parseLegalClientBeneficiaryItemEntity';
import { parseLegalClientHeadItemEntity } from './helpers/innerItemEntity/parseLegalClientHeadItemEntity';
import { parseLegalClientLegalHeadItemEntity } from './helpers/innerItemEntity/parseLegalClientLegalHeadItemEntity';

const parseParticipants = (
  data: OnboardingLegalClientParticipantsFragment
) => ({
  beneficiaries: data.beneficiaries
    ? lodash
        .compact(data.beneficiaries)
        .map(parseLegalClientBeneficiaryItemEntity)
    : undefined,
  heads: data.heads
    ? lodash.compact(data.heads).map(parseLegalClientHeadItemEntity)
    : undefined,
  legalHeads: data.legalHeads
    ? lodash.compact(data.legalHeads).map(parseLegalClientLegalHeadItemEntity)
    : undefined,
});

export const useOnboardingLegalClientParticipantsLazy = () => {
  const [handle, { data, loading, error: apolloError, refetch }] =
    useOnboardingLegalClientParticipantsLazyQuery({
      notifyOnNetworkStatusChange: true,
    });

  const getLegalClientParticipants = useCallback(
    async (id: string) => {
      const res = await handle({ variables: { id } });

      return res?.data?.onboardingLegalClientParticipants
        ? parseParticipants(res.data.onboardingLegalClientParticipants)
        : null;
    },
    [handle]
  );

  const legalClientParticipants = useMemo(
    () =>
      data?.onboardingLegalClientParticipants
        ? parseParticipants(data.onboardingLegalClientParticipants)
        : null,
    [data?.onboardingLegalClientParticipants]
  );

  const error = useError(apolloError);

  return {
    getLegalClientParticipants,
    refetch,
    legalClientParticipants,
    loading,
    error,
  };
};
