import { Stack } from 'libs/ui/Stack';
import { styled } from 'libs/ui/styled';

export const ContainerStyled = styled(Stack)(() => ({
  '.fileContainer-content': {
    overflow: 'hidden',

    '.fileContainer-fileInfo': {
      overflow: 'hidden',

      '.fileContainer-fileName': {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
      },
    },
  },
}));
