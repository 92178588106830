import { LegalClientSaveConstitutionalDocumentParam } from 'api/types/params';
import { formatToISODate } from 'helpers';

import { CompanyConstituentDocsFormType } from '../../forms/companyConstituentDocs';

export const getSubmitData = (
  values: CompanyConstituentDocsFormType
): LegalClientSaveConstitutionalDocumentParam => ({
  dateOfLastChange: formatToISODate(values.dateOfLastChange),
  fileIds: values.fileIds,
});
