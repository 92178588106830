// eslint-disable-next-line no-restricted-imports
import { ClientUserEntity } from 'api/types/entity';

interface Data {
  firstName: string;
  lastName: string;
  middleName?: string | null;
  user: {
    id: string;
    email: string;
    isActive: boolean;
  };
}

export const parseClientUserEntity = (data: Data): ClientUserEntity => ({
  id: data.user.id,
  email: data.user.email,
  isActive: data.user.isActive,
  firstName: data.firstName,
  lastName: data.lastName,
  middleName: data.middleName ?? undefined,
});
