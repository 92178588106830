import { RegistrationPersonalData } from '../components/RegistrationPersonalData';
import { useRegistrationContext } from '../components/RegistrationProvider';
import { RegistrationPersonalDataFormType } from '../forms/registrationPersonalDataForm';
import { RegistrationStepProps, RegistrationType } from '../types';

export const RegistrationPersonalDataStep = ({
  canGoBack,
  onGoBack,
  onGoNext,
}: RegistrationStepProps) => {
  const {
    changeContextData,
    registrationType,
    contextData: { firstname, lastname, surname, dateOfBirth, representative },
  } = useRegistrationContext();

  const onSubmit = async (values: RegistrationPersonalDataFormType) => {
    changeContextData(values);
    onGoNext();
  };

  return (
    <RegistrationPersonalData
      canGoBack={canGoBack}
      initialValues={{
        firstname,
        lastname,
        surname,
        dateOfBirth,
        representative,
      }}
      shownRepresentativeSwitch={registrationType !== RegistrationType.legal}
      onGoBack={onGoBack}
      onSubmit={onSubmit}
    />
  );
};
