import { yesOrNotSelectValidation } from 'helpers';
import {
  TypedForm,
  FormErrors as FormErrorsBase,
  ObjectSchema,
  object,
  array,
} from 'libs/form';
import {
  account,
  OnboardingAccountsInOrganizationsFormValue,
} from 'modules/onboarding/common/forms/onboardingAccountsInOrganizationsForm';
import { Maybe } from 'types/maybe';
import { YesOrNoSelectType } from 'types/yesOrNo';

export interface CompanyAccountsInOrganizationsFormValue
  extends OnboardingAccountsInOrganizationsFormValue {
  hasPermanentManagementInCountry: Maybe<YesOrNoSelectType>;
  onTerritoryWithNoAML_CFT: Maybe<YesOrNoSelectType>;
}

export interface CompanyAccountsInOrganizationsFormType {
  accounts: CompanyAccountsInOrganizationsFormValue[];
}

export const getSchema =
  (): ObjectSchema<CompanyAccountsInOrganizationsFormType> =>
    object({
      accounts: array()
        .of(
          object({
            hasPermanentManagementInCountry:
              yesOrNotSelectValidation.required(),
            onTerritoryWithNoAML_CFT: yesOrNotSelectValidation.required(),
          }).concat(account)
        )
        .required(),
    });

export const { Field, Form, useFormContext, Submit } =
  TypedForm<CompanyAccountsInOrganizationsFormType>();

export type FormErrors = FormErrorsBase<CompanyAccountsInOrganizationsFormType>;
