import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useLegalClientLegalHeadFirstHeadSavePepMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { SavePepConnectionParam } from 'api/types/params';

import { parsePepConnectionParam } from '../../helpers';

import { parseLegalClientLegalHeadFirstHeadEntity } from './helpers';

export const useLegalClientLegalHeadFirstHeadSavePepConnection = () => {
  const [savePepConnection, { data, loading, error: apolloError }] =
    useLegalClientLegalHeadFirstHeadSavePepMutation();

  const error = useError(apolloError);

  return {
    data: data?.legalClientLegalHeadFirstHeadSavePep
      ? parseLegalClientLegalHeadFirstHeadEntity(
          data.legalClientLegalHeadFirstHeadSavePep
        )
      : undefined,
    loading,
    error,
    savePepConnection: useCallback(
      async (
        id: string,
        legalHeadId: string,
        firstHeadId: string,
        params: SavePepConnectionParam
      ) => {
        const res = await savePepConnection({
          variables: {
            id,
            legalHeadId,
            firstHeadId,
            input: parsePepConnectionParam(params),
          },
        });
        return res.data?.legalClientLegalHeadFirstHeadSavePep
          ? parseLegalClientLegalHeadFirstHeadEntity(
              res.data.legalClientLegalHeadFirstHeadSavePep
            )
          : undefined;
      },
      [savePepConnection]
    ),
  };
};
