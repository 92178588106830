import { LegalClientFinancialMonitoringEntity } from 'api/types/entity';
import { yesOrNot } from 'helpers';
import { TFunc } from 'libs/i18n';

import {
  CompanyFinancialMonitoringFormType,
  getAmlPolicyReviewFrequencySelectedOption,
  getMonetaryServiceTransactionCountForLastSixMonthsSelectedOption,
} from '../../forms/companyFinancialMonitoringForm';

export const getInitialData = (
  t: TFunc,
  data?: LegalClientFinancialMonitoringEntity
): CompanyFinancialMonitoringFormType | undefined => {
  if (!data) {
    return undefined;
  }

  return {
    isSubjectToRegulation: yesOrNot(t, data.isSubjectToRegulation),
    authorityName: data.info?.authorityName,
    authorityWebsite: data.info?.authorityWebsite ?? undefined,
    hasAmlCftComplianceControl: yesOrNot(
      t,
      data.info?.hasAmlCftComplianceControl
    ),
    hasTransactionMonitoringAndSuspiciousActivityDetection: yesOrNot(
      t,
      data.info?.hasTransactionMonitoringAndSuspiciousActivityDetection
    ),
    amlPolicyReviewFrequency: getAmlPolicyReviewFrequencySelectedOption(
      t,
      data.info?.amlPolicyReviewFrequency
    ),
    otherAmlPolicyReviewFrequency:
      data.info?.otherAmlPolicyReviewFrequency ?? undefined,
    amlCftPoliciesApplyToForeignEntities: yesOrNot(
      t,
      data.info?.amlCftPoliciesApplyToForeignEntities ?? undefined
    ),
    hasAmlCftDocumentEffectivenessAssessmentProcedures: yesOrNot(
      t,
      data.info?.hasAmlCftDocumentEffectivenessAssessmentProcedures
    ),
    dateOfLastCheck: data.info?.dateOfLastCheck ?? undefined,
    hasAmlCftInternalControlOfficer: yesOrNot(
      t,
      data.info?.hasAmlCftInternalControlOfficer
    ),
    officerFirstName: data.info?.officer?.firstName,
    officerLastName: data.info?.officer?.lastName,
    officerMiddleName: data.info?.officer?.middleName ?? undefined,
    officerPosition: data.info?.officer?.position,
    officerPhoneNumber: data.info?.officer?.phoneNumber,
    officerEmail: data.info?.officer?.email,
    canProvideAmlPolicyDocuments: yesOrNot(
      t,
      data.info?.canProvideAmlPolicyDocuments
    ),
    documentProvisionRefusalReason:
      data.info?.documentProvisionRefusalReason ?? undefined,
    isInternalControlInfoConfidential: yesOrNot(
      t,
      data.info?.isInternalControlInfoConfidential
    ),
    hadAmlCftInvestigationsOrPenalties: yesOrNot(
      t,
      data.info?.hadAmlCftInvestigationsOrPenalties
    ),
    investigationOrPenaltyReasons:
      data.info?.investigationOrPenaltyReasons ?? undefined,
    hasMandatoryControlTransactions: yesOrNot(
      t,
      data.info?.hasMandatoryControlTransactions
    ),
    mandatoryControlTransactionIdentificationProcedure:
      data.info?.mandatoryControlTransactionIdentificationProcedure ??
      undefined,
    monetaryServiceTransactionCountForLastSixMonths:
      getMonetaryServiceTransactionCountForLastSixMonthsSelectedOption(
        t,
        data.info?.monetaryServiceTransactionCountForLastSixMonths
      ),
    isAffiliatedWithNonProfitOrganizations: yesOrNot(
      t,
      data.info?.isAffiliatedWithNonProfitOrganizations
    ),
    receivesFundingFromStateBudget: yesOrNot(
      t,
      data.info?.receivesFundingFromStateBudget
    ),
    receivesFundingFromForeignNonProfitNGOs: yesOrNot(
      t,
      data.info?.receivesFundingFromForeignNonProfitNGOs
    ),
    isCompliantWithFATFRecommendations: yesOrNot(
      t,
      data.info?.isCompliantWithFATFRecommendations
    ),
    hasBeenRefusedToOpenAccountByBanks: yesOrNot(
      t,
      data.info?.hasBeenRefusedToOpenAccountByBanks
    ),
  };
};
