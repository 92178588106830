import { FC, useCallback, useState } from 'react';

export interface ContentStepperComponentProps<T = any> {
  canGoBack: boolean;
  data?: T;
  onGoBack: () => void;
  onGoNext: () => void;
}
export interface ContentStepperStep<T = any> {
  component: FC<ContentStepperComponentProps<T>>;
  canGoBack: boolean;
  data?: any;
}

export interface ContentStepperProps<T = any> {
  steps: ContentStepperStep<T>[];
  initialActiveIndex?: number;
  onBack: () => void;
  onEnd: () => void;
  onChangeStep?: (index: number) => void;
}

export const ContentStepper = <T,>({
  steps,
  initialActiveIndex = 0,
  onBack,
  onEnd,
  onChangeStep,
}: ContentStepperProps<T>) => {
  const [activeIndex, setActiveIndex] = useState(initialActiveIndex);

  const step = steps[activeIndex];
  const { component: Component } = step;

  const handleGoNext = useCallback(async () => {
    // if last step
    if (activeIndex === steps.length - 1) {
      onEnd();
    } else {
      const newIndex = activeIndex + 1;
      setActiveIndex(newIndex);
      onChangeStep?.(newIndex);
    }
  }, [activeIndex, onChangeStep, onEnd, steps.length]);

  const handleGoBack = async () => {
    if (activeIndex === 0) {
      onBack();
    } else {
      const newIndex = activeIndex - 1;
      setActiveIndex(newIndex);
      onChangeStep?.(newIndex);
    }
  };

  return (
    <Component
      canGoBack={step.canGoBack}
      data={step.data}
      onGoBack={handleGoBack}
      onGoNext={handleGoNext}
    />
  );
};
