import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useLegalClientSaveBankAccountsMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { LegalClientSaveBankAccountsParam } from 'api/types/params/onboarding/legalClientSaveBankAccountsParam';

import { parseLegalClient } from './helpers';

export const useLegalClientSaveBankAccounts = () => {
  const [saveBankAccounts, { data, loading, error: apolloError }] =
    useLegalClientSaveBankAccountsMutation();

  const error = useError(apolloError);

  return {
    data: data?.legalClientSaveBankAccounts
      ? parseLegalClient(data.legalClientSaveBankAccounts)
      : undefined,
    loading,
    error,
    saveBankAccounts: useCallback(
      async (id: string, params: LegalClientSaveBankAccountsParam) => {
        const res = await saveBankAccounts({
          variables: {
            id,
            input: params,
          },
        });
        return res?.data?.legalClientSaveBankAccounts
          ? parseLegalClient(res.data.legalClientSaveBankAccounts)
          : undefined;
      },
      [saveBankAccounts]
    ),
  };
};
