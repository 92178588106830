import { TestAttributeProps } from 'types/testAttribute';

import { StackProps } from '../Stack/types';
import { Typography, TypographyProps } from '../Typography';

import { ListStyled } from './ListTypography.styles';

interface Props extends Omit<TypographyProps, 'as'>, TestAttributeProps {
  listType: 'ol' | 'ul';
  texts: string[];
  spacing?: StackProps['spacing'];
  className?: string;
}

export const ListTypography = ({
  listType,
  texts,
  spacing,
  className,
  datax,
  ...textProps
}: Props) => (
  <ListStyled
    as={listType}
    className={className}
    datax={datax}
    spacing={spacing}
  >
    {texts.map((item) => (
      <Typography {...textProps} as="li">
        {item}
      </Typography>
    ))}
  </ListStyled>
);
