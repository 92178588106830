import { CountryEntity } from 'api/types/entity';
import {
  TypedForm,
  FormErrors as FormErrorsBase,
  ObjectSchema,
  string,
  object,
  array,
} from 'libs/form';
import { Maybe } from 'types/maybe';

export interface OnboardingTaxResidencyFormTaxValue {
  taxNumber: string;
  country: Maybe<CountryEntity>;
}

export interface CompanyTaxResidencyFormType {
  taxValues: OnboardingTaxResidencyFormTaxValue[];
}

export const getSchema = (): ObjectSchema<CompanyTaxResidencyFormType> =>
  object({
    taxValues: array()
      .of(
        object({
          taxNumber: string().required().max(50),
          country: object({
            id: string().required(),
            name: string().required(),
          }).required(),
        }).required()
      )
      .required(),
  });

export const { Field, Form, useFormContext, Submit } =
  TypedForm<CompanyTaxResidencyFormType>();

export type FormErrors = FormErrorsBase<CompanyTaxResidencyFormType>;
