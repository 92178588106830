import { ControlOver } from '../components';
import { useControlOver } from '../hooks';
import { useOnboardingContext } from '../hooks/useOnboardingContext';
import { PageComponentProps } from '../types';

export const ControlOverStep = ({ canGoBack }: PageComponentProps) => {
  const { goToBack, initialValues, onSubmit, submitLoading } = useControlOver();

  const { step } = useOnboardingContext();

  return (
    <ControlOver
      canGoBack={canGoBack}
      initialValues={initialValues}
      step={step}
      submitLoading={submitLoading}
      onGoBack={goToBack}
      onSubmit={onSubmit}
    />
  );
};
