import { SetStateAction, useCallback } from 'react';

import { useFormikContext, yupToFormErrors } from 'formik';
import { ObjectSchema, ValidationError } from 'yup';

import { FormErrors } from './types';

interface UserFormReturn<Values extends object> {
  /** Function that triggers form submission. */
  handleSubmit: () => void;
  validateForm: (values?: Partial<Values>) => Promise<FormErrors<Values>>;
  validateField: (
    field: Extract<keyof Values, string>
  ) => Promise<boolean | undefined>;
  setErrors: (formError: FormErrors<Values>) => void;
  setValues: (
    values: SetStateAction<Values>,
    shouldValidate?: boolean
  ) => Promise<void | FormErrors<Values>>;
  values: Values;
  errors: FormErrors<Values>;
}
/**
 * Hook for accessing the Form context and retrieving the form submission handler.
 *
 * @template Values - The form values type.
 * @returns {UserFormReturn} An object containing the form submission handler.
 */
export const useFormContext = <
  Values extends object
>(): UserFormReturn<Values> => {
  const {
    handleSubmit: formikHandleSubmit,
    validateForm,
    validationSchema,
    validateField,
    values,
    errors,
    setErrors: setErrorsBase,
    setValues,
  } = useFormikContext<Values>();

  const handleSubmit = useCallback(
    () => formikHandleSubmit(),
    [formikHandleSubmit]
  );

  const handleValidateField = useCallback(
    async (field: Extract<keyof Values, string>) => {
      // formik not get field error! https://github.com/jaredpalmer/formik/issues/2021
      await validateField(field).catch();

      const isError = await (validationSchema as ObjectSchema<Values>)
        ?.validate(values, { abortEarly: false })
        .then(() => false)
        .catch((err: ValidationError) => {
          const errors = yupToFormErrors(err) as FormErrors<Values>;
          return errors[field] || !!err.inner.find((d) => d.path === field);
        });
      return !isError;
    },
    [validateField, validationSchema, values]
  );

  const setErrors = useCallback(
    (formError: FormErrors<Values>) => {
      setErrorsBase(formError);
    },
    [setErrorsBase]
  );

  return {
    handleSubmit,
    validateForm,
    validateField: handleValidateField,
    setErrors,
    setValues,
    values,
    errors: errors as FormErrors<Values>,
  };
};
