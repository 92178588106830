import { FC, PropsWithChildren } from 'react';

import { LoadingLayout } from './LoadingLayout';

interface Props extends PropsWithChildren {
  loading: boolean;
}

export const LoadingLayoutWrapper: FC<Props> = ({ loading, children }) => {
  if (loading) {
    return <LoadingLayout />;
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};
