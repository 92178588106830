// eslint-disable-next-line no-restricted-imports
import { BusinessRelationshipFragment } from 'api/generated/graphql';
import {
  LegalClientBusinessRelationshipEntity,
  BusinessRelationshipGoalType,
  BusinessRelationshipNatureType,
  AssetType,
  OperationType,
} from 'api/types/entity';
import { lodash } from 'helpers';
import { Maybe } from 'types/maybe';

import { parseAmount } from '../../helpers/parseAmount';

export const parseBusinessRelationshipEntity = (
  data?: Maybe<BusinessRelationshipFragment>
): LegalClientBusinessRelationshipEntity | undefined =>
  data
    ? {
        plannedAssetTypes: lodash
          .compact(data.plannedAssetTypes)
          .map((v) => AssetType[v]),

        businessRelationshipGoals: lodash
          .compact(data.businessRelationshipGoals)
          .map((v) => BusinessRelationshipGoalType[v]),

        businessRelationshipNatures: lodash
          .compact(data.businessRelationshipNatures)
          .map((v) => BusinessRelationshipNatureType[v]),

        plannedOperationTypes: lodash
          .compact(data.plannedOperationTypes)
          .map((v) => OperationType[v]),

        plannedAssetSize: parseAmount(data.plannedAssetSize),

        otherBusinessRelationshipGoal:
          data.otherBusinessRelationshipGoal ?? undefined,
        otherBusinessRelationshipNature:
          data.otherBusinessRelationshipNature ?? undefined,
        otherPlannedOperationType: data.otherPlannedOperationType ?? undefined,
      }
    : undefined;
