import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useLegalClientSaveContactsMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { LegalClientContactEntity } from 'api/types/entity';

import { parseLegalClient } from './helpers';

export const useLegalClientSaveContacts = () => {
  const [saveContacts, { data, loading, error: apolloError }] =
    useLegalClientSaveContactsMutation();

  const error = useError(apolloError);

  return {
    data: data?.legalClientSaveContacts
      ? parseLegalClient(data.legalClientSaveContacts)
      : undefined,
    loading,
    error,
    saveContacts: useCallback(
      async (id: string, params: LegalClientContactEntity) => {
        const res = await saveContacts({
          variables: { id, input: params },
        });
        return res.data?.legalClientSaveContacts
          ? parseLegalClient(res.data.legalClientSaveContacts)
          : undefined;
      },
      [saveContacts]
    ),
  };
};
