// eslint-disable-next-line no-restricted-imports
import { Tabs } from '@mui/material';
import { getColor } from 'helpers';

import { CSSObject, styled } from '../styled';
import { ColorsDark, ColorsLight } from '../theme';

export const TabsStyled = styled(Tabs)(({ theme }) => ({
  '.tabs-tab': {
    ...theme.typography.body,
    color: getColor(theme, {
      light: ColorsLight.black,
      dark: ColorsDark.white,
    }),
    textTransform: 'none',

    '&.Mui-selected': {
      ...theme.typography.bodyBold,
      color: getColor(theme, {
        light: ColorsLight.black,
        dark: ColorsDark.white,
      }),
    },
  } as CSSObject,

  '.MuiTabs-indicator': {
    backgroundColor: getColor(theme, {
      light: ColorsLight.black,
      dark: ColorsDark.white,
    }),
  } as CSSObject,
}));
