// eslint-disable-next-line no-restricted-imports
import { LegalClientBeneficiaryFragment } from 'api/generated/graphql';
import { parseAmount } from 'api/requests/onboarding/helpers/parseAmount';
import { ControlOverEntity } from 'api/types/entity/legal/beneficiary/controlOverEntity';

export const parseControlOverEntity = (
  data: LegalClientBeneficiaryFragment
): ControlOverEntity | undefined => {
  if (!data.financialConditionAmount) return undefined;

  return {
    directCapitalParticipationPercentage:
      data.directCapitalParticipationPercentage!,
    financialConditionAmount: parseAmount(data.financialConditionAmount!),
    hasControlOverLegalClient: data.hasControlOverLegalClient!,
    indirectCapitalParticipationPercentage:
      data.indirectCapitalParticipationPercentage!,
  };
};
