import { TestAttributeProps } from 'types/testAttribute';

import { Typography } from '../Typography';
import { TypographyVariant } from '../theme/typography';

import { ChipStyled } from './Chip.styles';
import { ChipProps, ChipSize } from './types';

/**
 * Chip component.
 */

const typographyVariant: Record<ChipSize, TypographyVariant> = {
  medium: 'bodySemiBold',
  small: 'captionMedium',
};

export const Chip = ({
  variant,
  text,
  uppercase = true,
  size = 'medium',
  datax,
}: ChipProps & TestAttributeProps) => (
  <ChipStyled
    className="chip"
    datax={datax}
    size={size}
    uppercase={uppercase}
    variant={variant}
  >
    <Typography className="chip-text" variant={typographyVariant[size]}>
      {text}
    </Typography>
  </ChipStyled>
);
