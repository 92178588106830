import { useCallback, useEffect, useRef } from 'react';

import { useOnboardingKycTokenLazy } from 'api/requests';
import { KYCStatus } from 'api/types/data';
import { useInterval, useServerErrorNotify } from 'hooks';
import { useNavigate } from 'libs/navigation';

import { STATUS_UPDATE_INTERVAL } from '../constants';

interface Props {
  status?: KYCStatus;
  refetch: () => void;
  onSuccess: () => void;
}

export const useOnboardingKyc = ({ status, refetch, onSuccess }: Props) => {
  const { navigate } = useNavigate();
  const isProcessed = useRef(false);

  const {
    token,
    getToken,
    loading,
    error: errorToken,
  } = useOnboardingKycTokenLazy();

  useServerErrorNotify(errorToken);

  const expirationHandler = useCallback(async () => {
    const res = await getToken();
    return res || '';
  }, [getToken]);

  // refetch status every [STATUS_UPDATE_INTERVAL] seconds
  useInterval(
    () => {
      refetch();
    },
    status !== KYCStatus.success ? STATUS_UPDATE_INTERVAL : null
  );

  // check status
  useEffect(() => {
    if (!isProcessed.current) {
      if (status === KYCStatus.success) {
        isProcessed.current = true;
        onSuccess();
      } else if (status === KYCStatus.error) {
        isProcessed.current = true;
      }
    }
  }, [onSuccess, navigate, status]);

  useEffect(() => {
    if (
      !token &&
      (status === KYCStatus.progress || status === KYCStatus.notStarted)
    ) {
      getToken();
    }
  }, [getToken, status, token]);

  return { loading: loading && !token, token, status, expirationHandler };
};
