import { useMemo } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useOnboardingCountriesQuery } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { lodash } from 'helpers';

import { parseCountryEntity } from './helpers';

export const useOnboardingCountries = () => {
  const { data, loading, error: apolloError } = useOnboardingCountriesQuery();

  const error = useError(apolloError);

  const countries = useMemo(
    () =>
      data?.onboardingCountries
        ? lodash.compact(data.onboardingCountries).map(parseCountryEntity)
        : undefined,
    [data]
  );

  return {
    countries,
    loading,
    error,
  };
};
