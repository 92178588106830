import { useOnboardingCountries as useOnboardingCountriesApi } from 'api/requests';
import { CountryEntity } from 'api/types/entity';

export const useOnboardingCountries = () => {
  const { countries, loading, error } = useOnboardingCountriesApi();

  return {
    countries: (countries || []) as CountryEntity[],
    loading: !countries && loading,
    error,
  };
};
