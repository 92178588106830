import { useRef } from 'react';

import { useClientSaveWithdrawalAccounts } from 'api/requests';
import { ClientEntity } from 'api/types/entity';
import { useFilesUpload, useServerErrorNotify } from 'hooks';
import { useOnboardingCountries } from 'modules/onboarding/common/hooks';

import { WithdrawalAccountsFormType } from '../forms/withdrawalAccountsForm';
import { getInitialData } from '../helpers/withdrawalAccounts/getInitialData';
import { getSubmitData } from '../helpers/withdrawalAccounts/getSubmitData';

export const useClientWithdrawalAccounts = (client: ClientEntity) => {
  const {
    loading: submitLoading,
    error,
    saveWithdrawalAccounts: saveWithdrawalAccountsApi,
  } = useClientSaveWithdrawalAccounts();

  const { countries, loading: countriesLoading } = useOnboardingCountries();

  const initialData = useRef(getInitialData(client.withdrawalAccounts)).current;
  const initialValues = useRef(initialData.values).current;

  const {
    files,
    isLoadingFile: submitDisabled,
    onRemoveFile,
    onAddFiles,
  } = useFilesUpload(initialData.files);

  const saveWithdrawalAccounts = async (values: WithdrawalAccountsFormType) => {
    const submitData = getSubmitData(values);
    await saveWithdrawalAccountsApi(client.id, submitData);
  };

  useServerErrorNotify(error);

  return {
    saveWithdrawalAccounts,
    files,
    onAddFiles,
    onRemoveFile,
    submitDisabled,
    submitLoading,
    initialValues,
    countries,
    countriesLoading,
  };
};
