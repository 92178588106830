import { useTranslation } from 'libs/i18n';
import { Stack } from 'libs/ui';
import { Fields as BaseFields } from 'modules/onboarding/common/components/OrderSubmissionContacts/FormContent/components/Fields';

import {
  Field,
  maxLengthName,
  maxLengthPosition,
} from '../../../../forms/orderSubmissionContacts';

interface Props {
  index: number;
}

export const Fields = ({ index }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <Stack alignItems="flex-end" direction="row" spacing={24}>
        <BaseFields index={index} />
      </Stack>
      <Field.Text
        label={t('onboarding.legalOrderSubmissionContacts.firstName.label')}
        maxLength={maxLengthName}
        name={`orderSubmissionContacts.${index}.firstName`}
        placeholder={t(
          'onboarding.legalOrderSubmissionContacts.firstName.placeholder'
        )}
      />
      <Field.Text
        label={t('onboarding.legalOrderSubmissionContacts.lastName.label')}
        maxLength={maxLengthName}
        name={`orderSubmissionContacts.${index}.lastName`}
        placeholder={t(
          'onboarding.legalOrderSubmissionContacts.lastName.placeholder'
        )}
      />
      <Field.Text
        showOptionalMark
        label={t('onboarding.legalOrderSubmissionContacts.middleName.label')}
        maxLength={maxLengthName}
        name={`orderSubmissionContacts.${index}.middleName`}
        placeholder={t(
          'onboarding.legalOrderSubmissionContacts.middleName.placeholder'
        )}
      />
      <Field.Text
        label={t('onboarding.legalOrderSubmissionContacts.position.label')}
        maxLength={maxLengthPosition}
        name={`orderSubmissionContacts.${index}.position`}
        placeholder={t(
          'onboarding.legalOrderSubmissionContacts.position.placeholder'
        )}
      />
    </>
  );
};
