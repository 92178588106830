import { NumberField } from 'libs/form/fields/NumberField';
import { TextField } from 'libs/form/fields/TextField';
import { useFormContext } from 'libs/form/useFormContext';
import { useTranslation } from 'libs/i18n';

import { SendCodeTimerStyled } from './styles';

interface ContentProps<Values extends object> {
  resendTime?: number;
  emailFieldName: Extract<keyof Values, string>;
  codeFieldName: Extract<keyof Values, string>;
  onSendEmailCode: (email: string) => void;
}

export const FormContent = <Values extends object>({
  onSendEmailCode,
  resendTime,
  emailFieldName,
  codeFieldName,
}: ContentProps<Values>) => {
  const { t } = useTranslation();
  const { validateField, values } = useFormContext<Values>();

  const handleOnClick = async () => {
    const res = await validateField(emailFieldName);
    if (res) {
      onSendEmailCode(values[emailFieldName] as string);
    }
  };

  return (
    <>
      <TextField
        fullWidth
        autoComplete="on"
        label={t('components.emailForm.email.label')}
        name={emailFieldName}
        placeholder={t('components.emailForm.email.placeholder')}
        rightElement={
          <SendCodeTimerStyled
            disabled={!values[emailFieldName]}
            reload={values[emailFieldName]}
            seconds={resendTime}
            onClickSend={handleOnClick}
          />
        }
        type="email"
      />
      <NumberField
        fullWidth
        label={t('components.emailForm.code.label')}
        maxLength={6}
        name={codeFieldName}
        placeholder={t('components.emailForm.code.placeholder')}
      />
    </>
  );
};
