import { useRef } from 'react';

import { useLegalClientRepresentativeSaveCitizenships } from 'api/requests';
import { useServerErrorNotify } from 'hooks';
import { OnboardingCitizenshipFormType } from 'modules/onboarding/common/forms/onboardingCitizenshipForm';
import { getInitialData } from 'modules/onboarding/common/helpers/citizenship/getInitialData';
import { getSubmitData } from 'modules/onboarding/common/helpers/citizenship/getSubmitData';
import { useOnboardingCountries } from 'modules/onboarding/common/hooks';

import { useOnboardingContext } from './useOnboardingContext';

export const useCitizenship = () => {
  const { data, goToBack, goToNext } = useOnboardingContext();
  const {
    saveCitizenships,
    error,
    loading: submitLoading,
  } = useLegalClientRepresentativeSaveCitizenships();

  const { countries, loading: countriesLoading } = useOnboardingCountries();

  const initialValues = useRef(
    getInitialData(data.currentClient.citizenships)
  ).current;

  const onSubmit = async (values: OnboardingCitizenshipFormType) => {
    if (values.citizenships) {
      const submitData = getSubmitData(values);
      const res = await saveCitizenships(data.currentClient.id, submitData);
      if (res) {
        goToNext();
      }
    }
  };

  useServerErrorNotify(error);

  return {
    onSubmit,
    submitLoading,
    initialValues,
    countries,
    countriesLoading,
    goToBack,
  };
};
