import { Stack } from 'libs/ui';
import { useFormContext } from 'modules/onboarding/common/forms/orderSubmissionContacts';

import { Fields, Buttons } from './components';

export const FormContent = () => {
  const { values } = useFormContext();

  return (
    <Stack spacing={32}>
      {values.orderSubmissionContacts.map((value, index) => (
        <Stack key={index.toLocaleString()} spacing={32}>
          <Stack alignItems="flex-end" direction="row" spacing={24}>
            <Fields index={index} />
          </Stack>

          <Buttons index={index} />
        </Stack>
      ))}
    </Stack>
  );
};
