import { ReactNode, useMemo } from 'react';

import { FormikContext, useFormik } from 'formik';
import { ObjectSchema } from 'yup';

import { prepareDataForValidation } from './helpers';
import { FormErrors } from './types';

export type FormSubmit<Values extends object> = (
  values: Values
) => void | Promise<any>;

/**
 * Props for the Form component.
 */
export interface FormProps<Values extends object> {
  /** Initial form field values (optional). */
  initialValues?: Partial<Values>;
  /** Initial form errors (optional). */
  initialErrors?: FormErrors<Values>;
  /** Function to handle form submission. */
  onSubmit: FormSubmit<Values>;
  /** Whether to validate on field change (default is false). */
  validateOnChange?: boolean;
  /** Whether to validate on field blur (default is false). */
  validateOnBlur?: boolean;
  /** A Yup Schema or a function that returns a Yup schema (optional). */
  validationSchema?: ObjectSchema<Values>;
  /** Child components or elements (optional). */
  children?: ReactNode;
}

/**
 * Form component that uses Formik for form management and validation.
 */
export const Form = <Values extends object>(props: FormProps<Values>) => {
  const {
    initialValues = {},
    onSubmit,
    validateOnChange = false,
    validateOnBlur = false,
    children,
    validationSchema,
    initialErrors,
    ...rest
  } = props;

  const formState = useFormik({
    ...rest,
    enableReinitialize: true,
    validateOnChange,
    validateOnBlur,
    initialErrors,
    initialValues: (initialValues || {}) as Values,
    validationSchema,
    onSubmit: (values: Values) =>
      onSubmit(prepareDataForValidation(values) as Values),
  });

  const value = useMemo(
    () => ({ ...formState, validationSchema }),
    [formState, validationSchema]
  );

  return (
    <FormikContext.Provider value={value}>{children}</FormikContext.Provider>
  );
};
