import { CountryEntity } from 'api/types/entity';
import {
  TypedForm,
  FormErrors as FormErrorsBase,
  ObjectSchema,
  string,
  object,
  array,
  boolean,
} from 'libs/form';
import { Maybe } from 'types/maybe';

export interface OnboardingTaxResidencyFormTaxValue {
  taxNumberIsMissing?: boolean;
  taxNumber?: string;
  reasonForAbsence?: string;
  country: Maybe<CountryEntity>;
}

export interface OnboardingTaxResidencyFormType {
  taxValues: OnboardingTaxResidencyFormTaxValue[];
}

export const getSchema = (): ObjectSchema<OnboardingTaxResidencyFormType> =>
  object({
    taxValues: array()
      .of(
        object({
          taxNumberIsMissing: boolean().default(false),
          taxNumber: string().when('taxNumberIsMissing', {
            is: false,
            then: (schema) => schema.required().max(50),
          }),
          reasonForAbsence: string().when('taxNumberIsMissing', {
            is: true,
            then: (schema) => schema.required().max(2000),
          }),
          country: object({
            id: string().required(),
            name: string().required(),
          }).required(),
        }).required()
      )
      .required(),
  });

export const { Field, Form, useFormContext, Submit } =
  TypedForm<OnboardingTaxResidencyFormType>();

export type FormErrors = FormErrorsBase<OnboardingTaxResidencyFormType>;
