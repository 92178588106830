// eslint-disable-next-line no-restricted-imports
import { ApolloClient } from '@apollo/client';
// eslint-disable-next-line no-restricted-imports
import {
  RefreshTokenDocument,
  RefreshTokenMutation,
} from 'api/generated/graphql';
import { LoginData } from 'api/types/data';

export const refreshToken = (client: ApolloClient<object>, token: string) =>
  client
    .mutate<RefreshTokenMutation>({
      mutation: RefreshTokenDocument,
      variables: { token },
    })
    .then((data) => data.data?.AuthController_refresh as LoginData | undefined);
