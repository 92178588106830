import {
  TypedForm,
  FormErrors as FormErrorsBase,
  ObjectSchema,
  string,
  object,
  array,
  boolean,
} from 'libs/form';

export const MAX_FILES = 10;

export interface CompanyBeneficiariesForm {
  impossibleIdentifyBeneficiary: boolean;
  fileIds?: string[];
}

export const getSchema = (): ObjectSchema<CompanyBeneficiariesForm> =>
  object({
    impossibleIdentifyBeneficiary: boolean().default(false).required(),
    fileIds: array()
      .of(string().required())
      .when('impossibleIdentifyBeneficiary', {
        is: true,
        then: (schema) => schema.min(1).max(MAX_FILES),
      }),
  });

export const { Field, Form, useFormContext, Submit } =
  TypedForm<CompanyBeneficiariesForm>();

export type FormErrors = FormErrorsBase<CompanyBeneficiariesForm>;
