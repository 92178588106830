import { useRef } from 'react';

import { useLegalClientSaveWithdrawalAccounts } from 'api/requests';
import { useFilesUpload, useServerErrorNotify } from 'hooks';
import { WithdrawalAccountsFormType } from 'modules/onboarding/common/forms/withdrawalAccountsForm';
import { getInitialData } from 'modules/onboarding/common/helpers/withdrawalAccounts/getInitialData';
import { getSubmitData } from 'modules/onboarding/common/helpers/withdrawalAccounts/getSubmitData';
import { useOnboardingCountries } from 'modules/onboarding/common/hooks';

import { useOnboardingContext } from './useOnboardingContext';

export const useWithdrawalAccounts = () => {
  const { goToBack, goToNext, data } = useOnboardingContext();

  const { error, loading, saveWithdrawalAccounts } =
    useLegalClientSaveWithdrawalAccounts();

  const { countries, loading: countriesLoading } = useOnboardingCountries();

  const initialData = useRef(
    getInitialData(data.legalClient!.withdrawalAccounts)
  ).current;
  const initialValues = useRef(initialData.values).current;

  const {
    files,
    isLoadingFile: submitDisabled,
    onRemoveFile,
    onAddFiles,
  } = useFilesUpload(initialData.files);

  const onSubmit = async (values: WithdrawalAccountsFormType) => {
    if (values) {
      const submitData = getSubmitData(values);
      await saveWithdrawalAccounts(data.legalClient!.id, submitData);
      goToNext();
    }
  };

  useServerErrorNotify(error);

  return {
    onSubmit,
    files,
    onAddFiles,
    onRemoveFile,
    submitDisabled,
    submitLoading: loading,
    initialValues,
    countries,
    countriesLoading,
    goToBack,
  };
};
