import { CountryEntity } from 'api/types/entity';
import { getYesOrNoOptions } from 'helpers';
import { useTranslation } from 'libs/i18n';
import { Fields as BaseFields } from 'modules/onboarding/common/components/OnboardingAccountsInOrganizations/FormContent/components/Fields';
import { Field } from 'modules/onboarding/legal/forms/companyAccountsInOrganizationsForm';

interface Props {
  index: number;
  countriesLoading?: boolean;
  countries: CountryEntity[];
}

export const Fields = ({ index, countriesLoading, countries }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <BaseFields
        countries={countries}
        countriesLoading={countriesLoading}
        index={index}
      />

      <Field.Autocomplete
        label={t(
          'onboarding.companyAccountsInOrganizations.onTerritoryWithNoAML_CFT.label'
        )}
        name={`accounts.${index}.onTerritoryWithNoAML_CFT`}
        optionLabelKey="label"
        options={getYesOrNoOptions(t)}
        optionValueKey="value"
        placeholder={t('common.select.placeholder')}
        variant="select"
      />

      <Field.Autocomplete
        label={t(
          'onboarding.companyAccountsInOrganizations.hasPermanentManagementInCountry.label'
        )}
        name={`accounts.${index}.hasPermanentManagementInCountry`}
        optionLabelKey="label"
        options={getYesOrNoOptions(t)}
        optionValueKey="value"
        placeholder={t('common.select.placeholder')}
        variant="select"
      />
    </>
  );
};
