import { OrderSubmissionContactsFormType } from 'modules/onboarding/common/forms/orderSubmissionContacts';
import { useClientOrderSubmissionContacts } from 'modules/onboarding/common/hooks';

import { useOnboardingContext } from './useOnboardingContext';

export const useOwnerOrderSubmissionContacts = () => {
  const { goToBack, goToNext, data } = useOnboardingContext();

  const { saveOrderSubmissionContacts, initialValues, submitLoading } =
    useClientOrderSubmissionContacts(data.ownerClient!);

  const onSubmit = async (values: OrderSubmissionContactsFormType) => {
    if (values.orderSubmissionContacts?.length) {
      await saveOrderSubmissionContacts(values);
      goToNext();
    }
  };

  return {
    onSubmit,
    goToBack,
    initialValues,
    submitLoading,
  };
};
