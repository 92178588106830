import { Layout, LayoutProps } from 'components/Layout';
import { styled } from 'libs/ui/styled';

interface Props extends LayoutProps {
  center?: boolean;
}
export const FormLayoutStyled = styled(Layout)<Props>(({ center, theme }) => ({
  '.formLayout-content': {
    maxWidth: 831,
    width: '100%',
    margin: 'auto',
    ...(!center && {
      marginTop: 64,
      [theme.breakpoints.down('mobile')]: {
        marginTop: 32,
      },
    }),
  },
}));
