import { Maybe } from 'types/maybe';

export enum UserType {
  Client = 'Client',
  ClientRepresentative = 'ClientRepresentative',
  LegalClientRepresentative = 'LegalClientRepresentative',
  LegalClientHead = 'LegalClientHead',
  LegalClientBeneficiary = 'LegalClientBeneficiary',
  LegalClientLegalHeadFirstHead = 'LegalClientLegalHeadFirstHead',
}
export interface UserEntity {
  id: string;
  email: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt?: Maybe<Date>;
  otp?: Maybe<{ verified: boolean }>;
  isPasswordUpdateRequired?: Boolean;
  type: UserType;
}
