import { useCallback, useMemo } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useLegalClientGetHeadLazyQuery } from 'api/generated/graphql';
import { useError } from 'api/helpers';

import { parseLegalClientHeadEntity } from './helpers';

export const useLegalClientGetHeadLazy = () => {
  const [query, { data, loading, error: apolloError }] =
    useLegalClientGetHeadLazyQuery();

  const error = useError(apolloError);

  const getHead = useCallback(
    async (legalId: string, headId: string) => {
      const res = await query({ variables: { id: legalId, headId } });
      if (res.data?.legalClientGetHead) {
        return parseLegalClientHeadEntity(res.data?.legalClientGetHead);
      }
      return null;
    },
    [query]
  );

  const legalClientHead = useMemo(() => {
    const res = data?.legalClientGetHead
      ? parseLegalClientHeadEntity(data.legalClientGetHead)
      : undefined;
    return res;
  }, [data?.legalClientGetHead]);

  return {
    legalClientHead,
    loading,
    getHead,
    error,
  };
};
