import { Hint } from 'components';
import { useTranslation } from 'libs/i18n';
import { Stack, Typography } from 'libs/ui';

import { Field } from '../../../forms/registrationPersonalDataForm';

import { HintTypographyStyled } from './styles';

export const RepresentativeSwitch = () => {
  const { t } = useTranslation();

  return (
    <Stack alignItems="center" direction="row" spacing={16}>
      <Stack alignItems="center" direction="row" spacing={8}>
        <Typography variant="bodyBold">
          {t('auth.registration.personalData.representative.label')}
        </Typography>
        <Hint
          title={
            <HintTypographyStyled variant="caption">
              {t('auth.registration.personalData.representative.hint.tilte')}
            </HintTypographyStyled>
          }
        />
      </Stack>
      <Field.Switch name="representative" />
    </Stack>
  );
};
