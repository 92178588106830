import { CountryEntity } from 'api/types/entity';
import { useTranslation } from 'libs/i18n';
import {
  Field,
  OnboardingTaxResidencyFormTaxValue,
} from 'modules/onboarding/common/forms/onboardingTaxResidencyForm';

interface Props {
  index: number;
  value: OnboardingTaxResidencyFormTaxValue;
  countriesLoading?: boolean;
  countries: CountryEntity[];
}

export const Fields = ({
  index,
  value,
  countriesLoading,
  countries,
}: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <Field.Autocomplete
        label={t('onboarding.taxResidency.country.label')}
        loading={countriesLoading}
        name={`taxValues.${index}.country` as 'taxValues'}
        optionLabelKey="name"
        options={countries}
        optionValueKey="id"
        placeholder={t('onboarding.taxResidency.country.placeholder')}
      />

      {value.taxNumberIsMissing ? (
        <Field.Text
          label={t('onboarding.taxResidency.reasonForAbsence.label')}
          name={`taxValues.${index}.reasonForAbsence` as 'taxValues'}
          placeholder={t(
            'onboarding.taxResidency.reasonForAbsence.placeholder'
          )}
        />
      ) : (
        <Field.Text
          label={t('onboarding.taxResidency.taxNumber.label')}
          name={`taxValues.${index}.taxNumber` as 'taxValues'}
          placeholder={t('onboarding.taxResidency.taxNumber.placeholder')}
        />
      )}
    </>
  );
};
