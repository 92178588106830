import { LoadingView } from 'components';

import { useRegistrationEmailLinkConfirm } from '../hooks';
import { RegistrationStepProps, RegistrationUrlData } from '../types';

export const RegistrationEmailLinkConfirmStep = ({
  onGoNext,
  data,
}: RegistrationStepProps<RegistrationUrlData>) => {
  useRegistrationEmailLinkConfirm(data!, onGoNext);

  return <LoadingView />;
};
