import { useRef, useState } from 'react';

import { useLegalClientLegalHeadSaveCompanyName } from 'api/requests';
import { useServerErrorNotify } from 'hooks';
import { CompanyNameFormType } from 'modules/onboarding/common/forms/companyNameForm';

import { useOnboardingContext } from './useOnboardingContext';

export const useCompanyName = () => {
  const { goToBack, goToNext, data } = useOnboardingContext();

  const {
    error,
    loading: submitCompanyNameLoading,
    saveCompanyName,
  } = useLegalClientLegalHeadSaveCompanyName();

  const [submitLoading, setSubmitLoading] = useState(false);

  const initialValues = useRef(
    data.legalHead?.fullName
      ? {
          fullName: data.legalHead.fullName,
          shortName: data.legalHead.shortName,
        }
      : undefined
  ).current;

  const onSubmit = async (values: CompanyNameFormType) => {
    if (values) {
      const param = {
        fullName: values.fullName,
        shortName: values.shortName,
      };
      if (!data.legalHead) {
        setSubmitLoading(true);

        try {
          if (await data.onCreateLegalHead(param)) {
            goToNext();
          }
        } finally {
          setSubmitLoading(false);
        }
      } else {
        const res = await saveCompanyName(
          data.legalClient!.id,
          data.legalHead.id,
          param
        );

        if (res) {
          goToNext();
        }
      }
    }
  };

  useServerErrorNotify(error);

  return {
    onSubmit,
    goToBack,
    submitLoading: submitCompanyNameLoading || submitLoading,
    initialValues,
  };
};
