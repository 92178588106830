interface Args {
  firstName: string;
  lastName: string;
  middleName?: string | null;
}
export const parseFullName = (names: Args) =>
  `${names.lastName}  ${names.firstName} ${names.middleName ?? ''}`;

export const parseInitials = (names: Args) =>
  `${names.lastName}  ${names.firstName[0]}.${`${
    names.middleName ? `${names.middleName?.[0]}.` : ''
  }`}`;
