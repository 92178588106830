export const parseNumber = (v?: string): number => {
  if (v === undefined || v === '') return 0;
  return Number(v.replace(',', '.'));
};

export const formatNumber = (v?: number): string => {
  if (v === undefined) return '';
  return `${v}`.replace('.', ',').substring(0, 13);
};

export const isFloat = (value: string | undefined = '') => {
  value = value.replace(/ /g, '');
  const num = Number(value.replace(',', '.'));
  if (Number.isNaN(num)) return false;
  if (value.includes(',') && value.split(',')[1].length > 2) return false;

  return true;
};
