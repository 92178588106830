// eslint-disable-next-line no-restricted-imports
import { LegalClientInvoiceFragment } from 'api/generated/graphql';
import { CurrencyType, LegalClientInvoiceEntity } from 'api/types/entity';

export const parseLegalClientInvoice = (
  data: LegalClientInvoiceFragment
): LegalClientInvoiceEntity => ({
  legalClientId: data.legalClientId,
  id: data.id,
  createdAt: data.createdAt,
  updatedAt: data.updatedAt,
  currency: CurrencyType[data.currency],
  privateFileId: data.privateFileId,
  deletedAt: data.deletedAt,
});
