import { Body } from 'components';
import { GlobalClasses } from 'helpers';
import { useTranslation } from 'libs/i18n';
import { Link, ROUTES } from 'libs/navigation';
import { Button, Typography, Stack } from 'libs/ui';
import { Icon } from 'libs/ui/Icon';

import {
  ButtonsStyled,
  FormLayoutStyled,
} from './RegistrationSelectType.styles';

interface Props {
  onSelectPresonal?: () => void;
  onSelectLegal?: () => void;
}

export const RegistrationSelectType = ({
  onSelectPresonal,
  onSelectLegal,
}: Props) => {
  const { t } = useTranslation();

  return (
    <FormLayoutStyled center variant="filled-all">
      <Stack spacing={16}>
        <Body padding="small">
          <Stack alignItems="start">
            <Link
              datax="registrationSelectType-backButton"
              params={{}}
              to={ROUTES.login.fullPath}
            >
              <Stack
                alignItems="center"
                className={GlobalClasses.hoveredChild}
                direction="row"
                spacing={4}
              >
                <Icon.ArrowLeft size={20} />
                <Typography variant="bodyBold" variantMobile="captionBold">
                  {t('auth.registration.selectType.goBack')}
                </Typography>
              </Stack>
            </Link>
          </Stack>
        </Body>

        <Body className="registrationSelectType-content">
          <Stack spacing={32}>
            <Stack as="header" spacing={20}>
              <Typography
                align="center"
                as="h1"
                datax="registrationSelectType-title"
                variant="title"
                variantMobile="subtitle"
              >
                {t('auth.registration.selectType.title')}
              </Typography>
              <Typography
                align="center"
                as="p"
                datax="registrationSelectType-subtitle"
              >
                {t('auth.registration.selectType.subtitle')}
              </Typography>
            </Stack>

            <ButtonsStyled>
              <Button
                fullWidth
                datax="registrationSelectType-personalButton"
                label={t('auth.registration.selectType.personalButton')}
                onClick={onSelectPresonal}
              />
              <Button
                fullWidth
                datax="registrationSelectType-legalButton"
                label={t('auth.registration.selectType.legalButton')}
                mode="outlined"
                onClick={onSelectLegal}
              />
            </ButtonsStyled>
          </Stack>
        </Body>
      </Stack>
    </FormLayoutStyled>
  );
};
