import { useState } from 'react';

import { LegalClientBodyEntity } from 'api/types/entity/legal/legalClientBody';
import { CircularProgress, Pressed, Stack, Typography } from 'libs/ui';
import { Icon } from 'libs/ui/Icon';

import { BodyStyled, DeleteButtonStyled } from './styles';

export interface BodyProps {
  body: LegalClientBodyEntity;
  onEdit: (v: LegalClientBodyEntity) => void;
  onDelete: (id: string) => Promise<void>;
}

export const BodyItem = ({ body, onEdit, onDelete }: BodyProps) => {
  const [loadingDelete, setLoadingDelete] = useState(false);

  const handleDelete = async (body: LegalClientBodyEntity) => {
    setLoadingDelete(true);
    await onDelete(body.id).finally(() => setLoadingDelete(false));
  };

  return (
    <Stack
      alignItems="center"
      datax="structure-item"
      direction="row"
      justifyContent="space-between"
      spacing={12}
    >
      <BodyStyled alignItems="center" spacing={16}>
        <Stack className="companyStructure-body-textContent">
          <Typography
            className="companyStructure-body-text"
            title={body.name}
            variant="bodyBold"
          >
            {body.name}
          </Typography>
          <Typography className="companyStructure-body-text" title={body.type}>
            {body.type}
          </Typography>
        </Stack>
      </BodyStyled>

      <Stack alignItems="center" direction="row" spacing={12}>
        <Pressed datax="edit" onClick={() => onEdit(body)}>
          <Icon.Edit />
        </Pressed>
        {loadingDelete ? (
          <CircularProgress size={24} />
        ) : (
          <DeleteButtonStyled datax="delete" onClick={() => handleDelete(body)}>
            <Icon.Bucket />
          </DeleteButtonStyled>
        )}
      </Stack>
    </Stack>
  );
};
