import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useLegalClientRepresentativeSaveBiographyMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { BiographyParam } from 'api/types/params/onboarding/biographyParam';

import { parseBiographyParams } from '../helpers';

import { parseLegalClientRepresentative } from './helpers';

export const useLegalClientRepresentativeSaveBiography = () => {
  const [handle, { data, loading, error: apolloError }] =
    useLegalClientRepresentativeSaveBiographyMutation();

  const error = useError(apolloError);

  return {
    data: data?.legalClientRepresentativeSaveBiography
      ? parseLegalClientRepresentative(
          data.legalClientRepresentativeSaveBiography
        )
      : undefined,
    loading,
    error,
    saveBiography: useCallback(
      async (id: string, params: BiographyParam) => {
        const input = parseBiographyParams(params);
        const res = await handle({
          variables: {
            id,
            input,
          },
        });
        return res?.data?.legalClientRepresentativeSaveBiography
          ? parseLegalClientRepresentative(
              res.data.legalClientRepresentativeSaveBiography
            )
          : undefined;
      },
      [handle]
    ),
  };
};
