import { useRef } from 'react';

import { useLegalClientSaveTaxResidences } from 'api/requests';
import { useServerErrorNotify } from 'hooks';
import { useOnboardingCountries } from 'modules/onboarding/common/hooks';

import { CompanyTaxResidencyFormType } from '../forms/companyTaxResidencyForm';
import { getInitialData, getSubmitData } from '../helpers/companyTaxResidences';

import { useOnboardingContext } from './useOnboardingContext';

export const useCompanyTaxResidency = () => {
  const { goToBack, goToNext, data } = useOnboardingContext();
  const { error, loading, saveTaxResidences } =
    useLegalClientSaveTaxResidences();

  const { countries, loading: countriesLoading } = useOnboardingCountries();

  const initialValues = useRef(
    getInitialData(data.legalClient!.taxResidences)
  ).current;

  const onSubmit = async (values: CompanyTaxResidencyFormType) => {
    if (values.taxValues) {
      const submitData = getSubmitData(values);
      const res = await saveTaxResidences(data.legalClient!.id, submitData);

      if (res) {
        goToNext();
      }
    }
  };

  useServerErrorNotify(error);

  return {
    onSubmit,
    goToBack,
    submitLoading: loading,
    initialValues,
    countries,
    countriesLoading,
  };
};
