import { getColor } from 'helpers';

import { Link, LinkProps } from '../Link';
import { styled } from '../styled';
import { ColorsDark, ColorsLight } from '../theme';

const linkLabelColor = {
  light: ColorsLight.blue150,
  dark: ColorsDark.blue150,
};
const linkLabelDisabledColor = {
  light: ColorsLight.gray150,
  dark: ColorsDark.gray150,
};

export const LinkStyled = styled(Link)<LinkProps>(({ disabled, theme }) => ({
  textDecorationColor: getColor(
    theme,
    disabled ? linkLabelDisabledColor : linkLabelColor
  ),

  width: 'fit-content',
  lineHeight: 'initial',

  '.link-label': {
    color: getColor(theme, disabled ? linkLabelDisabledColor : linkLabelColor),

    '&:hover': {
      color: !disabled ? theme.palette.hover : undefined,
    },
  },
}));
