import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useClientRepresentativeSaveTaxResidencesMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { OnboardingTaxResidencesParam } from 'api/types/params';

import { parseClientRepresentative } from './helpers/parseClientRepresentative';

export const useClientRepresentativeSaveTaxResidences = () => {
  const [saveTaxResidences, { data, loading, error: apolloError }] =
    useClientRepresentativeSaveTaxResidencesMutation();

  const error = useError(apolloError);

  return {
    data: data?.clientRepresentativeSaveTaxResidences
      ? parseClientRepresentative(data.clientRepresentativeSaveTaxResidences)
      : undefined,
    loading,
    error,
    saveTaxResidences: useCallback(
      async (id: string, params: OnboardingTaxResidencesParam) => {
        const res = await saveTaxResidences({
          variables: { id, input: params },
        });
        return res.data?.clientRepresentativeSaveTaxResidences
          ? parseClientRepresentative(
              res.data.clientRepresentativeSaveTaxResidences
            )
          : undefined;
      },
      [saveTaxResidences]
    ),
  };
};
