import { useTranslation } from 'libs/i18n';
import { Typography } from 'libs/ui';

import { ListTypographyStyled } from './styles';

export const Header = () => {
  const { t } = useTranslation();
  return (
    <Typography datax="onboarding-subtitle">
      {t('onboarding.companyConfirmStructure.subtitle')}
      <ListTypographyStyled
        listType="ol"
        spacing={0}
        texts={[
          t('onboarding.companyConfirmStructure.subtitleListFirstItem'),
          t('onboarding.companyConfirmStructure.subtitleListSecondItem'),
        ]}
      />
    </Typography>
  );
};
