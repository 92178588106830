import { Maybe } from 'types/maybe';

import { LegalClientFinancialMonitoringInfoOfficerEntity } from './legalClientFinancialMonitoringInfoOfficerEntity';

export interface LegalClientFinancialMonitoringInfoEntity {
  authorityName: string;
  authorityWebsite?: Maybe<string>;
  hasAmlCftComplianceControl: boolean;
  hasTransactionMonitoringAndSuspiciousActivityDetection: boolean;
  amlPolicyReviewFrequency: AmlPolicyReviewFrequency;
  otherAmlPolicyReviewFrequency?: Maybe<string>;
  amlCftPoliciesApplyToForeignEntities?: boolean | null;
  hasAmlCftDocumentEffectivenessAssessmentProcedures: boolean;
  dateOfLastCheck?: Maybe<Date>;
  hasAmlCftInternalControlOfficer: boolean;
  canProvideAmlPolicyDocuments: boolean;
  documentProvisionRefusalReason?: Maybe<string>;
  isInternalControlInfoConfidential: boolean;
  hadAmlCftInvestigationsOrPenalties: boolean;
  investigationOrPenaltyReasons?: Maybe<string>;
  hasMandatoryControlTransactions: boolean;
  mandatoryControlTransactionIdentificationProcedure?: Maybe<string>;
  monetaryServiceTransactionCountForLastSixMonths: MonetaryServiceTransactionCountForLastSixMonths;
  isAffiliatedWithNonProfitOrganizations: boolean;
  receivesFundingFromStateBudget: boolean;
  receivesFundingFromForeignNonProfitNGOs: boolean;
  isCompliantWithFATFRecommendations: boolean;
  hasBeenRefusedToOpenAccountByBanks: boolean;
  officer?: Maybe<LegalClientFinancialMonitoringInfoOfficerEntity>;
}

export enum AmlPolicyReviewFrequency {
  NeverReviewed = 'NeverReviewed',
  EveryYear = 'EveryYear',
  MoreThanTwiceAYear = 'MoreThanTwiceAYear',
  OnlyWhenThereAreChanges = 'OnlyWhenThereAreChanges',
  Other = 'Other',
}

export enum MonetaryServiceTransactionCountForLastSixMonths {
  None = 'None',
  UpTo100 = 'UpTo100',
  Between100And500 = 'Between100And500',
  Over500 = 'Over500',
}
