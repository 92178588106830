export enum OrganizationType {
  Commercial = 'Commercial',
  NonCommercial = 'NonCommercial',
}

export interface OrganizationalAndLegalFormEntity {
  organizationType: OrganizationType;
  organizationalAndLegalForm: string;
  isFinancialInstitution?: boolean;
  isUnincorporatedStructure?: boolean;
}
