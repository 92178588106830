import { KYCStatus } from 'api/types/data';
import {
  ClientEntity,
  ClientOnboardingStatusType,
  OnboardingStatus,
} from 'api/types/entity';

import { assertNever } from '../../../../helpers';

export const useClientOnboardingStatus = (
  client: ClientEntity | null
): OnboardingStatus | undefined => {
  const onboardingStatus = client?.onboardingStatus;
  const kycStatusIsError = client?.kyc?.status === KYCStatus.error;

  if (kycStatusIsError) return 'rejected';

  switch (onboardingStatus) {
    case ClientOnboardingStatusType.AccountOpened:
      return 'success';

    case ClientOnboardingStatusType.AccountOpening:
    case ClientOnboardingStatusType.AwaitingAccountOpening:
    case ClientOnboardingStatusType.AwaitingValidation:
    case ClientOnboardingStatusType.BoardReview:
    case ClientOnboardingStatusType.AmlCftCheck:
    case ClientOnboardingStatusType.AwaitingAmlCftCheck:
    case ClientOnboardingStatusType.AwaitingComplianceCheck:
    case ClientOnboardingStatusType.ValidationCheck:
    case ClientOnboardingStatusType.ComplianceCheck:
    case ClientOnboardingStatusType.PendingBoardReview:
    case ClientOnboardingStatusType.SigningDocuments:
      return 'inProcess';

    case ClientOnboardingStatusType.AccountOpeningDenied:
      return 'rejected';

    case ClientOnboardingStatusType.FillingOut:
      return undefined;

    default:
      if (onboardingStatus) {
        return assertNever('Unexpected onboardingStatus', onboardingStatus);
      }
      return undefined;
  }
};
