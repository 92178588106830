import { FC, ReactNode } from 'react';

import { OnboardingStatus } from 'api/types/entity';
import { Chip, ChipProps, Stack, Typography } from 'libs/ui';

import { LayoutStyled } from './OnboardingStatusWrapper.styles';

export type OnboardingStatusViewWrapperProps = {
  onboardingStatus: OnboardingStatus;
  title: string;
  statusText: string;
  children: ReactNode | ReactNode[];
};

const CHIP_VARIANT: Record<OnboardingStatus, ChipProps['variant']> = {
  openingProcess: 'blue',
  success: 'green',
  inProcess: 'blue',
  rejected: 'red',
  awaitingPayment: 'blue',
  identityConfirmed: 'green',
};

export const OnboardingStatusWrapper: FC<OnboardingStatusViewWrapperProps> = ({
  onboardingStatus,
  children,
  statusText,
  title,
}) => (
  <LayoutStyled variant="filled-all">
    <Stack className="onboardingStatus-content" spacing={32}>
      <Typography
        as="h1"
        datax="onboardingStatus-title"
        variant="header"
        variantMobile="subtitle"
      >
        {title}
      </Typography>

      <Typography datax="onboardingStatus-text" whiteSpace="pre-wrap">
        {children}
      </Typography>

      <Chip
        datax="onboardingStatus-status"
        text={statusText}
        variant={CHIP_VARIANT[onboardingStatus]}
      />
    </Stack>
  </LayoutStyled>
);
