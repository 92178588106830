import { getYesOrNoOptions } from 'helpers';
import { useTranslation } from 'libs/i18n';
import { YesOrNoType } from 'types/yesOrNo';

import { phoneValidation } from '../../../../common/helpers/validations';
import {
  Field,
  phoneMaxLength,
  siteMaxLength,
  disclosureInformationResourceMaxLength,
  useFormContext,
} from '../../../forms/companyContactsForm';

export interface FormContentProps {}

export const FormContent = () => {
  const { t } = useTranslation();
  const { values } = useFormContext();

  return (
    <>
      <Field.Text
        showOptionalMark
        checkValue={phoneValidation}
        label={t('onboarding.companyContacts.phone.label')}
        maxLength={phoneMaxLength}
        name="phone"
        placeholder={t('onboarding.companyContacts.phone.placeholder')}
      />

      <Field.Text
        showOptionalMark
        label={t('onboarding.companyContacts.email.label')}
        name="email"
        placeholder={t('onboarding.companyContacts.email.placeholder')}
        type="email"
      />

      <Field.Text
        showOptionalMark
        label={t('onboarding.companyContacts.site.label')}
        maxLength={siteMaxLength}
        name="site"
        placeholder={t('onboarding.companyContacts.site.placeholder')}
      />

      <Field.Autocomplete
        label={t('onboarding.companyContacts.disclosureInformation.label')}
        name="disclosureInformation"
        optionLabelKey="label"
        options={getYesOrNoOptions(t)}
        optionValueKey="value"
        placeholder={t('common.select.placeholder')}
        variant="select"
      />

      {values.disclosureInformation &&
        values.disclosureInformation.value === YesOrNoType.Yes && (
          <Field.Text
            label={t(
              'onboarding.companyContacts.disclosureInformationDescription.label'
            )}
            maxLength={disclosureInformationResourceMaxLength}
            name="disclosureInformationResource"
            placeholder={t(
              'onboarding.companyContacts.disclosureInformationDescription.placeholder'
            )}
          />
        )}
    </>
  );
};
