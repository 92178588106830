import {
  BusinessRelationshipGoalType,
  BusinessRelationshipNatureType,
  OperationType,
} from 'api/types/entity';
import { LegalClientSaveBusinessRelationshipParam } from 'api/types/params';

import { CompanyBusinessRelationshipFormType } from '../../forms/companyBusinessRelationshipForm';

export const getSubmitData = (
  values: CompanyBusinessRelationshipFormType
): LegalClientSaveBusinessRelationshipParam => {
  const plannedAssetTypes = values.plannedAssetTypes.map((v) => v.value);
  const businessRelationshipGoals = values.businessRelationshipGoals.map(
    (v) => v.value
  );
  const businessRelationshipNatures = values.businessRelationshipNatures.map(
    (v) => v.value
  );
  const plannedOperationTypes = values.plannedOperationTypes.map(
    (v) => v.value
  );

  return {
    plannedAssetTypes,
    businessRelationshipGoals,
    businessRelationshipNatures,
    plannedAssetSize: values.plannedAssetSize,
    plannedOperationTypes,
    otherBusinessRelationshipNature: businessRelationshipNatures.includes(
      BusinessRelationshipNatureType.Other
    )
      ? values.otherBusinessRelationshipNature!
      : null,

    otherBusinessRelationshipGoal: businessRelationshipGoals.includes(
      BusinessRelationshipGoalType.Other
    )
      ? values.otherBusinessRelationshipGoal!
      : null,

    otherPlannedOperationType: plannedOperationTypes.includes(
      OperationType.Other
    )
      ? values.otherPlannedOperationType!
      : null,
  };
};
