import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useLegalClientSaveRegistrationPlaceMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { SaveCompanyRegistrationPlaceParam } from 'api/types/params';

import { parseLegalClient } from './helpers';

export const useLegalClientSaveRegistrationPlace = () => {
  const [handle, { data, loading, error: apolloError }] =
    useLegalClientSaveRegistrationPlaceMutation();

  const error = useError(apolloError);

  return {
    data: data?.legalClientSaveRegistrationPlace
      ? parseLegalClient(data.legalClientSaveRegistrationPlace)
      : undefined,
    loading,
    error,
    saveRegistrationPlace: useCallback(
      async (id: string, params: SaveCompanyRegistrationPlaceParam) => {
        const res = await handle({
          variables: {
            id,
            input: params,
          },
        });
        return res?.data?.legalClientSaveRegistrationPlace
          ? parseLegalClient(res.data.legalClientSaveRegistrationPlace)
          : undefined;
      },
      [handle]
    ),
  };
};
