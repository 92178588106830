// eslint-disable-next-line no-restricted-imports
import {
  BiographyDto,
  Query_ClientController_GetById_Representative_Biography_MaritalStatus as MaritalStatusApi,
  Query_ClientController_GetById_Representative_Biography_Occupation as OccupationApi,
} from 'api/generated/graphql';
import { MaritalStatusType, OccupationType } from 'api/types/entity';
import { BiographyParam } from 'api/types/params/onboarding/biographyParam';
import { formatToISODate } from 'helpers';

const maritalStatus: Record<MaritalStatusType, MaritalStatusApi> = {
  [MaritalStatusType.married]: MaritalStatusApi.Married,
  [MaritalStatusType.single]: MaritalStatusApi.Single,
};
const occupation: Record<OccupationType, OccupationApi> = {
  [OccupationType.businessOwner]: OccupationApi.BusinessOwner,
  [OccupationType.employee]: OccupationApi.Employee,
  [OccupationType.entrepreneur]: OccupationApi.Entrepreneur,
  [OccupationType.retired]: OccupationApi.Retired,
  [OccupationType.student]: OccupationApi.Student,
  [OccupationType.unemployed]: OccupationApi.Unemployed,
};

export const parseBiographyParams = (
  biography: BiographyParam
): BiographyDto => ({
  ...biography,

  occupation: occupation[biography.occupation],
  maritalStatus: maritalStatus[biography.maritalStatus],

  registrationDate: biography.registrationDate
    ? formatToISODate(biography.registrationDate)
    : undefined,
});
