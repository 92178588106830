import { DetailedHTMLProps, HTMLAttributes } from 'react';

import { styled } from '../styled';

export interface BoxProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  flex?: number;
  maxWidth?: number | string;
}

export const Box = styled('div', {
  shouldForwardProp: (prop) =>
    prop !== 'justifyContent' && prop !== 'alignItems',
})<BoxProps>(({ flex, maxWidth }) => ({
  flex,
  maxWidth,
}));
