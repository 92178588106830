import { ConfirmationDialog } from 'components';
import { useTranslation } from 'libs/i18n';
import { DialogProps, Stack, Typography } from 'libs/ui';

interface Props extends DialogProps {
  loading?: boolean;
  onConfirm: () => void;
  onBack: () => void;
}

export const OnboardingConfirmationDataDialog = (props: Props) => {
  const { t } = useTranslation();

  const renderLi = (text: string) => <Typography as="li">{text}</Typography>;

  return (
    <ConfirmationDialog
      fullWidth
      confirmButtonLabel={t('onboarding.confirmationDataDialog.confirmButton')}
      maxWidth="lg"
      title={t('onboarding.confirmationDataDialog.title')}
      {...props}
    >
      <Stack as="ul" datax="confirmationDataDialog-text" spacing={16}>
        {renderLi(t('onboarding.confirmationDataDialog.text1'))}
        {renderLi(t('onboarding.confirmationDataDialog.text2'))}
        {renderLi(t('onboarding.confirmationDataDialog.text3'))}
      </Stack>
    </ConfirmationDialog>
  );
};
