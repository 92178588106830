import { useTranslation } from 'libs/i18n';
import { ROUTES, useNavigate } from 'libs/navigation';
import { Button, Stack, Typography } from 'libs/ui';

import { ErrorLayoutStyled } from './ErrorLayout.styles';

interface Props {
  title?: string;
  subtitle?: string;
  buttonLabel?: string;
  onButtonClick?: () => void;
}

export const ErrorLayout = ({
  buttonLabel,
  subtitle,
  title,
  onButtonClick,
}: Props) => {
  const { t } = useTranslation();
  const { navigate } = useNavigate();

  const handleClick = () => {
    if (onButtonClick) {
      onButtonClick();
    } else {
      navigate(ROUTES.fullPath, {}, { replace: true });
    }
  };

  return (
    <ErrorLayoutStyled alignItems="center" justifyContent="center">
      <Stack alignItems="center" className="errorLayout-content" spacing={32}>
        <Typography align="center" variant="title">
          {title ?? t('components.errorLayout.title')}
        </Typography>

        {!!subtitle && <Typography align="center">{subtitle}</Typography>}

        <Button
          label={buttonLabel ?? t('components.errorLayout.button.label')}
          onClick={handleClick}
        />
      </Stack>
    </ErrorLayoutStyled>
  );
};
