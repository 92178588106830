import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useClientSaveTaxResidencesMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { OnboardingTaxResidencesParam } from 'api/types/params';

import { parseClientEntity } from '../helpers';

export const useClientSaveTaxResidences = () => {
  const [saveTaxResidences, { data, loading, error: apolloError }] =
    useClientSaveTaxResidencesMutation();

  const error = useError(apolloError);

  return {
    data: data?.clientSaveTaxResidences
      ? parseClientEntity(data.clientSaveTaxResidences)
      : undefined,
    loading,
    error,
    saveTaxResidences: useCallback(
      async (id: string, params: OnboardingTaxResidencesParam) => {
        const res = await saveTaxResidences({
          variables: { id, input: params },
        });
        return res.data?.clientSaveTaxResidences
          ? parseClientEntity(res.data.clientSaveTaxResidences)
          : undefined;
      },
      [saveTaxResidences]
    ),
  };
};
