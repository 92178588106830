import { useTranslation } from 'libs/i18n';
import {
  ClientType,
  FormBodyWrapper,
} from 'modules/onboarding/common/components';

import {
  CompanyActivitiesFormType,
  FormErrors,
  getSchema,
} from '../../forms/companyActivitiesForm';

import { FormContent, FormContentProps } from './FormContent';

interface CompanyActivitiesProps extends FormContentProps {
  initialValues?: CompanyActivitiesFormType;
  onSubmit: (values: CompanyActivitiesFormType) => void;
  canGoBack?: boolean;
  onGoBack?: () => void;
  formError?: FormErrors;
  submitLoading?: boolean;
  clientType?: ClientType;
}

export const CompanyActivities = ({
  initialValues,
  canGoBack,
  onGoBack,
  submitLoading,
  formError,
  onSubmit,
  clientType,
  ...formProps
}: CompanyActivitiesProps) => {
  const schema = getSchema();
  const { t } = useTranslation();
  return (
    <FormBodyWrapper
      canGoBack={canGoBack}
      clientType={clientType}
      initialErrors={formError}
      initialValues={initialValues}
      submitLoading={submitLoading}
      subTitle={t('onboarding.companyActivities.subtitle')}
      title={t('onboarding.companyActivities.title')}
      validationSchema={schema}
      onGoBack={onGoBack}
      onSubmit={onSubmit}
    >
      <FormContent {...formProps} />
    </FormBodyWrapper>
  );
};
