import { useRef } from 'react';

import { useClientSaveFinancingSources } from 'api/requests';
import { ClientEntity } from 'api/types/entity';
import { useFilesUpload, useServerErrorNotify } from 'hooks';
import { useTranslation } from 'libs/i18n';

import { FinancingSourcesFormType } from '../forms/financingSourcesForm';
import { getInitialData } from '../helpers/financingSources/getInitialData';
import { getSubmitData } from '../helpers/financingSources/getSubmitData';

export const useClientFinancingSources = (client: ClientEntity) => {
  const {
    error,
    loading: submitLoading,
    saveFinancingSources: saveFinancingSourcesApi,
  } = useClientSaveFinancingSources();
  const { t } = useTranslation();

  const initialData = useRef(getInitialData(client, t)).current;
  const initialValues = useRef(initialData.values).current;

  const {
    files,
    isLoadingFile: submitDisabled,
    onRemoveFile,
    onAddFiles,
  } = useFilesUpload(initialData.files);

  const saveFinancingSources = async (values: FinancingSourcesFormType) => {
    const submitData = getSubmitData(values);
    await saveFinancingSourcesApi(client.id, submitData);
  };

  useServerErrorNotify(error);

  return {
    saveFinancingSources,
    files,
    onAddFiles,
    onRemoveFile,
    submitDisabled,
    submitLoading,
    initialValues,
  };
};
