import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useLegalClientSaveTaxResidencesMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { LegalClientSaveTaxResidencesParam } from 'api/types/params';

import { parseLegalClient } from './helpers';

export const useLegalClientSaveTaxResidences = () => {
  const [handle, { data, loading, error: apolloError }] =
    useLegalClientSaveTaxResidencesMutation();

  const error = useError(apolloError);

  return {
    data: data?.legalClientSaveTaxResidences
      ? parseLegalClient(data.legalClientSaveTaxResidences)
      : undefined,
    loading,
    error,
    saveTaxResidences: useCallback(
      async (id: string, params: LegalClientSaveTaxResidencesParam) => {
        const res = await handle({
          variables: {
            id,
            input: params,
          },
        });
        return res?.data?.legalClientSaveTaxResidences
          ? parseLegalClient(res.data.legalClientSaveTaxResidences)
          : undefined;
      },
      [handle]
    ),
  };
};
