import { tokenStorage } from 'api/client/helpers/tokenStorage';
import { useUser } from 'libs/providers';

import { RegistrationPassword } from '../components/RegistrationPassword';
import { useRegistrationContext } from '../components/RegistrationProvider';
import { RegistrationPasswordFormType } from '../forms/registrationPasswordForm';
import { useRegistrationPassword } from '../hooks';
import { RegistrationStepProps } from '../types';

export const RegistrationPasswordStep = ({
  canGoBack,
  onGoBack,
  onGoNext,
}: RegistrationStepProps) => {
  const { reUser } = useUser();
  const { changeContextData } = useRegistrationContext();
  const { onSubmit, loading, formError, passwordValidationRule } =
    useRegistrationPassword();

  const handleSubmit = async (values: RegistrationPasswordFormType) => {
    const loginData = await onSubmit(values);
    if (loginData) {
      tokenStorage.login(loginData);
      changeContextData(values);
      reUser();
      onGoNext();
    }
  };

  return (
    <RegistrationPassword
      canGoBack={canGoBack}
      formError={formError}
      passwordValidationRule={passwordValidationRule}
      submitLoading={loading}
      onGoBack={onGoBack}
      onSubmit={handleSubmit}
    />
  );
};
