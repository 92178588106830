import { StateRegistration } from '../components';
import { useStateRegistration, useOnboardingContext } from '../hooks';
import { PageComponentProps } from '../types';

export const StateRegistrationStep = ({ canGoBack }: PageComponentProps) => {
  const { goToBack, initialValues, onSubmit, submitLoading } =
    useStateRegistration();
  const { step } = useOnboardingContext();

  return (
    <StateRegistration
      canGoBack={canGoBack}
      initialValues={initialValues}
      step={step}
      submitLoading={submitLoading}
      onGoBack={goToBack}
      onSubmit={onSubmit}
    />
  );
};
