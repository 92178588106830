import { useCallback, useEffect } from 'react';

import {
  useRegistrationEmailCheckCode,
  useRegistrationEmailSendCode,
} from 'api/requests';
import { useObjectState } from 'hooks';
import { useTranslation } from 'libs/i18n';

import { useRegistrationContext } from '../components/RegistrationProvider';
import {
  RegistrationEmailFormType,
  FormErrors,
} from '../forms/registrationEmailForm';

export const useRegistrationEmail = () => {
  const { agreementIds, registrationType } = useRegistrationContext();

  const {
    sendCode,
    data,
    loading: sendLoading,
    error: sendCodeError,
  } = useRegistrationEmailSendCode();

  const {
    checkEmailCode,
    error: codeError,
    loading: submitLoading,
  } = useRegistrationEmailCheckCode();

  const { t } = useTranslation();

  const onSubmitEmail = useCallback(
    async (values: RegistrationEmailFormType) =>
      checkEmailCode(values.email, values.code),
    [checkEmailCode]
  );

  const onSendEmailCode = useCallback(
    async (email: string) => sendCode(email, registrationType, agreementIds),
    [agreementIds, registrationType, sendCode]
  );

  const [emailFormError, setEmailFormError] = useObjectState<FormErrors>({});

  useEffect(() => {
    if (codeError) {
      setEmailFormError({ code: t('auth.registration.codeError') });
    } else {
      setEmailFormError({ code: undefined });
    }
  }, [codeError, setEmailFormError, t]);

  useEffect(() => {
    if (sendCodeError) {
      setEmailFormError({ email: sendCodeError.message });
    } else {
      setEmailFormError({ email: undefined });
    }
  }, [sendCodeError, setEmailFormError]);

  return {
    onSubmitEmail,
    onSendEmailCode,
    emailFormError,
    resendTime: data.resendTime,
    sendLoading,
    submitLoading,
  };
};
