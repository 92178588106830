import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useMemo,
} from 'react';

import { useObjectState } from 'hooks';

interface ContextValue {
  token?: string;
  email?: string;
  otpRequired?: boolean;
}
interface Actions {
  changeData: (values: Partial<ContextValue>) => void;
}

const Context = createContext<{ data: ContextValue } & Actions>(null as never);

export const RestorePasswordProvider = ({ children }: PropsWithChildren) => {
  const [data, setData] = useObjectState<ContextValue>({});

  const changeData = useCallback(
    (values: Partial<ContextValue>) => {
      setData(values);
    },
    [setData]
  );

  const value = useMemo(
    () => ({
      data,
      changeData,
    }),
    [changeData, data]
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useRestorePasswordContext = () => useContext(Context);
