// eslint-disable-next-line no-restricted-imports
import { MutationInput_ClientController_SaveWithdrawalAccounts_Input_WithdrawalAccounts_Items_Currency as CurrencyApi } from 'api/generated/graphql';
import { CurrencyType } from 'api/types/entity';

const currencyMap: Record<CurrencyType, CurrencyApi> = {
  [CurrencyType.AED]: CurrencyApi.Aed,
  [CurrencyType.CHF]: CurrencyApi.Chf,
  [CurrencyType.CNY]: CurrencyApi.Cny,
  [CurrencyType.EUR]: CurrencyApi.Eur,
  [CurrencyType.GBP]: CurrencyApi.Gbp,
  [CurrencyType.HKD]: CurrencyApi.Hkd,
  [CurrencyType.IDR]: CurrencyApi.Idr,
  [CurrencyType.INR]: CurrencyApi.Inr,
  [CurrencyType.JPY]: CurrencyApi.Jpy,
  [CurrencyType.KZT]: CurrencyApi.Kzt,
  [CurrencyType.RUB]: CurrencyApi.Rub,
  [CurrencyType.USD]: CurrencyApi.Usd,
};

export const parseCurrencyParam = (currency: CurrencyType): CurrencyApi =>
  currencyMap[currency];
