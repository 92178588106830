import { CompanyName } from 'modules/onboarding/common/components';

import { useCompanyName } from '../hooks';
import { PageComponentProps } from '../types';

export const CompanyNameStep = ({ canGoBack }: PageComponentProps) => {
  const { initialValues, onSubmit, submitLoading, goToBack } = useCompanyName();

  return (
    <CompanyName
      showEng
      canGoBack={canGoBack}
      initialValues={initialValues}
      submitLoading={submitLoading}
      onGoBack={goToBack}
      onSubmit={onSubmit}
    />
  );
};
