import { Stack } from 'libs/ui';
import { CSSObject, styled } from 'libs/ui/styled';

export const ContentStyled = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  gap: 32,

  [theme.breakpoints.down('mobile')]: {
    flexDirection: 'column',
  },
}));

export const ListStyled = styled(Stack)(({ theme }) => ({
  paddingLeft: '1em',
  margin: 0,

  [theme.breakpoints.down('mobile')]: {
    gap: 20,
    marginBottom: 20,

    '.registrationTwoFactor-googleLink .link-label': {
      ...theme.typography.caption,
    },
  } as CSSObject,
}));
