// eslint-disable-next-line no-restricted-imports
import { Link as LinkBase } from '@mui/material';

import { CSSObject, styled } from '../styled';

import { LinkProps } from './types';

export const LinkStyled = styled(LinkBase)<LinkProps>(
  ({ theme, disabled, mode }) => ({
    cursor: disabled ? 'default' : 'pointer',

    ...(mode === 'text' &&
      ({
        color: 'inherit',
        textDecoration: 'none',
        ...theme.typography.bodyBold,
      } as CSSObject)),
  })
);
