import { OrderSubmissionContacts } from '../components';
import { useOrderSubmissionContacts } from '../hooks';
import { PageComponentProps } from '../types';

export const OrderSubmissionContactsStep = ({
  canGoBack,
}: PageComponentProps) => {
  const { goToBack, initialValues, onSubmit, submitLoading } =
    useOrderSubmissionContacts();

  return (
    <OrderSubmissionContacts
      canGoBack={canGoBack}
      initialValues={initialValues}
      submitLoading={submitLoading}
      onGoBack={goToBack}
      onSubmit={onSubmit}
    />
  );
};
