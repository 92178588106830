import { useCallback, useLayoutEffect, useRef, useState } from 'react';

import {
  ScenarioName,
  useLegalClientCompleteLegalHeadOnboarding,
  useLegalClientGetLegalHeadLazy,
  useLegalClientLegalHeadSettingsSave,
  useOnboardingGetProcessByScenarioName,
} from 'api/requests';
import {
  LegalClientEntity,
  LegalClientRepresentativeEntity,
} from 'api/types/entity';
import { LegalClientCreateLegalHeadSaveParam } from 'api/types/params';
import { useServerErrorNotify } from 'hooks';

import { useCreateLegalHead } from './useCreateLegalHead';

export const useOnboarding = (
  currentClient: LegalClientRepresentativeEntity,
  legalClient: LegalClientEntity,
  headId?: string
) => {
  const legalHeadId = useRef(headId);

  const { saveSettings } = useLegalClientLegalHeadSettingsSave();

  const { complete } = useLegalClientCompleteLegalHeadOnboarding();

  const { getLegalHead, legalHead } = useLegalClientGetLegalHeadLazy();

  const { createLegalHead, createLegalHeadError } =
    useCreateLegalHead(legalClient);

  const { process, loading: processLoading } =
    useOnboardingGetProcessByScenarioName(ScenarioName.LegalClientLegalHead);

  const [loading, setLoading] = useState(false);

  const onGoBack = useCallback(
    async (currentStepName: string) => {
      if (legalClient && headId) {
        await saveSettings(legalClient.id, headId, currentStepName);
      }
    },
    [headId, legalClient, saveSettings]
  );

  const onEnd = useCallback(
    async () => complete(legalClient.id, legalHead!.id),
    [complete, legalClient.id, legalHead]
  );

  const handleCreateLegalHead = async (
    param: LegalClientCreateLegalHeadSaveParam
  ) => {
    const legalHead = await createLegalHead(param);
    if (legalHead) {
      await getLegalHead(legalClient.id, legalHead.id);
    }
    return legalHead;
  };

  useLayoutEffect(() => {
    if (legalHeadId.current) {
      setLoading(true);
      getLegalHead(legalClient.id, legalHeadId.current).finally(() =>
        setLoading(false)
      );
    }
  }, [getLegalHead, legalClient.id]);

  useServerErrorNotify(createLegalHeadError);

  return {
    currentClient,
    legalHead,
    legalClient,
    loading: loading || (processLoading && !process),
    process,
    onGoBack,
    onEnd,
    createLegalHead: handleCreateLegalHead,
  };
};
