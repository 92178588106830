import { FC, PropsWithChildren } from 'react';

import { LoadingView } from './LoadingView';

interface Props extends PropsWithChildren {
  loading: boolean;
}

export const LoadingViewWrapper: FC<Props> = ({ loading, children }) => {
  if (loading) {
    return <LoadingView />;
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};
