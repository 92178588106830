import { useRef } from 'react';

import { useLegalClientSaveFinancingSources } from 'api/requests';
import { useFilesUpload, useServerErrorNotify } from 'hooks';
import { useTranslation } from 'libs/i18n';

import { CompanyFinancingSourcesFormType } from '../forms/companyFinancingSourcesForm';
import {
  getInitialData,
  getSubmitData,
} from '../helpers/companyFinancingSources';

import { useOnboardingContext } from './useOnboardingContext';

export const useCompanyFinancingSources = () => {
  const { goToBack, goToNext, data } = useOnboardingContext();
  const { error, loading, saveFinancingSources } =
    useLegalClientSaveFinancingSources();

  const { t } = useTranslation();

  const initialValues = useRef(
    getInitialData(data.legalClient!.financingSource, t)
  ).current;

  const onSubmit = async (values: CompanyFinancingSourcesFormType) => {
    if (values) {
      const submitData = getSubmitData(values);
      await saveFinancingSources(data.legalClient!.id, submitData);
      goToNext();
    }
  };

  const {
    files: auditedFinancialReportFiles,
    isLoadingFile: auditedFinancialReportFilesLoading,
    onRemoveFile: onRemoveFileAuditedFinancialReportFiles,
    onAddFiles: onAddFilesAuditedFinancialReportFiles,
  } = useFilesUpload(initialValues.lastYearAuditFinancialReportFiles);

  const {
    files: recommendationLetterFiles,
    isLoadingFile: recommendationLetterFilesLoading,
    onRemoveFile: onRemoveFileRecommendationLetterFiles,
    onAddFiles: onAddFilesRecommendationLetterFiles,
  } = useFilesUpload(initialValues.recommendationLetterFiles);

  useServerErrorNotify(error);

  return {
    onSubmit,
    goToBack,
    submitLoading: loading,
    initialValues: initialValues.values,
    submitDisabled:
      auditedFinancialReportFilesLoading || recommendationLetterFilesLoading,
    auditedFinancialReportFiles,
    onRemoveFileAuditedFinancialReportFiles,
    onAddFilesAuditedFinancialReportFiles,
    recommendationLetterFiles,
    onRemoveFileRecommendationLetterFiles,
    onAddFilesRecommendationLetterFiles,
  };
};
