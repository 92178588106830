import { useRef } from 'react';

import { useClientSaveOrderSubmissionContacts } from 'api/requests';
import { ClientEntity } from 'api/types/entity';
import { useServerErrorNotify } from 'hooks';
import { useTranslation } from 'libs/i18n';

import { OrderSubmissionContactsFormType } from '../forms/orderSubmissionContacts';
import { getInitialData } from '../helpers/orderSubmissionContacts/getInitialData';

export const useClientOrderSubmissionContacts = (client: ClientEntity) => {
  const {
    error,
    saveOrderSubmissionContacts: saveOrderSubmissionContactsApi,
    loading: submitLoading,
  } = useClientSaveOrderSubmissionContacts();

  const { t } = useTranslation();

  const initialData = useRef(
    getInitialData(t, client.orderSubmissionContacts)
  ).current;
  const initialValues = useRef(initialData).current;

  const saveOrderSubmissionContacts = async (
    values: OrderSubmissionContactsFormType
  ) => {
    await saveOrderSubmissionContactsApi(
      client.id,
      values.orderSubmissionContacts.map(({ type, value }) => ({
        value,
        type: type.value,
      }))
    );
  };

  useServerErrorNotify(error);

  return {
    saveOrderSubmissionContacts,
    initialValues,
    submitLoading,
  };
};
