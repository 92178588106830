import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useLegalClientSaveStateRegistrationMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { LegalClientSaveStateRegistrationParam } from 'api/types/params/onboarding/legalClientSaveStateRegistrationParam';

import { parseLegalClient } from './helpers';

export const useLegalClientSaveStateRegistration = () => {
  const [handle, { data, loading, error: apolloError }] =
    useLegalClientSaveStateRegistrationMutation();

  const error = useError(apolloError);

  return {
    data: data?.legalClientSaveStateRegistration
      ? parseLegalClient(data.legalClientSaveStateRegistration)
      : undefined,
    loading,
    error,
    saveStateRegistration: useCallback(
      async (id: string, params: LegalClientSaveStateRegistrationParam) => {
        const res = await handle({
          variables: {
            id,
            input: params,
          },
        });
        return res?.data?.legalClientSaveStateRegistration
          ? parseLegalClient(res.data.legalClientSaveStateRegistration)
          : undefined;
      },
      [handle]
    ),
  };
};
