// eslint-disable-next-line no-restricted-imports
import {
  LegalClientLegalHeadFirstHeadForLegalClientLegalHeadFragment,
  LegalClientLegalHeadFirstHeadWithUserFragment,
} from 'api/generated/graphql';
import { LegalClientInnerItemEntity } from 'api/types/entity/legal/legalClientInnerItemEntity';
import { parseFullName } from 'helpers';

import { parseKyc } from '../../../helpers';

export const parseLegalClientFirstHeadItemEntity = (
  data:
    | LegalClientLegalHeadFirstHeadForLegalClientLegalHeadFragment
    | LegalClientLegalHeadFirstHeadWithUserFragment
): LegalClientInnerItemEntity => ({
  id: data.id,
  name: parseFullName(data),

  email: data.email,
  createdAt: new Date(data.createdAt),
  isCompletedByRepresentative:
    data.onboarding?.isCompletedByRepresentative ?? false,

  kyc: parseKyc(data.kyc),

  isActive: (data as LegalClientLegalHeadFirstHeadWithUserFragment)?.user
    ?.isActive,
});
