import { RoleInCompanyType } from 'api/types/entity';
import { SaveRoleInCompanyParam } from 'api/types/params/onboarding/saveRoleInCompanyParam';

import { RoleInCompanyFormType } from '../../forms/roleInCompanyForm';

export const getSubmitData = (
  values: RoleInCompanyFormType
): SaveRoleInCompanyParam => ({
  roleInCompany: values.role?.value!,
  authorityConfirmationDocument: {
    dateOfExpiration: values.dateOfExpiration,
    dateOfIssue: values.dateOfIssue,
    fileIds: values.fileIds,
    number: values.number,
  },
  civilLawSubject:
    values.role?.value === RoleInCompanyType.AuthorizedRepresentative
      ? values.civilLawSubject?.value!
      : undefined,
});
