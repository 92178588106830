import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useRegistrationEmailCheckCodeMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';

export const useRegistrationEmailCheckCode = () => {
  const [handle, { error: apolloError, loading }] =
    useRegistrationEmailCheckCodeMutation();

  const error = useError(apolloError);

  return {
    loading,
    error,
    checkEmailCode: useCallback(
      async (email: string, code: string) => {
        const res = await handle({ variables: { email, code } });
        return Boolean(res.data?.UserController_confirmEmail);
      },
      [handle]
    ),
  };
};
