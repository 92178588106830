import {
  AddressesParam,
  AddressesWithFilesParam,
} from 'api/types/params/onboarding/addressesParam';

import {
  BaseOnboardingRegistrationAddressFormType,
  OnboardingRegistrationAddressFormType,
} from '../../forms/onboardingRegistrationAddressForm';

export const getBaseSubmitData = <
  F extends boolean,
  T = F extends true ? AddressesWithFilesParam : AddressesParam
>(
  values: BaseOnboardingRegistrationAddressFormType,
  isEnabledFiles?: F
): T => {
  const registrationAddress = {
    country: values.countryRegistration?.id!,
    region: values.regionRegistration,
    apartment: values.apartmentRegistration,
    building: values.housingRegistration,
    district: values.areaRegistration,
    house: values.houseNumberRegistration,
    locality: values.localityRegistration,
    postalCode: values.postcodeRegistration,
    street: values.streetRegistration,
  };

  const residentialAddress = !values.residentialAddressIsSame
    ? {
        country: values.countryResidence?.id!,
        region: values.regionResidence!,
        apartment: values.apartmentResidence!,
        building: values.housingResidence,
        district: values.areaResidence,
        house: values.houseNumberResidence!,
        locality: values.localityResidence!,
        postalCode: values.postcodeResidence!,
        street: values.streetResidence!,
      }
    : registrationAddress;

  return {
    registrationAddress,

    residentialAddress: {
      ...residentialAddress,
      proofOfResidenceFileIds: isEnabledFiles
        ? values.proofOfResidenceFileIds!
        : undefined,
    },
  } as T;
};

export const getSubmitData = <
  F extends boolean,
  T = F extends true ? AddressesWithFilesParam : AddressesParam
>(
  values: OnboardingRegistrationAddressFormType,
  isEnabledFiles?: F
): T => {
  const baseSubmitData = getBaseSubmitData(values, isEnabledFiles);

  return {
    ...baseSubmitData,
    placeOfBirth: {
      country: values.countryOfBirth?.id!,
      locality: values.localityOfBirth ?? '',
    },
  } as T;
};
