import { getColor } from 'helpers';
import { Skeleton, Stack } from 'libs/ui';
import { styled } from 'libs/ui/styled';
import { ColorsDark, ColorsLight } from 'libs/ui/theme';

export const ContainerStyled = styled(Stack)(() => ({
  minWidth: 223,
}));
export const SkeletonStyled = styled(Skeleton)(() => ({
  minWidth: '100%',
  minHeight: 223,
}));

export const ImageContainerStyled = styled('div')(({ theme }) => ({
  backgroundColor: getColor(theme, {
    dark: ColorsDark.gray50,
    light: ColorsLight.gray50,
  }),
  padding: 22,
  borderRadius: 12,
  display: 'flex',
  flexDirection: 'column',
  minHeight: 223,

  [theme.breakpoints.down('mobile')]: {
    height: 223,
    width: 223,
    alignSelf: 'center',
  },
}));

export const SercetStyled = styled('div')(({ theme }) => ({
  backgroundColor: getColor(theme, {
    dark: ColorsDark.gray50,
    light: ColorsLight.gray50,
  }),
  borderRadius: 8,
  border: `1px solid ${getColor(theme, {
    dark: ColorsDark.gray150,
    light: ColorsLight.gray150,
  })};`,
  width: '100%',
  maxWidth: 300,
  alignSelf: 'center',
  height: 38,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}));
