import { useColor } from 'hooks';
import { ColorsDark, ColorsLight } from 'libs/ui/theme';

import { SvgIcon } from '../SvgIcon';
import { SvgProps } from '../types';

export const CloudUpload = ({ size = 24, color, className }: SvgProps) => {
  const defaultColor = useColor({
    dark: ColorsDark.gray150,
    light: ColorsLight.gray300,
  });
  color = color || defaultColor;
  return (
    <SvgIcon className={className} size={size}>
      <svg
        fill="none"
        height="48"
        viewBox="0 0 49 48"
        width="49"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_6788_48364)">
          <path
            d="M26 26.865V41.4101C26 42.2385 25.3284 42.9101 24.5 42.9101C23.6716 42.9101 23 42.2385 23 41.4101V26.8649L18.9855 30.8795C18.6927 31.1724 18.3088 31.3189 17.9249 31.3189C17.5411 31.3189 17.1572 31.1724 16.8643 30.8795C16.2785 30.2938 16.2785 29.344 16.8643 28.7582L23.4394 22.1831C23.7207 21.9017 24.1022 21.7437 24.5 21.7437C24.8978 21.7437 25.2793 21.9017 25.5606 22.1831L32.1357 28.7582C32.7216 29.344 32.7216 30.2938 32.1357 30.8795C31.55 31.4653 30.6002 31.4653 30.0145 30.8795L26 26.865ZM46.1363 20.7996C44.8089 19.1663 43.0168 17.9598 41.0274 17.3463C39.4376 13.7701 35.868 11.4196 31.9041 11.4196C31.6451 11.4196 31.3856 11.4297 31.1267 11.4499C30.1252 9.71675 28.727 8.24094 27.0358 7.14134C24.9754 5.80184 22.5823 5.09375 20.115 5.09375C13.1943 5.09375 7.54737 10.6526 7.40375 17.5395C5.59278 18.2136 3.97719 19.385 2.77044 20.9161C1.30634 22.7738 0.5 25.0909 0.5 27.4405C0.5 33.2619 5.23606 37.9979 11.0575 37.9979H17.7678C18.5962 37.9979 19.2678 37.3264 19.2678 36.4979C19.2678 35.6695 18.5962 34.9979 17.7678 34.9979H11.0575C6.89028 34.9979 3.5 31.6077 3.5 27.4405C3.5 23.9984 5.93319 20.9092 9.28559 20.0949C9.99809 19.9218 10.4811 19.259 10.4276 18.5277C10.4099 18.2868 10.4009 18.0447 10.4009 17.8077C10.4009 12.4514 14.7586 8.09375 20.115 8.09375C23.8758 8.09375 27.3336 10.2978 28.9239 13.7089C29.2148 14.3326 29.8928 14.678 30.5684 14.5477C31.0075 14.4627 31.4569 14.4196 31.9042 14.4196C34.8689 14.4196 37.5182 16.2989 38.4966 19.096C38.6676 19.5851 39.079 19.9513 39.5846 20.0645C43.0122 20.8323 45.5 23.9343 45.5 27.4405C45.5 31.6077 42.1097 34.9979 37.9425 34.9979H31.2322C30.4038 34.9979 29.7322 35.6695 29.7322 36.4979C29.7322 37.3264 30.4038 37.9979 31.2322 37.9979H37.9425C43.7639 37.9979 48.5 33.2618 48.5 27.4405C48.5 25.0336 47.6606 22.6752 46.1363 20.7996Z"
            fill={color}
          />
        </g>
        <defs>
          <clipPath id="clip0_6788_48364">
            <rect
              fill="white"
              height="48"
              transform="translate(0.5)"
              width="48"
            />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};
