import { useTranslation } from 'libs/i18n';
import { Field } from 'modules/onboarding/common/forms/invoiceForPayment';
import { currencyInvoiceOptions } from 'modules/onboarding/common/helpers/currencyInvoice';

export const FormContent = () => {
  const { t } = useTranslation();

  return (
    <Field.Autocomplete
      label={t('onboarding.invoiceForPayment.currency.label')}
      name="currency"
      optionLabelKey="label"
      options={currencyInvoiceOptions}
      optionValueKey="value"
      placeholder={t('onboarding.invoiceForPayment.currency.placeholder')}
      variant="select"
    />
  );
};
