import { useContext } from 'react';

import {
  LegalClientEntity,
  LegalClientRepresentativeEntity,
} from 'api/types/entity';
import {
  OnboardingContext,
  OnboardingContextProps,
} from 'modules/onboarding/common/providers/OnboardingProvider/Context';

import { InnerState } from './useOnboarding/type';

export const useOnboardingContext = () => {
  const res = useContext(OnboardingContext);
  return res as OnboardingContextProps<{
    currentClient: LegalClientRepresentativeEntity;
    legalClient: LegalClientEntity | null;
    innerState: InnerState;
  }>;
};
