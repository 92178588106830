import { LoginData } from 'api/types/data';
import { LocalStorage } from 'libs/storage';
import { v4 as uuidv4 } from 'uuid';

/**
 * Class for managing token storage in local storage.
 */
class TokenStorage {
  /**
   * The access token stored in local storage.
   * @private
   */
  private accessToken?: string;

  /**
   * The refresh token stored in local storage.
   */
  private refreshToken?: string;

  /**
   * The uniq device id.
   */
  private deviceId?: string;

  /**
   * Creates a new TokenStorage instance.
   * Initializes the accessToken and refreshToken from local storage if available.
   */
  constructor() {
    this.refreshToken =
      LocalStorage.getItem(LocalStorage.keys.refreshToken) || undefined;
    this.deviceId = TokenStorage.generateDeviceId();
  }

  login(loginData: LoginData) {
    this.setAccessToken(loginData.accessToken);
    this.setRefreshToken(loginData.refreshToken);
  }

  /**
   * Sets the access token in local storage and updates the instance's accessToken.
   * @param {string | undefined} accessToken - The access token to set or undefined to remove.
   */
  setAccessToken(accessToken: string | undefined) {
    this.accessToken = accessToken;
  }

  /**
   * Gets the access token from the instance.
   * @returns {string | undefined} - The access token.
   */
  getAccessToken() {
    return this.accessToken;
  }

  /**
   * Gets the refresh token from the instance.
   * @returns {string | undefined} - The refresh token.
   */
  getRefreshToken() {
    return this.refreshToken;
  }

  getDeviceId() {
    return this.deviceId;
  }

  /**
   * Sets the refresh token in local storage and updates the instance's refreshToken.
   * @param {string | undefined} refreshToken - The refresh token to set or undefined to remove.
   */
  setRefreshToken(refreshToken: string | undefined) {
    this.refreshToken = refreshToken;
    LocalStorage.setOrRemoveItem(LocalStorage.keys.refreshToken, refreshToken);
  }

  /**
   * Clears both access token and refresh token from local storage and the instance.
   */
  clear() {
    this.setRefreshToken(undefined);
    this.setAccessToken(undefined);
  }

  /** Get or generate a device identifier */
  private static generateDeviceId() {
    // Check if device ID is already stred in localStorage
    let deviceId = LocalStorage.getItem(LocalStorage.keys.deviceId);

    if (!deviceId) {
      const uuid = uuidv4();
      deviceId = `${uuid}-${new Date().getTime()}`;

      // Store the device ID in localStorage for future use
      LocalStorage.setItem(LocalStorage.keys.deviceId, deviceId);
    }
    return deviceId;
  }
}

/**
 * Singleton instance of the TokenStorage class for managing token storage.
 */
export const tokenStorage = new TokenStorage();
