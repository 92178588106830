import { OrganizationalAndLegalForm } from '../components';
import { useOrganizationalAndLegalForm } from '../hooks';
import { PageComponentProps } from '../types';

export const OrganizationalAndLegalFormStep = ({
  canGoBack,
}: PageComponentProps) => {
  const { goToBack, initialValues, onSubmit, submitLoading } =
    useOrganizationalAndLegalForm();

  return (
    <OrganizationalAndLegalForm
      canGoBack={canGoBack}
      initialValues={initialValues}
      submitLoading={submitLoading}
      onGoBack={goToBack}
      onSubmit={onSubmit}
    />
  );
};
