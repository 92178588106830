// eslint-disable-next-line no-restricted-imports
import SnackbarBase from '@mui/material/Snackbar';

import { Alert } from '../Alert';
import { AlertVariant } from '../Alert/types';

interface Props {
  visible: boolean;
  variant?: AlertVariant;
  text?: string;
  onClose: () => void;
}

export const Snackbar = ({
  visible,
  text,
  variant,
  onClose,
}: Props): JSX.Element => (
  <SnackbarBase
    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    open={visible}
  >
    <Alert variant={variant} onClose={onClose}>
      {text}
    </Alert>
  </SnackbarBase>
);
