import { FileUpload } from 'hooks';
import { useTranslation } from 'libs/i18n';
import { FileType } from 'libs/ui';

import { Field, MAX_FILES } from '../../../forms/companyConfirmStructure';

export interface FormContentProps {
  files?: FileUpload[];
  onAddFiles?: (file: File[]) => void;
  onRemoveFile?: (file: FileUpload) => void;
}

export const FormContent = ({
  files,
  onAddFiles,
  onRemoveFile,
}: FormContentProps) => {
  const { t } = useTranslation();
  return (
    <Field.FilesUpload
      files={files}
      fileTypes={[FileType.pdf]}
      maxFiles={MAX_FILES}
      name="fileIds"
      title={t('onboarding.companyConfirmStructure.documentTitle')}
      onAddFiles={(f) => onAddFiles?.(f)}
      onRemoveFile={onRemoveFile}
    />
  );
};
