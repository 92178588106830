import { OnboardingProcessStepNameType } from 'api/types/entity';

import {
  RoleInCompanyStep,
  CitizenshipStep,
  TaxResidencyStep,
  RegistrationAddressStep,
  BiographyStep,
  PepConnectionStep,
  CompanyNameStep,
  CompanyRegistrationPlaceStep,
  CompanyRegistrationAddressStep,
  CompanyTaxResidencyStep,
  CompanyActivitiesStep,
  OrganizationalAndLegalFormStep,
  CompanyAccountsInOrganizationsStep,
  CompanyContactsStep,
  CompanyStateRegistrationStep,
  CompanyDebtsStep,
  KycStep,
  CompanyLicenseStep,
  CompanyConfirmStructureStep,
  CompanyStructureStep,
  CompanyConstituentDocsStep,
  CompanyHeadsStep,
  CompanyFinancingSourcesStep,
  CompanyFinancialMonitoringStep,
  CompanyBusinessRrelationshipStep,
  WithdrawalAccountsStep,
  LegalRepresentativeCompanyNameStep,
  LegalRepresentativeCompanyTaxResidencyStep,
  LegalRepresentativeCompanyStateRegistrationStep,
  LegalRepresentativeCompanyRegistrationAddressStep,
  OrderSubmissionContactsStep,
  LegalRepresentativeCompanyConstituentDocsStep,
  CompanyBranchesStep,
  ConfirmationDataStep,
  SignatureSamplesStep,
  InvoiceForPaymentStep,
  InvoiceConfirmForPaymentStep,
} from './steps';
import { CompanyBeneficiariesStep } from './steps/CompanyBeneficiariesStep';
import { RoutePage } from './types';

export const pagesMap: Partial<
  Record<OnboardingProcessStepNameType, RoutePage['component']>
> = {
  [OnboardingProcessStepNameType.LegalClientRepresentativeRoleInCompany]:
    RoleInCompanyStep,
  [OnboardingProcessStepNameType.LegalClientRepresentativeCitizenships]:
    CitizenshipStep,
  [OnboardingProcessStepNameType.LegalClientRepresentativeTaxResidencies]:
    TaxResidencyStep,
  [OnboardingProcessStepNameType.LegalClientRepresentativeAddresses]:
    RegistrationAddressStep,
  [OnboardingProcessStepNameType.LegalClientRepresentativeBiography]:
    BiographyStep,
  [OnboardingProcessStepNameType.LegalClientRepresentativePep]:
    PepConnectionStep,
  [OnboardingProcessStepNameType.LegalClientCompanyName]: CompanyNameStep,
  [OnboardingProcessStepNameType.LegalClientTaxResidencies]:
    CompanyTaxResidencyStep,
  [OnboardingProcessStepNameType.LegalClientRegistrationPlace]:
    CompanyRegistrationPlaceStep,
  [OnboardingProcessStepNameType.LegalClientAddresses]:
    CompanyRegistrationAddressStep,
  [OnboardingProcessStepNameType.LegalClientStateRegistration]:
    CompanyStateRegistrationStep,
  [OnboardingProcessStepNameType.LegalClientOrganizationalAndLegalForm]:
    OrganizationalAndLegalFormStep,
  [OnboardingProcessStepNameType.LegalClientActivityTypes]:
    CompanyActivitiesStep,
  [OnboardingProcessStepNameType.LegalClientLicenseInfo]: CompanyLicenseStep,
  [OnboardingProcessStepNameType.LegalClientConstitutionalDocument]:
    CompanyConstituentDocsStep,
  [OnboardingProcessStepNameType.LegalClientOwnershipStructure]:
    CompanyConfirmStructureStep,
  [OnboardingProcessStepNameType.LegalClientRepresentativeKyc]: KycStep,
  [OnboardingProcessStepNameType.LegalClientContacts]: CompanyContactsStep,
  [OnboardingProcessStepNameType.LegalClientBranches]: CompanyBranchesStep,
  [OnboardingProcessStepNameType.LegalClientBankAccounts]:
    CompanyAccountsInOrganizationsStep,
  [OnboardingProcessStepNameType.LegalClientFinancingSources]:
    CompanyFinancingSourcesStep,
  [OnboardingProcessStepNameType.LegalClientDebts]: CompanyDebtsStep,
  [OnboardingProcessStepNameType.LegalClientFinancialMonitoring]:
    CompanyFinancialMonitoringStep,
  [OnboardingProcessStepNameType.LegalClientBusinessRelationship]:
    CompanyBusinessRrelationshipStep,
  [OnboardingProcessStepNameType.LegalClientWithdrawalAccounts]:
    WithdrawalAccountsStep,
  [OnboardingProcessStepNameType.LegalClientOrderSubmissionContacts]:
    OrderSubmissionContactsStep,
  [OnboardingProcessStepNameType.LegalClientSignatureCards]:
    SignatureSamplesStep,
  [OnboardingProcessStepNameType.LegalClientConfirmDataAccuracy]:
    ConfirmationDataStep,
  [OnboardingProcessStepNameType.LegalClientInvoice]: InvoiceForPaymentStep,
  [OnboardingProcessStepNameType.LegalClientInvoicePaymentConfirmationFiles]:
    InvoiceConfirmForPaymentStep,
  [OnboardingProcessStepNameType.LegalClientRepresentativeCompanyName]:
    LegalRepresentativeCompanyNameStep,
  [OnboardingProcessStepNameType.LegalClientRepresentativeCompanyTaxResidencies]:
    LegalRepresentativeCompanyTaxResidencyStep,
  [OnboardingProcessStepNameType.LegalClientRepresentativeCompanyAddresses]:
    LegalRepresentativeCompanyRegistrationAddressStep,
  [OnboardingProcessStepNameType.LegalClientRepresentativeCompanyStateRegistration]:
    LegalRepresentativeCompanyStateRegistrationStep,
  [OnboardingProcessStepNameType.LegalClientRepresentativeCompanyConstitutionalDocument]:
    LegalRepresentativeCompanyConstituentDocsStep,
  [OnboardingProcessStepNameType.LegalClientBeneficiaries]:
    CompanyBeneficiariesStep,
  [OnboardingProcessStepNameType.LegalClientHeads]: CompanyHeadsStep,
  [OnboardingProcessStepNameType.LegalClientBodies]: CompanyStructureStep,
};
