import { useMemo } from 'react';

import { FileUpload } from 'hooks';
import { useTranslation } from 'libs/i18n';
import { FileType } from 'libs/ui';
import {
  getToday,
  getTodayWithResetHours,
} from 'modules/onboarding/common/helpers/getToday';

import {
  Field,
  maxLengthLicenseNumber,
  maxLengthActivityType,
  maxLengthAuthorityName,
  MAX_FILES,
} from '../../../../forms/companyLicenseForm';

interface Props {
  index: number;
  files?: FileUpload[];
  onAddFiles?: (file: File[], index: number) => void;
  onRemoveFile?: (file: FileUpload) => void;
}
export const Fields = ({ index, files, onAddFiles, onRemoveFile }: Props) => {
  const { t } = useTranslation();

  const filteredFiles = useMemo(
    () => files?.filter((v) => v.data === index),
    [files, index]
  );

  return (
    <>
      <Field.Text
        label={t('onboarding.companyLicense.licensedActivityType.label')}
        maxLength={maxLengthActivityType}
        name={`licenses.${index}.licensedActivityType`}
        placeholder={t(
          'onboarding.companyLicense.licensedActivityType.placeholder'
        )}
      />
      <Field.Text
        label={t('onboarding.companyLicense.licenseNumber.label')}
        maxLength={maxLengthLicenseNumber}
        name={`licenses.${index}.licenseNumber`}
        placeholder={t('onboarding.companyLicense.licenseNumber.placeholder')}
      />
      <Field.DatePicker
        label={t('onboarding.companyLicense.dateOfIssue.label')}
        maxDate={getToday()}
        name={`licenses.${index}.dateOfIssue`}
        placeholder={t('onboarding.companyLicense.dateOfIssue.placeholder')}
      />
      <Field.DatePicker
        showOptionalMark
        disableFuture={false}
        label={t('onboarding.companyLicense.dateOfExpiry.label')}
        minDate={getTodayWithResetHours()}
        name={`licenses.${index}.dateOfExpiry`}
        placeholder={t('onboarding.companyLicense.dateOfExpiry.placeholder')}
      />
      <Field.Text
        label={t('onboarding.companyLicense.authorityName.label')}
        maxLength={maxLengthAuthorityName}
        name={`licenses.${index}.authorityName`}
        placeholder={t('onboarding.companyLicense.authorityName.placeholder')}
      />

      <Field.FilesUpload
        files={filteredFiles}
        fileTypes={[FileType.pdf]}
        maxFiles={MAX_FILES}
        name={`licenses.${index}.fileIds`}
        title={t('onboarding.companyLicense.documentTitle')}
        onAddFiles={(f) => onAddFiles?.(f, index)}
        onRemoveFile={onRemoveFile}
      />
    </>
  );
};
