import { useRef } from 'react';

import { useLegalClientSaveFinancialMonitoring } from 'api/requests';
import { useServerErrorNotify } from 'hooks';
import { useTranslation } from 'libs/i18n';

import { CompanyFinancialMonitoringFormType } from '../forms/companyFinancialMonitoringForm';
import {
  getInitialData,
  getSubmitData,
} from '../helpers/companyFinancialMonitoring';

import { useOnboardingContext } from './useOnboardingContext';

export const useCompanyFinancialMonitoring = () => {
  const { goToBack, goToNext, data } = useOnboardingContext();
  const { error, loading, saveFinancialMonitoring } =
    useLegalClientSaveFinancialMonitoring();
  const { t } = useTranslation();

  const initialValues = useRef(
    getInitialData(t, data.legalClient!.financialMonitoring)
  ).current;
  const hasBranches = useRef(!!data.legalClient!.branches?.length).current;

  const onSubmit = async (values: CompanyFinancialMonitoringFormType) => {
    if (values) {
      const submitData = getSubmitData(values);
      await saveFinancialMonitoring(data.legalClient!.id, submitData);
      goToNext();
    }
  };

  useServerErrorNotify(error);

  return {
    onSubmit,
    goToBack,
    submitLoading: loading,
    initialValues,
    hasBranches,
  };
};
