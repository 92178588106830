import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useSetupPasswordMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';

export const useSetupPassword = () => {
  const [handle, { data, loading, error: apolloError }] =
    useSetupPasswordMutation();

  const error = useError(apolloError);

  return {
    data,
    loading,
    error,
    setupPassword: useCallback(
      async (password: string, agreementIds: string[]) => {
        const res = await handle({ variables: { password, agreementIds } });
        return res.data?.setupPassword;
      },
      [handle]
    ),
  };
};
