import {
  CivilLawSubjectType,
  RoleInCompanyType,
} from 'api/types/entity/legalClientRepresentativeEntity/roleInCompanyEntity';
import { FileUpload } from 'hooks';
import { useTranslation } from 'libs/i18n';
import { FileType } from 'libs/ui';
import {
  getToday,
  getTodayWithResetHours,
} from 'modules/onboarding/common/helpers/getToday';

import {
  useFormContext,
  Field,
  MAX_FILES,
} from '../../../forms/roleInCompanyForm';
import {
  CivilLawSubjectTypePath,
  RoleInCompanyTypePath,
} from '../../../helpers/roleInCompany';

export interface FormContentProps {
  files?: FileUpload[];
  onAddFiles?: (file: File[]) => void;
  onRemoveFile?: (file: FileUpload) => void;
}

export const FormContent = ({
  files,
  onAddFiles,
  onRemoveFile,
}: FormContentProps) => {
  const { t } = useTranslation();
  const { values } = useFormContext();
  return (
    <>
      <Field.Autocomplete
        label={t('onboarding.roleInCompany.role.label')}
        name="role"
        optionLabelKey="label"
        options={Object.values(RoleInCompanyType).map((v) => ({
          label: t(RoleInCompanyTypePath[v]),
          value: v,
        }))}
        optionValueKey="value"
        placeholder={t('onboarding.roleInCompany.role.placeholder')}
        variant="select"
      />

      {values.role?.value === RoleInCompanyType.AuthorizedRepresentative && (
        <Field.Autocomplete
          label={t('onboarding.roleInCompany.civilLawSubject.label')}
          name="civilLawSubject"
          optionLabelKey="label"
          options={Object.values(CivilLawSubjectType).map((v) => ({
            label: t(CivilLawSubjectTypePath[v]),
            value: v,
          }))}
          optionValueKey="value"
          placeholder={t(
            'onboarding.roleInCompany.civilLawSubject.placeholder'
          )}
          variant="select"
        />
      )}

      <Field.Text
        showOptionalMark
        label={t('onboarding.roleInCompany.number.label')}
        name="number"
        placeholder={t('onboarding.roleInCompany.number.placeholder')}
      />

      <Field.DatePicker
        label={t('onboarding.roleInCompany.dateOfIssue.label')}
        maxDate={getToday()}
        name="dateOfIssue"
        placeholder={t('onboarding.roleInCompany.dateOfIssue.placeholder')}
      />
      <Field.DatePicker
        disableFuture={false}
        label={t('onboarding.roleInCompany.dateOfExpiration.label')}
        minDate={getTodayWithResetHours()}
        name="dateOfExpiration"
        placeholder={t('onboarding.roleInCompany.dateOfExpiration.placeholder')}
      />

      <Field.FilesUpload
        files={files}
        fileTypes={[FileType.pdf]}
        maxFiles={MAX_FILES}
        name="fileIds"
        title={t('onboarding.roleInCompany.documentTitle')}
        onAddFiles={(f) => onAddFiles?.(f)}
        onRemoveFile={onRemoveFile}
      />
    </>
  );
};
