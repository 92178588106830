import { Stack } from 'libs/ui';
import { CSSObject, styled } from 'libs/ui/styled';

export const HintContainer = styled('span')({
  display: 'inline-flex',
  marginLeft: '10px',
});

export const HintContent = styled(Stack)({
  display: 'inline-flex',

  '.hint-title': {
    lineHeight: '130%',
  } as CSSObject,
});
