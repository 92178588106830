// eslint-disable-next-line no-restricted-imports
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { getColor } from 'helpers';

import { CSSObject, styled } from '../styled';
import { ColorsDark, ColorsLight } from '../theme';

import { InputStyleProps } from './types';

export const StyledInput = styled(TextField, {
  shouldForwardProp: (prop) => prop !== 'helperTextAbsolute',
})<TextFieldProps & InputStyleProps>(({ theme, helperTextAbsolute }) => ({
  '& label, & label.Mui-focused': {
    color: theme.palette.inputLabel,
    ...theme.typography.captionSemiBold,
    transform: 'none',
    whiteSpace: 'pre-wrap',
    textWrap: 'wrap',
    position: 'relative',
  } as CSSObject,
  '& input': {
    [theme.breakpoints.up('mobile')]: {
      ...theme.typography.bodyBold,
    },
    [theme.breakpoints.down('mobile')]: {
      ...theme.typography.captionBold,
    },

    '&:not(input:-webkit-autofill)::-webkit-contacts-auto-fill-button': {
      backgroundColor: getColor(theme, {
        light: ColorsLight.black,
        dark: ColorsDark.white,
      }),
    },

    '&::placeholder': {
      [theme.breakpoints.up('mobile')]: {
        ...theme.typography.body,
      },
      [theme.breakpoints.down('mobile')]: {
        ...theme.typography.caption,
      },

      color: theme.palette.inputPlaceholder,
      opacity: 1,
    },

    '&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus, &:-webkit-autofill:active':
      {
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': theme.palette.text.primary,
        transition: 'background-color 5000s ease-in-out 0s',
        boxShadow: 'none',
      },
  },

  '& .MuiInputBase-root': {
    height: 48,
    textWrap: 'nowrap',
    whiteSpace: 'nowrap',
    '&::before': {
      borderBottomColor: theme.palette.inputBorder,
    },
    '&.Mui-error:before': {
      borderBottomColor: theme.palette.error.main,
    },
    '&:hover:not(.Mui-disabled, .Mui-error):before': {
      borderBottomColor: theme.palette.inputBorderHover,
      borderBottomWidth: 1,
    },

    '&:after': {
      borderBottomColor: theme.palette.inputBorderFocused,
      borderBottomWidth: 1,
    },
  },
  'label+.MuiInputBase-root': {
    marginTop: 0,
  },
  ...(helperTextAbsolute && {
    '.MuiFormHelperText-root': {
      position: 'absolute',
      bottom: -22,
    },
  }),
}));
