import { useRef } from 'react';

import { useLegalClientHeadSavePepConnection } from 'api/requests';
import { useServerErrorNotify } from 'hooks';
import { useTranslation } from 'libs/i18n';
import { OnboardingPepConnectionFormType } from 'modules/onboarding/common/forms/onboardingPepConnectionForm';
import { getInitialData } from 'modules/onboarding/common/helpers/pepConnection/getInitialData';
import { getSubmitData } from 'modules/onboarding/common/helpers/pepConnection/getSubmitData';

import { useOnboardingContext } from './useOnboardingContext';

export const usePepConnection = () => {
  const { goToBack, goToNext, data } = useOnboardingContext();
  const { error, loading, savePepConnection } =
    useLegalClientHeadSavePepConnection();

  const { t } = useTranslation();

  const initialValues = useRef(
    getInitialData(data.legalClientHead.pepConnection, t)
  ).current;

  const onSubmit = async (values: OnboardingPepConnectionFormType) => {
    if (values) {
      const submitData = getSubmitData(values);
      await savePepConnection(
        data.legalClient.id,
        data.legalClientHead.id,
        submitData
      );
      goToNext();
    }
  };

  useServerErrorNotify(error);

  return {
    onSubmit,
    goToBack,
    initialValues,
    submitLoading: loading,
  };
};
