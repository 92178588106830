import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useLegalClientHeadSaveCitizenshipsMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { SaveCitizenshipParam } from 'api/types/params';

import { parseLegalClientHeadEntity } from './helpers';

export const useLegalClientHeadSaveCitizenships = () => {
  const [handle, { data, loading, error: apolloError }] =
    useLegalClientHeadSaveCitizenshipsMutation();

  const error = useError(apolloError);

  return {
    data: data?.legalClientHeadSaveCitizenships
      ? parseLegalClientHeadEntity(data.legalClientHeadSaveCitizenships)
      : undefined,
    loading,
    error,
    saveCitizenships: useCallback(
      async (id: string, headId: string, params: SaveCitizenshipParam) => {
        const res = await handle({
          variables: {
            id,
            headId,
            input: params,
          },
        });
        return res?.data?.legalClientHeadSaveCitizenships
          ? parseLegalClientHeadEntity(res.data.legalClientHeadSaveCitizenships)
          : undefined;
      },
      [handle]
    ),
  };
};
