import { ReactNode } from 'react';

import { Layout } from 'components';
import { Main } from 'modules/App/Main';
import { NotFound } from 'modules/App/NotFound';
import { Login } from 'modules/auth/login';
import { LoginByToken } from 'modules/auth/loginByToken';
import { Registration } from 'modules/auth/registration';
import { RestorePassword } from 'modules/auth/restorePassword';
import { OnboardingInfo } from 'modules/onboarding/onboardingInfo';
import { OnboardingRoutes } from 'modules/onboarding/routes';

import { RoutesId } from './routesId';

// Define a mapping of route IDs to React components or elements
export const PAGES: Record<RoutesId, ReactNode> = {
  [RoutesId.root]: <Main />,
  [RoutesId.notFound]: <NotFound />,
  [RoutesId.home]: <Layout />,
  [RoutesId.registration]: <Registration />,
  [RoutesId.login]: <Login />,
  [RoutesId.loginByToken]: <LoginByToken />,
  [RoutesId.onboarding]: <OnboardingRoutes />,
  [RoutesId.onboardingInfo]: <OnboardingInfo />,
  [RoutesId.restorePassword]: <RestorePassword />,
};
