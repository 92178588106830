import chunk from 'lodash.chunk';
import compact from 'lodash.compact';
import isPlainObject from 'lodash.isplainobject';
import sortby from 'lodash.sortby';

const sortBySearch = <T>(
  collection: T[],
  search: string,
  iteratees: (v: T) => string
) => {
  const sorted = sortby(collection, iteratees);

  search = search.toLowerCase();

  sorted.sort((a, b) => {
    const valueA = iteratees(a);
    const valueB = iteratees(b);
    if (
      valueA.toLowerCase().startsWith(search) &&
      valueB.toLowerCase().startsWith(search)
    )
      return valueA.localeCompare(valueB);

    if (valueA.toLowerCase().startsWith(search)) {
      return -1;
    }
    if (valueB.toLowerCase().startsWith(search)) {
      return 1;
    }

    return valueA.localeCompare(valueB);
  });

  return sorted;
};

const searchBy = <T>(
  collection: T[],
  search: string,
  iteratees: (v: T) => string
) => {
  if (!search) return collection;

  const itemsFiltered = sortBySearch(
    collection.filter((v) =>
      iteratees(v).toLowerCase().includes(search.toLowerCase())
    ),
    search,
    iteratees
  );
  return itemsFiltered;
};

const lodash = {
  compact,
  isPlainObject,
  chunk,
  sortby,
  searchBy,
  sortBySearch,
};

export { lodash };
