import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import {
  OnboardingLegalClientDocument,
  OnboardingLegalClientParticipantsDocument,
  useLegalClientSaveBeneficiaryEmailMutation,
} from 'api/generated/graphql';
import { useError } from 'api/helpers';

export const useLegalClientSaveBeneficiaryEmail = () => {
  const [handle, { data, loading, error: apolloError }] =
    useLegalClientSaveBeneficiaryEmailMutation({
      refetchQueries: [
        OnboardingLegalClientDocument,
        OnboardingLegalClientParticipantsDocument,
      ],
      awaitRefetchQueries: true,
    });

  const error = useError(apolloError);

  const saveBeneficiaryEmail = useCallback(
    async (legalId: string, beneficiaryId: string, email: string) => {
      const res = await handle({
        variables: { id: legalId, beneficiaryId, input: { email } },
      });
      return res.data?.legalClientSaveBeneficiaryEmail;
    },
    [handle]
  );

  return {
    data,
    loading,
    error,
    saveBeneficiaryEmail,
  };
};
