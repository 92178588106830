import { FinancingSources } from 'modules/onboarding/common/components';

import { useFinancingSources } from '../hooks';
import { PageComponentProps } from '../types';

export const FinancingSourcesStep = ({ canGoBack }: PageComponentProps) => {
  const {
    submitLoading,
    onSubmit,
    goToBack,
    files,
    onAddFiles,
    onRemoveFile,
    submitDisabled,
    initialValues,
  } = useFinancingSources();

  return (
    <FinancingSources
      canGoBack={canGoBack}
      files={files}
      initialValues={initialValues}
      submitDisabled={submitDisabled}
      submitLoading={submitLoading}
      onAddFiles={onAddFiles}
      onGoBack={goToBack}
      onRemoveFile={onRemoveFile}
      onSubmit={onSubmit}
    />
  );
};
