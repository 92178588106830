import { LegalClientBodyEntity } from 'api/types/entity/legal/legalClientBody';
import { getYesOrNoOptions } from 'helpers';
import { useTranslation } from 'libs/i18n';
import { FileType, FileUpload, Stack } from 'libs/ui';

import { Field, MAX_FILES } from '../../../forms/headTypeInfoForm';

export interface FormContentProps {
  files?: FileUpload[];
  bodies: LegalClientBodyEntity[];
  needMemberConfirmationFiles?: boolean;
  onAddFiles?: (file: File[]) => void;
  onRemoveFile?: (file: FileUpload) => void;
}

export const FormContent = ({
  files,
  bodies,
  needMemberConfirmationFiles,
  onAddFiles,
  onRemoveFile,
}: FormContentProps) => {
  const { t } = useTranslation();

  return (
    <Stack spacing={32}>
      <Field.Autocomplete
        hideClearIcon
        label={t('onboarding.headTypeInfo.body.label')}
        name="body"
        optionLabelKey="label"
        options={bodies.map((b) => ({
          value: b.id,
          label: b.name,
        }))}
        optionValueKey="value"
        placeholder={t('onboarding.headTypeInfo.body.placeholder')}
        variant="select"
      />

      <Field.Autocomplete
        hideClearIcon
        label={t('onboarding.headTypeInfo.firstHead.label')}
        name="firstHead"
        optionLabelKey="label"
        options={getYesOrNoOptions(t)}
        optionValueKey="value"
        placeholder={t('onboarding.headTypeInfo.firstHead.placeholder')}
        variant="select"
      />

      {needMemberConfirmationFiles && (
        <Field.FilesUpload
          files={files}
          fileTypes={[FileType.pdf]}
          maxFiles={MAX_FILES}
          name="memberConfirmationFileIds"
          title={t('onboarding.headTypeInfo.documentTitle')}
          onAddFiles={onAddFiles}
          onRemoveFile={onRemoveFile}
        />
      )}
    </Stack>
  );
};
