import { OnboardingPepConnection } from 'modules/onboarding/common/components';

import { usePepConnection } from '../hooks';
import { PageComponentProps } from '../types';

export const PepConnectionStep = ({ canGoBack }: PageComponentProps) => {
  const { goToBack, initialValues, onSubmit, submitLoading } =
    usePepConnection();
  return (
    <OnboardingPepConnection
      canGoBack={canGoBack}
      initialValues={initialValues}
      submitLoading={submitLoading}
      onGoBack={goToBack}
      onSubmit={onSubmit}
    />
  );
};
