import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import {
  OnboardingLegalClientDocument,
  useLegalClientLegalHeadFirstHeadSavePersonalDataMutation,
} from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { LegalClientCreateHeadSaveParam } from 'api/types/params';
import { formatToISODate } from 'helpers';

import { parseLegalClientLegalHeadFirstHeadEntity } from './helpers';

export const useLegalClientLegalHeadFirstHeadSavePersonalData = () => {
  const [handle, { data, loading, error: apolloError }] =
    useLegalClientLegalHeadFirstHeadSavePersonalDataMutation({
      refetchQueries: [OnboardingLegalClientDocument],
      awaitRefetchQueries: true,
    });

  const error = useError(apolloError);

  return {
    data: data?.legalClientLegalHeadFirstHeadSavePersonalData
      ? parseLegalClientLegalHeadFirstHeadEntity(
          data.legalClientLegalHeadFirstHeadSavePersonalData
        )
      : undefined,
    loading,
    error,
    savePersonalData: useCallback(
      async (
        id: string,
        legalHeadId: string,
        firstHeadId: string,
        param: LegalClientCreateHeadSaveParam
      ) => {
        const res = await handle({
          variables: {
            id,
            firstHeadId,
            legalHeadId,
            input: {
              dateOfBirth: formatToISODate(param.dateOfBirth),
              email: param.email,
              firstName: param.firstName,
              lastName: param.lastName,
              middleName: param.middleName,
            },
          },
        });
        return res?.data?.legalClientLegalHeadFirstHeadSavePersonalData
          ? parseLegalClientLegalHeadFirstHeadEntity(
              res.data.legalClientLegalHeadFirstHeadSavePersonalData
            )
          : undefined;
      },
      [handle]
    ),
  };
};
