import {
  BrokerRelationshipPurposeType,
  IncomeSourcesType,
  PlannedOperationType,
} from 'api/types/entity';
import { ClientSaveFinancingSourcesParam } from 'api/types/params';

import { FinancingSourcesFormType } from '../../forms/financingSourcesForm';

export const getSubmitData = (
  values: FinancingSourcesFormType
): ClientSaveFinancingSourcesParam => {
  const plannedOperationTypes = values.plannedOperationTypes.map(
    (v) => v.value
  );

  const incomeSources = values.incomeSources.map((v) => v.value);

  const brokerRelationshipPurpose = values.brokerRelationshipPurpose.map(
    (v) => v.value
  );

  const data: ClientSaveFinancingSourcesParam = {
    ...values,
    incomeSources,
    brokerRelationshipPurpose,
    plannedOperationTypes,
    otherPlannedOperationType: plannedOperationTypes.includes(
      PlannedOperationType.Other
    )
      ? values.otherPlannedOperationType
      : undefined,

    otherBrokerRelationshipPurpose: brokerRelationshipPurpose.includes(
      BrokerRelationshipPurposeType.Other
    )
      ? values.otherBrokerRelationshipPurpose
      : undefined,

    otherIncomeSource: incomeSources.includes(IncomeSourcesType.Other)
      ? values.otherIncomeSource
      : undefined,
  };
  return data;
};
