// eslint-disable-next-line no-restricted-imports
import Select from '@mui/material/Select';

import { styled } from '../styled';

export const StyledSelect = styled(Select)(({ theme }) => ({
  ...theme.typography.bodySemiBold,
  ' > div:focus': {
    background: 'none',
  },
  '::before': {
    content: 'none',
  },
  '::after': {
    content: 'none',
  },
}));
