import { useCallback, useMemo } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useOnboardingClientRepresentativeQuery } from 'api/generated/graphql';
import { useError } from 'api/helpers';

import { parseClientRepresentative } from './helpers/parseClientRepresentative';

export const useOnboardingClientRepresentative = (id: string) => {
  const {
    data,
    loading,
    refetch,
    error: apolloError,
  } = useOnboardingClientRepresentativeQuery({ variables: { id } });

  const error = useError(apolloError);

  const currentClient = useMemo(
    () =>
      data?.onboardingClientRepresentative
        ? parseClientRepresentative(data.onboardingClientRepresentative)
        : null,
    [data?.onboardingClientRepresentative]
  );

  return {
    currentClient,
    loading,
    error,
    refetch: useCallback(async () => {
      const res = await refetch();
      if (res?.data?.onboardingClientRepresentative) {
        return parseClientRepresentative(
          res.data.onboardingClientRepresentative
        );
      }
      return undefined;
    }, [refetch]),
  };
};
