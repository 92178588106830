import { BaseStorage } from './baseStorage';

enum LocalStorageKeys {
  themeMode = 'themeMode',
  i18nextLng = 'i18nextLng',
  deviceId = 'deviceId',
  refreshToken = 'refreshToken',
  onboardingInnerState = 'onboardingInnerState',
}

const systemsKeys = [
  LocalStorageKeys.deviceId,
  LocalStorageKeys.themeMode,
  LocalStorageKeys.i18nextLng,
];

export const LocalStorage = new BaseStorage(
  localStorage,
  LocalStorageKeys,
  systemsKeys
);
