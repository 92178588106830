import {
  TypedForm,
  FormErrors as FormErrorsBase,
  ObjectSchema,
  object,
  string,
  email,
} from 'libs/form';
import { TFunc } from 'libs/i18n';

export interface RegistrationEmailFormType {
  email: string;
  code: string;
}

export const getSchema = (t: TFunc): ObjectSchema<RegistrationEmailFormType> =>
  object({
    email: email(t).required(),
    code: string().min(6).required(),
  });

export const { Field, Form, useFormContext, Submit } =
  TypedForm<RegistrationEmailFormType>();

export type FormErrors = FormErrorsBase<RegistrationEmailFormType>;
