import { LegalClientLicenseInfoEntity } from 'api/types/entity';
import { yesOrNot } from 'helpers';
import { FileUpload } from 'hooks';
import { TFunc } from 'libs/i18n';

import { CompanyLicenseFormType } from '../../forms/companyLicenseForm';

interface Props {
  isLicensed?: boolean;
  licenses?: LegalClientLicenseInfoEntity[];
}

export const getInitialData = (data: Props, t: TFunc) => {
  const values: CompanyLicenseFormType = {
    companyActivityIsLicensed: yesOrNot(t, data.isLicensed),

    licenses: data.licenses?.map((licenseInfo) => ({
      authorityName: licenseInfo?.issuingAuthority,
      dateOfExpiry: licenseInfo?.expirationDate,
      dateOfIssue: licenseInfo?.issueDate,
      licensedActivityType: licenseInfo?.activityType,
      licenseNumber: licenseInfo?.licenseNumber,
      fileIds: licenseInfo?.files?.map((file) => file.id),
    })) ?? [
      {
        authorityName: '',
        licensedActivityType: '',
        licenseNumber: '',
        fileIds: [],
      },
    ],
  };
  const files: FileUpload[] | undefined = data!.licenses?.flatMap((v, index) =>
    v.files.map(
      (document) =>
        new FileUpload(
          {
            name: document.name,
            size: document.size,
          },
          undefined,
          false,
          document.id,
          index
        )
    )
  );

  return { values, files };
};
