// eslint-disable-next-line no-restricted-imports
import { useRouteError as useRouteErrorBase } from 'react-router-dom';
import { RouteError } from 'types/error';

export const useRouteError = () => {
  const error = useRouteErrorBase();

  if (error instanceof RouteError) {
    return { error, message: error.message };
  }

  return { error };
};
