import { useTranslation } from 'libs/i18n';
import { OnboardingPepConnection } from 'modules/onboarding/common/components';

import { usePepConnection } from '../hooks';
import { useOnboardingContext } from '../hooks/useOnboardingContext';
import { PageComponentProps } from '../types';

export const PepConnectionStep = ({ canGoBack }: PageComponentProps) => {
  const { goToBack, initialValues, onSubmit, submitLoading } =
    usePepConnection();

  const { step } = useOnboardingContext();

  const { t } = useTranslation();

  return (
    <OnboardingPepConnection
      canGoBack={canGoBack}
      initialValues={initialValues}
      positionPlaceholder={t(
        'onboarding.legalClientFirstHead.pepConnection.position.placeholder'
      )}
      relationLabel={t(
        'onboarding.legalClientFirstHead.pepConnection.relation.label'
      )}
      step={step}
      submitLoading={submitLoading}
      subTitle={t('onboarding.legalClientFirstHead.pepConnection.subtitle')}
      onGoBack={goToBack}
      onSubmit={onSubmit}
    />
  );
};
