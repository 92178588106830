import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useLegalClientSendCorporateAccountVerificationEmailToHeadMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';

export const useLegalClientSendCorporateAccountVerificationEmailToHead = () => {
  const [handle, { data, loading, error: apolloError }] =
    useLegalClientSendCorporateAccountVerificationEmailToHeadMutation();

  const error = useError(apolloError);

  return {
    data: {
      resendTime:
        data?.legalClientSendCorporateAccountVerificationEmailToHead
          ?.resendTime,
    },
    loading,
    error,
    send: useCallback(
      async (id: string, headId: string) => {
        const res = await handle({ variables: { id, headId } });
        return {
          resendTime:
            res.data?.legalClientSendCorporateAccountVerificationEmailToHead
              ?.resendTime,
        };
      },
      [handle]
    ),
  };
};
