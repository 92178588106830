import { FormContainer } from 'components';
import { useTranslation } from 'libs/i18n';
import { Stack } from 'libs/ui';

import {
  CompanyConfirmStructureFormType,
  FormErrors,
  getSchema,
} from '../../forms/companyConfirmStructure';

import { FormContent, FormContentProps } from './FormContent';
import { Header } from './Header';

interface Props extends FormContentProps {
  initialValues?: CompanyConfirmStructureFormType;
  onSubmit: (values: CompanyConfirmStructureFormType) => void;
  canGoBack?: boolean;
  onGoBack?: () => void;
  formError?: FormErrors;
  submitDisabled?: boolean;
  submitLoading?: boolean;
}

export const CompanyConfirmStructure = ({
  initialValues,
  canGoBack,
  onGoBack,
  submitLoading,
  submitDisabled,
  formError,
  onSubmit,
  ...formProps
}: Props) => {
  const { t } = useTranslation();
  const schema = getSchema();
  return (
    <FormContainer
      canGoBack={canGoBack}
      initialErrors={formError}
      initialValues={initialValues}
      submitDisabled={submitDisabled}
      submitLoading={submitLoading}
      title={t('onboarding.companyConfirmStructure.title')}
      validationSchema={schema}
      onGoBack={onGoBack}
      onSubmit={onSubmit}
    >
      <Stack spacing={32}>
        <Header />
        <FormContent {...formProps} />
      </Stack>
    </FormContainer>
  );
};
