// eslint-disable-next-line no-restricted-imports
import { useRegistrationPasswordValidationRuleQuery } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { PasswordValidationRule } from 'api/types/data';

export const useRegistrationPasswordValidationRule = () => {
  const {
    data,
    loading,
    error: apolloError,
  } = useRegistrationPasswordValidationRuleQuery();

  const error = useError(apolloError);

  return {
    passwordValidationRule: data?.passwordValidationRule as
      | PasswordValidationRule
      | undefined,
    loading,
    error,
  };
};
