import { CountryEntity } from 'api/types/entity';
import {
  TypedForm,
  FormErrors as FormErrorsBase,
  ObjectSchema,
  string,
  object,
  array,
} from 'libs/form';
import { Maybe } from 'types/maybe';

export interface OnboardingAccountsInOrganizationsFormValue {
  bic: string;
  country: Maybe<CountryEntity>;
}

export interface OnboardingAccountsInOrganizationsFormType {
  accounts: OnboardingAccountsInOrganizationsFormValue[];
}

export const maxLengthBic = 200;

export const account = object({
  bic: string().required().max(maxLengthBic),
  country: object({
    id: string().required(),
    name: string().required(),
  }).required(),
});

export const getSchema =
  (): ObjectSchema<OnboardingAccountsInOrganizationsFormType> =>
    object({
      accounts: array().of(account.required()).required(),
    });

export const { Field, Form, useFormContext, Submit } =
  TypedForm<OnboardingAccountsInOrganizationsFormType>();

export type FormErrors =
  FormErrorsBase<OnboardingAccountsInOrganizationsFormType>;
