import { useCallback, useEffect } from 'react';

import {
  useRegistrationPasswordValidationRule,
  useRestorePassword as useRestorePasswordApi,
} from 'api/requests';
import { ErrorCode } from 'api/types/error';
import { useObjectState } from 'hooks';
import { useNotify } from 'libs/notify';
import { useDialog } from 'libs/ui/Dialog/useDialog';

import {
  RestorePasswordFormType,
  FormErrors,
} from '../forms/restorePasswordForm';
import { useRestorePasswordContext } from '../providers';

export const useRestorePassword = () => {
  const {
    isOpen: isOpenVerificationDialog,
    onClose: onCloseVerificationDialog,
    open,
  } = useDialog();

  const { passwordValidationRule } = useRegistrationPasswordValidationRule();

  const {
    restorePassword: restorePasswordApi,
    loading: submitLoading,
    error,
  } = useRestorePasswordApi();

  const { data } = useRestorePasswordContext();

  const notify = useNotify();

  const restorePassword = useCallback(
    async (values: RestorePasswordFormType) => {
      if (!data.token || !data.email) {
        return false;
      }
      if (data.otpRequired && !values.otpCode) {
        open();
        return false;
      }
      return restorePasswordApi({
        token: data.token,
        newPassword: values.password,
        otpToken: values.otpCode,
        email: data.email,
      });
    },
    [data.email, data.otpRequired, data.token, open, restorePasswordApi]
  );

  const [formError, setFormError] = useObjectState<FormErrors>({});

  const handleOnCloseVerificationDialog = () => {
    onCloseVerificationDialog();
    setFormError({ otpCode: undefined });
  };

  useEffect(() => {
    if (error) {
      if (error.code === ErrorCode.INVALID_OTP_TOKEN) {
        setFormError({ otpCode: error.message });
      } else {
        setFormError({ otpCode: undefined });
        notify.error(error);
      }
    } else {
      setFormError({ otpCode: undefined });
    }
  }, [error, notify, setFormError]);

  return {
    isOpenVerificationDialog,
    passwordValidationRule,
    submitLoading,
    formError,
    onCloseVerificationDialog: handleOnCloseVerificationDialog,
    restorePassword,
  };
};
