import { useState, useCallback, useMemo } from 'react';

/**
 * Hook for managing state of an object.
 */
export const useObjectState = <S>(
  initialState: S
): [S, <K extends keyof S>(value: Pick<S, K> | S) => void] => {
  const [state, updateState] = useState<S>(initialState);

  const setState = useCallback(<K extends keyof S>(value: Pick<S, K> | S) => {
    updateState((state) => ({
      ...state,
      ...value,
    }));
  }, []);

  const memoState = useMemo(() => state, [state]);

  return [memoState, setState];
};
