// eslint-disable-next-line no-restricted-imports
import Button, { ButtonProps as ButtonPropsBase } from '@mui/material/Button';

import { styled } from '../styled';

import {
  getStandardMode,
  getOutlinedMode,
  getSmallMode,
  getTextMode,
  getMediumMode,
  getOutlinedSmallMode,
} from './mode';
import { ButtonProps } from './types';

export const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'colorVariant' && prop !== 'loading',
})<Omit<ButtonPropsBase, 'color'> & ButtonProps>(
  ({ theme, mode, loading, colorVariant = 'primary' }) => ({
    boxShadow: 'none',
    textTransform: 'inherit',
    '&:focus': {
      boxShadow: 'none',
    },
    '& .button-label': {
      lineHeight: '100%',
    },

    ...(mode === 'standard' && getStandardMode(theme, colorVariant)),
    ...(mode === 'outlined' && getOutlinedMode(theme, colorVariant)),
    ...(mode === 'medium' && getMediumMode(theme, colorVariant)),
    ...(mode === 'small' && getSmallMode(theme)),
    ...(mode === 'text' && getTextMode(theme, colorVariant)),
    ...(mode === 'outlined-small' && getOutlinedSmallMode(theme, colorVariant)),

    ...(loading && {
      '& .button-label': {
        opacity: 0,
      },
    }),

    '& .button-loading': {
      position: 'absolute',
      display: 'flex',
    },
  })
);
