// eslint-disable-next-line no-restricted-imports
import {
  MutationInput_LegalClientRepresentativeController_SaveRoleInCompany_Input_RoleInCompany as RoleInCompanyTypeApi,
  MutationInput_LegalClientRepresentativeController_SaveRoleInCompany_Input_CivilLawSubject as CivilLawSubjectTypeApi,
  SaveRoleInCompanyDto_Input,
} from 'api/generated/graphql';
import { CivilLawSubjectType, RoleInCompanyType } from 'api/types/entity';
import { SaveRoleInCompanyParam } from 'api/types/params/onboarding/saveRoleInCompanyParam';
import { formatToISODate } from 'helpers';

const roleMap: Record<RoleInCompanyType, RoleInCompanyTypeApi> = {
  [RoleInCompanyType.AuthorizedRepresentative]:
    RoleInCompanyTypeApi.AuthorizedRepresentative,
  [RoleInCompanyType.Director]: RoleInCompanyTypeApi.Director,
  [RoleInCompanyType.OneOfDirectors]: RoleInCompanyTypeApi.OneOfDirectors,
};

const civilLawSubjectMap: Record<CivilLawSubjectType, CivilLawSubjectTypeApi> =
  {
    [CivilLawSubjectType.JuridicalPerson]:
      CivilLawSubjectTypeApi.JuridicalPerson,
    [CivilLawSubjectType.PhysicalPerson]: CivilLawSubjectTypeApi.PhysicalPerson,
  };

export const parseRoleInCompanyParam = (
  params: SaveRoleInCompanyParam
): SaveRoleInCompanyDto_Input => ({
  authorityConfirmationDocument: {
    dateOfExpiration: formatToISODate(
      params.authorityConfirmationDocument.dateOfExpiration
    ),
    dateOfIssue: formatToISODate(
      params.authorityConfirmationDocument.dateOfIssue
    ),
    fileIds: params.authorityConfirmationDocument.fileIds,
    number: params.authorityConfirmationDocument.number ?? undefined,
  },
  roleInCompany: roleMap[params.roleInCompany],
  civilLawSubject: params.civilLawSubject
    ? civilLawSubjectMap[params.civilLawSubject]
    : undefined,
});
