import { useMemo, useRef } from 'react';

import { base64 } from 'helpers';
import { useNavigate } from 'libs/navigation';

const URL_PARAM_NAME = 'query';

interface UrlData {
  email: string;
  code: string;
}

export const useRestorePasswordUrlData = () => {
  const { searchParams, setSearchParams } = useNavigate();

  const query = useRef(searchParams.get(URL_PARAM_NAME));

  const clearSearchParams = () => {
    searchParams.delete(URL_PARAM_NAME);
    setSearchParams(searchParams);
  };

  const urlData = useMemo<UrlData | undefined>(() => {
    if (query.current) {
      try {
        const data = JSON.parse(base64.decode(query.current)) as UrlData;
        if (!data.email || !data.code) {
          return undefined;
        }
        return {
          email: data.email,
          code: data.code.toString(),
        };
      } catch (error) {
        return undefined;
      }
    }

    return undefined;
  }, []);

  return { urlData, clearSearchParams };
};
