import { useMemo } from 'react';

// eslint-disable-next-line no-restricted-imports
import {
  QueryInput_AgreementController_GetMany_Classification,
  useAgreementsQuery,
} from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { AgreementsClassificationType } from 'api/types/entity/agreementsEntity';
import { lodash } from 'helpers';

import { parseEntity } from './parseEntity';

const classifications: Record<
  AgreementsClassificationType,
  QueryInput_AgreementController_GetMany_Classification
> = {
  [AgreementsClassificationType.Offer]:
    QueryInput_AgreementController_GetMany_Classification.Offer,
  [AgreementsClassificationType.Legal]:
    QueryInput_AgreementController_GetMany_Classification.LegalOffer,
};

export const useAgreements = (classification: AgreementsClassificationType) => {
  const {
    data,
    loading,
    error: apolloError,
  } = useAgreementsQuery({
    variables: { classification: classifications[classification] },
  });

  const error = useError(apolloError);

  const agreements = useMemo(() => {
    if (data?.agreements) {
      return parseEntity(lodash.compact(data.agreements));
    }
    return [];
  }, [data?.agreements]);

  return {
    agreements,
    loading,
    error,
  };
};
