import { useTranslation } from 'libs/i18n';
import {
  BaseDebtsFormContent,
  ClientType,
  FormBodyWrapper,
} from 'modules/onboarding/common/components';

import { DebtsFormType, FormErrors, getSchema } from '../../forms/debtsForm';

interface Props {
  initialValues?: DebtsFormType;
  onSubmit: (values: DebtsFormType) => void;
  canGoBack?: boolean;
  onGoBack?: () => void;
  formError?: FormErrors;
  submitLoading?: boolean;
  submitDisabled?: boolean;
  isEntrepreneur?: boolean;
  clientType?: ClientType;
}

export const OnboardingDebts = ({
  initialValues,
  canGoBack,
  submitLoading,
  submitDisabled,
  formError,
  isEntrepreneur,
  onGoBack,
  onSubmit,
  clientType,
}: Props) => {
  const { t } = useTranslation();
  const schema = getSchema(isEntrepreneur);
  return (
    <FormBodyWrapper
      canGoBack={canGoBack}
      clientType={clientType}
      initialErrors={formError}
      initialValues={initialValues}
      submitDisabled={submitDisabled}
      submitLoading={submitLoading}
      subTitle={t('onboarding.debts.subtitle')}
      title={t('onboarding.debts.title')}
      validationSchema={schema}
      onGoBack={onGoBack}
      onSubmit={onSubmit}
    >
      <BaseDebtsFormContent isEntrepreneur={isEntrepreneur} />
    </FormBodyWrapper>
  );
};
