// eslint-disable-next-line no-restricted-imports
import { LegalTaxResidenceFragment } from 'api/generated/graphql';
import { LegalTaxResidencesEntity } from 'api/types/entity/legal/LegalTaxResidencesEntity';

import { parseCountryEntity } from '../../helpers';

export const parseLegalClientTaxResidency = (
  taxResidence: LegalTaxResidenceFragment
): LegalTaxResidencesEntity => ({
  country: parseCountryEntity(taxResidence.country),
  taxId: taxResidence.taxId,
});
