import { WithdrawalAccountEntity } from 'api/types/entity/clientEntity/withdrawalAccountEntity';
import { FileUpload } from 'hooks';

import { WithdrawalAccountsFormType } from '../../forms/withdrawalAccountsForm';

export const getInitialData = (
  withdrawalAccounts: WithdrawalAccountEntity[] | undefined
) => {
  const values: WithdrawalAccountsFormType = {
    withdrawalAccounts: withdrawalAccounts?.map((v) => ({
      bankAddress: v.bankAddress,
      bankCountry: v.bankCountry,
      bankName: v.bankName,
      checkingAccount: v.checkingAccount,
      correspondentAccount: v.correspondentAccount,
      swiftCode: v.swiftCode,
      ownerConfirmationFileIds: v.ownerConfirmationDocuments.map((f) => f.id),
      currency: { value: v.currency, label: v.currency },
    })) || [
      {
        bankCountry: null,
        swiftCode: '',
        bankAddress: '',
        bankName: '',
        checkingAccount: '',
        correspondentAccount: '',
        ownerConfirmationFileIds: [],
        currency: null,
      },
    ],
  };

  const files: FileUpload[] | undefined = withdrawalAccounts?.flatMap(
    (v, index) =>
      v.ownerConfirmationDocuments.map(
        (document) =>
          new FileUpload(
            {
              name: document.name,
              size: document.size,
            },
            undefined,
            false,
            document.id,
            index
          )
      )
  );

  return { values, files };
};
