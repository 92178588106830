import { OnboardingSignatureSamplesFormType } from 'modules/onboarding/common/forms/onboardingSignatureSamplesForm';
import { useClientSignatureSamples } from 'modules/onboarding/common/hooks';

import { useOnboardingContext } from './useOnboardingContext';

export const useSignatureSamples = () => {
  const { goToBack, goToNext, data } = useOnboardingContext();
  const {
    initialValues,
    onSubmit,
    submitLoading,
    error,
    files,
    onAddFiles,
    onRemoveFile,
    submitDisabled,
  } = useClientSignatureSamples(data);

  const handleSubmit = async (values: OnboardingSignatureSamplesFormType) => {
    if (values.fileIds) {
      await onSubmit(values);
      goToNext();
    }
  };

  return {
    onSubmit: handleSubmit,
    goToBack,
    submitLoading,
    initialValues,
    files,
    error,
    onAddFiles,
    onRemoveFile,
    submitDisabled,
  };
};
