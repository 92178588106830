import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useLegalClientSaveSignaturesCardFilesMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { ClientSaveSignaturesCardFilesParam } from 'api/types/params';

import { parseLegalClient } from './helpers';

export const useLegalClientSaveSignaturesCardFiles = () => {
  const [saveSignatures, { data, loading, error: apolloError }] =
    useLegalClientSaveSignaturesCardFilesMutation();

  const error = useError(apolloError);

  return {
    data: data?.legalClientSaveSignaturesCardFiles
      ? parseLegalClient(data.legalClientSaveSignaturesCardFiles)
      : undefined,
    loading,
    error,
    saveSignatures: useCallback(
      async (id: string, params: ClientSaveSignaturesCardFilesParam) => {
        const res = await saveSignatures({
          variables: { id, input: params },
        });
        return res.data?.legalClientSaveSignaturesCardFiles
          ? parseLegalClient(res.data.legalClientSaveSignaturesCardFiles)
          : undefined;
      },
      [saveSignatures]
    ),
  };
};
