import { useTranslation } from 'libs/i18n';
import { maxLengthName } from 'modules/onboarding/common/forms/companyNameForm';

import { Field } from '../../../forms/nameForm';

export interface FormContentProps {}

export const FormContent = () => {
  const { t } = useTranslation();

  return (
    <Field.Text
      label={t('onboarding.legalClientBranch.name.fullName.label')}
      maxLength={maxLengthName}
      name="fullName"
      placeholder={t('onboarding.legalClientBranch.name.fullName.placeholder')}
    />
  );
};
