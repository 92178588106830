// eslint-disable-next-line no-restricted-imports
import {
  FinancingSourcesFragment,
  Query_ClientController_GetById_FinancingSources_BrokerRelationshipPurposes_Items as BrokerRelationshipPurposeApi,
  Query_ClientController_GetById_FinancingSources_IncomeSources_Items as IncomeSourcesApi,
  Query_ClientController_GetById_FinancingSources_PlannedOperationTypes_Items as PlannedOperationTypesApi,
} from 'api/generated/graphql';
import {
  BrokerRelationshipPurposeType,
  FinancingSourcesEntity,
  IncomeSourcesType,
  PlannedOperationType,
} from 'api/types/entity';
import { lodash } from 'helpers';

import { parseAmount } from './parseAmount';
import { parseFileEntity } from './parseFileEntity';

const broker: Record<
  BrokerRelationshipPurposeApi,
  BrokerRelationshipPurposeType
> = {
  [BrokerRelationshipPurposeApi.Other]: BrokerRelationshipPurposeType.Other,
  [BrokerRelationshipPurposeApi.SecuritiesBrokerage]:
    BrokerRelationshipPurposeType.SecuritiesBrokerage,
  [BrokerRelationshipPurposeApi.TrustAssetManagement]:
    BrokerRelationshipPurposeType.TrustAssetManagement,
};

const plannedOperationTypes: Record<
  PlannedOperationTypesApi,
  PlannedOperationType
> = {
  [PlannedOperationTypesApi.ForeignCurrencyTransactions]:
    PlannedOperationType.ForeignCurrencyTransactions,
  [PlannedOperationTypesApi.ForeignIssuersBondsTransactions]:
    PlannedOperationType.ForeignIssuersBondsTransactions,
  [PlannedOperationTypesApi.ForeignIssuersStockTransactions]:
    PlannedOperationType.ForeignIssuersStockTransactions,
  [PlannedOperationTypesApi.KazakhIssuersBondsTransactions]:
    PlannedOperationType.KazakhIssuersBondsTransactions,
  [PlannedOperationTypesApi.KazakhIssuersStockTransactions]:
    PlannedOperationType.KazakhIssuersStockTransactions,
  [PlannedOperationTypesApi.OptionsFuturesEtcDerivatives]:
    PlannedOperationType.OptionsFuturesETCDerivatives,
  [PlannedOperationTypesApi.Other]: PlannedOperationType.Other,
  [PlannedOperationTypesApi.RepoOperations]:
    PlannedOperationType.RepoOperations,
  [PlannedOperationTypesApi.SecuritiesTransferFromOtherBroker]:
    PlannedOperationType.SecuritiesTransferFromOtherBroker,
};
const incomeSources: Record<IncomeSourcesApi, IncomeSourcesType> = {
  [IncomeSourcesApi.BusinessIncome]: IncomeSourcesType.BusinessIncome,
  [IncomeSourcesApi.ExpenseSavings]: IncomeSourcesType.ExpenseSavings,
  [IncomeSourcesApi.FutureIncome]: IncomeSourcesType.FutureIncome,
  [IncomeSourcesApi.GiftContract]: IncomeSourcesType.GiftContract,
  [IncomeSourcesApi.Inheritance]: IncomeSourcesType.Inheritance,
  [IncomeSourcesApi.InvestmentIncome]: IncomeSourcesType.InvestmentIncome,
  [IncomeSourcesApi.Loan]: IncomeSourcesType.Loan,
  [IncomeSourcesApi.Other]: IncomeSourcesType.Other,
  [IncomeSourcesApi.PropertySale]: IncomeSourcesType.PropertySale,
  [IncomeSourcesApi.Salary]: IncomeSourcesType.Salary,
  [IncomeSourcesApi.Savings]: IncomeSourcesType.Savings,
};

export const parseFinancingSourcesEntity = (
  data: FinancingSourcesFragment
): FinancingSourcesEntity => {
  const res: FinancingSourcesEntity = {
    incomeAmount: parseAmount(data.incomeAmount),
    plannedAssetAmount: parseAmount(data.plannedAssetAmount),
    otherIncomeSource: data.otherIncomeSource || undefined,
    otherPlannedOperationType: data.otherPlannedOperationType || undefined,
    otherBrokerRelationshipPurpose:
      data.otherBrokerRelationshipPurpose || undefined,
    plannedOperationTypes: lodash.compact(
      data.plannedOperationTypes.map((v) => v && plannedOperationTypes[v])
    ),

    brokerRelationshipPurpose: lodash.compact(
      data.brokerRelationshipPurposes.map((v) => v && broker[v])
    ),

    incomeSources: lodash.compact(
      data.incomeSources.map((v) => v && incomeSources[v])
    ),

    incomeSourceFile: lodash
      .compact(data.incomeSourceDocuments)
      .map((v) => parseFileEntity(v)),
  };

  return res;
};
