import { FileEntity } from '../fileEntity';
import { PriceEntity } from '../priceEntity';

export enum IncomeSourcesType {
  BusinessIncome = 'BusinessIncome', // Доход от предпринимательской деятельности
  InvestmentIncome = 'InvestmentIncome', // Доход от инвестиций (дивиденды, депозиты и тд)
  GiftContract = 'GiftContract', // Договор дарения
  Inheritance = 'Inheritance', // Наследство
  Salary = 'Salary', // Зарплата
  Savings = 'Savings', // Накопления
  PropertySale = 'PropertySale', // Реализация имущества
  Loan = 'Loan', // Кредит
  ExpenseSavings = 'ExpenseSavings', // Резервы от экономии расходов и платежей
  FutureIncome = 'FutureIncome', // Планируемые доходы в будущем
  Other = 'Other',
}

export enum BrokerRelationshipPurposeType {
  SecuritiesBrokerage = 'SecuritiesBrokerage', // Брокерское обслуживание на рынке ценных бумаг
  TrustAssetManagement = 'TrustAssetManagement', // Доверительное управление активами
  Other = 'Other',
}

export enum PlannedOperationType {
  ForeignCurrencyTransactions = 'ForeignCurrencyTransactions', // Заключение сделок с иностранной валютой
  KazakhIssuersStockTransactions = 'KazakhIssuersStockTransactions', // Заключение сделок с акциями казахстанских эмитетов
  ForeignIssuersStockTransactions = 'ForeignIssuersStockTransactions', // Заключение сделок с акциями иностранных эмитетов
  KazakhIssuersBondsTransactions = 'KazakhIssuersBondsTransactions', // Заключение сделок с облигациями казахстанских эмитетов
  ForeignIssuersBondsTransactions = 'ForeignIssuersBondsTransactions', // Заключение сделок с облигациями иностранных эмитетов
  RepoOperations = 'RepoOperations', // Операции РЕПО
  OptionsFuturesETCDerivatives = 'OptionsFuturesETCDerivatives', // Перечисление опционов, фьючерсов, ПФИ
  SecuritiesTransferFromOtherBroker = 'SecuritiesTransferFromOtherBroker', // Зачисление/списание ценных бумаг от другого брокера, депозитарии, кастодион, учетная организация
  Other = 'Other',
}

export interface FinancingSourcesEntity {
  incomeSources: IncomeSourcesType[];
  otherIncomeSource?: string;

  incomeAmount: PriceEntity;
  brokerRelationshipPurpose: BrokerRelationshipPurposeType[];
  otherBrokerRelationshipPurpose?: string;

  plannedAssetAmount: PriceEntity;
  plannedOperationTypes: PlannedOperationType[];
  otherPlannedOperationType?: string;

  incomeSourceFile: FileEntity[];
}
