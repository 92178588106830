import { useRef } from 'react';

import { useLegalClientBranchSaveStateRegistration } from 'api/requests/onboarding/legal/branch';
import { useServerErrorNotify } from 'hooks';
import { useOnboardingCountries } from 'modules/onboarding/common/hooks';
import {
  getBaseInitialData,
  getBaseSubmitData,
} from 'modules/onboarding/legal/helpers/stateRegistration';

import { StateRegistrationFormType } from '../forms/stateRegistrationForm';

import { useOnboardingContext } from './useOnboardingContext';

export const useStateRegistration = () => {
  const { goToBack, goToNext, data } = useOnboardingContext();

  const { error, loading, saveAddresses } =
    useLegalClientBranchSaveStateRegistration();

  const initialValues = useRef(
    getBaseInitialData(data.legalClientBranch.stateRegistration)
  ).current;

  const { countries, loading: countriesLoading } = useOnboardingCountries();

  const onSubmit = async (values: StateRegistrationFormType) => {
    if (values) {
      const submitData = getBaseSubmitData(values);
      await saveAddresses(
        data.legalClient.id,
        data.legalClientBranch.id,
        submitData
      );
      goToNext();
    }
  };

  useServerErrorNotify(error);

  return {
    onSubmit,
    goToBack,
    submitLoading: loading,
    initialValues,
    countries,
    countriesLoading,
  };
};
