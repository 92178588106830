import { CurrencyInvoiceType } from 'api/types/entity';
import {
  ObjectSchema,
  TypedForm,
  FormErrors as FormErrorsBase,
  object,
  mixed,
  string,
} from 'libs/form';
import { SelectType } from 'types/selectType';

export interface InvoiceForPaymentFormType {
  currency: SelectType<CurrencyInvoiceType> | null;
}

export const getSchema = (): ObjectSchema<InvoiceForPaymentFormType> =>
  object({
    currency: object({
      value: mixed<CurrencyInvoiceType>()
        .oneOf(Object.values(CurrencyInvoiceType))
        .required(),
      label: string().required(),
    }).required(),
  });

export const { Field, Form, useFormContext, Submit } =
  TypedForm<InvoiceForPaymentFormType>();

export type FormErrors = FormErrorsBase<InvoiceForPaymentFormType>;
