import { useRef } from 'react';

import { useClientSaveBiography } from 'api/requests';
import { ClientEntity } from 'api/types/entity';
import { useServerErrorNotify } from 'hooks';
import { useTranslation } from 'libs/i18n';
import {
  getInitialData,
  getSubmitData,
} from 'modules/onboarding/common/helpers/biography';

import { OnboardingBiographyFormType } from '../forms/onboardingBiographyForm';

export const useClientBiography = (client: ClientEntity) => {
  const {
    loading: submitLoading,
    error,
    saveBiography: saveBiographyApi,
  } = useClientSaveBiography();

  const { t } = useTranslation();

  const initialValues = useRef(getInitialData(client.biography, t)).current;

  const saveBiography = async (values: OnboardingBiographyFormType) => {
    if (values) {
      const data = getSubmitData(values);
      await saveBiographyApi(client.id, data);
    }
  };

  useServerErrorNotify(error);

  return {
    saveBiography,
    initialValues,
    submitLoading,
  };
};
