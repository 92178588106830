import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useLegalClientSaveDebtsMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { SaveDebtsParam } from 'api/types/params';

import { parseClientDebtsParams } from '../helpers';

import { parseLegalClient } from './helpers';

export const useLegalClientSaveDebts = () => {
  const [saveDebts, { data, loading, error: apolloError }] =
    useLegalClientSaveDebtsMutation();

  const error = useError(apolloError);

  return {
    data: data?.legalClientSaveDebts
      ? parseLegalClient(data.legalClientSaveDebts)
      : undefined,
    loading,
    error,
    saveDebts: useCallback(
      async (id: string, params: SaveDebtsParam) => {
        const res = await saveDebts({
          variables: {
            id,
            input: parseClientDebtsParams(params),
          },
        });
        return res.data?.legalClientSaveDebts
          ? parseLegalClient(res.data.legalClientSaveDebts)
          : undefined;
      },
      [saveDebts]
    ),
  };
};
