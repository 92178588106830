import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useLegalClientHeadSavePepMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { SavePepConnectionParam } from 'api/types/params';

import { parsePepConnectionParam } from '../../helpers';

import { parseLegalClientHeadEntity } from './helpers';

export const useLegalClientHeadSavePepConnection = () => {
  const [savePepConnection, { data, loading, error: apolloError }] =
    useLegalClientHeadSavePepMutation();

  const error = useError(apolloError);

  return {
    data: data?.legalClientHeadSavePep
      ? parseLegalClientHeadEntity(data.legalClientHeadSavePep)
      : undefined,
    loading,
    error,
    savePepConnection: useCallback(
      async (id: string, headId: string, params: SavePepConnectionParam) => {
        const res = await savePepConnection({
          variables: { id, headId, input: parsePepConnectionParam(params) },
        });
        return res.data?.legalClientHeadSavePep
          ? parseLegalClientHeadEntity(res.data.legalClientHeadSavePep)
          : undefined;
      },
      [savePepConnection]
    ),
  };
};
