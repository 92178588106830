import { useTranslation } from 'libs/i18n';

import { Stack } from '../Stack';

import { ProgressBarStyled, ProgressStatusStyled } from './ProgressBar.styles';
import { ProgressBarProps } from './types';

/**
 * ProgressBar component.
 */
export const ProgressBar = ({
  className,
  countSteps = 1,
  currentStepIndex = 0,
  showingSteps = true,
  ...rest
}: ProgressBarProps) => {
  const { t } = useTranslation();
  const progress = Math.round((currentStepIndex / countSteps) * 100);

  return (
    <Stack className={className} direction="column" spacing={16}>
      <ProgressBarStyled
        className="progressBar-root"
        value={progress}
        variant="determinate"
        {...rest}
      />

      {showingSteps && (
        <ProgressStatusStyled
          variant="bodySemiBold"
          variantMobile="captionSemiBold"
        >
          {t('ui.progressBar.stepTitle', {
            step: currentStepIndex + 1,
            steps: countSteps,
          })}
        </ProgressStatusStyled>
      )}
    </Stack>
  );
};
