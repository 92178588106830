import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useSendRestorePasswordCodeMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';

export const useSendRestorePasswordCode = () => {
  const [handle, { data, loading, error: apolloError }] =
    useSendRestorePasswordCodeMutation();

  const error = useError(apolloError);

  return {
    data: { resendTime: data?.sendRestorePasswordCode?.resendTime },
    loading,
    error,
    sendCode: useCallback(
      async (email: string) => {
        const res = await handle({ variables: { email } });
        return { resendTime: res.data?.sendRestorePasswordCode?.resendTime };
      },
      [handle]
    ),
  };
};
