import { useRef } from 'react';

import { useClientSaveAddresses } from 'api/requests';
import { ClientEntity } from 'api/types/entity';
import { useFilesUpload, useServerErrorNotify } from 'hooks';
import { useOnboardingCountries } from 'modules/onboarding/common/hooks';

import { OnboardingRegistrationAddressFormType } from '../forms/onboardingRegistrationAddressForm';
import { getInitialData, getSubmitData } from '../helpers/registrationAddress';

export const useClientRegistrationAddress = (client: ClientEntity) => {
  const {
    saveAddresses,
    loading: submitLoading,
    error,
  } = useClientSaveAddresses();

  const { countries, loading: countriesLoading } = useOnboardingCountries();

  const initialData = useRef(getInitialData(client)).current;
  const initialValues = useRef(initialData?.values).current;

  const {
    files,
    isLoadingFile: submitDisabled,
    onRemoveFile,
    onAddFiles,
  } = useFilesUpload(initialData?.files);

  const onSubmit = async (values: OnboardingRegistrationAddressFormType) => {
    if (values) {
      const data = getSubmitData(values, true);
      await saveAddresses(client.id, data);
    }
  };

  useServerErrorNotify(error);

  return {
    initialValues,
    countries,
    countriesLoading,
    submitLoading,
    files,
    submitDisabled,
    onRemoveFile,
    onAddFiles,
    onSubmit,
  };
};
