import { FormContainer, FormContainerProps } from 'components/FormContainer';

import { FormContent } from './FormContent';

interface Props<Values extends object> extends FormContainerProps<Values> {
  onSendEmailCode: (email: string) => void;
  resendTime?: number;
  emailFieldName: Extract<keyof Values, string>;
  codeFieldName: Extract<keyof Values, string>;
}

export const EmailFormBody = <Values extends object>({
  onSendEmailCode,
  resendTime,
  emailFieldName,
  codeFieldName,
  ...formProps
}: Props<Values>) => (
  <FormContainer {...formProps}>
    <FormContent
      codeFieldName={codeFieldName}
      emailFieldName={emailFieldName}
      resendTime={resendTime}
      onSendEmailCode={onSendEmailCode}
    />
  </FormContainer>
);
