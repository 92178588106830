// eslint-disable-next-line no-restricted-imports
import { OnboardingClientFragment } from 'api/generated/graphql';
import { ClientEntity } from 'api/types/entity';
import { lodash } from 'helpers';

import { parseBankAccounts } from './parseBankAccounts';
import { parseBiographyEntity } from './parseBiographyEntity';
import { parseDebtsEntity } from './parseClientDebtsEntity';
import { parseClientOnboardingStatus } from './parseClientOnboardingStatus';
import { parseCountryEntity } from './parseCountryEntity';
import { parseFileEntity } from './parseFileEntity';
import { parseFinancingSourcesEntity } from './parseFinancingSourcesEntity';
import { parseKyc } from './parseKyc';
import { parseOnboardingProcessEntity } from './parseOnboardingProcessEntity';
import { parseOrderSubmissionContactsEntity } from './parseOrderSubmissionContactsEntity';
import { parsePepConnectionEntity } from './parsePepConnectionEntity';
import { parseResidentialAddressEntity } from './parseResidentialAddressEntity';
import { parseTaxResidenceEntity } from './parseTaxResidenceEntity';
import { parseWithdrawalAccountsEntity } from './parseWithdrawalAccountsEntity';

export const parseClientEntity = (
  data: OnboardingClientFragment
): ClientEntity => ({
  id: data.id,
  userId: data.userId,
  citizenships: data.citizenships
    ? lodash.compact(data.citizenships).map(parseCountryEntity)
    : undefined,
  taxResidences: data.taxResidences
    ? lodash.compact(data.taxResidences).map(parseTaxResidenceEntity)
    : undefined,
  createdAt: data.createdAt,
  updatedAt: data.updatedAt,
  deletedAt: data.deletedAt,

  placeOfBirth: data.placeOfBirth,
  registrationAddress: data.registrationAddress,
  residentialAddress: parseResidentialAddressEntity(data.residentialAddress),

  biography: data.biography ? parseBiographyEntity(data.biography) : undefined,

  onboarding:
    data.representative?.onboarding || data.onboarding
      ? {
          process: parseOnboardingProcessEntity(
            data.representative?.onboarding?.process ??
              data.onboarding?.process!
          ),
        }
      : undefined,

  bankAccounts: parseBankAccounts(data.bankAccounts),
  financingSources: data.financingSources
    ? parseFinancingSourcesEntity(data.financingSources)
    : undefined,

  pepConnection: parsePepConnectionEntity(data),

  debts: parseDebtsEntity(data.debts),

  withdrawalAccounts: parseWithdrawalAccountsEntity(data.withdrawalAccounts),

  signaturesCardFiles: data.signaturesCardFiles
    ? lodash.compact(data.signaturesCardFiles).map(parseFileEntity)
    : undefined,

  isDataAccuracyConfirmed: data.isDataAccuracyConfirmed ?? false,

  orderSubmissionContacts: parseOrderSubmissionContactsEntity(
    data.orderSubmissionContacts
  ),

  kyc: parseKyc(data.kyc),

  onboardingStatus: parseClientOnboardingStatus(data.onboardingStatus),
});
