import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useLegalClientSaveOwnershipStructureFilesMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { LegalClientSaveOwnershipStructureFiles } from 'api/types/params';

import { parseLegalClient } from './helpers';

export const useLegalClientSaveOwnershipStructureFiles = () => {
  const [saveOwnershipStructureFiles, { data, loading, error: apolloError }] =
    useLegalClientSaveOwnershipStructureFilesMutation();

  const error = useError(apolloError);

  return {
    data: data?.legalClientSaveOwnershipStructureFiles
      ? parseLegalClient(data.legalClientSaveOwnershipStructureFiles)
      : undefined,
    loading,
    error,
    saveOwnershipStructureFiles: useCallback(
      async (id: string, params: LegalClientSaveOwnershipStructureFiles) => {
        const res = await saveOwnershipStructureFiles({
          variables: {
            id,
            input: params,
          },
        });
        return res.data?.legalClientSaveOwnershipStructureFiles
          ? parseLegalClient(res.data.legalClientSaveOwnershipStructureFiles)
          : undefined;
      },
      [saveOwnershipStructureFiles]
    ),
  };
};
