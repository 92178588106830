// eslint-disable-next-line no-restricted-imports
import {
  Query_LegalClientController_GetById_OrganizationalAndLegalForm_OrganizationType as OrganizationTypeApi,
  OrganizationalAndLegalFormDto_Input,
} from 'api/generated/graphql';
import { OrganizationType } from 'api/types/entity';
import { SaveOrganizationalAndLegalFormParam } from 'api/types/params/onboarding/saveOrganizationalAndLegalFormParam';

const organizationTypeMap: Record<OrganizationType, OrganizationTypeApi> = {
  [OrganizationType.Commercial]: OrganizationTypeApi.Commercial,
  [OrganizationType.NonCommercial]: OrganizationTypeApi.NonCommercial,
};

export const parseOrganizationalAndLegalFormParam = (
  params: SaveOrganizationalAndLegalFormParam
): OrganizationalAndLegalFormDto_Input => ({
  isFinancialInstitution: params.isFinancialInstitution,
  isUnincorporatedStructure: params.isUnincorporatedStructure,
  organizationalAndLegalForm: params.organizationalAndLegalForm,
  organizationType: organizationTypeMap[params.organizationType],
});
