import { PhoneInput, PhoneInputProps } from 'libs/ui';

import { FieldType } from '../types';
import { useField } from '../useField';

export interface PhoneFieldProps
  extends FieldType,
    Omit<PhoneInputProps, 'name' | 'onChange' | 'onChangeText' | 'value'> {}

/**
 * PhoneField.
 */
export const PhoneField = ({
  name,
  allowSubmit,
  ...props
}: PhoneFieldProps) => {
  const { field, helpers, inputProps } = useField<string | undefined>(name, {
    allowSubmit,
  });

  return (
    <PhoneInput
      {...props}
      name={name}
      value={field.value ?? ''}
      onBlur={field.onBlur(name)}
      onChange={() => helpers.setError('')}
      onChangeText={(v) => helpers.setValue(v)}
      onClear={() => helpers.setValue('')}
      {...inputProps}
    />
  );
};
