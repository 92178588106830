import { useCallback, useEffect } from 'react';

import { useRegistrationEmailCheckCode } from 'api/requests';
import { useTranslation } from 'libs/i18n';
import { RouteError } from 'types/error';

import { useRegistrationContext } from '../components/RegistrationProvider';
import { RegistrationUrlData } from '../types';

export const useRegistrationEmailLinkConfirm = (
  urlData: RegistrationUrlData,
  onGoNext: () => void
) => {
  const { t } = useTranslation();
  const { changeContextData } = useRegistrationContext();

  const { checkEmailCode, error } = useRegistrationEmailCheckCode();

  const throwRouteError = useCallback(() => {
    throw new RouteError(t('auth.registration.emailUrlConfirm.errorMessage'));
  }, [t]);

  useEffect(() => {
    if (!urlData) {
      throwRouteError();
    }
    checkEmailCode(urlData.email, urlData.code).then(() => {
      changeContextData({ email: urlData?.email });
      onGoNext();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (error) {
      throwRouteError();
    }
  }, [error, throwRouteError]);
};
