import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

// eslint-disable-next-line no-restricted-imports
import { GlobalStyles, ThemeProvider as ThemeProviderMui } from '@mui/material';
import { LocalStorage } from 'libs/storage';
import { CSSObject } from 'libs/ui/styled';

import { Theme, createTheme, PaletteMode } from './theme';
/**
 * Defines the shape of the theme context object used for theming within the application.
 * @interface ThemeContextType
 */
interface ThemeContextType {
  /** The theme configuration */
  theme: Theme;
  /** The current theme mode ('light' or 'dark'). */
  mode: PaletteMode;
  /** Function to toggle between 'light' and 'dark' modes */
  toggleColorMode: () => void;
}

interface ThemeProps {
  mode?: PaletteMode;
}

const ThemeContext = createContext<ThemeContextType>({} as ThemeContextType);

/**
 * ThemeProvider component for managing the application's theme.
 */
export function ThemeProvider({
  children,
  mode: forceMode,
}: ThemeProps & PropsWithChildren): JSX.Element {
  const systemMode =
    window.matchMedia &&
    window.matchMedia('(prefers-color-scheme: dark)').matches
      ? 'dark'
      : 'light';

  const localMode =
    useRef(LocalStorage.getItem(LocalStorage.keys.themeMode)).current ||
    systemMode;

  const [mode, setMode] = useState<PaletteMode>(
    forceMode ?? localMode === 'dark' ? 'dark' : 'light'
  );

  // Function to toggle between 'light' and 'dark' theme modes.
  const toggleColorMode = useCallback(() => {
    setMode((prevMode: PaletteMode) => {
      const mode = prevMode === 'light' ? 'dark' : 'light';
      LocalStorage.setItem(LocalStorage.keys.themeMode, mode);
      return mode;
    });
  }, []);

  useEffect(() => {
    if (forceMode) {
      setMode(forceMode);
    }
  }, [forceMode]);

  const theme = useMemo(() => createTheme(mode), [mode]);

  const value = useMemo(
    () => ({ toggleColorMode, mode, theme }),
    [mode, toggleColorMode, theme]
  );

  useEffect(() => {
    const metaColor = document.querySelector("meta[name='theme-color']");
    if (metaColor) {
      metaColor.setAttribute('content', theme.palette.body);
    }
  }, [theme]);

  return (
    <ThemeContext.Provider value={value}>
      <GlobalStyles
        styles={{
          body: {
            backgroundColor: theme.palette.body,
          } as CSSObject,
        }}
      />
      <ThemeProviderMui theme={theme}>{children}</ThemeProviderMui>
    </ThemeContext.Provider>
  );
}
/**
 * Hook for accessing the theme context and functions.
 * @returns {ThemeContextType} The theme context and functions.
 */
export const useTheme = (): ThemeContextType => useContext(ThemeContext);
