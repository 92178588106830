import { useRef } from 'react';

import { useLegalClientSaveActivityTypes } from 'api/requests';
import { useServerErrorNotify } from 'hooks';

import { CompanyActivitiesFormType } from '../forms/companyActivitiesForm';

import { useOnboardingContext } from './useOnboardingContext';

export const useCompanyActivities = () => {
  const { goToBack, goToNext, data } = useOnboardingContext();
  const { error, loading, saveActivityTypes } =
    useLegalClientSaveActivityTypes();

  const initialValues = useRef({
    activityTypes: data.legalClient!.activityTypes ?? [{ name: '' }],
  }).current;

  const onSubmit = async (values: CompanyActivitiesFormType) => {
    if (values.activityTypes.length) {
      const res = await saveActivityTypes(data.legalClient!.id, values);
      if (res) {
        goToNext();
      }
    }
  };

  useServerErrorNotify(error);

  return {
    onSubmit,
    goToBack,
    submitLoading: loading,
    initialValues,
  };
};
