import {
  ClientType,
  FinancingSources,
} from 'modules/onboarding/common/components';

import { useOwnerFinancingSources } from '../hooks';
import { PageComponentProps } from '../types';

export const OwnerFinancingSourcesStep = ({
  canGoBack,
}: PageComponentProps) => {
  const {
    submitLoading,
    onSubmit,
    goToBack,
    files,
    onAddFiles,
    onRemoveFile,
    submitDisabled,
    initialValues,
  } = useOwnerFinancingSources();

  return (
    <FinancingSources
      canGoBack={canGoBack}
      clientType={ClientType.Client}
      files={files}
      initialValues={initialValues}
      submitDisabled={submitDisabled}
      submitLoading={submitLoading}
      onAddFiles={onAddFiles}
      onGoBack={goToBack}
      onRemoveFile={onRemoveFile}
      onSubmit={onSubmit}
    />
  );
};
