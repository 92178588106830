import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useLegalClientSendCorporateAccountVerificationEmailToBeneficiaryMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';

export const useLegalClientSendCorporateAccountVerificationEmailToBeneficiary =
  () => {
    const [handle, { data, loading, error: apolloError }] =
      useLegalClientSendCorporateAccountVerificationEmailToBeneficiaryMutation();

    const error = useError(apolloError);

    return {
      data: {
        resendTime:
          data?.legalClientSendCorporateAccountVerificationEmailToBeneficiary
            ?.resendTime,
      },
      loading,
      error,
      send: useCallback(
        async (id: string, beneficiaryId: string) => {
          const res = await handle({ variables: { id, beneficiaryId } });
          return {
            resendTime:
              res.data
                ?.legalClientSendCorporateAccountVerificationEmailToBeneficiary
                ?.resendTime,
          };
        },
        [handle]
      ),
    };
  };
