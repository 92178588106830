import { DialogProps } from 'libs/ui';

import { VerificationCodeFormContentDialog } from '../VerificationCodeFormContentDialog';

import {
  CODE_LENGTH,
  Form,
  schema,
  VerificationCodeFormFormErrors,
  VerificationCodeFormType,
} from './verificationCodeForm';

interface Props extends DialogProps {
  initialErrors?: VerificationCodeFormFormErrors;
  onSubmit: (values: VerificationCodeFormType) => void;
}

export const VerificationCodeFormDialog = ({
  initialErrors,
  onSubmit,
  ...dialogProps
}: Props) => (
  <Form
    initialErrors={initialErrors}
    validationSchema={schema}
    onSubmit={onSubmit}
  >
    <VerificationCodeFormContentDialog<VerificationCodeFormType>
      {...dialogProps}
      codeFieldName="code"
      length={CODE_LENGTH}
    />
  </Form>
);
