import { useRef } from 'react';

import { useLegalClientBranchSaveTaxResidences } from 'api/requests/onboarding/legal/branch';
import { useServerErrorNotify } from 'hooks';
import { useOnboardingCountries } from 'modules/onboarding/common/hooks';
import { CompanyTaxResidencyFormType } from 'modules/onboarding/legal/forms/companyTaxResidencyForm';
import {
  getInitialData,
  getSubmitData,
} from 'modules/onboarding/legal/helpers/companyTaxResidences';

import { useOnboardingContext } from './useOnboardingContext';

export const useTaxResidency = () => {
  const { goToBack, goToNext, data } = useOnboardingContext();
  const { countries, loading: countriesLoading } = useOnboardingCountries();

  const { error, loading, saveTaxResidences } =
    useLegalClientBranchSaveTaxResidences();

  const initialValues = useRef(
    getInitialData(data.legalClientBranch.taxResidences)
  ).current;

  const onSubmit = async (values: CompanyTaxResidencyFormType) => {
    if (values.taxValues) {
      const submitData = getSubmitData(values);
      await saveTaxResidences(
        data.legalClient.id,
        data.legalClientBranch.id,
        submitData
      );
      goToNext();
    }
  };

  useServerErrorNotify(error);

  return {
    onSubmit,
    goToBack,
    submitLoading: loading,
    initialValues,
    countries,
    countriesLoading,
  };
};
