import { useRef } from 'react';

import { useLegalClientHeadSavePositionInCompany } from 'api/requests';
import { useFilesUpload } from 'hooks';
import { PositionInCompanyFormType } from 'modules/onboarding/legal/forms/positionInCompanyForm';
import {
  getSubmitData,
  getInitialData,
} from 'modules/onboarding/legal/helpers/positionInCompany';

import { useOnboardingContext } from './useOnboardingContext';

export const usePositionInCompany = () => {
  const { goToBack, goToNext, data } = useOnboardingContext();

  const {
    error,
    loading: submitLoading,
    savePositionInCompany,
  } = useLegalClientHeadSavePositionInCompany();

  const initialData = useRef(
    getInitialData(data.legalClientHead.positionInCompany)
  ).current;

  const initialValues = useRef(initialData?.values).current;

  const {
    files,
    isLoadingFile: submitDisabled,
    onRemoveFile,
    onAddFiles,
  } = useFilesUpload(initialData.files);

  const handleSubmit = async (values: PositionInCompanyFormType) => {
    if (values.fileIds) {
      const params = getSubmitData(values);
      await savePositionInCompany(
        data.legalClient.id,
        data.legalClientHead.id,
        params
      );
      goToNext();
    }
  };

  return {
    onSubmit: handleSubmit,
    goToBack,
    submitLoading,
    initialValues,
    files,
    error,
    onAddFiles,
    onRemoveFile,
    submitDisabled,
  };
};
