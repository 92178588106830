import { Link, LinkProps, Stack } from 'libs/ui';
import { CSSObject, styled } from 'libs/ui/styled';

export const DownloadInvoiceStyled = styled(Link)<LinkProps>(
  ({ disabled }) => ({
    textDecoration: 'none',

    ...(disabled
      ? ({
          pointerEvents: 'none',
        } as CSSObject)
      : {}),
  })
);

export const DownloadInvoiceWrapper = styled(Stack)({
  display: 'inline-flex',
});
