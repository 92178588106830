import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useClientRepresentativeSaveDebtsMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { SaveBaseDebtsParam } from 'api/types/params';

import { parseClientRepresentativeDebtsParams } from './helpers';
import { parseClientRepresentative } from './helpers/parseClientRepresentative';

export const useClientRepresentativeSaveDebts = () => {
  const [saveDebts, { data, loading, error: apolloError }] =
    useClientRepresentativeSaveDebtsMutation();

  const error = useError(apolloError);

  return {
    data: data?.clientRepresentativeSaveDebts
      ? parseClientRepresentative(data.clientRepresentativeSaveDebts)
      : undefined,
    loading,
    error,
    saveDebts: useCallback(
      async (id: string, params: SaveBaseDebtsParam) => {
        const res = await saveDebts({
          variables: {
            id,
            input: parseClientRepresentativeDebtsParams(params),
          },
        });
        return res.data?.clientRepresentativeSaveDebts
          ? parseClientRepresentative(res.data.clientRepresentativeSaveDebts)
          : undefined;
      },
      [saveDebts]
    ),
  };
};
