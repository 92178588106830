import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useLegalClientBranchSaveTaxResidencesMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { LegalClientSaveTaxResidencesParam } from 'api/types/params';

import { parseLegalClientBranchEntity } from './helpers';

export const useLegalClientBranchSaveTaxResidences = () => {
  const [handle, { data, loading, error: apolloError }] =
    useLegalClientBranchSaveTaxResidencesMutation();

  const error = useError(apolloError);

  return {
    data: data?.legalClientBranchSaveTaxResidences
      ? parseLegalClientBranchEntity(data.legalClientBranchSaveTaxResidences)
      : undefined,
    loading,
    error,
    saveTaxResidences: useCallback(
      async (
        id: string,
        branchId: string,
        params: LegalClientSaveTaxResidencesParam
      ) => {
        const res = await handle({
          variables: {
            id,
            branchId,
            input: params,
          },
        });
        return res?.data?.legalClientBranchSaveTaxResidences
          ? parseLegalClientBranchEntity(
              res.data.legalClientBranchSaveTaxResidences
            )
          : undefined;
      },
      [handle]
    ),
  };
};
