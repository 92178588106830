import { useCallback, useLayoutEffect, useRef, useState } from 'react';

import {
  ScenarioName,
  useLegalClientCompleteHeadOnboarding,
  useLegalClientGetHeadLazy,
  useLegalClientHeadSettingsSave,
  useOnboardingGetProcessByScenarioName,
} from 'api/requests';
import {
  LegalClientEntity,
  LegalClientRepresentativeEntity,
} from 'api/types/entity';
import { LegalClientCreateHeadSaveParam } from 'api/types/params';

import { useCreateHead } from './useCreateHead';

export const useOnboarding = (
  currentClient: LegalClientRepresentativeEntity,
  legalClient: LegalClientEntity,
  legalClientHeadId?: string
) => {
  const headId = useRef(legalClientHeadId);
  const { saveSettings } = useLegalClientHeadSettingsSave();

  const { complete } = useLegalClientCompleteHeadOnboarding();

  const { getHead, legalClientHead } = useLegalClientGetHeadLazy();

  const { createHead } = useCreateHead(legalClient);

  const { process, loading: processLoading } =
    useOnboardingGetProcessByScenarioName(ScenarioName.LegalClientHead);

  const [loading, setLoading] = useState(false);

  const onGoBack = useCallback(
    async (currentStepName: string) => {
      if (legalClient && legalClientHeadId) {
        await saveSettings(legalClient.id, legalClientHeadId, currentStepName);
      }
    },
    [legalClient, legalClientHeadId, saveSettings]
  );

  const onEnd = useCallback(
    async () => complete(legalClient.id, legalClientHead?.id!),
    [complete, legalClient.id, legalClientHead?.id]
  );

  const handleCreateHead = async (param: LegalClientCreateHeadSaveParam) => {
    const head = await createHead(param);
    if (head) {
      await getHead(legalClient.id, head.id);
    }
    return head;
  };

  useLayoutEffect(() => {
    if (headId.current) {
      setLoading(true);
      getHead(legalClient.id, headId.current).finally(() => setLoading(false));
    }
  }, [getHead, legalClient.id]);

  return {
    currentClient,
    legalClientHead,
    legalClient,
    loading: loading || (processLoading && !process),
    process,
    onGoBack,
    onEnd,
    createHead: handleCreateHead,
  };
};
