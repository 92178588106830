import { useState, useMemo, useRef, useCallback, ReactNode } from 'react';

import { delay } from 'helpers';
import { useTranslation } from 'libs/i18n';

import { NotificationsContext } from './NotificationsContext';
import { Message, NotificationTypes } from './types';

interface ChildRenderProps {
  state: State;
  close: () => void;
}

interface State {
  visible: boolean;
  message: Message | null;
}

interface Props {
  children: ReactNode;
  renderItem: (props: ChildRenderProps) => ReactNode;
  logger?: (...args: unknown[]) => void;
  autohide?: { timeOpen: number; timeOut: number } | null;
}

const SNACKBAR_TIME_OPEN = 3000;
const SNACKBAR_ANIMATION_OUT_TIMING = 800;

const AUTO_HIDE = {
  timeOpen: SNACKBAR_TIME_OPEN,
  timeOut: SNACKBAR_ANIMATION_OUT_TIMING,
};

export const NotificationsProvider = ({
  children,
  renderItem,
  logger,
  autohide = AUTO_HIDE,
}: Props) => {
  const { t } = useTranslation();
  const [state, setState] = useState<State>({
    visible: false,
    message: null,
  });
  const promiseRef = useRef<Promise<unknown>>(Promise.resolve());
  const lastRef = useRef<{
    type: NotificationTypes | null;
    text: string | null;
  }>({
    type: null,
    text: null,
  }).current;

  const close = useCallback(() => {
    setState((prev) => ({ ...prev, visible: false }));
  }, []);

  const show = useCallback(
    async (type: NotificationTypes, text: string) => {
      if (lastRef.text === text && lastRef.type === type) {
        return;
      }
      lastRef.text = text;
      lastRef.type = type;
      promiseRef.current = promiseRef.current.then(async () => {
        setState({ visible: true, message: { type, text } });
        if (autohide) {
          await delay(autohide.timeOpen);
          close();
          await delay(autohide.timeOut);
          lastRef.text = null;
          lastRef.type = null;
        }
      });
    },
    [lastRef, autohide, close]
  );

  const showError = useCallback(
    (err: unknown, defaultMessage?: string, logError?: boolean) => {
      if (logError) {
        logger?.(err);
      }
      const errorMessage =
        defaultMessage ?? (err as Error).message ?? t('common.unknownError');
      show('error', errorMessage);
    },
    [logger, show, t]
  );

  const logAndError = useCallback(
    (err: unknown, defaultMessage?: string) =>
      showError(err, defaultMessage, true),
    [showError]
  );

  const error = useCallback(
    (err: unknown, defaultMessage?: string) => showError(err, defaultMessage),
    [showError]
  );

  const info = useCallback(
    (text: string) => {
      show('info', text);
    },
    [show]
  );

  const notificationValues = useMemo(
    () => ({ error, info, logAndError, show }),
    [error, info, logAndError, show]
  );

  return (
    <NotificationsContext.Provider value={notificationValues}>
      {renderItem({ state, close })}
      {children}
    </NotificationsContext.Provider>
  );
};
