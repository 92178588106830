export enum LegalClientOnboardingStatusType {
  /** Заполнение анкеты (X%) */
  FillingOut = 'FillingOut',
  /** Ожидание проверки на корректность */
  AwaitingValidation = 'AwaitingValidation',
  /** Проверка на корректность */
  ValidationCheck = 'ValidationCheck',
  /** Ожидание Compliance проверки */
  AwaitingComplianceCheck = 'AwaitingComplianceCheck',
  /** Compliance проверка */
  ComplianceCheck = 'ComplianceCheck',
  /** Ожидание проверки ПОД/ФТ */
  AwaitingAmlCftCheck = 'AwaitingAmlCftCheck',
  /** Проверка ПОД/ФТ */
  AmlCftCheck = 'AmlCftCheck',
  /** Ожидание проверки правлением */
  PendingBoardReview = 'PendingBoardReview',
  /** Проверка правлением */
  BoardReview = 'BoardReview',
  /** Подписание документов */
  SigningDocuments = 'SigningDocuments',
  /** Ожидание открытия счета */
  AwaitingAccountOpening = 'AwaitingAccountOpening',
  /** Открытие счета */
  AccountOpening = 'AccountOpening',
  /** Счет открыт */
  AccountOpened = 'AccountOpened',
  /** Отказано в открытии счета */
  AccountOpeningDenied = 'AccountOpeningDenied',
  /** Ожидание подтверждения оплаты */
  AwaitingPaymentConfirmation = 'AwaitingPaymentConfirmation',
}
