import { useCallback, useEffect } from 'react';

import {
  useRegistrationTwoFactorGenerate,
  useRegistrationTwoFactorVerify,
} from 'api/requests';
import { useObjectState } from 'hooks';
import { useTranslation } from 'libs/i18n';

import {
  RegistrationTwoFactorFormType,
  FormErrors,
} from '../forms/registrationTwoFactorForm';

export const useRegistrationTwoFactor = () => {
  const { t } = useTranslation();

  const {
    generate,
    data: qrData,
    loading: qrLoading,
    error: qrError,
  } = useRegistrationTwoFactorGenerate();

  const {
    verify,
    loading: submitLoading,
    error,
  } = useRegistrationTwoFactorVerify();

  useEffect(() => {
    generate();
  }, [generate]);

  const onSubmit = useCallback(
    async (values: RegistrationTwoFactorFormType) => {
      if (values.code) {
        return verify(values.code);
      }
      return false;
    },
    [verify]
  );

  const [formError, setFormError] = useObjectState<FormErrors>({});

  useEffect(() => {
    if (error) {
      setFormError({ code: t('auth.registration.codeError') });
    } else {
      setFormError({ code: undefined });
    }
  }, [error, setFormError, t]);

  return { onSubmit, formError, qrData, submitLoading, qrError, qrLoading };
};
