// eslint-disable-next-line no-restricted-imports
import { GenerateOtpResponseDto } from 'api/generated/graphql';
import { RegistrationQrCodeGenerateData } from 'api/types/data';

export const parseData = (
  data: GenerateOtpResponseDto
): RegistrationQrCodeGenerateData => ({
  qrUrl: data.qrUrl,
  secret: data.secret,
});
