import { PositionInCompany } from 'modules/onboarding/legal/components';

import { usePositionInCompany } from '../hooks';
import { useOnboardingContext } from '../hooks/useOnboardingContext';
import { PageComponentProps } from '../types';

export const PositionInCompanyStep = ({ canGoBack }: PageComponentProps) => {
  const {
    files,
    initialValues,
    onAddFiles,
    onRemoveFile,
    onSubmit,
    submitDisabled,
    submitLoading,
    goToBack,
  } = usePositionInCompany();

  const { step } = useOnboardingContext();

  return (
    <PositionInCompany
      canGoBack={canGoBack}
      files={files}
      initialValues={initialValues}
      step={step}
      submitDisabled={submitDisabled}
      submitLoading={submitLoading}
      onAddFiles={onAddFiles}
      onGoBack={goToBack}
      onRemoveFile={onRemoveFile}
      onSubmit={onSubmit}
    />
  );
};
