import { ClientType } from 'modules/onboarding/common/components';

import { CompanyRegistrationAddress } from '../../components';
import { useLegalRepresentativeCompanyRegistrationAddress } from '../../hooks';
import { PageComponentProps } from '../../types';

export const LegalRepresentativeCompanyRegistrationAddressStep = ({
  canGoBack,
}: PageComponentProps) => {
  const {
    countries,
    countriesLoading,
    initialValues,
    submitLoading,
    onSubmit,
    goToBack,
  } = useLegalRepresentativeCompanyRegistrationAddress();

  return (
    <CompanyRegistrationAddress
      canGoBack={canGoBack}
      clientType={ClientType.ClientRepresentative}
      countries={countries}
      countriesLoading={countriesLoading}
      initialValues={initialValues}
      submitLoading={submitLoading}
      onGoBack={goToBack}
      onSubmit={onSubmit}
    />
  );
};
