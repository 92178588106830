// eslint-disable-next-line no-restricted-imports
import { LegalClientBranchForLegalClientFragment } from 'api/generated/graphql';
import { LegalClientInnerItemEntity } from 'api/types/entity/legal/legalClientInnerItemEntity';

export const parseLegalClientBranchItemEntity = (
  data: LegalClientBranchForLegalClientFragment
): LegalClientInnerItemEntity => ({
  id: data.id,
  name: data.fullName ?? '',

  createdAt: new Date(data.createdAt),
  isCompletedByRepresentative:
    data.onboarding?.isCompletedByRepresentative ?? false,
});
