import { subYears } from 'date-fns';
import { isValidName } from 'helpers';
import {
  ObjectSchema,
  string,
  object,
  boolean,
  date,
  TypedForm,
} from 'libs/form';
import { TFunc } from 'libs/i18n';

export interface RegistrationPersonalDataFormType {
  representative?: boolean;
  firstname: string;
  lastname: string;
  surname?: string | null;
  dateOfBirth: Date;
}
export const dateOfBirthMaxDate = subYears(new Date(), 18);
export const dateOfBirthMinDate = subYears(new Date(), 100);

export const getSchema = (t: TFunc) => {
  const schema: ObjectSchema<RegistrationPersonalDataFormType> = object({
    representative: boolean(),
    firstname: string()
      .max(20)
      .required()
      .test('ischars', t('validation.noSpecialCharsAndNumber'), isValidName),
    lastname: string()
      .max(20)
      .required()
      .test('ischars', t('validation.noSpecialCharsAndNumber'), isValidName),
    surname: string()
      .max(20)
      .nullable()
      .test('ischars', t('validation.noSpecialCharsAndNumber'), isValidName),
    dateOfBirth: date()
      .min(
        dateOfBirthMinDate,
        t('auth.registration.personalData.dateOfBirthMaxError')
      )
      .max(
        dateOfBirthMaxDate,
        t('auth.registration.personalData.dateOfBirthMinError')
      )
      .required(),
  });

  return schema;
};

export const { Field, Form, useFormContext, Submit } =
  TypedForm<RegistrationPersonalDataFormType>();
