import { useRef } from 'react';

import { useClientSavePepConnection } from 'api/requests';
import { ClientEntity } from 'api/types/entity';
import { useServerErrorNotify } from 'hooks';
import { useTranslation } from 'libs/i18n';
import { OnboardingPepConnectionFormType } from 'modules/onboarding/common/forms/onboardingPepConnectionForm';
import { getInitialData } from 'modules/onboarding/common/helpers/pepConnection/getInitialData';
import { getSubmitData } from 'modules/onboarding/common/helpers/pepConnection/getSubmitData';

export const useClientPepConnection = (client: ClientEntity) => {
  const { t } = useTranslation();
  const { savePepConnection, error, loading } = useClientSavePepConnection();

  const initialValues = useRef(getInitialData(client.pepConnection, t)).current;

  const onSubmit = async (values: OnboardingPepConnectionFormType) => {
    if (values) {
      const data = getSubmitData(values);
      await savePepConnection(client.id, data);
    }
  };

  useServerErrorNotify(error);

  return {
    onSubmit,
    initialValues,
    submitLoading: loading,
  };
};
