// eslint-disable-next-line no-restricted-imports
import {
  SaveInvoiceDto_Input,
  MutationInput_LegalClientController_SaveInvoice_Input_Currency as CurrencyApi,
} from 'api/generated/graphql';
import { CurrencyInvoiceType } from 'api/types/entity';
import { CreateInvoiceParam } from 'api/types/params';

const currency: Record<CurrencyInvoiceType, CurrencyApi> = {
  [CurrencyInvoiceType.EUR]: CurrencyApi.Eur,
  [CurrencyInvoiceType.KZT]: CurrencyApi.Kzt,
  [CurrencyInvoiceType.RUB]: CurrencyApi.Rub,
  [CurrencyInvoiceType.USD]: CurrencyApi.Usd,
};

export const parseLegalClientCreateInvoiceParam = (
  data: CreateInvoiceParam
): SaveInvoiceDto_Input => ({
  currency: currency[data.currency],
});
