import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useLegalClientSaveInvoiceMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { CreateInvoiceParam } from 'api/types/params';

import {
  parseLegalClientCreateInvoiceParam,
  parseLegalClient,
} from './helpers';

export const useLegalClientSaveInvoice = () => {
  const [saveInvoice, { data, loading, error: apolloError }] =
    useLegalClientSaveInvoiceMutation();

  const error = useError(apolloError);

  return {
    data: data?.legalClientSaveInvoice
      ? parseLegalClient(data.legalClientSaveInvoice)
      : undefined,
    loading,
    error,
    saveInvoice: useCallback(
      async (id: string, params: CreateInvoiceParam) => {
        const res = await saveInvoice({
          variables: {
            id,
            input: parseLegalClientCreateInvoiceParam(params),
          },
        });
        return res?.data?.legalClientSaveInvoice
          ? parseLegalClient(res.data.legalClientSaveInvoice)
          : undefined;
      },
      [saveInvoice]
    ),
  };
};
