// eslint-disable-next-line no-restricted-imports
import {
  WithdrawalAccountsFragment,
  Query_ClientController_GetById_FinancingSources_IncomeAmount_Currency as CurrencyApi,
} from 'api/generated/graphql';
import { CurrencyType } from 'api/types/entity';
import { WithdrawalAccountEntity } from 'api/types/entity/clientEntity/withdrawalAccountEntity';
import { lodash } from 'helpers';
import { Maybe } from 'types/maybe';

import { parseFileEntity } from './parseFileEntity';

const currency: Record<CurrencyApi, CurrencyType> = {
  [CurrencyApi.Aed]: CurrencyType.AED,
  [CurrencyApi.Chf]: CurrencyType.CHF,
  [CurrencyApi.Cny]: CurrencyType.CNY,
  [CurrencyApi.Eur]: CurrencyType.EUR,
  [CurrencyApi.Gbp]: CurrencyType.GBP,
  [CurrencyApi.Hkd]: CurrencyType.HKD,
  [CurrencyApi.Idr]: CurrencyType.IDR,
  [CurrencyApi.Inr]: CurrencyType.INR,
  [CurrencyApi.Jpy]: CurrencyType.JPY,
  [CurrencyApi.Kzt]: CurrencyType.KZT,
  [CurrencyApi.Rub]: CurrencyType.RUB,
  [CurrencyApi.Usd]: CurrencyType.USD,
};
export const parseWithdrawalAccountsEntity = (
  data?: Maybe<Array<Maybe<WithdrawalAccountsFragment>>>
): WithdrawalAccountEntity[] | undefined => {
  if (!data) {
    return undefined;
  }

  const res: WithdrawalAccountEntity[] = lodash.compact(data).map((v) => ({
    bankAddress: v.bankAddress,
    bankCountry: {
      id: v.bankCountry.id,
      name: v.bankCountry.name,
    },
    bankName: v.bankName,
    checkingAccount: v.checkingAccount,
    correspondentAccount: v.correspondentAccount ?? undefined,
    ownerConfirmationDocuments: lodash
      .compact(v.ownerConfirmationDocuments)
      .map((v) => parseFileEntity(v)),
    swiftCode: v.swiftCode,
    currency: currency[v.currency],
  }));

  return res;
};
