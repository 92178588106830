// eslint-disable-next-line no-restricted-imports
import {
  LegalOrderSubmissionContactsDto_Input,
  LegalContactDto_Input,
  Query_LegalClientController_GetById_OrderSubmissionContacts_Items_Type,
} from 'api/generated/graphql';
import { LegalClientSaveOrderSubmissionContactParam } from 'api/types/params';
import { lodash } from 'helpers';

export const parseLegalClientOrderSubmissionContactParam = ({
  type,
  ...orderSubmissionContactOtherFields
}: LegalClientSaveOrderSubmissionContactParam): LegalContactDto_Input => ({
  ...orderSubmissionContactOtherFields,
  type: Query_LegalClientController_GetById_OrderSubmissionContacts_Items_Type[
    type
  ],
});

export const parseLegalClientOrderSubmissionContactsParam = (
  orderSubmissionContacts: LegalClientSaveOrderSubmissionContactParam[]
): LegalOrderSubmissionContactsDto_Input => ({
  orderSubmissionContacts: lodash
    .compact(orderSubmissionContacts)
    .map(parseLegalClientOrderSubmissionContactParam),
});
