import { FC } from 'react';

import { AgreementsClassificationType } from 'api/types/entity/agreementsEntity';
import { useTranslation } from 'libs/i18n';
import { usePageTitle } from 'libs/navigation';

import { useAgreements } from '../hooks';
import { AgreementView } from '../views/AgreementView';

export interface AgreementContainerProps {
  onAgreement: (ids: string[]) => void;
  onGoBack?: () => void;
  canGoBack?: boolean;
  classificationType: AgreementsClassificationType;
}

export const AgreementContainer: FC<AgreementContainerProps> = ({
  canGoBack,
  classificationType,
  onAgreement,
  onGoBack,
}) => {
  const { activeIndex, agreements, loading, onAccept } = useAgreements({
    classificationType,
    onAgreement,
  });

  const { t } = useTranslation();

  usePageTitle(t('containers.agreement.pageTitle'));

  return (
    <AgreementView
      activeIndex={activeIndex}
      agreements={agreements}
      canGoBack={canGoBack}
      loading={loading}
      onAccept={onAccept}
      onGoBack={onGoBack}
    />
  );
};
