import { Typography } from 'libs/ui';
import { styled } from 'libs/ui/styled';

export const HintTypographyStyled = styled(Typography)(({ theme }) => ({
  width: 367,
  display: 'block',

  [theme.breakpoints.down('mobile')]: {
    width: 283,
  },
}));
