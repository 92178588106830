import { CountryEntity } from 'api/types/entity';
import {
  TypedForm,
  FormErrors as FormErrorsBase,
  ObjectSchema,
  object,
  string,
} from 'libs/form';
import { Maybe } from 'types/maybe';

export interface CompanyRegistrationPlaceFormType {
  country: Maybe<CountryEntity>;
  locality: string;
  specialRegistrationArea?: string;
}

export const maxLengthLocality = 200;
export const maxLengthSpecialRegistrationArea = 200;

export const getSchema = (): ObjectSchema<CompanyRegistrationPlaceFormType> =>
  object({
    country: object({
      id: string().required(),
      name: string().required(),
    }).required(),
    locality: string().max(maxLengthLocality).required(),
    specialRegistrationArea: string().max(maxLengthSpecialRegistrationArea),
  });

export const { Field, Form, useFormContext, Submit } =
  TypedForm<CompanyRegistrationPlaceFormType>();

export type FormErrors = FormErrorsBase<CompanyRegistrationPlaceFormType>;
