import { FormContainer } from 'components';
import { useTranslation } from 'libs/i18n';
import { FileType, FileUpload, Stack } from 'libs/ui';

import {
  CompanyFinancingSourcesFormType,
  FormErrors,
  Field,
  getSchema,
  MAX_FILES,
} from '../../forms/companyFinancingSourcesForm';

import { FormContent } from './FormContent';

interface Props {
  initialValues?: CompanyFinancingSourcesFormType;
  onSubmit: (values: CompanyFinancingSourcesFormType) => void;
  canGoBack?: boolean;
  onGoBack?: () => void;
  formError?: FormErrors;
  submitLoading?: boolean;
  submitDisabled?: boolean;
  auditedFinancialReportFiles?: FileUpload[];
  onAddFilesAuditedFinancialReportFiles?: (file: File[]) => void;
  onRemoveFileAuditedFinancialReportFiles?: (
    file: FileUpload,
    index: number
  ) => void;
  recommendationLetterFiles?: FileUpload[];
  onAddFilesRecommendationLetterFiles?: (file: File[]) => void;
  onRemoveFileRecommendationLetterFiles?: (
    file: FileUpload,
    index: number
  ) => void;
}

export const CompanyFinancingSources = ({
  initialValues,
  canGoBack,
  submitLoading,
  submitDisabled,
  formError,
  onGoBack,
  onSubmit,
  auditedFinancialReportFiles,
  onAddFilesAuditedFinancialReportFiles,
  onAddFilesRecommendationLetterFiles,
  onRemoveFileAuditedFinancialReportFiles,
  recommendationLetterFiles,
  onRemoveFileRecommendationLetterFiles,
}: Props) => {
  const { t } = useTranslation();
  const schema = getSchema(t);
  return (
    <FormContainer
      canGoBack={canGoBack}
      initialErrors={formError}
      initialValues={initialValues}
      submitDisabled={submitDisabled}
      submitLoading={submitLoading}
      subTitle={t('onboarding.companyFinancingSources.subtitle')}
      title={t('onboarding.companyFinancingSources.title')}
      validationSchema={schema}
      onGoBack={onGoBack}
      onSubmit={onSubmit}
    >
      <Stack spacing={32}>
        <FormContent />

        <Field.FilesUpload
          files={auditedFinancialReportFiles}
          fileTypes={[FileType.pdf]}
          maxFiles={MAX_FILES}
          name="auditedFinancialReportFileIds"
          subtitle={t(
            'onboarding.companyFinancingSources.auditedFinancialReportDocument.label'
          )}
          title={t(
            'onboarding.companyFinancingSources.auditedFinancialReportDocumentTitle'
          )}
          onAddFiles={onAddFilesAuditedFinancialReportFiles}
          onRemoveFile={onRemoveFileAuditedFinancialReportFiles}
        />

        <Field.FilesUpload
          files={recommendationLetterFiles}
          fileTypes={[FileType.pdf]}
          maxFiles={MAX_FILES}
          name="recommendationLetterFileIds"
          subtitle={t(
            'onboarding.companyFinancingSources.recommendationLetterDocument.label'
          )}
          title={t(
            'onboarding.companyFinancingSources.recommendationLetterDocumentTitle'
          )}
          onAddFiles={onAddFilesRecommendationLetterFiles}
          onRemoveFile={onRemoveFileRecommendationLetterFiles}
        />
      </Stack>
    </FormContainer>
  );
};
