import { Name } from '../components';
import { useName, useOnboardingContext } from '../hooks';
import { PageComponentProps } from '../types';

export const NameStep = ({ canGoBack }: PageComponentProps) => {
  const { goToBack, initialValues, onSubmit, submitLoading } = useName();

  const { step } = useOnboardingContext();

  return (
    <Name
      canGoBack={canGoBack}
      initialValues={initialValues}
      step={step}
      submitLoading={submitLoading}
      onGoBack={goToBack}
      onSubmit={onSubmit}
    />
  );
};
