import { useEffect } from 'react';

import { ROUTES, useNavigate } from 'libs/navigation';

import { useLogin, useUrlToken } from '../hooks';
import { LoginByTokenView } from '../views/LoginByTokenView';

export const LoginByTokenContainer = () => {
  const token = useUrlToken();

  const { navigate } = useNavigate();

  const { loginByToken, error } = useLogin();

  useEffect(() => {
    if (!token) {
      navigate(ROUTES.login.fullPath, {});
    } else {
      loginByToken(token);
    }
  }, [loginByToken, navigate, token]);

  return <LoginByTokenView isError={!!error} />;
};
