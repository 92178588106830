export enum OccupationType {
  employee = 'Employee',
  entrepreneur = 'Entrepreneur',
  businessOwner = 'BusinessOwner',
  student = 'Student',
  retired = 'Retired',
  unemployed = 'Unemployed',
}

export enum MaritalStatusType {
  married = 'Married',
  single = 'Single',
}

export interface BiographyEntity {
  maritalStatus: MaritalStatusType;
  occupation: OccupationType;

  companyName?: string;
  workAddress?: string;
  position?: string;

  entrepreneurName?: string;
  registrationNumber?: string;
  registrationDate?: Date;
  registrationPlace?: string;
  typeOfServices?: string;
}
