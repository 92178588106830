import { useRef } from 'react';

import { useLegalClientRepresentativeSaveCompanyName } from 'api/requests';
import { useServerErrorNotify } from 'hooks';
import { CompanyNameFormType } from 'modules/onboarding/common/forms/companyNameForm';

import { useOnboardingContext } from '../useOnboardingContext';

export const useLegalRepresentativeCompanyName = () => {
  const { goToBack, goToNext, data } = useOnboardingContext();
  const { error, loading, saveCompanyName } =
    useLegalClientRepresentativeSaveCompanyName();

  const initialValues = useRef(
    data.currentClient.company?.fullName
      ? {
          fullName: data.currentClient.company.fullName,
          shortName: data.currentClient.company.shortName,
        }
      : undefined
  ).current;

  const handleSubmit = async (values: CompanyNameFormType) => {
    if (values) {
      const res = await saveCompanyName(data.currentClient!.id, {
        fullName: values.fullName,
        shortName: values.shortName,
      });

      if (res) {
        goToNext();
      }
    }
  };

  useServerErrorNotify(error);

  return {
    onSubmit: handleSubmit,
    goToBack,
    submitLoading: loading,
    initialValues,
  };
};
