import { useRef } from 'react';

import { useLegalClientSaveOrderSubmissionContacts } from 'api/requests';
import { useServerErrorNotify } from 'hooks';
import { useTranslation } from 'libs/i18n';

import { LegalOrderSubmissionContactsFormType } from '../forms/orderSubmissionContacts';
import { getSubmitData } from '../helpers/orderSubmissionContacts';
import { getInitialData } from '../helpers/orderSubmissionContacts/getInitialData';

import { useOnboardingContext } from './useOnboardingContext';

export const useOrderSubmissionContacts = () => {
  const { goToBack, goToNext, data } = useOnboardingContext();
  const {
    error,
    saveContacts,
    loading: submitLoading,
  } = useLegalClientSaveOrderSubmissionContacts();

  const { t } = useTranslation();

  const initialData = useRef(
    getInitialData(t, data.legalClient!.orderSubmissionContacts)
  ).current;
  const initialValues = useRef(initialData).current;

  const onSubmit = async (values: LegalOrderSubmissionContactsFormType) => {
    if (values.orderSubmissionContacts.length) {
      const submitData = getSubmitData(values);
      await saveContacts(data.legalClient!.id, submitData);
      goToNext();
    }
  };

  useServerErrorNotify(error);

  return {
    onSubmit,
    goToBack,
    initialValues,
    submitLoading,
  };
};
