import { KYCStatus } from 'api/types/data';
import { OnboardingStatus } from 'api/types/entity';

export const useKycOnboardingStatus = (
  status?: KYCStatus | null
): OnboardingStatus | undefined => {
  let onboardingStatus: OnboardingStatus | undefined;

  switch (status) {
    case KYCStatus.error:
      onboardingStatus = 'rejected';
      break;
    case KYCStatus.success:
      onboardingStatus = 'identityConfirmed';
      break;
    default:
      onboardingStatus = undefined;
  }

  return onboardingStatus;
};
