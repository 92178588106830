import {
  TypedForm,
  FormErrors as FormErrorsBase,
  ObjectSchema,
  object,
  string,
  date,
  array,
} from 'libs/form';
import { TFunc } from 'libs/i18n';
import { getToday } from 'modules/onboarding/common/helpers/getToday';

export interface CompanyConstituentDocsFormType {
  dateOfLastChange: Date;
  fileIds: string[];
}
export const MAX_FILES = 10;

export const getSchema = (
  t: TFunc
): ObjectSchema<CompanyConstituentDocsFormType> =>
  object({
    dateOfLastChange: date()
      .required()
      .max(getToday(), t('validation.maxDate')),
    fileIds: array().of(string().required()).required().min(1).max(MAX_FILES),
  });

export const { Field, Form, useFormContext, Submit } =
  TypedForm<CompanyConstituentDocsFormType>();

export type FormErrors = FormErrorsBase<CompanyConstituentDocsFormType>;
