import { FormContainer, FormContainerProps } from 'components';
import { useTranslation, TranslationPath } from 'libs/i18n';
import { Chip, ChipProps, Stack } from 'libs/ui';

export enum ClientType {
  Client,
  ClientRepresentative,
}

const chipVariantMap: Record<ClientType, ChipProps['variant']> = {
  [ClientType.Client]: 'yellow',
  [ClientType.ClientRepresentative]: 'blue',
};

const chipTextMap: Record<ClientType, TranslationPath> = {
  [ClientType.Client]: 'onboarding.clientTitle',
  [ClientType.ClientRepresentative]: 'onboarding.clientRepresentativeTitle',
};

export interface FormBodyWrapperProps<Values extends object>
  extends FormContainerProps<Values> {
  clientType?: ClientType;
}

export const FormBodyWrapper = <Values extends object>({
  children,
  clientType,
  ...formProps
}: FormBodyWrapperProps<Values>) => {
  const { t } = useTranslation();

  return (
    <FormContainer {...formProps}>
      <Stack spacing={24} spacingMobile={16}>
        {clientType !== undefined && (
          <Chip
            size="small"
            text={t(chipTextMap[clientType])}
            variant={chipVariantMap[clientType]}
          />
        )}

        {children}
      </Stack>
    </FormContainer>
  );
};
