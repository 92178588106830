import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useClientRepresentativeSaveBankAccountsMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { SaveBankAccountsParam } from 'api/types/params';

import { parseClientRepresentative } from './helpers/parseClientRepresentative';

export const useClientRepresentativeSaveBankAccounts = () => {
  const [saveBankAccounts, { data, loading, error: apolloError }] =
    useClientRepresentativeSaveBankAccountsMutation();

  const error = useError(apolloError);

  return {
    data: data?.clientRepresentativeSaveBankAccounts
      ? parseClientRepresentative(data.clientRepresentativeSaveBankAccounts)
      : undefined,
    loading,
    error,
    saveBankAccounts: useCallback(
      async (id: string, params: SaveBankAccountsParam) => {
        const res = await saveBankAccounts({
          variables: {
            id,
            input: params,
          },
        });
        return res?.data?.clientRepresentativeSaveBankAccounts
          ? parseClientRepresentative(
              res.data.clientRepresentativeSaveBankAccounts
            )
          : undefined;
      },
      [saveBankAccounts]
    ),
  };
};
