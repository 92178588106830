/**
 * Determine the mobile operating system.
 * This function returns one of 'ios', 'android', or undefined.
 */
export const getMobileOperatingSystem = () => {
  const userAgent = navigator.userAgent || navigator.vendor;

  if (/android/i.test(userAgent)) {
    return 'android';
  }

  // @ts-ignore (for window.MSStream)
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'ios';
  }

  return undefined;
};
