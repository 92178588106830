import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useClientRepresentativeSaveKycStepMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';

import { parseClientRepresentative } from './helpers';

export const useClientRepresentativeSaveKycStep = () => {
  const [saveKycStep, { data, loading, error: apolloError }] =
    useClientRepresentativeSaveKycStepMutation();

  const error = useError(apolloError);

  return {
    data: data?.clientRepresentativeSaveKycStep
      ? parseClientRepresentative(data.clientRepresentativeSaveKycStep)
      : undefined,
    loading,
    error,
    saveKycStep: useCallback(
      async (id: string) => {
        const res = await saveKycStep({
          variables: { id },
        });
        return res.data?.clientRepresentativeSaveKycStep
          ? parseClientRepresentative(res.data.clientRepresentativeSaveKycStep)
          : undefined;
      },
      [saveKycStep]
    ),
  };
};
