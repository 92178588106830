import { OnboardingPepConnectionFormType } from 'modules/onboarding/common/forms/onboardingPepConnectionForm';
import { useClientPepConnection } from 'modules/onboarding/common/hooks';

import { useOnboardingContext } from './useOnboardingContext';

export const useOwnerPepConnection = () => {
  const { goToBack, goToNext, data } = useOnboardingContext();

  const { initialValues, onSubmit, submitLoading } = useClientPepConnection(
    data.ownerClient!
  );

  const handleSubmit = async (values: OnboardingPepConnectionFormType) => {
    if (values) {
      await onSubmit(values);
      goToNext();
    }
  };

  return {
    onSubmit: handleSubmit,
    goToBack,
    initialValues,
    submitLoading,
  };
};
