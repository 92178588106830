import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useOnboardingKycTokenLazyQuery } from 'api/generated/graphql';
import { useError } from 'api/helpers';

export const useOnboardingKycTokenLazy = () => {
  const [, { data, refetch, loading, error }] = useOnboardingKycTokenLazyQuery({
    fetchPolicy: 'no-cache',
  });

  const getToken = useCallback(async () => {
    const res = await refetch();
    return res.data.onboardingKycToken?.token;
  }, [refetch]);

  const serverError = useError(error);

  return {
    token: data?.onboardingKycToken?.token,
    getToken,
    loading,
    error: serverError,
  };
};
