import { OrganizationType } from 'api/types/entity';
import {
  TypedForm,
  FormErrors as FormErrorsBase,
  ObjectSchema,
  string,
  mixed,
  object,
} from 'libs/form';
import { SelectType } from 'types/selectType';
import { YesOrNoSelectType, YesOrNoType } from 'types/yesOrNo';
import { Maybe } from 'yup';

export interface OrganizationalAndLegalFormFormType {
  organizationType: Maybe<SelectType<OrganizationType>>;
  organizationalAndLegalForm: string;
  isFinancialInstitution?: Maybe<YesOrNoSelectType>;
  isUnincorporatedStructure?: Maybe<YesOrNoSelectType>;
}

export const isCommercial = (v?: Maybe<SelectType<OrganizationType>>) =>
  v?.value === OrganizationType.Commercial;

export const getSchema = (): ObjectSchema<OrganizationalAndLegalFormFormType> =>
  object({
    organizationType: object({
      value: mixed<OrganizationType>()
        .oneOf(Object.values(OrganizationType))
        .required(),
      label: string().required(),
    })
      .default(null)
      .required(),

    isFinancialInstitution: object({
      value: mixed<YesOrNoType>().oneOf(Object.values(YesOrNoType)).required(),
      label: string().required(),
    })
      .default(null)
      .nullable()
      .when('organizationType', {
        is: isCommercial,
        then: (schema) => schema.required(),
      }),

    isUnincorporatedStructure: object({
      value: mixed<YesOrNoType>().oneOf(Object.values(YesOrNoType)).required(),
      label: string().required(),
    })
      .default(null)
      .nullable()
      .when('organizationType', {
        is: isCommercial,
        then: (schema) => schema.required(),
      }),

    organizationalAndLegalForm: string().max(200).required(),
  });

export const { Field, Form, useFormContext, Submit } =
  TypedForm<OrganizationalAndLegalFormFormType>();

export type FormErrors = FormErrorsBase<OrganizationalAndLegalFormFormType>;
