import { useCallback, useMemo } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useLegalClientGetLegalHeadFirstHeadLazyQuery } from 'api/generated/graphql';
import { useError } from 'api/helpers';

import { parseLegalClientLegalHeadFirstHeadEntity } from './helpers';

export const useLegalClientGetLegalHeadFirstHeadLazy = () => {
  const [query, { data, loading, error: apolloError }] =
    useLegalClientGetLegalHeadFirstHeadLazyQuery();

  const error = useError(apolloError);

  const getFirstHead = useCallback(
    async (legalId: string, legalHeadId: string, firstHeadId: string) => {
      const res = await query({
        variables: { id: legalId, legalHeadId, firstHeadId },
      });
      if (res.data?.legalClientGetLegalHeadFirstHead) {
        return parseLegalClientLegalHeadFirstHeadEntity(
          res.data?.legalClientGetLegalHeadFirstHead
        );
      }
      return null;
    },
    [query]
  );

  return {
    firstHead: useMemo(
      () =>
        data?.legalClientGetLegalHeadFirstHead
          ? parseLegalClientLegalHeadFirstHeadEntity(
              data.legalClientGetLegalHeadFirstHead
            )
          : undefined,
      [data?.legalClientGetLegalHeadFirstHead]
    ),
    loading,
    getFirstHead,
    error,
  };
};
