import { FileEntity } from 'api/types/entity';
import { FileUpload } from 'hooks/useFilesUpload';

import { OnboardingSignatureSamplesFormType } from '../../forms/onboardingSignatureSamplesForm';

export const getInitialData = (
  signaturesCardFiles: FileEntity[] | undefined
) => {
  if (!signaturesCardFiles) {
    return undefined;
  }

  const values: OnboardingSignatureSamplesFormType = {
    fileIds: signaturesCardFiles.map((v) => v.id),
  };

  const files: FileUpload[] | undefined = signaturesCardFiles.map(
    (v) =>
      new FileUpload({ name: v.name, size: v.size }, undefined, false, v.id)
  );
  return { values, files };
};
