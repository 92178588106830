import {
  CompanyIncomeSources,
  CompanyFinancingSources,
} from 'api/types/entity';
import { LegalClientSaveFinancingSourcesParam } from 'api/types/params';

import { CompanyFinancingSourcesFormType } from '../../forms/companyFinancingSourcesForm';

export const getSubmitData = (
  values: CompanyFinancingSourcesFormType
): LegalClientSaveFinancingSourcesParam => {
  const incomeSources = values.incomeSources.map((v) => v.value);
  const financingSources = values.financingSources.map((v) => v.value);

  const data: LegalClientSaveFinancingSourcesParam = {
    incomeSources,
    financingSources,
    otherIncomeSource: incomeSources.includes(CompanyIncomeSources.Other)
      ? values.otherIncomeSource
      : undefined,
    otherFinancingSource: financingSources.includes(
      CompanyFinancingSources.Other
    )
      ? values.otherFinancingSources
      : undefined,
    lastYearAuditFinancialReportFileIds: values.auditedFinancialReportFileIds,
    recommendationLetterFiles: values.recommendationLetterFileIds,
  };

  return data;
};
