// eslint-disable-next-line no-restricted-imports
import { CircularProgress as CircularProgressBase } from '@mui/material';

import { CircularProgressProps } from './types';

/**
 * CircularProgress component.
 */
export const CircularProgress = ({
  color = 'inherit',
  size = 24,
  ...rest
}: CircularProgressProps) => (
  <CircularProgressBase color={color} size={size} {...rest} />
);
