// eslint-disable-next-line no-restricted-imports
import {
  SaveFinancingSourcesDto_Input,
  MutationInput_ClientController_SaveFinancingSources_Input_BrokerRelationshipPurposes_Items as BrokerRelationshipPurposeApi,
  MutationInput_ClientController_SaveFinancingSources_Input_IncomeSources_Items as IncomeSourcesApi,
  MutationInput_ClientController_SaveFinancingSources_Input_PlannedOperationTypes_Items as PlannedOperationTypesApi,
} from 'api/generated/graphql';
import {
  BrokerRelationshipPurposeType,
  IncomeSourcesType,
  PlannedOperationType,
} from 'api/types/entity';
import { ClientSaveFinancingSourcesParam } from 'api/types/params';

import { parsePrice } from './parsePrice';

const broker: Record<
  BrokerRelationshipPurposeType,
  BrokerRelationshipPurposeApi
> = {
  [BrokerRelationshipPurposeType.Other]: BrokerRelationshipPurposeApi.Other,
  [BrokerRelationshipPurposeType.SecuritiesBrokerage]:
    BrokerRelationshipPurposeApi.SecuritiesBrokerage,
  [BrokerRelationshipPurposeType.TrustAssetManagement]:
    BrokerRelationshipPurposeApi.TrustAssetManagement,
};

const plannedOperationTypes: Record<
  PlannedOperationType,
  PlannedOperationTypesApi
> = {
  [PlannedOperationType.ForeignCurrencyTransactions]:
    PlannedOperationTypesApi.ForeignCurrencyTransactions,
  [PlannedOperationType.ForeignIssuersBondsTransactions]:
    PlannedOperationTypesApi.ForeignIssuersBondsTransactions,
  [PlannedOperationType.ForeignIssuersStockTransactions]:
    PlannedOperationTypesApi.ForeignIssuersStockTransactions,
  [PlannedOperationType.KazakhIssuersBondsTransactions]:
    PlannedOperationTypesApi.KazakhIssuersBondsTransactions,
  [PlannedOperationType.KazakhIssuersStockTransactions]:
    PlannedOperationTypesApi.KazakhIssuersStockTransactions,
  [PlannedOperationType.OptionsFuturesETCDerivatives]:
    PlannedOperationTypesApi.OptionsFuturesEtcDerivatives,
  [PlannedOperationType.Other]: PlannedOperationTypesApi.Other,
  [PlannedOperationType.RepoOperations]:
    PlannedOperationTypesApi.RepoOperations,
  [PlannedOperationType.SecuritiesTransferFromOtherBroker]:
    PlannedOperationTypesApi.SecuritiesTransferFromOtherBroker,
};
const incomeSources: Record<IncomeSourcesType, IncomeSourcesApi> = {
  [IncomeSourcesType.BusinessIncome]: IncomeSourcesApi.BusinessIncome,
  [IncomeSourcesType.ExpenseSavings]: IncomeSourcesApi.ExpenseSavings,
  [IncomeSourcesType.FutureIncome]: IncomeSourcesApi.FutureIncome,
  [IncomeSourcesType.GiftContract]: IncomeSourcesApi.GiftContract,
  [IncomeSourcesType.Inheritance]: IncomeSourcesApi.Inheritance,
  [IncomeSourcesType.InvestmentIncome]: IncomeSourcesApi.InvestmentIncome,
  [IncomeSourcesType.Loan]: IncomeSourcesApi.Loan,
  [IncomeSourcesType.Other]: IncomeSourcesApi.Other,
  [IncomeSourcesType.PropertySale]: IncomeSourcesApi.PropertySale,
  [IncomeSourcesType.Salary]: IncomeSourcesApi.Salary,
  [IncomeSourcesType.Savings]: IncomeSourcesApi.Savings,
};

export const parseSaveFinancingSourcesParam = (
  data: ClientSaveFinancingSourcesParam
): SaveFinancingSourcesDto_Input => ({
  otherBrokerRelationshipPurpose: data.otherBrokerRelationshipPurpose || null,
  otherIncomeSource: data.otherIncomeSource || null,
  otherPlannedOperationType: data.otherPlannedOperationType || null,
  incomeAmount: parsePrice(data.incomeAmount),
  plannedAssetAmount: parsePrice(data.plannedAssetAmount),
  incomeSourceFileIds: data.incomeSourceFileIds,
  incomeSources: data.incomeSources.map((v) => incomeSources[v]),
  brokerRelationshipPurposes: data.brokerRelationshipPurpose.map(
    (v) => broker[v]
  ),
  plannedOperationTypes: data.plannedOperationTypes.map(
    (v) => plannedOperationTypes[v]
  ),
});
