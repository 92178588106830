import { Input, InputProps } from 'libs/ui';

import { FieldType } from '../types';
import { useField } from '../useField';

export interface TextFieldProps
  extends FieldType,
    Omit<InputProps, 'name' | 'onChange' | 'onChangeText' | 'value'> {
  checkValue?: (v: string) => boolean;
}

/**
 * Component that abstracts and simplifies the usage of Form Field component
 * for managing form input fields. It renders an Input component with the provided props.
 */
export const TextField = ({
  name,
  allowSubmit,
  checkValue,
  ...props
}: TextFieldProps) => {
  const { field, helpers, inputProps } = useField<string | undefined>(name);

  return (
    <Input
      {...props}
      name={name}
      value={field.value ?? ''}
      onBlur={field.onBlur(name)}
      onChange={() => helpers.setError('')}
      onChangeText={(v) => {
        if (!checkValue || checkValue(v)) {
          helpers.setValue(v);
        }
      }}
      onClear={() => helpers.setValue('')}
      {...inputProps}
    />
  );
};
