import { useMemo } from 'react';

import { useTranslation } from 'libs/i18n';
import { Link, ROUTES } from 'libs/navigation';
import { Stack, Typography } from 'libs/ui';
import { ColorsLight } from 'libs/ui/theme';

import {
  LoginFormType,
  Field,
  Form,
  Submit,
  FormErrors,
  getSchema,
} from '../../forms/loginFormForm';

export interface LoginFormProps {
  onSubmit: (values: LoginFormType) => void;
  loading?: boolean;
  formError?: FormErrors;
}

export const LoginForm = ({ loading, formError, onSubmit }: LoginFormProps) => {
  const { t } = useTranslation();
  const scheme = useMemo(() => getSchema(t), [t]);

  return (
    <Form
      initialErrors={{ ...formError }}
      initialValues={{ rememberMe: true }}
      validationSchema={scheme}
      onSubmit={onSubmit}
    >
      <Stack spacing={48} spacingMobile={8}>
        <Stack spacing={24}>
          <Field.Text
            allowSubmit
            helperTextAbsolute
            label={t('auth.login.email.label')}
            name="email"
            placeholder={t('auth.login.email.placeholder')}
            type="email"
          />
          <Field.Password
            allowSubmit
            helperTextAbsolute
            label={t('auth.login.password.label')}
            name="password"
            placeholder={t('auth.login.password.placeholder')}
          />
        </Stack>
        <Stack spacing={20}>
          <Stack direction="row" justifyContent="space-between">
            {/* <ControlLabel
            control={<Field.Checkbox name="rememberMe" />}
            label={
              <Typography variant="captionMedium">
                {t('auth.login.rememberMe')}
              </Typography>
            }
          /> */}
            <Stack />
            <Link params={{}} to={ROUTES.restorePassword.fullPath}>
              <Typography color={ColorsLight.gray250} variant="captionMedium">
                {t('auth.login.forgotPassword')}
              </Typography>
            </Link>
          </Stack>

          <Stack spacing={20} spacingMobile={16}>
            <Submit label={t('auth.login.submitButton')} loading={loading} />
            <Stack alignItems="center">
              <Link params={{}} to={ROUTES.registration.fullPath}>
                <Typography variant="bodyBold">
                  {t('auth.login.regButton')}
                </Typography>
              </Link>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Form>
  );
};
