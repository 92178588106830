import { useState } from 'react';

import SumsubWebSdk from '@sumsub/websdk-react';
import { useTranslation } from 'libs/i18n';
import { Typography } from 'libs/ui';
import { useTheme } from 'libs/ui/theme';

import { BodyStyled, HelperTextBoxStyled } from './OnboardingKYC.styles';

interface OnboardingKYCProps {
  token: string;
  expirationHandler: () => Promise<string>;
}

interface StepInitiatedPayload {
  idDocSetType: string;
  types: string[];
  videoRequired?: string;
}
interface StepCompletedPayload {
  idDocSetType: string;
  idDocType: string;
}

const identityDocType = 'IDENTITY';

export const OnboardingKYC = ({
  token,
  expirationHandler,
}: OnboardingKYCProps) => {
  const { language } = useTranslation();
  const { mode } = useTheme();
  const { t } = useTranslation();

  const [showHelperText, setShowHelperText] = useState(false);

  return (
    <>
      {showHelperText && (
        <HelperTextBoxStyled>
          <Typography align="center" variant="captionSemiBold">
            {t('onboarding.kyc.helperText')}
          </Typography>
        </HelperTextBoxStyled>
      )}

      <BodyStyled>
        <SumsubWebSdk
          key={mode}
          accessToken={token}
          config={{
            lang: language,
            theme: mode,
          }}
          expirationHandler={expirationHandler}
          onMessage={(type, payload) => {
            if (type === 'idCheck.onStepInitiated') {
              const data = payload as StepInitiatedPayload;
              if (data.idDocSetType === identityDocType) {
                setShowHelperText(true);
              }
            } else if (type === 'idCheck.onStepCompleted') {
              const data = payload as StepCompletedPayload;
              if (data.idDocSetType === identityDocType) {
                setShowHelperText(false);
              }
            }
          }}
        />
      </BodyStyled>
    </>
  );
};
