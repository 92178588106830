import { OnboardingBiography } from 'modules/onboarding/common/components';

import { useBiography } from '../hooks';
import { PageComponentProps } from '../types';

export const BiographyStep = ({ canGoBack }: PageComponentProps) => {
  const { goToBack, initialValues, onSubmit, submitLoading } = useBiography();

  return (
    <OnboardingBiography
      canGoBack={canGoBack}
      initialValues={initialValues}
      submitLoading={submitLoading}
      onGoBack={goToBack}
      onSubmit={onSubmit}
    />
  );
};
