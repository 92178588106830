import { ColorType, ColorsDark, ColorsLight, PaletteMode } from '../../theme';
import { ChipVariant } from '../types';

export const TEXT_COLORS: Record<
  ChipVariant,
  Record<PaletteMode, ColorType>
> = {
  blue: {
    light: ColorsLight.blue250,
    dark: ColorsDark.blue250,
  },
  red: {
    light: ColorsLight.red200,
    dark: ColorsDark.red200,
  },
  yellow: {
    light: ColorsLight.yellow100,
    dark: ColorsDark.yellow100,
  },
  green: {
    light: ColorsLight.green100,
    dark: ColorsDark.green100,
  },
};

export const BG_COLORS: Record<ChipVariant, Record<PaletteMode, ColorType>> = {
  blue: {
    light: ColorsLight.blue50,
    dark: ColorsDark.blue50,
  },
  red: {
    light: ColorsLight.red50,
    dark: ColorsDark.red50,
  },
  yellow: {
    light: ColorsLight.yellow50,
    dark: ColorsDark.yellow50,
  },
  green: {
    light: ColorsLight.green50,
    dark: ColorsDark.green50,
  },
};
