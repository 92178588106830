import { useRef } from 'react';

import { useNavigate } from 'libs/navigation';

const URL_PARAM_NAME = 'token';

export const useUrlToken = () => {
  const { searchParams } = useNavigate();

  const token = useRef(searchParams.get(URL_PARAM_NAME)).current;

  return token;
};
