import { OnboardingRegistrationAddressFormType } from 'modules/onboarding/common/forms/onboardingRegistrationAddressForm';
import { useClientRegistrationAddress } from 'modules/onboarding/common/hooks';

import { useOnboardingContext } from './useOnboardingContext';

export const useOwnerRegistrationAddress = () => {
  const { goToBack, goToNext, data } = useOnboardingContext();

  const {
    countries,
    countriesLoading,
    initialValues,
    submitLoading,
    files,
    submitDisabled,
    onSubmit,
    onAddFiles,
    onRemoveFile,
  } = useClientRegistrationAddress(data.ownerClient!);

  const handleSubmit = async (
    values: OnboardingRegistrationAddressFormType
  ) => {
    if (values) {
      await onSubmit(values);
      goToNext();
    }
  };

  return {
    submitLoading,
    initialValues,
    countries,
    countriesLoading,
    files,
    submitDisabled,
    onAddFiles,
    onRemoveFile,
    onSubmit: handleSubmit,
    goToBack,
  };
};
