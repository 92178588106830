import { getColor } from 'helpers';
import { styled } from 'libs/ui/styled';
import { ColorsDark, ColorsLight } from 'libs/ui/theme';

export interface LayoutStyledProps {
  /**
   * filled - fills in the background color except for mobile
   * filled-all - fills in the background color
   */
  variant?: 'filled' | 'outlined' | 'filled-all';
}

export const LayoutStyled = styled('div')<LayoutStyledProps>(
  ({ variant = 'filled', theme }) => ({
    ...(variant === 'filled' && {
      [theme.breakpoints.up('mobile')]: {
        backgroundColor: theme.palette.background.default,
      },
      [theme.breakpoints.down('mobile')]: {
        backgroundColor: getColor(theme, {
          dark: ColorsDark.black,
          light: ColorsLight.white,
        }),
      },
    }),

    ...(variant === 'filled-all' && {
      backgroundColor: theme.palette.background.default,
    }),

    ...(variant === 'outlined' && {
      backgroundColor: getColor(theme, {
        dark: ColorsDark.black,
        light: ColorsLight.white,
      }),
    }),

    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',

    '& .layout-content': {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    },

    '& .layout-footer': {
      height: 72,
    },
  })
);
