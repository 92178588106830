import { Body } from 'components';
import { styled } from 'libs/ui/styled';

export const BodyStyled = styled(Body)(({ theme }) => ({
  paddingTop: 8,
  paddingBottom: 8,
  paddingLeft: 8,
  [theme.breakpoints.down('mobile')]: {
    marginLeft: -8,
  },
}));
