import { FC } from 'react';

import { Typography } from '../Typography';

import { TooltipStyled } from './Tooltip.styles';
import { TooltipProps } from './types';

/**
 * Tooltip component.
 */
export const Tooltip: FC<TooltipProps> = (props) => {
  const { type, title, children, arrow = true } = props;

  const preparedTitle =
    typeof title === 'string' ? (
      <Typography className="tooltip-typography" variant="caption">
        {title}
      </Typography>
    ) : (
      title
    );

  switch (type) {
    case 'click': {
      const { open, onClose } = props;

      return (
        <TooltipStyled
          disableFocusListener
          disableHoverListener
          disableTouchListener
          arrow={arrow}
          open={open}
          title={preparedTitle}
          onClose={onClose}
        >
          {children}
        </TooltipStyled>
      );
    }

    case 'hover':
    default:
      return (
        <TooltipStyled arrow={arrow} title={preparedTitle}>
          {children}
        </TooltipStyled>
      );
  }
};
