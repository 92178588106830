import { useRef } from 'react';

import { useClientSaveDebts } from 'api/requests';
import { ClientEntity, OccupationType } from 'api/types/entity';
import { useServerErrorNotify } from 'hooks';
import { useTranslation } from 'libs/i18n';

import { DebtsFormType } from '../forms/debtsForm';
import { getInitialData } from '../helpers/debts/getInitialData';
import { getSubmitData } from '../helpers/debts/getSubmitData';

export const useClientDebts = (client: ClientEntity) => {
  const {
    saveDebts: saveDebtsApi,
    error,
    loading: submitLoading,
  } = useClientSaveDebts();
  const { t } = useTranslation();

  const initialValues = useRef(getInitialData(client.debts, t)).current;

  const isEntrepreneur =
    client.biography?.occupation === OccupationType.entrepreneur;

  const saveDebts = async (values: DebtsFormType) => {
    const submitData = getSubmitData(values, isEntrepreneur);
    await saveDebtsApi(client.id, submitData);
  };

  useServerErrorNotify(error);

  return {
    saveDebts,
    isEntrepreneur,
    initialValues,
    submitLoading,
    error,
  };
};
