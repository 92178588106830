import { SvgIcon } from '../SvgIcon';
import { SvgProps } from '../types';

export const Logo = ({
  size = { width: 28, height: 44 },
  className,
}: SvgProps) => (
  <SvgIcon className={className} size={size}>
    <svg
      fill="none"
      height="44"
      viewBox="0 0 28 44"
      width="28"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.47369 41.2305L0 44V17.9818C0 15.0907 1.61008 12.4474 4.15839 11.1584L9.96576 8.22025V33.8615C9.96576 36.9836 8.22699 39.8382 5.47518 41.2305H5.47369Z"
        fill="url(#paint0_linear_1409_84)"
      />
      <path
        d="M9.96415 25.1685L7.21085 21.8792C3.54477 17.4985 4.89748 10.7845 9.96415 8.22028V25.1685Z"
        fill="#04ACF4"
        opacity="0.5"
      />
      <path
        d="M9.96436 8.21881V25.1685L17.7215 35.7813V18.8012L9.96436 8.21881Z"
        fill="#95D9E5"
      />
      <path
        d="M24.0992 32.5528L17.7217 35.7798V10.1401C17.7217 7.01799 19.4605 4.16339 22.2123 2.77106L27.6874 6.10352e-05V26.6673C27.6874 29.1616 26.2988 31.4417 24.1007 32.5543L24.0992 32.5528Z"
        fill="url(#paint1_linear_1409_84)"
      />
      <path
        d="M17.7217 35.7815V18.8013L20.4855 22.1135C24.1485 26.5017 22.7899 33.2157 17.7217 35.7815Z"
        fill="#04ACF4"
        opacity="0.5"
      />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_1409_84"
          x1="4.98288"
          x2="4.98288"
          y1="8.21873"
          y2="44"
        >
          <stop stopColor="#95D9E5" />
          <stop offset="0.13" stopColor="#8CD6E5" />
          <stop offset="0.34" stopColor="#73CEE8" />
          <stop offset="0.6" stopColor="#4BC2EC" />
          <stop offset="0.91" stopColor="#15B1F2" />
          <stop offset="1" stopColor="#04ACF4" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint1_linear_1409_84"
          x1="22.7046"
          x2="22.7046"
          y1="35.7813"
          y2="6.10352e-05"
        >
          <stop stopColor="#95D9E5" />
          <stop offset="0.13" stopColor="#8CD6E5" />
          <stop offset="0.34" stopColor="#73CEE8" />
          <stop offset="0.6" stopColor="#4BC2EC" />
          <stop offset="0.91" stopColor="#15B1F2" />
          <stop offset="1" stopColor="#04ACF4" />
        </linearGradient>
      </defs>
    </svg>
  </SvgIcon>
);
