import { useTranslation } from 'libs/i18n';
import { Debts } from 'modules/onboarding/common/components';

import { useCompanyDebs } from '../hooks';
import { PageComponentProps } from '../types';

export const CompanyDebtsStep = ({ canGoBack }: PageComponentProps) => {
  const { goToBack, initialValues, onSubmit, submitLoading } = useCompanyDebs();
  const { t } = useTranslation();

  return (
    <Debts
      isEntrepreneur
      canGoBack={canGoBack}
      initialValues={initialValues}
      submitLoading={submitLoading}
      subtitle={t('onboarding.companyDebts.subtitle')}
      onGoBack={goToBack}
      onSubmit={onSubmit}
    />
  );
};
