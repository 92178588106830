import { FormContainer, FormContainerStepProps } from 'components';
import { useTranslation } from 'libs/i18n';

import {
  HeadTypeInfoFormType,
  FormErrors,
  getSchema,
} from '../../forms/headTypeInfoForm';

import { FormContent, FormContentProps } from './FormContent';

interface Props extends FormContentProps, FormContainerStepProps {
  onSubmit: (values: HeadTypeInfoFormType) => void;
  canGoBack?: boolean;
  onGoBack?: () => void;
  formError?: FormErrors;
  submitLoading?: boolean;
  initialValues?: HeadTypeInfoFormType;
  submitDisabled?: boolean;
  needMemberConfirmationFiles?: boolean;
}

export const HeadTypeInfo = ({
  canGoBack,
  onGoBack,
  submitLoading,
  submitDisabled,
  formError,
  step,
  initialValues,
  needMemberConfirmationFiles,
  onSubmit,
  ...formProps
}: Props) => {
  const { t } = useTranslation();
  const schema = getSchema(needMemberConfirmationFiles);
  return (
    <FormContainer
      canGoBack={canGoBack}
      initialErrors={formError}
      initialValues={initialValues}
      step={step}
      submitDisabled={submitDisabled}
      submitLoading={submitLoading}
      subTitle={t('onboarding.headTypeInfo.subtitle')}
      title={t('onboarding.headTypeInfo.title')}
      validationSchema={schema}
      onGoBack={onGoBack}
      onSubmit={onSubmit}
    >
      <FormContent
        {...formProps}
        needMemberConfirmationFiles={needMemberConfirmationFiles}
      />
    </FormContainer>
  );
};
