import { PropsWithChildren } from 'react';

import { FormContainer, FormContainerProps } from 'components/FormContainer';
import { PasswordValidation } from 'helpers';
import { PasswordField } from 'libs/form/fields/PasswordField';
import { useTranslation } from 'libs/i18n';
import { Stack } from 'libs/ui';

import { PasswordRules } from './PasswordRules';

interface Props<Values extends object> extends FormContainerProps<Values> {
  passwordFieldName: Extract<keyof Values, string>;
  passwordValidation: PasswordValidation;
}

export const PasswordFormBody = <Values extends object>({
  passwordValidation,
  passwordFieldName,
  children,
  ...formProps
}: Props<Values> & PropsWithChildren) => {
  const { t } = useTranslation();

  return (
    <FormContainer {...formProps}>
      <Stack spacing={32}>
        <PasswordField
          allowSubmit
          fullWidth
          autoComplete="new-password"
          label={t('components.passwordForm.password.label')}
          name={passwordFieldName}
          placeholder={t('components.passwordForm.password.placeholder')}
        />
        <PasswordRules
          passwordFieldName={passwordFieldName}
          passwordValidation={passwordValidation}
        />

        {children}
      </Stack>
    </FormContainer>
  );
};
