import { RegistrationTwoFactor } from '../components/RegistrationTwoFactor';
import { RegistrationTwoFactorFormType } from '../forms/registrationTwoFactorForm';
import { useRegistrationTwoFactor } from '../hooks';
import { RegistrationStepProps } from '../types';

export const RegistrationTwoFactorStep = ({
  canGoBack,
  onGoBack,
  onGoNext,
}: RegistrationStepProps) => {
  const { formError, onSubmit, qrData, qrLoading, submitLoading } =
    useRegistrationTwoFactor();

  const handleSubmit = async (values: RegistrationTwoFactorFormType) => {
    if (await onSubmit(values)) {
      onGoNext();
    }
  };

  return (
    <RegistrationTwoFactor
      canGoBack={canGoBack}
      formError={formError}
      qrData={qrData}
      qrLoading={qrLoading}
      submitLoading={submitLoading}
      onGoBack={onGoBack}
      onSubmit={handleSubmit}
    />
  );
};
