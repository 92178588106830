import { useRef } from 'react';

import { useLegalClientSaveLicenseInfo } from 'api/requests';
import { useFilesUpload, useServerErrorNotify } from 'hooks';
import { useTranslation } from 'libs/i18n';
import { useOnboardingCountries } from 'modules/onboarding/common/hooks';

import { CompanyLicenseFormType } from '../forms/companyLicenseForm';
import { getInitialData, getSubmitData } from '../helpers/companyLicense';

import { useOnboardingContext } from './useOnboardingContext';

export const useCompanyLicense = () => {
  const { goToBack, goToNext, data } = useOnboardingContext();
  const { loading, error, saveLicense } = useLegalClientSaveLicenseInfo();

  const { countries, loading: countriesLoading } = useOnboardingCountries();

  const { t } = useTranslation();

  const initialData = useRef(getInitialData(data.legalClient!, t)).current;
  const initialValues = useRef(initialData.values).current;

  const onSubmit = async (values: CompanyLicenseFormType) => {
    if (values) {
      const submitData = getSubmitData(values);
      const res = await saveLicense(data.legalClient!.id, submitData);

      if (res) {
        goToNext();
      }
    }
  };

  const {
    files,
    isLoadingFile: submitDisabled,
    onRemoveFile,
    onAddFiles,
    onRemoveFilesByServerIds,
  } = useFilesUpload(initialData.files);

  useServerErrorNotify(error);

  return {
    onSubmit,
    goToBack,
    submitLoading: loading,
    initialValues,
    submitDisabled,
    countries,
    countriesLoading,
    files,
    onAddFiles,
    onRemoveFile,
    onRemoveFilesByServerIds,
  };
};
