import { useTranslation } from 'libs/i18n';

import { Field } from '../../../forms/companyNameForm';

export interface FormContentProps {
  showEng?: boolean;
}

export const FormContent = ({ showEng }: FormContentProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Field.Text
        label={t('onboarding.companyName.fullName.label')}
        name="fullName"
        placeholder={t('onboarding.companyName.fullName.placeholder')}
      />
      {showEng && (
        <Field.Text
          showOptionalMark
          label={t('onboarding.companyName.fullNameEng.label')}
          name="fullNameEng"
          placeholder={t('onboarding.companyName.fullNameEng.placeholder')}
        />
      )}

      <Field.Text
        showOptionalMark
        label={t('onboarding.companyName.shortName.label')}
        name="shortName"
        placeholder={t('onboarding.companyName.shortName.placeholder')}
      />
      {showEng && (
        <Field.Text
          showOptionalMark
          label={t('onboarding.companyName.shortNameEng.label')}
          name="shortNameEng"
          placeholder={t('onboarding.companyName.shortNameEng.placeholder')}
        />
      )}
    </>
  );
};
