import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import {
  OnboardingLegalClientDocument,
  useLegalClientLegalHeadSaveCompanyNameMutation,
} from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { LegalClientCreateLegalHeadSaveParam } from 'api/types/params';

import { parseLegalClientLegalHeadEntity } from './helpers';

export const useLegalClientLegalHeadSaveCompanyName = () => {
  const [handle, { data, loading, error: apolloError }] =
    useLegalClientLegalHeadSaveCompanyNameMutation({
      refetchQueries: [OnboardingLegalClientDocument],
      awaitRefetchQueries: true,
    });

  const error = useError(apolloError);

  return {
    data: data?.legalClientLegalHeadSaveCompanyName
      ? parseLegalClientLegalHeadEntity(
          data.legalClientLegalHeadSaveCompanyName
        )
      : undefined,
    loading,
    error,
    saveCompanyName: useCallback(
      async (
        id: string,
        legalHeadId: string,
        param: LegalClientCreateLegalHeadSaveParam
      ) => {
        const res = await handle({
          variables: {
            id,
            legalHeadId,
            input: {
              fullName: param.fullName,
              shortName: param.shortName,
            },
          },
        });
        return res.data?.legalClientLegalHeadSaveCompanyName
          ? parseLegalClientLegalHeadEntity(
              res.data.legalClientLegalHeadSaveCompanyName
            )
          : undefined;
      },
      [handle]
    ),
  };
};
