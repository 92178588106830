// eslint-disable-next-line no-restricted-imports
import { useLegalClientHeadQuery } from 'api/generated/graphql';
import { useError } from 'api/helpers';

import { parseLegalClientHeadEntity } from './helpers';

export const useLegalClientHead = (headId: string) => {
  const {
    data,
    loading,
    error: apolloError,
    refetch,
  } = useLegalClientHeadQuery({ variables: { id: headId } });

  const error = useError(apolloError);

  return {
    head: data?.legalClientHead
      ? parseLegalClientHeadEntity(data.legalClientHead)
      : undefined,
    loading,
    refetch,
    error,
  };
};
