import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useClientSaveDebtsMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { SaveDebtsParam } from 'api/types/params';

import { parseClientDebtsParams, parseClientEntity } from '../helpers';

export const useClientSaveDebts = () => {
  const [saveDebts, { data, loading, error: apolloError }] =
    useClientSaveDebtsMutation();

  const error = useError(apolloError);

  return {
    data: data?.clientSaveDebts
      ? parseClientEntity(data.clientSaveDebts)
      : undefined,
    loading,
    error,
    saveDebts: useCallback(
      async (id: string, params: SaveDebtsParam) => {
        const res = await saveDebts({
          variables: {
            id,
            input: parseClientDebtsParams(params),
          },
        });
        return res.data?.clientSaveDebts
          ? parseClientEntity(res.data.clientSaveDebts)
          : undefined;
      },
      [saveDebts]
    ),
  };
};
