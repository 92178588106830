import { OrderSubmissionContactEntity } from 'api/types/entity';
import { TFunc } from 'libs/i18n';

import {
  getTypes,
  initialOrderSubmissionContactType,
} from '../../forms/orderSubmissionContacts';

export const getInitialData = (
  t: TFunc,
  orderSubmissionContacts?: OrderSubmissionContactEntity[]
) =>
  orderSubmissionContacts
    ? {
        orderSubmissionContacts: orderSubmissionContacts.map(
          ({ type, value }) => {
            const formContactType = getTypes(t).find(
              ({ value }) => type === value
            );

            if (!formContactType) {
              throw new Error(`Unknown contact type: ${type}`);
            }

            return {
              type: formContactType,
              value,
            };
          }
        ),
      }
    : {
        orderSubmissionContacts: [
          {
            type: initialOrderSubmissionContactType,
            value: '',
          },
        ],
      };
