import {
  TypedForm,
  FormErrors as FormErrorsBase,
  ObjectSchema,
} from 'libs/form';
import {
  BaseNameFormType,
  getBaseSchema,
} from 'modules/onboarding/common/forms/companyNameForm';

export interface NameFormType extends BaseNameFormType {}

export const getSchema = (): ObjectSchema<NameFormType> => getBaseSchema();

export const { Field, Form, useFormContext, Submit } =
  TypedForm<NameFormType>();

export type FormErrors = FormErrorsBase<NameFormType>;
