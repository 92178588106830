import { Switch, SwitchProps } from 'libs/ui';

import { FieldType } from '../types';
import { useField } from '../useField';

export interface SwitchFieldProps
  extends FieldType,
    Omit<SwitchProps, 'onChange' | 'checked'> {}

/**
 * Switch field
 */
export const SwitchField = ({ name, ...props }: SwitchFieldProps) => {
  const { field, helpers } = useField<boolean | undefined>(name);

  return (
    <Switch
      {...props}
      checked={field.value}
      onChange={(v) => helpers.setValue(v)}
    />
  );
};
