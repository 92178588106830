// eslint-disable-next-line no-restricted-imports
import { Typography } from '@mui/material';

import { styled } from '../styled';

import { TypographyProps } from './types';

export const TypographyStyled = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'maxLine',
})<TypographyProps>(({ theme, maxLine, color }) => ({
  color: color ?? theme.palette.text.primary,
  ...(maxLine !== undefined && {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': maxLine.toString(),
    '-webkit-box-orient': 'vertical',
  }),
}));
