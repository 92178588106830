import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useLegalClientSaveOrderSubmissionContactsMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { LegalClientSaveOrderSubmissionContactParam } from 'api/types/params';

import {
  parseLegalClient,
  parseLegalClientOrderSubmissionContactsParam,
} from './helpers';

export const useLegalClientSaveOrderSubmissionContacts = () => {
  const [handle, { data, loading, error: apolloError }] =
    useLegalClientSaveOrderSubmissionContactsMutation();

  const error = useError(apolloError);

  return {
    data: data?.legalClientSaveOrderSubmissionContacts
      ? parseLegalClient(data.legalClientSaveOrderSubmissionContacts)
      : undefined,
    loading,
    error,
    saveContacts: useCallback(
      async (
        id: string,
        params: LegalClientSaveOrderSubmissionContactParam[]
      ) => {
        const res = await handle({
          variables: {
            id,
            input: parseLegalClientOrderSubmissionContactsParam(params),
          },
        });
        return res?.data?.legalClientSaveOrderSubmissionContacts
          ? parseLegalClient(res.data.legalClientSaveOrderSubmissionContacts)
          : undefined;
      },
      [handle]
    ),
  };
};
