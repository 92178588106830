// eslint-disable-next-line no-restricted-imports
import { ActivityTypeFragment } from 'api/generated/graphql';
import { LegalClientActivityTypeEntity } from 'api/types/entity/legal/legalClientActivityTypeEntity';
import { lodash } from 'helpers';
import { Maybe } from 'types/maybe';

export const parseLegalClientActivityTypes = (
  activityTypes: Maybe<Maybe<ActivityTypeFragment>[]> | undefined
): LegalClientActivityTypeEntity[] | undefined => {
  if (!activityTypes?.length) {
    return undefined;
  }

  return lodash.compact(activityTypes).map(({ name, code }) => ({
    name,
    code: code ?? undefined,
  }));
};
