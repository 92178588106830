// eslint-disable-next-line no-restricted-imports
import {
  FetchResult,
  Observable,
  Operation,
  fromPromise,
} from '@apollo/client';
// eslint-disable-next-line no-restricted-imports
import { onError } from '@apollo/client/link/error';
import { LoginData } from 'api/types/data';
import { ServerError } from 'api/types/error';

import { getAuthorizationData } from './getAuthorizationData';
import { tokenStorage } from './tokenStorage';

/**
 * Class representing token refresh status.
 */
class TokenPending {
  /** Indicates if a token refresh is in progress. */
  isRefreshing = false;

  /** An array of pending request callbacks. */
  pendingRequests: VoidFunction[] = [];

  operations: Operation[] = [];

  /**
   * Resolves all pending requests and resets the refresh status.
   */
  resolvePendingRequests() {
    this.pendingRequests.forEach((callback) => callback());
    this.operations = [];
    this.pendingRequests = [];
    this.isRefreshing = false;
  }

  pendingRequestsPush(operation: Operation, v: VoidFunction) {
    if (!this.operations.includes(operation)) {
      this.operations.push(operation);
      this.pendingRequests.push(v);
    }
  }

  /**
   * Clears all pending requests, resets the refresh status, clears token storage
   */
  catch() {
    this.pendingRequests = [];
    this.operations = [];
    this.isRefreshing = false;
    tokenStorage.clear();
  }

  /**
   * Clears all pending requests, resets the refresh status, clears token storage, and reloads the page.
   */
  catchAndReload() {
    this.catch();
    window.location.replace('/');
  }
}

/** Instance of TokenPending class. */
const tokenPending = new TokenPending();

/**
 * Creates Apollo Client middleware for handling token refresh.
 * @param {Function} getNewAccessToken - A function that returns a Promise to fetch a new access token.
 * @returns {Function} - The Apollo Client middleware function.
 */
export const getRefreshTokenMiddleware = (
  getNewAccessToken: (token: string) => Promise<LoginData | undefined>
) =>
  onError(({ graphQLErrors, operation, forward }) => {
    const errors = graphQLErrors ?? [];
    let forwardBuf: Observable<void | Observable<FetchResult>>;

    for (const err of errors) {
      const responseJson: ServerError | undefined = err.extensions
        ?.responseJson as ServerError;

      switch (responseJson?.status) {
        case 401:
          if (
            tokenPending.isRefreshing &&
            operation.operationName === 'RefreshToken'
          ) {
            tokenPending.catchAndReload();
            return undefined;
          }

          if (!tokenPending.isRefreshing) {
            tokenPending.isRefreshing = true;
            const refreshToken = tokenStorage.getRefreshToken();
            tokenPending.operations.push(operation);
            if (!refreshToken) {
              tokenPending.catch();
              return undefined;
            }
            forwardBuf = fromPromise(
              getNewAccessToken(refreshToken)
                .then((data) => {
                  if (!data) {
                    tokenPending.catchAndReload();
                    return;
                  }

                  tokenStorage.login(data);
                  tokenPending.resolvePendingRequests();
                })
                .then(() => forward(operation))
                .catch(tokenPending.catchAndReload)
            ).filter((value) => Boolean(value));
          } else {
            // If a token refresh is already in progress, add the request to the pending list
            forwardBuf = fromPromise(
              new Promise((resolve) => {
                tokenPending.pendingRequestsPush(operation, () => resolve());
              })
            );
          }
          return forwardBuf.flatMap(() => {
            const oldHeaders = operation.getContext().headers;
            // modify the operation context with a new token
            operation.setContext({
              headers: {
                ...oldHeaders,
                ...getAuthorizationData(),
              },
            });

            // Retry the request, returning the new observable
            return forward(operation);
          });
        default:
      }
    }
    return undefined;
  });
