import { useTranslation } from 'libs/i18n';
import { OnboardingRegistrationAddress } from 'modules/onboarding/common/components';

import { useRegistrationAddress } from '../hooks';
import { useOnboardingContext } from '../hooks/useOnboardingContext';
import { PageComponentProps } from '../types';

export const RegistrationAddressStep = ({ canGoBack }: PageComponentProps) => {
  const {
    countries,
    countriesLoading,
    initialValues,
    submitLoading,
    submitDisabled,
    files,
    onSubmit,
    goToBack,
    onAddFiles,
    onRemoveFile,
  } = useRegistrationAddress();

  const { step } = useOnboardingContext();

  const { t } = useTranslation();

  return (
    <OnboardingRegistrationAddress
      enabledFiles
      canGoBack={canGoBack}
      countries={countries}
      countriesLoading={countriesLoading}
      enabledPlaceOfBirth={false}
      files={files}
      initialValues={initialValues}
      step={step}
      submitDisabled={submitDisabled}
      submitLoading={submitLoading}
      subTitle={t(
        'onboarding.legalClientFirstHead.registrationAddress.subtitle'
      )}
      title={t('onboarding.legalClientHead.registrationAddress.title')}
      onAddFiles={onAddFiles}
      onGoBack={goToBack}
      onRemoveFile={onRemoveFile}
      onSubmit={onSubmit}
    />
  );
};
