import { useServerErrorNotify } from 'hooks';
import { DebtsFormType } from 'modules/onboarding/common/forms/debtsForm';
import { useClientDebts } from 'modules/onboarding/common/hooks';

import { useOnboardingContext } from './useOnboardingContext';

export const useOwnerDebts = () => {
  const { goToBack, goToNext, data } = useOnboardingContext();
  const { saveDebts, error, submitLoading, initialValues, isEntrepreneur } =
    useClientDebts(data.ownerClient!);

  const onSubmit = async (values: DebtsFormType) => {
    if (values) {
      await saveDebts(values);
      goToNext();
    }
  };

  useServerErrorNotify(error);

  return {
    onSubmit,
    goToBack,
    isEntrepreneur,
    initialValues,
    submitLoading,
  };
};
