import { useLegalClientBeneficiary } from 'api/requests/onboarding/legal/beneficiary';
import { KYCStatus } from 'api/types/data';
import { LegalClientOnboardingStatusType } from 'api/types/entity';
import { useOnboardingKyc } from 'modules/onboarding/common/hooks';

export const useKyc = (clientId: string) => {
  const { beneficiary, refetch } = useLegalClientBeneficiary(clientId);

  const status =
    beneficiary?.legalClientOnboardingStatus ===
    LegalClientOnboardingStatusType.AccountOpeningDenied
      ? KYCStatus.error
      : beneficiary?.kyc?.status;

  const { expirationHandler, loading, token } = useOnboardingKyc({
    onSuccess: () => {},
    refetch,
    status,
  });

  return {
    loading: loading && !token,
    token,
    status,
    expirationHandler,
  };
};
