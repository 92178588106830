// eslint-disable-next-line no-restricted-imports
import {
  Query_ClientController_GetById_Kyc_Status as KYCStatusApi,
  Query_ClientController_GetById_Representative_Kyc_Status as RepresentativeKYCStatusApi,
} from 'api/generated/graphql';
import { KYCStatus } from 'api/types/data';
import { KycEntity } from 'api/types/entity';

type ApiStatus = KYCStatusApi | RepresentativeKYCStatusApi;

const statuses: Record<ApiStatus, KYCStatus> = {
  [KYCStatusApi.Approved]: KYCStatus.success,
  [KYCStatusApi.Pending]: KYCStatus.progress,
  [KYCStatusApi.Rejected]: KYCStatus.error,
  [KYCStatusApi.NotStarted]: KYCStatus.notStarted,
};

interface KycProps {
  status?: ApiStatus | null;
}

export const parseKyc = (kyc?: KycProps | null): KycEntity => ({
  status: kyc?.status ? statuses[kyc.status] : KYCStatus.notStarted,
});
