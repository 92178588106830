import {
  BrokerRelationshipPurposeType,
  ClientEntity,
  IncomeSourcesType,
  PlannedOperationType,
} from 'api/types/entity';
import { FileUpload } from 'hooks/useFilesUpload';
import { TFunc, TranslationPath } from 'libs/i18n';

import { FinancingSourcesFormType } from '../../forms/financingSourcesForm';

export const IncomeSourcesPath: Record<IncomeSourcesType, TranslationPath> = {
  [IncomeSourcesType.BusinessIncome]:
    'onboarding.financingSources.businessIncome',
  [IncomeSourcesType.ExpenseSavings]:
    'onboarding.financingSources.expenseSavings',
  [IncomeSourcesType.FutureIncome]: 'onboarding.financingSources.futureIncome',
  [IncomeSourcesType.GiftContract]: 'onboarding.financingSources.giftContract',
  [IncomeSourcesType.Inheritance]: 'onboarding.financingSources.inheritance',
  [IncomeSourcesType.InvestmentIncome]:
    'onboarding.financingSources.investmentIncome',
  [IncomeSourcesType.Loan]: 'onboarding.financingSources.loan',
  [IncomeSourcesType.Other]: 'onboarding.financingSources.other.label',
  [IncomeSourcesType.PropertySale]: 'onboarding.financingSources.propertySale',
  [IncomeSourcesType.Salary]: 'onboarding.financingSources.salary',
  [IncomeSourcesType.Savings]: 'onboarding.financingSources.savings',
};

export const BrokerRelationshipPurposePath: Record<
  BrokerRelationshipPurposeType,
  TranslationPath
> = {
  [BrokerRelationshipPurposeType.SecuritiesBrokerage]:
    'onboarding.financingSources.securitiesBrokerage',
  [BrokerRelationshipPurposeType.TrustAssetManagement]:
    'onboarding.financingSources.trustAssetManagement',
  [BrokerRelationshipPurposeType.Other]:
    'onboarding.financingSources.other.label',
};

export const PlannedOperationTypePath: Record<
  PlannedOperationType,
  TranslationPath
> = {
  [PlannedOperationType.ForeignCurrencyTransactions]:
    'onboarding.financingSources.foreignCurrencyTransactions',
  [PlannedOperationType.ForeignIssuersStockTransactions]:
    'onboarding.financingSources.foreignIssuersStockTransactions',
  [PlannedOperationType.ForeignIssuersBondsTransactions]:
    'onboarding.financingSources.foreignIssuersBondsTransactions',
  [PlannedOperationType.KazakhIssuersBondsTransactions]:
    'onboarding.financingSources.kazakhIssuersBondsTransactions',
  [PlannedOperationType.KazakhIssuersStockTransactions]:
    'onboarding.financingSources.kazakhIssuersStockTransactions',
  [PlannedOperationType.OptionsFuturesETCDerivatives]:
    'onboarding.financingSources.optionsFuturesETCDerivatives',
  [PlannedOperationType.RepoOperations]:
    'onboarding.financingSources.repoOperations',
  [PlannedOperationType.SecuritiesTransferFromOtherBroker]:
    'onboarding.financingSources.securitiesTransferFromOtherBroker',

  [PlannedOperationType.Other]: 'onboarding.financingSources.other.label',
};

export const getInitialData = (client: ClientEntity, t: TFunc) => {
  const values: FinancingSourcesFormType | undefined = client.financingSources
    ? {
        ...client.financingSources,
        incomeSourceFileIds: client.financingSources.incomeSourceFile.map(
          (v) => v.id
        ),

        incomeSources: client.financingSources.incomeSources?.map((v) => ({
          value: v,
          label: t(IncomeSourcesPath[v]),
        })),

        brokerRelationshipPurpose:
          client.financingSources.brokerRelationshipPurpose?.map((v) => ({
            value: v,
            label: t(BrokerRelationshipPurposePath[v]),
          })),

        plannedOperationTypes:
          client.financingSources.plannedOperationTypes?.map((v) => ({
            value: v,
            label: t(PlannedOperationTypePath[v]),
          })),
      }
    : undefined;

  const files: FileUpload[] | undefined =
    client.financingSources?.incomeSourceFile.map(
      (v) =>
        new FileUpload({ name: v.name, size: v.size }, undefined, false, v.id)
    );

  return { values, files };
};
