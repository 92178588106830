// eslint-disable-next-line no-restricted-imports
import { BaseStateRegistrationFragment } from 'api/generated/graphql';
import { LegalClientBaseStateRegistrationEntity } from 'api/types/entity/legal/legalClientStateRegistrationEntity';
import { Maybe } from 'types/maybe';

export const parseLegalClientBranchStateRegistration = (
  stateRegistration?: Maybe<BaseStateRegistrationFragment>
): LegalClientBaseStateRegistrationEntity | undefined => {
  if (!stateRegistration) {
    return undefined;
  }

  return {
    initialStateRegistrationDate: new Date(
      stateRegistration.initialStateRegistrationDate
    ),
    registrationDocumentName: stateRegistration.registrationDocumentName,
    registrationAuthorityName: stateRegistration.registrationAuthorityName,
    registrationNumber: stateRegistration.registrationNumber,
    stateRegistrationConfirmationDocument: {
      dateOfIssue: new Date(
        stateRegistration.stateRegistrationConfirmationDocument.dateOfIssue
      ),
    },
  };
};
