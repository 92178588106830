import {
  TypedForm,
  FormErrors as FormErrorsBase,
  ObjectSchema,
  string,
  object,
  array,
  date,
} from 'libs/form';
import { TFunc } from 'libs/i18n';
import {
  getToday,
  getTodayWithResetHours,
} from 'modules/onboarding/common/helpers/getToday';

export const MAX_FILES = 10;

export interface OnboardingPowerOfAttorneyFormType {
  documentNumber?: string;
  dateOfIssue: Date;
  dateOfExpiration: Date;
  fileIds: string[];
}

export const getSchema = (
  t: TFunc
): ObjectSchema<OnboardingPowerOfAttorneyFormType> =>
  object({
    documentNumber: string().max(200),
    dateOfIssue: date().max(getToday(), t('validation.maxDate')).required(),
    dateOfExpiration: date()
      .min(getTodayWithResetHours(), t('validation.minDate'))
      .required(),
    fileIds: array().of(string().required()).required().min(1).max(MAX_FILES),
  });

export const { Field, Form, useFormContext, Submit } =
  TypedForm<OnboardingPowerOfAttorneyFormType>();

export type FormErrors = FormErrorsBase<OnboardingPowerOfAttorneyFormType>;
