import { useRef } from 'react';

import { useClientSaveSignaturesCardFiles } from 'api/requests';
import { ClientEntity } from 'api/types/entity';
import { useFilesUpload } from 'hooks';

import { OnboardingSignatureSamplesFormType } from '../forms/onboardingSignatureSamplesForm';
import { getInitialData } from '../helpers/signatureSamples';

export const useClientSignatureSamples = (client: ClientEntity) => {
  const {
    saveSignatures,
    loading: submitLoading,
    error,
  } = useClientSaveSignaturesCardFiles();
  const initialData = useRef(
    getInitialData(client.signaturesCardFiles)
  ).current;
  const initialValues = useRef(initialData?.values).current;

  const {
    files,
    isLoadingFile: submitDisabled,
    onRemoveFile,
    onAddFiles,
  } = useFilesUpload(initialData?.files);

  const onSubmit = async (values: OnboardingSignatureSamplesFormType) => {
    if (values) {
      await saveSignatures(client.id, {
        signaturesCardFileIds: values.fileIds,
      });
    }
  };

  return {
    onSubmit,
    files,
    error,
    initialValues,
    onAddFiles,
    onRemoveFile,
    submitLoading,
    submitDisabled,
  };
};
