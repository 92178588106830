import { useRef } from 'react';

import { useLegalClientSaveAddresses } from 'api/requests';
import { useServerErrorNotify } from 'hooks';
import { BaseOnboardingRegistrationAddressFormType } from 'modules/onboarding/common/forms/onboardingRegistrationAddressForm';
import { useOnboardingCountries } from 'modules/onboarding/common/hooks';

import {
  getInitialData,
  getSubmitData,
} from '../helpers/companyRegistrationAddress';

import { useOnboardingContext } from './useOnboardingContext';

export const useCompanyRegistrationAddress = () => {
  const {
    error,
    loading: submitLoading,
    saveAddresses,
  } = useLegalClientSaveAddresses();

  const { goToBack, goToNext, data } = useOnboardingContext();

  const { countries, loading: countriesLoading } = useOnboardingCountries();

  const initialValues = useRef(
    getInitialData(data.legalClient!)?.values
  ).current;

  const onSubmit = async (
    values: BaseOnboardingRegistrationAddressFormType
  ) => {
    if (values) {
      const submitData = getSubmitData(values);
      await saveAddresses(data.legalClient!.id, submitData);
      goToNext();
    }
  };

  useServerErrorNotify(error);

  return {
    onSubmit,
    initialValues,
    countries,
    countriesLoading,
    submitLoading,
    goToBack,
  };
};
