import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useLoginWithOtpMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { LoginData } from 'api/types/data';

export const useLoginWithOtp = () => {
  const [handle, { data, loading, error: apolloError }] =
    useLoginWithOtpMutation();

  const error = useError(apolloError);

  return {
    data: data?.loginWithOtp as LoginData | undefined,
    loading,
    error,
    loginWithOtp: useCallback(
      async (otp: string) => {
        const res = await handle({ variables: { otp } });
        return res.data?.loginWithOtp as LoginData | undefined;
      },
      [handle]
    ),
  };
};
