import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useClientSaveWithdrawalAccountsMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { SaveWithdrawalAccountsParam } from 'api/types/params';
import { parseCurrencyParam } from 'helpers/parseCurrencyParam';

import { parseClientEntity } from '../helpers';

export const useClientSaveWithdrawalAccounts = () => {
  const [saveWithdrawalAccounts, { data, loading, error: apolloError }] =
    useClientSaveWithdrawalAccountsMutation();

  const error = useError(apolloError);

  return {
    data: data?.clientSaveWithdrawalAccounts
      ? parseClientEntity(data.clientSaveWithdrawalAccounts)
      : undefined,
    loading,
    error,
    saveWithdrawalAccounts: useCallback(
      async (id: string, params: SaveWithdrawalAccountsParam) => {
        const res = await saveWithdrawalAccounts({
          variables: {
            id,
            input: {
              withdrawalAccounts: params.withdrawalAccounts.map((p) => ({
                ...p,
                currency: parseCurrencyParam(p.currency),
              })),
            },
          },
        });
        return res.data?.clientSaveWithdrawalAccounts
          ? parseClientEntity(res.data.clientSaveWithdrawalAccounts)
          : undefined;
      },
      [saveWithdrawalAccounts]
    ),
  };
};
