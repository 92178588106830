import { useTranslation } from 'libs/i18n';
import { PersonalData } from 'modules/onboarding/common/components';

import { usePersonalData } from '../hooks';
import { useOnboardingContext } from '../hooks/useOnboardingContext';
import { PageComponentProps } from '../types';

export const PersonalDataStep = ({ canGoBack }: PageComponentProps) => {
  const { goToBack, onSubmit, initialValues, submitLoading, formError } =
    usePersonalData();

  const { step } = useOnboardingContext();
  const { t } = useTranslation();

  return (
    <PersonalData
      canGoBack={canGoBack}
      formError={formError}
      initialValues={initialValues}
      step={step}
      submitLoading={submitLoading}
      subTitle={t('onboarding.legalClientFirstHead.personalData.subtitle')}
      onGoBack={goToBack}
      onSubmit={onSubmit}
    />
  );
};
