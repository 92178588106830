import { Outlet } from 'libs/navigation';

import { GlobalStyled } from './GlobalStyles';
import { NavigationWrapper } from './NavigationWrapper';

export const Main = () => (
  <NavigationWrapper>
    {GlobalStyled}
    <Outlet />
  </NavigationWrapper>
);
