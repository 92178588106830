import { FC, useMemo } from 'react';

import { getMobileOperatingSystem } from 'helpers';
import { LinkLabelProps, LinkLabel } from 'libs/ui';

export interface OneLinkToMobileStoreProps
  extends Omit<LinkLabelProps, 'href'> {
  /** Android link */
  androidLink: string;
  /** iOS link */
  iosLink: string;
  /** Rendering content when no mobile device is detected  */
  renderEmpty?: () => JSX.Element;
}

export const OneLinkToMobileStore: FC<OneLinkToMobileStoreProps> = ({
  androidLink,
  iosLink,
  renderEmpty,
  label,
  ...rest
}) => {
  const link = useMemo(() => {
    const device = getMobileOperatingSystem();
    const links = { android: androidLink, ios: iosLink };
    return device && links[device];
  }, [androidLink, iosLink]);

  if (!link) {
    return renderEmpty ? renderEmpty() : null;
  }

  return <LinkLabel href={link} label={label} target="_blank" {...rest} />;
};
