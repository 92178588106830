import { styled } from '../styled';

export const AutocompleteStyled = styled('div')(({ theme }) => ({
  '& label': {
    color: theme.palette.inputLabel,
    ...theme.typography.captionSemiBold,
    transform: 'none',
  },

  '& div.MuiInputBase-formControl input.MuiInput-input::placeholder': {
    opacity: '1 !important',
  },

  '& .MuiInputBase-root': {
    flexWrap: 'nowrap!important',
  },

  '& .autocomplete-tag': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textWrap: 'nowrap',
    whiteSpace: 'nowrap',
    pointerEvents: 'none',
    ...theme.typography.bodyBold,
  },

  '@global': {
    '.MuiAutocomplete-option[aria-selected="true"]': {
      color: theme.palette.selectSelectedMenuItem,
      fontWeight: 700,
    },
  },
}));
