import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useClientSaveOrderSubmissionContactsMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { ClientSaveOrderSubmissionContactParam } from 'api/types/params';

import {
  parseClientEntity,
  parseOrderSubmissionContactsParam,
} from '../helpers';

export const useClientSaveOrderSubmissionContacts = () => {
  const [saveOrderSubmissionContacts, { data, loading, error: apolloError }] =
    useClientSaveOrderSubmissionContactsMutation();

  const error = useError(apolloError);

  return {
    data: data?.clientSaveOrderSubmissionContacts
      ? parseClientEntity(data.clientSaveOrderSubmissionContacts)
      : undefined,
    loading,
    error,
    saveOrderSubmissionContacts: useCallback(
      async (id: string, params: ClientSaveOrderSubmissionContactParam[]) => {
        const res = await saveOrderSubmissionContacts({
          variables: { id, input: parseOrderSubmissionContactsParam(params) },
        });
        return res.data?.clientSaveOrderSubmissionContacts
          ? parseClientEntity(res.data.clientSaveOrderSubmissionContacts)
          : undefined;
      },
      [saveOrderSubmissionContacts]
    ),
  };
};
