import { useMemo } from 'react';

import { PasswordValidationRule } from 'api/types/data';
import { PasswordFormBody } from 'components';
import { PasswordValidation } from 'helpers';
import { useTranslation } from 'libs/i18n';

import {
  getSchema,
  RegistrationPasswordFormType,
  FormErrors,
} from '../../forms/registrationPasswordForm';

interface RegistrationPasswordProps {
  onSubmit: (valie: RegistrationPasswordFormType) => void;
  canGoBack?: boolean;
  onGoBack?: () => void;
  submitLoading?: boolean;
  formError: FormErrors;
  passwordValidationRule?: PasswordValidationRule;
}

export const RegistrationPassword = ({
  canGoBack,
  submitLoading,
  formError,
  passwordValidationRule,
  onGoBack,
  onSubmit,
}: RegistrationPasswordProps) => {
  const { t } = useTranslation();

  const passwordValidation = useMemo(
    () => PasswordValidation.parseByValidationRule(passwordValidationRule),
    [passwordValidationRule]
  );

  const schema = useMemo(
    () => getSchema(t, passwordValidation),
    [passwordValidation, t]
  );

  return (
    <PasswordFormBody
      canGoBack={canGoBack}
      initialErrors={formError}
      passwordFieldName="password"
      passwordValidation={passwordValidation}
      submitLoading={submitLoading}
      subTitle={t('auth.registration.password.subtitle')}
      title={t('auth.registration.password.title')}
      validationSchema={schema}
      onGoBack={onGoBack}
      onSubmit={onSubmit}
    />
  );
};
