import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useLegalClientBranchSaveStateRegistrationMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { LegalClientSaveBaseStateRegistrationParam } from 'api/types/params/onboarding/saveBaseStateRegistrationParam';

import { parseLegalClientBranchEntity } from './helpers';

export const useLegalClientBranchSaveStateRegistration = () => {
  const [handle, { data, loading, error: apolloError }] =
    useLegalClientBranchSaveStateRegistrationMutation();

  const error = useError(apolloError);

  return {
    data: data?.legalClientBranchSaveStateRegistration
      ? parseLegalClientBranchEntity(
          data.legalClientBranchSaveStateRegistration
        )
      : undefined,
    loading,
    error,
    saveAddresses: useCallback(
      async (
        id: string,
        branchId: string,
        params: LegalClientSaveBaseStateRegistrationParam
      ) => {
        const res = await handle({
          variables: {
            id,
            branchId,
            input: params,
          },
        });
        return res?.data?.legalClientBranchSaveStateRegistration
          ? parseLegalClientBranchEntity(
              res.data.legalClientBranchSaveStateRegistration
            )
          : undefined;
      },
      [handle]
    ),
  };
};
