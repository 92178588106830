import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useClientConfirmDataAccuracyMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';

import { parseClientEntity } from '../helpers';

export const useClientConfirmDataAccuracy = () => {
  const [handle, { data, loading, error: apolloError }] =
    useClientConfirmDataAccuracyMutation();

  const error = useError(apolloError);

  return {
    data: data?.clientConfirmDataAccuracy
      ? parseClientEntity(data.clientConfirmDataAccuracy)
      : undefined,
    loading,
    error,
    confirmData: useCallback(
      async (id: string) => {
        const res = await handle({ variables: { id } });
        return res.data?.clientConfirmDataAccuracy
          ? parseClientEntity(res.data.clientConfirmDataAccuracy)
          : undefined;
      },
      [handle]
    ),
  };
};
