import { useCallback } from 'react';

import { useOnboardingClient, useOnboardingSettingsSave } from 'api/requests';

export const useOnboarding = (clientId: string) => {
  const { currentClient, loading, refetch } = useOnboardingClient(clientId);
  const { saveSettings } = useOnboardingSettingsSave();

  const onGoBack = useCallback(
    async (name: string) => {
      if (currentClient) {
        await saveSettings(currentClient.id, name);
      }
    },
    [currentClient, saveSettings]
  );

  const totalStepCount = currentClient?.onboarding?.process?.totalStepCount;

  return {
    currentClient,
    loading: !currentClient && loading,
    totalStepCount,
    onGoBack,
    refetch,
  };
};
