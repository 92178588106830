import { SaveDebtsParam } from 'api/types/params';
import { getBaseDebtsSubmitData } from 'modules/onboarding/common/helpers/debts/getBaseDebtsSubmitData';
import { YesOrNoType } from 'types/yesOrNo';

import { DebtsFormType } from '../../forms/debtsForm';

export const getSubmitData = (
  values: DebtsFormType,
  isEntrepreneur: boolean
): SaveDebtsParam => ({
  ...getBaseDebtsSubmitData(values, isEntrepreneur),
  hasMarginTrades: values.marginTrades?.value === YesOrNoType.Yes,
});
