import {
  LegalClientBaseStateRegistrationEntity,
  LegalClientStateRegistrationEntity,
} from 'api/types/entity/legal/legalClientStateRegistrationEntity';
import { FileUpload } from 'hooks/useFilesUpload';

import {
  CompanyStateRegistrationFormType,
  BaseStateRegistrationFormType,
} from '../../forms/companyStateRegistrationForm';

export const getBaseInitialData = (
  stateRegistration: LegalClientBaseStateRegistrationEntity | undefined
): BaseStateRegistrationFormType | undefined =>
  stateRegistration
    ? {
        date: stateRegistration.initialStateRegistrationDate,
        dateOfIssue:
          stateRegistration.stateRegistrationConfirmationDocument.dateOfIssue,
        nameAuthority: stateRegistration.registrationAuthorityName,
        number: stateRegistration.registrationNumber,
        registrationDocumentName: stateRegistration.registrationDocumentName,
      }
    : undefined;

export const getInitialData = (
  stateRegistration: LegalClientStateRegistrationEntity | undefined
) => {
  const baseData = getBaseInitialData(stateRegistration);
  const values: CompanyStateRegistrationFormType | undefined =
    stateRegistration && baseData
      ? {
          ...baseData,
          fileIds:
            stateRegistration.stateRegistrationConfirmationDocument.files.map(
              (v) => v.id
            ),
        }
      : undefined;

  const filesUpload: FileUpload[] | undefined =
    stateRegistration?.stateRegistrationConfirmationDocument.files.map(
      (v) =>
        new FileUpload({ name: v.name, size: v.size }, undefined, false, v.id)
    );

  return { values, filesUpload };
};
