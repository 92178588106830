import { OnboardingAccountsInOrganizationsFormType } from 'modules/onboarding/common/forms/onboardingAccountsInOrganizationsForm';
import { useClientAccountsInOrganizations } from 'modules/onboarding/common/hooks';

import { useOnboardingContext } from './useOnboardingContext';

export const useOwnerAccountsInOrganizations = () => {
  const { data, goToNext, goToBack } = useOnboardingContext();
  const {
    countries,
    countriesLoading,
    initialValues,
    saveBankAccounts,
    submitLoading,
  } = useClientAccountsInOrganizations(data.ownerClient!);

  const onSubmit = async (
    values: OnboardingAccountsInOrganizationsFormType
  ) => {
    if (values) {
      await saveBankAccounts(values);
      goToNext();
    }
  };

  return {
    onSubmit,
    goToBack,
    submitLoading,
    initialValues,
    countries,
    countriesLoading,
  };
};
