import { PropsWithChildren } from 'react';

import { useIsMobileSize } from 'hooks';
import { TestAttributeProps } from 'types/testAttribute';

import { TypographyStyled } from './Typography.styles';
import { TypographyProps } from './types';

/**
 * Typography component.
 */

export const Typography = ({
  children,
  variant = 'body',
  variantMobile,
  className,
  title,
  align,
  color,
  as,
  maxLine,
  whiteSpace,
  datax,
  onClick,
}: TypographyProps & PropsWithChildren & TestAttributeProps) => {
  const isMobile = useIsMobileSize();
  return (
    <TypographyStyled
      align={align}
      as={as}
      className={className}
      color={color}
      datax={datax}
      maxLine={maxLine}
      title={title}
      variant={isMobile && variantMobile ? variantMobile : variant}
      whiteSpace={whiteSpace}
      onClick={onClick}
    >
      {children}
    </TypographyStyled>
  );
};
