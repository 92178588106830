import { CompanyActivities } from '../components';
import { useCompanyActivities } from '../hooks/useCompanyActivities';
import { PageComponentProps } from '../types';

export const CompanyActivitiesStep = ({ canGoBack }: PageComponentProps) => {
  const { initialValues, submitLoading, onSubmit, goToBack } =
    useCompanyActivities();

  return (
    <CompanyActivities
      canGoBack={canGoBack}
      initialValues={initialValues}
      submitLoading={submitLoading}
      onGoBack={goToBack}
      onSubmit={onSubmit}
    />
  );
};
