import { CountryEntity } from 'api/types/entity';
import { useTranslation } from 'libs/i18n';
import { Stack } from 'libs/ui';

import {
  useFormContext,
  Field,
} from '../../../forms/onboardingCitizenshipForm';

import { Buttons } from './components';

export interface FormContentProps {
  countriesLoading?: boolean;
  countries: CountryEntity[];
}

export const FormContent = ({
  countriesLoading,
  countries,
}: FormContentProps) => {
  const { values } = useFormContext();
  const { t } = useTranslation();

  return (
    <Stack spacing={32}>
      {values.citizenships.map((value, index) => (
        <Stack key={index.toLocaleString()} spacing={32}>
          <Stack spacing={24} spacingMobile={16}>
            <Field.Autocomplete
              label={t('onboarding.citizenship.citizenship.label')}
              loading={countriesLoading}
              name={`citizenships.${index}` as 'citizenships'}
              optionLabelKey="name"
              options={countries}
              optionValueKey="id"
              placeholder={t('onboarding.citizenship.citizenship.placeholder')}
            />
          </Stack>

          <Buttons index={index} />
        </Stack>
      ))}
    </Stack>
  );
};
