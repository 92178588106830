import { FC, useCallback, useRef } from 'react';

import { AgreementEntity } from 'api/types/entity';
import { Body, LoadingView, Markdown } from 'components';
import { useTranslation } from 'libs/i18n';
import { Box, Button, Stack, Typography } from 'libs/ui';

import { ContainerStyled } from './AgreementView.styles';

export type AgreementViewProps = {
  agreements?: AgreementEntity[];
  activeIndex: number;
  loading: boolean;
  canGoBack?: boolean;
  onAccept: () => void;
  onGoBack?: () => void;
};

export const AgreementView: FC<AgreementViewProps> = ({
  agreements,
  activeIndex,
  loading,
  canGoBack,
  onAccept,
  onGoBack,
}) => {
  const agreementTextBoxRef = useRef<HTMLDivElement | null>(null);
  const { t } = useTranslation();

  const resetScrollPositionTextBox = useCallback(() => {
    agreementTextBoxRef.current?.scrollTo(0, 0);
    window.scrollTo(0, 0);
  }, []);

  const onGoBackHandler = useCallback(() => {
    if (!onGoBack) {
      return;
    }
    resetScrollPositionTextBox();
    onGoBack();
  }, [onGoBack, resetScrollPositionTextBox]);

  const onAcceptHandler = useCallback(() => {
    resetScrollPositionTextBox();
    onAccept();
  }, [onAccept, resetScrollPositionTextBox]);

  return (
    <ContainerStyled>
      {loading ? (
        <LoadingView />
      ) : (
        <Body className="agreement-body">
          <Stack
            className="agreement-content"
            justifyContent="space-between"
            spacing={32}
          >
            <Typography as="h1" variant="title" variantMobile="subtitle">
              {t('containers.agreement.title')}
            </Typography>
            <Stack className="agreement-contentWrapper" spacing={12}>
              <Typography variant="subtitle" variantMobile="bodyBold">
                {activeIndex + 1}. {agreements?.[activeIndex]?.title}
              </Typography>
              <Box ref={agreementTextBoxRef} className="agreement-textBox">
                <Typography className="agreement-text">
                  <Markdown>{agreements?.[activeIndex]?.text}</Markdown>
                </Typography>
              </Box>
            </Stack>
          </Stack>
          <Stack
            alignItems="center"
            className="agreement-buttons"
            direction="row"
            justifyContent="flex-end"
            spacing={36}
          >
            {canGoBack && (
              <Button
                label={t('common.back')}
                mode="text"
                onClick={onGoBackHandler}
              />
            )}
            <Button
              label={t('containers.agreement.acceptTitle', {
                step: activeIndex + 1,
                steps: agreements?.length,
              })}
              onClick={onAcceptHandler}
            />
          </Stack>
        </Body>
      )}
    </ContainerStyled>
  );
};
