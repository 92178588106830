import { ConfirmationDataDialog } from '../components';
import { useConfirmationData } from '../hooks';

export const ConfirmationDataStep = () => {
  const { onConfirm, goToBack, loading } = useConfirmationData();

  return (
    <ConfirmationDataDialog
      isOpen
      loading={loading}
      onBack={goToBack}
      onConfirm={onConfirm}
    />
  );
};
