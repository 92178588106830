import { getColor } from 'helpers';
import { CSSObject } from 'libs/ui/styled';
import { ColorsDark, ColorsLight, Theme } from 'libs/ui/theme';

import { ButtonColorVariant } from '../types';

import { getStandardMode } from './standard';

const getColorPrimary = (theme: Theme) => ({
  textColor: getColor(theme, {
    dark: ColorsDark.blue150,
    light: ColorsLight.blue150,
  }),
  borderColor: getColor(theme, {
    dark: ColorsDark.blue150,
    light: ColorsLight.blue150,
  }),

  hoverBgColor: getColor(theme, {
    dark: ColorsDark.blue50,
    light: ColorsLight.blue50,
  }),
  hoverBorderColor: getColor(theme, {
    dark: ColorsDark.blue250,
    light: ColorsLight.blue150,
  }),
  hoverTextColor: getColor(theme, {
    dark: ColorsDark.blue250,
    light: ColorsLight.blue150,
  }),

  focusBorderColor: getColor(theme, {
    dark: ColorsDark.blue250,
    light: ColorsLight.blue250,
  }),
  focusTextColor: getColor(theme, {
    dark: ColorsDark.blue250,
    light: ColorsLight.blue250,
  }),

  disableBorderColor: getColor(theme, {
    dark: ColorsDark.gray250,
    light: ColorsLight.gray250,
  }),
  disableTextColor: getColor(theme, {
    dark: ColorsDark.gray250,
    light: ColorsLight.gray250,
  }),
});

const getColorRed = (theme: Theme) => ({
  textColor: getColor(theme, {
    dark: ColorsDark.red100,
    light: ColorsLight.red100,
  }),
  borderColor: getColor(theme, {
    dark: ColorsDark.red100,
    light: ColorsLight.red100,
  }),

  hoverBgColor: getColor(theme, {
    dark: ColorsDark.red50,
    light: ColorsLight.red50,
  }),
  hoverBorderColor: getColor(theme, {
    dark: ColorsDark.red100,
    light: ColorsLight.red100,
  }),
  hoverTextColor: getColor(theme, {
    dark: ColorsDark.red100,
    light: ColorsLight.red100,
  }),

  focusBorderColor: getColor(theme, {
    dark: ColorsDark.red200,
    light: ColorsLight.red200,
  }),
  focusTextColor: getColor(theme, {
    dark: ColorsDark.red200,
    light: ColorsLight.red200,
  }),

  disableBorderColor: getColor(theme, {
    dark: ColorsDark.gray250,
    light: ColorsLight.gray250,
  }),
  disableTextColor: getColor(theme, {
    dark: ColorsDark.gray250,
    light: ColorsLight.gray250,
  }),
});

export const getOutlinedMode = (
  theme: Theme,
  colorVariant: ButtonColorVariant
): CSSObject => {
  const colors =
    colorVariant === 'primary' ? getColorPrimary(theme) : getColorRed(theme);

  return {
    ...getStandardMode(theme, colorVariant),
    background: 'none',
    color: colors.textColor,
    border: `1px solid ${colors.borderColor}`,

    '&:hover': {
      backgroundColor: colors.hoverBgColor,
      border: `1px solid ${colors.hoverBorderColor}`,
      color: colors.hoverTextColor,
    } as CSSObject,

    '&:active': {
      background: 'none',
      border: `1px solid ${colors.focusBorderColor}`,
      color: colors.focusTextColor,
    } as CSSObject,

    '&:disabled': {
      borderColor: colors.disableTextColor,
      color: colors.disableTextColor,
    } as CSSObject,
  };
};
