import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useLegalClientRepresentativeSaveTaxResidencesMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { OnboardingTaxResidencesParam } from 'api/types/params';

import { parseLegalClientRepresentative } from './helpers';

export const useLegalClientRepresentativeSaveTaxResidences = () => {
  const [handle, { data, loading, error: apolloError }] =
    useLegalClientRepresentativeSaveTaxResidencesMutation();

  const error = useError(apolloError);

  return {
    data: data?.legalClientRepresentativeSaveTaxResidences
      ? parseLegalClientRepresentative(
          data.legalClientRepresentativeSaveTaxResidences
        )
      : undefined,
    loading,
    error,
    saveTaxResidences: useCallback(
      async (id: string, params: OnboardingTaxResidencesParam) => {
        const res = await handle({
          variables: {
            id,
            input: params,
          },
        });
        return res?.data?.legalClientRepresentativeSaveTaxResidences
          ? parseLegalClientRepresentative(
              res.data.legalClientRepresentativeSaveTaxResidences
            )
          : undefined;
      },
      [handle]
    ),
  };
};
