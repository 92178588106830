import { useContext } from 'react';

import {
  LegalClientBeneficiaryEntity,
  LegalClientEntity,
  LegalClientRepresentativeEntity,
} from 'api/types/entity';
import { PersonalDataFormType } from 'modules/onboarding/common/forms/personalDataForm';
import {
  OnboardingContext,
  OnboardingContextProps,
} from 'modules/onboarding/common/providers/OnboardingProvider/Context';

export const useOnboardingContext = () => {
  const res = useContext(OnboardingContext);
  return res as OnboardingContextProps<{
    currentClient: LegalClientRepresentativeEntity;
    legalClient: LegalClientEntity;
    legalClientBeneficiary: LegalClientBeneficiaryEntity;
    onCreateBeneficiary: (
      personalData: PersonalDataFormType
    ) => Promise<boolean>;
  }>;
};
