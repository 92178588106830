import { ColorsLight } from 'libs/ui/theme';

import { SvgIcon } from '../SvgIcon';
import { SvgProps } from '../types';

export const Moon = ({
  size = 18,
  className,
  color = ColorsLight.black,
}: SvgProps) => (
  <SvgIcon className={className} size={size}>
    <svg
      color={color}
      fill="none"
      height="18"
      viewBox="0 0 18 18"
      width="18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_133_1572)">
        <path
          d="M17.562 10.2518C17.3778 10.2057 17.1937 10.2518 17.0326 10.3669C16.4341 10.8732 15.7436 11.2875 14.9841 11.5637C14.2705 11.84 13.488 11.9781 12.6594 11.9781C10.795 11.9781 9.09172 11.2185 7.87182 9.99859C6.65192 8.77869 5.89235 7.07543 5.89235 5.21105C5.89235 4.42847 6.03046 3.66891 6.26063 2.9784C6.51381 2.24185 6.88209 1.57436 7.36544 0.998936C7.5726 0.745749 7.52656 0.377476 7.27338 0.170323C7.11226 0.0552381 6.92812 0.00920402 6.74398 0.0552381C4.78754 0.584629 3.08428 1.7585 1.86438 3.32365C0.69051 4.86579 0 6.77621 0 8.84774C0 11.3566 1.01275 13.6353 2.66997 15.2925C4.3272 16.9497 6.58286 17.9625 9.11474 17.9625C11.2323 17.9625 13.1887 17.2259 14.7539 16.006C16.3421 14.7631 17.4929 12.9908 17.9763 10.9653C18.0684 10.6431 17.8842 10.3208 17.562 10.2518Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_133_1572">
          <rect fill="white" height="18" width="18" />
        </clipPath>
      </defs>
    </svg>
  </SvgIcon>
);
