import { useRef, useState } from 'react';

import {
  useLegalClientSaveBeneficiariesStep,
  useLegalClientSaveUnidentifiableBeneficiaryLetterFiles,
} from 'api/requests';
import { useLegalClientDeleteBeneficiary } from 'api/requests/onboarding/legal/beneficiary';
import { LegalClientInnerItemEntity } from 'api/types/entity/legal/legalClientInnerItemEntity';
import { FileUpload, useFilesUpload } from 'hooks';
import { useTranslation } from 'libs/i18n';
import { useNotify } from 'libs/notify';

import { CompanyBeneficiariesForm } from '../forms/companyBeneficiariesForm';

import { useOnboardingContext } from './useOnboardingContext';

const checkFullness = (b: LegalClientInnerItemEntity) =>
  !!b.isCompletedByRepresentative;

export const useBeneficiaries = () => {
  const notify = useNotify();
  const { t } = useTranslation();

  const [errorBeneficiariesIds, setErrorBeneficiariesIds] =
    useState<string[]>();

  const { goToBack, goToNext, data } = useOnboardingContext();

  const beneficiaries = data.legalClient?.beneficiaries ?? [];

  const { deleteBeneficiary, loading: deleteLoading } =
    useLegalClientDeleteBeneficiary();

  const {
    saveUnidentifiableBeneficiaryLetterFiles,
    loading: saveUnidentifiableBeneficiaryLetterFilesLoading,
  } = useLegalClientSaveUnidentifiableBeneficiaryLetterFiles();

  const { saveBeneficiariesStep, loading: saveBeneficiariesStepLoading } =
    useLegalClientSaveBeneficiariesStep();

  const initialValues: CompanyBeneficiariesForm = useRef({
    impossibleIdentifyBeneficiary:
      !data.legalClient!.beneficiaries?.length &&
      !!data.legalClient!.unidentifiableBeneficiaryLetterFiles?.length,
    fileIds: data.legalClient?.unidentifiableBeneficiaryLetterFiles?.map(
      (v) => v.id
    ),
  }).current;

  const filesUpload: FileUpload[] | undefined =
    data.legalClient?.unidentifiableBeneficiaryLetterFiles?.map(
      (v) =>
        new FileUpload({ name: v.name, size: v.size }, undefined, false, v.id)
    );

  const {
    files,
    isLoadingFile: submitDisabled,
    onRemoveFile,
    onAddFiles,
  } = useFilesUpload(filesUpload);

  const onSubmit = async (values: CompanyBeneficiariesForm) => {
    if (!values.impossibleIdentifyBeneficiary) {
      if (!beneficiaries?.length) {
        notify.error(Error(t('onboarding.beneficiaries.emptyErrorMessage')));
        return;
      }

      const notFulled = beneficiaries.filter((b) => !checkFullness(b));

      if (notFulled.length) {
        setErrorBeneficiariesIds(notFulled.map((b) => b.id));
        return;
      }
      await saveBeneficiariesStep(data.legalClient!.id);
    } else {
      await saveUnidentifiableBeneficiaryLetterFiles(data.legalClient!.id, {
        unidentifiableBeneficiaryLetterFileIds: values.fileIds!,
      });
    }

    goToNext();
  };

  const onAdd = () => {
    data.innerState.setBeneficiary();
  };

  const onEdit = (id: string) => {
    if (id) {
      data.innerState.setBeneficiary(id);
    }
  };

  const onDelete = (id: string) => {
    if (id) {
      deleteBeneficiary(data.legalClient?.id!, id);
    }
  };

  return {
    onSubmit,
    goToBack,
    onAdd,
    onEdit,
    onDelete,
    onRemoveFile,
    onAddFiles,
    errorBeneficiariesIds,
    deleteLoading,
    beneficiaries,
    submitLoading:
      saveUnidentifiableBeneficiaryLetterFilesLoading ||
      saveBeneficiariesStepLoading,
    initialValues,
    submitDisabled,
    files,
  };
};
