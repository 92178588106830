import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useLegalClientRepresentativeCompleteOnboardingMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';

export const useLegalClientRepresentativeCompleteOnboarding = () => {
  const [handle, { loading, error: apolloError }] =
    useLegalClientRepresentativeCompleteOnboardingMutation();

  const error = useError(apolloError);

  return {
    loading,
    error,
    completeOnboarding: useCallback(
      async (id: string) => {
        const res = await handle({ variables: { id } });
        return res.data?.legalClientRepresentativeCompleteOnboarding;
      },
      [handle]
    ),
  };
};
