import { useTranslation } from 'libs/i18n';
import { Stack } from 'libs/ui';
import {
  getToday,
  getTodayWithResetHours,
} from 'modules/onboarding/common/helpers/getToday';

import { Field } from '../../../forms/onboardingPowerOfAttorneyForm';

export const FormContent = () => {
  const { t } = useTranslation();

  return (
    <Stack spacing={24} spacingMobile={16}>
      <Field.Text
        showOptionalMark
        label={t('onboarding.powerOfAttorney.documentNumber.label')}
        name="documentNumber"
        placeholder={t('onboarding.powerOfAttorney.documentNumber.placeholder')}
      />
      <Field.DatePicker
        label={t('onboarding.powerOfAttorney.registrationDate.label')}
        maxDate={getToday()}
        name="dateOfIssue"
        placeholder={t(
          'onboarding.powerOfAttorney.registrationDate.placeholder'
        )}
      />
      <Field.DatePicker
        disableFuture={false}
        label={t('onboarding.powerOfAttorney.expirationDate.label')}
        minDate={getTodayWithResetHours()}
        name="dateOfExpiration"
        placeholder={t('onboarding.powerOfAttorney.expirationDate.placeholder')}
      />
    </Stack>
  );
};
