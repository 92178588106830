import { Stack } from 'libs/ui';
import { CSSObject, styled } from 'libs/ui/styled';

export const ItemStyled = styled(Stack)(({ theme }) => ({
  overflow: 'hidden',
  [theme.breakpoints.down('mobile')]: {
    width: '100%',
  },

  '.waitingKyc-item-textContent': {
    overflow: 'hidden',

    [theme.breakpoints.down('mobile')]: {
      width: '100%',
      justifyContent: 'space-between',
    } as CSSObject,

    '.waitingKyc-item-text': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    } as CSSObject,
  },
}));

export const SendButtonWrapperStyled = styled(Stack)(({ theme }) => ({
  textWrap: 'nowrap',
  whiteSpace: 'nowrap',

  [theme.breakpoints.down('mobile')]: {
    button: {
      padding: 0,
    },
  } as CSSObject,
}));
