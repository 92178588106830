import { RegistrationAddressEntity } from 'api/types/entity';
import { getBaseInitialData } from 'modules/onboarding/common/helpers/registrationAddress';
import { Maybe } from 'types/maybe';

interface Props {
  legalAddress?: Maybe<RegistrationAddressEntity>;
  actualAddress?: Maybe<RegistrationAddressEntity>;
}

export const getInitialData = (client: Props) => {
  if (!client.legalAddress) {
    return undefined;
  }

  const values = getBaseInitialData(client.legalAddress, client.actualAddress);

  return values;
};
