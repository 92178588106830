/**
 * Defines a set of custom colors.
 */
export enum ColorsLight {
  white = '#ffffff',
  black = '#081A35',
  red = '#D84315',

  red50 = '#F9CCCC',
  red100 = '#D84315',
  red150 = '#BD3911',
  red200 = '#972E0E',

  gray50 = '#F5F9FE',
  gray100 = '#CBD2E0',
  gray150 = '#C0D2E9',
  gray200 = '#A0ABC0',
  gray250 = '#787F89',
  gray300 = '#384D6C',

  blue50 = '#C0DCFF',
  blue100 = '#A8C0F1',
  blue150 = '#1A5FEA',
  blue200 = '#1958D6',
  blue250 = '#2755BE',

  green50 = '#C3EECA',
  green100 = '#0E591A',

  yellow50 = '#F0E6B0',
  yellow100 = '#78691B',

  hover = '#0B3189',
}

export enum ColorsDark {
  white = '#F6F6F7',
  black = '#1C2638',
  red = '#F54439',

  red50 = '#FAC6CC',
  red100 = '#F54439',
  red150 = '#AE3009',
  red200 = '#711D04',

  gray50 = '#2B354A',
  gray100 = '#3D4A60',
  gray150 = '#7687A5',
  gray200 = '#4A4E56',
  gray250 = '#8B9099',
  gray300 = '#384D6C',

  blue50 = '#D2F7FF',
  blue100 = '#A8E8F1',
  blue150 = '#00FAFF',
  blue200 = '#00A8AB',
  blue250 = '#065F61',

  green50 = '#69F0BF',
  green100 = '#0B6B49',

  yellow50 = '#FCE38E',
  yellow100 = '#554314',

  hover = '#359597',
}
export type ColorType = `${ColorsLight}` | `${ColorsDark}`;
