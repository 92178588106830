import { ContentStepper, ContentStepperStep, FormLayout } from 'components';
import { ROUTES, useNavigate } from 'libs/navigation';

import { RestorePasswordProvider } from '../providers';

interface Props {
  steps: ContentStepperStep[];
}

export const RestorePasswordStepper = ({ steps }: Props) => {
  const { navigate } = useNavigate();

  const onEnd = () => {
    window.location.replace('/');
  };

  const onBack = () => {
    navigate(ROUTES.login.fullPath, {});
  };

  return (
    <FormLayout>
      <RestorePasswordProvider>
        <ContentStepper steps={steps} onBack={onBack} onEnd={onEnd} />
      </RestorePasswordProvider>
    </FormLayout>
  );
};
