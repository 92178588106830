import { Hint } from 'components';
import { Stack } from 'libs/ui';

type Props = {
  hint?: string;
  label?: string;
};

export const FieldLabelWithHint = ({ hint, label }: Props) => (
  <Stack alignItems="center" direction="row" spacing={8}>
    {label}
    {hint && <Hint title={hint} />}
  </Stack>
);
