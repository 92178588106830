import { CompanyStructure } from '../components/CompanyStructure';
import { useCompanyStructure } from '../hooks';
import { PageComponentProps } from '../types';

export const CompanyStructureStep = ({ canGoBack }: PageComponentProps) => {
  const { submitLoading, bodies, onSubmit, goToBack, onAdd, onEdit, onDelete } =
    useCompanyStructure();

  return (
    <CompanyStructure
      bodies={bodies}
      canGoBack={canGoBack}
      submitLoading={submitLoading}
      onAdd={onAdd}
      onDelete={onDelete}
      onEdit={onEdit}
      onGoBack={goToBack}
      onSubmit={onSubmit}
    />
  );
};
