export enum CurrencyType {
  KZT = 'KZT',
  USD = 'USD',
  EUR = 'EUR',
  GBP = 'GBP',
  RUB = 'RUB',
  CHF = 'CHF',
  AED = 'AED',
  HKD = 'HKD',
  CNY = 'CNY',
  JPY = 'JPY',
  INR = 'INR',
  IDR = 'IDR',
}

export enum CurrencyInvoiceType {
  EUR = 'EUR',
  KZT = 'KZT',
  RUB = 'RUB',
  USD = 'USD',
}
