import { lodash } from 'helpers';

import { OnboardingAccountsInOrganizationsFormType } from '../../forms/onboardingAccountsInOrganizationsForm';

export const getSubmitData = (
  values: OnboardingAccountsInOrganizationsFormType
) => ({
  bankAccounts: lodash.compact(
    values.accounts.map((v) => ({
      swiftCode: v.bic!,
      country: v.country?.id!,
    }))
  ),
});
