import { ErrorCode, ServerError, ValidationError } from 'api/types/error';

/**
 * Get validation errors
 * @param {ServerError} error - The server error
 * @param {string} field - The validation field name
 * @return {Record<string, ValidationError[]> | undefined} - Record with field keys
 * */
export const getValidationErrors = <T extends string>(
  error: ServerError
): Record<T, ValidationError[]> | undefined => {
  if (
    error.code === ErrorCode.VALIDATION_FAILED &&
    error.additionalParams?.length
  ) {
    const result: Record<string, ValidationError[]> = {};

    for (const validationError of error.additionalParams) {
      result[validationError.field] = validationError.errors;
    }
    return result;
  }

  return undefined;
};
