import { InvoiceForPayment } from 'modules/onboarding/common/components';

import { useInvoiceForPayment } from '../hooks';
import { PageComponentProps } from '../types';

export const InvoiceForPaymentStep = ({ canGoBack }: PageComponentProps) => {
  const { initialValues, submitLoading, onSubmit, goToBack } =
    useInvoiceForPayment();

  return (
    <InvoiceForPayment
      canGoBack={canGoBack}
      initialValues={initialValues}
      submitLoading={submitLoading}
      onGoBack={goToBack}
      onSubmit={onSubmit}
    />
  );
};
