import { useRef } from 'react';

import { useLegalClientSaveInvoice } from 'api/requests';
import { useServerErrorNotify } from 'hooks';
import { InvoiceForPaymentFormType } from 'modules/onboarding/common/forms/invoiceForPayment';

import { useOnboardingContext } from './useOnboardingContext';

export const useInvoiceForPayment = () => {
  const { data, goToBack, goToNext } = useOnboardingContext();
  const {
    saveInvoice,
    error,
    loading: submitLoading,
  } = useLegalClientSaveInvoice();

  const initialValues = useRef<InvoiceForPaymentFormType>({
    currency: data.legalClient?.invoiceCurrency
      ? {
          label: data.legalClient.invoiceCurrency,
          value: data.legalClient.invoiceCurrency,
        }
      : null,
  }).current;

  const onSubmit = async (values: InvoiceForPaymentFormType) => {
    if (values.currency) {
      await saveInvoice(data.legalClient!.id, {
        currency: values.currency.value,
      });

      goToNext();
    }
  };

  useServerErrorNotify(error);

  return {
    onSubmit,
    goToBack,
    initialValues,
    submitLoading,
  };
};
