import { useContext } from 'react';

import {
  LegalClientEntity,
  LegalClientHeadEntity,
  LegalClientRepresentativeEntity,
} from 'api/types/entity';
import { LegalClientCreateHeadSaveParam } from 'api/types/params';
import {
  OnboardingContext,
  OnboardingContextProps,
} from 'modules/onboarding/common/providers/OnboardingProvider/Context';

export const useOnboardingContext = () => {
  const res = useContext(OnboardingContext);
  return res as OnboardingContextProps<{
    currentClient: LegalClientRepresentativeEntity;
    legalClient: LegalClientEntity;
    legalClientHead: LegalClientHeadEntity;
    onCreateHead: (param: LegalClientCreateHeadSaveParam) => Promise<boolean>;
  }>;
};
