import { AmlPolicyReviewFrequency } from 'api/types/entity';
import { getYesOrNoOptions } from 'helpers';
import { useTranslation } from 'libs/i18n';
import { getTodayWithResetHours } from 'modules/onboarding/common/helpers/getToday';
import { YesOrNoType } from 'types/yesOrNo';

import {
  Field,
  useFormContext,
  maxLengthTextField,
  getAmlPolicyReviewFrequencyOptions,
  getMonetaryServiceTransactionCountForLastSixMonthsOptions,
  maxLengthName,
  maxLengthOfficerPosition,
  maxLengthAuthorityName,
} from '../../../forms/companyFinancialMonitoringForm';

import { FieldLabelWithHint } from './components';

export interface FormContentProps {
  hasBranches: boolean;
}

export const FormContent = ({ hasBranches }: FormContentProps) => {
  const { t } = useTranslation();
  const { values } = useFormContext();

  return (
    <>
      <Field.Autocomplete
        label={t(
          'onboarding.companyFinancialMonitoring.isSubjectToRegulation.label'
        )}
        name="isSubjectToRegulation"
        optionLabelKey="label"
        options={getYesOrNoOptions(t)}
        optionValueKey="value"
        placeholder={t('common.select.placeholder')}
        variant="select"
      />
      {values.isSubjectToRegulation?.value === YesOrNoType.Yes && (
        <>
          <Field.Text
            label={t(
              'onboarding.companyFinancialMonitoring.authorityName.label'
            )}
            maxLength={maxLengthAuthorityName}
            name="authorityName"
            placeholder={t(
              'onboarding.companyFinancialMonitoring.authorityName.placeholder'
            )}
          />
          <Field.Text
            showOptionalMark
            label={t(
              'onboarding.companyFinancialMonitoring.authorityWebsite.label'
            )}
            maxLength={maxLengthTextField}
            name="authorityWebsite"
            placeholder={t(
              'onboarding.companyFinancialMonitoring.authorityWebsite.placeholder'
            )}
          />

          <Field.Autocomplete
            label={t(
              'onboarding.companyFinancialMonitoring.hasAmlCftComplianceControl.label'
            )}
            name="hasAmlCftComplianceControl"
            optionLabelKey="label"
            options={getYesOrNoOptions(t)}
            optionValueKey="value"
            placeholder={t('common.select.placeholder')}
            variant="select"
          />

          <Field.Autocomplete
            label={t(
              'onboarding.companyFinancialMonitoring.hasTransactionMonitoringAndSuspiciousActivityDetection.label'
            )}
            name="hasTransactionMonitoringAndSuspiciousActivityDetection"
            optionLabelKey="label"
            options={getYesOrNoOptions(t)}
            optionValueKey="value"
            placeholder={t('common.select.placeholder')}
            variant="select"
          />

          <Field.Autocomplete
            label={
              <FieldLabelWithHint
                hint={t(
                  'onboarding.companyFinancialMonitoring.amlPolicyReviewFrequencyHint'
                )}
                label={t(
                  'onboarding.companyFinancialMonitoring.amlPolicyReviewFrequency.label'
                )}
              />
            }
            name="amlPolicyReviewFrequency"
            optionLabelKey="label"
            options={getAmlPolicyReviewFrequencyOptions(t)}
            optionValueKey="value"
            placeholder={t('common.select.placeholder')}
            variant="select"
          />

          {values.amlPolicyReviewFrequency?.value ===
            AmlPolicyReviewFrequency.Other && (
            <Field.Text
              label={t(
                'onboarding.companyFinancialMonitoring.otherAmlPolicyReviewFrequency.label'
              )}
              maxLength={maxLengthTextField}
              name="otherAmlPolicyReviewFrequency"
              placeholder={t(
                'onboarding.companyFinancialMonitoring.otherAmlPolicyReviewFrequency.placeholder'
              )}
            />
          )}

          {hasBranches && (
            <Field.Autocomplete
              label={t(
                'onboarding.companyFinancialMonitoring.amlCftPoliciesApplyToForeignEntities.label'
              )}
              name="amlCftPoliciesApplyToForeignEntities"
              optionLabelKey="label"
              options={getYesOrNoOptions(t)}
              optionValueKey="value"
              placeholder={t('common.select.placeholder')}
              variant="select"
            />
          )}

          <Field.Autocomplete
            label={t(
              'onboarding.companyFinancialMonitoring.hasAmlCftDocumentEffectivenessAssessmentProcedures.label'
            )}
            name="hasAmlCftDocumentEffectivenessAssessmentProcedures"
            optionLabelKey="label"
            options={getYesOrNoOptions(t)}
            optionValueKey="value"
            placeholder={t('common.select.placeholder')}
            variant="select"
          />

          {values.hasAmlCftDocumentEffectivenessAssessmentProcedures?.value ===
            YesOrNoType.Yes && (
            <Field.DatePicker
              showOptionalMark
              label={t(
                'onboarding.companyFinancialMonitoring.dateOfLastCheck.label'
              )}
              maxDate={getTodayWithResetHours()}
              name="dateOfLastCheck"
              placeholder={t(
                'onboarding.companyFinancialMonitoring.dateOfLastCheck.placeholder'
              )}
            />
          )}

          <Field.Autocomplete
            label={t(
              'onboarding.companyFinancialMonitoring.hasAmlCftInternalControlOfficer.label'
            )}
            name="hasAmlCftInternalControlOfficer"
            optionLabelKey="label"
            options={getYesOrNoOptions(t)}
            optionValueKey="value"
            placeholder={t('common.select.placeholder')}
            variant="select"
          />

          {values.hasAmlCftInternalControlOfficer?.value ===
            YesOrNoType.Yes && (
            <>
              <Field.Text
                label={t(
                  'onboarding.companyFinancialMonitoring.officerFirstName.label'
                )}
                maxLength={maxLengthName}
                name="officerFirstName"
                placeholder={t(
                  'onboarding.companyFinancialMonitoring.officerFirstName.placeholder'
                )}
                trim="start"
              />
              <Field.Text
                label={t(
                  'onboarding.companyFinancialMonitoring.officerLastName.label'
                )}
                maxLength={maxLengthName}
                name="officerLastName"
                placeholder={t(
                  'onboarding.companyFinancialMonitoring.officerLastName.placeholder'
                )}
                trim="start"
              />
              <Field.Text
                showOptionalMark
                label={t(
                  'onboarding.companyFinancialMonitoring.officerMiddleName.label'
                )}
                maxLength={maxLengthName}
                name="officerMiddleName"
                placeholder={t(
                  'onboarding.companyFinancialMonitoring.officerMiddleName.placeholder'
                )}
                trim="start"
              />
              <Field.Text
                label={t(
                  'onboarding.companyFinancialMonitoring.officerPosition.label'
                )}
                maxLength={maxLengthOfficerPosition}
                name="officerPosition"
                placeholder={t(
                  'onboarding.companyFinancialMonitoring.officerPosition.placeholder'
                )}
                trim="start"
              />
              <Field.Phone
                label={t(
                  'onboarding.companyFinancialMonitoring.officerPhoneNumber.label'
                )}
                maxLength={22}
                name="officerPhoneNumber"
                placeholder={t(
                  'onboarding.companyFinancialMonitoring.officerPhoneNumber.placeholder'
                )}
                type="phone"
              />
              <Field.Text
                autoComplete="on"
                label={t(
                  'onboarding.companyFinancialMonitoring.officerEmail.label'
                )}
                name="officerEmail"
                placeholder={t(
                  'onboarding.companyFinancialMonitoring.officerEmail.placeholder'
                )}
                type="email"
              />
            </>
          )}
          <Field.Autocomplete
            label={t(
              'onboarding.companyFinancialMonitoring.canProvideAmlPolicyDocuments.label'
            )}
            name="canProvideAmlPolicyDocuments"
            optionLabelKey="label"
            options={getYesOrNoOptions(t)}
            optionValueKey="value"
            placeholder={t('common.select.placeholder')}
            variant="select"
          />

          {values.canProvideAmlPolicyDocuments?.value === YesOrNoType.No && (
            <Field.Text
              label={t(
                'onboarding.companyFinancialMonitoring.documentProvisionRefusalReason.label'
              )}
              maxLength={maxLengthTextField}
              name="documentProvisionRefusalReason"
              placeholder={t(
                'onboarding.companyFinancialMonitoring.documentProvisionRefusalReason.placeholder'
              )}
            />
          )}

          <Field.Autocomplete
            label={t(
              'onboarding.companyFinancialMonitoring.isInternalControlInfoConfidential.label'
            )}
            name="isInternalControlInfoConfidential"
            optionLabelKey="label"
            options={getYesOrNoOptions(t)}
            optionValueKey="value"
            placeholder={t('common.select.placeholder')}
            variant="select"
          />

          <Field.Autocomplete
            label={t(
              'onboarding.companyFinancialMonitoring.hadAmlCftInvestigationsOrPenalties.label'
            )}
            name="hadAmlCftInvestigationsOrPenalties"
            optionLabelKey="label"
            options={getYesOrNoOptions(t)}
            optionValueKey="value"
            placeholder={t('common.select.placeholder')}
            variant="select"
          />

          {values.hadAmlCftInvestigationsOrPenalties?.value ===
            YesOrNoType.Yes && (
            <Field.Text
              label={t(
                'onboarding.companyFinancialMonitoring.investigationOrPenaltyReasons.label'
              )}
              maxLength={maxLengthTextField}
              name="investigationOrPenaltyReasons"
              placeholder={t(
                'onboarding.companyFinancialMonitoring.investigationOrPenaltyReasons.placeholder'
              )}
            />
          )}

          <Field.Autocomplete
            label={t(
              'onboarding.companyFinancialMonitoring.hasMandatoryControlTransactions.label'
            )}
            name="hasMandatoryControlTransactions"
            optionLabelKey="label"
            options={getYesOrNoOptions(t)}
            optionValueKey="value"
            placeholder={t('common.select.placeholder')}
            variant="select"
          />

          {values.hasMandatoryControlTransactions?.value ===
            YesOrNoType.Yes && (
            <Field.Text
              label={t(
                'onboarding.companyFinancialMonitoring.mandatoryControlTransactionIdentificationProcedure.label'
              )}
              maxLength={maxLengthTextField}
              name="mandatoryControlTransactionIdentificationProcedure"
              placeholder={t(
                'onboarding.companyFinancialMonitoring.mandatoryControlTransactionIdentificationProcedure.placeholder'
              )}
            />
          )}

          <Field.Autocomplete
            label={t(
              'onboarding.companyFinancialMonitoring.monetaryServiceTransactionCountForLastSixMonths.label'
            )}
            name="monetaryServiceTransactionCountForLastSixMonths"
            optionLabelKey="label"
            options={getMonetaryServiceTransactionCountForLastSixMonthsOptions(
              t
            )}
            optionValueKey="value"
            placeholder={t('common.select.placeholder')}
            variant="select"
          />

          <Field.Autocomplete
            label={
              <FieldLabelWithHint
                hint={t(
                  'onboarding.companyFinancialMonitoring.isAffiliatedWithNonProfitOrganizationsHint'
                )}
                label={t(
                  'onboarding.companyFinancialMonitoring.isAffiliatedWithNonProfitOrganizations.label'
                )}
              />
            }
            name="isAffiliatedWithNonProfitOrganizations"
            optionLabelKey="label"
            options={getYesOrNoOptions(t)}
            optionValueKey="value"
            placeholder={t('common.select.placeholder')}
            variant="select"
          />
          <Field.Autocomplete
            label={t(
              'onboarding.companyFinancialMonitoring.receivesFundingFromStateBudget.label'
            )}
            name="receivesFundingFromStateBudget"
            optionLabelKey="label"
            options={getYesOrNoOptions(t)}
            optionValueKey="value"
            placeholder={t('common.select.placeholder')}
            variant="select"
          />
          <Field.Autocomplete
            label={t(
              'onboarding.companyFinancialMonitoring.receivesFundingFromForeignNonProfitNGOs.label'
            )}
            name="receivesFundingFromForeignNonProfitNGOs"
            optionLabelKey="label"
            options={getYesOrNoOptions(t)}
            optionValueKey="value"
            placeholder={t('common.select.placeholder')}
            variant="select"
          />

          <Field.Autocomplete
            label={
              <FieldLabelWithHint
                hint={t(
                  'onboarding.companyFinancialMonitoring.isCompliantWithFATFRecommendationsHint'
                )}
                label={t(
                  'onboarding.companyFinancialMonitoring.isCompliantWithFATFRecommendations.label'
                )}
              />
            }
            name="isCompliantWithFATFRecommendations"
            optionLabelKey="label"
            options={getYesOrNoOptions(t)}
            optionValueKey="value"
            placeholder={t('common.select.placeholder')}
            variant="select"
          />
          <Field.Autocomplete
            label={t(
              'onboarding.companyFinancialMonitoring.hasBeenRefusedToOpenAccountByBanks.label'
            )}
            name="hasBeenRefusedToOpenAccountByBanks"
            optionLabelKey="label"
            options={getYesOrNoOptions(t)}
            optionValueKey="value"
            placeholder={t('common.select.placeholder')}
            variant="select"
          />
        </>
      )}
    </>
  );
};
