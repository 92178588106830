import { SavePositionInCompanyParam } from 'api/types/params';
import { formatToISODate } from 'helpers';

import { PositionInCompanyFormType } from '../../forms/positionInCompanyForm';

export const getSubmitData = (
  values: PositionInCompanyFormType
): SavePositionInCompanyParam => ({
  positionInCompany: values.position,
  positionConfirmationDocument: {
    dateOfExpiration: formatToISODate(values.dateOfExpiration),
    fileIds: values.fileIds,
  },
});
