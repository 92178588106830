import { getYesOrNoOptions } from 'helpers';
import { useTranslation } from 'libs/i18n';
import { Stack } from 'libs/ui';

import { Field } from '../../forms/baseDebtsForm';

export interface FormContentProps {
  isEntrepreneur?: boolean;
}

export const BaseDebtsFormContent = ({ isEntrepreneur }: FormContentProps) => {
  const { t } = useTranslation();

  return (
    <Stack spacing={32}>
      <Field.Price
        showOptionalMark
        label={t('onboarding.debts.taxes.label')}
        name="taxes"
        placeholder={t('onboarding.debts.taxes.placeholder')}
      />
      <Field.Price
        showOptionalMark
        label={t('onboarding.debts.fees.label')}
        name="fees"
        placeholder={t('onboarding.debts.fees.placeholder')}
      />
      <Field.Price
        showOptionalMark
        label={t('onboarding.debts.penalties.label')}
        name="penalties"
        placeholder={t('onboarding.debts.penalties.placeholder')}
      />
      <Field.Price
        showOptionalMark
        label={t('onboarding.debts.finesForViolation.label')}
        name="finesForViolation"
        placeholder={t('onboarding.debts.finesForViolation.placeholder')}
      />
      {isEntrepreneur && (
        <>
          <Field.Autocomplete
            label={t('onboarding.debts.bankruptcyProceedings.label')}
            name="bankruptcyProceedings"
            optionLabelKey="label"
            options={getYesOrNoOptions(t)}
            optionValueKey="value"
            placeholder={t('common.select.placeholder')}
            variant="select"
          />
          <Field.Autocomplete
            label={t(
              'onboarding.debts.delaysOrNonFulfillmentOfObligations.label'
            )}
            name="delaysOrNonFulfillmentOfObligations"
            optionLabelKey="label"
            options={getYesOrNoOptions(t)}
            optionValueKey="value"
            placeholder={t('common.select.placeholder')}
            variant="select"
          />
        </>
      )}
    </Stack>
  );
};
