import { getColor } from 'helpers';
import { Stack } from 'libs/ui';
import { styled } from 'libs/ui/styled';
import { ColorsDark, ColorsLight } from 'libs/ui/theme';

export const LoadingViewStyled = styled(Stack)(({ theme }) => ({
  height: '100%',
  flex: 1,
  [theme.breakpoints.up('mobile')]: {
    backgroundColor: getColor(theme, {
      dark: ColorsDark.gray50,
      light: ColorsLight.gray50,
    }),
  },

  [theme.breakpoints.down('mobile')]: {
    backgroundColor: getColor(theme, {
      dark: ColorsDark.black,
      light: ColorsLight.white,
    }),
  },
}));
