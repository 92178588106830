import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import {
  OnboardingLegalClientDocument,
  useLegalClientDeleteBranchMutation,
} from 'api/generated/graphql';
import { useError } from 'api/helpers';

export const useLegalClientDeleteBranch = () => {
  const [handle, { loading, error: apolloError }] =
    useLegalClientDeleteBranchMutation({
      refetchQueries: [OnboardingLegalClientDocument],
      awaitRefetchQueries: true,
    });

  const error = useError(apolloError);

  return {
    loading,
    error,
    deleteBranch: useCallback(
      async (id: string, branchId: string) => {
        const res = await handle({
          variables: {
            id,
            branchId,
          },
        });
        return res?.data?.legalClientDeleteBranch;
      },
      [handle]
    ),
  };
};
