import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import {
  OnboardingLegalClientDocument,
  useLegalClientBranchSaveNameMutation,
} from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { LegalClientSaveNameParam } from 'api/types/params/onboarding/branch/legalClientSaveNameParam';

import { parseLegalClientBranchEntity } from './helpers';

export const useLegalClientBranchSaveName = () => {
  const [handle, { data, loading, error: apolloError }] =
    useLegalClientBranchSaveNameMutation({
      refetchQueries: [OnboardingLegalClientDocument],
      awaitRefetchQueries: true,
    });

  const error = useError(apolloError);

  return {
    data: data?.legalClientBranchSaveName
      ? parseLegalClientBranchEntity(data.legalClientBranchSaveName)
      : undefined,
    loading,
    error,
    saveName: useCallback(
      async (
        id: string,
        branchId: string,
        params: LegalClientSaveNameParam
      ) => {
        const res = await handle({
          variables: {
            id,
            branchId,
            input: params,
          },
        });
        return res?.data?.legalClientBranchSaveName
          ? parseLegalClientBranchEntity(res.data.legalClientBranchSaveName)
          : undefined;
      },
      [handle]
    ),
  };
};
