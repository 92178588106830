import { OnboardingProcessStepNameType } from 'api/types/entity';

import {
  OnboardingBiographyStep,
  OnboardingCitizenshipStep,
  OnboardingRegistrationAddressStep,
  OnboardingTaxResidencyStep,
  PepConnectionStep,
  FinancingSourcesStep,
  DebtsStep,
  WithdrawalAccountsStep,
  ConfirmationDataStep,
  SignatureSamplesStep,
  AccountsInOrganizationsStep,
  OrderSubmissionContactsStep,
  KycStep,
} from './steps';
import { RoutePage } from './types';

export const pagesMap: Partial<
  Record<OnboardingProcessStepNameType, RoutePage['component']>
> = {
  [OnboardingProcessStepNameType.ClientCitizenships]: OnboardingCitizenshipStep,
  [OnboardingProcessStepNameType.ClientTaxResidencies]:
    OnboardingTaxResidencyStep,
  [OnboardingProcessStepNameType.ClientAddresses]:
    OnboardingRegistrationAddressStep,
  [OnboardingProcessStepNameType.ClientKyc]: KycStep,
  [OnboardingProcessStepNameType.ClientBiography]: OnboardingBiographyStep,
  [OnboardingProcessStepNameType.ClientBankAccounts]:
    AccountsInOrganizationsStep,
  [OnboardingProcessStepNameType.ClientFinancingSources]: FinancingSourcesStep,
  [OnboardingProcessStepNameType.ClientPep]: PepConnectionStep,
  [OnboardingProcessStepNameType.ClientDebts]: DebtsStep,
  [OnboardingProcessStepNameType.ClientWithdrawalAccounts]:
    WithdrawalAccountsStep,
  [OnboardingProcessStepNameType.ClientOrderSubmissionContacts]:
    OrderSubmissionContactsStep,
  [OnboardingProcessStepNameType.ClientSignatureCards]: SignatureSamplesStep,
  [OnboardingProcessStepNameType.ClientConfirmDataAccuracy]:
    ConfirmationDataStep,
};
