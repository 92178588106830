import { PropsWithChildren } from 'react';

import { useIsMobileSize } from 'hooks';

import {
  DialogSyled,
  PullerStyled,
  SwipeableDrawerSyled,
} from './Dialog.styles';
import { DialogProps } from './types';

/**
 * Dialog component.
 */
export const Dialog = ({
  isOpen,
  children,
  className,
  maxWidth = 'md',
  fullWidth,
  onClose,
}: DialogProps & PropsWithChildren) => {
  const isMobile = useIsMobileSize();

  if (isMobile) {
    return (
      <SwipeableDrawerSyled
        disableSwipeToOpen
        anchor="bottom"
        open={isOpen}
        onClose={() => onClose?.()}
        onOpen={() => {}}
      >
        <PullerStyled />
        {children}
      </SwipeableDrawerSyled>
    );
  }

  return (
    <DialogSyled
      className={className}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      open={isOpen}
      onClose={onClose}
    >
      {children}
    </DialogSyled>
  );
};
