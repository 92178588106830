import { useRef } from 'react';

import { useClientRepresentativeSaveCitizenships } from 'api/requests';
import { useServerErrorNotify } from 'hooks';
import { OnboardingCitizenshipFormType } from 'modules/onboarding/common/forms/onboardingCitizenshipForm';
import { getInitialData } from 'modules/onboarding/common/helpers/citizenship/getInitialData';
import { getSubmitData } from 'modules/onboarding/common/helpers/citizenship/getSubmitData';
import { useOnboardingCountries } from 'modules/onboarding/common/hooks';

import { useOnboardingContext } from './useOnboardingContext';

export const useCitizenship = () => {
  const { saveCitizenships, error, loading } =
    useClientRepresentativeSaveCitizenships();
  const { goToBack, goToNext, clientId, data } = useOnboardingContext();

  const { countries, loading: countriesLoading } = useOnboardingCountries();

  const initialValues = useRef(
    getInitialData(data.currentClient.citizenships)
  ).current;

  const onSubmit = async (values: OnboardingCitizenshipFormType) => {
    if (values.citizenships) {
      const submitData = getSubmitData(values);
      const res = await saveCitizenships(clientId, submitData);
      if (res) {
        goToNext();
      }
    }
  };

  useServerErrorNotify(error);

  return {
    onSubmit,
    goToBack,
    submitLoading: loading,
    initialValues,
    countries,
    countriesLoading,
  };
};
