import { ReactNode, useRef } from 'react';

import { useTranslation } from 'libs/i18n';
import { Box, Stack, Typography } from 'libs/ui';
import { useDialog } from 'libs/ui/Dialog/useDialog';

import { FormErrors, FormSubmit } from '../../forms/emailForm';

import { EmailFormDialog } from './EmailFormDialog';
import { Item } from './Item/Item';
import { LayoutStyled } from './WaitingKyc.styles';
import { WaitingKycUserType } from './types';

type Props = {
  users: WaitingKycUserType[];
  title?: string;
  subtitle: ReactNode;
  initialEmailEditErrors?: FormErrors;
  loadingEmailEdit?: boolean;
  onResend: (id: string) => void;
  onEditEmail: (id: string, email: string) => Promise<boolean>;
};

export const WaitingKyc = ({
  title,
  subtitle,
  users,
  initialEmailEditErrors,
  loadingEmailEdit,
  onEditEmail,
  onResend,
}: Props) => {
  const { t } = useTranslation();

  const { isOpen, onClose, open } = useDialog();

  const editItemid = useRef<string>();

  const handleSubmit: FormSubmit = async (values) => {
    if (editItemid.current) {
      if (await onEditEmail(editItemid.current, values.email)) {
        onClose();
      }
    }
  };

  const handleEdit = (id: string) => {
    editItemid.current = id;
    open();
  };

  return (
    <LayoutStyled variant="filled-all">
      <Stack
        alignItems="flex-start"
        className="waitingKyc-content"
        spacing={32}
      >
        <Typography
          datax="waitingKyc-title"
          variant="header"
          variantMobile="subtitle"
        >
          {title ?? t('onboarding.waitingKyc.title')}
        </Typography>

        {subtitle}

        <Box className="waitingKyc-line" />

        <Stack className="waitingKyc-items" spacing={32}>
          {users.map((user) => (
            <Item
              key={user.id}
              user={user}
              onEdit={handleEdit}
              onResend={onResend}
            />
          ))}
        </Stack>
      </Stack>

      <EmailFormDialog
        initialErrors={initialEmailEditErrors}
        isOpen={isOpen}
        submitLoading={loadingEmailEdit}
        onClose={onClose}
        onSubmit={handleSubmit}
      />
    </LayoutStyled>
  );
};
