import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useLegalClientBeneficiarySaveTaxResidencesMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { OnboardingTaxResidencesParam } from 'api/types/params';

import { parseLegalClientBeneficiaryEntity } from './helpers';

export const useLegalClientBeneficiarySaveTaxResidences = () => {
  const [handle, { data, loading, error: apolloError }] =
    useLegalClientBeneficiarySaveTaxResidencesMutation();

  const error = useError(apolloError);

  return {
    data: data?.legalClientBeneficiarySaveTaxResidences
      ? parseLegalClientBeneficiaryEntity(
          data.legalClientBeneficiarySaveTaxResidences
        )
      : undefined,
    loading,
    error,
    saveTaxResidences: useCallback(
      async (
        id: string,
        beneficiaryId: string,
        params: OnboardingTaxResidencesParam
      ) => {
        const res = await handle({
          variables: {
            id,
            beneficiaryId,
            input: params,
          },
        });
        return res?.data?.legalClientBeneficiarySaveTaxResidences
          ? parseLegalClientBeneficiaryEntity(
              res.data.legalClientBeneficiarySaveTaxResidences
            )
          : undefined;
      },
      [handle]
    ),
  };
};
