import { FormContainer } from 'components';
import { useTranslation } from 'libs/i18n';

import {
  CompanyAccountsInOrganizationsFormType,
  FormErrors,
  getSchema,
} from '../../forms/companyAccountsInOrganizationsForm';

import { FormContent, FormContentProps } from './FormContent';

interface Props extends FormContentProps {
  initialValues?: CompanyAccountsInOrganizationsFormType;
  onSubmit: (values: CompanyAccountsInOrganizationsFormType) => void;
  canGoBack?: boolean;
  onGoBack?: () => void;
  formError?: FormErrors;
  submitLoading?: boolean;
}

export const CompanyAccountsInOrganizations = ({
  initialValues,
  canGoBack,
  onGoBack,
  submitLoading,
  formError,
  onSubmit,
  ...formProps
}: Props) => {
  const schema = getSchema();
  const { t } = useTranslation();
  return (
    <FormContainer
      canGoBack={canGoBack}
      initialErrors={formError}
      initialValues={initialValues}
      submitLoading={submitLoading}
      subTitle={t('onboarding.companyAccountsInOrganizations.subtitle')}
      title={t('onboarding.accountsInOrganizations.title')}
      validationSchema={schema}
      onGoBack={onGoBack}
      onSubmit={onSubmit}
    >
      <FormContent {...formProps} />
    </FormContainer>
  );
};
