import { CountryEntity } from 'api/types/entity';
import { FileUpload, Stack } from 'libs/ui';

import { useFormContext } from '../../../forms/withdrawalAccountsForm';

import { Header, Fields, Buttons } from './components';

export interface FormContentProps {
  countriesLoading?: boolean;
  countries: CountryEntity[];

  files?: FileUpload[];
  onAddFiles?: (file: File[]) => void;
  onRemoveFile?: (file: FileUpload) => void;
}

export const FormContent = ({
  countriesLoading,
  countries,
  files,
  onAddFiles,
  onRemoveFile,
}: FormContentProps) => {
  const { values } = useFormContext();

  return (
    <Stack spacing={32}>
      {values.withdrawalAccounts.map((value, index) => (
        <Stack key={index.toLocaleString()} spacing={32}>
          <Stack spacing={24} spacingMobile={16}>
            <Header index={index} />

            <Fields
              countries={countries}
              countriesLoading={countriesLoading}
              files={files}
              index={index}
              onAddFiles={onAddFiles}
              onRemoveFile={onRemoveFile}
            />
          </Stack>

          <Buttons files={files} index={index} onRemoveFile={onRemoveFile} />
        </Stack>
      ))}
    </Stack>
  );
};
