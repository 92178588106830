import { SaveBaseDebtsParam } from 'api/types/params';
import {
  BaseDebtsOptionalPrice,
  BaseDebtsFormType,
} from 'modules/onboarding/common/forms/baseDebtsForm';
import { YesOrNoType } from 'types/yesOrNo';

const getPrice = (v?: BaseDebtsOptionalPrice) =>
  v && v.currency && v.price !== undefined && v.price !== null
    ? { currency: v.currency!, price: v.price! }
    : null;

export const getBaseDebtsSubmitData = (
  values: BaseDebtsFormType,
  isEntrepreneur: boolean
): SaveBaseDebtsParam => ({
  fees: getPrice(values.fees),
  finesForViolation: getPrice(values.finesForViolation),
  penalties: getPrice(values.penalties),
  taxes: getPrice(values.taxes),

  hasBankruptcyProceedings: isEntrepreneur
    ? values.bankruptcyProceedings?.value === YesOrNoType.Yes
    : undefined,

  hasDelaysOrNonFulfillmentOfObligations: isEntrepreneur
    ? values.delaysOrNonFulfillmentOfObligations?.value === YesOrNoType.Yes
    : undefined,
});
