import {
  TypedForm,
  FormErrors as FormErrorsBase,
  ObjectSchema,
} from 'libs/form';
import { TFunc } from 'libs/i18n';
import {
  BaseStateRegistrationFormType,
  getBaseScheme,
} from 'modules/onboarding/legal/forms/companyStateRegistrationForm';

export interface StateRegistrationFormType
  extends BaseStateRegistrationFormType {}

export const getSchema = (t: TFunc): ObjectSchema<StateRegistrationFormType> =>
  getBaseScheme(t);

export const { Field, Form, useFormContext, Submit } =
  TypedForm<StateRegistrationFormType>();

export type FormErrors = FormErrorsBase<StateRegistrationFormType>;
