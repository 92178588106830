import { lodash } from 'helpers';

import { OnboardingTaxResidencyFormType } from '../../forms/onboardingTaxResidencyForm';

export const getSubmitData = (values: OnboardingTaxResidencyFormType) => {
  const result = {
    taxResidences: lodash.compact(
      values.taxValues.map((v) => ({
        taxId: !v.taxNumberIsMissing ? v.taxNumber : undefined,
        country: v.country?.id!,
        reasonForMissingTaxId: v.taxNumberIsMissing
          ? v.reasonForAbsence
          : undefined,
      }))
    ),
  };

  return result;
};
