import { yesOrNotSelectValidation } from 'helpers';
import {
  TypedForm,
  FormErrors as FormErrorsBase,
  ObjectSchema,
  string,
  object,
  date,
  array,
} from 'libs/form';
import { TFunc } from 'libs/i18n';
import {
  getToday,
  getTodayWithResetHours,
} from 'modules/onboarding/common/helpers/getToday';
import { Maybe } from 'types/maybe';
import { YesOrNoSelectType, YesOrNoType } from 'types/yesOrNo';

export interface CompanyLicenseFormTypeValue {
  licensedActivityType?: string;
  licenseNumber?: string;
  dateOfIssue?: Date;
  dateOfExpiry?: Date | null;
  authorityName?: string;
  fileIds?: string[];
}

export interface CompanyLicenseFormType {
  companyActivityIsLicensed: Maybe<YesOrNoSelectType>;
  licenses: CompanyLicenseFormTypeValue[];
}

export const MAX_FILES = 10;
export const maxLengthLicenseNumber = 200;
export const maxLengthActivityType = 2000;
export const maxLengthAuthorityName = 2000;

const companyActivityIsLicensed = (v?: CompanyLicenseFormType) =>
  v?.companyActivityIsLicensed?.value === YesOrNoType.Yes;

export const getSchema = (t: TFunc): ObjectSchema<CompanyLicenseFormType> =>
  object({
    companyActivityIsLicensed: yesOrNotSelectValidation
      .default(null)
      .required(),
    licenses: array()
      .of(
        object({
          licensedActivityType: string().when((_, schema, opt) => {
            if (companyActivityIsLicensed(opt.context)) {
              return schema.required().max(maxLengthActivityType);
            }
            return schema;
          }),

          licenseNumber: string().when((_, schema, opt) => {
            if (companyActivityIsLicensed(opt.context)) {
              return schema.required().max(maxLengthLicenseNumber);
            }
            return schema;
          }),

          dateOfIssue: date().when((_, schema, opt) => {
            if (companyActivityIsLicensed(opt.context)) {
              return schema.required().max(getToday(), t('validation.maxDate'));
            }
            return schema;
          }),
          dateOfExpiry: date()
            .when((_, schema, opt) => {
              if (companyActivityIsLicensed(opt.context)) {
                return schema.min(
                  getTodayWithResetHours(),
                  t('validation.minDate')
                );
              }
              return schema;
            })
            .nullable()
            .default(null),
          authorityName: string().when((_, schema, opt) => {
            if (companyActivityIsLicensed(opt.context)) {
              return schema.required().max(maxLengthAuthorityName);
            }
            return schema;
          }),
          fileIds: array().when((_, schema, opt) => {
            if (companyActivityIsLicensed(opt.context)) {
              return schema
                .of(string().required())
                .required()
                .min(1)
                .max(MAX_FILES);
            }
            return schema;
          }),
        })
      )
      .default([]),
  });

export const { Field, Form, useFormContext, Submit } =
  TypedForm<CompanyLicenseFormType>();

export type FormErrors = FormErrorsBase<CompanyLicenseFormType>;
