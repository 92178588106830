import { setLocale } from 'yup';

import { TFunc } from './types';

/** Localization of Yup errors for i18n */
export function buildYupLocale(_: unknown, t: TFunc): void {
  setLocale({
    mixed: {
      required: t('validation.required'),
    },
    string: {
      email: t('validation.email'),
      min: ({ min }) => t('validation.min', { min }),
      max: ({ max }) => t('validation.max', { max }),
    },
  });
}
