import { FormContainer } from 'components';
import { useTranslation } from 'libs/i18n';
import { Stack } from 'libs/ui';

import {
  CompanyBusinessRelationshipFormType,
  FormErrors,
  getSchema,
} from '../../forms/companyBusinessRelationshipForm';

import { FormContent } from './FormContent';

interface Props {
  initialValues?: CompanyBusinessRelationshipFormType;
  onSubmit: (values: CompanyBusinessRelationshipFormType) => void;
  canGoBack?: boolean;
  onGoBack?: () => void;
  formError?: FormErrors;
  submitLoading?: boolean;
  submitDisabled?: boolean;
}

export const CompanyBusinessRelationship = ({
  initialValues,
  canGoBack,
  submitLoading,
  submitDisabled,
  formError,
  onGoBack,
  onSubmit,
}: Props) => {
  const { t } = useTranslation();
  const schema = getSchema(t);
  return (
    <FormContainer
      canGoBack={canGoBack}
      initialErrors={formError}
      initialValues={initialValues}
      submitDisabled={submitDisabled}
      submitLoading={submitLoading}
      subTitle={t('onboarding.companyBusinessRelationship.subtitle')}
      title={t('onboarding.companyBusinessRelationship.title')}
      validationSchema={schema}
      onGoBack={onGoBack}
      onSubmit={onSubmit}
    >
      <Stack spacing={32}>
        <FormContent />
      </Stack>
    </FormContainer>
  );
};
