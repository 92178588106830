import { useCallback, useState } from 'react';

import { Icon } from '../Icon';
import { Input } from '../Input';
import { Pressed } from '../Pressed';

import { PasswordInputProps } from './types';

/**
 * PasswordInput component.
 */
export const PasswordInput = (props: PasswordInputProps) => {
  const [type, setType] = useState<'password' | 'text'>('password');

  const switchType = useCallback(() => {
    setType((type) => (type === 'password' ? 'text' : 'password'));
  }, []);

  const IconRight = type === 'password' ? Icon.Eye : Icon.EyeOff;

  return (
    <Input
      {...props}
      rightElement={
        <Pressed onClick={switchType}>
          <IconRight />
        </Pressed>
      }
      type={type}
    />
  );
};
