// eslint-disable-next-line no-restricted-imports
import {
  LegalClientLegalHeadFirstHeadWithUserFragment,
  LegalClientLegalHeadForLegalClientFragment,
  LegalClientLegalHeadWithUserFragment,
} from 'api/generated/graphql';
import { LegalClientLegalHeadItemEntity } from 'api/types/entity/legal/legalClientLegalHeadItemEntity';
import { lodash } from 'helpers';

import { parseLegalClientFirstHeadItemEntity } from './parseLegalClientFirstHeadItemEntity';

export const parseLegalClientLegalHeadItemEntity = (
  data:
    | LegalClientLegalHeadForLegalClientFragment
    | LegalClientLegalHeadWithUserFragment
): LegalClientLegalHeadItemEntity => ({
  id: data.id,
  name: data.fullName ?? '',

  createdAt: new Date(data.createdAt),

  isCompletedByRepresentative:
    data.onboarding?.isCompletedByRepresentative ?? false,

  firstHeads: lodash
    .compact(data.firstHeads as LegalClientLegalHeadFirstHeadWithUserFragment[])
    .map(parseLegalClientFirstHeadItemEntity),
});
