import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useLegalClientLegalHeadSaveTaxResidencesMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { LegalClientSaveTaxResidencesParam } from 'api/types/params';

import { parseLegalClientLegalHeadEntity } from './helpers';

export const useLegalClientLegalHeadSaveTaxResidences = () => {
  const [handle, { data, loading, error: apolloError }] =
    useLegalClientLegalHeadSaveTaxResidencesMutation();

  const error = useError(apolloError);

  return {
    data: data?.legalClientLegalHeadSaveTaxResidences
      ? parseLegalClientLegalHeadEntity(
          data.legalClientLegalHeadSaveTaxResidences
        )
      : undefined,
    loading,
    error,
    saveTaxResidences: useCallback(
      async (
        id: string,
        legalHeadId: string,
        params: LegalClientSaveTaxResidencesParam
      ) => {
        const res = await handle({
          variables: {
            id,
            legalHeadId,
            input: params,
          },
        });
        return res?.data?.legalClientLegalHeadSaveTaxResidences
          ? parseLegalClientLegalHeadEntity(
              res.data.legalClientLegalHeadSaveTaxResidences
            )
          : undefined;
      },
      [handle]
    ),
  };
};
