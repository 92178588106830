import { useTranslation } from 'libs/i18n';
import { useNotify } from 'libs/notify';

import { OnboardingTaxResidencyFormType } from '../forms/onboardingTaxResidencyForm';

export const useBeforeSubmitTaxResidency = (
  handle: (values: OnboardingTaxResidencyFormType) => void
) => {
  const notify = useNotify();
  const { t } = useTranslation();

  return (values: OnboardingTaxResidencyFormType) => {
    const hasTaxNumber = values.taxValues?.some(
      (v) => !v.taxNumberIsMissing && v.taxNumber
    );

    if (!hasTaxNumber) {
      notify.show('error', t('onboarding.taxResidency.needTaxNumberError'));
      return;
    }

    handle(values);
  };
};
