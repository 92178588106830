import { ReactNode } from 'react';

import { Form, FormProps } from 'libs/form/Form';
import { Submit } from 'libs/form/Submit';
import { useTranslation } from 'libs/i18n';
import { Button, Typography } from 'libs/ui';

import {
  ContentStyled,
  FieldsStyled,
  FooterStyled,
  HeaderStyled,
} from './FormContainer.styles';

export interface FormContainerStepProps {
  step?: {
    count: number;
    current: number;
  };
}

export interface FormContainerProps<Values extends object>
  extends FormContainerStepProps,
    FormProps<Values> {
  title: string;
  subTitle?: string;
  subTitleContent?: ReactNode;
  submitLoading?: boolean;
  submitDisabled?: boolean;
  canGoBack?: boolean;
  onGoBack?: () => void;
  submitLabel?: string;
}

export const FormContainer = <Values extends object>({
  title,
  subTitle,
  subTitleContent,
  children,
  canGoBack,
  submitLoading,
  submitDisabled,
  onGoBack,
  submitLabel,
  step,
  ...formProps
}: FormContainerProps<Values>) => {
  const { t } = useTranslation();

  return (
    <ContentStyled as="section">
      <HeaderStyled>
        <Typography
          as="h1"
          className="formContainer-title"
          datax="onboarding-title"
          variant="title"
          variantMobile="subtitle"
        >
          {title}
        </Typography>
        {subTitleContent ||
          (!!subTitle && (
            <Typography
              as="p"
              datax="onboarding-subtitle"
              variantMobile="caption"
            >
              {subTitle}
            </Typography>
          ))}
      </HeaderStyled>

      <Form {...formProps}>
        <FieldsStyled>{children}</FieldsStyled>
        <FooterStyled>
          {canGoBack && (
            <Button label={t('common.back')} mode="text" onClick={onGoBack} />
          )}

          <Submit
            disabled={submitDisabled}
            label={
              submitLabel ||
              (step
                ? t('common.nextWithStep', {
                    current: step.current,
                    count: step.count,
                  })
                : t('common.next'))
            }
            loading={submitLoading}
          />
        </FooterStyled>
      </Form>
    </ContentStyled>
  );
};
