import { FormLayout } from 'components';
import { styled } from 'libs/ui/styled';

export const OnboardingLayoutStyled = styled(FormLayout)(({ theme }) => ({
  '& .formLayout-content': {
    marginTop: 0,
  },

  '.formLayout-header': {
    marginTop: 48,
    [theme.breakpoints.down('mobile')]: {
      marginTop: 32,
    },
    marginBottom: 8,
    width: '100%',

    '.onboardingLayout-progressBar': {
      width: '100%',
      maxWidth: 1100,
      margin: '0 auto',
      marginBottom: 32,
    },
  },
}));
