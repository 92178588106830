import {
  BrokerRelationshipPurposeType,
  IncomeSourcesType,
  PlannedOperationType,
} from 'api/types/entity';
import { useTranslation } from 'libs/i18n';
import { Stack } from 'libs/ui';
import {
  useFormContext,
  Field,
} from 'modules/onboarding/common/forms/financingSourcesForm';
import {
  BrokerRelationshipPurposePath,
  IncomeSourcesPath,
  PlannedOperationTypePath,
} from 'modules/onboarding/common/helpers/financingSources/getInitialData';

export const FormContent = () => {
  const { values } = useFormContext();
  const { t } = useTranslation();

  const isOtherIncomeSource =
    values.incomeSources?.find((v) => v?.value === IncomeSourcesType.Other) !==
    undefined;

  const isOtherBrokerRelationshipPurpose =
    values.brokerRelationshipPurpose?.find(
      (v) => v?.value === BrokerRelationshipPurposeType.Other
    ) !== undefined;

  const isOtherPlannedOperationType =
    values.plannedOperationTypes?.find(
      (v) => v?.value === PlannedOperationType.Other
    ) !== undefined;

  return (
    <Stack spacing={24} spacingMobile={16}>
      <Field.Autocomplete
        multiple
        label={t('onboarding.financingSources.incomeSources.label')}
        name="incomeSources"
        optionLabelKey="label"
        options={Object.values(IncomeSourcesType).map((v) => ({
          label: t(IncomeSourcesPath[v]),
          value: v,
        }))}
        optionValueKey="value"
        placeholder={t('onboarding.financingSources.incomeSources.placeholder')}
        variant="select"
      />

      {isOtherIncomeSource && (
        <Field.Text
          label={t('onboarding.financingSources.other.label')}
          name="otherIncomeSource"
          placeholder={t('onboarding.financingSources.other.label')}
        />
      )}

      <Field.Price
        label={t('onboarding.financingSources.incomeAmount.label')}
        name="incomeAmount"
        placeholder={t('onboarding.financingSources.incomeAmount.placeholder')}
      />

      <Field.Autocomplete
        multiple
        label={t('onboarding.financingSources.brokerRelationshipPurpose.label')}
        name="brokerRelationshipPurpose"
        optionLabelKey="label"
        options={Object.values(BrokerRelationshipPurposeType).map((v) => ({
          label: t(BrokerRelationshipPurposePath[v]),
          value: v,
        }))}
        optionValueKey="value"
        placeholder={t(
          'onboarding.financingSources.brokerRelationshipPurpose.placeholder'
        )}
        variant="select"
      />

      {isOtherBrokerRelationshipPurpose && (
        <Field.Text
          label={t('onboarding.financingSources.other.label')}
          name="otherBrokerRelationshipPurpose"
          placeholder={t('onboarding.financingSources.other.placeholder')}
        />
      )}

      <Field.Price
        label={t('onboarding.financingSources.plannedAssetAmount.label')}
        name="plannedAssetAmount"
        placeholder={t(
          'onboarding.financingSources.plannedAssetAmount.placeholder'
        )}
      />

      <Field.Autocomplete
        multiple
        label={t('onboarding.financingSources.plannedOperationTypes.label')}
        name="plannedOperationTypes"
        optionLabelKey="label"
        options={Object.values(PlannedOperationType).map((v) => ({
          label: t(PlannedOperationTypePath[v]),
          value: v,
        }))}
        optionValueKey="value"
        placeholder={t(
          'onboarding.financingSources.plannedOperationTypes.placeholder'
        )}
        variant="select"
      />

      {isOtherPlannedOperationType && (
        <Field.Text
          label={t('onboarding.financingSources.other.label')}
          name="otherPlannedOperationType"
          placeholder={t('onboarding.financingSources.other.placeholder')}
        />
      )}
    </Stack>
  );
};
