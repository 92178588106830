import { useCallback, useEffect, useState } from 'react';

const MINUTE = 60;

const getString = (n: number) => (n < 10 ? `0${n}` : n).toString();

const secondsToTime = (secs: number) => {
  const hours = Math.floor(secs / (60 * 60));

  const divisorForMinutes = secs % (60 * 60);
  const minutes = Math.floor(divisorForMinutes / 60);

  const divisorForSeconds = divisorForMinutes % 60;
  const seconds = Math.ceil(divisorForSeconds);

  const obj = {
    h: hours,
    m: minutes,
    s: seconds,
  };
  return obj;
};

export const useTimer = (seconds = MINUTE) => {
  const [timer, setTimer] = useState(seconds);
  const [reload, setReload] = useState(false);

  const reloadTimer = useCallback(
    (newSeconds = MINUTE) => [setTimer(newSeconds), setReload((prev) => !prev)],
    []
  );

  useEffect(() => {
    const interval = setInterval(
      () =>
        setTimer((prev) => {
          if (prev - 1 <= 0) clearInterval(interval);
          return prev - 1;
        }),
      1000
    );
    return () => clearInterval(interval);
  }, [reload]);

  const { m, s } = secondsToTime(timer);

  return {
    timer: `${getString(m)}:${getString(s)}`,
    reloadTimer,
    isTimerVisible: timer > 0,
  };
};
