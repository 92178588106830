import { ClientType } from 'modules/onboarding/common/components';

import { OnboardingDebts } from '../components';
import { useDebts } from '../hooks';
import { PageComponentProps } from '../types';

export const DebtsStep = ({ canGoBack }: PageComponentProps) => {
  const { goToBack, initialValues, onSubmit, submitLoading, isEntrepreneur } =
    useDebts();

  return (
    <OnboardingDebts
      canGoBack={canGoBack}
      clientType={ClientType.ClientRepresentative}
      initialValues={initialValues}
      isEntrepreneur={isEntrepreneur}
      submitLoading={submitLoading}
      onGoBack={goToBack}
      onSubmit={onSubmit}
    />
  );
};
