import {
  TypedForm,
  FormErrors as FormErrorsBase,
  ObjectSchema,
  string,
  object,
  mixed,
  array,
} from 'libs/form';
import { Maybe } from 'types/maybe';
import { SelectType } from 'types/selectType';
import { YesOrNoSelectType, YesOrNoType } from 'types/yesOrNo';

export interface HeadTypeInfoFormType {
  firstHead?: Maybe<YesOrNoSelectType>;
  body?: Maybe<SelectType<string>>;
  memberConfirmationFileIds?: string[];
}

export const MAX_FILES = 10;

export const getSchema = (
  needMemberConfirmationFiles: boolean = false
): ObjectSchema<HeadTypeInfoFormType> =>
  object({
    body: object({
      value: string().required(),
      label: string().required(),
    })
      .default(null)
      .required(),

    firstHead: object({
      value: mixed<YesOrNoType>().oneOf(Object.values(YesOrNoType)).required(),
      label: string().required(),
    })
      .default(null)
      .required(),

    memberConfirmationFileIds: array()
      .of(string().required())
      .when('headType', {
        is: () => needMemberConfirmationFiles,
        then: (schema) => schema.required().min(1).max(MAX_FILES),
      }),
  });

export const { Field, Form, useFormContext, Submit } =
  TypedForm<HeadTypeInfoFormType>();

export type FormErrors = FormErrorsBase<HeadTypeInfoFormType>;
