import { useCallback, useMemo } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useOnboardingClientLazyQuery } from 'api/generated/graphql';
import { useError } from 'api/helpers';

import { parseClientEntity } from '../helpers';

export const useOnboardingClientLazy = () => {
  const [handle, { data, loading, error: apolloError, refetch }] =
    useOnboardingClientLazyQuery({
      notifyOnNetworkStatusChange: true,
    });

  const getClient = useCallback(
    async (id: string) => {
      const res = await handle({ variables: { id } });

      return res?.data?.onboardingClient
        ? parseClientEntity(res.data.onboardingClient)
        : null;
    },
    [handle]
  );

  const client = useMemo(
    () =>
      data?.onboardingClient ? parseClientEntity(data.onboardingClient) : null,
    [data?.onboardingClient]
  );

  const error = useError(apolloError);

  return {
    getClient,
    refetch,
    client,
    loading,
    error,
  };
};
