import { Typography } from '../Typography';

import { LinkStyled } from './LinkLabel.styles';
import { LinkLabelProps } from './types';

/**
 * Link label component.
 */
export const LinkLabel = ({ label, ...linkProps }: LinkLabelProps) => (
  <LinkStyled {...linkProps}>
    <Typography className="link-label">{label}</Typography>
  </LinkStyled>
);
