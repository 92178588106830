import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useLoginByTokenMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { LoginData } from 'api/types/data';

export const useLoginByToken = () => {
  const [handle, { data, loading, error: apolloError }] =
    useLoginByTokenMutation();

  const error = useError(apolloError);

  return {
    data,
    loading,
    error,
    login: useCallback(
      async (token: string) => {
        const res = await handle({ variables: { token } });
        return res.data?.loginByToken as LoginData | undefined;
      },
      [handle]
    ),
  };
};
