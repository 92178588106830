import { useState } from 'react';

import { LegalClientInnerItemEntity } from 'api/types/entity/legal/legalClientInnerItemEntity';
import { FormContainer } from 'components';
import { useTranslation } from 'libs/i18n';
import { Button, Stack, Switch, Typography } from 'libs/ui';
import { Icon } from 'libs/ui/Icon';

import { DATAX_ADD_ITEM } from '../../../common/constants';
import { CompanyList } from '../CompanyList';

interface CompanyBranchesProps {
  branches: LegalClientInnerItemEntity[];
  canGoBack?: boolean;
  submitLoading?: boolean;
  deleteLoading?: boolean;
  errorBranchesIds?: string[];
  onGoBack?: () => void;
  onAdd: () => void;
  onEdit: (id: string) => void;
  onDelete: (id: string) => void;
  onSubmit: (hasBranches: boolean) => void;
}

export const CompanyBranches = ({
  canGoBack,
  submitLoading,
  branches,
  deleteLoading,
  errorBranchesIds,
  onGoBack,
  onEdit,
  onAdd,
  onDelete,
  onSubmit,
}: CompanyBranchesProps) => {
  const { t } = useTranslation();
  const [switchValue, setSwitchValue] = useState(false);

  const handleSubmit = () => {
    onSubmit(switchValue);
  };

  return (
    <FormContainer
      canGoBack={canGoBack}
      submitLoading={submitLoading}
      subTitle={t('onboarding.branches.subtitle')}
      title={t('onboarding.branches.title')}
      onGoBack={onGoBack}
      onSubmit={handleSubmit}
    >
      {!branches.length && (
        <Stack alignItems="center" direction="row" spacing={16}>
          <Typography variant="bodyBold">
            {t('onboarding.branches.switch.label')}
          </Typography>
          <Switch checked={switchValue} onChange={setSwitchValue} />
        </Stack>
      )}
      <CompanyList
        companies={branches.map((b) => ({
          id: b.id,
          name: b.name ?? '',
          type: t('onboarding.branches.type'),
        }))}
        deleteLoading={deleteLoading}
        errorIds={errorBranchesIds}
        errorText={t('onboarding.branches.filledErrorMessage')}
        onDelete={onDelete}
        onEdit={onEdit}
      />
      <Button
        fullWidth
        datax={DATAX_ADD_ITEM}
        disabled={switchValue}
        label={t('onboarding.branches.addButton')}
        mode="medium"
        startIcon={<Icon.Plus />}
        onClick={onAdd}
      />
    </FormContainer>
  );
};
