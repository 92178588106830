import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useLegalClientLegalHeadSettingsSaveMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';

import { parseOnboardingProcessEntity } from '../../helpers';

export const useLegalClientLegalHeadSettingsSave = () => {
  const [handle, { data, loading, error: apolloError }] =
    useLegalClientLegalHeadSettingsSaveMutation();

  const error = useError(apolloError);

  const saveSettings = useCallback(
    async (legalId: string, legalHeadId: string, currentStepName: string) => {
      const res = await handle({
        variables: {
          id: legalId,
          legalHeadId,
          input: {
            currentStepName,
          },
        },
      });
      return res.data?.legalClientLegalHeadSettingsSave?.process
        ? parseOnboardingProcessEntity(
            res.data?.legalClientLegalHeadSettingsSave?.process
          )
        : undefined;
    },
    [handle]
  );

  return {
    data,
    loading,
    error,
    saveSettings,
  };
};
