import {
  TypedForm,
  FormErrors as FormErrorsBase,
  ObjectSchema,
  string,
  object,
  array,
} from 'libs/form';

export interface CompanyActivityFormType {
  name: string;
  code?: string;
}

export interface CompanyActivitiesFormType {
  activityTypes: CompanyActivityFormType[];
}

export const getSchema = (): ObjectSchema<CompanyActivitiesFormType> =>
  object({
    activityTypes: array()
      .of(
        object({
          name: string().required().max(200),
          code: string().max(200),
        }).required()
      )
      .required(),
  });

export const { Field, Form, useFormContext, Submit } =
  TypedForm<CompanyActivitiesFormType>();

export type FormErrors = FormErrorsBase<CompanyActivitiesFormType>;
