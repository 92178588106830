import { LegalClientConstitutionalDocumentEntity } from 'api/types/entity';

import { CompanyConstituentDocsFormType } from '../../forms/companyConstituentDocs';

export const getInitialData = (
  constitutionalDocument?: LegalClientConstitutionalDocumentEntity
): CompanyConstituentDocsFormType | undefined => {
  if (
    !constitutionalDocument?.dateOfLastChange ||
    !constitutionalDocument?.files
  ) {
    return undefined;
  }

  return {
    dateOfLastChange: constitutionalDocument.dateOfLastChange,
    fileIds: constitutionalDocument.files.map((file) => file.id),
  };
};
