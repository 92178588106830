import { useLegalClientCreateLegalHead } from 'api/requests';
import { LegalClientEntity } from 'api/types/entity';
import { LegalClientCreateLegalHeadSaveParam } from 'api/types/params';

export const useCreateLegalHead = (legalClient: LegalClientEntity) => {
  const { createLegalHead, error: createLegalHeadError } =
    useLegalClientCreateLegalHead();

  const handleCreateLegalHead = async (
    param: LegalClientCreateLegalHeadSaveParam
  ) => {
    const legalHead = await createLegalHead(legalClient.id, param);
    return legalHead;
  };

  return {
    createLegalHead: handleCreateLegalHead,
    createLegalHeadError,
  };
};
