// eslint-disable-next-line no-restricted-imports
import { LinearProgress as ProgressBarBase } from '@mui/material';
import { getColor } from 'helpers';
import { ColorsDark, ColorsLight } from 'libs/ui/theme';

import { Typography } from '../Typography';
import { styled } from '../styled';

export const ProgressBarStyled = styled(ProgressBarBase)(({ theme }) => ({
  width: '100%',
  borderRadius: 20,
  background: getColor(theme, {
    light: ColorsLight.gray100,
    dark: ColorsDark.gray100,
  }),
  height: 8,

  '& .MuiLinearProgress-bar1Determinate': {
    background: getColor(theme, {
      light: ColorsLight.blue150,
      dark: ColorsDark.blue150,
    }),
  },
}));

export const ProgressStatusStyled = styled(Typography)({
  lineHeight: '16px',
});
