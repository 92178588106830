// eslint-disable-next-line no-restricted-imports
import {
  Query_LegalClientController_GetById_OnboardingStatus as LegalClientOnboardingStatusTypeApi1,
  Query_LegalClientController_GetHead_LegalClientOnboardingStatus as LegalClientOnboardingStatusTypeApi2,
  Query_LegalClientController_GetLegalHeadFirstHead_LegalClientOnboardingStatus as LegalClientOnboardingStatusTypeApi3,
  Query_LegalClientController_GetBeneficiary_LegalClientOnboardingStatus as LegalClientOnboardingStatusTypeApi4,
} from 'api/generated/graphql';
import { LegalClientOnboardingStatusType } from 'api/types/entity';
import { Maybe } from 'types/maybe';

type LegalClientStatusTypeApi =
  | LegalClientOnboardingStatusTypeApi1
  | LegalClientOnboardingStatusTypeApi2
  | LegalClientOnboardingStatusTypeApi3
  | LegalClientOnboardingStatusTypeApi4;

const statusMap: Record<
  LegalClientStatusTypeApi,
  LegalClientOnboardingStatusType
> = {
  [LegalClientOnboardingStatusTypeApi1.FillingOut]:
    LegalClientOnboardingStatusType.FillingOut,
  [LegalClientOnboardingStatusTypeApi1.AccountOpened]:
    LegalClientOnboardingStatusType.AccountOpened,
  [LegalClientOnboardingStatusTypeApi1.ComplianceCheck]:
    LegalClientOnboardingStatusType.ComplianceCheck,
  [LegalClientOnboardingStatusTypeApi1.AccountOpeningDenied]:
    LegalClientOnboardingStatusType.AccountOpeningDenied,
  [LegalClientOnboardingStatusTypeApi1.AmlCftCheck]:
    LegalClientOnboardingStatusType.AmlCftCheck,
  [LegalClientOnboardingStatusTypeApi1.AccountOpening]:
    LegalClientOnboardingStatusType.AccountOpening,
  [LegalClientOnboardingStatusTypeApi1.AwaitingAccountOpening]:
    LegalClientOnboardingStatusType.AwaitingAccountOpening,
  [LegalClientOnboardingStatusTypeApi1.AwaitingAmlCftCheck]:
    LegalClientOnboardingStatusType.AwaitingAmlCftCheck,
  [LegalClientOnboardingStatusTypeApi1.AwaitingComplianceCheck]:
    LegalClientOnboardingStatusType.AwaitingComplianceCheck,
  [LegalClientOnboardingStatusTypeApi1.AwaitingValidation]:
    LegalClientOnboardingStatusType.AwaitingValidation,
  [LegalClientOnboardingStatusTypeApi1.BoardReview]:
    LegalClientOnboardingStatusType.BoardReview,
  [LegalClientOnboardingStatusTypeApi1.ValidationCheck]:
    LegalClientOnboardingStatusType.ValidationCheck,
  [LegalClientOnboardingStatusTypeApi1.PendingBoardReview]:
    LegalClientOnboardingStatusType.PendingBoardReview,
  [LegalClientOnboardingStatusTypeApi1.SigningDocuments]:
    LegalClientOnboardingStatusType.SigningDocuments,
  [LegalClientOnboardingStatusTypeApi1.AwaitingPaymentConfirmation]:
    LegalClientOnboardingStatusType.AwaitingPaymentConfirmation,
};

export const parseLegalClientOnboardingStatus = (
  status: Maybe<LegalClientStatusTypeApi>
): LegalClientOnboardingStatusType | undefined =>
  status ? statusMap[status] : undefined;
