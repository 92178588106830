import { getColor } from 'helpers';
import { Pressed } from 'libs/ui';
import { styled } from 'libs/ui/styled';
import { ColorsDark, ColorsLight } from 'libs/ui/theme';

export const HintStyled = styled(Pressed)(({ theme }) => ({
  cursor: 'pointer',

  '.hint-info': {
    color: getColor(theme, {
      light: ColorsLight.blue150,
      dark: ColorsDark.blue150,
    }),

    ':hover': {
      color: theme.palette.hover,
    },
  },
}));
