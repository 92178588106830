import { Fragment } from 'react';

import { range } from 'helpers';

import { LineStyled, StepStyled, StepperStyled } from './Stepper.styles';

interface StepperProps {
  count: number;
  activeIndex: number;
}

export const Stepper = ({ count, activeIndex }: StepperProps) => (
  <StepperStyled>
    {range(count).map((i) => (
      <Fragment key={i.toString()}>
        <StepStyled active={activeIndex === i} passed={activeIndex > i} />
        {i !== count - 1 && <LineStyled passed={activeIndex > i} />}
      </Fragment>
    ))}
  </StepperStyled>
);
