import { FileUpload } from 'hooks';
import { useTranslation } from 'libs/i18n';
import { FileType } from 'libs/ui';
import { getTodayWithResetHours } from 'modules/onboarding/common/helpers/getToday';

import {
  Field,
  MAX_FILES,
  maxLengthPosition,
} from '../../../forms/positionInCompanyForm';

export interface FormContentProps {
  files?: FileUpload[];
  onAddFiles?: (file: File[]) => void;
  onRemoveFile?: (file: FileUpload) => void;
}

export const FormContent = ({
  files,
  onAddFiles,
  onRemoveFile,
}: FormContentProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Field.Text
        label={t('onboarding.positionInCompany.position.label')}
        maxLength={maxLengthPosition}
        name="position"
        placeholder={t('onboarding.positionInCompany.position.placeholder')}
      />

      <Field.DatePicker
        disableFuture={false}
        label={t('onboarding.positionInCompany.dateOfExpiration.label')}
        minDate={getTodayWithResetHours()}
        name="dateOfExpiration"
        placeholder={t(
          'onboarding.positionInCompany.dateOfExpiration.placeholder'
        )}
      />

      <Field.FilesUpload
        files={files}
        fileTypes={[FileType.pdf]}
        maxFiles={MAX_FILES}
        name="fileIds"
        title={t('onboarding.positionInCompany.documentTitle')}
        onAddFiles={(f) => onAddFiles?.(f)}
        onRemoveFile={onRemoveFile}
      />
    </>
  );
};
