import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useLegalClientLegalHeadFirstHeadSaveCitizenshipsMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { SaveCitizenshipParam } from 'api/types/params';

import { parseLegalClientLegalHeadFirstHeadEntity } from './helpers';

export const useLegalClientLegalHeadFirstHeadSaveCitizenships = () => {
  const [handle, { data, loading, error: apolloError }] =
    useLegalClientLegalHeadFirstHeadSaveCitizenshipsMutation();

  const error = useError(apolloError);

  return {
    data: data?.legalClientLegalHeadFirstHeadSaveCitizenships
      ? parseLegalClientLegalHeadFirstHeadEntity(
          data.legalClientLegalHeadFirstHeadSaveCitizenships
        )
      : undefined,
    loading,
    error,
    saveCitizenships: useCallback(
      async (
        id: string,
        legalHeadId: string,
        firstHeadId: string,
        params: SaveCitizenshipParam
      ) => {
        const res = await handle({
          variables: {
            id,
            legalHeadId,
            firstHeadId,
            input: params,
          },
        });
        return res?.data?.legalClientLegalHeadFirstHeadSaveCitizenships
          ? parseLegalClientLegalHeadFirstHeadEntity(
              res.data.legalClientLegalHeadFirstHeadSaveCitizenships
            )
          : undefined;
      },
      [handle]
    ),
  };
};
