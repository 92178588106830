import {
  TypedForm,
  FormErrors as FormErrorsBase,
  ObjectSchema,
  email,
  object,
  FormSubmit as FormSubmitBase,
} from 'libs/form';
import { TFunc } from 'libs/i18n';

export interface EmailFormType {
  email: string;
}

export const getSchema = (t: TFunc): ObjectSchema<EmailFormType> =>
  object({
    email: email(t).required(),
  });

export const { Field, Form, useFormContext, Submit } =
  TypedForm<EmailFormType>();

export type FormErrors = FormErrorsBase<EmailFormType>;
export type FormSubmit = FormSubmitBase<EmailFormType>;
