import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import {
  OnboardingLegalClientDocument,
  useLegalClientBeneficiarySaveControlOverMutation,
} from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { SaveControlOverLegalClientParam } from 'api/types/params/onboarding/saveControlOverLegalClientParam';

import { parsePrice } from '../../helpers/parsePrice';

import { parseLegalClientBeneficiaryEntity } from './helpers';

export const useLegalClientBeneficiarySaveControlOver = () => {
  const [handle, { data, loading, error: apolloError }] =
    useLegalClientBeneficiarySaveControlOverMutation({
      refetchQueries: [OnboardingLegalClientDocument],
      awaitRefetchQueries: true,
    });

  const error = useError(apolloError);

  return {
    data: data?.legalClientBeneficiarySaveControlOver
      ? parseLegalClientBeneficiaryEntity(
          data.legalClientBeneficiarySaveControlOver
        )
      : undefined,
    loading,
    error,
    saveControlOver: useCallback(
      async (
        id: string,
        beneficiaryId: string,
        params: SaveControlOverLegalClientParam
      ) => {
        const res = await handle({
          variables: {
            id,
            beneficiaryId,
            input: {
              ...params,
              financialConditionAmount: parsePrice(
                params.financialConditionAmount
              ),
            },
          },
        });
        return res?.data?.legalClientBeneficiarySaveControlOver
          ? parseLegalClientBeneficiaryEntity(
              res.data.legalClientBeneficiarySaveControlOver
            )
          : undefined;
      },
      [handle]
    ),
  };
};
