// eslint-disable-next-line no-restricted-imports
import {
  BusinessRelationshipDto_Input,
  Query_LegalClientController_GetById_BusinessRelationship_BusinessRelationshipGoals_Items,
  Query_LegalClientController_GetById_BusinessRelationship_BusinessRelationshipNatures_Items,
  Query_LegalClientController_GetById_BusinessRelationship_PlannedAssetTypes_Items,
  Query_LegalClientController_GetById_BusinessRelationship_PlannedOperationTypes_Items,
} from 'api/generated/graphql';
import { LegalClientSaveBusinessRelationshipParam } from 'api/types/params';

import { parsePrice } from '../../helpers/parsePrice';

export const parseLegalClientBusinessRelationsParam = (
  data: LegalClientSaveBusinessRelationshipParam
): BusinessRelationshipDto_Input => ({
  businessRelationshipGoals: data.businessRelationshipGoals.map(
    (v) =>
      Query_LegalClientController_GetById_BusinessRelationship_BusinessRelationshipGoals_Items[
        v
      ]
  ),
  businessRelationshipNatures: data.businessRelationshipNatures.map(
    (v) =>
      Query_LegalClientController_GetById_BusinessRelationship_BusinessRelationshipNatures_Items[
        v
      ]
  ),
  plannedAssetSize: parsePrice(data.plannedAssetSize),
  plannedAssetTypes: data.plannedAssetTypes.map(
    (v) =>
      Query_LegalClientController_GetById_BusinessRelationship_PlannedAssetTypes_Items[
        v
      ]
  ),
  plannedOperationTypes: data.plannedOperationTypes.map(
    (v) =>
      Query_LegalClientController_GetById_BusinessRelationship_PlannedOperationTypes_Items[
        v
      ]
  ),
  otherBusinessRelationshipGoal: data.otherBusinessRelationshipGoal,
  otherBusinessRelationshipNature: data.otherBusinessRelationshipNature,
  otherPlannedOperationType: data.otherPlannedOperationType,
});
