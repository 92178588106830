import { useCallback, useLayoutEffect, useMemo } from 'react';

import {
  useLegalClientSaveHeadEmail,
  useLegalClientSaveLegalHeadFirstHeadEmail,
  useLegalClientSendCorporateAccountVerificationEmailToBeneficiary,
  useLegalClientSendCorporateAccountVerificationEmailToHead,
  useLegalClientSendCorporateAccountVerificationEmailToLegalHeadFirstHead,
  useOnboardingLegalClientParticipantsLazy,
} from 'api/requests';
import { useLegalClientSaveBeneficiaryEmail } from 'api/requests/onboarding/legal/beneficiary';
import { KYCStatus } from 'api/types/data';
import {
  LegalClientEntity,
  LegalClientRepresentativeEntity,
  RoleInCompanyType,
} from 'api/types/entity';
import { ErrorCode } from 'api/types/error';
import { lodash } from 'helpers';
import { useTranslation } from 'libs/i18n';
import { useNotify } from 'libs/notify';

import { FormErrors } from '../forms/emailForm';

export const useParticipantsWaitingKyc = (
  currentClient: LegalClientRepresentativeEntity | null,
  legalClient: LegalClientEntity | null
) => {
  const notify = useNotify();
  const { t } = useTranslation();

  const { send: sendFirstHead } =
    useLegalClientSendCorporateAccountVerificationEmailToLegalHeadFirstHead();

  const { send: sendHead } =
    useLegalClientSendCorporateAccountVerificationEmailToHead();

  const { send: sendBeneficiary } =
    useLegalClientSendCorporateAccountVerificationEmailToBeneficiary();

  const { legalClientParticipants, getLegalClientParticipants } =
    useOnboardingLegalClientParticipantsLazy();

  const {
    saveHeadEmail,
    loading: loadingSaveHeadEmail,
    error: errorSaveHeadEmail,
  } = useLegalClientSaveHeadEmail();

  const {
    saveBeneficiaryEmail,
    loading: loadingSaveBeneficiaryEmail,
    error: errorSaveBeneficiaryEmail,
  } = useLegalClientSaveBeneficiaryEmail();

  const {
    saveFirstHeadEmail,
    loading: loadingSaveFirstHeadEmail,
    error: errorSaveFirstHeadEmail,
  } = useLegalClientSaveLegalHeadFirstHeadEmail();

  const isWaitingKyc = useMemo(() => {
    if (
      currentClient?.kyc?.status !== KYCStatus.success ||
      currentClient?.roleInCompany?.role === RoleInCompanyType.Director
    ) {
      return false;
    }

    const isWaitingHeadsKyc = legalClient?.heads?.some(
      ({ kyc }) => kyc?.status !== KYCStatus.success
    );

    const isWaitingFirstHeadsKyc = legalClient?.legalHeads?.some(
      ({ firstHeads }) =>
        firstHeads?.some(({ kyc }) => kyc?.status !== KYCStatus.success)
    );

    const isWaitingbeneficiariesHeadsKyc = legalClient?.beneficiaries?.some(
      ({ kyc }) => kyc?.status !== KYCStatus.success
    );

    return (
      isWaitingHeadsKyc ||
      isWaitingFirstHeadsKyc ||
      isWaitingbeneficiariesHeadsKyc
    );
  }, [
    currentClient?.kyc?.status,
    currentClient?.roleInCompany?.role,
    legalClient?.beneficiaries,
    legalClient?.heads,
    legalClient?.legalHeads,
  ]);

  const users = useMemo(() => {
    const participants = legalClientParticipants ?? legalClient;
    return lodash
      .compact(
        participants?.heads?.map((v) => ({
          id: v.id,
          done: v.kyc?.status === KYCStatus.success,
          name: v.name,
          email: v.email!,
          isActive: v.isActive,
        }))
      )
      .concat(
        lodash.compact(
          participants?.beneficiaries?.map((v) => ({
            id: v.id,
            done: v.kyc?.status === KYCStatus.success,
            name: v.name,
            email: v.email!,
            isActive: v.isActive,
          }))
        )
      )
      .concat(
        lodash
          .compact(
            participants?.legalHeads?.map((v) =>
              lodash.compact(
                v.firstHeads?.map((v) => ({
                  id: v.id,
                  done: v.kyc?.status === KYCStatus.success,
                  name: v.name,
                  email: v.email!,
                  isActive: v.isActive,
                }))
              )
            )
          )
          .flat()
      );
  }, [legalClient, legalClientParticipants]);

  const onResendCode = useCallback(
    (id: string) => {
      const head = legalClient?.heads?.find((v) => v.id === id);

      if (head) {
        sendHead(legalClient?.id!, id);
        return;
      }

      const beneficiary = legalClient?.beneficiaries?.find((v) => v.id === id);

      if (beneficiary) {
        sendBeneficiary(legalClient?.id!, id);
        return;
      }

      const legalHead = legalClient?.legalHeads?.find((v) =>
        v.firstHeads?.find((v) => v.id === id)
      );

      if (legalHead) {
        sendFirstHead(legalClient!.id, legalHead.id, id);
      }
    },
    [legalClient, sendBeneficiary, sendFirstHead, sendHead]
  );

  const onEditEmail = async (id: string, email: string) => {
    const head = legalClient?.heads?.find((v) => v.id === id);

    const handleResult = (res: any) => {
      if (res) {
        notify.info(t('onboarding.waitingKyc.emailSuccessEdit'));
      }
      return !!res;
    };

    if (head) {
      const res = await saveHeadEmail(legalClient?.id!, id, email);
      return handleResult(res);
    }

    const beneficiary = legalClient?.beneficiaries?.find((v) => v.id === id);

    if (beneficiary) {
      const res = await saveBeneficiaryEmail(legalClient?.id!, id, email);
      return handleResult(res);
    }

    const legalHead = legalClient?.legalHeads?.find((v) =>
      v.firstHeads?.find((v) => v.id === id)
    );

    if (legalHead) {
      const res = await saveFirstHeadEmail(
        legalClient!.id,
        legalHead.id,
        id,
        email
      );
      return handleResult(res);
    }

    return false;
  };

  const formErrorSaveEmail = useMemo((): FormErrors | undefined => {
    const error =
      errorSaveBeneficiaryEmail ||
      errorSaveFirstHeadEmail ||
      errorSaveHeadEmail;

    if (
      error?.code === ErrorCode.ACCOUNT_ALREADY_ACTIVE ||
      error?.code === ErrorCode.USER_WITH_EMAIL_ALREADY_EXISTS
    ) {
      return { email: error?.message };
    }

    return undefined;
  }, [errorSaveBeneficiaryEmail, errorSaveFirstHeadEmail, errorSaveHeadEmail]);

  useLayoutEffect(() => {
    if (legalClient?.id && isWaitingKyc) {
      getLegalClientParticipants(legalClient.id);
    }
  }, [getLegalClientParticipants, isWaitingKyc, legalClient?.id]);

  return {
    users,
    isWaitingKyc,
    loadingSaveEmail:
      loadingSaveHeadEmail ||
      loadingSaveBeneficiaryEmail ||
      loadingSaveFirstHeadEmail,
    formErrorSaveEmail,
    onResendCode,
    getLegalClientParticipants,
    onEditEmail,
  };
};
