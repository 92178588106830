import { useMemo } from 'react';

// eslint-disable-next-line no-restricted-imports
import {
  QueryInput_OnboardingController_GetProcessByScenarioName_ScenarioName,
  useOnboardingGetProcessByScenarioNameQuery,
} from 'api/generated/graphql';
import { useError } from 'api/helpers';

import { parseOnboardingProcessEntity } from './helpers';

export enum ScenarioName {
  Client = 'Client',
  ClientRepresentative = 'ClientRepresentative',
  LegalClientBeneficiary = 'LegalClientBeneficiary',
  LegalClientBranch = 'LegalClientBranch',
  LegalClientHead = 'LegalClientHead',
  LegalClientLegalHead = 'LegalClientLegalHead',
  LegalClientLegalHeadFirstHead = 'LegalClientLegalHeadFirstHead',
  LegalClientRepresentativeAuthorizedRepresentative = 'LegalClientRepresentative_AuthorizedRepresentative',
  LegalClientRepresentativeDirector = 'LegalClientRepresentative_Director',
  LegalClientRepresentativeOneOfDirectors = 'LegalClientRepresentative_OneOfDirectors',
}

export const useOnboardingGetProcessByScenarioName = (
  scenarioName: ScenarioName
) => {
  const { data, loading, error } = useOnboardingGetProcessByScenarioNameQuery({
    variables: {
      scenarioName:
        scenarioName as unknown as QueryInput_OnboardingController_GetProcessByScenarioName_ScenarioName,
    },
  });

  const serverError = useError(error);

  return {
    process: useMemo(
      () =>
        data?.onboardingProcess
          ? parseOnboardingProcessEntity(data?.onboardingProcess)
          : undefined,
      [data?.onboardingProcess]
    ),
    loading,
    error: serverError,
  };
};
