import { useRef } from 'react';

import { useLegalClientRepresentativeSaveRoleInCompany } from 'api/requests';
import { useFilesUpload } from 'hooks';
import { useTranslation } from 'libs/i18n';

import { RoleInCompanyFormType } from '../forms/roleInCompanyForm';
import { getInitialData, getSubmitData } from '../helpers/roleInCompany';

import { useOnboardingContext } from './useOnboardingContext';

export const useRoleInCompany = () => {
  const { goToBack, goToNext, data } = useOnboardingContext();
  const { t } = useTranslation();

  const {
    error,
    loading: submitLoading,
    saveRoleInCompany,
  } = useLegalClientRepresentativeSaveRoleInCompany();

  const initialData = useRef(
    getInitialData(data.currentClient.roleInCompany, t)
  ).current;

  const initialValues = useRef(initialData?.values).current;

  const {
    files,
    isLoadingFile: submitDisabled,
    onRemoveFile,
    onAddFiles,
  } = useFilesUpload(initialData.files);

  const handleSubmit = async (values: RoleInCompanyFormType) => {
    if (values.fileIds) {
      const params = getSubmitData(values);
      await saveRoleInCompany(data.currentClient.id, params);
      goToNext();
    }
  };

  return {
    onSubmit: handleSubmit,
    goToBack,
    submitLoading,
    initialValues,
    files,
    error,
    onAddFiles,
    onRemoveFile,
    submitDisabled,
  };
};
