export interface OrderSubmissionContactEntity {
  value: string;
  type: OrderSubmissionContactType;
}

export enum OrderSubmissionContactType {
  WhatsApp = 'WhatsApp',
  Telegram = 'Telegram',
  Email = 'Email',
  Phone = 'Phone',
}
