// eslint-disable-next-line no-restricted-imports
import { Autocomplete as AutocompleteBase } from '@mui/material';
import { GlobalClasses, lodash } from 'helpers';
import { useTranslation } from 'libs/i18n';

import { Icon } from '../Icon';
import { Input } from '../Input';
import { Typography } from '../Typography';

import { AutocompleteStyled } from './Autocomplete.styles';
import { AutocompleteProps } from './types';

/**
 * Autocomplete component.
 */
export const Autocomplete = <
  Value extends object,
  Multiple extends boolean | undefined = false
>({
  fullWidth = true,
  value,
  label,
  placeholder,
  loading,
  loadingText,
  error,
  helperText,
  options,
  name,
  optionLabelKey,
  optionValueKey,
  className,
  disabled,
  hideClearIcon,
  variant,
  multiple,
  onChange,
  getOptionDisabled,
}: AutocompleteProps<Value, Multiple>) => {
  const { t } = useTranslation();

  return (
    <AutocompleteStyled>
      <AutocompleteBase
        className={className}
        clearIcon={
          hideClearIcon ? null : (
            <Icon.Close className={GlobalClasses.hovered} size={16} />
          )
        }
        disableClearable={hideClearIcon}
        disableCloseOnSelect={multiple}
        disabled={disabled}
        filterOptions={(options, state) =>
          lodash.searchBy(options, state.inputValue, (option) =>
            String(option[optionLabelKey])
          )
        }
        fullWidth={fullWidth}
        getOptionDisabled={getOptionDisabled}
        getOptionLabel={(v) => v[optionLabelKey] as string}
        isOptionEqualToValue={(v, option) =>
          v[optionValueKey] === option[optionValueKey]
        }
        loading={loading}
        loadingText={loadingText || `${t('common.loading')}...`}
        multiple={multiple}
        noOptionsText={t('components.autocomplete.noOptionsText')}
        options={options}
        popupIcon={
          <Icon.ArrowDown className={GlobalClasses.hovered} size={24} />
        }
        renderInput={(props) => {
          const { inputProps } = props;
          inputProps.readOnly = variant === 'select';

          props.inputProps = inputProps;
          return (
            <Input
              {...props}
              error={error}
              helperText={helperText}
              label={label}
              name={name}
              placeholder={
                value instanceof Array && value.length > 0
                  ? undefined
                  : placeholder
              }
            />
          );
        }}
        renderTags={(value) => {
          const labels = value.map(
            (option) => option[optionLabelKey] as string
          );
          labels.join(', ');

          return (
            <Typography className="autocomplete-tag">
              {labels.join(', ')}
            </Typography>
          );
        }}
        slotProps={{
          popupIndicator: { disableRipple: true },
          clearIndicator: { disableRipple: true },
        }}
        sx={{
          "& button[title='Clear']": {
            visibility: 'visible',
          },
        }}
        value={value}
        onChange={onChange}
      />
    </AutocompleteStyled>
  );
};
