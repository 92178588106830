import { useCallback, useLayoutEffect, useRef, useState } from 'react';

import {
  ScenarioName,
  useOnboardingGetProcessByScenarioName,
} from 'api/requests';
import {
  useLegalClientBranchSettingsSave,
  useLegalClientCompleteBranchOnboarding,
  useLegalClientCreateBranch,
  useLegalClientGetBranchLazy,
} from 'api/requests/onboarding/legal/branch';
import {
  LegalClientEntity,
  LegalClientRepresentativeEntity,
} from 'api/types/entity';

import { NameFormType } from '../forms/nameForm';

export const useOnboarding = (
  currentClient: LegalClientRepresentativeEntity,
  legalClient: LegalClientEntity,
  legalClientBranchId?: string
) => {
  const branchId = useRef(legalClientBranchId);
  const [loading, setLoading] = useState(false);

  const { saveSettings } = useLegalClientBranchSettingsSave();
  const { getBranch, legalClientBranch } = useLegalClientGetBranchLazy();

  const { complete } = useLegalClientCompleteBranchOnboarding();

  const { createBranch } = useLegalClientCreateBranch();

  const { process, loading: processLoading } =
    useOnboardingGetProcessByScenarioName(ScenarioName.LegalClientBranch);

  const handleCreateBranch = async (name: NameFormType) => {
    const branch = await createBranch(legalClient.id, name);
    if (branch) {
      await getBranch(legalClient.id, branch.id);
    }
    return branch;
  };

  const onGoBack = useCallback(
    async (currentStepName: string) => {
      if (legalClientBranchId) {
        await saveSettings(
          legalClient.id,
          legalClientBranchId,
          currentStepName
        );
      }
    },
    [legalClient.id, legalClientBranchId, saveSettings]
  );

  useLayoutEffect(() => {
    if (branchId.current) {
      setLoading(true);
      getBranch(legalClient.id, branchId.current).finally(() =>
        setLoading(false)
      );
    }
  }, [getBranch, legalClient.id]);

  const onComplete = async () => complete(legalClient.id, legalClientBranchId!);

  return {
    currentClient,
    legalClientBranch,
    legalClient,
    loading: loading || (processLoading && !process),
    process,
    createBranch: handleCreateBranch,
    onGoBack,
    onComplete,
  };
};
