import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useLegalClientSaveConstitutionalDocumentMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { LegalClientSaveConstitutionalDocumentParam } from 'api/types/params';

import { parseLegalClient } from './helpers';

export const useLegalClientSaveConstitutionalDocument = () => {
  const [handle, { data, loading, error: apolloError }] =
    useLegalClientSaveConstitutionalDocumentMutation();

  const error = useError(apolloError);

  return {
    data: data?.legalClientSaveConstitutionalDocument
      ? parseLegalClient(data.legalClientSaveConstitutionalDocument)
      : undefined,
    loading,
    error,
    saveConstitutionalDocument: useCallback(
      async (
        id: string,
        params: LegalClientSaveConstitutionalDocumentParam
      ) => {
        const res = await handle({
          variables: {
            id,
            input: params,
          },
        });
        return res?.data?.legalClientSaveConstitutionalDocument
          ? parseLegalClient(res.data.legalClientSaveConstitutionalDocument)
          : undefined;
      },
      [handle]
    ),
  };
};
