import { RegistrationQrCodeGenerateData } from 'api/types/data';
import { OneLinkToMobileStore, FormContainer } from 'components';
import { useTranslation } from 'libs/i18n';
import { Typography, Stack } from 'libs/ui';

import {
  RegistrationTwoFactorFormType,
  FormErrors,
  schema,
} from '../../forms/registrationTwoFactorForm';

import { FormContent } from './FormContent';
import { QrCode } from './QrCode';
import { ContentStyled, ListStyled } from './styles';

interface Props {
  onSubmit: (values: RegistrationTwoFactorFormType) => void;
  canGoBack?: boolean;
  onGoBack?: () => void;
  formError: FormErrors;
  qrData?: RegistrationQrCodeGenerateData;
  qrLoading?: boolean;
  submitLoading?: boolean;
}

export const RegistrationTwoFactor = ({
  onSubmit,
  onGoBack,
  canGoBack,
  formError,
  qrData,
  qrLoading,
  submitLoading,
}: Props) => {
  const { t } = useTranslation();

  const androidLink =
    'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2';
  const iosLink =
    'https://apps.apple.com/ru/app/google-authenticator/id388497605';

  return (
    <FormContainer
      canGoBack={canGoBack}
      submitLoading={submitLoading}
      title={t('auth.registration.twoFactor.title')}
      validationSchema={schema}
      onGoBack={onGoBack}
      onSubmit={onSubmit}
    >
      <Stack spacing={32}>
        <ContentStyled>
          <ListStyled as="ol" spacing={32}>
            <Typography as="li" variantMobile="caption">
              {t('auth.registration.twoFactor.step1')}
              <OneLinkToMobileStore
                androidLink={androidLink}
                className="registrationTwoFactor-googleLink"
                iosLink={iosLink}
                label={t('auth.registration.twoFactor.googleAuthentificator')}
                renderEmpty={() => (
                  <>{t('auth.registration.twoFactor.googleAuthentificator')}</>
                )}
              />
              ).
            </Typography>
            <Typography as="li" variantMobile="caption">
              {t('auth.registration.twoFactor.step2')}
            </Typography>
            <Typography as="li" variantMobile="caption">
              {t('auth.registration.twoFactor.step3')}
            </Typography>
          </ListStyled>
          <QrCode data={qrData} loading={qrLoading} />
        </ContentStyled>
        <FormContent formError={formError} />
      </Stack>
    </FormContainer>
  );
};
