import { useState } from 'react';

import { useLegalClientSaveBranchesStep } from 'api/requests';
import { useLegalClientDeleteBranch } from 'api/requests/onboarding/legal/branch';
import { LegalClientInnerItemEntity } from 'api/types/entity/legal/legalClientInnerItemEntity';
import { useTranslation } from 'libs/i18n';
import { useNotify } from 'libs/notify';

import { useOnboardingContext } from './useOnboardingContext';

const checkFullness = (b: LegalClientInnerItemEntity) =>
  !!b.isCompletedByRepresentative;

export const useBranches = () => {
  const notify = useNotify();
  const { t } = useTranslation();

  const [errorBranchesIds, setErrorBranchesIds] = useState<string[]>();

  const { goToBack, goToNext, data } = useOnboardingContext();

  const { deleteBranch, loading: deleteLoading } = useLegalClientDeleteBranch();

  const { saveBranchesStep, loading: saveBranchesStepLoading } =
    useLegalClientSaveBranchesStep();

  const branches: LegalClientInnerItemEntity[] =
    data.legalClient?.branches ?? [];

  const onSubmit = async (impossibleIdentifyBranch: boolean) => {
    if (!impossibleIdentifyBranch) {
      if (!branches?.length) {
        notify.error(Error(t('onboarding.branches.emptyErrorMessage')));
        return;
      }

      const notFulled = branches.filter((b) => !checkFullness(b));

      if (notFulled.length) {
        setErrorBranchesIds(notFulled.map((b) => b.id));

        return;
      }
    }

    await saveBranchesStep(data.legalClient?.id!);

    goToNext();
  };

  const onAdd = () => {
    data.innerState.setBranch();
  };

  const onEdit = (id: string) => {
    if (id) {
      data.innerState.setBranch(id);
    }
  };

  const onDelete = (id: string) => {
    if (id) {
      deleteBranch(data.legalClient?.id!, id);
    }
  };

  return {
    onSubmit,
    goToBack,
    onAdd,
    onEdit,
    onDelete,
    errorBranchesIds,
    deleteLoading,
    branches,
    submitLoading: saveBranchesStepLoading,
    addLoading: false,
  };
};
