import React, { useRef, useEffect, useState } from 'react';

import { range } from 'helpers';

import { Stack } from '../Stack';
import { cx } from '../theme';

import { InputContentStyled } from './VerificationCode.styles';
import { VerificationCodeProps } from './types';

export const VerificationCode = ({
  autoFocus = true,
  length = 6,
  isError,
  pattern = '[0-9]{1}',
  onChange,
  onComplete,
}: VerificationCodeProps) => {
  const inputsRef = useRef<Array<HTMLInputElement>>([]);

  const [localValue, setLocalValue] = useState('');

  const sendResult = () => {
    const res = inputsRef.current.map((input) => input.value).join('');
    setLocalValue(res);
    onChange(res);
    if (res.length === length) {
      onComplete?.(res);
    }
  };

  const getNextInput = (input: HTMLInputElement) => {
    const { parentElement } = input;
    if (parentElement?.nextElementSibling !== null) {
      return parentElement?.nextElementSibling.children[0] as HTMLInputElement;
    }
    return undefined;
  };

  const getPrevInput = (input: HTMLInputElement) => {
    const { parentElement } = input;
    if (parentElement?.previousElementSibling !== null) {
      return parentElement?.previousElementSibling
        .children[0] as HTMLInputElement;
    }
    return undefined;
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = e;
    if (value.length > 1) {
      e.target.value = value.charAt(0);
      const nextInput = getNextInput(e.target);
      nextInput?.focus();
    } else if (value.match(pattern)) {
      const nextInput = getNextInput(e.target);
      nextInput?.focus();
    } else {
      e.target.value = '';
    }
    sendResult();
  };

  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    if (e.key === 'Backspace') {
      if (target.value === '') {
        const nextInput = getPrevInput(target);
        if (nextInput) {
          nextInput.value = '';
          nextInput.focus();
          e.preventDefault();
        }
      } else {
        target.value = '';
      }
      sendResult();
    }
  };

  const handleOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    e.target.select();
  };

  const handleOnPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const pastedValue = e.clipboardData.getData('Text');

    let currentInput = 0;

    range(pastedValue.length).forEach((v, i) => {
      const pastedCharacter = pastedValue.charAt(i);
      const currentValue = inputsRef.current[currentInput].value;
      if (pastedCharacter.match(pattern)) {
        if (!currentValue) {
          inputsRef.current[currentInput].value = pastedCharacter;

          const nextInput = getNextInput(inputsRef.current[currentInput]);

          if (nextInput) {
            nextInput.focus();
            currentInput += 1;
          }
        }
      }
    });

    sendResult();

    e.preventDefault();
  };

  useEffect(() => {
    if (autoFocus) {
      inputsRef.current[0].focus();
    }
  }, [autoFocus]);

  useEffect(() => {
    if (isError) {
      inputsRef.current.forEach((input) => {
        input.value = '';
      });
      inputsRef.current[0].focus();
    }
  }, [isError]);

  return (
    <Stack className="verificationCode-inputs" direction="row" spacing={16}>
      {range(length).map((i) => (
        <InputContentStyled
          key={i}
          className={cx(
            'text',
            localValue?.[i] && 'filled',
            isError && 'error'
          )}
        >
          <input
            ref={(el: HTMLInputElement) => {
              inputsRef.current[i] = el;
            }}
            autoComplete={i === 0 ? 'one-time-code' : 'off'}
            inputMode="numeric"
            pattern="[0-9]{1}"
            type="tel"
            onChange={handleOnChange}
            onFocus={handleOnFocus}
            onKeyDown={handleOnKeyDown}
            onPaste={handleOnPaste}
          />
        </InputContentStyled>
      ))}
    </Stack>
  );
};
