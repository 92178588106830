import { useTranslation } from 'libs/i18n';
import { Stack, Typography } from 'libs/ui';
import { Field } from 'modules/onboarding/common/forms/onboardingTaxResidencyForm';

interface Props {
  index: number;
}

export const Header = ({ index }: Props) => {
  const { t } = useTranslation();
  return (
    <Stack direction="row" justifyContent="space-between">
      <Typography variant="subtitle" variantMobile="bodyBold">
        {t(
          index === 0
            ? 'onboarding.taxResidency.taxNumberTitle'
            : 'onboarding.taxResidency.taxNumberOtherCountryTitle'
        )}
      </Typography>
      <Stack alignItems="center" direction="row" spacing={16}>
        <Typography variant="bodyMedium">
          {t('onboarding.taxResidency.taxNumberIsMissing')}
        </Typography>
        <Field.Switch
          name={`taxValues.${index}.taxNumberIsMissing` as 'taxValues'}
        />
      </Stack>
    </Stack>
  );
};
