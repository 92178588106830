import { OnboardingProcessStepNameType } from 'api/types/entity';

import {
  PersonalDataStep,
  CitizenshipStep,
  PepConnectionStep,
  RegistrationAddressStep,
  PositionInCompanyStep,
  TaxResidencyStep,
} from './steps';
import { RoutePage } from './types';

export const pagesMap: Partial<
  Record<OnboardingProcessStepNameType, RoutePage['component']>
> = {
  [OnboardingProcessStepNameType.LegalClientLegalHeadFirstHeadPersonalData]:
    PersonalDataStep,
  [OnboardingProcessStepNameType.LegalClientLegalHeadFirstHeadCitizenships]:
    CitizenshipStep,
  [OnboardingProcessStepNameType.LegalClientLegalHeadFirstHeadTaxResidencies]:
    TaxResidencyStep,
  [OnboardingProcessStepNameType.LegalClientLegalHeadFirstHeadAddresses]:
    RegistrationAddressStep,
  [OnboardingProcessStepNameType.LegalClientLegalHeadFirstHeadPep]:
    PepConnectionStep,
  [OnboardingProcessStepNameType.LegalClientLegalHeadFirstHeadPositionInCompany]:
    PositionInCompanyStep,
};
