// eslint-disable-next-line no-restricted-imports
import { CountryFragment } from 'api/generated/graphql';
import { CountryEntity } from 'api/types/entity';

export const parseCountryEntity = (
  country: CountryFragment
): CountryEntity => ({
  id: country.id,
  name: country.name,
});
