import { SaveCompanyRegistrationPlaceParam } from 'api/types/params';

import { CompanyRegistrationPlaceFormType } from '../../forms/companyRegistrationPlaceForm';

export const getSubmitData = (
  values: CompanyRegistrationPlaceFormType
): SaveCompanyRegistrationPlaceParam => ({
  locality: values.locality,
  country: values.country?.id!,
  specialRegistrationArea: values.specialRegistrationArea
    ? values.specialRegistrationArea
    : undefined,
});
