import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import {
  OnboardingLegalClientDocument,
  useLegalClientDeleteHeadMutation,
} from 'api/generated/graphql';
import { useError } from 'api/helpers';

export const useLegalClientHeadDeleteHead = () => {
  const [handle, { loading, error: apolloError }] =
    useLegalClientDeleteHeadMutation({
      refetchQueries: [OnboardingLegalClientDocument],
      awaitRefetchQueries: true,
    });

  const error = useError(apolloError);

  return {
    loading,
    error,
    deleteHead: useCallback(
      async (id: string, headId: string) => {
        await handle({
          variables: {
            id,
            headId,
          },
        });
      },
      [handle]
    ),
  };
};
