import {
  BrokerRelationshipPurposeType,
  IncomeSourcesType,
  PlannedOperationType,
} from 'api/types/entity/clientEntity';
import {
  TypedForm,
  FormErrors as FormErrorsBase,
  ObjectSchema,
  string,
  object,
  array,
  mixed,
  priceAndCurrency,
} from 'libs/form';
import { PriceFieldValue } from 'libs/form/fields/PriceField';
import { TFunc } from 'libs/i18n';
import { SelectType } from 'types/selectType';

export interface FinancingSourcesFormType {
  incomeSources: SelectType<IncomeSourcesType>[];
  otherIncomeSource?: string;

  incomeAmount: PriceFieldValue;
  brokerRelationshipPurpose: SelectType<BrokerRelationshipPurposeType>[];
  otherBrokerRelationshipPurpose?: string;

  plannedAssetAmount: PriceFieldValue;
  plannedOperationTypes: SelectType<PlannedOperationType>[];
  otherPlannedOperationType?: string;

  incomeSourceFileIds: string[];
}

export const MAX_FILES = 10;

const isOtherIncomeSource = (values: SelectType<IncomeSourcesType>[]) =>
  values?.find((v) => v?.value === IncomeSourcesType.Other) !== undefined;

const isOtherBrokerRelationshipPurpose = (
  values: SelectType<BrokerRelationshipPurposeType>[]
) =>
  values?.find((v) => v?.value === BrokerRelationshipPurposeType.Other) !==
  undefined;

const isOtherPlannedOperationType = (
  values: SelectType<PlannedOperationType>[]
) => values?.find((v) => v?.value === PlannedOperationType.Other) !== undefined;

export const getSchema = (t: TFunc): ObjectSchema<FinancingSourcesFormType> =>
  object({
    incomeSourceFileIds: array()
      .of(string().required())
      .required()
      .min(1)
      .max(MAX_FILES),

    otherIncomeSource: string().when('incomeSources', {
      is: isOtherIncomeSource,
      then: (schema) => schema.required().max(200),
    }),
    otherBrokerRelationshipPurpose: string().when('brokerRelationshipPurpose', {
      is: isOtherBrokerRelationshipPurpose,
      then: (schema) => schema.required().max(2000),
    }),
    otherPlannedOperationType: string().when('plannedOperationTypes', {
      is: isOtherPlannedOperationType,
      then: (schema) => schema.required().max(200),
    }),

    incomeAmount: priceAndCurrency().required(),

    plannedAssetAmount: priceAndCurrency().required(),

    incomeSources: array()
      .of(
        object({
          value: mixed<IncomeSourcesType>()
            .oneOf(Object.values(IncomeSourcesType))
            .required(),
          label: string().required(),
        })
          .default(null)
          .required()
      )
      .min(1, t('validation.required'))
      .required(),

    brokerRelationshipPurpose: array()
      .of(
        object({
          value: mixed<BrokerRelationshipPurposeType>()
            .oneOf(Object.values(BrokerRelationshipPurposeType))
            .required(),
          label: string().required(),
        })
          .default(null)
          .required()
      )
      .min(1, t('validation.required'))
      .required(),

    plannedOperationTypes: array()
      .of(
        object({
          value: mixed<PlannedOperationType>()
            .oneOf(Object.values(PlannedOperationType))
            .required(),
          label: string().required(),
        })
          .default(null)
          .required()
      )
      .min(1, t('validation.required'))
      .required(),
  });

export const { Field, Form, useFormContext, Submit } =
  TypedForm<FinancingSourcesFormType>();

export type FormErrors = FormErrorsBase<FinancingSourcesFormType>;
