import { useTranslation } from 'libs/i18n';
import { FileType, FileUpload } from 'libs/ui';

import {
  InvoiceConfirmForPaymentFormType,
  Field,
  FormErrors,
  getSchema,
  MAX_FILES,
} from '../../forms/invoiceConfirmForPaymentForm';
import { DownloadContent } from '../DownloadContent';
import { ClientType, FormBodyWrapper } from '../FormBodyWrapper';

interface Props {
  initialValues?: InvoiceConfirmForPaymentFormType;
  onSubmit: (values: InvoiceConfirmForPaymentFormType) => void;
  canGoBack?: boolean;
  onGoBack?: () => void;
  formError?: FormErrors;
  submitLoading?: boolean;
  submitDisabled?: boolean;
  clientType?: ClientType;
  files?: FileUpload[];
  onAddFiles?: (file: File[]) => void;
  onRemoveFile?: (file: FileUpload) => void;
  invoiceLink: string;
}

export const InvoiceConfirmForPayment = ({
  initialValues,
  canGoBack,
  submitLoading,
  submitDisabled,
  formError,
  files,
  onAddFiles,
  onRemoveFile,
  onGoBack,
  onSubmit,
  invoiceLink,
  clientType,
}: Props) => {
  const { t } = useTranslation();
  const schema = getSchema();
  return (
    <FormBodyWrapper
      canGoBack={canGoBack}
      clientType={clientType}
      initialErrors={formError}
      initialValues={initialValues}
      submitDisabled={submitDisabled}
      submitLoading={submitLoading}
      subTitle={t('onboarding.invoiceConfirmForPayment.subtitle')}
      title={t('onboarding.invoiceConfirmForPayment.title')}
      validationSchema={schema}
      onGoBack={onGoBack}
      onSubmit={onSubmit}
    >
      <DownloadContent
        href={invoiceLink}
        title={t('onboarding.invoiceConfirmForPayment.downloadDocument')}
      />

      <Field.FilesUpload
        files={files}
        fileTypes={[FileType.pdf]}
        maxFiles={MAX_FILES}
        name="fileIds"
        title={t('onboarding.invoiceConfirmForPayment.documentTitle')}
        onAddFiles={onAddFiles}
        onRemoveFile={onRemoveFile}
      />
    </FormBodyWrapper>
  );
};
