import { MouseEvent, useState } from 'react';

// eslint-disable-next-line no-restricted-imports
import { ClickAwayListener } from '@mui/material';
import { Stack, Tooltip } from 'libs/ui';
import { Icon } from 'libs/ui/Icon';

import { HintStyled } from './Hint.styles';
import { HintProps } from './types';

/**
 * Hint component.
 */
export const Hint = ({ title }: HintProps) => {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setOpen(true);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Stack alignItems="center" direction="row">
        <Tooltip
          open={open}
          title={title}
          type="click"
          onClose={handleTooltipClose}
        >
          <HintStyled onClick={handleTooltipOpen}>
            <Icon.Info className="hint-info" size={16} />
          </HintStyled>
        </Tooltip>
      </Stack>
    </ClickAwayListener>
  );
};
