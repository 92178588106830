import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useLegalClientLegalHeadFirstHeadSaveTaxResidencesMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { OnboardingTaxResidencesParam } from 'api/types/params';

import { parseLegalClientLegalHeadFirstHeadEntity } from './helpers';

export const useLegalClientLegalHeadFirstHeadSaveTaxResidences = () => {
  const [saveTaxResidences, { data, loading, error: apolloError }] =
    useLegalClientLegalHeadFirstHeadSaveTaxResidencesMutation();

  const error = useError(apolloError);

  return {
    data: data?.legalClientLegalHeadFirstHeadSaveTaxResidences
      ? parseLegalClientLegalHeadFirstHeadEntity(
          data.legalClientLegalHeadFirstHeadSaveTaxResidences
        )
      : undefined,
    loading,
    error,
    saveTaxResidences: useCallback(
      async (
        id: string,
        legalHeadId: string,
        firstHeadId: string,
        params: OnboardingTaxResidencesParam
      ) => {
        const res = await saveTaxResidences({
          variables: { id, legalHeadId, firstHeadId, input: params },
        });
        return res.data?.legalClientLegalHeadFirstHeadSaveTaxResidences
          ? parseLegalClientLegalHeadFirstHeadEntity(
              res.data.legalClientLegalHeadFirstHeadSaveTaxResidences
            )
          : undefined;
      },
      [saveTaxResidences]
    ),
  };
};
