import { getColor } from 'helpers';
import { CSSObject, styled } from 'libs/ui/styled';
import { ColorsDark, ColorsLight } from 'libs/ui/theme';

export interface StepperProps {
  count: number;
  activeIndex: number;
}

export const StepperStyled = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
}));

interface Props {
  active?: boolean;
  passed: boolean;
}

export const StepStyled = styled('div')<Props>(({ theme, active, passed }) => ({
  border: `5px solid ${
    passed || active
      ? getColor(theme, {
          light: ColorsLight.blue150,
          dark: ColorsDark.blue150,
        })
      : getColor(theme, {
          light: ColorsLight.gray100,
          dark: ColorsDark.gray100,
        })
  }`,
  width: 32,
  minWidth: 32,
  height: 32,
  borderRadius: 32,
  [theme.breakpoints.down('mobile')]: {
    width: 20,
    minWidth: 20,
    height: 20,
    borderRadius: 20,
    borderWidth: 3,
  } as CSSObject,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  '&::after': {
    backgroundColor: getColor(theme, {
      light: ColorsLight.blue150,
      dark: ColorsDark.blue150,
    }),
    content: active ? '""' : 'none',
    width: 10,
    height: 10,
    borderRadius: 10,
    [theme.breakpoints.down('mobile')]: {
      width: 6,
      height: 6,
      borderRadius: 6,
    },
    display: 'block',
  },
}));

export const LineStyled = styled('div')<Props>(({ theme, passed }) => ({
  backgroundColor: passed
    ? getColor(theme, {
        light: ColorsLight.blue150,
        dark: ColorsDark.blue150,
      })
    : getColor(theme, {
        light: ColorsLight.gray100,
        dark: ColorsDark.gray100,
      }),
  width: '100%',
  height: 5,
  [theme.breakpoints.down('mobile')]: {
    height: 3,
  },
}));
