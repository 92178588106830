import { useOnboardingClient } from 'api/requests';
import { useOnboardingKyc } from 'modules/onboarding/common/hooks';

export const useKyc = (clientId: string) => {
  const { currentClient, refetch } = useOnboardingClient(clientId);

  const { expirationHandler, loading, token } = useOnboardingKyc({
    onSuccess: () => {},
    refetch,
    status: currentClient?.kyc?.status,
  });

  return {
    loading: loading && !token,
    token,
    status: currentClient?.kyc?.status,
    expirationHandler,
  };
};
