import { useCallback } from 'react';

import {
  useClientRepresentativeSaveKycStep,
  useOnboardingClientRepresentative,
} from 'api/requests';
import { useOnboardingKyc } from 'modules/onboarding/common/hooks';

import { useOnboardingContext } from './useOnboardingContext';

export const useKyc = () => {
  const { clientId, goToNext, data } = useOnboardingContext();

  const { currentClient, refetch } =
    useOnboardingClientRepresentative(clientId);

  const { saveKycStep } = useClientRepresentativeSaveKycStep();

  const onSuccess = useCallback(async () => {
    await saveKycStep(clientId);
    await data.refetchCurrentClient();
    goToNext();
  }, [clientId, data, goToNext, saveKycStep]);

  const { expirationHandler, loading, token } = useOnboardingKyc({
    status: currentClient?.kyc?.status,
    refetch,
    onSuccess,
  });

  return {
    loading: loading && !token,
    token,
    status: currentClient?.kyc?.status,
    expirationHandler,
  };
};
