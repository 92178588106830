import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useClientRepresentativeSavePowerOfAttorneyMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { OnboardingClientRepresentativeSavePowerOfAttorneyParam } from 'api/types/params';
import { formatToISODate } from 'helpers';

import { parseClientRepresentative } from './helpers/parseClientRepresentative';

export const useClientRepresentativeSavePowerOfAttorney = () => {
  const [handle, { data, loading, error: apolloError }] =
    useClientRepresentativeSavePowerOfAttorneyMutation();

  const error = useError(apolloError);

  return {
    data: data?.clientRepresentativeSavePowerOfAttorney
      ? parseClientRepresentative(data.clientRepresentativeSavePowerOfAttorney)
      : undefined,
    loading,
    error,
    savePowerOfAttorney: useCallback(
      async (
        id: string,
        params: OnboardingClientRepresentativeSavePowerOfAttorneyParam
      ) => {
        const res = await handle({
          variables: {
            id,
            input: {
              ...params,
              dateOfExpiration: formatToISODate(params.dateOfExpiration),
              dateOfIssue: formatToISODate(params.dateOfIssue),
            },
          },
        });
        return res.data?.clientRepresentativeSavePowerOfAttorney
          ? parseClientRepresentative(
              res.data.clientRepresentativeSavePowerOfAttorney
            )
          : undefined;
      },
      [handle]
    ),
  };
};
