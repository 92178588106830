import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import {
  OnboardingLegalClientDocument,
  useLegalClientCreateBranchMutation,
} from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { LegalClientCreateBranchParam } from 'api/types/params';

import { parseLegalClientBranchEntity } from './helpers';

export const useLegalClientCreateBranch = () => {
  const [handle, { data, loading, error: apolloError }] =
    useLegalClientCreateBranchMutation({
      refetchQueries: [OnboardingLegalClientDocument],
      awaitRefetchQueries: true,
    });

  const error = useError(apolloError);

  return {
    data: data?.legalClientCreateBranch
      ? parseLegalClientBranchEntity(data.legalClientCreateBranch)
      : undefined,
    loading,
    error,
    createBranch: useCallback(
      async (id: string, param: LegalClientCreateBranchParam) => {
        const res = await handle({
          variables: {
            id,
            input: param,
          },
        });
        return res.data?.legalClientCreateBranch
          ? parseLegalClientBranchEntity(res.data.legalClientCreateBranch)
          : undefined;
      },
      [handle]
    ),
  };
};
