import { useEffect } from 'react';

import { ContentStepperComponentProps, LoadingView } from 'components';
import { useTranslation } from 'libs/i18n';
import { ROUTES, useNavigate } from 'libs/navigation';
import { RouteError } from 'types/error';

import { ConfirmEmailFormType } from '../forms/confirmEmailForm';
import { useConfirmEmail, useRestorePasswordUrlData } from '../hooks';

export const ConfirmEmailLinkStep = ({
  onGoNext,
}: ContentStepperComponentProps) => {
  const { navigate } = useNavigate();
  const { t } = useTranslation();
  const { codeError, onConfirm } = useConfirmEmail();

  const { urlData, clearSearchParams } = useRestorePasswordUrlData();

  const onSubmit = async (values: ConfirmEmailFormType) => {
    await onConfirm(values);
    clearSearchParams();
    onGoNext();
  };

  useEffect(() => {
    if (!urlData) {
      navigate(ROUTES.login.fullPath, {});
      return;
    }
    onSubmit(urlData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (codeError) {
    throw new RouteError(
      t('auth.restorePassword.emailUrlConfirm.errorMessage')
    );
  }

  return <LoadingView />;
};
