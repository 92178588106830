import { tokenStorage } from './tokenStorage';

/**
 * Retrieves authorization data for making authenticated API requests.
 * @returns {Object} - The authorization data as an object with an "Authorization" header or an empty object.
 */
export const getAuthorizationData = () => {
  const token = tokenStorage.getAccessToken();
  if (!token) return {};
  return { Authorization: token ? `Bearer ${token}` : '' };
};
