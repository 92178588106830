import { BaseOnboardingRegistrationAddressFormType } from 'modules/onboarding/common/forms/onboardingRegistrationAddressForm';
import { getBaseSubmitData } from 'modules/onboarding/common/helpers/registrationAddress';

export const getSubmitData = (
  values: BaseOnboardingRegistrationAddressFormType
) => {
  const { registrationAddress, residentialAddress } = getBaseSubmitData(values);
  return {
    legalAddress: registrationAddress,
    actualAddress: residentialAddress,
  };
};
