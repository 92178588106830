import {
  TypedForm,
  FormErrors as FormErrorsBase,
  ObjectSchema,
  string,
  object,
  array,
  date,
} from 'libs/form';
import { TFunc } from 'libs/i18n';
import { getTodayWithResetHours } from 'modules/onboarding/common/helpers/getToday';

export const MAX_FILES = 10;

export interface PositionInCompanyFormType {
  position: string;
  dateOfExpiration: Date;
  fileIds: string[];
}

export const maxLengthPosition = 200;

export const getSchema = (t: TFunc): ObjectSchema<PositionInCompanyFormType> =>
  object({
    position: string().max(maxLengthPosition).required(),
    dateOfExpiration: date()
      .min(getTodayWithResetHours(), t('validation.minDate'))
      .required(),
    fileIds: array().of(string().required()).required().min(1).max(MAX_FILES),
  });

export const { Field, Form, useFormContext, Submit } =
  TypedForm<PositionInCompanyFormType>();

export type FormErrors = FormErrorsBase<PositionInCompanyFormType>;
