import { useCallback } from 'react';

import { useClientConfirmationData } from 'modules/onboarding/common/hooks';

import { useOnboardingContext } from './useOnboardingContext';

export const useConfirmationData = () => {
  const { goToBack, data, goToNext } = useOnboardingContext();

  const { confirm, loading, error } = useClientConfirmationData(
    data.ownerClient!.id
  );

  const onConfirm = useCallback(async () => {
    await confirm();
    goToNext();
  }, [confirm, goToNext]);

  return { onConfirm, goToBack, loading, error };
};
