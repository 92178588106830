import { ErrorLayout, Layout } from 'components';
import { useRouteError } from 'helpers';

export const ErrorFallback = () => {
  const { message } = useRouteError();

  return (
    <Layout variant="filled-all">
      <ErrorLayout subtitle={message} />
    </Layout>
  );
};
