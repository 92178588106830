import { useRef } from 'react';

import { useLegalClientSaveContacts } from 'api/requests';
import { useTranslation } from 'libs/i18n';

import { CompanyContactsFormType } from '../forms/companyContactsForm';
import { getInitialData, getSubmitData } from '../helpers/companyContacts';

import { useOnboardingContext } from './useOnboardingContext';

export const useCompanyContacts = () => {
  const { goToBack, goToNext, data } = useOnboardingContext();
  const { loading, saveContacts } = useLegalClientSaveContacts();
  const { t } = useTranslation();

  const initialValues = useRef(
    getInitialData(data.legalClient?.contacts, t)
  ).current;

  const onSubmit = async (values: CompanyContactsFormType) => {
    if (values) {
      const submitData = getSubmitData(values);
      await saveContacts(data.legalClient!.id, submitData);
      goToNext();
    }
  };

  return {
    onSubmit,
    goToBack,
    submitLoading: loading,
    initialValues,
  };
};
