import { useTranslation } from 'libs/i18n';
import { AddAndRemoveButtons } from 'modules/onboarding/common/components/AddAndRemoveButtons';

import { useFormContext } from '../../../../forms/companyAccountsInOrganizationsForm';

interface Props {
  index: number;
}

export const Buttons = ({ index }: Props) => {
  const { values, setValues } = useFormContext();

  const { t } = useTranslation();

  const onAdd = () => {
    values.accounts.push({
      country: null,
      bic: '',
      hasPermanentManagementInCountry: null,
      onTerritoryWithNoAML_CFT: null,
    });
    setValues(values, false);
  };

  const onRemove = (index: number) => {
    values.accounts.splice(index, 1);
    setValues(values, false);
  };

  return (
    <AddAndRemoveButtons
      addLabel={t('onboarding.accountsInOrganizations.addAccount')}
      counts={values.accounts.length}
      index={index}
      removeLabel={t('onboarding.accountsInOrganizations.removeAccount')}
      onAdd={onAdd}
      onRemove={onRemove}
    />
  );
};
