import { LegalClientSaveOrderSubmissionContactParam } from 'api/types/params';
import { lodash } from 'helpers';

import { LegalOrderSubmissionContactsFormType } from '../../forms/orderSubmissionContacts';

export const getSubmitData = (
  values: LegalOrderSubmissionContactsFormType
): LegalClientSaveOrderSubmissionContactParam[] =>
  lodash.compact(
    values.orderSubmissionContacts.map(
      ({ firstName, lastName, position, type, value, middleName }) => ({
        firstName,
        lastName,
        position,
        type: type.value,
        value,
        middleName,
      })
    )
  );
