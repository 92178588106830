import { getColor } from 'helpers';
import { styled } from 'libs/ui/styled';
import { ColorsDark, ColorsLight } from 'libs/ui/theme';

export const ContentStyled = styled('div')(() => ({
  outline: 'none',
}));

export const LineStyled = styled('div')(({ theme }) => ({
  backgroundColor: getColor(theme, {
    light: ColorsLight.blue100,
    dark: ColorsDark.gray200,
  }),
  height: 1,
}));
