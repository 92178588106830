import { PasswordValidation } from 'helpers';
import { TFunc } from 'libs/i18n';
import { string } from 'yup';

export const password = (passwordValidation: PasswordValidation, t: TFunc) =>
  string()
    .test('password', t('validation.password'), (value) => {
      if (!value) return true;

      return passwordValidation.isCorrectPassword(value);
    })
    .required();
