import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import {
  OnboardingLegalClientDocument,
  useLegalClientDeleteBodyMutation,
} from 'api/generated/graphql';
import { useError } from 'api/helpers';

export const useLegalClientDeleteBody = () => {
  const [handle, { loading, error: apolloError }] =
    useLegalClientDeleteBodyMutation({
      refetchQueries: [OnboardingLegalClientDocument],
      awaitRefetchQueries: true,
    });

  const error = useError(apolloError);

  return {
    loading,
    error,
    deleteBody: useCallback(
      async (id: string, bodyId: string) => {
        const res = await handle({
          variables: {
            id,
            bodyId,
          },
        });
        return res?.data?.legalClientDeleteBody;
      },
      [handle]
    ),
  };
};
