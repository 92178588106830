/**
 * Format a phone number input value with a plus sign.
 * @param {string | undefined} value - The input value to be formatted.
 * @param {boolean} isFocused - Indicates whether the input field is focused.
 */
export const formatPhoneWithPlus = (
  value: string | undefined,
  isFocused: boolean
) => {
  let formattedValue;

  value = value?.replace('+', '');

  if (isFocused && !value) {
    formattedValue = '+';
  } else if (!isFocused && value === '+') {
    formattedValue = '';
  } else if (!value?.startsWith('+') && value) {
    formattedValue = `+${value}`;
  } else {
    formattedValue = value || '';
  }

  return formattedValue;
};
