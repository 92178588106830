// eslint-disable-next-line no-restricted-imports
import { AgreementFragment } from 'api/generated/graphql';
import { AgreementEntity } from 'api/types/entity';

export const parseEntity = (
  agreements: AgreementFragment[]
): AgreementEntity[] =>
  agreements.map((agreement) => ({
    id: agreement.id,
    title: agreement.title,
    text: agreement.text,
  }));
