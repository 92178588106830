import { useRef } from 'react';

import { useLegalClientLegalHeadSaveTaxResidences } from 'api/requests';
import { useServerErrorNotify } from 'hooks';
import { useOnboardingCountries } from 'modules/onboarding/common/hooks';
import { CompanyTaxResidencyFormType } from 'modules/onboarding/legal/forms/companyTaxResidencyForm';
import {
  getInitialData,
  getSubmitData,
} from 'modules/onboarding/legal/helpers/companyTaxResidences';

import { useOnboardingContext } from './useOnboardingContext';

export const useTaxResidency = () => {
  const { goToBack, goToNext, data } = useOnboardingContext();
  const { countries, loading: countriesLoading } = useOnboardingCountries();

  const {
    saveTaxResidences,
    error,
    loading: submitLoading,
  } = useLegalClientLegalHeadSaveTaxResidences();

  const initialValues = useRef(
    getInitialData(data.legalHead.taxResidences)
  ).current;

  const onSubmit = async (values: CompanyTaxResidencyFormType) => {
    if (values.taxValues) {
      const submitData = getSubmitData(values);
      const res = await saveTaxResidences(
        data.legalClient.id,
        data.legalHead.id,
        submitData
      );

      if (res) {
        goToNext();
      }
    }
  };

  useServerErrorNotify(error);

  return {
    onSubmit,
    goToBack,
    submitLoading,
    initialValues,
    countries,
    countriesLoading,
  };
};
