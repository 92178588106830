import { useRef } from 'react';

import { useLegalClientSaveBankAccounts } from 'api/requests';
import { useServerErrorNotify } from 'hooks';
import { useTranslation } from 'libs/i18n';
import { useOnboardingCountries } from 'modules/onboarding/common/hooks';

import { CompanyAccountsInOrganizationsFormType } from '../forms/companyAccountsInOrganizationsForm';
import {
  getInitialData,
  getSubmitData,
} from '../helpers/companyAccountsInOrganizations';

import { useOnboardingContext } from './useOnboardingContext';

export const useCompanyAccountsInOrganizations = () => {
  const { goToBack, goToNext, data } = useOnboardingContext();
  const { error, loading, saveBankAccounts } = useLegalClientSaveBankAccounts();
  const { t } = useTranslation();

  const initialValues = useRef(
    getInitialData(data.legalClient!.bankAccounts, t)
  ).current;

  const { countries, loading: countriesLoading } = useOnboardingCountries();

  const onSubmit = async (values: CompanyAccountsInOrganizationsFormType) => {
    if (values) {
      const submitData = getSubmitData(values);
      saveBankAccounts(data.legalClient!.id, submitData);
      goToNext();
    }
  };

  useServerErrorNotify(error);

  return {
    onSubmit,
    goToBack,
    submitLoading: loading,
    initialValues,
    countries,
    countriesLoading,
  };
};
