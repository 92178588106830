import { Button, ButtonProps } from 'libs/ui/Button';

import { useFormContext } from './useFormContext';

// Define props for the Submit component, omitting the 'onClick' prop.
export interface SubmitProps extends Omit<ButtonProps, 'onClick'> {}

/**
 * Submit component that triggers form submission using the useFormContext hook.
 * It renders a Button component with the provided props.
 */
export const Submit = (props: SubmitProps) => {
  const { handleSubmit } = useFormContext();

  const onClick = () => handleSubmit();

  return <Button {...props} onClick={onClick} />;
};
