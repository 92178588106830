import { Dialog } from 'libs/ui';

import { ConfirmationDialogContent } from '../ConfirmationDialogContent';

import { ConfirmationDialogProps } from './types';

export const ConfirmationDialog = ({
  maxWidth,
  isOpen,
  fullWidth,
  className,
  onClose,
  ...dialogContentProps
}: ConfirmationDialogProps) => (
  <Dialog
    className={className}
    fullWidth={fullWidth}
    isOpen={isOpen}
    maxWidth={maxWidth}
    onClose={onClose ?? dialogContentProps.onBack}
  >
    <ConfirmationDialogContent {...dialogContentProps} />
  </Dialog>
);
