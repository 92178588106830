import { useCallback } from 'react';

import { tokenStorage } from 'api/client/helpers/tokenStorage';
import { useLoginByToken } from 'api/requests';

export const useLogin = () => {
  const { loading, data, error, login } = useLoginByToken();

  const loginByToken = useCallback(
    async (token: string) => {
      const loginData = await login(token);

      if (loginData) {
        tokenStorage.login(loginData);
        window.location.replace('/');
      } else {
        throw Error();
      }
    },
    [login]
  );

  return {
    loginByToken,
    error,
    data,
    loading,
  };
};
