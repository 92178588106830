import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useLegalClientSaveCompanyNameMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { SaveCompanyNameWithEngParam } from 'api/types/params';

import { parseLegalClient } from './helpers';

export const useLegalClientSaveCompanyName = () => {
  const [handle, { data, loading, error: apolloError }] =
    useLegalClientSaveCompanyNameMutation();

  const error = useError(apolloError);

  return {
    data: data?.legalClientSaveCompanyName
      ? parseLegalClient(data.legalClientSaveCompanyName)
      : undefined,
    loading,
    error,
    saveCompanyName: useCallback(
      async (id: string, params: SaveCompanyNameWithEngParam) => {
        const res = await handle({
          variables: {
            id,
            input: params,
          },
        });
        return res?.data?.legalClientSaveCompanyName
          ? parseLegalClient(res.data.legalClientSaveCompanyName)
          : undefined;
      },
      [handle]
    ),
  };
};
