import { LegalFinancingSourcesEntity } from 'api/types/entity';
import { FileUpload } from 'hooks/useFilesUpload';
import { TFunc } from 'libs/i18n';

import {
  CompanyFinancingSourcesFormType,
  getFinancingSourcesOptions,
  getIncomeSourcesOptions,
} from '../../forms/companyFinancingSourcesForm';

export const getInitialData = (
  data: LegalFinancingSourcesEntity | undefined,
  t: TFunc
) => {
  const values: CompanyFinancingSourcesFormType | undefined = data
    ? {
        incomeSources: getIncomeSourcesOptions(t).filter(({ value }) =>
          data.incomeSources.some((v) => v === value)
        ),
        financingSources: getFinancingSourcesOptions(t).filter(({ value }) =>
          data.financingSources.some((v) => v === value)
        ),
        auditedFinancialReportFileIds:
          data.lastYearAuditFinancialReportFiles.map((v) => v.id),
        recommendationLetterFileIds: data.recommendationLetterFiles.map(
          (v) => v.id
        ),
        otherFinancingSources: data.otherFinancingSource,
        otherIncomeSource: data.otherIncomeSource,
      }
    : undefined;

  const lastYearAuditFinancialReportFiles: FileUpload[] | undefined =
    data?.lastYearAuditFinancialReportFiles.map(
      (v) =>
        new FileUpload({ name: v.name, size: v.size }, undefined, false, v.id)
    );

  const recommendationLetterFiles: FileUpload[] | undefined =
    data?.recommendationLetterFiles.map(
      (v) =>
        new FileUpload({ name: v.name, size: v.size }, undefined, false, v.id)
    );

  return {
    values,
    lastYearAuditFinancialReportFiles,
    recommendationLetterFiles,
  };
};
