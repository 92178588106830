import { OnboardingConfirmationDataDialog } from 'modules/onboarding/common/components';

import { useConfirmationData } from '../hooks';

export const ConfirmationDataStep = () => {
  const { onConfirm, goToBack, loading } = useConfirmationData();

  return (
    <OnboardingConfirmationDataDialog
      isOpen
      loading={loading}
      onBack={goToBack}
      onConfirm={onConfirm}
    />
  );
};
