import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useLegalClientBeneficiarySaveAddressesMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { AddressesWithFilesParam } from 'api/types/params/onboarding/addressesParam';

import { parseLegalClientBeneficiaryEntity } from './helpers';

export const useLegalClientBeneficiarySaveAddresses = () => {
  const [handle, { data, loading, error: apolloError }] =
    useLegalClientBeneficiarySaveAddressesMutation();

  const error = useError(apolloError);

  return {
    data: data?.legalClientBeneficiarySaveAddresses
      ? parseLegalClientBeneficiaryEntity(
          data.legalClientBeneficiarySaveAddresses
        )
      : undefined,
    loading,
    error,
    saveAddresses: useCallback(
      async (
        id: string,
        beneficiaryId: string,
        params: AddressesWithFilesParam
      ) => {
        const res = await handle({
          variables: {
            id,
            beneficiaryId,
            input: params,
          },
        });
        return res?.data?.legalClientBeneficiarySaveAddresses
          ? parseLegalClientBeneficiaryEntity(
              res.data.legalClientBeneficiarySaveAddresses
            )
          : undefined;
      },
      [handle]
    ),
  };
};
