import { getColor } from 'helpers';
import { styled } from 'libs/ui/styled';
import { ColorsDark, ColorsLight } from 'libs/ui/theme';

export const InputContentStyled = styled('div')(({ theme }) => ({
  flex: 1,
  borderRadius: 12,
  borderColor: getColor(theme, {
    light: ColorsLight.gray100,
    dark: ColorsDark.gray100,
  }),
  borderWidth: 1,
  borderStyle: 'solid',
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',

  '&:focus-within': {
    borderColor: getColor(theme, {
      light: ColorsLight.blue200,
      dark: ColorsDark.gray150,
    }),
  },

  '&:after': {
    content: '""',
    display: 'block',
    paddingBottom: '100%',
  },

  '&.filled': {
    backgroundColor: getColor(theme, {
      light: ColorsLight.gray50,
      dark: ColorsDark.gray50,
    }),
    borderColor: getColor(theme, {
      light: ColorsLight.gray150,
      dark: ColorsDark.gray150,
    }),
  },
  '&.error': {
    borderColor: getColor(theme, {
      light: ColorsLight.red100,
      dark: ColorsDark.red100,
    }),
    backgroundColor: getColor(theme, {
      light: ColorsLight.red50,
      dark: ColorsDark.gray50,
    }),
  },

  input: {
    height: '100%',
    width: '100%',
    outline: 'none',
    textAlign: 'center',
    color: theme.palette.text.primary,

    ...theme.typography.headline,
    [theme.breakpoints.down('mobile')]: {
      ...theme.typography.subtitle,
    },

    border: 'none',

    backgroundColor: 'transparent',
  },
}));
