// eslint-disable-next-line no-restricted-imports
import {
  ThemeOptions,
  Theme as ThemeBase,
  createTheme as createThemeBase,
} from '@mui/material';

import { breakpoints } from './breakpoints';
import { palettes } from './palette';
import { typography } from './typography';

export type PaletteMode = 'dark' | 'light';

/**
 * Generates theme options based on the selected theme mode.
 * @param {PaletteMode} mode - The current theme mode ('light' or 'dark').
 */
const getThemeOptions = (mode: PaletteMode): ThemeOptions => {
  const palette = palettes[mode];

  const defaultTheme = createThemeBase({ breakpoints });

  return {
    palette: {
      ...palette,
      mode,
    },
    typography,
    breakpoints,
    components: {
      MuiMenu: {
        styleOverrides: {
          paper: {
            borderRadius: 12,
            boxShadow:
              '0px 1px 11px 0px rgba(56, 77, 108, 0.12), 0px 1px 2px 0px rgba(56, 77, 108, 0.04);',
          },
          list: {
            backgroundColor: palette.selectBg,
            padding: 0,
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundColor: palette.selectBg,
          },
        },
      },
      MuiMenuItem: {
        defaultProps: {
          disableRipple: true,
        },
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              color: palette.selectSelectedMenuItem,
              background: 'none',
              fontWeight: '600',
            },
            '&:hover, &.Mui-selected:hover': {
              background: 'none',
            },
            color: palette.selectMenuItem,
            fontSize: 14,
            '&:not(:last-child)': {
              borderBottom: `1px solid ${palette.selectMenuItemBorder};`,
            },
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          listbox: {
            [defaultTheme.breakpoints.down('mobile')]: {
              fontSize: 14,
              '.MuiAutocomplete-option': {
                minHeight: 40,
              },
            },
            '.MuiAutocomplete-option[aria-selected="true"]': {
              color: palette.selectSelectedMenuItem,
              fontWeight: 700,
            },
          },
        },
      },
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
        },
      },

      MuiSvgIcon: {
        styleOverrides: {
          root: {
            transition: defaultTheme.transitions.create(['color']),
          },
        },
      },
    },
  };
};

/**
 * Creates a Material-UI theme based on the specified theme mode.
 * @param {PaletteMode} mode - The current theme mode ('light' or 'dark').
 */
export const createTheme = (mode: PaletteMode) =>
  createThemeBase(getThemeOptions(mode));

export type Theme = ThemeBase;
