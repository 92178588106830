// eslint-disable-next-line no-restricted-imports
import { useLegalClientLegalHeadFirstHeadQuery } from 'api/generated/graphql';
import { useError } from 'api/helpers';

import { parseLegalClientLegalHeadFirstHeadEntity } from './helpers';

export const useLegalClientLegalHeadFirstHead = (headId: string) => {
  const {
    data,
    loading,
    error: apolloError,
    refetch,
  } = useLegalClientLegalHeadFirstHeadQuery({ variables: { id: headId } });

  const error = useError(apolloError);

  return {
    firstHead: data?.legalClientLegalHeadFirstHead
      ? parseLegalClientLegalHeadFirstHeadEntity(
          data.legalClientLegalHeadFirstHead
        )
      : undefined,
    loading,
    refetch,
    error,
  };
};
