import { ReactNode } from 'react';

// eslint-disable-next-line no-restricted-imports
import { Outlet } from 'react-router-dom';

import { RoutesId } from '../config/routesId';
import { Route, DeepReadonly } from '../types';

/**
 * Configuration type for a route element.
 */
type Config = Omit<Route, 'children'> & {
  element?: ReactNode;
  children?: Config[];
};

// postfix for added pages with an index
export const INDEX_PATH_POSTFIX = '-[index]';

/**
 * Merges a map of route IDs to React elements into a route configuration.
 * @param {Record<RoutesId, ReactNode>} map - The map of route IDs to React elements.
 * @param {DeepReadonly<Route>} config - The deep-readonly route configuration to merge.
 * @returns {Config} - The merged route configuration with React elements.
 */
export const mergeMapElement = (
  map: Record<RoutesId, ReactNode>,
  config: DeepReadonly<Route>
) => {
  /**
   * Recursively reverse the route configuration to inject React elements.
   * @param {Config} obj - The current route configuration.
   */
  const reverse = (obj: Config) => {
    const { id } = obj;
    if (!map[id]) {
      throw new Error(`ID is not specified in the configurator: ${id} `);
    }
    if (obj.id === RoutesId.root) {
      obj.element = map[id];
      obj.children?.forEach((d) => reverse(d as Config));
    } else if (obj.children) {
      // adding an index page with a parent element
      obj.children.unshift({
        // @ts-ignore
        id: `${obj.id}${INDEX_PATH_POSTFIX}`,
        index: true,
        element: map[id],
        path: '',
      });
      obj.element = <Outlet />;
      obj.children?.forEach((d, index) => index !== 0 && reverse(d as Config));
    } else {
      // eslint-disable-next-line no-param-reassign
      obj.element = map[id];
    }
  };

  const res = JSON.parse(JSON.stringify(config)) as Config;
  reverse(res);

  return res;
};
