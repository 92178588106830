import { useRef } from 'react';

import { useLegalClientSaveDebts } from 'api/requests';
import { useServerErrorNotify } from 'hooks';
import { useTranslation } from 'libs/i18n';
import { DebtsFormType } from 'modules/onboarding/common/forms/debtsForm';
import {
  getSubmitData,
  getInitialData,
} from 'modules/onboarding/common/helpers/debts';

import { useOnboardingContext } from './useOnboardingContext';

export const useCompanyDebs = () => {
  const {
    saveDebts,
    error,
    loading: submitLoading,
  } = useLegalClientSaveDebts();
  const { t } = useTranslation();

  const { goToBack, goToNext, data } = useOnboardingContext();

  const initialValues = useRef(
    getInitialData(data.legalClient!.debts, t)
  ).current;

  const onSubmit = async (values: DebtsFormType) => {
    if (values) {
      const submitData = getSubmitData(values, true);
      await saveDebts(data.legalClient!.id, submitData);
      goToNext();
    }
  };

  useServerErrorNotify(error);

  return {
    onSubmit,
    goToBack,
    initialValues,
    submitLoading,
  };
};
