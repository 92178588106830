// eslint-disable-next-line no-restricted-imports
import { MenuItem } from '@mui/material';

import { styled } from '../styled';

/**
 * Styled MenuItem component used as a placeholder for the Select component.
 */
export const PlaceholderMenuItem = styled(MenuItem)({
  display: 'none',
});

/**
 * Styled element for the placeholder text in the Select component.
 */
export const Placeholder = styled('div')({
  opacity: 0.38,
});
