// eslint-disable-next-line no-restricted-imports
import { OnboardingClientRepresentativeFragment } from 'api/generated/graphql';
import { BaseDebtsEntity } from 'api/types/entity';

import { parseAmount } from '../../helpers/parseAmount';

const withoutNull = <T>(v: T) => (v === null ? undefined : v);

export const parseClientRepresentativeDebtsEntity = (
  data: OnboardingClientRepresentativeFragment
): BaseDebtsEntity | undefined =>
  data.debts
    ? {
        hasBankruptcyProceedings: withoutNull(
          data.debts.hasBankruptcyProceedings
        ),

        hasDelaysOrNonFulfillmentOfObligations: withoutNull(
          data.debts.hasDelaysOrNonFulfillmentOfObligations
        ),

        fees: data.debts.fees ? parseAmount(data.debts.fees) : undefined,

        penalties: data.debts.penalties
          ? parseAmount(data.debts.penalties)
          : undefined,

        finesForViolation: data.debts.finesForViolation
          ? parseAmount(data.debts.finesForViolation)
          : undefined,

        taxes: data.debts.taxes ? parseAmount(data.debts.taxes) : undefined,
      }
    : undefined;
