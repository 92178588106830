import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useLegalClientSaveBranchesStepMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';

import { parseLegalClient } from './helpers';

export const useLegalClientSaveBranchesStep = () => {
  const [handle, { data, loading, error: apolloError }] =
    useLegalClientSaveBranchesStepMutation();

  const error = useError(apolloError);

  return {
    data: data?.legalClientSaveBranchesStep
      ? parseLegalClient(data.legalClientSaveBranchesStep)
      : undefined,
    loading,
    error,
    saveBranchesStep: useCallback(
      async (id: string) => {
        const res = await handle({
          variables: {
            id,
          },
        });
        return res.data?.legalClientSaveBranchesStep
          ? parseLegalClient(res.data.legalClientSaveBranchesStep)
          : undefined;
      },
      [handle]
    ),
  };
};
