// eslint-disable-next-line no-restricted-imports
import { ResidentialAddressFragment } from 'api/generated/graphql';
import { ResidentialAddressEntity } from 'api/types/entity';
import { lodash } from 'helpers';
import { Maybe } from 'types/maybe';

import { parseFileEntity } from './parseFileEntity';

export const parseResidentialAddressEntity = (
  data?: Maybe<ResidentialAddressFragment>
): ResidentialAddressEntity | undefined => {
  if (!data) {
    return undefined;
  }

  const { proofOfResidenceFiles, ...rest } = data;

  const res: ResidentialAddressEntity = {
    ...rest,
    proofOfResidenceFiles: lodash
      .compact(data.proofOfResidenceFiles)
      .map(parseFileEntity),
  };

  return res;
};
