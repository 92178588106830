import { useTranslation } from 'libs/i18n';
import { getToday } from 'modules/onboarding/common/helpers/getToday';
import {
  Field,
  nameAuthorityMaxLength,
  numberMaxLength,
  registrationDocumentNameMaxLength,
} from 'modules/onboarding/legal/forms/companyStateRegistrationForm';

export const Fields = () => {
  const { t } = useTranslation();
  return (
    <>
      <Field.Text
        label={t(
          'onboarding.companyStateRegistration.registrationDocumentName.label'
        )}
        maxLength={registrationDocumentNameMaxLength}
        name="registrationDocumentName"
        placeholder={t(
          'onboarding.companyStateRegistration.registrationDocumentName.placeholder'
        )}
      />
      <Field.DatePicker
        label={t('onboarding.companyStateRegistration.date.label')}
        maxDate={getToday()}
        name="date"
        placeholder={t('onboarding.companyStateRegistration.date.placeholder')}
      />
      <Field.Text
        label={t('onboarding.companyStateRegistration.number.label')}
        maxLength={numberMaxLength}
        name="number"
        placeholder={t(
          'onboarding.companyStateRegistration.number.placeholder'
        )}
      />

      <Field.DatePicker
        disableFuture={false}
        label={t('onboarding.companyStateRegistration.dateOfIssue.label')}
        maxDate={getToday()}
        name="dateOfIssue"
        placeholder={t(
          'onboarding.companyStateRegistration.dateOfIssue.placeholder'
        )}
      />

      <Field.Text
        label={t('onboarding.companyStateRegistration.nameAuthority.label')}
        maxLength={nameAuthorityMaxLength}
        name="nameAuthority"
        placeholder={t(
          'onboarding.companyStateRegistration.nameAuthority.placeholder'
        )}
      />
    </>
  );
};
