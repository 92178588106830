import { Stack } from 'libs/ui';
import { styled } from 'libs/ui/styled';

export const ErrorLayoutStyled = styled(Stack)({
  height: '100%',
  flex: 1,

  '.errorLayout-content': {
    maxWidth: 624,
  },
});
