import { useCallback, useState } from 'react';

import { useLegalClientConfirmDataAccuracy } from 'api/requests';

import { useOnboardingContext } from './useOnboardingContext';

export const useConfirmationData = () => {
  const { goToBack, data, goToNext } = useOnboardingContext();

  const { confirmData, error } = useLegalClientConfirmDataAccuracy();

  const [loading, setLoading] = useState(false);
  const legalClientId = data.legalClient!.id;

  const onConfirm = useCallback(async () => {
    setLoading(true);
    try {
      await confirmData(legalClientId);
    } catch (error) {
      setLoading(false);
      throw error;
    }

    goToNext();
    setLoading(false);
  }, [confirmData, goToNext, legalClientId]);

  return {
    onConfirm,
    loading,
    error,
    goToBack,
  };
};
