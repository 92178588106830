import { PriceEntity } from '../priceEntity';

export enum AssetType {
  Cash = 'Cash', // Денежные средства
  Securities = 'Securities', // Ценные бумаги
}

export enum OperationType {
  ForeignCurrencyTrading = 'ForeignCurrencyTrading', // Заключение сделок с иностранной валютой
  KazakhStockTrading = 'KazakhStockTrading', // Заключение сделок с акциями казахстанских эмитентов
  ForeignStockTrading = 'ForeignStockTrading', // Заключение сделок с акциями иностранных эмитентов
  KazakhBondTrading = 'KazakhBondTrading', // Заключение сделок с облигациями казахстанских эмитентов
  ForeignBondTrading = 'ForeignBondTrading', // Заключение сделок с облигациями иностранных эмитентов
  RepoOperations = 'RepoOperations', // Операции РЕПО
  DerivativesAndFutures = 'DerivativesAndFutures', // Перечисление опционов, фьючерсов, ПФИ
  SecuritiesTransfer = 'SecuritiesTransfer', // Зачисление/списание ценных бумаг
  Other = 'Other',
}

export enum BusinessRelationshipGoalType {
  BrokerageServices = 'BrokerageServices', // Брокерские услуги
  AssetManagement = 'AssetManagement', // Доверительное управление активами
  Underwriting = 'Underwriting', // Андеррайтинг
  Other = 'Other',
}

export enum BusinessRelationshipNatureType {
  LongTermSpeculative = 'LongTermSpeculative', // Долгосрочные отношения, спекулятивный характер сделок
  LongTermInvestment = 'LongTermInvestment', // Долгосрочные отношения, инвестиционный характер сделок
  TargetedOperations = 'TargetedOperations', // Разовые целевые операции
  Other = 'Other',
}

export interface LegalClientBusinessRelationshipEntity {
  plannedAssetTypes: AssetType[];
  plannedOperationTypes: OperationType[];
  otherPlannedOperationType?: string;
  plannedAssetSize: PriceEntity;
  businessRelationshipGoals: BusinessRelationshipGoalType[];
  otherBusinessRelationshipGoal?: string;
  businessRelationshipNatures: BusinessRelationshipNatureType[];
  otherBusinessRelationshipNature?: string;
}
