import { useRef } from 'react';

import { useClientSaveCitizenships } from 'api/requests';
import { ClientEntity } from 'api/types/entity';
import { useServerErrorNotify } from 'hooks';
import { OnboardingCitizenshipFormType } from 'modules/onboarding/common/forms/onboardingCitizenshipForm';
import { useOnboardingCountries } from 'modules/onboarding/common/hooks';

import { getInitialData } from '../helpers/citizenship/getInitialData';
import { getSubmitData } from '../helpers/citizenship/getSubmitData';

export const useClientCitizenship = (client: ClientEntity) => {
  const {
    saveCitizenships,
    error,
    loading: submitLoading,
  } = useClientSaveCitizenships();

  const { countries, loading: countriesLoading } = useOnboardingCountries();

  const initialValues = useRef(getInitialData(client.citizenships)).current;

  const onSubmit = async (values: OnboardingCitizenshipFormType) => {
    if (values.citizenships) {
      const submitData = getSubmitData(values);
      await saveCitizenships(client.id, submitData);
    }
  };

  useServerErrorNotify(error);

  return {
    onSubmit,
    submitLoading,
    initialValues,
    countries,
    countriesLoading,
  };
};
