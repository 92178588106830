import { CountryEntity } from 'api/types/entity';
import { useTranslation } from 'libs/i18n';
import { Stack, Typography } from 'libs/ui';
import { Buttons } from 'modules/onboarding/common/components/OnboardingTaxResidency/FormContent/components';

import { useFormContext } from '../../../forms/companyTaxResidencyForm';

import { Fields } from './components';

export interface FormContentProps {
  countriesLoading?: boolean;
  countries: CountryEntity[];
}

export const FormContent = ({
  countriesLoading,
  countries,
}: FormContentProps) => {
  const { values } = useFormContext();

  const { t } = useTranslation();

  return (
    <Stack spacing={32}>
      {values.taxValues.map((value, index) => (
        <Stack key={index.toLocaleString()} spacing={32}>
          <Stack spacing={24} spacingMobile={16}>
            <Typography variant="subtitle" variantMobile="bodyBold">
              {t(
                index === 0
                  ? 'onboarding.taxResidency.taxNumberTitle'
                  : 'onboarding.taxResidency.taxNumberOtherCountryTitle'
              )}
            </Typography>

            <Fields
              countries={countries}
              countriesLoading={countriesLoading}
              index={index}
            />
          </Stack>

          <Buttons index={index} />
        </Stack>
      ))}
    </Stack>
  );
};
