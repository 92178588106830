import { useColor } from 'hooks';
import { ColorType, ColorsDark, ColorsLight } from 'libs/ui/theme';

import { SvgIcon } from '../SvgIcon';
import { SvgProps } from '../types';

interface CheckedIntdeterminateProps extends SvgProps {
  rectColor?: ColorType;
  strokeColor?: ColorType;
}

export const CheckedIntdeterminate = ({
  size = 24,
  rectColor,
  strokeColor,
  className,
}: CheckedIntdeterminateProps) => {
  const defaultRectColor = useColor({
    dark: ColorsDark.white,
    light: ColorsLight.black,
  });
  const defaultStrokeColor = useColor({
    dark: ColorsDark.black,
    light: ColorsLight.white,
  });
  rectColor = rectColor || defaultRectColor;
  strokeColor = strokeColor || defaultStrokeColor;
  return (
    <SvgIcon className={className} size={size}>
      <svg
        fill="none"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect fill={rectColor} height="18" rx="4" width="18" x="3" y="3" />
        <path d="M6.5 12H17.5" stroke={strokeColor} strokeWidth="2" />
      </svg>
    </SvgIcon>
  );
};
