import { useCallback, useEffect } from 'react';

import {
  useRegistration,
  useRegistrationPasswordValidationRule,
} from 'api/requests';
import { RegistrationUserType } from 'api/types/params';
import { getValidationErrors } from 'helpers';
import { useObjectState } from 'hooks';
import { useNotify } from 'libs/notify';

import { useRegistrationContext } from '../components/RegistrationProvider';
import {
  RegistrationPasswordFormType,
  FormErrors,
} from '../forms/registrationPasswordForm';
import { RegistrationType } from '../types';

const getUserType = (isLegal: boolean, isRepresentative: boolean) => {
  if (isLegal) {
    return RegistrationUserType.LegalClientRepresentative;
  }
  if (isRepresentative) {
    return RegistrationUserType.ClientRepresentative;
  }
  return RegistrationUserType.Client;
};

export const useRegistrationPassword = () => {
  const { registration, loading, error } = useRegistration();

  const { contextData, agreementIds, registrationType } =
    useRegistrationContext();

  const { passwordValidationRule } = useRegistrationPasswordValidationRule();

  const notify = useNotify();

  const [formError, setFormError] = useObjectState<FormErrors>({});

  const onSubmit = useCallback(
    async (values: RegistrationPasswordFormType) => {
      const isLegal = registrationType === RegistrationType.legal;
      const isRepresentative = !!contextData.representative;

      return registration({
        password: values.password,
        dateOfBirth: contextData.dateOfBirth!,
        email: contextData.email!,
        firstName: contextData.firstname!,
        lastName: contextData.lastname!,
        middleName: contextData.surname || undefined,
        type: getUserType(isLegal, isRepresentative),
        agreementIds,
      });
    },
    [registration, registrationType, contextData, agreementIds]
  );

  // handle error
  useEffect(() => {
    if (error) {
      const validationErrors = getValidationErrors<'password'>(error);
      if (validationErrors?.password) {
        setFormError({ password: validationErrors.password[0].description });
      } else {
        notify.error(error);
        setFormError({ password: undefined });
      }
    } else {
      setFormError({ password: undefined });
    }
  }, [error, notify, setFormError]);

  return { onSubmit, loading, formError, passwordValidationRule };
};
