import { PropsWithChildren, ReactNode } from 'react';

import { ProgressBar } from 'libs/ui';

import { OnboardingLayoutStyled } from './OnboardingLayout.styles';

interface OnboardingLayoutProps extends PropsWithChildren {
  countSteps?: number;
  currentStepIndex?: number;
  headerComponent?: ReactNode;
}

export const OnboardingLayout = ({
  children,
  countSteps,
  currentStepIndex,
  headerComponent,
}: OnboardingLayoutProps) => (
  <OnboardingLayoutStyled
    headerComponent={
      headerComponent === undefined ? (
        <ProgressBar
          className="onboardingLayout-progressBar"
          countSteps={countSteps}
          currentStepIndex={currentStepIndex}
        />
      ) : (
        headerComponent
      )
    }
  >
    {children}
  </OnboardingLayoutStyled>
);
