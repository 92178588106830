// eslint-disable-next-line no-restricted-imports
import MuiAlert from '@mui/material/Alert';
import { getColor } from 'helpers';

import { styled } from '../styled';
import { ColorsDark, ColorsLight } from '../theme';

export const AlertStyled = styled(MuiAlert)(({ theme, severity }) => ({
  alignItems: 'center',

  backgroundColor:
    severity === 'error'
      ? theme.palette.error.main
      : getColor(theme, { light: ColorsLight.black, dark: ColorsDark.black }),

  color: getColor(theme, { light: ColorsLight.white, dark: ColorsDark.white }),

  '.MuiAlert-action': {
    padding: 0,
    paddingLeft: 16,
  },
}));
