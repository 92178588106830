import { ClientType } from 'modules/onboarding/common/components';

import { CompanyTaxResidency } from '../../components/CompanyTaxResidency/CompanyTaxResidency';
import { useLegalRepresentativeCompanyTaxResidency } from '../../hooks';
import { PageComponentProps } from '../../types';

export const LegalRepresentativeCompanyTaxResidencyStep = ({
  canGoBack,
}: PageComponentProps) => {
  const {
    countries,
    countriesLoading,
    initialValues,
    submitLoading,
    onSubmit,
    goToBack,
  } = useLegalRepresentativeCompanyTaxResidency();

  return (
    <CompanyTaxResidency
      canGoBack={canGoBack}
      clientType={ClientType.ClientRepresentative}
      countries={countries}
      countriesLoading={countriesLoading}
      initialValues={initialValues}
      submitLoading={submitLoading}
      onGoBack={goToBack}
      onSubmit={onSubmit}
    />
  );
};
