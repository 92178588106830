import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import {
  LegalClientGetLegalHeadDocument,
  useLegalClientDeleteLegalHeadFirstHeadMutation,
} from 'api/generated/graphql';
import { useError } from 'api/helpers';

export const useLegalClientDeleteLegalHeadFirstHead = () => {
  const [handle, { loading, error: apolloError }] =
    useLegalClientDeleteLegalHeadFirstHeadMutation({
      refetchQueries: [LegalClientGetLegalHeadDocument],
      awaitRefetchQueries: true,
    });

  const error = useError(apolloError);

  return {
    loading,
    error,
    deleteFirstHead: useCallback(
      async (id: string, legalHeadId: string, firstHeadId: string) => {
        const res = await handle({
          variables: {
            id,
            legalHeadId,
            firstHeadId,
          },
        });
        return res.data?.legalClientDeleteLegalHeadFirstHead;
      },
      [handle]
    ),
  };
};
