import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import {
  OnboardingLegalClientDocument,
  OnboardingLegalClientParticipantsDocument,
  useLegalClientSaveHeadEmailMutation,
} from 'api/generated/graphql';
import { useError } from 'api/helpers';

export const useLegalClientSaveHeadEmail = () => {
  const [handle, { data, loading, error: apolloError }] =
    useLegalClientSaveHeadEmailMutation({
      refetchQueries: [
        OnboardingLegalClientDocument,
        OnboardingLegalClientParticipantsDocument,
      ],
      awaitRefetchQueries: true,
    });

  const error = useError(apolloError);

  const saveHeadEmail = useCallback(
    async (legalId: string, headId: string, email: string) => {
      const res = await handle({
        variables: { id: legalId, headId, input: { email } },
      });
      return res.data?.legalClientSaveHeadEmail;
    },
    [handle]
  );

  return {
    data,
    loading,
    error,
    saveHeadEmail,
  };
};
