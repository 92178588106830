import { useTranslation } from 'libs/i18n';
import { FileType, FileUpload, Stack } from 'libs/ui';

import {
  FinancingSourcesFormType,
  FormErrors,
  Field,
  getSchema,
  MAX_FILES,
} from '../../forms/financingSourcesForm';
import { ClientType, FormBodyWrapper } from '../FormBodyWrapper';

import { FormContent } from './FormContent';

interface Props {
  initialValues?: FinancingSourcesFormType;
  onSubmit: (values: FinancingSourcesFormType) => void;
  canGoBack?: boolean;
  onGoBack?: () => void;
  formError?: FormErrors;
  submitLoading?: boolean;
  submitDisabled?: boolean;
  files?: FileUpload[];
  onAddFiles?: (file: File[]) => void;
  onRemoveFile?: (file: FileUpload, index: number) => void;
  clientType?: ClientType;
}

export const FinancingSources = ({
  initialValues,
  canGoBack,
  submitLoading,
  submitDisabled,
  formError,
  files,
  onGoBack,
  onAddFiles,
  onRemoveFile,
  onSubmit,
  clientType,
}: Props) => {
  const { t } = useTranslation();
  const schema = getSchema(t);
  return (
    <FormBodyWrapper
      canGoBack={canGoBack}
      clientType={clientType}
      initialErrors={formError}
      initialValues={initialValues}
      submitDisabled={submitDisabled}
      submitLoading={submitLoading}
      subTitle={t('onboarding.financingSources.subtitle')}
      title={t('onboarding.financingSources.title')}
      validationSchema={schema}
      onGoBack={onGoBack}
      onSubmit={onSubmit}
    >
      <Stack spacing={32}>
        <FormContent />

        <Field.FilesUpload
          files={files}
          fileTypes={[FileType.pdf]}
          maxFiles={MAX_FILES}
          name="incomeSourceFileIds"
          title={t('onboarding.financingSources.documentTitle')}
          onAddFiles={onAddFiles}
          onRemoveFile={onRemoveFile}
        />
      </Stack>
    </FormBodyWrapper>
  );
};
