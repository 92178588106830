import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useRegistrationMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { LoginData } from 'api/types/data';
import { RegistrationParam } from 'api/types/params';

import { parseParams } from './parseParams';

export const useRegistration = () => {
  const [handle, { data, loading, error: apolloError }] =
    useRegistrationMutation();

  const error = useError(apolloError);

  return {
    data: data?.AuthController_register as LoginData | undefined,
    loading,
    error,
    registration: useCallback(
      async (params: RegistrationParam) => {
        const res = await handle({
          variables: {
            input: parseParams(params),
          },
        });
        const data = res.data?.AuthController_register;
        if (!data) {
          return null;
        }
        return data as LoginData;
      },
      [handle]
    ),
  };
};
