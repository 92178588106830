import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useClientRepresentativeSaveBiographyMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { BiographyParam } from 'api/types/params/onboarding/biographyParam';

import { parseBiographyParams } from '../helpers/parseBiographyParams';

import { parseClientRepresentative } from './helpers/parseClientRepresentative';

export const useClientRepresentativeSaveBiography = () => {
  const [handle, { data, loading, error: apolloError }] =
    useClientRepresentativeSaveBiographyMutation();

  const error = useError(apolloError);

  return {
    data: data?.clientRepresentativeSaveBiography
      ? parseClientRepresentative(data.clientRepresentativeSaveBiography)
      : undefined,
    loading,
    error,
    saveBiography: useCallback(
      async (id: string, params: BiographyParam) => {
        const input = parseBiographyParams(params);
        const res = await handle({
          variables: {
            id,
            input,
          },
        });
        return res?.data?.clientRepresentativeSaveBiography
          ? parseClientRepresentative(
              res.data.clientRepresentativeSaveBiography
            )
          : undefined;
      },
      [handle]
    ),
  };
};
