import { OrganizationType } from 'api/types/entity';
import { SaveOrganizationalAndLegalFormParam } from 'api/types/params/onboarding/saveOrganizationalAndLegalFormParam';
import { YesOrNoType } from 'types/yesOrNo';

import { OrganizationalAndLegalFormFormType } from '../../forms/organizationalAndLegalFormForm';

export const getSubmitData = (
  values: OrganizationalAndLegalFormFormType
): SaveOrganizationalAndLegalFormParam => ({
  organizationalAndLegalForm: values.organizationalAndLegalForm,
  organizationType: values.organizationType?.value!,
  isFinancialInstitution:
    values.organizationType!.value === OrganizationType.Commercial
      ? values.isFinancialInstitution?.value === YesOrNoType.Yes
      : undefined,
  isUnincorporatedStructure:
    values.organizationType!.value === OrganizationType.Commercial
      ? values.isUnincorporatedStructure?.value === YesOrNoType.Yes
      : undefined,
});
