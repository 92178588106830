import { CountryEntity } from 'api/types/entity';
import { useTranslation } from 'libs/i18n';

import {
  Field,
  maxLengthLocality,
  maxLengthSpecialRegistrationArea,
} from '../../../forms/companyRegistrationPlaceForm';

export interface FormContentProps {
  countriesLoading?: boolean;
  countries: CountryEntity[];
}

export const FormContent = ({
  countries,
  countriesLoading,
}: FormContentProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Field.Autocomplete
        label={t('onboarding.companyRegistrationPlace.county.label')}
        loading={countriesLoading}
        name="country"
        optionLabelKey="name"
        options={countries}
        optionValueKey="id"
        placeholder={t(
          'onboarding.companyRegistrationPlace.country.placeholder'
        )}
      />

      <Field.Text
        label={t('onboarding.companyRegistrationPlace.locality.label')}
        maxLength={maxLengthLocality}
        name="locality"
        placeholder={t(
          'onboarding.companyRegistrationPlace.locality.placeholder'
        )}
      />

      <Field.Text
        showOptionalMark
        label={t(
          'onboarding.companyRegistrationPlace.specialRegistrationArea.label'
        )}
        maxLength={maxLengthSpecialRegistrationArea}
        name="specialRegistrationArea"
        placeholder={t(
          'onboarding.companyRegistrationPlace.specialRegistrationArea.placeholder'
        )}
      />
    </>
  );
};
