import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useLegalClientRepresentativeSaveKycStepMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';

import { parseLegalClientRepresentative } from './helpers';

export const useLegalClientRepresentativeSaveKycStep = () => {
  const [saveKycStep, { data, loading, error: apolloError }] =
    useLegalClientRepresentativeSaveKycStepMutation();

  const error = useError(apolloError);

  return {
    data: data?.legalClientRepresentativeSaveKycStep
      ? parseLegalClientRepresentative(
          data.legalClientRepresentativeSaveKycStep
        )
      : undefined,
    loading,
    error,
    saveKycStep: useCallback(
      async (id: string) => {
        const res = await saveKycStep({
          variables: { id },
        });
        return res.data?.legalClientRepresentativeSaveKycStep
          ? parseLegalClientRepresentative(
              res.data.legalClientRepresentativeSaveKycStep
            )
          : undefined;
      },
      [saveKycStep]
    ),
  };
};
