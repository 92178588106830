import { PositionInCompanyEntity } from 'api/types/entity';
import { FileUpload } from 'hooks/useFilesUpload';

import { PositionInCompanyFormType } from '../../forms/positionInCompanyForm';

export const getInitialData = (
  positionInCompany: PositionInCompanyEntity | undefined
) => {
  const values: PositionInCompanyFormType | undefined = positionInCompany
    ? {
        position: positionInCompany?.position,
        dateOfExpiration:
          positionInCompany.authorityConfirmationDocument.dateOfExpiration,
        fileIds: positionInCompany.authorityConfirmationDocument.files.map(
          (v) => v.id
        ),
      }
    : undefined;

  const files: FileUpload[] | undefined =
    positionInCompany?.authorityConfirmationDocument.files.map(
      (v) =>
        new FileUpload({ name: v.name, size: v.size }, undefined, false, v.id)
    );
  return { values, files };
};
