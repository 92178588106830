import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useTranslation as useTranslationBase } from 'react-i18next';

import { Langs } from './types';

/**
 * Hook useTranslation provides access to the translation
 * function (t) and the language change function (changeLanguage).
 * It utilizes the base hook useTranslationBase to access
 * translations and the current application language.
 *
 * @returns {Object} Returns an object with two functions:
 *   - t: Function for retrieving translations for a given key (path) in the current language.
 *   - changeLanguage: Function for changing the current application language to the specified one.
 *   - language: Is set to the current detected or set language.
 */
export const useTranslation = () => {
  const { t, i18n } = useTranslationBase();

  const changeLanguage = useCallback(
    (lang: Langs) => i18n.changeLanguage(lang),
    [i18n]
  );

  return { t, changeLanguage, language: i18n.language as Langs };
};
