import { ReactNode, useMemo } from 'react';

import { OnboardingProcessEntity } from 'api/types/entity';
import { LoadingLayout } from 'components';

import { PageComponent, RoutePage } from '../../types';

import { OnboardingContext, OnboardingContextProps } from './Context';
import { useOnboardingPage } from './hooks';

type OnboardingPage = { pageKey: string; page: RoutePage; pageNumber: number };

interface OnboardingProviderProps<T> {
  clientId: string;
  data: T;
  pagesMap: Record<string, PageComponent>;
  process: OnboardingProcessEntity;
  renderChild: ({ route }: { route: OnboardingPage }) => ReactNode;
  onGoBack?: (name: string) => void;
  onEnd?: () => void;
}

export const OnboardingProvider = <T,>({
  clientId,
  data,
  pagesMap,
  process,
  onEnd,
  renderChild,
  onGoBack,
}: OnboardingProviderProps<T>) => {
  const {
    canGoBack,
    currentPageName,
    currentPage,
    currentPageNumber,
    totalStepCount,
    goToBack,
    goToNext,
  } = useOnboardingPage(process, pagesMap, { onGoBack, onEnd });

  const value: OnboardingContextProps<T> = useMemo(
    () => ({
      data,
      clientId,
      goToBack,
      goToNext,
      step: { count: totalStepCount, current: currentPageNumber + 1 },
    }),
    [data, clientId, goToBack, goToNext, totalStepCount, currentPageNumber]
  );

  return (
    <OnboardingContext.Provider value={value}>
      {currentPage !== undefined ? (
        renderChild({
          route: {
            page: {
              component: currentPage,
              componentProps: {
                canGoBack,
              },
            },
            pageKey: currentPageName,
            pageNumber: currentPageNumber,
          },
        })
      ) : (
        <LoadingLayout />
      )}
    </OnboardingContext.Provider>
  );
};
