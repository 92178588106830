// eslint-disable-next-line no-restricted-imports
import { FinancialMonitoringDto } from 'api/generated/graphql';
import {
  AmlPolicyReviewFrequency,
  LegalClientFinancialMonitoringEntity,
  MonetaryServiceTransactionCountForLastSixMonths,
} from 'api/types/entity';
import { Maybe } from 'types/maybe';

export const parseFinancialMonitoringEntity = (
  data?: Maybe<FinancialMonitoringDto>
): LegalClientFinancialMonitoringEntity | undefined =>
  data
    ? {
        isSubjectToRegulation: data.isSubjectToRegulation,
        info: data.info
          ? {
              ...data.info,
              amlPolicyReviewFrequency:
                AmlPolicyReviewFrequency[data.info.amlPolicyReviewFrequency],
              monetaryServiceTransactionCountForLastSixMonths:
                MonetaryServiceTransactionCountForLastSixMonths[
                  data.info.monetaryServiceTransactionCountForLastSixMonths
                ],
              dateOfLastCheck: data.info.dateOfLastCheck
                ? new Date(data.info.dateOfLastCheck)
                : undefined,
            }
          : undefined,
      }
    : undefined;
