import { FileEntity } from '../fileEntity';

export enum CompanyIncomeSources {
  ProfitFromCoreActivities = 'ProfitFromCoreActivities', // Доход от основной деятельности
  InvestmentIncome = 'InvestmentIncome', // Доход от инвестиций (дивиденды, депозиты и тд)
  AuthorizedCapital = 'AuthorizedCapital', // Уставной капитал
  VoluntaryPropertyLossesAndDonations = 'VoluntaryPropertyLossesAndDonations', // Добровольные имущественные взносы и пожертвования
  CreditsAndLoans = 'CreditsAndLoans', // Кредиты и займы
  LeasingFromFinancialOrganizations = 'LeasingFromFinancialOrganizations', // Лизинг от финансовых учреждений
  PersonalFunds = 'PersonalFunds', // Личные средства владельца
  ReserveCapitalOfCompany = 'ReserveCapitalOfCompany', // Резервный капитал
  Other = 'Other', // Прочие доходы
}

export enum CompanyFinancingSources {
  LoansAndCreditFacilities = 'LoansAndCreditFacilities', // Займы и кредитные средства
  InternalFundsOfCompany = 'InternalFundsOfCompany', // Внутренние собственные средства компании
  Charity = 'Charity', // Благотворительность
  Other = 'Other', // Прочее
}

export interface LegalFinancingSourcesEntity {
  incomeSources: CompanyIncomeSources[];
  otherIncomeSource?: string;
  financingSources: CompanyFinancingSources[];
  otherFinancingSource?: string;
  lastYearAuditFinancialReportFiles: FileEntity[];
  recommendationLetterFiles: FileEntity[];
}
