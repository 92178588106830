import { FormContainerStepProps } from 'components';
import { useTranslation } from 'libs/i18n';

import {
  OnboardingCitizenshipFormType,
  FormErrors,
  getSchema,
} from '../../forms/onboardingCitizenshipForm';
import { ClientType, FormBodyWrapper } from '../FormBodyWrapper';

import { FormContent, FormContentProps } from './FormContent';

interface OnboardingCitizenshipProps
  extends FormContentProps,
    FormContainerStepProps {
  initialValues?: OnboardingCitizenshipFormType;
  onSubmit: (values: OnboardingCitizenshipFormType) => void;
  canGoBack?: boolean;
  onGoBack?: () => void;
  formError?: FormErrors;
  submitLoading?: boolean;
  clientType?: ClientType;
  subTitle?: string;
}

export const OnboardingCitizenship = ({
  initialValues,
  canGoBack,
  onGoBack,
  submitLoading,
  formError,
  onSubmit,
  clientType,
  subTitle,
  step,
  ...formProps
}: OnboardingCitizenshipProps) => {
  const schema = getSchema();
  const { t } = useTranslation();
  return (
    <FormBodyWrapper
      canGoBack={canGoBack}
      clientType={clientType}
      initialErrors={formError}
      initialValues={initialValues}
      step={step}
      submitLoading={submitLoading}
      subTitle={subTitle ?? t('onboarding.citizenship.subtitle')}
      title={t('onboarding.citizenship.title')}
      validationSchema={schema}
      onGoBack={onGoBack}
      onSubmit={onSubmit}
    >
      <FormContent {...formProps} />
    </FormBodyWrapper>
  );
};
