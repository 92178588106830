import { useClientSaveKycStep, useOnboardingClient } from 'api/requests';
import { useOnboardingKyc } from 'modules/onboarding/common/hooks';

import { useOnboardingContext } from './useOnboardingContext';

export const useKyc = () => {
  const { clientId, goToNext } = useOnboardingContext();

  const { currentClient, refetch } = useOnboardingClient(clientId);

  const { saveKycStep } = useClientSaveKycStep();

  const onSuccess = async () => {
    await saveKycStep(clientId);
    goToNext();
  };

  const { expirationHandler, loading, token } = useOnboardingKyc({
    status: currentClient?.kyc?.status,
    refetch,
    onSuccess,
  });

  return {
    loading: loading && !token,
    token,
    status: currentClient?.kyc?.status,
    expirationHandler,
  };
};
