import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import {
  OnboardingLegalClientDocument,
  useLegalClientDeleteBeneficiaryMutation,
} from 'api/generated/graphql';
import { useError } from 'api/helpers';

export const useLegalClientDeleteBeneficiary = () => {
  const [handle, { loading, error: apolloError }] =
    useLegalClientDeleteBeneficiaryMutation({
      refetchQueries: [OnboardingLegalClientDocument],
      awaitRefetchQueries: true,
    });

  const error = useError(apolloError);

  return {
    loading,
    error,
    deleteBeneficiary: useCallback(
      async (id: string, beneficiaryId: string) => {
        const res = await handle({
          variables: {
            id,
            beneficiaryId,
          },
        });
        return res?.data?.legalClientDeleteBeneficiary;
      },
      [handle]
    ),
  };
};
