import { ConfirmationDialog } from 'components';
import { useTranslation } from 'libs/i18n';
import { DialogProps, ListTypography } from 'libs/ui';

interface Props extends DialogProps {
  loading?: boolean;
  onConfirm: () => void;
  onBack: () => void;
}

export const ConfirmationDataDialog = (props: Props) => {
  const { t } = useTranslation();

  return (
    <ConfirmationDialog
      fullWidth
      confirmButtonLabel={t(
        'onboarding.companyConfirmationDataDialog.confirmButton'
      )}
      headerCentered={false}
      maxWidth="lg"
      title={t('onboarding.companyConfirmationDataDialog.title')}
      {...props}
    >
      <ListTypography
        datax="companyConfirmationDataDialog.text"
        listType="ul"
        spacing={16}
        texts={t('onboarding.companyConfirmationDataDialog.texts', {
          returnObjects: true,
        })}
      />
    </ConfirmationDialog>
  );
};
