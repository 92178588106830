import { Box } from 'libs/ui';
import { styled } from 'libs/ui/styled';

export interface BodyProps {
  /**
   * Padding
   * default - '52px 32px'
   * small - 8
   * does not work for mobile
   * */
  padding?: 'default' | 'small';
  /**
   * filled - fills in the background color except for mobile
   * filled-mobile - fills in the background color
   */
  variant?: 'filled' | 'outlined' | 'filled-mobile';
}

export const Body = styled(Box)<BodyProps>(
  ({ theme, variant = 'filled', padding = 'default' }) => ({
    ...(variant === 'filled' && {
      [theme.breakpoints.up('mobile')]: {
        backgroundColor: theme.palette.body,
        boxShadow: theme.palette.shadow.main,
      },
    }),

    ...(variant === 'filled-mobile' && {
      backgroundColor: theme.palette.body,
      boxShadow: theme.palette.shadow.main,
    }),

    borderRadius: 24,
    width: '100%',
    padding: padding === 'small' ? 8 : '52px 32px',

    [theme.breakpoints.down('mobile')]: {
      padding: 0,
    },
  })
);
