import { ChangeEvent, KeyboardEvent, Ref, forwardRef } from 'react';

import { useTranslation } from 'libs/i18n';

import { Icon } from '../Icon';
import { Pressed } from '../Pressed';
import { Typography } from '../Typography';
import { useTheme } from '../theme';

import { StyledInput } from './Input.styles';
import { InputProps } from './types';

/**
 * Input component.
 */
export const Input = forwardRef(
  (
    {
      onChange,
      onChangeText,
      onSubmit,
      onKeyDown,
      onClear,
      rightElement,
      leftElement,
      inputRef,
      error,
      fullWidth,
      maxLength,
      trim,
      readOnly,
      value,
      label,
      showOptionalMark,
      ...props
    }: InputProps,
    ref: Ref<HTMLDivElement>
  ) => {
    const { theme } = useTheme();
    const { t } = useTranslation();

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      if (maxLength !== undefined && event.target.value.length > maxLength) {
        return;
      }
      onChange?.(event);
      let { value } = event.target;
      if (trim === 'all') {
        value = value?.trim();
      } else if (trim === 'end') {
        value = value?.trimEnd();
      } else if (trim === 'start') {
        value = value?.trimStart();
      }

      onChangeText?.(value, event);
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        onSubmit?.(e);
      }
      onKeyDown?.(e);
    };

    const inputLabel =
      label && typeof label === 'string' && showOptionalMark ? (
        <Typography variant="captionSemiBold">
          {label}{' '}
          <Typography color={theme.palette.text.gray} variant="captionSemiBold">
            ({t('components.input.optionalLabel')})
          </Typography>
        </Typography>
      ) : (
        label
      );

    return (
      <StyledInput
        ref={ref}
        error={Boolean(error)}
        fullWidth={fullWidth}
        InputLabelProps={{ shrink: true, focused: false }}
        InputProps={{
          endAdornment: (
            <>
              {!!value && onClear && !readOnly && !props.disabled && (
                <Pressed onClick={onClear}>
                  <Icon.Close size={16} />
                </Pressed>
              )}
              {rightElement}
            </>
          ),
          startAdornment: leftElement,
          readOnly,
          className: 'input-root',
        }}
        inputRef={inputRef}
        label={inputLabel}
        value={value}
        variant="standard"
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        {...props}
      />
    );
  }
);
