import { useRef } from 'react';

import { useLegalClientSaveSignaturesCardFiles } from 'api/requests';
import { useFilesUpload, useServerErrorNotify } from 'hooks';
import { OnboardingSignatureSamplesFormType } from 'modules/onboarding/common/forms/onboardingSignatureSamplesForm';
import { getInitialData } from 'modules/onboarding/common/helpers/signatureSamples';

import { useOnboardingContext } from './useOnboardingContext';

export const useSignatureSamples = () => {
  const { goToBack, goToNext, data } = useOnboardingContext();
  const { error, loading, saveSignatures } =
    useLegalClientSaveSignaturesCardFiles();

  const initialData = useRef(
    getInitialData(data.legalClient?.signaturesCardFiles)
  ).current;
  const initialValues = useRef(initialData?.values).current;

  const {
    files,
    isLoadingFile: submitDisabled,
    onRemoveFile,
    onAddFiles,
  } = useFilesUpload(initialData?.files);

  const onSubmit = async (values: OnboardingSignatureSamplesFormType) => {
    if (values) {
      await saveSignatures(data.legalClient!.id, {
        signaturesCardFileIds: values.fileIds,
      });
      goToNext();
    }
  };

  useServerErrorNotify(error);

  return {
    onSubmit,
    goToBack,
    submitLoading: loading,
    initialValues,
    files,
    onAddFiles,
    onRemoveFile,
    submitDisabled,
  };
};
