import { GlobalClasses } from 'helpers';
import { Stack, Typography } from 'libs/ui';
import { Icon } from 'libs/ui/Icon';

interface Props {
  title: string;
}

export const Item = ({ title }: Props) => (
  <Stack
    alignItems="center"
    className={GlobalClasses.hovered}
    direction="row"
    flex={1}
    justifyContent="space-between"
  >
    <Typography variant="bodyBold">{title}</Typography>
    <Icon.ArrowRight className={GlobalClasses.hovered} />
  </Stack>
);
