import { MaritalStatusType, OccupationType } from 'api/types/entity';
import { useTranslation } from 'libs/i18n';
import { Stack } from 'libs/ui';
import {
  MaritalStatusTranslationPath,
  OccupationTranslationPath,
} from 'modules/onboarding/common/helpers/biography/getInitialData';

import { Field, useFormContext } from '../../../forms/onboardingBiographyForm';

export const FormContent = () => {
  const { values } = useFormContext();
  const { t } = useTranslation();

  const isCompany =
    values.occupation?.value === OccupationType.businessOwner ||
    values.occupation?.value === OccupationType.employee;

  const isEntrepreneur =
    values.occupation?.value === OccupationType.entrepreneur;

  return (
    <Stack spacing={24} spacingMobile={16}>
      <Field.Autocomplete
        label={t('onboarding.biography.maritalStatus.label')}
        name="maritalStatus"
        optionLabelKey="label"
        options={Object.values(MaritalStatusType).map((v) => ({
          label: t(MaritalStatusTranslationPath[v]),
          value: v,
        }))}
        optionValueKey="value"
        placeholder={t('onboarding.biography.maritalStatus.placeholder')}
        variant="select"
      />

      <Field.Autocomplete
        label={t('onboarding.biography.occupation.label')}
        name="occupation"
        optionLabelKey="label"
        options={Object.values(OccupationType).map((v) => ({
          label: t(OccupationTranslationPath[v]),
          value: v,
        }))}
        optionValueKey="value"
        placeholder={t('onboarding.biography.occupation.placeholder')}
        variant="select"
      />

      {isCompany && (
        <>
          <Field.Text
            label={t('onboarding.biography.companyName.label')}
            name="companyName"
            placeholder={t('onboarding.biography.companyName.placeholder')}
          />
          <Field.Text
            label={t('onboarding.biography.workAddress.label')}
            name="workAddress"
            placeholder={t('onboarding.biography.workAddress.placeholder')}
          />
          <Field.Text
            label={t('onboarding.biography.position.label')}
            name="position"
            placeholder={t('onboarding.biography.position.placeholder')}
          />
        </>
      )}

      {isEntrepreneur && (
        <>
          <Field.Text
            label={t('onboarding.biography.entrepreneurName.label')}
            name="entrepreneurName"
            placeholder={t('onboarding.biography.entrepreneurName.placeholder')}
          />
          <Field.Text
            label={t('onboarding.biography.registrationNumber.label')}
            name="registrationNumber"
            placeholder={t(
              'onboarding.biography.registrationNumber.placeholder'
            )}
          />
          <Field.DatePicker
            label={t('onboarding.biography.registrationDate.label')}
            name="registrationDate"
            placeholder={t('onboarding.biography.registrationDate.placeholder')}
          />
          <Field.Text
            label={t('onboarding.biography.registrationPlace.label')}
            name="registrationPlace"
            placeholder={t(
              'onboarding.biography.registrationPlace.placeholder'
            )}
          />
          <Field.Text
            label={t('onboarding.biography.typeOfServices.label')}
            name="typeOfServices"
            placeholder={t('onboarding.biography.typeOfServices.placeholder')}
          />
        </>
      )}
    </Stack>
  );
};
