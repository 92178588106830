import { yesOrNotSelectValidation } from 'helpers';
import {
  ObjectSchema,
  TypedForm,
  object,
  priceAndCurrencyOptional,
} from 'libs/form';
import { PriceFieldValue } from 'libs/form/fields/PriceField';
import { Maybe } from 'types/maybe';
import { YesOrNoSelectType } from 'types/yesOrNo';

export type BaseDebtsOptionalPrice = Maybe<Partial<PriceFieldValue>>;

export interface BaseDebtsFormType {
  taxes?: BaseDebtsOptionalPrice;
  fees?: BaseDebtsOptionalPrice;
  penalties?: BaseDebtsOptionalPrice;
  finesForViolation?: BaseDebtsOptionalPrice;
  bankruptcyProceedings?: Maybe<YesOrNoSelectType>;
  delaysOrNonFulfillmentOfObligations?: Maybe<YesOrNoSelectType>;
}

export const getBaseDebtsSchema = (
  isEntrepreneur?: boolean
): ObjectSchema<BaseDebtsFormType> =>
  object({
    taxes: priceAndCurrencyOptional().nullable(),
    fees: priceAndCurrencyOptional().nullable(),
    penalties: priceAndCurrencyOptional().nullable(),
    finesForViolation: priceAndCurrencyOptional().nullable(),

    bankruptcyProceedings: yesOrNotSelectValidation
      .when('delaysOrNonFulfillmentOfObligations', {
        is: () => isEntrepreneur,
        then: (schema) => schema.default(null).required(),
      })

      .default(isEntrepreneur ? null : undefined),

    delaysOrNonFulfillmentOfObligations: yesOrNotSelectValidation
      .when('finesForViolation', {
        is: () => isEntrepreneur,
        then: (schema) => schema.default(null).required(),
      })
      .default(isEntrepreneur ? null : undefined),
  });

export const { Field } = TypedForm<BaseDebtsFormType>();
