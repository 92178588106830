import {
  AmlPolicyReviewFrequency,
  MonetaryServiceTransactionCountForLastSixMonths,
} from 'api/types/entity';
import { isNo, isValidName, isYes, yesOrNotSelectValidation } from 'helpers';
import { isValidNumber } from 'libphonenumber-js';
import {
  TypedForm,
  FormErrors as FormErrorsBase,
  ObjectSchema,
  string,
  object,
  date,
  mixed,
  email,
} from 'libs/form';
import { TFunc } from 'libs/i18n';
import { getTodayWithResetHours } from 'modules/onboarding/common/helpers/getToday';
import { Maybe } from 'types/maybe';
import { SelectType } from 'types/selectType';
import { YesOrNoSelectType } from 'types/yesOrNo';

export const getAmlPolicyReviewFrequencyOptions = (t: TFunc) =>
  Object.values(AmlPolicyReviewFrequency).map((v) => ({
    label: t(
      `onboarding.companyFinancialMonitoring.amlPolicyReviewFrequencyOptions.${AmlPolicyReviewFrequency[v]}`
    ),
    value: v,
  }));

export const getAmlPolicyReviewFrequencySelectedOption = (
  t: TFunc,
  v?: AmlPolicyReviewFrequency
) => getAmlPolicyReviewFrequencyOptions(t).find((option) => option.value === v);

export const getMonetaryServiceTransactionCountForLastSixMonthsOptions = (
  t: TFunc
) =>
  Object.values(MonetaryServiceTransactionCountForLastSixMonths).map((v) => ({
    label: t(
      `onboarding.companyFinancialMonitoring.monetaryServiceTransactionCountForLastSixMonthsOptions.${MonetaryServiceTransactionCountForLastSixMonths[v]}`
    ),
    value: v,
  }));

export const getMonetaryServiceTransactionCountForLastSixMonthsSelectedOption =
  (t: TFunc, v?: MonetaryServiceTransactionCountForLastSixMonths) =>
    getMonetaryServiceTransactionCountForLastSixMonthsOptions(t).find(
      (option) => option.value === v
    );

export interface CompanyFinancialMonitoringFormType {
  isSubjectToRegulation: Maybe<YesOrNoSelectType>;
  authorityName?: string;
  authorityWebsite?: string;
  hasAmlCftComplianceControl?: Maybe<YesOrNoSelectType>;
  hasTransactionMonitoringAndSuspiciousActivityDetection?: Maybe<YesOrNoSelectType>;
  amlPolicyReviewFrequency?: Maybe<SelectType<AmlPolicyReviewFrequency>>;
  otherAmlPolicyReviewFrequency?: string;
  amlCftPoliciesApplyToForeignEntities?: Maybe<YesOrNoSelectType>;
  hasAmlCftDocumentEffectivenessAssessmentProcedures?: Maybe<YesOrNoSelectType>;
  dateOfLastCheck?: Date | null;
  hasAmlCftInternalControlOfficer?: Maybe<YesOrNoSelectType>;
  officerFirstName?: string;
  officerLastName?: string;
  officerMiddleName?: string;
  officerPosition?: string;
  officerPhoneNumber?: string;
  officerEmail?: string;
  canProvideAmlPolicyDocuments?: Maybe<YesOrNoSelectType>;
  documentProvisionRefusalReason?: string;
  isInternalControlInfoConfidential?: Maybe<YesOrNoSelectType>;
  hadAmlCftInvestigationsOrPenalties?: Maybe<YesOrNoSelectType>;
  investigationOrPenaltyReasons?: string;
  hasMandatoryControlTransactions?: Maybe<YesOrNoSelectType>;
  mandatoryControlTransactionIdentificationProcedure?: string;
  monetaryServiceTransactionCountForLastSixMonths?: Maybe<
    SelectType<MonetaryServiceTransactionCountForLastSixMonths>
  >;
  isAffiliatedWithNonProfitOrganizations?: Maybe<YesOrNoSelectType>;
  receivesFundingFromStateBudget?: Maybe<YesOrNoSelectType>;
  receivesFundingFromForeignNonProfitNGOs?: Maybe<YesOrNoSelectType>;
  isCompliantWithFATFRecommendations?: Maybe<YesOrNoSelectType>;
  hasBeenRefusedToOpenAccountByBanks?: Maybe<YesOrNoSelectType>;
}

export const maxLengthTextField = 2000;
export const maxLengthOfficerPosition = 200;
export const maxLengthAuthorityName = 200;
export const maxLengthName = 20;

const yesOrNoCustomValidation = yesOrNotSelectValidation
  .default(undefined)
  .when('isSubjectToRegulation', {
    is: isYes,
    then: (schema) => schema.default(null).nullable().required(),
  });

export const getSchema = (
  t: TFunc,
  hasBranches: boolean
): ObjectSchema<CompanyFinancialMonitoringFormType> =>
  object({
    isSubjectToRegulation: yesOrNotSelectValidation.default(null).required(),
    authorityName: string()
      .max(maxLengthAuthorityName)
      .when('isSubjectToRegulation', {
        is: isYes,
        then: (schema) => schema.required(),
      }),
    authorityWebsite: string().max(maxLengthTextField),
    hasAmlCftComplianceControl: yesOrNoCustomValidation,
    hasTransactionMonitoringAndSuspiciousActivityDetection:
      yesOrNoCustomValidation,
    amlPolicyReviewFrequency: object({
      value: mixed<AmlPolicyReviewFrequency>()
        .oneOf(Object.values(AmlPolicyReviewFrequency))
        .required(),
      label: string().required(),
    })
      .default(undefined)
      .when('isSubjectToRegulation', {
        is: isYes,
        then: (schema) => schema.default(null).nullable().required(),
      }),
    otherAmlPolicyReviewFrequency: string()
      .max(maxLengthTextField)
      .when('amlPolicyReviewFrequency', {
        is: (v: SelectType<AmlPolicyReviewFrequency> | null) =>
          v?.value === AmlPolicyReviewFrequency.Other,
        then: (schema) => schema.required(),
      }),
    amlCftPoliciesApplyToForeignEntities: yesOrNotSelectValidation
      .default(undefined)
      .when('isSubjectToRegulation', {
        is: (v: YesOrNoSelectType | null) => isYes(v) && hasBranches,
        then: (schema) => schema.default(null).nullable().required(),
      }),
    hasAmlCftDocumentEffectivenessAssessmentProcedures: yesOrNoCustomValidation,
    dateOfLastCheck: date()
      .nullable()
      .default(null)
      .max(getTodayWithResetHours(), t('validation.maxDate')),
    hasAmlCftInternalControlOfficer: yesOrNoCustomValidation,
    officerFirstName: string()
      .max(maxLengthName)
      .test('ischars', t('validation.noSpecialCharsAndNumber'), isValidName)
      .when('hasAmlCftInternalControlOfficer', {
        is: isYes,
        then: (schema) => schema.required(),
      }),
    officerLastName: string()
      .max(maxLengthName)
      .test('ischars', t('validation.noSpecialCharsAndNumber'), isValidName)
      .when('hasAmlCftInternalControlOfficer', {
        is: isYes,
        then: (schema) => schema.required(),
      }),
    officerMiddleName: string()
      .max(maxLengthName)
      .test('ischars', t('validation.noSpecialCharsAndNumber'), isValidName),
    officerPosition: string()
      .max(maxLengthOfficerPosition)
      .when('hasAmlCftInternalControlOfficer', {
        is: isYes,
        then: (schema) => schema.required(),
      }),
    officerPhoneNumber: string()
      .test('officerPhoneNumber', t('validation.phone'), (value) => {
        if (!value) return true;
        return isValidNumber(value);
      })
      .max(20)
      .when('hasAmlCftInternalControlOfficer', {
        is: isYes,
        then: (schema) => schema.required(),
      }),
    officerEmail: email(t).when('hasAmlCftInternalControlOfficer', {
      is: isYes,
      then: (schema) => schema.required(),
    }),
    canProvideAmlPolicyDocuments: yesOrNoCustomValidation,
    documentProvisionRefusalReason: string()
      .max(maxLengthTextField)
      .when('canProvideAmlPolicyDocuments', {
        is: isNo,
        then: (schema) => schema.required(),
      }),
    isInternalControlInfoConfidential: yesOrNoCustomValidation,
    hadAmlCftInvestigationsOrPenalties: yesOrNoCustomValidation,
    investigationOrPenaltyReasons: string()
      .max(maxLengthTextField)
      .when('hadAmlCftInvestigationsOrPenalties', {
        is: isYes,
        then: (schema) => schema.required(),
      }),
    hasMandatoryControlTransactions: yesOrNoCustomValidation,
    mandatoryControlTransactionIdentificationProcedure: string()
      .max(maxLengthTextField)
      .when('hasMandatoryControlTransactions', {
        is: isYes,
        then: (schema) => schema.required(),
      }),

    monetaryServiceTransactionCountForLastSixMonths: object({
      value: mixed<MonetaryServiceTransactionCountForLastSixMonths>()
        .oneOf(Object.values(MonetaryServiceTransactionCountForLastSixMonths))
        .required(),
      label: string().required(),
    })
      .default(undefined)
      .when('isSubjectToRegulation', {
        is: isYes,
        then: (schema) => schema.default(null).nullable().required(),
      }),
    isAffiliatedWithNonProfitOrganizations: yesOrNoCustomValidation,
    receivesFundingFromStateBudget: yesOrNoCustomValidation,
    receivesFundingFromForeignNonProfitNGOs: yesOrNoCustomValidation,
    isCompliantWithFATFRecommendations: yesOrNoCustomValidation,
    hasBeenRefusedToOpenAccountByBanks: yesOrNoCustomValidation,
  });

export const { Field, Form, useFormContext, Submit } =
  TypedForm<CompanyFinancialMonitoringFormType>();

export type FormErrors = FormErrorsBase<CompanyFinancialMonitoringFormType>;
