import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useClientSaveFinancingSourcesMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { ClientSaveFinancingSourcesParam } from 'api/types/params';

import { parseClientEntity, parseSaveFinancingSourcesParam } from '../helpers';

export const useClientSaveFinancingSources = () => {
  const [saveFinancingSources, { data, loading, error: apolloError }] =
    useClientSaveFinancingSourcesMutation();

  const error = useError(apolloError);

  return {
    data: data?.clientSaveFinancingSources
      ? parseClientEntity(data.clientSaveFinancingSources)
      : undefined,
    loading,
    error,
    saveFinancingSources: useCallback(
      async (id: string, params: ClientSaveFinancingSourcesParam) => {
        const res = await saveFinancingSources({
          variables: {
            id,
            input: parseSaveFinancingSourcesParam(params),
          },
        });

        return res.data?.clientSaveFinancingSources
          ? parseClientEntity(res.data.clientSaveFinancingSources)
          : undefined;
      },
      [saveFinancingSources]
    ),
  };
};
