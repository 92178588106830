import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import {
  OnboardingLegalClientDocument,
  useLegalClientCreateBeneficiaryMutation,
} from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { LegalClientCreateBeneficiaryParam } from 'api/types/params';
import { formatToISODate } from 'helpers';

import { parseLegalClientBeneficiaryEntity } from './helpers';

export const useLegalClientCreateBeneficiary = () => {
  const [handle, { data, loading, error: apolloError }] =
    useLegalClientCreateBeneficiaryMutation({
      refetchQueries: [OnboardingLegalClientDocument],
      awaitRefetchQueries: true,
    });

  const error = useError(apolloError);

  return {
    data: data?.legalClientCreateBeneficiary
      ? parseLegalClientBeneficiaryEntity(data.legalClientCreateBeneficiary)
      : undefined,
    loading,
    error,
    createBeneficiary: useCallback(
      async (id: string, param: LegalClientCreateBeneficiaryParam) => {
        const res = await handle({
          variables: {
            id,
            input: {
              ...param,
              dateOfBirth: formatToISODate(param.dateOfBirth),
            },
          },
        });
        return res.data?.legalClientCreateBeneficiary
          ? parseLegalClientBeneficiaryEntity(
              res.data.legalClientCreateBeneficiary
            )
          : undefined;
      },
      [handle]
    ),
  };
};
