import {
  TypedForm,
  FormErrors as FormErrorsBase,
  ObjectSchema,
  string,
  object,
  mixed,
  email,
} from 'libs/form';
import { TFunc } from 'libs/i18n';
import { Maybe } from 'types/maybe';
import { YesOrNoSelectType, YesOrNoType } from 'types/yesOrNo';

export interface CompanyContactsFormType {
  phone?: string;
  email?: string;
  site?: string;
  disclosureInformation: Maybe<YesOrNoSelectType>;
  disclosureInformationResource?: string;
}

export const phoneMaxLength = 200;
export const siteMaxLength = 200;
export const disclosureInformationResourceMaxLength = 200;

export const disclosureInformation = object({
  value: mixed<YesOrNoType>().oneOf(Object.values(YesOrNoType)).required(),
  label: string().required(),
})
  .default(null)
  .required();

export const getSchema = (t: TFunc): ObjectSchema<CompanyContactsFormType> =>
  object({
    phone: string()
      .max(phoneMaxLength),
    email: email(t),
    site: string().max(siteMaxLength),
    disclosureInformation,
    disclosureInformationResource: string().when('disclosureInformation', {
      is: (v: YesOrNoSelectType) => v?.value === YesOrNoType.Yes,
      then: (schema) =>
        schema.required().max(disclosureInformationResourceMaxLength),
    }),
  });

export const { Field, Form, useFormContext, Submit } =
  TypedForm<CompanyContactsFormType>();

export type FormErrors = FormErrorsBase<CompanyContactsFormType>;
