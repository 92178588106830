import { PropsWithChildren, forwardRef } from 'react';

import { Icon } from '../Icon';

import { AlertStyled } from './Alert.styles';
import { AlertProps } from './types';

const IconClose = () => <Icon.Close size={22} />;

export const Alert = forwardRef<HTMLDivElement, AlertProps & PropsWithChildren>(
  ({ onClose, variant, children }, ref) => (
    <AlertStyled
      ref={ref}
      icon={false}
      severity={variant}
      slotProps={{ closeButton: { disableRipple: true } }}
      slots={{ closeIcon: IconClose }}
      variant="filled"
      onClose={onClose}
    >
      {children}
    </AlertStyled>
  )
);
