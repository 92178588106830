import { useTranslation } from 'libs/i18n';

import { Field } from '../../../../forms/companyActivitiesForm';

interface Props {
  index: number;
}

export const Fields = ({ index }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <Field.Text
        label={t('onboarding.companyActivities.activity.label')}
        name={`activityTypes.${index}.name` as 'activityTypes'}
        placeholder={t('onboarding.companyActivities.activity.placeholder')}
      />

      <Field.Text
        showOptionalMark
        label={t('onboarding.companyActivities.activityCode.label')}
        name={`activityTypes.${index}.code` as 'activityTypes'}
        placeholder={t('onboarding.companyActivities.activityCode.placeholder')}
      />
    </>
  );
};
