import { config } from 'config';
import { useTranslation } from 'libs/i18n';
import { FileType } from 'libs/ui';
import { OnboardingSignatureSamples } from 'modules/onboarding/common/components';

import { useSignatureSamples } from '../hooks';
import { PageComponentProps } from '../types';

export const SignatureSamplesStep = ({ canGoBack }: PageComponentProps) => {
  const {
    onSubmit,
    goToBack,
    files,
    onAddFiles,
    onRemoveFile,
    initialValues,
    submitLoading,
    submitDisabled,
  } = useSignatureSamples();

  const { t } = useTranslation();

  return (
    <OnboardingSignatureSamples
      canGoBack={canGoBack}
      documentSimpleHref={config.DOCUMENT_TEMPLATE_SIGNATURES_URL ?? ''}
      documentTitle={t('onboarding.companySignatureSamples.documentTitle')}
      files={files}
      fileTypes={[FileType.pdf]}
      initialValues={initialValues}
      submitDisabled={submitDisabled}
      submitLoading={submitLoading}
      subTitle={t('onboarding.companySignatureSamples.subtitle')}
      title={t('onboarding.companySignatureSamples.title')}
      onAddFiles={onAddFiles}
      onGoBack={goToBack}
      onRemoveFile={onRemoveFile}
      onSubmit={onSubmit}
    />
  );
};
