import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useLegalClientSaveActivityTypesMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { LegalClientSaveActivityTypesParam } from 'api/types/params';

import { parseLegalClient } from './helpers';

export const useLegalClientSaveActivityTypes = () => {
  const [handle, { data, loading, error: apolloError }] =
    useLegalClientSaveActivityTypesMutation();

  const error = useError(apolloError);

  return {
    data: data?.legalClientSaveActivityTypes
      ? parseLegalClient(data.legalClientSaveActivityTypes)
      : undefined,
    loading,
    error,
    saveActivityTypes: useCallback(
      async (id: string, params: LegalClientSaveActivityTypesParam) => {
        const res = await handle({
          variables: {
            id,
            input: params,
          },
        });
        return res?.data?.legalClientSaveActivityTypes
          ? parseLegalClient(res.data.legalClientSaveActivityTypes)
          : undefined;
      },
      [handle]
    ),
  };
};
