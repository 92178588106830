import { CompanyContacts } from '../components';
import { useCompanyContacts } from '../hooks';
import { PageComponentProps } from '../types';

export const CompanyContactsStep = ({ canGoBack }: PageComponentProps) => {
  const { initialValues, onSubmit, submitLoading, goToBack } =
    useCompanyContacts();

  return (
    <CompanyContacts
      canGoBack={canGoBack}
      initialValues={initialValues}
      submitLoading={submitLoading}
      onGoBack={goToBack}
      onSubmit={onSubmit}
    />
  );
};
