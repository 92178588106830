import {
  TypedForm,
  FormErrors as FormErrorsBase,
  ObjectSchema,
  string,
  object,
} from 'libs/form';

export interface CompanyStructureFormAuthoritieType {
  id?: string;
  type: string;
  name: string;
}

export interface CompanyStructureFormType {
  id?: string;
  type: string;
  name: string;
}

export const MAX_AUTHORITIES = 10;

export const getSchema = (): ObjectSchema<CompanyStructureFormType> =>
  object({
    id: string(),
    type: string().max(2000).required(),
    name: string().max(2000).required(),
  });

export const emptySchema = object({});

export const { Field, Form, useFormContext, Submit } =
  TypedForm<CompanyStructureFormType>();

export type FormErrors = FormErrorsBase<CompanyStructureFormType>;
