import { FormLayout } from 'components';
import { Stack } from 'libs/ui';
import { CSSObject, styled } from 'libs/ui/styled';

export const FormLayoutStyled = styled(FormLayout)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,

  [theme.breakpoints.down('mobile')]: {
    backgroundColor:
      theme.palette.mode === 'light'
        ? theme.palette.background.default
        : theme.palette.body,
  } as CSSObject,

  '.registrationSelectType-content': {
    [theme.breakpoints.down('mobile')]: {
      marginTop: 32,
    } as CSSObject,
  },
}));

export const ButtonsStyled = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'center',
  gap: 32,

  [theme.breakpoints.down('tablet')]: {
    flexDirection: 'column',
    gap: 16,
  },
}));
