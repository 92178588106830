import { FormContainer } from 'components';
import { useTranslation } from 'libs/i18n';

import {
  FormErrors,
  getSchema,
  LegalOrderSubmissionContactsFormType,
} from '../../forms/orderSubmissionContacts';

import { FormContent } from './FormContent';

interface Props {
  initialValues?: LegalOrderSubmissionContactsFormType;
  onSubmit: (values: LegalOrderSubmissionContactsFormType) => void;
  canGoBack?: boolean;
  onGoBack?: () => void;
  formError?: FormErrors;
  submitLoading?: boolean;
}

export const OrderSubmissionContacts = ({
  initialValues,
  canGoBack,
  onGoBack,
  submitLoading,
  formError,
  onSubmit,
  ...formProps
}: Props) => {
  const { t } = useTranslation();
  const schema = getSchema(t);
  return (
    <FormContainer
      canGoBack={canGoBack}
      initialErrors={formError}
      initialValues={initialValues}
      submitLoading={submitLoading}
      subTitle={t('onboarding.orderSubmissionContacts.subtitle')}
      title={t('onboarding.orderSubmissionContacts.title')}
      validationSchema={schema}
      onGoBack={onGoBack}
      onSubmit={onSubmit}
    >
      <FormContent {...formProps} />
    </FormContainer>
  );
};
