import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useLegalClientBranchSaveAddressesMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { LegalClientSaveRegistrationAddressesParam } from 'api/types/params';

import { parseLegalClientBranchEntity } from './helpers';

export const useLegalClientBranchSaveAddresses = () => {
  const [handle, { data, loading, error: apolloError }] =
    useLegalClientBranchSaveAddressesMutation();

  const error = useError(apolloError);

  return {
    data: data?.legalClientBranchSaveAddresses
      ? parseLegalClientBranchEntity(data.legalClientBranchSaveAddresses)
      : undefined,
    loading,
    error,
    saveAddresses: useCallback(
      async (
        id: string,
        branchId: string,
        params: LegalClientSaveRegistrationAddressesParam
      ) => {
        const res = await handle({
          variables: {
            id,
            branchId,
            input: params,
          },
        });
        return res?.data?.legalClientBranchSaveAddresses
          ? parseLegalClientBranchEntity(
              res.data.legalClientBranchSaveAddresses
            )
          : undefined;
      },
      [handle]
    ),
  };
};
