import { useCallback, useEffect } from 'react';

import {
  useRestorePasswordConfirmCode,
  useSendRestorePasswordCode,
} from 'api/requests';
import { useObjectState } from 'hooks';
import { useTranslation } from 'libs/i18n';

import { ConfirmEmailFormType, FormErrors } from '../forms/confirmEmailForm';
import { useRestorePasswordContext } from '../providers';

export const useConfirmEmail = () => {
  const { changeData } = useRestorePasswordContext();

  const {
    sendCode,
    data,
    loading: sendLoading,
    error: sendCodeError,
  } = useSendRestorePasswordCode();

  const {
    confirmCode,
    error: codeError,
    loading: submitLoading,
  } = useRestorePasswordConfirmCode();

  const { t } = useTranslation();

  const onConfirm = useCallback(
    async (values: ConfirmEmailFormType) => {
      const res = await confirmCode(values.email, values.code);
      if (res) {
        changeData({
          email: values.email,
          otpRequired: res.otpRequired,
          token: res.token,
        });
      }
    },
    [changeData, confirmCode]
  );

  const onSendCode = useCallback(
    async (email: string) => sendCode(email),
    [sendCode]
  );

  const [formError, setFormError] = useObjectState<FormErrors>({});

  useEffect(() => {
    if (codeError) {
      setFormError({ code: t('common.incorrectCode') });
    } else {
      setFormError({ code: undefined });
    }
  }, [codeError, setFormError, t]);

  useEffect(() => {
    if (sendCodeError) {
      setFormError({ email: sendCodeError.message });
    } else {
      setFormError({ email: undefined });
    }
  }, [sendCodeError, setFormError]);

  return {
    onConfirm,
    onSendCode,
    codeError,
    formError,
    resendTime: data.resendTime,
    sendLoading,
    submitLoading,
  };
};
