import { LegalClientOrderSubmissionContactEntity } from 'api/types/entity';
import { TFunc } from 'libs/i18n';
import {
  getTypes,
  initialOrderSubmissionContactType,
} from 'modules/onboarding/common/forms/orderSubmissionContacts';

import { LegalOrderSubmissionContactsFormType } from '../../forms/orderSubmissionContacts';

export const getInitialData = (
  t: TFunc,
  orderSubmissionContacts?: LegalClientOrderSubmissionContactEntity[]
): LegalOrderSubmissionContactsFormType =>
  orderSubmissionContacts
    ? {
        orderSubmissionContacts: orderSubmissionContacts.map(
          ({ type, value, firstName, lastName, position, middleName }) => {
            const formContactType = getTypes(t).find(
              ({ value }) => type === value
            );

            if (!formContactType) {
              throw new Error(`Unknown contact type: ${type}`);
            }

            return {
              type: formContactType,
              value,
              firstName,
              lastName,
              position,
              middleName,
            };
          }
        ),
      }
    : {
        orderSubmissionContacts: [
          {
            type: initialOrderSubmissionContactType,
            value: '',
            firstName: '',
            lastName: '',
            position: '',
          },
        ],
      };
