import { Stack } from 'libs/ui';

import { Item, ItemType } from './Item/Item';

export interface ItemProps {
  items: ItemType[];
  deleteLoading?: boolean;
  errorIds?: string[];
  errorText: string;
  onEdit: (id: string) => void;
  onDelete: (id: string) => void;
}

export const ItemsList = ({
  items,
  deleteLoading,
  errorIds,
  errorText,
  onEdit,
  onDelete,
}: ItemProps) => (
  <Stack datax="itemslist-container" spacing={32}>
    {items.map((item) => (
      <Item
        key={item.id}
        deleteLoading={deleteLoading}
        error={errorIds?.includes(item.id)}
        errorText={errorText}
        item={item}
        onDelete={onDelete}
        onEdit={onEdit}
      />
    ))}
  </Stack>
);
