import {
  TypedForm,
  FormErrors as FormErrorsBase,
  ObjectSchema,
  string,
  object,
  array,
} from 'libs/form';

export interface CompanyConfirmStructureFormType {
  fileIds?: string[];
}

export const MAX_FILES = 10;

export const getSchema = (): ObjectSchema<CompanyConfirmStructureFormType> =>
  object({
    fileIds: array().max(MAX_FILES).of(string().required()).required().min(2),
  });

export const { Field, Form, useFormContext, Submit } =
  TypedForm<CompanyConfirmStructureFormType>();

export type FormErrors = FormErrorsBase<CompanyConfirmStructureFormType>;
