import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useLegalClientSaveLicenseInfoMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { LegalClientSaveLicenseInfoParam } from 'api/types/params';

import { parseLegalClient } from './helpers';

export const useLegalClientSaveLicenseInfo = () => {
  const [saveLicense, { data, loading, error: apolloError }] =
    useLegalClientSaveLicenseInfoMutation();

  const error = useError(apolloError);

  return {
    data: data?.legalClientSaveLicenseInfo
      ? parseLegalClient(data.legalClientSaveLicenseInfo)
      : undefined,
    loading,
    error,
    saveLicense: useCallback(
      async (id: string, params: LegalClientSaveLicenseInfoParam) => {
        const res = await saveLicense({
          variables: { id, input: params },
        });
        return res.data?.legalClientSaveLicenseInfo
          ? parseLegalClient(res.data.legalClientSaveLicenseInfo)
          : undefined;
      },
      [saveLicense]
    ),
  };
};
