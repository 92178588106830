import { DatePicker, DatePickerProps } from 'libs/ui';

import { FieldType } from '../types';
import { useField } from '../useField';

export interface DatePickerFieldProps
  extends FieldType,
    Omit<DatePickerProps, 'name' | 'onChange' | 'helperText' | 'value'> {}

/**
 * DatePicker Field
 */
export const DatePickerField = ({
  name,
  disabled,
  label,
  allowSubmit,
  placeholder,
  ...datePickerProps
}: DatePickerFieldProps) => {
  const { field, helpers, inputProps } = useField<Date | undefined | null>(
    name,
    { allowSubmit }
  );
  return (
    <DatePicker
      disabled={disabled}
      label={label}
      name={name}
      placeholder={placeholder}
      value={field.value ?? undefined}
      onChange={(v) => {
        helpers.setError('');
        helpers.setValue(v);
      }}
      {...datePickerProps}
      {...inputProps}
    />
  );
};
