// eslint-disable-next-line no-restricted-imports
import { useOnboardingClientIdentityQuery } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { ClientIdentity, ClientIdentityType } from 'api/types/data';

export const useOnboardingClientIdentity = () => {
  const {
    data,
    loading,
    error: apolloError,
  } = useOnboardingClientIdentityQuery();

  const error = useError(apolloError);

  const clientIdentity: ClientIdentity | undefined =
    data?.onboardingClientIdentity
      ? {
          id: data.onboardingClientIdentity.id,
          type: ClientIdentityType[data.onboardingClientIdentity.type],
        }
      : undefined;

  return {
    clientIdentity,
    loading,
    error,
  };
};
