import { useEffect, useMemo, useRef } from 'react';

import { base64 } from 'helpers';
import { useNavigate } from 'libs/navigation';

import { RegistrationType, RegistrationUrlData } from '../types';

const URL_PARAM_NAME = 'query';

interface UrlData {
  email: string;
  code: string;
  type: 'Client' | 'LegalClientRepresentative';
  agreementIds: string[];
}

export const useRegistrationUrlData = () => {
  const { searchParams, setSearchParams } = useNavigate();

  const query = useRef(searchParams.get(URL_PARAM_NAME));

  useEffect(() => {
    searchParams.delete(URL_PARAM_NAME);
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  return useMemo<RegistrationUrlData | undefined>(() => {
    if (query.current) {
      try {
        const data = JSON.parse(base64.decode(query.current)) as UrlData;
        if (
          !data.email ||
          !data.code ||
          !data.type ||
          !data.agreementIds.length
        ) {
          return undefined;
        }
        return {
          email: data.email,
          code: data.code.toString(),
          type:
            data.type === 'Client'
              ? RegistrationType.personal
              : RegistrationType.legal,
          agreementIds: data.agreementIds,
        };
      } catch (error) {
        return undefined;
      }
    }

    return undefined;
  }, []);
};
