// eslint-disable-next-line no-restricted-imports
import { RegistrationPlaceFragment } from 'api/generated/graphql';
import { RegistrationPlaceEntity } from 'api/types/entity';

import { parseCountryEntity } from '../../helpers';

export const parseRegistrationPlaceEntity = (
  registrationPlace: RegistrationPlaceFragment
): RegistrationPlaceEntity => ({
  country: parseCountryEntity(registrationPlace.country),
  locality: registrationPlace.locality ?? undefined,
  specialRegistrationArea:
    registrationPlace.specialRegistrationArea ?? undefined,
});
