// eslint-disable-next-line no-restricted-imports
import { ApolloError } from '@apollo/client';
import { ServerError } from 'api/types/error';

type ResultError = Error & {
  result: { errors: { extensions: { responseJson: ServerError } }[] };
};

/** Handle apollo error */
export const parseServerError = (error?: unknown) => {
  if (error instanceof ApolloError) {
    if (error.networkError?.name === 'ServerError') {
      const networkError = error.networkError as ResultError;

      const serverError =
        networkError.result.errors[0]?.extensions?.responseJson;

      if (serverError?.localizedDescription) {
        serverError.message = serverError.localizedDescription;
        return serverError;
      }
    }

    if (error.graphQLErrors) {
      const graphQLErrors = error.graphQLErrors[0]?.extensions as {
        responseJson: ServerError;
      };

      const status = graphQLErrors?.responseJson?.status;
      if (status === 401) {
        return undefined;
      }
    }

    return false;
  }

  return undefined;
};
