import { OccupationType } from 'api/types/entity';
import { BiographyParam } from 'api/types/params/onboarding/biographyParam';

import { OnboardingBiographyFormType } from '../../forms/onboardingBiographyForm';

export const getSubmitData = (values: OnboardingBiographyFormType) => {
  const isCompany =
    values.occupation?.value === OccupationType.businessOwner ||
    values.occupation?.value === OccupationType.employee;

  const isEntrepreneur =
    values.occupation?.value === OccupationType.entrepreneur;

  let fields = {};

  if (isCompany) {
    fields = {
      companyName: values.companyName,
      position: values.position,
      workAddress: values.workAddress,
    };
  } else if (isEntrepreneur) {
    fields = {
      entrepreneurName: values.entrepreneurName,
      registrationNumber: values.registrationNumber,
      registrationDate: values.registrationDate,
      registrationPlace: values.registrationPlace,
      typeOfServices: values.typeOfServices,
    };
  }

  const result: BiographyParam = {
    ...fields,

    maritalStatus: values.maritalStatus!.value,
    occupation: values.occupation!.value,
  };

  return result;
};
