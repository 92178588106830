import { OnboardingProcessStepNameType } from 'api/types/entity';

import {
  RegistrationAddressStep,
  TaxResidencyStep,
  NameStep,
  StateRegistrationStep,
  ContactsStep,
} from './steps';
import { RoutePage } from './types';

export const pagesMap: Partial<
  Record<OnboardingProcessStepNameType, RoutePage['component']>
> = {
  [OnboardingProcessStepNameType.LegalClientBranchName]: NameStep,
  [OnboardingProcessStepNameType.LegalClientBranchTaxResidencies]:
    TaxResidencyStep,
  [OnboardingProcessStepNameType.LegalClientBranchStateRegistration]:
    StateRegistrationStep,
  [OnboardingProcessStepNameType.LegalClientBranchAddresses]:
    RegistrationAddressStep,
  [OnboardingProcessStepNameType.LegalClientBranchContacts]: ContactsStep,
};
