import { ContentStepperComponentProps } from 'components';

import { ConfirmEmail } from '../components';
import { ConfirmEmailFormType } from '../forms/confirmEmailForm';
import { useConfirmEmail } from '../hooks';

export const ConfirmEmailStep = ({
  canGoBack,
  onGoBack,
  onGoNext,
}: ContentStepperComponentProps) => {
  const { formError, onConfirm, onSendCode, resendTime, submitLoading } =
    useConfirmEmail();

  const onSubmit = async (values: ConfirmEmailFormType) => {
    await onConfirm(values);
    onGoNext();
  };

  return (
    <ConfirmEmail
      canGoBack={canGoBack}
      initialErrors={formError}
      resendTime={resendTime}
      submitLoading={submitLoading}
      onGoBack={onGoBack}
      onSendEmailCode={onSendCode}
      onSubmit={onSubmit}
    />
  );
};
