import { useTranslation } from 'libs/i18n';
import { ClientType, PersonalData } from 'modules/onboarding/common/components';

import { useOwnerPersonalData } from '../hooks';
import { PageComponentProps } from '../types';

export const OwnerPersonalDataStep = ({ canGoBack }: PageComponentProps) => {
  const { goToBack, onSubmit, submitLoading, formError } =
    useOwnerPersonalData();

  const { t } = useTranslation();

  return (
    <PersonalData
      canGoBack={canGoBack}
      clientType={ClientType.Client}
      formError={formError}
      submitLoading={submitLoading}
      subTitle={t('onboarding.ownerPersonalData.subtitle')}
      onGoBack={goToBack}
      onSubmit={onSubmit}
    />
  );
};
