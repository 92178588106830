import { useMemo, useState } from 'react';

import { useLegalClientLegalHeadFirstHeadSavePersonalData } from 'api/requests/onboarding/legal/legalHeadFirstHead';
import { ErrorCode, ServerError } from 'api/types/error';
import { useNotify } from 'libs/notify';
import {
  PersonalDataFormType,
  FormErrors,
} from 'modules/onboarding/common/forms/personalDataForm';

import { useOnboardingContext } from './useOnboardingContext';

export const usePersonalData = () => {
  const { goToBack, data, goToNext } = useOnboardingContext();
  const notify = useNotify();

  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitError, setSubmitError] = useState<ServerError>();

  const {
    savePersonalData,
    loading: submitPersonalDataLoading,
    error: errorPersonalData,
  } = useLegalClientLegalHeadFirstHeadSavePersonalData();

  const initialValues = useMemo<PersonalDataFormType | undefined>(
    () =>
      data.firstHead
        ? {
            email: data.firstHead.email,
            firstName: data.firstHead.firstName,
            lastName: data.firstHead.lastName,
            dateOfBirth: data.firstHead.dateOfBirth,
            middleName: data.firstHead.middleName,
          }
        : undefined,
    [data.firstHead]
  );

  const onSubmit = async (values: PersonalDataFormType) => {
    if (values) {
      if (!data.firstHead) {
        setSubmitLoading(true);
        setSubmitError(undefined);

        try {
          if (await data.onCreateFirstHead(values)) {
            goToNext();
          }
        } catch (error) {
          setSubmitError(error as ServerError);
        } finally {
          setSubmitLoading(false);
        }
      } else {
        const res = await savePersonalData(
          data.legalClient.id,
          data.legalHeadId,
          data.firstHead.id,
          values
        );

        if (res) {
          goToNext();
        }
      }
    }
  };

  const formError = useMemo((): FormErrors | undefined => {
    if (submitError?.code === ErrorCode.USER_WITH_EMAIL_ALREADY_EXISTS) {
      return { email: submitError?.message };
    }

    if (errorPersonalData?.code === ErrorCode.USER_WITH_EMAIL_ALREADY_EXISTS) {
      return { email: errorPersonalData?.message };
    }

    if (submitError || errorPersonalData) {
      notify.error(submitError ?? errorPersonalData);
    }

    return undefined;
  }, [submitError, errorPersonalData, notify]);

  return {
    onSubmit,
    goToBack,
    initialValues,
    formError,
    submitLoading: submitLoading || submitPersonalDataLoading,
  };
};
