import { LoadingView } from 'components';
import { InvoiceConfirmForPayment } from 'modules/onboarding/common/components';

import { useInvoiceConfirmForPayment } from '../hooks';
import { PageComponentProps } from '../types';

export const InvoiceConfirmForPaymentStep = ({
  canGoBack,
}: PageComponentProps) => {
  const {
    initialValues,
    submitLoading,
    files,
    onAddFiles,
    onRemoveFile,
    submitDisabled,
    goToBack,
    onSubmit,
    invoiceLink,
  } = useInvoiceConfirmForPayment();

  if (!invoiceLink) {
    return <LoadingView />;
  }

  return (
    <InvoiceConfirmForPayment
      canGoBack={canGoBack}
      files={files}
      initialValues={initialValues}
      invoiceLink={invoiceLink}
      submitDisabled={submitDisabled}
      submitLoading={submitLoading}
      onAddFiles={onAddFiles}
      onGoBack={goToBack}
      onRemoveFile={onRemoveFile}
      onSubmit={onSubmit}
    />
  );
};
