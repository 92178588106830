import { RegistrationEmail } from '../components/RegistrationEmail';
import { useRegistrationContext } from '../components/RegistrationProvider';
import { RegistrationEmailFormType } from '../forms/registrationEmailForm';
import { useRegistrationEmail } from '../hooks';
import { RegistrationStepProps } from '../types';

export const RegistrationEmailStep = ({
  canGoBack,
  onGoBack,
  onGoNext,
}: RegistrationStepProps) => {
  const { changeContextData } = useRegistrationContext();
  const {
    onSubmitEmail,
    onSendEmailCode,
    emailFormError,
    resendTime,
    submitLoading,
  } = useRegistrationEmail();

  const onSubmit = async (values: RegistrationEmailFormType) => {
    if (await onSubmitEmail(values)) {
      changeContextData(values);
      onGoNext();
    }
  };

  return (
    <RegistrationEmail
      canGoBack={canGoBack}
      emailFormError={emailFormError}
      resendTime={resendTime}
      submitLoading={submitLoading}
      onGoBack={onGoBack}
      onSendEmailCode={onSendEmailCode}
      onSubmit={onSubmit}
    />
  );
};
