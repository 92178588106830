import { useMemo } from 'react';

import { useTranslation } from 'libs/i18n';

import {
  OnboardingBiographyFormType,
  FormErrors,
  getSchema,
} from '../../forms/onboardingBiographyForm';
import { ClientType, FormBodyWrapper } from '../FormBodyWrapper';

import { FormContent } from './FormContent';

interface Props {
  initialValues?: OnboardingBiographyFormType;
  onSubmit: (values: OnboardingBiographyFormType) => void;
  canGoBack?: boolean;
  onGoBack?: () => void;
  formError?: FormErrors;
  submitLoading?: boolean;
  clientType?: ClientType;
}

export const OnboardingBiography = ({
  initialValues,
  canGoBack,
  submitLoading,
  formError,
  clientType,
  onGoBack,
  onSubmit,
}: Props) => {
  const { t } = useTranslation();
  const schema = useMemo(() => getSchema(t), [t]);

  return (
    <FormBodyWrapper
      canGoBack={canGoBack}
      clientType={clientType}
      initialErrors={formError}
      initialValues={initialValues}
      submitLoading={submitLoading}
      subTitle={t('onboarding.biography.subtitle')}
      title={t('onboarding.biography.title')}
      validationSchema={schema}
      onGoBack={onGoBack}
      onSubmit={onSubmit}
    >
      <FormContent />
    </FormBodyWrapper>
  );
};
