import { DefaultNamespace, ParseKeys, TFunction } from 'i18next';
import { LocalStorage } from 'libs/storage';

import ru from './locales/ru.json';

/**
 * Enum representing language codes for different languages.
 */
export enum Langs {
  RU = 'ru',
}

export type TranslationPath = ParseKeys<DefaultNamespace, typeof ru, undefined>;

export type TFunc = TFunction<DefaultNamespace, undefined>;

export const I18N_KEY = LocalStorage.keys.i18nextLng;
