// eslint-disable-next-line no-restricted-imports
import { OnboardingLegalClientRepresentativeFragment } from 'api/generated/graphql';
import { LegalClientRepresentativeCompanyEntity } from 'api/types/entity/legalClientRepresentativeEntity/legalClientRepresentativeCompanyEntity';
import { lodash } from 'helpers';

import { parseLegalClientConstitutionalDocument } from '../../legal/helpers/parseConstitutionalDocument';
import { parseLegalClientStateRegistration } from '../../legal/helpers/parseLegalClientStateRegistration';
import { parseLegalClientTaxResidency } from '../../legal/helpers/parseLegalClientTaxResidency';

export const parseCompanyEntity = (
  data: OnboardingLegalClientRepresentativeFragment
): LegalClientRepresentativeCompanyEntity | undefined =>
  data.company
    ? {
        fullName: data.company.fullName ?? undefined,
        shortName: data.company.shortName ?? undefined,
        legalAddress: data.company.legalAddress,
        actualAddress: data.company.actualAddress,

        taxResidences: data.company.taxResidences
          ? lodash
              .compact(data.company.taxResidences)
              .map(parseLegalClientTaxResidency)
          : undefined,
        stateRegistration: parseLegalClientStateRegistration(data.company),
        constitutionalDocument: parseLegalClientConstitutionalDocument(
          data.company.constitutionalDocument
        ),
      }
    : undefined;
