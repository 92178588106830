import { useIsMobileSize } from 'hooks';
import { Stack, Button } from 'libs/ui';
import { Icon } from 'libs/ui/Icon';

import { DATAX_ADD_ITEM, DATAX_DELETE_ITEM } from '../../constants';

interface Props {
  index: number;
  onAdd: () => void;
  onRemove: (index: number) => void;
  maxCount?: number;
  counts: number;
  removeLabel: string;
  addLabel: string;
}

export const AddAndRemoveButtons = ({
  counts,
  index,
  onAdd,
  onRemove,
  removeLabel,
  addLabel,
  maxCount = 10,
}: Props) => {
  const isHideButtons = counts > 1 && index === 0;
  const isShowAddButton = counts < maxCount && index === counts - 1;
  const isShowRemoveButton = index > 0;

  const isMobile = useIsMobileSize();

  if (isHideButtons) {
    return null;
  }

  return (
    <Stack
      direction={isMobile ? 'column' : 'row'}
      justifyContent="space-between"
      spacing={16}
    >
      {isShowAddButton && (
        <Button
          fullWidth
          datax={DATAX_ADD_ITEM}
          label={addLabel}
          mode="medium"
          startIcon={<Icon.Plus />}
          onClick={onAdd}
        />
      )}
      {isShowRemoveButton && (
        <Button
          fullWidth
          colorVariant="red"
          datax={DATAX_DELETE_ITEM}
          label={removeLabel}
          mode="medium"
          startIcon={<Icon.Minus />}
          onClick={() => onRemove(index)}
        />
      )}
    </Stack>
  );
};
