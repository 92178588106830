import { useState } from 'react';

import {
  useLegalClientDeleteLegalHead,
  useLegalClientHeadDeleteHead,
  useLegalClientSaveHeadsStep,
} from 'api/requests';
import { LegalClientInnerItemEntity } from 'api/types/entity/legal/legalClientInnerItemEntity';
import { useTranslation } from 'libs/i18n';
import { useNotify } from 'libs/notify';

import { HeadPageType } from './useOnboarding/type';
import { useOnboardingContext } from './useOnboardingContext';

const checkFullnessHead = (h: LegalClientInnerItemEntity) =>
  !!h.isCompletedByRepresentative;

export const useHeads = () => {
  const notify = useNotify();
  const { t } = useTranslation();

  const [errorHeadsIds, setErrorHeadsIds] = useState<string[]>();
  const { goToBack, goToNext, data } = useOnboardingContext();

  const { deleteHead, loading: deleteHeadLoading } =
    useLegalClientHeadDeleteHead();

  const { deleteLegalHead, loading: deleteLegalHeadLoading } =
    useLegalClientDeleteLegalHead();

  const { saveHeadsStep, loading: saveHeadStepLoading } =
    useLegalClientSaveHeadsStep();

  const heads = data.legalClient?.heads ?? [];
  const legalHeads = data.legalClient?.legalHeads ?? [];

  const onSubmit = async () => {
    if (!heads?.length && !legalHeads?.length) {
      notify.error(Error(t('onboarding.legalClientHeads.emptyErrorMessage')));
      return;
    }

    const notFulledHead = heads.filter((b) => !checkFullnessHead(b));

    const notFulledLegalHead = legalHeads.filter((b) => !checkFullnessHead(b));

    if (notFulledHead.length || notFulledLegalHead.length) {
      const errors = notFulledHead
        .map((b) => b.id)
        .concat(notFulledLegalHead.map((b) => b.id));

      setErrorHeadsIds(errors);

      return;
    }

    await saveHeadsStep(data.legalClient?.id!);

    goToNext();
  };

  const onAdd = (type: HeadPageType.legal | HeadPageType.person) => {
    data.innerState.setHead(type);
  };

  const onEdit = (id: string) => {
    if (id) {
      const type = heads.find((head) => id === head.id)
        ? HeadPageType.person
        : HeadPageType.legal;

      data.innerState.setHead(type, id);
    }
  };

  const onDelete = (id: string) => {
    if (id) {
      if (heads.find((head) => id === head.id)) {
        deleteHead(data.legalClient?.id!, id);
      } else {
        deleteLegalHead(data.legalClient?.id!, id);
      }
    }
  };

  return {
    onSubmit,
    goToBack,
    onAdd,
    onEdit,
    onDelete,
    errorHeadsIds,
    deleteLoading: deleteLegalHeadLoading || deleteHeadLoading,
    heads,
    legalHeads,
    submitLoading: saveHeadStepLoading,
  };
};
