import { OnboardingCitizenshipFormType } from 'modules/onboarding/common/forms/onboardingCitizenshipForm';
import { useClientCitizenship } from 'modules/onboarding/common/hooks';

import { useOnboardingContext } from './useOnboardingContext';

export const useOwnerCitizenship = () => {
  const { goToBack, goToNext, data } = useOnboardingContext();

  const {
    countries,
    countriesLoading,
    initialValues,
    onSubmit,
    submitLoading,
  } = useClientCitizenship(data.ownerClient!);

  const handleSubmit = async (values: OnboardingCitizenshipFormType) => {
    if (values.citizenships) {
      await onSubmit(values);
      goToNext();
    }
  };

  return {
    onSubmit: handleSubmit,
    goToBack,
    submitLoading,
    initialValues,
    countries,
    countriesLoading,
  };
};
