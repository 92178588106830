import { FC, useMemo } from 'react';

import { TestAttributeProps } from 'types/testAttribute';

import { cx } from '../theme';

import { Container } from './Switch.styles';
import { SwitchProps } from './types';

/**
 * Switch Component
 */
export const Switch: FC<SwitchProps & TestAttributeProps> = ({
  checked,
  disabled,
  size = 'small',
  thumb,
  thumbChecked,
  className,
  datax,
  onChange,
}) => {
  const handlerChange = () => {
    if (!disabled) {
      onChange?.(!checked);
    }
  };
  const containerClassName = useMemo(
    () => cx('switch', `switch-${size}`, { checked, disabled }, className),
    [checked, disabled, size, className]
  );

  const customThumb = useMemo(
    () => (checked ? thumbChecked || thumb : thumb),
    [checked, thumb, thumbChecked]
  );

  return (
    <Container
      className={containerClassName}
      datax={datax || 'switch'}
      role="button"
      size={size}
      onClick={handlerChange}
    >
      <div className="switch-thumbContainer">
        {customThumb ? (
          <div className="switch-customThumb">{customThumb}</div>
        ) : (
          <div className="switch-thumb" />
        )}
      </div>
    </Container>
  );
};
