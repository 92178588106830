import { FileUpload } from 'hooks';
import { useTranslation } from 'libs/i18n';
import { AddAndRemoveButtons } from 'modules/onboarding/common/components/AddAndRemoveButtons';
import { useFormContext } from 'modules/onboarding/common/forms/withdrawalAccountsForm';

interface Props {
  index: number;
  files?: FileUpload[];
  onRemoveFile?: (file: FileUpload) => void;
}

export const Buttons = ({ index, files, onRemoveFile }: Props) => {
  const { values, setValues } = useFormContext();

  const { t } = useTranslation();

  const onAdd = () => {
    values.withdrawalAccounts.push({
      bankCountry: null,
      swiftCode: '',
      bankAddress: '',
      bankName: '',
      checkingAccount: '',
      correspondentAccount: '',
      ownerConfirmationFileIds: [],
      currency: null,
    });
    setValues(values, false);
  };

  const onRemove = (index: number) => {
    values.withdrawalAccounts.splice(index, 1);
    setValues(values, false);
    const file = files?.find((f) => f.data === index);
    if (file) {
      onRemoveFile?.(file);
    }
  };

  return (
    <AddAndRemoveButtons
      addLabel={t('onboarding.withdrawalAccounts.addButton')}
      counts={values.withdrawalAccounts.length}
      index={index}
      removeLabel={t('onboarding.withdrawalAccounts.removeButton')}
      onAdd={onAdd}
      onRemove={onRemove}
    />
  );
};
