import { useCallback, useLayoutEffect, useRef, useState } from 'react';

import {
  ScenarioName,
  useOnboardingGetProcessByScenarioName,
} from 'api/requests';
import {
  useLegalClientLegalHeadFirstHeadSettingsSave,
  useLegalClientGetLegalHeadFirstHeadLazy,
  useLegalClientCompleteLegalHeadFirstHeadOnboarding,
  useLegalClientCreateLegalHeadFirstHead,
} from 'api/requests/onboarding/legal/legalHeadFirstHead';
import {
  LegalClientEntity,
  LegalClientRepresentativeEntity,
} from 'api/types/entity';
import { PersonalDataFormType } from 'modules/onboarding/common/forms/personalDataForm';

export const useOnboarding = (
  currentClient: LegalClientRepresentativeEntity,
  legalClient: LegalClientEntity,
  legalHeadId: string,
  firstHeadId?: string
) => {
  const headId = useRef(firstHeadId);
  const [loading, setLoading] = useState(false);

  const { saveSettings } = useLegalClientLegalHeadFirstHeadSettingsSave();

  const { getFirstHead, firstHead } = useLegalClientGetLegalHeadFirstHeadLazy();

  const { complete } = useLegalClientCompleteLegalHeadFirstHeadOnboarding();

  const { createFirstHead } = useLegalClientCreateLegalHeadFirstHead();

  const { process, loading: processLoading } =
    useOnboardingGetProcessByScenarioName(
      ScenarioName.LegalClientLegalHeadFirstHead
    );

  const handleCreateFirstHead = async (personalData: PersonalDataFormType) => {
    const firstHead = await createFirstHead(
      legalClient.id,
      legalHeadId,
      personalData
    );
    if (firstHead) {
      await getFirstHead(legalClient.id, legalHeadId, firstHead.id);
    }
    return firstHead;
  };

  const onGoBack = useCallback(
    async (currentStepName: string) => {
      if (firstHeadId) {
        await saveSettings(
          legalClient.id,
          legalHeadId,
          firstHeadId,
          currentStepName
        );
      }
    },
    [firstHeadId, saveSettings, legalClient.id, legalHeadId]
  );

  const onEnd = useCallback(
    async () => complete(legalClient.id, legalHeadId, firstHeadId!),
    [complete, firstHeadId, legalClient.id, legalHeadId]
  );

  useLayoutEffect(() => {
    if (headId.current) {
      setLoading(true);
      getFirstHead(legalClient.id, legalHeadId, headId.current).finally(() =>
        setLoading(false)
      );
    }
  }, [getFirstHead, legalClient.id, legalHeadId]);

  return {
    currentClient,
    firstHead,
    legalClient,
    loading: loading || (processLoading && !process),
    process,
    onGoBack,
    onEnd,
    createFirstHead: handleCreateFirstHead,
  };
};
