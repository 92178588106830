import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useRestorePasswordConfirmCodeMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { RestorePasswordConfirmCode } from 'api/types/data/restorePasswordConfirmCode';

export const useRestorePasswordConfirmCode = () => {
  const [handle, { data, loading, error: apolloError }] =
    useRestorePasswordConfirmCodeMutation();

  const error = useError(apolloError);

  return {
    data: data?.restorePasswordConfirmCode
      ? (data?.restorePasswordConfirmCode as RestorePasswordConfirmCode)
      : undefined,
    loading,
    error,
    confirmCode: useCallback(
      async (email: string, code: string) => {
        const res = await handle({ variables: { input: { email, code } } });
        return res?.data?.restorePasswordConfirmCode
          ? (res?.data
              ?.restorePasswordConfirmCode as RestorePasswordConfirmCode)
          : undefined;
      },
      [handle]
    ),
  };
};
