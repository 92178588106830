import { useTranslation } from 'libs/i18n';
import { AddAndRemoveButtons } from 'modules/onboarding/common/components/AddAndRemoveButtons';

import { useFormContext } from '../../../../forms/companyLicenseForm';

interface Props {
  index: number;
  onRemoveFilesByServerIds?: (ids: string[]) => void;
}

export const Buttons = ({ index, onRemoveFilesByServerIds }: Props) => {
  const { values, setValues } = useFormContext();

  const { t } = useTranslation();

  const onAdd = () => {
    values.licenses.push({
      authorityName: '',
      dateOfExpiry: null,
      dateOfIssue: undefined,
      fileIds: [],
      licensedActivityType: '',
      licenseNumber: '',
    });
    setValues(values, false);
  };

  const onRemove = (index: number) => {
    onRemoveFilesByServerIds?.(values.licenses[index]?.fileIds ?? []);
    values.licenses.splice(index, 1);
    setValues(values, false);
  };

  return (
    <AddAndRemoveButtons
      addLabel={t('onboarding.companyLicense.addLicense')}
      counts={values.licenses.length}
      index={index}
      removeLabel={t('onboarding.companyLicense.removeLicense')}
      onAdd={onAdd}
      onRemove={onRemove}
    />
  );
};
