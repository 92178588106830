import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useLegalClientHeadSaveTaxResidencesMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { OnboardingTaxResidencesParam } from 'api/types/params';

import { parseLegalClientHeadEntity } from './helpers';

export const useLegalClientHeadSaveTaxResidences = () => {
  const [saveTaxResidences, { data, loading, error: apolloError }] =
    useLegalClientHeadSaveTaxResidencesMutation();

  const error = useError(apolloError);

  return {
    data: data?.legalClientHeadSaveTaxResidences
      ? parseLegalClientHeadEntity(data.legalClientHeadSaveTaxResidences)
      : undefined,
    loading,
    error,
    saveTaxResidences: useCallback(
      async (
        id: string,
        headId: string,
        params: OnboardingTaxResidencesParam
      ) => {
        const res = await saveTaxResidences({
          variables: { id, headId, input: params },
        });
        return res.data?.legalClientHeadSaveTaxResidences
          ? parseLegalClientHeadEntity(
              res.data.legalClientHeadSaveTaxResidences
            )
          : undefined;
      },
      [saveTaxResidences]
    ),
  };
};
