import {
  CivilLawSubjectType,
  RoleInCompanyType,
} from 'api/types/entity/legalClientRepresentativeEntity/roleInCompanyEntity';
import {
  TypedForm,
  FormErrors as FormErrorsBase,
  ObjectSchema,
  string,
  object,
  array,
  date,
  mixed,
} from 'libs/form';
import { TFunc } from 'libs/i18n';
import {
  getToday,
  getTodayWithResetHours,
} from 'modules/onboarding/common/helpers/getToday';
import { Maybe } from 'types/maybe';
import { SelectType } from 'types/selectType';

export const MAX_FILES = 10;

export interface RoleInCompanyFormType {
  role: Maybe<SelectType<RoleInCompanyType>>;
  civilLawSubject?: Maybe<SelectType<CivilLawSubjectType>>;
  number?: string;
  dateOfIssue: Date;
  dateOfExpiration: Date;
  fileIds: string[];
}

const isRepresentative = (v: SelectType<RoleInCompanyType>) =>
  v?.value === RoleInCompanyType.AuthorizedRepresentative;

export const getSchema = (t: TFunc): ObjectSchema<RoleInCompanyFormType> =>
  object({
    role: object({
      value: mixed<RoleInCompanyType>()
        .oneOf(Object.values(RoleInCompanyType))
        .required(),
      label: string().required(),
    })
      .default(null)
      .required(),

    civilLawSubject: object({
      value: mixed<CivilLawSubjectType>()
        .oneOf(Object.values(CivilLawSubjectType))
        .required(),
      label: string().required(),
    })
      .default(undefined)
      .when('role', {
        is: isRepresentative,
        then: (schema) => schema.default(null).required(),
      }),

    number: string().max(200),

    dateOfIssue: date().max(getToday(), t('validation.maxDate')).required(),

    dateOfExpiration: date()
      .min(getTodayWithResetHours(), t('validation.minDate'))
      .required(),
    fileIds: array().of(string().required()).required().min(1).max(MAX_FILES),
  });

export const { Field, Form, useFormContext, Submit } =
  TypedForm<RoleInCompanyFormType>();

export type FormErrors = FormErrorsBase<RoleInCompanyFormType>;
