// eslint-disable-next-line no-restricted-imports
import { OnboardingClientRepresentativeFragment } from 'api/generated/graphql';
import { ClientRepresentativeEntity } from 'api/types/entity';
import { lodash } from 'helpers';

import {
  parseBankAccounts,
  parseCountryEntity,
  parseFileEntity,
  parseKyc,
  parseOnboardingProcessEntity,
  parseResidentialAddressEntity,
  parseTaxResidenceEntity,
} from '../../helpers';
import { parseBiographyEntity } from '../../helpers/parseBiographyEntity';
import { parsePepConnectionEntity } from '../../helpers/parsePepConnectionEntity';

import { parseClientRepresentativeDebtsEntity } from './parseClientRepresentativeDebtsEntity';

export const parseClientRepresentative = (
  data: OnboardingClientRepresentativeFragment
): ClientRepresentativeEntity => ({
  id: data.id,
  userId: data.userId,
  clientId: data.clientId || undefined,
  onboarding: data.onboarding
    ? {
        process: parseOnboardingProcessEntity(data.onboarding?.process),
      }
    : undefined,
  citizenships: data.citizenships
    ? lodash.compact(data.citizenships).map(parseCountryEntity)
    : undefined,

  placeOfBirth: data.placeOfBirth,
  registrationAddress: data.registrationAddress,
  residentialAddress: parseResidentialAddressEntity(data.residentialAddress),
  powerOfAttorney: data.powerOfAttorney
    ? {
        dateOfExpiration: new Date(data.powerOfAttorney.dateOfExpiration),
        dateOfIssue: new Date(data.powerOfAttorney.dateOfIssue),
        files: lodash.compact(data.powerOfAttorney.files).map(parseFileEntity),
        documentNumber: data.powerOfAttorney.documentNumber ?? undefined,
      }
    : undefined,
  taxResidences: data.taxResidences
    ? lodash.compact(data.taxResidences).map(parseTaxResidenceEntity)
    : undefined,
  biography: data.biography ? parseBiographyEntity(data.biography) : undefined,
  pepConnection: parsePepConnectionEntity(data),
  bankAccounts: parseBankAccounts(data.bankAccounts),
  debts: parseClientRepresentativeDebtsEntity(data),
  kyc: parseKyc(data.kyc),
});
