import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useFileUploadMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';

export const useFileUpload = () => {
  const [handle, { data, loading, error: apolloError }] =
    useFileUploadMutation();

  const error = useError(apolloError);

  const upload = useCallback(
    async (file: File) => {
      const res = await handle({ variables: { input: { file } } });
      return res.data?.fileUpload?.id;
    },
    [handle]
  );

  return {
    data,
    loading,
    error,
    upload,
  };
};
