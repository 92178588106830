import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useLegalClientSaveOrganizationalAndLegalFormMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { SaveOrganizationalAndLegalFormParam } from 'api/types/params/onboarding/saveOrganizationalAndLegalFormParam';

import { parseLegalClient } from './helpers';
import { parseOrganizationalAndLegalFormParam } from './helpers/parseOrganizationalAndLegalFormParam';

export const useLegalClientSaveOrganizationalAndLegalForm = () => {
  const [handle, { data, loading, error: apolloError }] =
    useLegalClientSaveOrganizationalAndLegalFormMutation();

  const error = useError(apolloError);

  return {
    data: data?.legalClientSaveOrganizationalAndLegalForm
      ? parseLegalClient(data.legalClientSaveOrganizationalAndLegalForm)
      : undefined,
    loading,
    error,
    saveOrganizationalAndLegalForm: useCallback(
      async (id: string, params: SaveOrganizationalAndLegalFormParam) => {
        const res = await handle({
          variables: {
            id,
            input: parseOrganizationalAndLegalFormParam(params),
          },
        });
        return res?.data?.legalClientSaveOrganizationalAndLegalForm
          ? parseLegalClient(res.data.legalClientSaveOrganizationalAndLegalForm)
          : undefined;
      },
      [handle]
    ),
  };
};
