import { ItemsList } from '../ItemsList';

export interface UserType {
  id: string;
  type: string;
  name: string;
}
export interface UserProps {
  users: UserType[];
  deleteLoading?: boolean;
  errorIds?: string[];
  errorText: string;
  onEdit: (id: string) => void;
  onDelete: (id: string) => void;
}

export const UserList = ({
  users,
  deleteLoading,
  errorIds,
  errorText,
  onEdit,
  onDelete,
}: UserProps) => (
  <ItemsList
    deleteLoading={deleteLoading}
    errorIds={errorIds}
    errorText={errorText}
    items={users}
    onDelete={onDelete}
    onEdit={onEdit}
  />
);
