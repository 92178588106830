import { Hint } from 'components';
import { useTranslation } from 'libs/i18n';
import { Typography } from 'libs/ui';

import { HintContainer } from './styles';

export const SubTitle = () => {
  const { t } = useTranslation();

  return (
    <Typography datax="onboarding-subtitle">
      {t('onboarding.beneficiaries.subtitle')}{' '}
      <HintContainer>
        <Hint
          title={
            <Typography variant="caption">
              {t('onboarding.beneficiaries.hintText')}
            </Typography>
          }
        />
      </HintContainer>
    </Typography>
  );
};
