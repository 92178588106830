// eslint-disable-next-line no-restricted-imports
import { DebtsDto_Input } from 'api/generated/graphql';
import { DebtsEntity } from 'api/types/entity/clientEntity/debtsEntity';

import { parsePrice } from './parsePrice';

export const parseClientDebtsParams = (data: DebtsEntity): DebtsDto_Input => ({
  hasBankruptcyProceedings: data.hasBankruptcyProceedings,
  hasMarginTrades: data.hasMarginTrades,
  hasDelaysOrNonFulfillmentOfObligations:
    data.hasDelaysOrNonFulfillmentOfObligations,
  fees: data.fees ? parsePrice(data.fees) : undefined,
  finesForViolation: data.finesForViolation
    ? parsePrice(data.finesForViolation)
    : undefined,
  penalties: data.penalties ? parsePrice(data.penalties) : undefined,
  taxes: data.taxes ? parsePrice(data.taxes) : undefined,
});
