import { FormContainer } from 'components';
import { useTranslation } from 'libs/i18n';

import {
  CompanyBeneficiariesForm,
  getSchema,
} from '../../forms/companyBeneficiariesForm';

import { FormContent, FormContentProps } from './FormContent';
import { SubTitle } from './SubTitle';

interface CompanyBeneficiariesProps extends FormContentProps {
  canGoBack?: boolean;
  submitDisabled?: boolean;
  submitLoading?: boolean;
  initialValues: CompanyBeneficiariesForm;
  onGoBack?: () => void;
  onSubmit: (values: CompanyBeneficiariesForm) => void;
}

export const CompanyBeneficiaries = ({
  canGoBack,
  submitLoading,
  initialValues,
  submitDisabled,
  onGoBack,
  onSubmit,
  ...formProps
}: CompanyBeneficiariesProps) => {
  const { t } = useTranslation();

  return (
    <FormContainer
      canGoBack={canGoBack}
      initialValues={initialValues}
      submitDisabled={submitDisabled}
      submitLoading={submitLoading}
      subTitleContent={<SubTitle />}
      title={t('onboarding.beneficiaries.title')}
      validationSchema={getSchema()}
      onGoBack={onGoBack}
      onSubmit={onSubmit}
    >
      <FormContent {...formProps} />
    </FormContainer>
  );
};
