import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useLegalClientRepresentativeSavePepMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { SavePepConnectionParam } from 'api/types/params';

import { parsePepConnectionParam } from '../helpers';

import { parseLegalClientRepresentative } from './helpers';

export const useLegalClientRepresentativeSavePepConnection = () => {
  const [savePepConnection, { data, loading, error: apolloError }] =
    useLegalClientRepresentativeSavePepMutation();

  const error = useError(apolloError);

  return {
    data: data?.legalClientRepresentativeSavePep
      ? parseLegalClientRepresentative(data.legalClientRepresentativeSavePep)
      : undefined,
    loading,
    error,
    savePepConnection: useCallback(
      async (id: string, params: SavePepConnectionParam) => {
        const res = await savePepConnection({
          variables: { id, input: parsePepConnectionParam(params) },
        });
        return res.data?.legalClientRepresentativeSavePep
          ? parseLegalClientRepresentative(
              res.data.legalClientRepresentativeSavePep
            )
          : undefined;
      },
      [savePepConnection]
    ),
  };
};
