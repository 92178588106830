import { useMemo } from 'react';

import { FormContainer } from 'components';
import { useTranslation } from 'libs/i18n';

import {
  getSchema,
  Field,
  dateOfBirthMaxDate,
  dateOfBirthMinDate,
  RegistrationPersonalDataFormType,
} from '../../forms/registrationPersonalDataForm';

import { RepresentativeSwitch } from './RepresentativeSwitch';

interface RegistrationPersonalDataProps {
  initialValues?: Partial<RegistrationPersonalDataFormType>;
  onSubmit: (valie: RegistrationPersonalDataFormType) => void;
  canGoBack?: boolean;
  onGoBack?: () => void;
  shownRepresentativeSwitch?: boolean;
}

export const RegistrationPersonalData = ({
  initialValues,
  canGoBack,
  shownRepresentativeSwitch = true,
  onGoBack,
  onSubmit,
}: RegistrationPersonalDataProps) => {
  const { t } = useTranslation();

  const schema = useMemo(() => getSchema(t), [t]);

  return (
    <FormContainer
      canGoBack={canGoBack}
      initialValues={initialValues}
      subTitle={t('auth.registration.personalData.subtitle')}
      title={t('auth.registration.personalData.title')}
      validationSchema={schema}
      onGoBack={onGoBack}
      onSubmit={onSubmit}
    >
      {shownRepresentativeSwitch && <RepresentativeSwitch />}

      <Field.Text
        fullWidth
        label={t('auth.registration.personalData.firstName.label')}
        name="firstname"
        placeholder={t('auth.registration.personalData.firstname.placeholder')}
        trim="start"
      />
      <Field.Text
        fullWidth
        label={t('auth.registration.personalData.lastname.label')}
        name="lastname"
        placeholder={t('auth.registration.personalData.lastname.placeholder')}
        trim="start"
      />
      <Field.Text
        fullWidth
        showOptionalMark
        label={t('auth.registration.personalData.surname.label')}
        name="surname"
        placeholder={t('auth.registration.personalData.surname.placeholder')}
        trim="start"
      />
      <Field.DatePicker
        label={t('auth.registration.personalData.dateOfBirth.label')}
        maxDate={dateOfBirthMaxDate}
        minDate={dateOfBirthMinDate}
        name="dateOfBirth"
        placeholder={t(
          'auth.registration.personalData.dateOfBirth.placeholder'
        )}
      />
    </FormContainer>
  );
};
