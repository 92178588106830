import { FormContainer, FormContainerStepProps } from 'components';
import { parseFullName } from 'helpers';
import { useTranslation } from 'libs/i18n';
import { Button } from 'libs/ui';
import { Icon } from 'libs/ui/Icon';
import { UserList } from 'modules/onboarding/legal/components/UserList';

import { DATAX_ADD_ITEM } from '../../../../../common/constants';

interface FirstHeadsProps extends FormContainerStepProps {
  firstHeads: {
    id: string;
    firstName: string;
    lastName: string;
    middleName?: string;
  }[];
  canGoBack?: boolean;
  submitLoading?: boolean;
  deleteLoading?: boolean;
  errorFirstHeadsIds?: string[];
  onGoBack?: () => void;
  onAdd: () => void;
  onEdit: (id: string) => void;
  onDelete: (id: string) => void;
  onSubmit: () => void;
}

export const FirstHeads = ({
  canGoBack,
  submitLoading,
  firstHeads,
  deleteLoading,
  errorFirstHeadsIds,
  step,
  onGoBack,
  onEdit,
  onAdd,
  onDelete,
  onSubmit,
}: FirstHeadsProps) => {
  const { t } = useTranslation();
  return (
    <FormContainer
      canGoBack={canGoBack}
      step={step}
      submitLoading={submitLoading}
      subTitle={t('onboarding.legalClientLegalHead.firstHeads.subtitle')}
      title={t('onboarding.legalClientLegalHead.firstHeads.title')}
      onGoBack={onGoBack}
      onSubmit={onSubmit}
    >
      <UserList
        deleteLoading={deleteLoading}
        errorIds={errorFirstHeadsIds}
        errorText={t(
          'onboarding.legalClientLegalHead.firstHeads.filledErrorMessage'
        )}
        users={firstHeads.map((b) => ({
          id: b.id,
          name: parseFullName(b),
          type: t('onboarding.legalClientLegalHead.firstHeads.userType'),
        }))}
        onDelete={onDelete}
        onEdit={onEdit}
      />
      <Button
        fullWidth
        datax={DATAX_ADD_ITEM}
        label={t('onboarding.legalClientLegalHead.firstHeads.addButton')}
        mode="medium"
        startIcon={<Icon.Plus />}
        onClick={onAdd}
      />
    </FormContainer>
  );
};
