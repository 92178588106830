import { useMemo } from 'react';

import { EmailFormBody } from 'components';
import { useTranslation } from 'libs/i18n';

import {
  FormErrors,
  RegistrationEmailFormType,
  getSchema,
} from '../../forms/registrationEmailForm';

interface RegistrationEmailProps {
  initialValues?: RegistrationEmailFormType;
  onSendEmailCode: (email: string) => void;
  onSubmit: (value: RegistrationEmailFormType) => void;
  canGoBack?: boolean;
  onGoBack?: () => void;
  emailFormError: FormErrors;
  resendTime?: number;
  submitLoading?: boolean;
}

export const RegistrationEmail = ({
  initialValues,
  canGoBack,
  emailFormError,
  resendTime,
  submitLoading,
  onGoBack,
  onSendEmailCode,
  onSubmit,
}: RegistrationEmailProps) => {
  const { t } = useTranslation();
  const schema = useMemo(() => getSchema(t), [t]);

  return (
    <EmailFormBody
      canGoBack={canGoBack}
      codeFieldName="code"
      emailFieldName="email"
      initialErrors={emailFormError}
      initialValues={initialValues}
      resendTime={resendTime}
      submitLoading={submitLoading}
      subTitle={t('auth.registration.email.subtitle')}
      title={t('auth.registration.email.title')}
      validationSchema={schema}
      onGoBack={onGoBack}
      onSendEmailCode={onSendEmailCode}
      onSubmit={onSubmit}
    />
  );
};
