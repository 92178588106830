import { Input, InputProps } from 'libs/ui';

import { FieldType } from '../types';
import { useField } from '../useField';

export interface TextFieldProps
  extends FieldType,
    Omit<InputProps, 'name' | 'onChange' | 'onChangeText' | 'value' | 'type'> {
  maxNumber?: number;
  checkNumber?: (value: string | undefined) => boolean;
  format?: (v?: string) => string | undefined;
}

export const NumberField = ({
  name,
  allowSubmit,
  maxNumber,
  checkNumber,
  format,
  ...props
}: TextFieldProps) => {
  const { field, helpers, inputProps } = useField<string | undefined>(name, {
    allowSubmit,
  });

  return (
    <Input
      {...props}
      name={name}
      value={field.value ?? ''}
      onBlur={field.onBlur(name)}
      onChange={() => helpers.setError('')}
      onChangeText={(v) => {
        if (
          !v ||
          (/^[-\d]\d*$/.test(v) &&
            (maxNumber === undefined || Number(v) <= maxNumber) &&
            (!checkNumber || checkNumber(v)))
        ) {
          const value = format ? format?.(v) : v;
          helpers.setValue(value);
        }
      }}
      onClear={() => helpers.setValue('')}
      {...inputProps}
    />
  );
};
