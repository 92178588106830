import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import {
  OnboardingClientUserDocument,
  useClientRepresentativeSaveOwnerEmailMutation,
} from 'api/generated/graphql';
import { useError } from 'api/helpers';

export const useClientRepresentativeSaveOwnerEmail = () => {
  const [handle, { data, loading, error: apolloError }] =
    useClientRepresentativeSaveOwnerEmailMutation({
      refetchQueries: [OnboardingClientUserDocument],
      awaitRefetchQueries: true,
    });

  const error = useError(apolloError);

  const saveOwnerEmail = useCallback(
    async (id: string, email: string) => {
      const res = await handle({
        variables: { id, input: { email } },
      });
      return res.data?.clientRepresentativeSaveOwnerEmail;
    },
    [handle]
  );

  return {
    data,
    loading,
    error,
    saveOwnerEmail,
  };
};
