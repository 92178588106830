import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import {
  LegalClientGetLegalHeadDocument,
  useLegalClientCompleteLegalHeadFirstHeadOnboardingMutation,
} from 'api/generated/graphql';
import { useError } from 'api/helpers';

export const useLegalClientCompleteLegalHeadFirstHeadOnboarding = () => {
  const [handle, { loading, error: apolloError }] =
    useLegalClientCompleteLegalHeadFirstHeadOnboardingMutation({
      refetchQueries: [LegalClientGetLegalHeadDocument],
      awaitRefetchQueries: true,
    });

  const error = useError(apolloError);

  const complete = useCallback(
    async (legalId: string, legalHeadId: string, firstHeadId: string) => {
      const res = await handle({
        variables: { id: legalId, legalHeadId, firstHeadId },
      });
      return !!res.data?.legalClientCompleteLegalHeadFirstHeadOnboarding;
    },
    [handle]
  );

  return {
    loading,
    error,
    complete,
  };
};
