// eslint-disable-next-line no-restricted-imports
import { LegalClientLegalHeadFragment } from 'api/generated/graphql';
import {
  parseFileEntity,
  parseOnboardingProcessEntity,
} from 'api/requests/onboarding/helpers';
import { LegalClientLegalHeadEntity } from 'api/types/entity';
import { lodash } from 'helpers';

import { parseLegalClientStateRegistration } from '../../helpers/parseLegalClientStateRegistration';
import { parseLegalClientTaxResidency } from '../../helpers/parseLegalClientTaxResidency';

export const parseLegalClientLegalHeadEntity = (
  data: LegalClientLegalHeadFragment
): LegalClientLegalHeadEntity => ({
  id: data.id,
  createdAt: new Date(data.createdAt),
  fullName: data.fullName ?? undefined,
  shortName: data.shortName ?? undefined,

  legalClientBodyMemberConfirmationFiles: lodash
    .compact(data.legalClientBodyMemberConfirmationFiles)
    .map(parseFileEntity),

  isFirstHead: data.isFirstHead ?? undefined,
  legalClientBodyId: data.legalClientBodyId ?? undefined,

  legalAddress: data.legalAddress,
  actualAddress: data.actualAddress,

  taxResidences: data.taxResidences
    ? lodash.compact(data.taxResidences).map(parseLegalClientTaxResidency)
    : undefined,
  stateRegistration: parseLegalClientStateRegistration(data),
  firstHeads: lodash.compact(data.firstHeads).map((firstHead) => ({
    id: firstHead.id,
    firstName: firstHead.firstName,
    lastName: firstHead.lastName,
    middleName: firstHead.middleName || undefined,
    onboarding: {
      isCompletedByRepresentative:
        firstHead.onboarding?.isCompletedByRepresentative ?? false,
    },
  })),

  onboarding: data.onboarding
    ? {
        process: parseOnboardingProcessEntity(data.onboarding?.process),
      }
    : undefined,
});
