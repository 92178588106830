import { useCallback, useEffect } from 'react';

import {
  useOnboardingClientIdentity,
  useRegistrationPasswordValidationRule,
  useSetupPassword,
} from 'api/requests';
import { getValidationErrors } from 'helpers';
import { useObjectState } from 'hooks';
import { useNotify } from 'libs/notify';

import { useRegistrationContext } from '../components/RegistrationProvider';
import {
  RegistrationPasswordFormType,
  FormErrors,
} from '../forms/registrationPasswordForm';

export const useRegistrationPasswordActivate = () => {
  const { clientIdentity } = useOnboardingClientIdentity();

  const {
    setupPassword,
    loading: setupPasswordLoading,
    error,
  } = useSetupPassword();

  const { agreementIds } = useRegistrationContext();

  const { passwordValidationRule } = useRegistrationPasswordValidationRule();

  const notify = useNotify();

  const [formError, setFormError] = useObjectState<FormErrors>({});

  const onSubmit = useCallback(
    async (values: RegistrationPasswordFormType) => {
      if (values && agreementIds && clientIdentity) {
        await setupPassword(values.password, agreementIds);
      }
    },
    [agreementIds, clientIdentity, setupPassword]
  );

  // handle error
  useEffect(() => {
    if (error) {
      const validationErrors = getValidationErrors<'password'>(error);
      if (validationErrors?.password) {
        setFormError({ password: validationErrors.password[0].description });
      } else {
        notify.error(error);
        setFormError({ password: undefined });
      }
    } else {
      setFormError({ password: undefined });
    }
  }, [error, notify, setFormError]);

  return {
    onSubmit,
    loading: setupPasswordLoading,
    formError,
    passwordValidationRule,
  };
};
