import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import {
  OnboardingLegalClientDocument,
  useLegalClientCompleteBeneficiaryOnboardingMutation,
} from 'api/generated/graphql';
import { useError } from 'api/helpers';

export const useLegalClientCompleteBeneficiaryOnboarding = () => {
  const [handle, { loading, error: apolloError }] =
    useLegalClientCompleteBeneficiaryOnboardingMutation({
      refetchQueries: [OnboardingLegalClientDocument],
      awaitRefetchQueries: true,
    });

  const error = useError(apolloError);

  const complete = useCallback(
    async (legalId: string, beneficiaryId: string) => {
      const res = await handle({
        variables: { id: legalId, beneficiaryId },
      });
      return !!res.data?.legalClientCompleteBeneficiaryOnboarding;
    },
    [handle]
  );

  return {
    loading,
    error,
    complete,
  };
};
