// eslint-disable-next-line no-restricted-imports
import {
  ContactDto_Input,
  OrderSubmissionContactsDto_Input,
  Query_ClientController_GetById_OrderSubmissionContacts_Items_Type,
} from 'api/generated/graphql';
import { ClientSaveOrderSubmissionContactParam } from 'api/types/params';
import { lodash } from 'helpers';

export const parseOrderSubmissionContactParam = ({
  type,
  value,
}: ClientSaveOrderSubmissionContactParam): ContactDto_Input => ({
  value,
  type: Query_ClientController_GetById_OrderSubmissionContacts_Items_Type[type],
});

export const parseOrderSubmissionContactsParam = (
  orderSubmissionContacts: ClientSaveOrderSubmissionContactParam[]
): OrderSubmissionContactsDto_Input => ({
  orderSubmissionContacts: lodash
    .compact(orderSubmissionContacts)
    .map(parseOrderSubmissionContactParam),
});
