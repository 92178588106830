import {
  BiographyEntity,
  MaritalStatusType,
  OccupationType,
} from 'api/types/entity';
import { TFunc, TranslationPath } from 'libs/i18n';

import { OnboardingBiographyFormType } from '../../forms/onboardingBiographyForm';

export const MaritalStatusTranslationPath: Record<
  MaritalStatusType,
  TranslationPath
> = {
  [MaritalStatusType.married]: 'onboarding.biography.married',
  [MaritalStatusType.single]: 'onboarding.biography.single',
};

export const OccupationTranslationPath: Record<
  OccupationType,
  TranslationPath
> = {
  [OccupationType.businessOwner]: 'onboarding.biography.businessOwner',
  [OccupationType.employee]: 'onboarding.biography.employee',
  [OccupationType.entrepreneur]: 'onboarding.biography.entrepreneur',
  [OccupationType.unemployed]: 'onboarding.biography.notWorking',
  [OccupationType.retired]: 'onboarding.biography.retired',
  [OccupationType.student]: 'onboarding.biography.student',
};

export const getInitialData = (
  biography: BiographyEntity | undefined,
  t: TFunc
) => {
  const values: OnboardingBiographyFormType = {
    ...biography,
    maritalStatus: biography?.maritalStatus
      ? {
          value: biography.maritalStatus,
          label: t(MaritalStatusTranslationPath[biography.maritalStatus]),
        }
      : null,

    occupation: biography?.occupation
      ? {
          value: biography.occupation,
          label: t(OccupationTranslationPath[biography.occupation]),
        }
      : null,
  };

  return values;
};
