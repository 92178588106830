import { CompanyStateRegistration } from 'modules/onboarding/legal/components';

import { useCompanyStateRegistration } from '../hooks';
import { useOnboardingContext } from '../hooks/useOnboardingContext';
import { PageComponentProps } from '../types';

export const CompanyStateRegistrationStep = ({
  canGoBack,
}: PageComponentProps) => {
  const {
    files,
    goToBack,
    initialValues,
    onAddFiles,
    onRemoveFile,
    onSubmit,
    submitDisabled,
    submitLoading,
  } = useCompanyStateRegistration();

  const { step } = useOnboardingContext();

  return (
    <CompanyStateRegistration
      canGoBack={canGoBack}
      files={files}
      initialValues={initialValues}
      step={step}
      submitDisabled={submitDisabled}
      submitLoading={submitLoading}
      onAddFiles={onAddFiles}
      onGoBack={goToBack}
      onRemoveFile={onRemoveFile}
      onSubmit={onSubmit}
    />
  );
};
