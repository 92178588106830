// eslint-disable-next-line no-restricted-imports
import { Query_LegalClientController_GetById__Dollar_1cInvoiceCurrency as CurrencyApi } from 'api/generated/graphql';
import { CurrencyInvoiceType } from 'api/types/entity';

const currency: Record<CurrencyApi, CurrencyInvoiceType> = {
  [CurrencyApi.Eur]: CurrencyInvoiceType.EUR,
  [CurrencyApi.Kzt]: CurrencyInvoiceType.KZT,
  [CurrencyApi.Rub]: CurrencyInvoiceType.RUB,
  [CurrencyApi.Usd]: CurrencyInvoiceType.USD,
};

export const parseLegalClientInvoiceCurrency = (
  data: CurrencyApi
): CurrencyInvoiceType => currency[data];
