import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import {
  OnboardingLegalClientDocument,
  useLegalClientUpdateBodyMutation,
} from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { LegalClientBodyParam } from 'api/types/params';

export const useLegalClientUpdateBody = () => {
  const [handle, { loading, error: apolloError }] =
    useLegalClientUpdateBodyMutation({
      refetchQueries: [OnboardingLegalClientDocument],
      awaitRefetchQueries: true,
    });

  const error = useError(apolloError);

  return {
    loading,
    error,
    updateBody: useCallback(
      async (id: string, bodyId: string, params: LegalClientBodyParam) => {
        const res = await handle({
          variables: {
            id,
            bodyId,
            input: params,
          },
        });
        return !!res?.data?.legalClientUpdateBody;
      },
      [handle]
    ),
  };
};
