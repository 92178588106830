// eslint-disable-next-line no-restricted-imports
import { Query_ClientController_GetById_OnboardingStatus as ClientOnboardingStatusTypeApi } from 'api/generated/graphql';
import { Maybe } from 'types/maybe';

import { ClientOnboardingStatusType } from '../../../types/entity';

const statusMap: Record<
  ClientOnboardingStatusTypeApi,
  ClientOnboardingStatusType
> = {
  [ClientOnboardingStatusTypeApi.FillingOut]:
    ClientOnboardingStatusType.FillingOut,
  [ClientOnboardingStatusTypeApi.AccountOpened]:
    ClientOnboardingStatusType.AccountOpened,
  [ClientOnboardingStatusTypeApi.ComplianceCheck]:
    ClientOnboardingStatusType.ComplianceCheck,
  [ClientOnboardingStatusTypeApi.AccountOpeningDenied]:
    ClientOnboardingStatusType.AccountOpeningDenied,
  [ClientOnboardingStatusTypeApi.AmlCftCheck]:
    ClientOnboardingStatusType.AmlCftCheck,
  [ClientOnboardingStatusTypeApi.AccountOpening]:
    ClientOnboardingStatusType.AccountOpening,
  [ClientOnboardingStatusTypeApi.AwaitingAccountOpening]:
    ClientOnboardingStatusType.AwaitingAccountOpening,
  [ClientOnboardingStatusTypeApi.AwaitingAmlCftCheck]:
    ClientOnboardingStatusType.AwaitingAmlCftCheck,
  [ClientOnboardingStatusTypeApi.AwaitingComplianceCheck]:
    ClientOnboardingStatusType.AwaitingComplianceCheck,
  [ClientOnboardingStatusTypeApi.AwaitingValidation]:
    ClientOnboardingStatusType.AwaitingValidation,
  [ClientOnboardingStatusTypeApi.BoardReview]:
    ClientOnboardingStatusType.BoardReview,
  [ClientOnboardingStatusTypeApi.ValidationCheck]:
    ClientOnboardingStatusType.ValidationCheck,
  [ClientOnboardingStatusTypeApi.PendingBoardReview]:
    ClientOnboardingStatusType.PendingBoardReview,
  [ClientOnboardingStatusTypeApi.SigningDocuments]:
    ClientOnboardingStatusType.SigningDocuments,
};

export const parseClientOnboardingStatus = (
  status: Maybe<ClientOnboardingStatusTypeApi>
) => (status ? statusMap[status] : undefined);
