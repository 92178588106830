import { useRef } from 'react';

import { useClientSaveBankAccounts } from 'api/requests';
import { ClientEntity } from 'api/types/entity';
import { useServerErrorNotify } from 'hooks';
import { OnboardingAccountsInOrganizationsFormType } from 'modules/onboarding/common/forms/onboardingAccountsInOrganizationsForm';
import {
  getInitialData,
  getSubmitData,
} from 'modules/onboarding/common/helpers/accountsInOrganizations';
import { useOnboardingCountries } from 'modules/onboarding/common/hooks';

export const useClientAccountsInOrganizations = (client: ClientEntity) => {
  const {
    loading: submitLoading,
    error,
    saveBankAccounts: saveBankAccountsApi,
  } = useClientSaveBankAccounts();

  const { countries, loading: countriesLoading } = useOnboardingCountries();

  const initialValues = useRef({
    accounts: getInitialData(client.bankAccounts),
  }).current;

  const saveBankAccounts = async (
    values: OnboardingAccountsInOrganizationsFormType
  ) => {
    const submitData = getSubmitData(values);
    await saveBankAccountsApi(client.id, submitData);
  };

  useServerErrorNotify(error);

  return {
    saveBankAccounts,
    submitLoading,
    initialValues,
    countries,
    countriesLoading,
  };
};
