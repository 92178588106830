import { useMemo } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useOnboardingLegalClientRepresentativeQuery } from 'api/generated/graphql';
import { useError } from 'api/helpers';

import { parseLegalClientRepresentative } from './helpers';

export const useOnboardingLegalClientRepresentative = (id: string) => {
  const {
    data,
    loading,
    refetch,
    error: apolloError,
  } = useOnboardingLegalClientRepresentativeQuery({ variables: { id } });

  const error = useError(apolloError);

  const currentClient = useMemo(
    () =>
      data?.onboardingLegalClientRepresentative
        ? parseLegalClientRepresentative(
            data.onboardingLegalClientRepresentative
          )
        : null,
    [data?.onboardingLegalClientRepresentative]
  );

  return {
    currentClient,
    loading,
    refetch,
    error,
  };
};
