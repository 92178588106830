import { useMemo } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useOnboardingClientQuery } from 'api/generated/graphql';
import { useError } from 'api/helpers';

import { parseClientEntity } from '../helpers';

export const useOnboardingClient = (id: string) => {
  const {
    data,
    loading,
    error: apolloError,
    refetch,
  } = useOnboardingClientQuery({ variables: { id } });

  const error = useError(apolloError);

  const currentClient = useMemo(
    () =>
      data?.onboardingClient ? parseClientEntity(data.onboardingClient) : null,
    [data?.onboardingClient]
  );

  return {
    currentClient,
    loading,
    error,
    refetch,
  };
};
