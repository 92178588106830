import {
  TypedForm,
  FormErrors as FormErrorsBase,
  object,
  string,
} from 'libs/form';

export interface VerificationCodeFormType {
  code: string;
}

export const CODE_LENGTH = 6;

export const schema = object({
  code: string().min(CODE_LENGTH).required(),
});

export const { Field, Form, useFormContext, Submit } =
  TypedForm<VerificationCodeFormType>();

export type VerificationCodeFormFormErrors =
  FormErrorsBase<VerificationCodeFormType>;
