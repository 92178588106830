import { config } from 'config';
import { OnboardingSignatureSamples } from 'modules/onboarding/common/components';

import { useOwnerSignatureSamples } from '../hooks/useOwnerSignatureSamples';
import { PageComponentProps } from '../types';

export const SignatureSamplesStep = ({ canGoBack }: PageComponentProps) => {
  const {
    onSubmit,
    goToBack,
    files,
    onAddFiles,
    onRemoveFile,
    initialValues,
    submitLoading,
  } = useOwnerSignatureSamples();

  return (
    <OnboardingSignatureSamples
      canGoBack={canGoBack}
      documentSimpleHref={config.DOCUMENT_TEMPLATE_SIGNATURES_URL ?? ''}
      files={files}
      initialValues={initialValues}
      submitLoading={submitLoading}
      onAddFiles={onAddFiles}
      onGoBack={goToBack}
      onRemoveFile={onRemoveFile}
      onSubmit={onSubmit}
    />
  );
};
