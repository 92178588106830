import { useEffect } from 'react';

import { lodash } from 'helpers';
import { FileUpload } from 'hooks/useFilesUpload';
import { FilesUpload, FilesUploadProps } from 'libs/ui';

import { FieldType } from '../types';
import { useField } from '../useField';

export interface FilesUploadFieldProps
  extends FieldType,
    Omit<FilesUploadProps<FileUpload>, 'name'> {}

/**
 * FilesUpload Field
 */
export const FilesUploadField = ({
  name,
  title,
  files,
  ...rest
}: FilesUploadFieldProps) => {
  const { helpers, meta } = useField<string[] | undefined | null>(name);

  useEffect(() => {
    if (files) {
      const ids = lodash.compact(files.map((v) => v.serverId));

      helpers.setError('');
      helpers.setValue(ids);
    }
  }, [files, helpers]);

  return (
    <FilesUpload
      files={files}
      isError={meta.isError}
      name={name}
      title={title}
      {...rest}
    />
  );
};
