import { Layout } from 'components';
import { Stack } from 'libs/ui';
import { CSSObject, styled } from 'libs/ui/styled';

export const ContainerStyled = styled(Stack)(({ theme }) => ({
  minHeight: '100%',
  backgroundColor: theme.palette.body,

  '.login-right': {
    flex: 1,
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.down('tablet')]: {
      display: 'none',
    },
  },
}));

export const LoginLayoutStyled = styled(Layout)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.up('tablet')]: {
    minWidth: 648,
    maxWidth: 648,
  },

  flex: 1,

  '.login-content': {
    flex: 1,
    justifyContent: 'center',
    [theme.breakpoints.down('tablet')]: {
      maxWidth: 440,
      width: '100%',
      margin: '0 auto',
      justifyContent: 'flex-start',
      marginTop: 32,
    } as CSSObject,
  },
}));
