export type FormErrors<Values> = {
  [K in keyof Values]?: NonNullable<Values[K]> extends any[]
    ? NonNullable<NonNullable<Values[K]>[number]> extends object
      ? FormErrors<NonNullable<Values[K]>[number]>[] | string | string[]
      : string | string[]
    : NonNullable<Values[K]> extends object
    ? FormErrors<Values[K]>
    : string;
};

/** error text that will not be displayed will be marked as an error in the field */
export const emptyErrorString = '%empty%';

export type NestedKeyOf<ObjectType> = {
  [Key in keyof ObjectType & (string | number)]: NonNullable<
    ObjectType[Key]
  > extends (infer R)[]
    ? `${Key}` | `${Key}.${number}.${NestedKeyOf<R>}`
    : NonNullable<ObjectType[Key]> extends object
    ? `${Key}` | `${Key}.${NestedKeyOf<NonNullable<ObjectType[Key]>>}`
    : `${Key}`;
}[keyof ObjectType & (string | number)];
