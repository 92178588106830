import { useCallback, useEffect, useState } from 'react';

import {
  useClientRepresentativeSettingsSave,
  useOnboardingClientLazy,
  useOnboardingClientRepresentative,
} from 'api/requests';

export const useOnboarding = (clientId: string) => {
  const {
    currentClient,
    loading,
    refetch: refetchCurrentClient,
  } = useOnboardingClientRepresentative(clientId);

  const { saveSettings } = useClientRepresentativeSettingsSave();

  const [mainLoading, setMainLoading] = useState(true);

  const {
    client: ownerClient,
    getClient,
    refetch: refetchOwnerClient,
  } = useOnboardingClientLazy();

  const [process, setProcess] = useState(currentClient?.onboarding?.process);

  const onGoBack = useCallback(
    async (name: string) => {
      if (currentClient) {
        await saveSettings(currentClient.id, name);
      }
    },
    [currentClient, saveSettings]
  );

  // get owner client
  useEffect(() => {
    if (currentClient?.id) {
      if (currentClient?.clientId) {
        getClient(currentClient.clientId).finally(() => {
          setMainLoading(false);
        });
      } else {
        setMainLoading(false);
      }
    }
  }, [currentClient?.clientId, currentClient?.id, getClient]);

  useEffect(() => {
    setProcess(currentClient?.onboarding?.process);
  }, [currentClient?.onboarding?.process]);

  useEffect(() => {
    if (ownerClient?.onboarding?.process) {
      setProcess(ownerClient?.onboarding?.process);
    }
  }, [ownerClient?.onboarding?.process]);

  return {
    currentClient,
    ownerClient,
    loading: (!currentClient && loading) || mainLoading,
    process,
    refetchOwnerClient,
    onGoBack,
    refetchCurrentClient,
  };
};
