import { CircularProgress } from '../CircularProgress';

import { StyledButton } from './Button.styles';
import { ButtonProps } from './types';

/**
 * Button component.
 */
export const Button = ({
  label,
  mode = 'standard',
  disabled,
  loading,
  startIcon,
  className,
  fullWidth,
  colorVariant = 'primary',
  datax,
  onClick,
}: ButtonProps) => (
  <StyledButton
    disableRipple
    className={className}
    colorVariant={colorVariant}
    datax={datax}
    disabled={disabled || loading}
    fullWidth={fullWidth}
    loading={loading}
    mode={mode}
    startIcon={startIcon}
    onClick={onClick}
  >
    <span className="button-label">{label}</span>
    {loading && (
      <span className="button-loading">
        <CircularProgress />
      </span>
    )}
  </StyledButton>
);
