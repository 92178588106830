import { SaveCitizenshipParam } from 'api/types/params';
import { lodash } from 'helpers';

import { OnboardingCitizenshipFormType } from '../../forms/onboardingCitizenshipForm';

export const getSubmitData = (
  values: OnboardingCitizenshipFormType
): SaveCitizenshipParam => ({
  citizenships: lodash.compact(values.citizenships.map((v) => v?.id)),
});
