import {
  CompanyFinancingSources,
  CompanyIncomeSources,
} from 'api/types/entity';
import { useTranslation } from 'libs/i18n';
import { Stack } from 'libs/ui';

import {
  useFormContext,
  Field,
  getIncomeSourcesOptions,
  getFinancingSourcesOptions,
  maxLengthOtherFinancingSources,
  maxLengthOtherIncomeSource,
} from '../../../forms/companyFinancingSourcesForm';

export const FormContent = () => {
  const { values } = useFormContext();
  const { t } = useTranslation();

  const isOtherIncomeSource = values.incomeSources?.some(
    (source) => source.value === CompanyIncomeSources.Other
  );
  const isFinancingSourcesOther = values.financingSources?.some(
    (source) => source.value === CompanyFinancingSources.Other
  );

  return (
    <Stack spacing={24} spacingMobile={16}>
      <Field.Autocomplete
        multiple
        label={t('onboarding.companyFinancingSources.incomeSources.label')}
        name="incomeSources"
        optionLabelKey="label"
        options={getIncomeSourcesOptions(t)}
        optionValueKey="value"
        placeholder={t(
          'onboarding.companyFinancingSources.incomeSources.placeholder'
        )}
        variant="select"
      />

      {isOtherIncomeSource && (
        <Field.Text
          label={t('onboarding.companyFinancingSources.other.label')}
          maxLength={maxLengthOtherIncomeSource}
          name="otherIncomeSource"
          placeholder={t(
            'onboarding.companyFinancingSources.other.placeholder'
          )}
        />
      )}

      <Field.Autocomplete
        multiple
        label={t('onboarding.companyFinancingSources.financingSources.label')}
        name="financingSources"
        optionLabelKey="label"
        options={getFinancingSourcesOptions(t)}
        optionValueKey="value"
        placeholder={t(
          'onboarding.companyFinancingSources.financingSources.placeholder'
        )}
        variant="select"
      />

      {isFinancingSourcesOther && (
        <Field.Text
          label={t('onboarding.companyFinancingSources.other.label')}
          maxLength={maxLengthOtherFinancingSources}
          name="otherFinancingSources"
          placeholder={t(
            'onboarding.companyFinancingSources.other.placeholder'
          )}
        />
      )}
    </Stack>
  );
};
