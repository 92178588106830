import { useRef } from 'react';

import { useLegalClientRepresentativeSaveCompanyConstitutionalDocument } from 'api/requests';
import { FileUpload, useFilesUpload, useServerErrorNotify } from 'hooks';
import { useOnboardingCountries } from 'modules/onboarding/common/hooks';

import { CompanyConstituentDocsFormType } from '../../forms/companyConstituentDocs';
import {
  getSubmitData,
  getInitialData,
} from '../../helpers/companyConstituentDocs';
import { useOnboardingContext } from '../useOnboardingContext';

export const useLegalRepresentativeCompanyConstituentDocs = () => {
  const { goToBack, goToNext, data } = useOnboardingContext();
  const { error, loading, saveConstitutionalDocument } =
    useLegalClientRepresentativeSaveCompanyConstitutionalDocument();

  const { countries, loading: countriesLoading } = useOnboardingCountries();

  const initialValues = useRef(
    getInitialData(data.currentClient.company?.constitutionalDocument)
  ).current;

  const onSubmit = async (values: CompanyConstituentDocsFormType) => {
    if (values) {
      const submitData = getSubmitData(values);
      await saveConstitutionalDocument(data.currentClient!.id, submitData);
      goToNext();
    }
  };

  const filesUpload: FileUpload[] | undefined =
    data.currentClient.company?.constitutionalDocument?.files?.map(
      (v) =>
        new FileUpload({ name: v.name, size: v.size }, undefined, false, v.id)
    );

  const {
    files,
    isLoadingFile: submitDisabled,
    onRemoveFile,
    onAddFiles,
  } = useFilesUpload(filesUpload);

  useServerErrorNotify(error);

  return {
    onSubmit,
    goToBack,
    submitLoading: loading,
    initialValues,
    submitDisabled,
    countries,
    countriesLoading,
    files,
    onAddFiles,
    onRemoveFile,
  };
};
