import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useLegalClientCreateLegalHeadFirstHeadMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { LegalClientCreateLegalHeadFirstHeadSaveParam } from 'api/types/params';
import { formatToISODate } from 'helpers';

import { parseLegalClientLegalHeadFirstHeadEntity } from './helpers';

export const useLegalClientCreateLegalHeadFirstHead = () => {
  const [handle, { data, loading, error: apolloError }] =
    useLegalClientCreateLegalHeadFirstHeadMutation();

  const error = useError(apolloError);

  return {
    data: data?.legalClientCreateLegalHeadFirstHead
      ? parseLegalClientLegalHeadFirstHeadEntity(
          data.legalClientCreateLegalHeadFirstHead
        )
      : undefined,
    loading,
    error,
    createFirstHead: useCallback(
      async (
        id: string,
        legalHeadId: string,
        param: LegalClientCreateLegalHeadFirstHeadSaveParam
      ) => {
        const res = await handle({
          variables: {
            id,
            legalHeadId,
            input: {
              ...param,
              dateOfBirth: formatToISODate(param.dateOfBirth),
            },
          },
        });
        return res.data?.legalClientCreateLegalHeadFirstHead
          ? parseLegalClientLegalHeadFirstHeadEntity(
              res.data.legalClientCreateLegalHeadFirstHead
            )
          : undefined;
      },
      [handle]
    ),
  };
};
