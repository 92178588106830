import { TaxResidencesEntity } from 'api/types/entity';
import { Maybe } from 'types/maybe';

import { OnboardingTaxResidencyFormType } from '../../forms/onboardingTaxResidencyForm';

export const getInitialData = (
  taxResidences?: Maybe<TaxResidencesEntity[]>
): OnboardingTaxResidencyFormType => ({
  taxValues: taxResidences?.map((v) => ({
    country: v.country || null,
    reasonForAbsence: v.reasonForMissingTaxId,
    taxNumber: v.taxId,
    taxNumberIsMissing: !v.taxId && !!v.reasonForMissingTaxId,
  })) || [{ country: null }],
});
