import { ChangeEvent, FocusEvent, useRef, useState } from 'react';

// eslint-disable-next-line no-restricted-imports
import { AsYouType } from 'libphonenumber-js';

import { Input } from '../Input';

import { formatPhoneWithPlus } from './helper';
import { PhoneInputProps } from './types';

/**
 * PhoneInput component.
 */
export const PhoneInput = ({
  value,
  onBlur,
  onFocus,
  onChangeText,
  ...props
}: PhoneInputProps) => {
  const asYouType = useRef(new AsYouType()).current;
  const [isFocused, setFocus] = useState(false);

  asYouType.reset();

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    setFocus(false);
    onBlur?.(e);
  };

  const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
    setFocus(true);
    onFocus?.(e);
  };

  const handleOnChange = (
    value: string,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    value = value.replace('++', '+');
    onChangeText?.(value, event);
  };

  return (
    <Input
      {...props}
      value={asYouType.input(formatPhoneWithPlus(value as string, isFocused))}
      onBlur={handleBlur}
      onChangeText={handleOnChange}
      onFocus={handleFocus}
    />
  );
};
