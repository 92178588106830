import { LegalClientInnerItemEntity } from 'api/types/entity/legal/legalClientInnerItemEntity';
import { useTranslation } from 'libs/i18n';
import { Button, FileType, FileUpload, Stack, Typography } from 'libs/ui';
import { Icon } from 'libs/ui/Icon';
import { DownloadContent } from 'modules/onboarding/common/components/DownloadContent';

import { DATAX_ADD_ITEM } from '../../../../common/constants';
import {
  Field,
  MAX_FILES,
  useFormContext,
} from '../../../forms/companyBeneficiariesForm';
import { UserList } from '../../UserList';

export interface FormContentProps {
  beneficiaries: LegalClientInnerItemEntity[];
  deleteLoading?: boolean;
  errorBeneficiariesIds?: string[];
  documentSimpleHref: string;
  files?: FileUpload[];
  onAddFiles?: (file: File[]) => void;
  onRemoveFile?: (file: FileUpload) => void;
  onAdd: () => void;
  onEdit: (id: string) => void;
  onDelete: (id: string) => void;
}

export const FormContent = ({
  beneficiaries,
  deleteLoading,
  errorBeneficiariesIds,
  documentSimpleHref,
  files,
  onAddFiles,
  onRemoveFile,
  onEdit,
  onAdd,
  onDelete,
}: FormContentProps) => {
  const { t } = useTranslation();

  const { values } = useFormContext();

  return (
    <>
      {!beneficiaries.length && (
        <Stack alignItems="center" direction="row" spacing={16}>
          <Typography variant="bodyBold">
            {t('onboarding.beneficiaries.switch.label')}
          </Typography>
          <Field.Switch name="impossibleIdentifyBeneficiary" />
        </Stack>
      )}
      {values.impossibleIdentifyBeneficiary ? (
        <>
          <DownloadContent
            href={documentSimpleHref}
            title={t('onboarding.beneficiaries.simpleDocumentTitle')}
          />
          <Field.FilesUpload
            files={files}
            fileTypes={[FileType.pdf]}
            maxFiles={MAX_FILES}
            name="fileIds"
            title={t('onboarding.beneficiaries.documentTitle')}
            onAddFiles={onAddFiles}
            onRemoveFile={onRemoveFile}
          />
        </>
      ) : (
        <>
          <UserList
            deleteLoading={deleteLoading}
            errorIds={errorBeneficiariesIds}
            errorText={t('onboarding.beneficiaries.filledErrorMessage')}
            users={beneficiaries.map((b) => ({
              id: b.id,
              name: b.name,
              type: t('onboarding.beneficiaries.userType'),
            }))}
            onDelete={onDelete}
            onEdit={onEdit}
          />
          <Button
            fullWidth
            datax={DATAX_ADD_ITEM}
            label={t('onboarding.beneficiaries.addButton')}
            mode="medium"
            startIcon={<Icon.Plus />}
            onClick={onAdd}
          />
        </>
      )}
    </>
  );
};
