import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useLegalClientLegalHeadSaveAddressesMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { LegalClientSaveRegistrationAddressesParam } from 'api/types/params';

import { parseLegalClientLegalHeadEntity } from './helpers';

export const useLegalClientLegalHeadSaveAddresses = () => {
  const [handle, { data, loading, error: apolloError }] =
    useLegalClientLegalHeadSaveAddressesMutation();

  const error = useError(apolloError);

  return {
    data: data?.legalClientLegalHeadSaveAddresses
      ? parseLegalClientLegalHeadEntity(data.legalClientLegalHeadSaveAddresses)
      : undefined,
    loading,
    error,
    saveAddresses: useCallback(
      async (
        id: string,
        legalHeadId: string,
        params: LegalClientSaveRegistrationAddressesParam
      ) => {
        const res = await handle({
          variables: {
            id,
            legalHeadId,
            input: params,
          },
        });
        return res?.data?.legalClientLegalHeadSaveAddresses
          ? parseLegalClientLegalHeadEntity(
              res.data.legalClientLegalHeadSaveAddresses
            )
          : undefined;
      },
      [handle]
    ),
  };
};
