import {
  TypedForm,
  FormErrors as FormErrorsBase,
  ObjectSchema,
} from 'libs/form';
import {
  BaseOnboardingRegistrationAddressFormType,
  getBaseSchema,
} from 'modules/onboarding/common/forms/onboardingRegistrationAddressForm';

export const getSchema =
  (): ObjectSchema<BaseOnboardingRegistrationAddressFormType> =>
    getBaseSchema();

export const { Field, Form, useFormContext, Submit } =
  TypedForm<BaseOnboardingRegistrationAddressFormType>();

export type FormErrors =
  FormErrorsBase<BaseOnboardingRegistrationAddressFormType>;
