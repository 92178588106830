// eslint-disable-next-line no-restricted-imports
import { LegalFinancingSourcesFragment } from 'api/generated/graphql';
import {
  CompanyIncomeSources,
  CompanyFinancingSources,
  LegalFinancingSourcesEntity,
} from 'api/types/entity';
import { lodash } from 'helpers';
import { Maybe } from 'types/maybe';

import { parseFileEntity } from '../../helpers';

export const parseLegalClientFinancingSourcesEntity = (
  data?: Maybe<LegalFinancingSourcesFragment>
): LegalFinancingSourcesEntity | undefined => {
  if (!data) {
    return undefined;
  }

  return {
    incomeSources: lodash
      .compact(data.incomeSources)
      .map((v) => CompanyIncomeSources[v]),
    otherIncomeSource: data.otherIncomeSource || undefined,
    financingSources: lodash
      .compact(data.financingSources)
      .map((v) => CompanyFinancingSources[v]),
    otherFinancingSource: data.otherFinancingSource || undefined,

    lastYearAuditFinancialReportFiles: lodash
      .compact(data.lastYearAuditFinancialReportFiles)
      .map((v) => parseFileEntity(v)),

    recommendationLetterFiles: lodash
      .compact(data.recommendationLetterFiles)
      .map((v) => parseFileEntity(v)),
  };
};
