import { useRef } from 'react';

import { useLegalClientRepresentativeSavePepConnection } from 'api/requests';
import { useServerErrorNotify } from 'hooks';
import { useTranslation } from 'libs/i18n';
import { OnboardingPepConnectionFormType } from 'modules/onboarding/common/forms/onboardingPepConnectionForm';
import { getInitialData } from 'modules/onboarding/common/helpers/pepConnection/getInitialData';
import { getSubmitData } from 'modules/onboarding/common/helpers/pepConnection/getSubmitData';

import { useOnboardingContext } from './useOnboardingContext';

export const usePepConnection = () => {
  const { t } = useTranslation();
  const { savePepConnection, error, loading } =
    useLegalClientRepresentativeSavePepConnection();

  const { goToBack, goToNext, data, clientId } = useOnboardingContext();

  const initialValues = useRef(
    getInitialData(data.currentClient.pepConnection, t)
  ).current;

  const onSubmit = async (values: OnboardingPepConnectionFormType) => {
    if (values) {
      const data = getSubmitData(values);
      await savePepConnection(clientId, data);
      goToNext();
    }
  };

  useServerErrorNotify(error);

  return {
    onSubmit,
    goToBack,
    initialValues,
    submitLoading: loading,
  };
};
