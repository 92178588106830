import { Contacts } from '../components';
import { useContacts, useOnboardingContext } from '../hooks';
import { PageComponentProps } from '../types';

export const ContactsStep = ({ canGoBack }: PageComponentProps) => {
  const { initialValues, onSubmit, submitLoading, goToBack } = useContacts();

  const { step } = useOnboardingContext();

  return (
    <Contacts
      canGoBack={canGoBack}
      initialValues={initialValues}
      step={step}
      submitLoading={submitLoading}
      onGoBack={goToBack}
      onSubmit={onSubmit}
    />
  );
};
