import { useRef } from 'react';

import { useLegalClientRepresentativeSaveCompanyStateRegistration } from 'api/requests';
import { useFilesUpload, useServerErrorNotify } from 'hooks';
import { useOnboardingCountries } from 'modules/onboarding/common/hooks';

import { CompanyStateRegistrationFormType } from '../../forms/companyStateRegistrationForm';
import { getInitialData, getSubmitData } from '../../helpers/stateRegistration';
import { useOnboardingContext } from '../useOnboardingContext';

export const useLegalRepresentativeCompanyStateRegistration = () => {
  const { saveStateRegistration, error, loading } =
    useLegalClientRepresentativeSaveCompanyStateRegistration();
  const { goToBack, goToNext, data } = useOnboardingContext();

  const { filesUpload, values: initialValues } = useRef(
    getInitialData(data.currentClient!.company?.stateRegistration)
  ).current;

  const {
    files,
    isLoadingFile: submitDisabled,
    onRemoveFile,
    onAddFiles,
  } = useFilesUpload(filesUpload);

  const { countries, loading: countriesLoading } = useOnboardingCountries();

  const onSubmit = async (values: CompanyStateRegistrationFormType) => {
    const submitData = getSubmitData(values);
    const res = await saveStateRegistration(data.currentClient!.id, submitData);
    if (res) {
      goToNext();
    }
  };

  useServerErrorNotify(error);

  return {
    onSubmit,
    goToBack,
    submitLoading: loading,
    initialValues,
    submitDisabled,
    countries,
    countriesLoading,
    files,
    onAddFiles,
    onRemoveFile,
  };
};
