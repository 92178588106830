import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useLegalClientRepresentativeSaveCompanyTaxResidencesMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { LegalClientSaveTaxResidencesParam } from 'api/types/params';

import { parseLegalClientRepresentative } from '../helpers';

export const useLegalClientRepresentativeSaveCompanyTaxResidences = () => {
  const [handle, { data, loading, error: apolloError }] =
    useLegalClientRepresentativeSaveCompanyTaxResidencesMutation();

  const error = useError(apolloError);

  return {
    data: data?.legalClientRepresentativeSaveCompanyTaxResidences
      ? parseLegalClientRepresentative(
          data.legalClientRepresentativeSaveCompanyTaxResidences
        )
      : undefined,
    loading,
    error,
    saveTaxResidences: useCallback(
      async (id: string, params: LegalClientSaveTaxResidencesParam) => {
        const res = await handle({
          variables: {
            id,
            input: params,
          },
        });
        return res?.data?.legalClientRepresentativeSaveCompanyTaxResidences
          ? parseLegalClientRepresentative(
              res.data.legalClientRepresentativeSaveCompanyTaxResidences
            )
          : undefined;
      },
      [handle]
    ),
  };
};
