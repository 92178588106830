import { CompanyBranches } from '../components';
import { useBranches } from '../hooks';
import { PageComponentProps } from '../types';

export const CompanyBranchesStep = ({ canGoBack }: PageComponentProps) => {
  const {
    submitLoading,
    deleteLoading,
    onAdd,
    onSubmit,
    goToBack,
    onEdit,
    onDelete,
    branches,
    errorBranchesIds,
  } = useBranches();

  return (
    <CompanyBranches
      branches={branches}
      canGoBack={canGoBack}
      deleteLoading={deleteLoading}
      errorBranchesIds={errorBranchesIds}
      submitLoading={submitLoading}
      onAdd={onAdd}
      onDelete={onDelete}
      onEdit={onEdit}
      onGoBack={goToBack}
      onSubmit={onSubmit}
    />
  );
};
