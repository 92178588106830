import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useLegalClientLegalHeadFirstHeadSettingsSaveMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';

import { parseOnboardingProcessEntity } from '../../helpers';

export const useLegalClientLegalHeadFirstHeadSettingsSave = () => {
  const [handle, { data, loading, error: apolloError }] =
    useLegalClientLegalHeadFirstHeadSettingsSaveMutation();

  const error = useError(apolloError);

  const saveSettings = useCallback(
    async (
      legalId: string,
      legalHeadId: string,
      firstHeadId: string,
      currentStepName: string
    ) => {
      const res = await handle({
        variables: {
          id: legalId,
          legalHeadId,
          firstHeadId,
          input: {
            currentStepName,
          },
        },
      });
      return res.data?.legalClientLegalHeadFirstHeadSettingsSave?.process
        ? parseOnboardingProcessEntity(
            res.data?.legalClientLegalHeadFirstHeadSettingsSave?.process
          )
        : undefined;
    },
    [handle]
  );

  return {
    data,
    loading,
    error,
    saveSettings,
  };
};
