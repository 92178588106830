import { useEffect, useLayoutEffect, useState } from 'react';

import { LocalStorage } from 'libs/storage';

import { HeadPageType, StateValue, InnerState } from './type';

export const useInnerState = (): InnerState => {
  const [state, setState] = useState<StateValue>();

  const setCreateFirstHead = () => {
    setState({
      type: HeadPageType.legalFirstHead,
    });
  };

  const setHead = (
    type: HeadPageType.legal | HeadPageType.person,
    headId?: string
  ) => {
    setState({
      type,
      headId,
    });
  };

  const setFirstHead = (headId: string, legalFirstHeadId?: string) => {
    setState({
      type: HeadPageType.legalFirstHead,
      headId,
      legalFirstHeadId,
    });
  };

  const setBeneficiary = (beneficiaryId?: string) => {
    setState({
      type: HeadPageType.beneficiary,
      beneficiaryId,
    });
  };

  const setBranch = (branchId?: string) => {
    setState({
      type: HeadPageType.branch,
      branchId,
    });
  };

  const removeState = () => {
    setState(undefined);
  };

  useEffect(() => {
    if (state) {
      LocalStorage.setItem(
        LocalStorage.keys.onboardingInnerState,
        JSON.stringify(state)
      );
    } else {
      LocalStorage.removeItem(LocalStorage.keys.onboardingInnerState);
    }
  }, [state]);

  // init state
  useLayoutEffect(() => {
    const stateStorage = LocalStorage.getItem(
      LocalStorage.keys.onboardingInnerState
    );
    if (stateStorage) {
      try {
        const state = JSON.parse(stateStorage);
        setState(state);
      } catch (error) {
        LocalStorage.removeItem(LocalStorage.keys.onboardingInnerState);
      }
    }
  }, []);

  return {
    state,
    setCreateFirstHead,
    setFirstHead,
    removeState,
    setHead,
    setBeneficiary,
    setBranch,
  };
};
