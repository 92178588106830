import {
  LegalClientEntity,
  LegalClientRepresentativeEntity,
} from 'api/types/entity';
import { LoadingLayout } from 'components';
import { useTranslation } from 'libs/i18n';
import { Stack } from 'libs/ui';
import {
  BackBody,
  OnboardingLayout,
} from 'modules/onboarding/common/components';
import { PersonalDataFormType } from 'modules/onboarding/common/forms/personalDataForm';
import { OnboardingProvider } from 'modules/onboarding/common/providers';

import { useOnboarding } from '../hooks/useOnboarding';
import { pagesMap } from '../route';

interface Props {
  currentClient: LegalClientRepresentativeEntity;
  legalClient: LegalClientEntity;
  id?: string;
  goBack: () => void;
  onCreateBeneficiary: (id: string) => void;
}

export const LegalClientBeneficiaryContainer = ({
  id,
  currentClient,
  legalClient,
  goBack,
  onCreateBeneficiary,
}: Props) => {
  const {
    loading,
    legalClientBeneficiary,
    process,
    createBeneficiary,
    onGoBack,
    onComplete,
  } = useOnboarding(currentClient, legalClient, id);

  const { t } = useTranslation();

  if (loading) {
    return <LoadingLayout />;
  }

  const handleEnd = async () => {
    if (await onComplete()) {
      goBack();
    }
  };

  const handleCreateBeneficiary = async (
    personalData: PersonalDataFormType
  ) => {
    const beneficiary = await createBeneficiary(personalData);
    if (beneficiary) {
      onCreateBeneficiary(beneficiary.id);
    }
    return !!beneficiary;
  };

  return (
    <OnboardingProvider
      clientId={currentClient.id}
      data={{
        currentClient,
        legalClient,
        legalClientBeneficiary,
        onCreateBeneficiary: handleCreateBeneficiary,
      }}
      pagesMap={pagesMap}
      process={legalClientBeneficiary?.onboarding?.process ?? process!}
      renderChild={({ route }) => {
        const Component = route.page.component;
        return (
          <OnboardingLayout key={route.pageKey} headerComponent={<div />}>
            <Stack spacing={16}>
              <BackBody
                text={t('onboarding.legalClientBeneficiary.backTitle')}
                onClick={goBack}
              />
              <Component {...route.page.componentProps} />
            </Stack>
          </OnboardingLayout>
        );
      }}
      onEnd={handleEnd}
      onGoBack={onGoBack}
    />
  );
};
