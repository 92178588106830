import { Layout } from 'components';
import { getColor } from 'helpers';
import { CSSObject, styled } from 'libs/ui/styled';
import { ColorsDark, ColorsLight } from 'libs/ui/theme';

export const LayoutStyled = styled(Layout)(({ theme }) => ({
  '.waitingKyc-content': {
    maxWidth: 800,
    [theme.breakpoints.up('mobile')]: {
      justifyContent: 'center',
      alignSelf: 'center',
    },
    [theme.breakpoints.down('mobile')]: {
      marginTop: 32,
    },
    flex: 1,
  },

  '.waitingKyc-line': {
    height: 1,
    width: '100%',
    backgroundColor: getColor(theme, {
      dark: ColorsDark.blue100,
      light: ColorsLight.gray200,
    }),
  } as CSSObject,

  '.waitingKyc-items': {
    width: '100%',
  } as CSSObject,
}));
