import { useLegalClientHead } from 'api/requests';
import { KYCStatus } from 'api/types/data';
import { LegalClientOnboardingStatusType } from 'api/types/entity';
import { useOnboardingKyc } from 'modules/onboarding/common/hooks';

export const useKyc = (clientId: string) => {
  const { head, refetch } = useLegalClientHead(clientId);

  const status =
    head?.legalClientOnboardingStatus ===
    LegalClientOnboardingStatusType.AccountOpeningDenied
      ? KYCStatus.error
      : head?.kyc?.status;

  const { expirationHandler, loading, token } = useOnboardingKyc({
    onSuccess: () => {},
    refetch,
    status,
  });

  return {
    loading: loading && !token,
    token,
    status,
    expirationHandler,
  };
};
