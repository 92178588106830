import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useClientSaveBankAccountsMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { SaveBankAccountsParam } from 'api/types/params';

import { parseClientEntity } from '../helpers';

export const useClientSaveBankAccounts = () => {
  const [saveBankAccounts, { data, loading, error: apolloError }] =
    useClientSaveBankAccountsMutation();

  const error = useError(apolloError);

  return {
    data: data?.clientSaveBankAccounts
      ? parseClientEntity(data.clientSaveBankAccounts)
      : undefined,
    loading,
    error,
    saveBankAccounts: useCallback(
      async (id: string, params: SaveBankAccountsParam) => {
        const res = await saveBankAccounts({
          variables: {
            id,
            input: params,
          },
        });
        return res?.data?.clientSaveBankAccounts
          ? parseClientEntity(res.data.clientSaveBankAccounts)
          : undefined;
      },
      [saveBankAccounts]
    ),
  };
};
