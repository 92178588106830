// eslint-disable-next-line no-restricted-imports
import { SvgIcon as SvgIconBase } from '@mui/material';
import { styled } from 'libs/ui/styled';

import { SvgSize } from './types';

export const SvgIcon = styled(SvgIconBase)<{ size: SvgSize | undefined }>(
  ({ size }) => ({
    ...(typeof size === 'number'
      ? {
          width: size,
          height: size,
          overflow: 'visible',
        }
      : size),
  })
);
