import { FormContainer } from 'components';
import { useTranslation } from 'libs/i18n';

import { getSchema, emptySchema, Form } from '../../forms/companyStructureForm';

import { FormContent, FormContentProps } from './FormContent';

interface CompanyStructureProps extends FormContentProps {
  onSubmit: () => void;
  canGoBack?: boolean;
  onGoBack?: () => void;
  submitLoading?: boolean;
}

export const CompanyStructure = ({
  canGoBack,
  onGoBack,
  submitLoading,
  onSubmit,
  ...formProps
}: CompanyStructureProps) => {
  const schema = getSchema();
  const { t } = useTranslation();
  return (
    <FormContainer
      canGoBack={canGoBack}
      submitLoading={submitLoading}
      subTitle={t('onboarding.companyStructure.subtitle')}
      title={t('onboarding.companyStructure.title')}
      validationSchema={emptySchema}
      onGoBack={onGoBack}
      onSubmit={onSubmit}
    >
      <Form validationSchema={schema} onSubmit={() => {}}>
        <FormContent {...formProps} />
      </Form>
    </FormContainer>
  );
};
