import { useTranslation } from 'libs/i18n';
import { Stack, Typography } from 'libs/ui';

import { useFormContext } from '../../../forms/orderSubmissionContacts';

import { Fields, Buttons } from './components';

export const FormContent = () => {
  const { values } = useFormContext();
  const { t } = useTranslation();

  return (
    <Stack spacing={32}>
      {values.orderSubmissionContacts.map((value, index) => (
        <Stack key={index.toLocaleString()} spacing={32}>
          <Stack spacing={24} spacingMobile={16}>
            <Typography variant="subtitle">
              {t(
                index === 0
                  ? 'onboarding.orderSubmissionContacts.contact.label'
                  : 'onboarding.orderSubmissionContacts.otherContact.label'
              )}
            </Typography>
            <Fields index={index} />
          </Stack>

          <Buttons index={index} />
        </Stack>
      ))}
    </Stack>
  );
};
