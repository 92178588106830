import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useLoginMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { LoginData } from 'api/types/data';
import { LoginParam } from 'api/types/params';

export const useLogin = () => {
  const [handle, { data, loading, error: apolloError }] = useLoginMutation();

  const error = useError(apolloError);

  return {
    data: data?.AuthController_login as LoginData | undefined,
    loading,
    error,
    login: useCallback(
      async (params: LoginParam) => {
        const res = await handle({ variables: params });
        return res.data?.AuthController_login as LoginData | undefined;
      },
      [handle]
    ),
  };
};
