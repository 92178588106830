import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useLegalClientSaveInvoicePaymentConfirmationFilesMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';

import { parseLegalClient } from './helpers';

export const useLegalClientSaveInvoicePaymentConfirmationFiles = () => {
  const [saveInvoice, { data, loading, error: apolloError }] =
    useLegalClientSaveInvoicePaymentConfirmationFilesMutation();

  const error = useError(apolloError);

  return {
    data: data?.legalClientSaveInvoicePaymentConfirmationFiles
      ? parseLegalClient(data.legalClientSaveInvoicePaymentConfirmationFiles)
      : undefined,
    loading,
    error,
    saveInvoicePaymentConfirmationFiles: useCallback(
      async (id: string, fileIds: string[]) => {
        const res = await saveInvoice({
          variables: {
            id,
            fileIds,
          },
        });
        return res?.data?.legalClientSaveInvoicePaymentConfirmationFiles
          ? parseLegalClient(
              res.data.legalClientSaveInvoicePaymentConfirmationFiles
            )
          : undefined;
      },
      [saveInvoice]
    ),
  };
};
