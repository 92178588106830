// eslint-disable-next-line no-restricted-imports
import { LegalClientBranchFragment } from 'api/generated/graphql';
import { parseOnboardingProcessEntity } from 'api/requests/onboarding/helpers';
import { LegalClientBranchEntity } from 'api/types/entity';
import { lodash } from 'helpers';

import { parseLegalClientTaxResidency } from '../../helpers/parseLegalClientTaxResidency';

import { parseLegalClientBranchContacts } from './parseLegalClientBranchContacts';
import { parseLegalClientBranchStateRegistration } from './parseLegalClientBranchStateRegistration';

export const parseLegalClientBranchEntity = (
  data: LegalClientBranchFragment
): LegalClientBranchEntity => ({
  id: data.id,
  fullName: data.fullName,
  legalClientId: data.legalClientId || undefined,
  onboarding: data.onboarding
    ? {
        process: parseOnboardingProcessEntity(data.onboarding?.process),
      }
    : undefined,
  actualAddress: data.actualAddress,
  legalAddress: data.legalAddress,
  taxResidences: data.taxResidences
    ? lodash.compact(data.taxResidences).map(parseLegalClientTaxResidency)
    : undefined,
  stateRegistration: parseLegalClientBranchStateRegistration(
    data.stateRegistration
  ),
  contacts: parseLegalClientBranchContacts(data.contacts),
});
