type Enum = {
  [id: string]: string;
};

export class BaseStorage<K extends Enum, S extends K[keyof K]> {
  storage: Storage;

  keys: K;

  systemsKeys: S[];

  constructor(stotage: Storage, keys: K, systemsKeys: S[] = []) {
    this.storage = stotage;
    this.keys = keys;
    this.systemsKeys = systemsKeys;
  }

  getItem(key: keyof K) {
    return this.storage.getItem(key.toString());
  }

  setItem(key: keyof K, value: string) {
    return this.storage.setItem(key.toString(), value);
  }

  /**
   * Removes the key-value pair with the given key
   */
  removeItem(key: keyof K) {
    this.storage.removeItem(key.toString());
  }

  /**
   * Removes the key-value pair with the given keys
   */
  removeItems(keys: S[]) {
    keys.forEach((key) => {
      this.removeItem(key);
    });
  }

  /**
   * Sets or removes a key-value pair in local storage based on the value.
   */
  setOrRemoveItem(key: keyof K, value: string | undefined) {
    if (value) {
      this.setItem(key, value);
    } else {
      this.removeItem(key);
    }
  }

  /**
   * Removes all key-value pairs except for the system keys
   */
  clear() {
    const keysWithoutSystem = Object.keys(this.keys).filter(
      (key) => !this.systemsKeys.includes(key as S)
    ) as S[];

    this.removeItems(keysWithoutSystem);
  }
}
