import { WithdrawalAccountsFormType } from 'modules/onboarding/common/forms/withdrawalAccountsForm';
import { useClientWithdrawalAccounts } from 'modules/onboarding/common/hooks';

import { useOnboardingContext } from './useOnboardingContext';

export const useOwnerWithdrawalAccounts = () => {
  const { data, goToNext, goToBack } = useOnboardingContext();

  const {
    countries,
    countriesLoading,
    files,
    initialValues,
    onAddFiles,
    onRemoveFile,
    saveWithdrawalAccounts,
    submitDisabled,
    submitLoading,
  } = useClientWithdrawalAccounts(data.ownerClient!);

  const onSubmit = async (values: WithdrawalAccountsFormType) => {
    if (values) {
      await saveWithdrawalAccounts(values);
      goToNext();
    }
  };

  return {
    onSubmit,
    goToBack,
    files,
    onAddFiles,
    onRemoveFile,
    submitDisabled,
    submitLoading,
    initialValues,
    countries,
    countriesLoading,
  };
};
