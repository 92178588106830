import { LegalClientSaveLicenseInfoParam } from 'api/types/params';
import { formatToISODate } from 'helpers';
import { YesOrNoType } from 'types/yesOrNo';

import { CompanyLicenseFormType } from '../../forms/companyLicenseForm';

export const getSubmitData = (
  values: CompanyLicenseFormType
): LegalClientSaveLicenseInfoParam => {
  const isLicensed =
    values.companyActivityIsLicensed?.value === YesOrNoType.Yes;

  if (!isLicensed) {
    return { isLicensed };
  }
  return {
    isLicensed,
    licenses: values.licenses.map((license) => ({
      activityType: license.licensedActivityType!,
      issueDate: formatToISODate(license.dateOfIssue!),
      issuingAuthority: license.authorityName!,
      licenseNumber: license.licenseNumber!,
      expirationDate: license.dateOfExpiry
        ? formatToISODate(license.dateOfExpiry)
        : undefined,

      fileIds: license.fileIds!,
    })),
  };
};
