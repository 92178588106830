import { config } from 'config';

import { CompanyBeneficiaries } from '../components/CompanyBeneficiaries';
import { useBeneficiaries } from '../hooks';
import { PageComponentProps } from '../types';

export const CompanyBeneficiariesStep = ({ canGoBack }: PageComponentProps) => {
  const {
    submitLoading,
    beneficiaries,
    deleteLoading,
    errorBeneficiariesIds,
    initialValues,
    submitDisabled,
    files,
    onAddFiles,
    onRemoveFile,
    onAdd,
    onSubmit,
    goToBack,
    onEdit,
    onDelete,
  } = useBeneficiaries();

  return (
    <CompanyBeneficiaries
      beneficiaries={beneficiaries}
      canGoBack={canGoBack}
      deleteLoading={deleteLoading}
      documentSimpleHref={config.DOCUMENT_TEMPLATE_BENEFICIARY_URL ?? ''}
      errorBeneficiariesIds={errorBeneficiariesIds}
      files={files}
      initialValues={initialValues}
      submitDisabled={submitDisabled}
      submitLoading={submitLoading}
      onAdd={onAdd}
      onAddFiles={onAddFiles}
      onDelete={onDelete}
      onEdit={onEdit}
      onGoBack={goToBack}
      onRemoveFile={onRemoveFile}
      onSubmit={onSubmit}
    />
  );
};
