import { useCallback, useMemo } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useOnboardingLegalClientLazyQuery } from 'api/generated/graphql';
import { useError } from 'api/helpers';

import { parseLegalClient } from './helpers';

export const useOnboardingLegalClientLazy = () => {
  const [handle, { data, loading, error: apolloError, refetch }] =
    useOnboardingLegalClientLazyQuery({
      notifyOnNetworkStatusChange: true,
    });

  const getLegalClient = useCallback(
    async (id: string) => {
      const res = await handle({ variables: { id } });

      return res?.data?.onboardingLegalClient
        ? parseLegalClient(res.data.onboardingLegalClient)
        : null;
    },
    [handle]
  );

  const legalClient = useMemo(
    () =>
      data?.onboardingLegalClient
        ? parseLegalClient(data.onboardingLegalClient)
        : null,
    [data?.onboardingLegalClient]
  );

  const error = useError(apolloError);

  return {
    getLegalClient,
    refetch,
    legalClient,
    loading,
    error,
  };
};
