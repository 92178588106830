import { yesOrNotSelectValidation } from 'helpers';
import {
  ObjectSchema,
  TypedForm,
  FormErrors as FormErrorsBase,
  object,
} from 'libs/form';
import {
  getBaseDebtsSchema,
  BaseDebtsFormType,
} from 'modules/onboarding/common/forms/baseDebtsForm';
import { Maybe } from 'types/maybe';
import { YesOrNoSelectType } from 'types/yesOrNo';

export interface DebtsFormType extends BaseDebtsFormType {
  marginTrades: Maybe<YesOrNoSelectType>;
}

export const getSchema = (
  isEntrepreneur?: boolean
): ObjectSchema<DebtsFormType> =>
  object({
    marginTrades: yesOrNotSelectValidation.default(null).required(),
  }).concat(getBaseDebtsSchema(isEntrepreneur));

export const { Field, Form, useFormContext, Submit } =
  TypedForm<DebtsFormType>();

export type FormErrors = FormErrorsBase<DebtsFormType>;
