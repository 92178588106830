import { Body } from 'components';
import { getColor } from 'helpers';
import { styled } from 'libs/ui/styled';
import { ColorsDark, ColorsLight } from 'libs/ui/theme';

export const BodyStyled = styled(Body)({
  paddingTop: 16,
  paddingBottom: 16,
});

export const HelperTextBoxStyled = styled(Body)(({ theme }) => ({
  padding: '8px !important',
  marginBottom: 16,
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  border: `1px solid ${getColor(theme, {
    light: ColorsLight.blue150,
    dark: ColorsDark.blue150,
  })}`,
}));
