import { LegalClientSaveTaxResidencesParam } from 'api/types/params';
import { lodash } from 'helpers';

import { CompanyTaxResidencyFormType } from '../../forms/companyTaxResidencyForm';

export const getSubmitData = (
  values: CompanyTaxResidencyFormType
): LegalClientSaveTaxResidencesParam => {
  const result = {
    taxResidences: lodash.compact(
      values.taxValues.map((v) => ({
        taxId: v.taxNumber,
        country: v.country?.id!,
      }))
    ),
  };

  return result;
};
