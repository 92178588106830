import { useCallback, useMemo } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useLegalClientGetBeneficiaryLazyQuery } from 'api/generated/graphql';
import { useError } from 'api/helpers';

import { parseLegalClientBeneficiaryEntity } from './helpers';

export const useLegalClientGetBeneficiaryLazy = () => {
  const [query, { data, loading, error: apolloError }] =
    useLegalClientGetBeneficiaryLazyQuery();

  const error = useError(apolloError);

  const getBeneficiary = useCallback(
    async (legalId: string, beneficiaryId: string) => {
      const res = await query({ variables: { id: legalId, beneficiaryId } });
      if (res.data?.legalClientGetBeneficiary) {
        return parseLegalClientBeneficiaryEntity(
          res.data?.legalClientGetBeneficiary
        );
      }
      return null;
    },
    [query]
  );

  return {
    legalClientBeneficiary: useMemo(
      () =>
        data?.legalClientGetBeneficiary
          ? parseLegalClientBeneficiaryEntity(data.legalClientGetBeneficiary)
          : undefined,
      [data?.legalClientGetBeneficiary]
    ),
    loading,
    getBeneficiary,
    error,
  };
};
