import { useRef } from 'react';

import { useLegalClientSaveOwnershipStructureFiles } from 'api/requests';
import { FileUpload, useFilesUpload, useServerErrorNotify } from 'hooks';

import { CompanyConfirmStructureFormType } from '../forms/companyConfirmStructure';

import { useOnboardingContext } from './useOnboardingContext';

export const useCompanyConfirmStructure = () => {
  const { goToBack, goToNext, data } = useOnboardingContext();
  const { saveOwnershipStructureFiles, loading, error } =
    useLegalClientSaveOwnershipStructureFiles();

  const initialValues = useRef({
    fileIds: data.legalClient?.ownershipStructureFiles?.map((v) => v.id),
  }).current;

  const onSubmit = async (values: CompanyConfirmStructureFormType) => {
    if (values.fileIds?.length) {
      saveOwnershipStructureFiles(data.legalClient!.id, {
        ownershipStructureFileIds: values.fileIds,
      });
      goToNext();
    }
  };

  const filesUpload: FileUpload[] | undefined =
    data.legalClient!.ownershipStructureFiles?.map(
      (v) =>
        new FileUpload({ name: v.name, size: v.size }, undefined, false, v.id)
    );

  const {
    files,
    isLoadingFile: submitDisabled,
    onRemoveFile,
    onAddFiles,
  } = useFilesUpload(filesUpload);

  useServerErrorNotify(error);

  return {
    onSubmit,
    goToBack,
    submitLoading: loading,
    initialValues,
    submitDisabled,
    files,
    onAddFiles,
    onRemoveFile,
  };
};
