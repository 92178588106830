// eslint-disable-next-line no-restricted-imports
import { OnboardingProcessFragment } from 'api/generated/graphql';
import {
  OnboardingProcessEntity,
  OnboardingProcessStepNameType,
} from 'api/types/entity';
import { lodash } from 'helpers';

export const parseOnboardingProcessEntity = (
  process: OnboardingProcessFragment | undefined | null
): OnboardingProcessEntity | undefined =>
  process
    ? {
        currentStep: {
          isEditable: process.currentStep.isEditable,
          isEnabled: process.currentStep.isEnabled,
          name: process.currentStep
            .name as unknown as OnboardingProcessStepNameType,
        },
        previousSteps: lodash
          .compact(process.previousSteps)
          .map((previousStep) => ({
            isCompleted: previousStep.isCompleted,
            isEditable: previousStep.isEditable,
            isEnabled: previousStep.isEnabled,
            name: previousStep.name as unknown as OnboardingProcessStepNameType,
          })),
        totalStepCount: process.totalStepCount,
      }
    : undefined;
