import { FC } from 'react';

import { OnboardingStatus } from 'api/types/entity';
import { Link } from 'libs/ui';

import { OnboardingStatusWrapper } from '../OnboardingStatusWrapper';

export type OnboardingStatusDefaultProps = {
  onboardingStatus: OnboardingStatus;
  email: string;
  text: string;
  statusText: string;
  title: string;
};

export const OnboardingStatusDefault: FC<OnboardingStatusDefaultProps> = ({
  onboardingStatus,
  email,
  text,
  statusText,
  title,
}) => (
  <OnboardingStatusWrapper
    onboardingStatus={onboardingStatus}
    statusText={statusText}
    title={title}
  >
    {text}{' '}
    <Link href={`mailto: ${email}`} mode="text">
      {email}
    </Link>
    .
  </OnboardingStatusWrapper>
);
