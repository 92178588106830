import { Hint } from 'components';
import { useTranslation } from 'libs/i18n';
import { ListTypography, Stack, Typography } from 'libs/ui';

interface Props {
  subTitle?: string;
}

export const SubTitle = ({ subTitle }: Props) => {
  const { t } = useTranslation();

  return (
    <Stack alignItems="center" direction="row" spacing={8}>
      <Typography>
        {subTitle ?? t('onboarding.pepConnection.subtitle')}
      </Typography>
      <Hint
        title={
          <Stack spacing={8}>
            <Typography variant="subtitle" variantMobile="bodyBold">
              {t('onboarding.pepConnection.hint.title')}
            </Typography>
            <ListTypography
              listType="ul"
              texts={t('onboarding.pepConnection.hint.texts', {
                returnObjects: true,
              })}
              variant="caption"
            />
          </Stack>
        }
      />
    </Stack>
  );
};
