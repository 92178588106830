import { OrganizationType } from 'api/types/entity';
import { getYesOrNoOptions } from 'helpers';
import { useTranslation } from 'libs/i18n';
import { Stack } from 'libs/ui';
import { OrganizationTypePath } from 'modules/onboarding/legal/helpers/organizationalAndLegalForm/getInitialData';

import {
  Field,
  isCommercial,
  useFormContext,
} from '../../../forms/organizationalAndLegalFormForm';

export const FormContent = () => {
  const { values } = useFormContext();

  const { t } = useTranslation();

  return (
    <Stack spacing={32}>
      <Field.Autocomplete
        label={t(
          'onboarding.organizationalAndLegalForm.organizationType.label'
        )}
        name="organizationType"
        optionLabelKey="label"
        options={Object.values(OrganizationType).map((v) => ({
          label: t(OrganizationTypePath[v]),
          value: v,
        }))}
        optionValueKey="value"
        placeholder={t(
          'onboarding.organizationalAndLegalForm.organizationType.placeholder'
        )}
        variant="select"
      />

      {isCommercial(values.organizationType) && (
        <>
          <Field.Autocomplete
            label={t(
              'onboarding.organizationalAndLegalForm.isFinancialInstitution.label'
            )}
            name="isFinancialInstitution"
            optionLabelKey="label"
            options={getYesOrNoOptions(t)}
            optionValueKey="value"
            placeholder={t('common.select.placeholder')}
            variant="select"
          />
          <Field.Autocomplete
            label={t(
              'onboarding.organizationalAndLegalForm.isUnincorporatedStructure.label'
            )}
            name="isUnincorporatedStructure"
            optionLabelKey="label"
            options={getYesOrNoOptions(t)}
            optionValueKey="value"
            placeholder={t('common.select.placeholder')}
            variant="select"
          />
        </>
      )}

      <Field.Text
        label={t(
          'onboarding.organizationalAndLegalForm.organizationalAndLegalForm.label'
        )}
        name="organizationalAndLegalForm"
        placeholder={t(
          'onboarding.organizationalAndLegalForm.organizationalAndLegalForm.placeholder'
        )}
      />
    </Stack>
  );
};
