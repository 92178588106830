import { styled } from '../styled';

import { BG_COLORS, TEXT_COLORS } from './styles/colors';
import { CHIP_SIZES } from './styles/sizes';
import { ChipStyledType } from './types';

export const ChipStyled = styled('div')<ChipStyledType>(
  ({ theme, variant, uppercase, size = 'medium' }) => ({
    width: 'fit-content',
    textTransform: uppercase ? 'uppercase' : 'none',
    backgroundColor: BG_COLORS[variant][theme.palette.mode],
    borderRadius: 40,

    '.chip-text': {
      color: TEXT_COLORS[variant][theme.palette.mode],
      ...theme.typography.bodySemiBold,
      [theme.breakpoints.down('mobile')]: {
        ...theme.typography.captionSemiBold,
      },
    },

    ...CHIP_SIZES[size],
  })
);
