import { useRef, useState } from 'react';

import { useLegalClientBranchSaveName } from 'api/requests/onboarding/legal/branch';
import { useServerErrorNotify } from 'hooks';
import { useNotify } from 'libs/notify';

import { NameFormType } from '../forms/nameForm';
import { getInitialData } from '../helpers/name';

import { useOnboardingContext } from './useOnboardingContext';

export const useName = () => {
  const { goToBack, goToNext, data } = useOnboardingContext();

  const [submitLoading, setSubmitLoading] = useState(false);
  const notify = useNotify();

  const {
    saveName,
    loading: submitNameLoading,
    error: errorName,
  } = useLegalClientBranchSaveName();

  const initialValues = useRef(getInitialData(data.legalClientBranch)).current;

  const onSubmit = async (values: NameFormType) => {
    if (values) {
      if (!data.legalClientBranch) {
        setSubmitLoading(true);
        try {
          if (await data.onCreateBranch(values)) {
            goToNext();
          }
        } catch (error) {
          notify.error(error);
        } finally {
          setSubmitLoading(false);
        }
      } else {
        const branch = await saveName(
          data.legalClient.id,
          data.legalClientBranch.id,
          values
        );
        if (branch) {
          goToNext();
        }
      }
    }
  };

  useServerErrorNotify(errorName);

  return {
    submitLoading: submitLoading || submitNameLoading,
    initialValues,
    goToBack,
    onSubmit,
  };
};
