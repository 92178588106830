import { FormContainerStepProps } from 'components';
import { useTranslation } from 'libs/i18n';
import {
  ClientType,
  FormBodyWrapper,
} from 'modules/onboarding/common/components';

import {
  StateRegistrationFormType,
  FormErrors,
  getSchema,
} from '../../forms/stateRegistrationForm';

import { FormContent, FormContentProps } from './FormContent';

interface Props extends FormContentProps, FormContainerStepProps {
  initialValues?: StateRegistrationFormType;
  onSubmit: (values: StateRegistrationFormType) => void;
  canGoBack?: boolean;
  onGoBack?: () => void;
  formError?: FormErrors;
  submitLoading?: boolean;
  clientType?: ClientType;
}

export const StateRegistration = ({
  initialValues,
  canGoBack,
  submitLoading,
  formError,
  step,
  clientType,
  onGoBack,
  onSubmit,
}: Props) => {
  const { t } = useTranslation();
  const schema = getSchema(t);
  return (
    <FormBodyWrapper
      canGoBack={canGoBack}
      clientType={clientType}
      initialErrors={formError}
      initialValues={initialValues}
      step={step}
      submitLoading={submitLoading}
      subTitle={t('onboarding.legalClientBranch.stateRegistration.subtitle')}
      title={t('onboarding.companyStateRegistration.title')}
      validationSchema={schema}
      onGoBack={onGoBack}
      onSubmit={onSubmit}
    >
      <FormContent />
    </FormBodyWrapper>
  );
};
