import {
  AmlPolicyReviewFrequency,
  LegalClientFinancialMonitoringEntity,
  LegalClientFinancialMonitoringInfoEntity,
  LegalClientFinancialMonitoringInfoOfficerEntity,
} from 'api/types/entity';
import { Maybe } from 'types/maybe';
import { YesOrNoType } from 'types/yesOrNo';

import { CompanyFinancialMonitoringFormType } from '../../forms/companyFinancialMonitoringForm';

export const getSubmitData = (
  values: CompanyFinancialMonitoringFormType
): LegalClientFinancialMonitoringEntity => {
  const isSubjectToRegulation =
    values.isSubjectToRegulation?.value === YesOrNoType.Yes;

  if (!isSubjectToRegulation) {
    return { isSubjectToRegulation };
  }

  const hasAmlCftInternalControlOfficer =
    values.hasAmlCftInternalControlOfficer?.value === YesOrNoType.Yes;

  const officerInfo:
    | Maybe<LegalClientFinancialMonitoringInfoOfficerEntity>
    | undefined = hasAmlCftInternalControlOfficer
    ? {
        email: values.officerEmail!,
        firstName: values.officerFirstName!,
        lastName: values.officerLastName!,
        phoneNumber: values.officerPhoneNumber!,
        position: values.officerPosition!,
        middleName: values.officerMiddleName,
      }
    : undefined;

  const frequencyReviewPolice = values.amlPolicyReviewFrequency?.value;

  const otherAmlPolicyReviewFrequencyField: Pick<
    LegalClientFinancialMonitoringInfoEntity,
    'otherAmlPolicyReviewFrequency'
  > =
    frequencyReviewPolice === AmlPolicyReviewFrequency.Other
      ? { otherAmlPolicyReviewFrequency: values.otherAmlPolicyReviewFrequency }
      : {};

  const hasAmlCftDocumentEffectivenessAssessmentProcedures =
    values.hasAmlCftDocumentEffectivenessAssessmentProcedures?.value ===
    YesOrNoType.Yes;

  const documentProvisionRefusalReasonField: Pick<
    LegalClientFinancialMonitoringInfoEntity,
    'documentProvisionRefusalReason'
  > =
    values.canProvideAmlPolicyDocuments?.value === YesOrNoType.No
      ? {
          documentProvisionRefusalReason: values.documentProvisionRefusalReason,
        }
      : {};

  const hadAmlCftInvestigationsOrPenalties =
    values.hadAmlCftInvestigationsOrPenalties?.value === YesOrNoType.Yes;
  const investigationOrPenaltyReasonsField: Pick<
    LegalClientFinancialMonitoringInfoEntity,
    'investigationOrPenaltyReasons'
  > = hadAmlCftInvestigationsOrPenalties
    ? { investigationOrPenaltyReasons: values.investigationOrPenaltyReasons }
    : {};

  const hasMandatoryControlTransactions =
    values.hasMandatoryControlTransactions?.value === YesOrNoType.Yes;
  const mandatoryControlTransactionIdentificationProcedureField: Pick<
    LegalClientFinancialMonitoringInfoEntity,
    'mandatoryControlTransactionIdentificationProcedure'
  > = hasMandatoryControlTransactions
    ? {
        mandatoryControlTransactionIdentificationProcedure:
          values.mandatoryControlTransactionIdentificationProcedure,
      }
    : {};

  return {
    isSubjectToRegulation,
    info: {
      authorityName: values.authorityName!,
      authorityWebsite: values.authorityWebsite,
      hasAmlCftComplianceControl:
        values.hasAmlCftComplianceControl?.value === YesOrNoType.Yes,
      hasTransactionMonitoringAndSuspiciousActivityDetection:
        values.hasTransactionMonitoringAndSuspiciousActivityDetection?.value ===
        YesOrNoType.Yes,
      amlPolicyReviewFrequency: values.amlPolicyReviewFrequency?.value!,
      ...otherAmlPolicyReviewFrequencyField,
      amlCftPoliciesApplyToForeignEntities:
        values.amlCftPoliciesApplyToForeignEntities?.value === YesOrNoType.Yes,
      hasAmlCftDocumentEffectivenessAssessmentProcedures,
      dateOfLastCheck: values.dateOfLastCheck,
      hasAmlCftInternalControlOfficer,
      officer: officerInfo,
      canProvideAmlPolicyDocuments:
        values.canProvideAmlPolicyDocuments?.value === YesOrNoType.Yes,
      ...documentProvisionRefusalReasonField,
      isInternalControlInfoConfidential:
        values.isInternalControlInfoConfidential?.value === YesOrNoType.Yes,
      hadAmlCftInvestigationsOrPenalties,
      ...investigationOrPenaltyReasonsField,
      hasMandatoryControlTransactions,
      ...mandatoryControlTransactionIdentificationProcedureField,
      monetaryServiceTransactionCountForLastSixMonths:
        values.monetaryServiceTransactionCountForLastSixMonths?.value!,
      isAffiliatedWithNonProfitOrganizations:
        values.isAffiliatedWithNonProfitOrganizations?.value ===
        YesOrNoType.Yes,
      receivesFundingFromStateBudget:
        values.receivesFundingFromStateBudget?.value === YesOrNoType.Yes,
      receivesFundingFromForeignNonProfitNGOs:
        values.receivesFundingFromForeignNonProfitNGOs?.value ===
        YesOrNoType.Yes,
      isCompliantWithFATFRecommendations:
        values.isCompliantWithFATFRecommendations?.value === YesOrNoType.Yes,
      hasBeenRefusedToOpenAccountByBanks:
        values.hasBeenRefusedToOpenAccountByBanks?.value === YesOrNoType.Yes,
    },
  };
};
