import { lodash } from './lodash';

/**
 * Adds spaces to a string every three characters for better readability.
 */
function addSpaces(str: string) {
  return lodash
    .chunk(str.split('').reverse(), 3)
    .map((part) => part.join(''))
    .join(' ')
    .split('')
    .reverse()
    .join('');
}

/**
 * Formats a floating-point number into a string representation.
 * @param value - The number to format.
 * @param formatInt - Function to format the integer part of the number.
 */
export const formatFloat = (
  value?: number,
  formatInt?: (value: string) => string
): string => {
  if (value === undefined) return '';
  const price = `${value}`.substring(0, 13);
  const [integer, floating] = price.split('.');
  const integerFmt = formatInt?.(integer) || integer;
  const floatFmt = floating?.slice(0, 2);
  return [integerFmt, floatFmt].filter(Boolean).join(',');
};

/**
 * Parses a string representation of a floating-point number into a JavaScript number.
 */
export const parseFloat = (value?: string): number => {
  if (value === undefined || value === '') return 0;
  return Number(value.replace(/\s+/g, '').replace(',', '.'));
};

/**
 * Parses a string representation of a price into a number (multiplied by 100).
 */
export const parsePrice = (value?: string): number | undefined => {
  if (value === '') return undefined;
  return parseFloat(value) * 100;
};

/**
 * Formats a number into a string representation of a price.
 * @param value - The number to format.
 * @param round - Determines whether to round the price.
 */
export const formatPrice = (value?: number, round = false): string => {
  if (value === undefined || Number.isNaN(value)) return '';
  const price = round ? Math.round(value / 100) : value / 100;
  return formatFloat(price, addSpaces);
};
