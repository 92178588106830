import { useRef, useState } from 'react';

import { UserType } from 'api/types/entity';
import { ContentStepperStep } from 'components';
import { useUser } from 'libs/providers';

import {
  RegistrationEmailStep,
  RegistrationEmailLinkConfirmStep,
  RegistrationPasswordActivateStep,
  RegistrationPasswordStep,
  RegistrationPersonalDataStep,
  RegistrationTwoFactorStep,
} from '../steps';
import { RegistrationType } from '../types';

import { useRegistrationUrlData } from './useRegistrationUrlData';

enum Pages {
  email,
  personalData,
  password,
  twoFactor,
}

const routes: Pages[] = [
  Pages.email,
  Pages.personalData,
  Pages.password,
  Pages.twoFactor,
];

const mapper: Record<Pages, ContentStepperStep<any>> = {
  [Pages.email]: { component: RegistrationEmailStep, canGoBack: true },
  [Pages.personalData]: {
    component: RegistrationPersonalDataStep,
    canGoBack: false,
  },
  [Pages.password]: { component: RegistrationPasswordStep, canGoBack: true },
  [Pages.twoFactor]: { component: RegistrationTwoFactorStep, canGoBack: false },
};

const findIndex = (page: Pages) => routes.findIndex((v) => v === page);

export const useSteps = () => {
  const urlData = useRegistrationUrlData();

  const [registrationType, setRegistrationType] = useState<RegistrationType>(
    urlData?.type ?? RegistrationType.personal
  );
  const { user } = useUser();

  const [needSelectType, setNeedSelectType] = useState(
    !(!!urlData?.type || !!user)
  );

  const steps = routes.map((v) => mapper[v]);

  let initialActiveIndex = 0;
  let needAgreements = !user;
  const hideStepper = useRef(false);

  if (user) {
    if (user.isPasswordUpdateRequired) {
      needAgreements = true;
      hideStepper.current = true;
      // show password step
      initialActiveIndex = findIndex(Pages.password);

      steps[initialActiveIndex].component = RegistrationPasswordActivateStep;
      steps[initialActiveIndex].canGoBack = false;
    } else if (!user.otp?.verified) {
      // show twoFactor step
      initialActiveIndex = findIndex(Pages.twoFactor);
    }
  } else if (urlData) {
    needAgreements = false;
    steps[0].component = RegistrationEmailLinkConfirmStep;
    steps[0].data = urlData;
  }

  const userRegistrationType =
    user && user.type !== UserType.Client
      ? RegistrationType.legal
      : registrationType;

  const handleChangeRegistrationType = (type: RegistrationType) => {
    setRegistrationType(type);
    setNeedSelectType(false);
  };

  return {
    steps,
    initialActiveIndex,
    needAgreements,
    agreementIds: urlData?.agreementIds,
    registrationType: userRegistrationType,
    needSelectType,
    hideStepper: hideStepper.current,
    onSelectPersonal: () =>
      handleChangeRegistrationType(RegistrationType.personal),
    onSelectLegal: () => handleChangeRegistrationType(RegistrationType.legal),
    setNeedSelectType,
  };
};
