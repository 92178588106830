import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useRestorePasswordMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { RestorePasswordParam } from 'api/types/params';

export const useRestorePassword = () => {
  const [handle, { data, loading, error: apolloError }] =
    useRestorePasswordMutation();

  const error = useError(apolloError);

  return {
    data: data?.restorePassword,
    loading,
    error,
    restorePassword: useCallback(
      async (input: RestorePasswordParam) => {
        const res = await handle({ variables: { input } });
        return res.data?.restorePassword;
      },
      [handle]
    ),
  };
};
