import {
  TypedForm,
  FormErrors as FormErrorsBase,
  ObjectSchema,
  string,
  object,
  email,
  boolean,
} from 'libs/form';
import { TFunc } from 'libs/i18n';

export interface LoginFormType {
  email: string;
  password: string;
  rememberMe?: boolean;
}

export const getSchema = (t: TFunc): ObjectSchema<LoginFormType> =>
  object({
    email: email(t).required(),
    password: string().required(),
    rememberMe: boolean(),
  });

export const { Field, Form, useFormContext, Submit } =
  TypedForm<LoginFormType>();

export type FormErrors = FormErrorsBase<LoginFormType>;
