import { FormContainerStepProps } from 'components';
import { useTranslation } from 'libs/i18n';

import {
  PersonalDataFormType,
  FormErrors,
  getSchema,
  Field,
  dateOfBirthMinDate,
  dateOfBirthMaxDate,
} from '../../forms/personalDataForm';
import { ClientType, FormBodyWrapper } from '../FormBodyWrapper';

interface Props extends FormContainerStepProps {
  initialValues?: PersonalDataFormType;
  canGoBack?: boolean;
  formError?: FormErrors;
  submitLoading?: boolean;
  submitDisabled?: boolean;
  clientType?: ClientType;
  subTitle?: string;
  onSubmit: (values: PersonalDataFormType) => void;
  onGoBack?: () => void;
}

export const PersonalData = ({
  initialValues,
  canGoBack,
  submitLoading,
  submitDisabled,
  formError,
  clientType,
  step,
  subTitle,
  onGoBack,
  onSubmit,
}: Props) => {
  const { t } = useTranslation();
  const scheme = getSchema(t);

  return (
    <FormBodyWrapper
      canGoBack={canGoBack}
      clientType={clientType}
      initialErrors={formError}
      initialValues={initialValues}
      step={step}
      submitDisabled={submitDisabled}
      submitLoading={submitLoading}
      subTitle={subTitle ?? t('onboarding.personalData.subtitle')}
      title={t('onboarding.personalData.title')}
      validationSchema={scheme}
      onGoBack={onGoBack}
      onSubmit={onSubmit}
    >
      <Field.Text
        fullWidth
        autoComplete="on"
        label={t('components.emailForm.email.label')}
        name="email"
        placeholder={t('components.emailForm.email.placeholder')}
        type="email"
      />

      <Field.Text
        fullWidth
        label={t('onboarding.personalData.firstName.label')}
        name="firstName"
        placeholder={t('onboarding.personalData.firstname.placeholder')}
        trim="start"
      />
      <Field.Text
        fullWidth
        label={t('onboarding.personalData.lastname.label')}
        name="lastName"
        placeholder={t('onboarding.personalData.lastname.placeholder')}
        trim="start"
      />
      <Field.Text
        fullWidth
        showOptionalMark
        label={t('onboarding.personalData.surname.label')}
        name="middleName"
        placeholder={t('onboarding.personalData.surname.placeholder')}
        trim="start"
      />
      <Field.DatePicker
        label={t('onboarding.personalData.dateOfBirth.label')}
        maxDate={dateOfBirthMaxDate}
        minDate={dateOfBirthMinDate}
        name="dateOfBirth"
        placeholder={t('onboarding.personalData.dateOfBirth.placeholder')}
      />
    </FormBodyWrapper>
  );
};
