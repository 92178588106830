import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useClientSaveSignaturesCardFilesMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { ClientSaveSignaturesCardFilesParam } from 'api/types/params';

import { parseClientEntity } from '../helpers';

export const useClientSaveSignaturesCardFiles = () => {
  const [saveSignatures, { data, loading, error: apolloError }] =
    useClientSaveSignaturesCardFilesMutation();

  const error = useError(apolloError);

  return {
    data: data?.clientSaveSignaturesCardFiles
      ? parseClientEntity(data.clientSaveSignaturesCardFiles)
      : undefined,
    loading,
    error,
    saveSignatures: useCallback(
      async (id: string, params: ClientSaveSignaturesCardFilesParam) => {
        const res = await saveSignatures({
          variables: { id, input: params },
        });
        return res.data?.clientSaveSignaturesCardFiles
          ? parseClientEntity(res.data.clientSaveSignaturesCardFiles)
          : undefined;
      },
      [saveSignatures]
    ),
  };
};
