import { OrderSubmissionContactType } from 'api/types/entity';
import { isValidNumber } from 'libphonenumber-js';
import { TypedForm, FormErrors as FormErrorsBase, email } from 'libs/form';
import { TFunc } from 'libs/i18n';
import { SelectType } from 'types/selectType';
import { ObjectSchema, string, object, mixed, array } from 'yup';

export interface OrderSubmissionContacts {
  type: SelectType<OrderSubmissionContactType>;
  value: string;
}

export type OrderSubmissionContactFormTypeOption =
  SelectType<OrderSubmissionContactType>;

export interface OrderSubmissionContactsFormType {
  orderSubmissionContacts: OrderSubmissionContacts[];
}

export const initialOrderSubmissionContactType: OrderSubmissionContactFormTypeOption =
  {
    label: 'WhatsApp',
    value: OrderSubmissionContactType.WhatsApp,
  };

export const getTypes = (t: TFunc): OrderSubmissionContactFormTypeOption[] => [
  initialOrderSubmissionContactType,
  {
    label: 'Email',
    value: OrderSubmissionContactType.Email,
  },
  {
    label: 'Telegram',
    value: OrderSubmissionContactType.Telegram,
  },
  {
    label: t('onboarding.orderSubmissionContacts.phone.label'),
    value: OrderSubmissionContactType.Phone,
  },
];

export const getPlaceholderByType = (
  t: TFunc,
  contactType: OrderSubmissionContactType
): string => {
  const contactPlaceholderByType: Record<OrderSubmissionContactType, string> = {
    [OrderSubmissionContactType.Email]: t(
      'onboarding.orderSubmissionContacts.contactEmail.placeholder'
    ),
    [OrderSubmissionContactType.Phone]: t(
      'onboarding.orderSubmissionContacts.contactPhoneNumber.placeholder'
    ),
    [OrderSubmissionContactType.WhatsApp]: t(
      'onboarding.orderSubmissionContacts.contactPhoneNumber.placeholder'
    ),
    [OrderSubmissionContactType.Telegram]: t(
      'onboarding.orderSubmissionContacts.contact.placeholder'
    ),
  };

  return contactPlaceholderByType[contactType];
};

export const getOrderSubmissionContactSchema = (
  t: TFunc
): ObjectSchema<OrderSubmissionContacts> =>
  object({
    value: string()
      .when('type', {
        is: (t: OrderSubmissionContactFormTypeOption) =>
          t.value === OrderSubmissionContactType.Phone ||
          t.value === OrderSubmissionContactType.WhatsApp,
        then: (schema) =>
          schema
            .test('value', t('validation.phone'), (value) => {
              if (!value) return true;
              return isValidNumber(value);
            })
            .max(20),
      })
      .when('type', {
        is: (t: OrderSubmissionContactFormTypeOption) =>
          t.value === OrderSubmissionContactType.Telegram,
        then: (schema) => schema.min(1).max(200),
      })
      .when('type', {
        is: (t: OrderSubmissionContactFormTypeOption) =>
          t.value === OrderSubmissionContactType.Email,
        then: () => email(t).required(),
      })
      .required(),
    type: object({
      label: string().required(),
      value: mixed<OrderSubmissionContactType>()
        .oneOf(Object.values(OrderSubmissionContactType))
        .required(),
    }).required(),
  });

export const getSchema = (
  t: TFunc
): ObjectSchema<OrderSubmissionContactsFormType> =>
  object({
    orderSubmissionContacts: array()
      .of(getOrderSubmissionContactSchema(t))
      .min(1, t('validation.required'))
      .required(),
  });

export const { Field, Form, useFormContext, Submit } =
  TypedForm<OrderSubmissionContactsFormType>();

export type FormErrors = FormErrorsBase<OrderSubmissionContactsFormType>;
