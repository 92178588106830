// eslint-disable-next-line no-restricted-imports
import { LegalContactDto } from 'api/generated/graphql';
import {
  LegalClientOrderSubmissionContactEntity,
  OrderSubmissionContactType,
} from 'api/types/entity';
import { lodash } from 'helpers';
import { Maybe } from 'types/maybe';

export const parseLegalClientOrderSubmissionContactEntity = ({
  type,
  middleName,
  ...orderSubmissionContactOtherFields
}: LegalContactDto): LegalClientOrderSubmissionContactEntity => ({
  ...orderSubmissionContactOtherFields,
  middleName: middleName ?? undefined,
  type: OrderSubmissionContactType[type],
});

export const parseLegalClientOrderSubmissionContactsEntity = (
  orderSubmissionContacts?: Maybe<Array<Maybe<LegalContactDto>>>
): LegalClientOrderSubmissionContactEntity[] | undefined =>
  orderSubmissionContacts
    ? lodash
        .compact(orderSubmissionContacts)
        .map(parseLegalClientOrderSubmissionContactEntity)
    : undefined;
