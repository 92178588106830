import { ClientType } from 'modules/onboarding/common/components';

import { CompanyConstituentDocs } from '../../components';
import { useLegalRepresentativeCompanyConstituentDocs } from '../../hooks';
import { PageComponentProps } from '../../types';

export const LegalRepresentativeCompanyConstituentDocsStep = ({
  canGoBack,
}: PageComponentProps) => {
  const {
    files,
    initialValues,
    onAddFiles,
    onRemoveFile,
    onSubmit,
    submitDisabled,
    submitLoading,
    goToBack,
  } = useLegalRepresentativeCompanyConstituentDocs();

  return (
    <CompanyConstituentDocs
      canGoBack={canGoBack}
      clientType={ClientType.ClientRepresentative}
      files={files}
      initialValues={initialValues}
      submitDisabled={submitDisabled}
      submitLoading={submitLoading}
      onAddFiles={onAddFiles}
      onGoBack={goToBack}
      onRemoveFile={onRemoveFile}
      onSubmit={onSubmit}
    />
  );
};
