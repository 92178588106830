import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import {
  MutationInput_UserController_SendVerificationCode_Input_Type as RegistrationTypeApi,
  useRegistrationEmailSendCodeMutation,
} from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { RegistrationType } from 'modules/auth/registration/types';

const registrationTypes: Record<RegistrationType, RegistrationTypeApi> = {
  [RegistrationType.legal]: RegistrationTypeApi.LegalClientRepresentative,
  [RegistrationType.personal]: RegistrationTypeApi.Client,
};

export const useRegistrationEmailSendCode = () => {
  const [handle, { data, error: apolloError, loading }] =
    useRegistrationEmailSendCodeMutation();

  const error = useError(apolloError);

  return {
    data: { resendTime: data?.UserController_sendVerificationCode?.resendTime },
    error,
    loading,
    sendCode: useCallback(
      async (
        email: string,
        registrationType: RegistrationType,
        agreementIds: string[]
      ) => {
        const res = await handle({
          variables: {
            input: {
              email,
              type: registrationTypes[registrationType],
              agreementIds,
            },
          },
        });
        return {
          resendTime: res.data?.UserController_sendVerificationCode?.resendTime,
        };
      },
      [handle]
    ),
  };
};
