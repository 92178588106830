import { DialogContent } from 'components';
import { useTranslation } from 'libs/i18n';
import {
  Button,
  Dialog,
  DialogStateProps,
  Pressed,
  Stack,
  Typography,
} from 'libs/ui';

import { Item } from './Item';
import { ContentStyled, LineStyled } from './styles';

interface Props extends DialogStateProps {
  onClickPerson: () => void;
  onClickLegal: () => void;
}

export const HeadAddDialog = ({
  isOpen,
  onClose,
  onClickPerson,
  onClickLegal,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Dialog fullWidth isOpen={isOpen} maxWidth="lg" onClose={onClose}>
      <DialogContent>
        <Stack spacing={48} spacingMobile={32}>
          <Typography align="center" variant="title" variantMobile="subtitle">
            {t('onboarding.legalClientHeads.addDialog.title')}
          </Typography>
          <ContentStyled>
            <Stack spacing={24}>
              <Pressed datax="headsDialog-addPerson" onClick={onClickPerson}>
                <Item
                  title={t('onboarding.legalClientHeads.addDialog.person')}
                />
              </Pressed>
              <LineStyled />
              <Pressed datax="headsDialog-addLegal" onClick={onClickLegal}>
                <Item
                  title={t('onboarding.legalClientHeads.addDialog.legal')}
                />
              </Pressed>
            </Stack>
          </ContentStyled>
          <Button label={t('common.back')} mode="outlined" onClick={onClose} />
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
