import { RecursiveTransform, Route, DeepReadonly } from '../types';

/**
 * Replaces trailing slashes in a path with a single slash.
 * @param {string} path - The path to process.
 */
const replaceTrailingSlash = (path: string): string =>
  path.replace(/\/\//g, '/');

/**
 * Transforms a flat list of routes into a nested object structure.
 * @template T - The type of the route object.
 * @param {DeepReadonly<T>} routes - The flat list of routes.
 * @returns {RecursiveTransform<T>} - The transformed nested object structure.
 */
export const transformRoutes = <T extends DeepReadonly<Route>>(
  routes: T
): RecursiveTransform<T> => {
  /**
   * Recursively traverse and transform routes into a nested object structure.
   * @param {DeepReadonly<Route>} current - The current route to process.
   * @param {string} fullPath - The full path of the current route.
   * @returns {RecursiveTransform<T>} - The transformed nested object structure.
   */
  const traverse = (
    current: DeepReadonly<Route>,
    fullPath: string = current.path!
  ): RecursiveTransform<T> => {
    const { path, children, id } = current;
    if (!children) {
      return {
        [id!]: {
          id,
          path,
          fullPath: replaceTrailingSlash(fullPath) || '/',
        },
      } as RecursiveTransform<T>;
    }

    return {
      [id!]: children
        .map((route) => traverse(route, `${fullPath}/${route.path ?? ''}`))
        .reduce(
          (previousValue, currentValue) => ({
            ...previousValue,
            ...currentValue,
          }),
          {
            path,
            fullPath: replaceTrailingSlash(fullPath) || '/',
          }
        ),
    } as RecursiveTransform<T>;
  };
  return traverse(routes) as RecursiveTransform<T>;
};
