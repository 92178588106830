import { LegalClientContactEntity } from 'api/types/entity';
import { YesOrNoType } from 'types/yesOrNo';

import { CompanyContactsFormType } from '../../forms/companyContactsForm';

export const getSubmitData = (
  values: CompanyContactsFormType
): LegalClientContactEntity => {
  const isInformationDisclosureCompliant =
    values.disclosureInformation?.value === YesOrNoType.Yes;
  return {
    isInformationDisclosureCompliant,
    email: values.email ?? undefined,
    informationDisclosureCompliantResource:
      isInformationDisclosureCompliant && values.disclosureInformationResource
        ? values.disclosureInformationResource
        : undefined,
    phoneNumber: values.phone ?? undefined,
    website: values.site ?? undefined,
  };
};
