import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useLegalClientSaveUnidentifiableBeneficiaryLetterFilesMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { LegalClientSaveUnidentifiableBeneficiaryLetterFilesParam } from 'api/types/params';

import { parseLegalClient } from './helpers';

export const useLegalClientSaveUnidentifiableBeneficiaryLetterFiles = () => {
  const [saveSignatures, { data, loading, error: apolloError }] =
    useLegalClientSaveUnidentifiableBeneficiaryLetterFilesMutation();

  const error = useError(apolloError);

  return {
    data: data?.legalClientSaveUnidentifiableBeneficiaryLetterFiles
      ? parseLegalClient(
          data.legalClientSaveUnidentifiableBeneficiaryLetterFiles
        )
      : undefined,
    loading,
    error,
    saveUnidentifiableBeneficiaryLetterFiles: useCallback(
      async (
        id: string,
        params: LegalClientSaveUnidentifiableBeneficiaryLetterFilesParam
      ) => {
        const res = await saveSignatures({
          variables: { id, input: params },
        });
        return res.data?.legalClientSaveUnidentifiableBeneficiaryLetterFiles
          ? parseLegalClient(
              res.data.legalClientSaveUnidentifiableBeneficiaryLetterFiles
            )
          : undefined;
      },
      [saveSignatures]
    ),
  };
};
