import { useCallback, useState } from 'react';

export const useDialog = (initialOpen = false) => {
  const [isOpen, setOpen] = useState(initialOpen);

  const onClose = useCallback(() => {
    setOpen(false);
  }, []);

  const open = useCallback(() => {
    setOpen(true);
  }, []);

  return {
    isOpen,
    onClose,
    open,
  };
};
