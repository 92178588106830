import { useTranslation } from 'libs/i18n';

import {
  InvoiceForPaymentFormType,
  FormErrors,
  getSchema,
} from '../../forms/invoiceForPayment';
import { ClientType, FormBodyWrapper } from '../FormBodyWrapper';

import { FormContent } from './FormContent';

interface Props {
  initialValues?: InvoiceForPaymentFormType;
  onSubmit: (values: InvoiceForPaymentFormType) => void;
  canGoBack?: boolean;
  onGoBack?: () => void;
  formError?: FormErrors;
  submitLoading?: boolean;
  submitDisabled?: boolean;
  clientType?: ClientType;
}

export const InvoiceForPayment = ({
  initialValues,
  canGoBack,
  submitLoading,
  submitDisabled,
  formError,
  onGoBack,
  onSubmit,
  clientType,
}: Props) => {
  const { t } = useTranslation();
  const schema = getSchema();
  return (
    <FormBodyWrapper
      canGoBack={canGoBack}
      clientType={clientType}
      initialErrors={formError}
      initialValues={initialValues}
      submitDisabled={submitDisabled}
      submitLabel={t('onboarding.invoiceForPayment.submitButtonTitle')}
      submitLoading={submitLoading}
      subTitle={t('onboarding.invoiceForPayment.subtitle')}
      title={t('onboarding.invoiceForPayment.title')}
      validationSchema={schema}
      onGoBack={onGoBack}
      onSubmit={onSubmit}
    >
      <FormContent />
    </FormBodyWrapper>
  );
};
