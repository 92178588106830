import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useLogoutMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';

export const useLogout = () => {
  const [handle, { data, loading, error: apolloError }] = useLogoutMutation();

  const error = useError(apolloError, false);

  return {
    data,
    loading,
    error,
    logout: useCallback(async () => {
      await handle({ variables: {} });
    }, [handle]),
  };
};
