import { PasswordValidationRule } from 'api/types/data';

/** Password error type */
export enum PasswordErrorType {
  SIZE = 'SIZE',
  DIGIT = 'DIGIT',
  LETTER = 'LETTER',
  SPECIAL_CHARS = 'SPECIAL_CHARS',
}

/** Password min size */
export const PASSWORD_MIN_SIZE = 8;

/** Digit regex */
const digitRegexp = new RegExp('[0-9]', '');

/** Latter regex */
const latterRegexp = new RegExp('[a-zA-Z]', '');

/** Specia chars regex */
const specialCharsRegexp = /[.()\]|"'±§[^№,:;?!*+%<>\\@{}\\/*_#=&~`$-]/;

export interface PasswordValidationParams {
  specialChars?: RegExp;
  minSize?: number;
}

export class PasswordValidation {
  specialChars: RegExp;

  minSize: number;

  constructor({
    specialChars = specialCharsRegexp,
    minSize = PASSWORD_MIN_SIZE,
  }: PasswordValidationParams) {
    this.specialChars = specialChars;
    this.minSize = minSize;
  }

  /** Check the password rules */
  checkPassword(val?: string): Record<PasswordErrorType, boolean> {
    const res = {
      [PasswordErrorType.SIZE]: this.checkPasswordMinSize(val),
      [PasswordErrorType.DIGIT]: this.checkPasswordDigit(val),
      [PasswordErrorType.LETTER]: this.checkPasswordLatter(val),
      [PasswordErrorType.SPECIAL_CHARS]: this.checkPasswordSpecialChars(val),
    };

    return res;
  }

  /** Is correct the password rules */
  isCorrectPassword(val?: string) {
    const passwordRules = this.checkPassword(val);

    const res = Object.values(passwordRules).reduce((a, v) => a && v);

    return res;
  }

  static parseByValidationRule(
    passwordValidationRule?: PasswordValidationRule
  ) {
    const specialChars = passwordValidationRule?.regex.specialChars
      ? new RegExp(passwordValidationRule?.regex.specialChars)
      : undefined;

    const minSize = passwordValidationRule?.minLength;

    return new PasswordValidation({
      specialChars,
      minSize,
    });
  }

  /** Check the password for cpecia chars content */
  private checkPasswordSpecialChars(val?: string) {
    return !!val && this.specialChars.test(val);
  }

  /** Check the password size */
  private checkPasswordMinSize(val?: string) {
    return !!val && val.length >= this.minSize;
  }

  /** Check the password for digit content */
  private checkPasswordDigit(val?: string) {
    return !!val && digitRegexp.test(val);
  }

  /** Check the password for latter content */
  private checkPasswordLatter(val?: string) {
    return !!val && latterRegexp.test(val);
  }
}
