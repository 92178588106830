import { GlobalClasses } from 'helpers';
import { CSSObject, GlobalStyles } from 'libs/ui/styled';

export const GlobalStyled = (
  <GlobalStyles
    styles={(theme) => ({
      [`.${GlobalClasses.hovered}`]: {
        transition: theme.transitions.create(['color']),
      },
      [`.${GlobalClasses.hoveredChild} > *`]: {
        transition: theme.transitions.create(['color']),
      },
      [`.${GlobalClasses.hovered}:hover`]: {
        color: theme.palette.hover,
      } as CSSObject,

      [`.${GlobalClasses.hoveredChild}:hover > *`]: {
        color: theme.palette.hover,
      } as CSSObject,
    })}
  />
);
