import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import {
  OnboardingLegalClientDocument,
  useLegalClientCreateLegalHeadMutation,
} from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { LegalClientCreateLegalHeadSaveParam } from 'api/types/params';

import { parseLegalClientLegalHeadEntity } from './helpers';

export const useLegalClientCreateLegalHead = () => {
  const [handle, { data, loading, error: apolloError }] =
    useLegalClientCreateLegalHeadMutation({
      refetchQueries: [OnboardingLegalClientDocument],
      awaitRefetchQueries: true,
    });

  const error = useError(apolloError);

  return {
    data: data?.legalClientCreateLegalHead
      ? parseLegalClientLegalHeadEntity(data.legalClientCreateLegalHead)
      : undefined,
    loading,
    error,
    createLegalHead: useCallback(
      async (id: string, param: LegalClientCreateLegalHeadSaveParam) => {
        const res = await handle({
          variables: {
            id,
            input: {
              fullName: param.fullName,
              shortName: param.shortName,
            },
          },
        });
        return res.data?.legalClientCreateLegalHead
          ? parseLegalClientLegalHeadEntity(res.data.legalClientCreateLegalHead)
          : undefined;
      },
      [handle]
    ),
  };
};
